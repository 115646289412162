import * as TYPES from './../../types';

// state
const initialState = {
    cbtNodes: [],
    cbtChecked: [],
    cbtExpanded: [],
    historyRedirect: null,
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    main_url: '/settings/users',
    create_url: '/settings/users/role/create',
    edit_url: '/settings/users/role/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    loading: false,
    roles: [],
    role: {}
}

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPES.SET_NODES:
            return {
                ...state,
                cbtNodes: action.payload
            } 
        case TYPES.SET_CHECKED:
            return {
                ...state,
                cbtChecked: action.payload
            }
        case TYPES.SET_EXPANDED:
            return {
                ...state,
                cbtExpanded: action.payload
            }   
        case TYPES.SET_REDIRECT:
            return {
                ...state,
                historyRedirect: action.payload
            }
        case TYPES.SET_ROLES:
            return {
                ...state,
                roles: action.payload.roles,
                pagination: action.payload.pagination
            }   
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }  
        case TYPES.SEARCH_INPUT: 
            return {
                ...state,
                search: action.payload
            }
        case TYPES.SET_SINGLE_ROLE: 
            return {
                ...state,
                role: action.payload
            }    
        case TYPES.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload
            }                  
        default:
            return state;
            break;
    }

}

export default rolesReducer;

