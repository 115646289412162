import * as TYPES from '../../types';
import { UserService } from './_service.users.js';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import history from '../../../history';
import { SwalWarning } from '../../../Services/_swal.service';

export const setLoading = status => async dispatch => dispatch({ type: TYPES.SET_LOADING, payload: status });
export const setRedirect = status => async dispatch => dispatch({ type: TYPES.SET_REDIRECT, payload: status });

export const getPemissions = (id) => async (dispatch, getState) => {
	dispatch(setRedirect(null));
    dispatch(setLoading(true));
    UserService.getPermissions(id).then(res => {

    	// console.log(res.data.PermissionStructured);
    	dispatch({ type: TYPES.SET_CHECKED, payload: [] });
		dispatch({ type: TYPES.SET_EXPANDED, payload: [] });
        dispatch({ type: TYPES.SET_NODES, payload: res.data.PermissionStructured });
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
};


export const setChecked = status => async dispatch => dispatch({ type: TYPES.SET_CHECKED, payload: status });
export const setExpanded = status => async dispatch => dispatch({ type: TYPES.SET_EXPANDED, payload: status });

export const saveRole = (e) => async (dispatch, getState) =>{
    e.preventDefault();
    let formSerialize = $('.form-role-create').serializeArray();
    let formData = {};
    let { cbtChecked } = getState().rolesReducer;

    formSerialize.map((input, i) => {
    	formData[input.name] = input.value;
    });

    formData.permissions = cbtChecked;
    
    dispatch(setLoading(true));
    $('.btn-save').attr('disabled','disabled').html('Saving...');
    UserService.savePermission(formData).then(res => {

    	switch(res.data.status){
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch({ type: TYPES.SET_EXPANDED, payload: [] });
        		dispatch({ type: TYPES.SET_CHECKED, payload: [] });
                dispatch(setRedirect('/settings/users'));
                break;
            default:
                break;
        }

        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
        dispatch(setRedirect(null));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
}

export const getRoles = (pageNumber, rows_per_page = null) => async (dispatch, getState) => {

    dispatch(setLoading(true));
    let { search , pagination, sort_order_name, sort_order_direction } = getState().rolesReducer; 

    let searchPostParams = {
        search: search,
        activePage: pagination.activePage,
        page: pageNumber,
        rows_per_page: rows_per_page, 
        sort_order_name: sort_order_name,
        sort_order_direction: sort_order_direction
    }

    UserService.searchPermissions(searchPostParams).then(res => {

        let pagination = {
            totalCount: res.data.roles.total, 
            activePage: res.data.roles.current_page, 
            itemsCountPerPage: res.data.roles.per_page, 
            totalItemsCount: res.data.roles.total,
        }
        
        dispatch({ type: TYPES.SET_ROLES, payload: { roles: res.data.roles.data, pagination: pagination } })
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
    });
}

export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getRoles());
}

export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value })
}

export const getSelectedRows = (selectedRows, currentRows) => async dispatch => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current,i) => {
        selectedRows.map((selected, _i) => {
            if(selected.index == current.index)
            {
                let selected = { id: current.data[0], name: current.data[1] };
                newArray.push(selected);
            }
        })
    });
    dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
}

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    
    let { rows_to_delete } = getState().rolesReducer;
    let names = '';
    rows_to_delete.map((rows,i) => {  names += rows.name + ", ";  })

    SwalWarning('Warning!', `Do you want to remove selected Roles? "${names}"`, () => dispatch(moveToArchive()));
}

export const moveToArchive = () => async (dispatch, getState) => {

    let { rows_to_delete } = getState().rolesReducer;
    let id_arr = [];
    rows_to_delete.map(row => { id_arr.push(row.id) })

    let params = { 
        ids: JSON.stringify(id_arr)
    }

    dispatch(setLoading(true));
    UserService.ArchiveBrand(params).then(res => {
        ToastSuccess(res.data.message);
        dispatch(setLoading(false));
        dispatch(getRoles());
    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
    });
};


export const getRole = (id) => async (dispatch, getState) => {

    dispatch(setLoading(true));
    UserService.getRole(id).then(res => {

    	// console.log(res.data.PermissionStructured);
    	
    	dispatch({ type: TYPES.SET_SINGLE_ROLE, payload: { id: res.data.role._id, name: res.data.role.role_title,  description: res.data.role.role_desc} });
        dispatch({ type: TYPES.SET_NODES, payload: res.data.PermissionStructured });
        dispatch({ type: TYPES.SET_CHECKED, payload: res.data.checked });
		dispatch({ type: TYPES.SET_EXPANDED, payload: res.data.expanded });
		dispatch(setExpanded(res.data.expanded));
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
};


export const handleInputChange = e => async (dispatch, getState) => {

	let { role } = getState().rolesReducer;
	role[e.target.name] =  e.target.value;
	console.log(role);
    dispatch({ type: TYPES.SET_SINGLE_ROLE, payload: role });
}


export const updateRole = (e) => async (dispatch, getState) =>{
    e.preventDefault();
    let formSerialize = $('.form-role-create').serializeArray();
    let formData = {};
    let { cbtChecked, role } = getState().rolesReducer;


    formData = role;
    formData.permissions = cbtChecked;
    
    dispatch(setLoading(true));
    $('.btn-save').attr('disabled','disabled').html('Saving...');
    UserService.updatePermission(role.id, formData).then(res => {

    	switch(res.data.status){
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch({ type: TYPES.SET_EXPANDED, payload: [] });
        		dispatch({ type: TYPES.SET_CHECKED, payload: [] });
                dispatch(setRedirect('/settings/users'));
                break;
            default:
                break;
        }

        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
        dispatch(setRedirect(null));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
}