import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { formattedDate, numberFormat } from "../../Utils/Common";
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { handleSearchInputMUI, posSalesList, sortTableByColumn, DateRange, DateCallBack, paginateChange, clearDate} from '../../redux/actions/pos/pos.actions';
import {  Show, useAccess } from "react-redux-permission";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { updateMUISetting } from "../../redux/actions/helper/helper.actions";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

function POSSales({ pos: { pos_sales, pagination, search, loading, create_url, sort_order_name, sort_order_direction, set_row_checked, date_range  }, 
    handleSearchInputMUI, posSalesList, handleChangeTab, sortTableByColumn, updateMUISetting, DateRange, DateCallBack, paginateChange, clearDate}) {

    const { hasPermission } = useAccess();
    const canCreate = hasPermission("inv.sales.pos.create");;
    const canview = hasPermission("inv.sales.pos.view");
    const canViewCost = hasPermission("inv.sales.pos.view-cost");
    const canViewProfit = hasPermission("inv.sales.pos.view-profit");

    useEffect(() => {
        posSalesList();
    },[])

    const history = useHistory();
    
    const changePage = (link) => {
        history.push(link);
    }

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles(
        theme => ({
          centeredHeader: {
            '& span': {
              display:'flex',
              justifyContent: 'left',
              alignItems: 'center',
            },
          },
        })
    );
    const classes = useStyles();    

    // THIS SET THE ROW BACKGROUND COLOR   
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            // MUIDataTableHeadCell: {
            //     toolButton: {
            //       justifyContent: 'center'
            //     },
            // },
            MUIDataTableBodyCell: {}
        }
    });


    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'date', label: 'Date', options: {
            customBodyRender: (value) => {
                return (  <span>{moment(value).format('MMM D, YYYY (dddd)')}</span> )
                }
            }
        },
        {name: '', label: 'Time', options: { display: true, print: false , download: false, sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let start = pos_sales[tableMeta['rowIndex']]['start'];
                    let end = pos_sales[tableMeta['rowIndex']]['end'];
                    let status = pos_sales[tableMeta['rowIndex']]['status'];
                    // console.log(start, end);
                    return (  <span>{`${moment(start, 'h:mm').format('h:mm A')}`}{status == 'OPEN' ? '' : ` - ${moment(end, 'h:mm').format('h:mm A')}`}</span> )
                },
                // setCellProps : value => {
                //     return {className : "cursor-pointer text-center"}
                // },
            }
        },
        {name: 'status', label: 'Status', options: { display: true, sort : false, download : false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const status = pos_sales[tableMeta['rowIndex']].status;
                // console.log(status);
                    return (  <span className={`lbl-status ${status == 'OPEN' ? 'lbl-primary' : 'lbl-closed'}`}>{status == 'OPEN' ? 'OPEN' : 'CLOSED'}</span> )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer text-left"}
                },
            },
            
        },
        {name: 'total_qty', label: 'Total Sold', options: { display: true, print: false , download: false, sort: true,
                setCellProps : value => {
                    return {className : "cursor-pointer text-left"}
                },
            } 
        },
        {name: 'total_transactions', label: 'Transactions', options: { display: true, print: false , download: false, sort: true,
                setCellProps : value => {
                    return {className : "cursor-pointer text-left"}
                },
            } 
        },
        {name: 'total_costs', label: 'Total Cost', options: { display: true, print: false , download: false, sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (  <span>{numberFormat(value)}</span> )
            },
            setCellProps : value => {
                    return {className : "cursor-pointer text-left"}
                },
            } 
        },
        {name: 'total_sales', label: 'Total Sales', options: { display: true, print: false , download: false, sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (  <span>{numberFormat(value)}</span> )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer text-left"}
                },
            } 
        },
        {name: 'total_profit', label: 'Net Profit', options: { display: true, print: false , download: false, sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (  <span>{numberFormat(value)}</span> )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer text-left"}
                },
            } 
        },
    ];


    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20],
        rowsPerPage: pagination.itemsCountPerPage ? pagination.itemsCountPerPage : 1,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        selectableRows: false,
        elevation: 2,
        print: false,
        download: false,
        filter: true,
        viewColumns: true,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `pos_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            posSalesList(null, numberOfRows)
            updateMUISetting('rows', 'pos.sales', numberOfRows);
            paginateChange(numberOfRows, '')
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    posSalesList(tableState.page + 1, pagination.itemsCountPerPage);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    // getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'pos.sales', null, tableState.columns);
                    break;
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
        },
        onRowClick: (rowData, rowMeta) => {
            let id = rowData[0];
            // console.log(id)
            handleChangeTab('Transactions', id);
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            // console.log("options", options)
            return (    
                <div className="custom-search">
                    {/* <i className="ft ft-calendar"></i> */}
                    <DateRangePicker  onEvent={(event, picker) => DateRange(event,picker)} onCallback={(start, end) => DateCallBack(start, end, options.page, options.rowsPerPage)}>
                        <input type="text" className="custom-search-input text-center" />
                    </DateRangePicker>
                    <a onClick={() => clearDate(options.page, options.rowsPerPage)}><i className="ft ft-x"></i></a>
                   {/* <i className="ft ft-calendar"></i> <input type="date"  name="search" value={search} onChange={DateRange}  className="custom-search-input"/> */}
                </div>
            )
        },
    };

    const _columns = updateMUISetting('view-columns', 'pos.sales', null, columns);

    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="col-md-6">
                    <h4 className="card-title">POS Daily Sales <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                    <div className="heading-elements">
                        <ul className="list-inline mb-0"></ul>
                    </div>
                </div> 
                
            </div>
            <div className="card-content collapse show">
                <div className="card-body">
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable data={pos_sales} columns={_columns} options={options}/>
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    pos: state.pos
});

export default connect(mapStateToProps, { handleSearchInputMUI, posSalesList, sortTableByColumn, updateMUISetting, DateRange, DateCallBack, paginateChange, clearDate})(POSSales);
