import React, {Component, Fragment } from 'react';
import Spinner from "../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import AgentTable from './AgentTable';
import AgentCreateModal from './AgentCreateModal';
import { AgentService } from './_service.agent';
import { ToastSuccess } from '../../Services/_toast.service';
import ReactTooltip from 'react-tooltip';

class Agent extends Component {
		
	state = {
		agents: [],
        totalCount: '',
		loading: false,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8
	}

	componentDidMount() {
		this.getAgents();
	}

	// fetch all agents
    getAgents = async () => {
        this.setState({ loading: true });

        AgentService.paginateWithSearchAgents().then(res => {
            // console.log(res);
            this.setState({ 
                agents: res.data.agents.data, 
                totalCount: res.data.agents.total,
                activePage: res.data.agents.current_page,
                itemsCountPerPage: res.data.agents.per_page,
                totalItemsCount: res.data.agents.total,
                loading: false 
            });
        }).catch(error => {
             alert(`An Error Occured! ${error}`);
             console.log(error);
        });
    };
    
    // update archives: 1 if deleted
    moveToArchives = async id => {
        AgentService.ArchiveAgent(id).then(res => {
            // fetch the new updated data
            this.getAgents();
            // alert message
            ToastSuccess(res.data.message);
        }).catch(error => {
             alert(`An Error Occured! ${error}`);
             console.log(error);
        });
    };
    
    // pagination links
    handlePageChange = (pageNumber) => {
        
        this.setState({ loading: true});

        let paginateParams = {
            search: this.state.search,
            page: pageNumber
        }

        AgentService.paginateWithSearchAgents(paginateParams).then(res => {
                this.setState({ 
                    agents: res.data.agents.data, 
                    activePage: res.data.agents.current_page,
                    itemsCountPerPage: res.data.agents.per_page,
                    totalItemsCount: res.data.agents.total,
                    loading: false
                });
        }).catch(error => {
             alert(`An Error Occured! ${error}`);
             console.log(error);
        });
    }

    // search 
    searchAgent = async (search) => {
        this.setState({ loading: true, search: search });
    
        let searchPostParams = {
            search: search
        }

        AgentService.paginateWithSearchAgents(searchPostParams).then(res => {
            // console.log(res);
            this.setState({ 
                agents: res.data.agents.data, 
                totalCount: res.data.agents.total,
                activePage: res.data.agents.current_page,
                itemsCountPerPage: res.data.agents.per_page,
                totalItemsCount: res.data.agents.total,
                loading: false 
            });
        }).catch(error => {
             alert(`An Error Occured! ${error}`);
             console.log(error);
        });    
    }

    open = () => {
      this.setState({showModal: true});
    }

    close = () => {
      this.setState({showModal: false});
    }

	render() {
		return (
			<div className="app-content content">
                <ReactTooltip  effect="solid"/>
                <div className="content-wrapper">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Agent
                            </h3>

                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">List of Agents <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                            <a className="heading-elements-toggle">
                                                <i className="la la-ellipsis-v font-medium-3"></i>
                                            </a>
                                            <div className="heading-elements">
                                            
                                            </div>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <section className="row">
                                                    <div className="col-sm-12">
                                                        {this.state.loading ? (
                                                            <Spinner />
                                                        ) : this.state.agents.length  > 0 ? (
                                                            <Fragment>
                                                                <AgentTable
                                                                    agents={this.state.agents}
                                                                    totalCount={this.state.totalCount}
                                                                    moveToArchives={this.moveToArchives}
                                                                    getAgents={this.getAgents}
                                                                    searchAgent={this.searchAgent}
                                                                />

                                                               {this.state.totalCount > 10 && (
                                                                    <div className="d-flex justify-content-center">
                                                                         <Pagination
                                                                            className="pagination"
                                                                            itemClass='page-item'
                                                                            linkClass='page-link'
                                                                            activePage={this.state.activePage}
                                                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                                                            totalItemsCount={this.state.totalItemsCount}
                                                                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                                                                            onChange={this.handlePageChange}
                                                                        />
                                                                    </div>
                                                                )}
                                                            
                                                            </Fragment>
                                                        ) : (
                                                        
                                                            <h1 align="center" className="my-5">-- No Data --</h1>
                                                        )}
                                                    </div>
                                                </section>      
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>

		)
	}
}

export default Agent;