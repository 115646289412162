import React, { Fragment, useEffect, useMemo, useState } from "react";
import Spinner from "../layouts/Spinner";
import { Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import {
  setPage,
  getQuotations,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  emailConfig,
  sendEmailDyanmic,
  dynamiPdf,
} from "../../redux/actions/quotation/quotation.actions";
import { Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import QuotationPrintModal from "./modals/Quotation.modal.print";
import { formattedDate, numberFormat } from "../../Utils/Common";
import { Show, useAccess } from "react-redux-permission";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  handleHover,
  setMUIPage,
  updateMUISetting,
} from "../../redux/actions/helper/helper.actions";
import { defaultFormat } from "../../Utils/DateFormat";

function QuotationTable({
  quotation: {
    quotations,
    loading,
    search,
    pagination,
    print_modal,
    edit_url,
    edit_page,
    create_url,
    item_id,
    sort_order_name,
    sort_order_direction,
    set_row_checked,
  },
  customer: { history_url },
  setPage,
  getQuotations,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  pageOrigin,
  handleHover,
  hoverIndex,
  updateMUISetting,
  emailConfig,
  setMUIPage,
  muiPage,
  sendEmailDyanmic,
  dynamiPdf,
}) {
  const { hasPermission } = useAccess();
  const canCreate = hasPermission("inv.sales.quotations.create");
  const canDelete = hasPermission("inv.sales.quotations.delete");
  const canEdit = hasPermission("inv.sales.quotations.edit");
  const canview = hasPermission("inv.sales.quotations.view");
  const canPrint = hasPermission("inv.sales.quotations.print");
  const canCreateInvoice = hasPermission("inv.sales.sales-orders.create");

  // START DEVOUNCED SEARCH
  const debouncedSearch = useDebounce(search, 800);
  const [oldDebounced, setOldDebounced] = useState("");
  useEffect(() => {
    if (debouncedSearch) {
      if (oldDebounced !== debouncedSearch[0]) {
        if (debouncedSearch[0].length !== 1) {
          if (canview) {
            setOldDebounced(debouncedSearch[0]);
            getQuotations(1, null, pageOrigin);
          }
        }
      }
    }
  }, [debouncedSearch]);
  // END DEVOUNCE SEARCH

  useEffect(() => {
    getQuotations(muiPage, null, pageOrigin);
    emailConfig();
  }, []);

  if (edit_page) return <Redirect to={edit_url} />;

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "rgba(0,0,0,.05)",
            },
          },
        },
        MuiTableRow: {
          hover: {
            "&:hover": {
              backgroundColor: "#bfcfe4 !important",
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            "&:nth-child(2)": {
              height: "62px",
            },
            "&:nth-child(8)": {
              width: "150px",
            },
            "&:nth-child(9)": {
              width: "200px",
            },
            "&:nth-child(10)": {
              width: "190px",
            },
          },
        },
        MUIDataTableHeadCell: {},
      },
    });

  const columns = [
    {
      name: "_id",
      label: "",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "quotation_no",
      label: "Quotation No",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = quotations[tableMeta["rowIndex"]]._id;
          return (
            <Link
              className='txt-underline'
              onClick={() => setPage("print_modal_qu", id)}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date Created",
      options: {
        display: true,
        customBodyRender: (value) => {
          return <span> {value.substr(0, 10)} </span>;
        },
        setCellProps: (value) => {
          return { className: "cursor-pointer" };
        },
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: {
        display: true,
        filter: false,
        sort: false,
        // customBodyRender: (value) => {
        //   return (
        //     <Link className='txt-underline' to={`${history_url}/${value._id}`}>
        //       <span>{value.name}</span>
        //     </Link>
        //   );
        // },
        customBodyRender: (value, tableMeta) => {
          let id = quotations[tableMeta["rowIndex"]].customer_id;
          return (
            <span>
              <Link
                className='txt-underline'
                to={`${history_url}/${id ?? ""}`}
              >
                {value ?? ""}
              </Link>
            </span>
          );
        },
        setCellProps: (value) => {
          return { className: "text-left" };
        },
      },
    },
    {
      name: "",
      label: "Agent",
      options: {
        display: true,
        filter: false,
        print: false,
        download: false,
        sort: false,
        customBodyRender: (value) => {
          return <div> </div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: true,
        customBodyRender: (value) => {
          return <span className={"lbl-status lbl-" + value}> {value} </span>;
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        display: true,
        customBodyRender: (value) => {
          return <div className='text-left'>₱ {numberFormat(value)} </div>;
        },
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        display: true,
        customBodyRender: (value) => {
          return <span> {value} </span>;
        },
        setCellProps: (value) => {
          return { className: "mw-20m" };
        },
      },
    },
    {
      name: "",
      label: "Order",
      options: {
        display: true,
        filter: false,
        sort: false,
        print: false,
        download: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {

          let makeSi = {
            value: quotations[tableMeta['rowIndex']]._id,
            name: 'qu',
            label: quotations[tableMeta['rowIndex']].quotation_no,
            pageOrigin: 'qu-makeSi'
        }

          let delivery_status = quotations[tableMeta['rowIndex']].delivery_status;
          let dr_id = quotations[tableMeta['rowIndex']].dr_id;

          let data = quotations[tableMeta["rowIndex"]];
          let status = quotations[tableMeta["rowIndex"]].status;

          if (!canCreateInvoice) {
            return false;
          }

          return (
            <div>
              <td width='15%' align='left'>
                {status === "saved" ? (
                  <Link
                    to={{ pathname: "/sales/sales-orders/create", makeSi }} 
                    className='btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white'
                  >
                    MAKE ORDER
                  </Link>
                ) : (
                  "N/A"
                )}
              </td>
            </div>
          );
        },
        setCellProps: (value) => {
          return { className: "cursor-pointer" };
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = quotations[tableMeta["rowIndex"]]._id;

          if (!canEdit) {
            return false;
          }

          return (
            <div className='btn-group'>
              {(hoverIndex == tableMeta.rowIndex ||
                quotations[tableMeta["rowIndex"]].sending_email ||
                quotations[tableMeta["rowIndex"]].download_pdf) && (
                <>
                  <Link
                    to={`${edit_url}/${id}`}
                    className='btn btn-sm btn-grey'
                  >
                    <i className='ft ft-edit'></i>
                  </Link>
                  <button
                    className='btn btn-sm btn-blue'
                    onClick={(e) => sendEmailDyanmic(e, id, tableMeta.rowIndex)}
                  >
                    <i
                      className={
                        quotations[tableMeta["rowIndex"]].sending_email
                          ? "icon-loading-custom icon-15"
                          : quotations[tableMeta["rowIndex"]].email_sent
                          ? "custom-resend"
                          : "ft ft-navigation"
                      }
                    ></i>
                  </button>
                  <button
                    className='btn btn-sm btn-danger'
                    onClick={(e) => dynamiPdf(e, id, tableMeta.rowIndex)}
                  >
                    <i
                      className={
                        quotations[tableMeta["rowIndex"]].download_pdf
                          ? "icon-loading-custom icon-15"
                          : "ft-custom-pdf"
                      }
                    ></i>
                  </button>
                </>
              )}
            </div>
          );
        },
        setCellProps: (value) => {
          return { className: "text-center" };
        },
      },
    },
  ];

  const options = {
    serverSide: true,
    jumpToPage: true,
    filterType: "dropdown",
    responsive: "standard",
    search: false,
    searchOpen: true,
    filter: false,
    rowsPerPageOptions: [10, 20],
    rowsPerPage: pagination.itemsCountPerPage,
    count: pagination.totalItemsCount,
    page: pagination.activePage - 1,
    sortOrder: {
      name: sort_order_name,
      direction: sort_order_direction,
    },
    downloadOptions: {
      filename: `quotations_${formattedDate()}.csv`,
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Spinner />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onChangeRowsPerPage: (numberOfRows) => {
      getQuotations(null, numberOfRows, pageOrigin);
      updateMUISetting("rows", "quotation", numberOfRows);
    },
    onTableChange: (action, tableState) => {
      // console.log(action, tableState)
      switch (action) {
        case "changePage":
          getQuotations(
            tableState.page + 1,
            pagination.itemsCountPerPage,
            pageOrigin
          );
          setMUIPage(tableState.page + 1);
          break;
        case "sort":
          let column_name = tableState.sortOrder.name;
          let direction = tableState.sortOrder.direction;
          sortTableByColumn(column_name, direction, pageOrigin);
          break;
        case "search":
          // handleSearchInputMUI(tableState.searchText);
          break;
        case "rowSelectionChange":
          let selectedRows = tableState.selectedRows.data;
          let currentRows = tableState.data;
          getSelectedRows(selectedRows, currentRows);
          break;
        case "propsUpdate":
          // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
          if (set_row_checked) {
            tableState.selectedRows["data"] = [];
            tableState.selectedRows["lookup"] = {};
            tableState.selectedRows["showResponsive"] = false;
          } else {
            return tableState;
          }
        case "viewColumnsChange":
          updateMUISetting("columns", "quotation", null, tableState.columns);
          break;
        default:
        // console.log('action not handled.');
      }
    },
    onRowsDelete: () => {
      // Confirm Delete Modal
      if (canDelete) {
        confirmDeleteDialogBox(pageOrigin);
      }

      return false;
    },
    onRowClick: (rowData, rowMeta) => {
      // redirect to edit page
      // changePage(`${edit_url}/${rowData[0]}`)
      // showmodal
    },
    onCellClick: (colData, cellMeta) => {
      // console.log(cellMeta)
      if (cellMeta.colIndex !== 7 && cellMeta.colIndex !== 8) {
        // console.log('PO_PRINT_ID', quotations[cellMeta.dataIndex]._id, quotations);
        if (canPrint) {
          // setPage('print_modal_qu', quotations[cellMeta.dataIndex]._id);
        }
      }
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div className='custom-search'>
          <i className='ft ft-search'></i>{" "}
          <input
            type='text'
            name='search'
            onChange={handleSearchInputMUI}
            className='custom-search-input'
          />
        </div>
      );
    },
    setRowProps: (data, dataIndex, rowIndex) => {
      return {
        onMouseEnter: () => handleHover("in", rowIndex),
        onMouseLeave: () => handleHover("out", rowIndex),
      };
    },
  };

  if (!canDelete) {
    options.selectableRows = false;
  }

  const _columns = updateMUISetting("view-columns", "quotation", null, columns);

  return (
    <>
      <Show when='inv.sales.quotations.view'>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={""}
            data={quotations}
            columns={_columns}
            options={options}
          />
        </MuiThemeProvider>
      </Show>
      <Show when='inv.sales.quotations.print'>
        <QuotationPrintModal
          onHide={() => setPage("print_modal", null, false)}
          edit_url={edit_url}
        />
      </Show>
    </>
  );
}

const mapStateToProps = (state) => ({
  quotation: state.quotation,
  customer: state.customer,
  hoverIndex: state.helper.hoverIndex,
  muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
  setPage,
  getQuotations,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  handleHover,
  updateMUISetting,
  emailConfig,
  setMUIPage,
  sendEmailDyanmic,
  dynamiPdf,
})(QuotationTable);
