import React, { Fragment, useState } from "react";
import { NumberFormat } from "../../Services/_numberformat.service";
import { connect, useDispatch } from "react-redux";
import {
  reactSelectStyle,
  truncateString,
  stockClass,
} from "./../../Utils/Common";
import { defaultFormat } from "./../../Utils/DateFormat";
import {getSingleProduct} from '../../redux/actions/purchase_order/purchase_order.actions'
import { Link } from 'react-router-dom';
import moment from "moment";

function PurchaseOrderView({
  purchase_order: {
    single_purchase_order,
    count_items,
    default_supplier_value,
    po_default_template,
    supplier_item_only,
  },
}) {
  // subtotal
  let percent_value = [
    { value: "none", label: "None", symbol: "" },
    { value: "percent", label: "Percent", symbol: "%" },
    { value: "amount", label: "Fixed Amount", symbol: "" },
  ];

  const [showArrowBox, setShowArrowBox] = useState({ show: false, index: null, position: { x: 0, y: 0 } });

  const dispatch = useDispatch();
  // Add the handleHoverProduct function
  const handleHoverProduct = (productId, index, e) => {
    dispatch(getSingleProduct(productId));
    
    // Update the position of the hover box based on mouse coordinates
    setShowArrowBox({
      show: true,
      index,
      position: { x: e.clientX, y: e.clientY }
      });
  };

  const handleMouseMove = (e) => {
      // Update the position of the hover box based on mouse coordinates
      setShowArrowBox((prev) => ({
          ...prev,
          position: { x: e.clientX, y: e.clientY }
      }));
  };

  const handleScroll = () => {
      // Update the position of the hover box based on scroll
      setShowArrowBox((prev) => ({
          ...prev,
          position: { ...prev.position, y: prev.position.y + window.scrollY }
      }));
  };

  const status_lbl = (
    <span className={`lbl-status mr-1 lbl-${single_purchase_order.status}`}>
      {single_purchase_order.status}
    </span>
  );
  const payment_mode_status =
    single_purchase_order.payment_type &&
    single_purchase_order.payment_type.value != "" ? (
      <span
        className={`lbl-status mr-1 lbl-payment-type lbl-${single_purchase_order.payment_type.label}`}
      >
        {single_purchase_order.payment_type.label}
      </span>
    ) : (
      ""
    );
  return (
    <form>
      <section className='f-regular'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-12 mb-2'>
                {status_lbl}
                {payment_mode_status}
              </div>
              <div className='col-sm-4'>
                <label>
                  Template <span className='text-danger'>*</span>
                </label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    {/* <Select options={po_templates} onChange={handleSelectPOTemplate} value={po_default_template} isSearchable={isSearchable} required/> */}
                    <div className='form-control f-regular'>
                      {po_default_template.label}
                    </div>
                  </div>
                  <div className='float-right pl-0 col-md-2 col-2'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'></div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <label>
                  Supplier <span className='text-danger'>*</span>{" "}
                </label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    <div className='form-control f-regular'>
                      {default_supplier_value.label}
                    </div>
                    {/* <AsyncSelect defaultValue={default_supplier_value} isLoading={loading} loadingMessage={() => 'searching...'} defaultOptions={suppliers_default} loadOptions={SearchSupplier} isSearchable={true} cacheOptions onChange={handleSelectSupplier} placeholder="Select Supplier..." required/> */}
                  </div>
                  <div className='float-right pl-0 col-md-2 col-2'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label class='form-check-label font-medium-2 text-bold-600 ml-1'>
                    <i
                      className={
                        supplier_item_only
                          ? "ft ft-check-square"
                          : "ft ft-square"
                      }
                    ></i>{" "}
                    Generate P.O. by Supplier{" "}
                  </label>
                </div>
              </div>
              <div className='col-sm-4'></div>
              <div className='col-sm-4'>
                <label>PO Date</label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    <div className='date-picker-100'>
                      <div className='form-control f-regular'>
                        {defaultFormat(
                          single_purchase_order.purchase_order_date
                        )}
                      </div>
                      {/* <DatePicker onChange={date => PODatePicker(date)} selected={single_purchase_order.purchase_order_date} name="purchase_order_date" className="form-control f-regular"/> */}
                    </div>
                  </div>
                  <div className='float-right pl-0 col-md-2 col-2'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                {single_purchase_order.supplier_details && (
                  <div className='table-responsive'>
                    <table className='table table-hover table-striped table-bordered f-regular'>
                      <thead>
                        <tr>
                          <th className='text-center'>SKU</th>
                          <th className='text-center'>Product</th>
                          <th className='text-center'>Qty</th>
                          <th className='text-center'>Unit</th>
                          <th className='text-center'>Unit/Price</th>
                          <th className='text-center'>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {single_purchase_order.purchase_order_items.map(
                          (item, index) => (
                            // <tr
                            //   key={index}
                            //   className={stockClass(item.product.status_code)}
                            // >
                            <Fragment key={index}> {/* This is the key part */}
                            <tr className={stockClass(item.product ? item.product.status_code : null)}>
                              <td className='min-wdth-100 max-wdth-100'>
                                {item.sku}
                              </td>
                              <td className='min-wdth-300 max-wdth-300'>
                              <Link
                                to={`/inventory/view/${item._id}`}
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                onMouseEnter={(e) => handleHoverProduct(item._id, index, e)}
                                onMouseMove={(e) => handleMouseMove(e)}
                                onMouseLeave={() => setShowArrowBox({ show: false, index: null, position: { x: 0, y: 0 } })}
                                >
                                {item.product_name}
                                </Link>
                                {showArrowBox.show && showArrowBox.index === index && (
                                  <div className="arrow-box" style={{ top: `${showArrowBox.position.y}px`, left: `${showArrowBox.position.x}px`, position: 'fixed' }}>
                                  <p>Incoming: <span className='font-weight-bold'>{item.productDetails.incoming_orders}</span></p>
                                    <p>Current stock: <span className='font-weight-bold'>{item.productDetails.total_stocks}</span></p>
                                    <p>Last arrival: <span className='font-weight-bold'>{moment(item.productDetails.last_arrival).isValid() ? moment(item.productDetails.last_arrival).format("YYYY-MM-DD") : "N/A"}</span></p>
                                  </div>
                                )}
                                <div className='text-secondary f-12 text-dark'>
                                  {item.product != null &&
                                    item.product.barcode}
                                </div>
                                <div className='text-secondary f-12 text-dark'>
                                  {item.product != null &&
                                    item.product.description}
                                </div>
                                <div className='text-secondary f-12 text-dark'>
                                  {item.brand && item.brand.name}
                                </div>
                              </td>
                              <td className='min-wdth-100 max-wdth-100 text-center'>
                                {item.qty}
                              </td>
                              <td className='min-wdth-75 max-wdth-75 text-center'>
                                {item.product_unit_name}
                              </td>
                              <td
                                className='min-wdth-150 max-wdth-150'
                                align='right'
                              >
                                {NumberFormat(item.cost.toFixed(2))}
                              </td>
                              <td
                                className='min-wdth-100 max-wdth-100'
                                align='right'
                              >
                                {item.amount
                                  ? NumberFormat(item.amount.toFixed(2))
                                  : NumberFormat(item.cost.toFixed(2))}
                              </td>
                            </tr>

                            {item.discount_type && item.discount_type.value !== 'none' &&
                              <tr>
                                <td colSpan={1}></td>
                                <td className="d-flex justify-content-left " >
                                 
                                  
                                <div className="w-50">
                                    <span>Discount: </span>
                                    <span className="product-discount">
                                        {item.discount}
                                        {item.discount_type.label === 'Percent' ? ' %' : ''}
                                    </span>
                                </div>
                                                            
                                <div className="w-50">
                                    <span>Discount type: </span>
                                    <span className="product-discount">
                                        {item.discount_type.label}
                                    </span>
                                </div>

                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='min-wdth-100 max-wdth-100 text-right text-danger'>
                                  - {NumberFormat(Number(item.discount_amount).toFixed(2))}
                                </td>
                              </tr>
                            }


                              </Fragment>
                          )
                        )}
                        <tr>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                        </tr>
                        <tr>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                          <td className='p-2'>&nbsp;</td>
                        </tr>
                        <tr>
                          <td colSpan={4}></td>
                          <td>
                            <div align='right'>Sub Total</div>
                          </td>
                          <td align='right'>
                            {NumberFormat(
                              single_purchase_order.sub_total.toFixed(2)
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}></td>
                          <td>
                            <div align='right'>Discount</div>
                          </td>
                          <td align='right'>                          
                          {single_purchase_order.discount_type.value === 'percent' ? `${single_purchase_order.discount}  %` : NumberFormat(single_purchase_order.discount.toFixed(2))}  
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}></td>
                          <td>
                            <div align='right'>
                              VAT{" "}
                              {single_purchase_order.tax == 0
                                ? null
                                : single_purchase_order.tax_type.value}
                            </div>
                          </td>
                          <td align='right'>{single_purchase_order.tax} %</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td align='right'>Total</td>
                          <td align='center'>{count_items}</td>
                          <td></td>
                          <td>
                            <div align='right'>
                              <strong>Total</strong>
                            </div>
                          </td>
                          <td align='right'>
                            {NumberFormat(
                              single_purchase_order.total.toFixed(2)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className='col-12 mb-2'></div>

                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label htmlFor=''>Remarks:</label>
                          <div className='form-control f-regular'>
                            {single_purchase_order.remarks || "None"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className='col-md-4'>
                        <label>Payment Mode: </label>
                        <div className='form-control f-regular'>
                          {single_purchase_order.payment_mode
                            ? single_purchase_order.payment_mode.label
                            : "None"}
                        </div>
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className='col-md-4'>
                        <label>Payment Terms: </label>
                        <div className='form-control f-regular'>
                          {single_purchase_order.payment_terms
                            ? single_purchase_order.payment_terms.label
                            : "None"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
}

const mapStateToProps = (state) => ({
  purchase_order: state.purchase_order,
});

export default connect(mapStateToProps, {})(PurchaseOrderView, getSingleProduct);
