import { getNumRows } from '../../../Utils/Common';
import * as TYPES from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import { ReturnsService } from './_service.return';

// Set Loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });

// Set Modal
export const setModal =
    (modal, status, data = null) =>
    async (dispatch) => {
        dispatch({
            type: TYPES.SET_MODAL,
            payload: { modal, status, data },
        });
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
    // dispatch(getReceiving(1));
};

export const getStockLogs =
    (
        pageNumber,
        rows_per_page = null,
        pageOrigin,
        movement_filter = 'out',
        date_filter = null
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));

            let { search, pagination, sort_order_name, sort_order_direction } =
                getState().returns;
            let rows_per_page_val =
                rows_per_page != null ? rows_per_page : getNumRows('returns');

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let params = {
                search: search,
                options: {
                    page: pageNumber ? pageNumber : pagination.activePage,
                    // limit: rows_per_page_val ? rows_per_page_val : 10,
                    sort_by: sort_order_name,
                    sort_order: sort_order_direction,
                },
                product_id: pageOrigin ? pageOrigin.product_id : null,
                movement: movement_filter,
            };

            // Custom History Date Filtering
            if (date_filter) {
                params.date_from = date_filter['date_from'];
                params.date_to = date_filter['date_to'];
            }
            ReturnsService.getStockLogs(params).then((result) => {
                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let payload = {
                    stock_logs: result.data.docs,
                    pagination: {
                        totalCount: result.data.total_docs,
                        activePage: result.data.page,
                        // itemsCountPerPage: result.data.limit,
                        totalItemsCount: result.data.total_docs,
                    },
                };

                dispatch({ type: TYPES.GET_STOCK_LOGS, payload });
            });
        } catch (err) {
            console.log(err);
            dispatch(setLoading(false));
        }
    };

export const getReturns =
    (
        pageNumber,
        rows_per_page = null,
        pageOrigin,
        movement_filter = 'return',
        date_filter = null
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));

            let { search, pagination, sort_order_name, sort_order_direction } =
                getState().returns;
            let rows_per_page_val =
                rows_per_page != null ? rows_per_page : getNumRows('returns');

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let params = {
                search: search,
                options: {
                    page: pageNumber ? pageNumber : pagination.activePage,
                    // limit: rows_per_page_val ? rows_per_page_val : 10,
                    sort_by: sort_order_name,
                    sort_order: sort_order_direction,
                },
                product_id: pageOrigin ? pageOrigin.product_id : null,
                movement: movement_filter,
            };

            // Custom History Date Filtering
            if (date_filter) {
                params.date_from = date_filter['date_from'];
                params.date_to = date_filter['date_to'];
            }

            ReturnsService.getReturns(params).then((result) => {
                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let payload = {
                    returns: result.data.docs,
                    pagination: {
                        totalCount: result.data.total_docs,
                        activePage: result.data.page,
                        //itemsCountPerPage: result.data.limit,
                        totalItemsCount: result.data.total_docs,
                    },
                };

                dispatch({ type: TYPES.GET_RETURNS_DATA, payload });
            });
        } catch (err) {
            console.log(err);
            dispatch(setLoading(false));
        }
    };

export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getStockLogs());
    };

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
    };

export const getSingleStockLog =
    (id, status = null) =>
    async (dispatch) => {
        if (!status) {
            dispatch(setLoading(true));
        }

        ReturnsService.getSingleStockLog(id)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_SINGLE_STOCK_LOG,
                    payload: res.data.stock_log,
                });

                if (!status) {
                    dispatch(setLoading(false));
                }
                // Open the modal after data is fetched
                dispatch({
                    type: TYPES.SET_MODAL,
                    payload: { modal: 'stockLogModal', status: true },
                });
            })
            .catch((error) => {
                console.log(error);
                if (!status) {
                    dispatch(setLoading(false));
                }
            });
    };

export const handleInputChange = (e) => async (dispatch, getState) => {
    let { name, value } = e.target;
    const { modalData } = getState().returns;
    const dataQty = modalData.quantity;

    if (name === 'qty') {
        value = Math.min(parseFloat(value), dataQty); // Restrict value to dataQty
    }

    dispatch({ type: TYPES.RETURN_HANDLE_INPUT, payload: { name, value } });
};

// handle qty item pos
export const updateQty = (status) => async (dispatch, getState) => {
    const { return_stock_logs, modalData } = getState().returns;
    const dataQty = modalData.quantity;
    const qty = return_stock_logs.qty;

    let newQty = status === 'inc' ? qty + 1 : qty > 0 ? qty - 1 : qty;
    newQty = Math.min(newQty, dataQty); // Cap the quantity to dataQty

    dispatch({ type: TYPES.RETURN_UPDATE_QTY, payload: newQty });
};

//** SUBMIT RETURN */
export const submitReturn = (e) => async (dispatch, getState) => {
    e.preventDefault();

    const { modalData, return_stock_logs } = getState().returns;
    const { new_uploads, qty, remarks } = return_stock_logs;

    // console.log('new_uploads', new_uploads); // Verify all files are here
    let errors = false;
    if (remarks === '') {
        ToastDanger('Remarks is required');
        errors = true;
    } else if (new_uploads.length === 0) {
        ToastDanger('Please add attachment file');
        errors = true;
    } else if (qty === 0) {
        ToastDanger('Quantity must not be equal to 0');
        errors = true;
    }

    if (!errors) {
        const formData = new FormData();
        formData.append('id', modalData._id);
        formData.append('reference_id', modalData.reference_id);
        formData.append('reference_type', modalData.reference_type);
        formData.append('movement', modalData.movement);
        formData.append('product_id', modalData.product_id);
        formData.append('qty', qty);
        formData.append('remarks', remarks);

        // Append each file in new_uploads to FormData with the field name 'new_uploads[]'
        new_uploads.forEach((file) => {
            formData.append('new_uploads[]', file);
        });

        ReturnsService.postReturn(formData)
            .then((res) => {
                ToastSuccess(res.data.message);

                // Close the modal
                dispatch({
                    type: TYPES.SET_MODAL,
                    payload: { modal: 'return_stock_modal', status: false },
                });

                // Refresh the table
                dispatch(getStockLogs(1));
            })
            .catch((error) => {
                if (error.response?.data?.errors) {
                    ToastDanger(error.response.data.errors);
                }
                console.log(error);
            });
    }
};

export const resetModalInputs = () => (dispatch) => {
    dispatch({
        type: TYPES.RETURN_CLEAR,
        payload: {
            return_stock_logs: {
                new_uploads: [],
                files_to_remove: [],
                qty: 0,
                remarks: '',
            },
        },
    });
};

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
    dispatch({
        type: TYPES.DATE_FILTER_INPUT,
        payload: { key: [e.target.name], value: e.target.value },
    });
};

// DATE INPUT CLEAR
export const dateFilterClear =
    (type, pageOrigin = null) =>
    async (dispatch) => {
        dispatch({ type: TYPES.LOGS_DATE_INPUT_CLEAR, payload: type });

        switch (type) {
            case 'stock_logs':
                dispatch(getStockLogs(1, null, pageOrigin));
                break;
            default:
                break;
        }
    };

// HANDLE SUBMIT DATE FILTER FOR [ QUOTATIONS, SALES_INVOICES .... ]
export const handleDateFilterSubmit =
    (e, filterBy, pageOrigin = null, movementFilter) =>
    async (dispatch, getState) => {
        e.preventDefault();

        // console.log('movementFilter', movementFilter);
        let { date_to, date_from } = getState().returns;
        const today = new Date();
        try {
            if (
                new Date(date_from).getTime() >= today.getTime() ||
                new Date(date_to).getTime() >= today.getTime()
            ) {
                ToastDanger('Your Date value can not be greater than today.');
            } else {
                let date_filter = { date_from: date_from, date_to: date_to };
                // CHECK TYPE OF PAGE TO FILTER
                switch (filterBy) {
                    case 'stock_logs':
                        dispatch(
                            getStockLogs(
                                1,
                                null,
                                pageOrigin,
                                movementFilter,
                                date_filter
                            )
                        );
                        break;
                    case 'returns':
                        dispatch(
                            getReturns(
                                1,
                                null,
                                pageOrigin,
                                movementFilter,
                                date_filter
                            )
                        );
                        break;
                    default:
                        break;
                }

                // console.log(pageOrigin);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            ToastDanger('Something went wrong please contact your admin...');
        }
    };
