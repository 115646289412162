import { 
    SET_LOADING, CLEAR_CUSTOMER, GET_SINGLE_CUSTOMER, GET_CUSTOMERS, CUSTOMER_INPUT_CHANGE, 
    SEARCH_INPUT, SET_PAGE, SELECT_COUNTRY, HANDLE_MOBILE, ADD_FILE, CUSTOMER_REMOVE_FILE, CUSTOMER_UPDATE_FILE, 
    SET_MINI_LOADING, GET_CUSTOMER_HISTORY_QUOTATIONS, GET_CUSTOMER_HISTORY_SALES_INVOICES,
    GET_SALES_INVOICE_DATE_FILTER, GET_QUOTATION_DATE_FILTER, CUSTOMER_HISTORY_DATE_INPUT_CLEAR, DATE_FILTER_INPUT,
    GET_CUSTOMER_HISTORY_SINGLE_QUOTATION, GET_CUSTOMER_HISTORY_SINGLE_SALES_INVOICE, SET_DYNAMIC_LOADING, 
    CUSTOMER_UPLOADS_TO_REMOVE, SORT_BY_COLUMN, GET_ROWS_TO_DELETE, SET_ROW_CHECKED, CUSTOMER_GET_UPLOADED_FILES, CUSTOMER_CLEAR_UPLOAD } from "../types";

// state
const initialState = {
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8},
    customers: [],
    single_customer: { 
        name: '',
        business_name: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: {},
        fax: '',
        email: '',
        landline: '',
        mobile: '',
        contact_person: '',
        uploads: [],
        tin : ''
    },
    single_quotation: [],
    single_sales_invoice: [],
    quotations: [],
    sales_invoices: [],
    display_tab: { quotations: false, sales_invoices: false },
    new_uploads: [],
    files_to_remove: [],
    search: '',
    date_from: new Date(),
    date_to: new Date(),
    date_filter: false,
    loading: false,
    isSearchable: true,
    mini_loading: false,
    main_page: false,
    edit_page: false,
    print_modal: false,
    main_url: '/sales/customers',
    create_url: '/sales/customers/create',
    edit_url: '/sales/customers/edit',
    history_url: '/sales/customers/history',
    quotation_url: '/sales/customers/history/quotations',
    sales_invoice_url: '/sales/customers/history/sales-invoices',
    rows_to_delete: null,
    sort_order_name: null,
    sort_order_direction: null,
    set_row_checked: false,
    upload_loading: false,
}

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.customers,
                pagination: action.payload.pagination,
                set_row_checked: false
            }
        case GET_SINGLE_CUSTOMER:
            return {
                ...state,
                single_customer: action.payload
            }
        case CUSTOMER_INPUT_CHANGE:
            return {
                ...state,
                single_customer: { ...state.single_customer, [action.payload.key]: action.payload.value }
            }
        case SELECT_COUNTRY:
            return {
                ...state,
                single_customer: { ...state.single_customer, country: action.payload }
            }
        case HANDLE_MOBILE:
            return {
                ...state,
                single_customer: { ...state.single_customer, mobile: action.payload }
            }
        case SEARCH_INPUT: 
            return {
                ...state,
                search: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload
            }
        case SET_PAGE:
            if(action.payload.page == 'main_page')
            {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                }
            }
            else if(action.payload.page == 'edit_page')
            {
                return {
                    ...state,
                    main_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else if(action.payload.page == 'history_page')
            {
                return {
                    ...state,
                    main_page: false,
                    quotation_page: false,
                    sales_invoice_page: false,
                    quotation_url: '/sales/customers/history/quotations',
                    sales_invoice_url: '/sales/customers/history/sales-invoices',
                    history_url: action.payload.history_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else if(action.payload.page == 'quotation_page')
            {
                return {
                    ...state,
                    main_page: false,
                    history_page: false,
                    history_url: '/sales/customers/history',     
                    quotation_url: action.payload.quotation_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else if(action.payload.page == 'sales_invoice_page')
            {
                return {
                    ...state,
                    main_page: false,
                    history_page: false,
                    history_url: '/sales/customers/history',
                    sales_invoice_url: action.payload.sales_invoice_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status
                }
            }
        case ADD_FILE:
            return {
                ...state,
                single_customer: { ...state.single_customer, uploads: [...state.single_customer.uploads, ...action.payload ] }
            }
        case CUSTOMER_UPDATE_FILE:
            return {
                ...state,
                new_uploads: [...state.new_uploads, ...action.payload ]
            }
        case CUSTOMER_REMOVE_FILE:
            if(action.payload.status == 'old')
            {
                return {
                    ...state,
                    single_customer: { ...state.single_customer, uploads: [...state.single_customer.uploads.filter(file => file.name !== action.payload.name)]}
                }
            }
            else
            {
                return {
                    ...state,
                    new_uploads: [...state.new_uploads.filter(file => file.name !== action.payload.name)]
                }
            }
        case CUSTOMER_UPLOADS_TO_REMOVE:
            return {
                ...state,
                files_to_remove: [...state.files_to_remove, action.payload]
            }
        case CUSTOMER_CLEAR_UPLOAD:
            return {
                ...state,
                new_uploads: []
            }
        case CLEAR_CUSTOMER:
            return {
                ...state,
                single_customer: { uploads:[] },
                new_uploads: [],
                single_quotation: [],
                single_sales_invoice: [],
                quotations: [],
                sales_invoices: [],
                display_tab: {
                    quotations: false,
                    sales_invoices: false
                },
                files_to_remove: [],
                date_from: new Date(),
                date_to: new Date(),
                date_filter: false,
                loading: false,
                mini_loading: false,
                main_page: false,
                edit_page: false,
                print_modal: false,
                main_url: '/sales/customers',
                create_url: '/sales/customers/create',
                edit_url: '/sales/customers/edit',
                history_url: '/sales/customers/history',    
                quotation_url: '/sales/customers/history/quotations',
                sales_invoice_url: '/sales/customers/history/sales-invoices',
                rows_to_delete : null
            }
        /* CUSTOMER HISTORY  BELOW */
        case GET_CUSTOMER_HISTORY_QUOTATIONS:
            return {
                ...state,
                quotations: action.payload.quotations,
                pagination: action.payload.pagination,
                display_tab: { quotations: true, sales_invoices: false },
            }
        case GET_CUSTOMER_HISTORY_SALES_INVOICES:
            return {
                ...state,
                sales_invoices: action.payload.sales_invoices,
                pagination: action.payload.pagination,
                display_tab: { quotations: false, sales_invoices: true },
            }
            case DATE_FILTER_INPUT:
                return {
                   ...state,
                   [action.payload.key]: action.payload.value
                }
            case CUSTOMER_HISTORY_DATE_INPUT_CLEAR:
                return {
                    ...state,
                    date_from: new Date(),
                    date_to: new Date(),
                    date_filter: false,
                    display_tab: { ...state.display_tab, [action.payload]: true },
                }
            case GET_QUOTATION_DATE_FILTER:
                return {
                    ...state,
                    quotations: action.payload.quotations,
                    pagination: action.payload.pagination,
                    display_tab: { quotations: true, sales_invoices: false },
                }
            case GET_SALES_INVOICE_DATE_FILTER:
                return {
                    ...state,
                    sales_invoices: action.payload.sales_invoices,
                    pagination: action.payload.pagination,
                    display_tab: { quotations: false, sales_invoices: true },
                    date_filter: true,
                }
            case GET_CUSTOMER_HISTORY_SINGLE_QUOTATION:
                return {
                    ...state,
                    single_quotation: action.payload,
                }
            case GET_CUSTOMER_HISTORY_SINGLE_SALES_INVOICE:
                return {
                    ...state,
                    single_sales_invoice: action.payload
                }
            case SORT_BY_COLUMN:
                return {
                    ...state,
                    sort_order_name: action.payload.sort_order_name,
                    sort_order_direction: action.payload.sort_order_direction
                }
            case GET_ROWS_TO_DELETE:
                return {
                    ...state,
                    rows_to_delete: action.payload
                }
            case SET_ROW_CHECKED: 
                return {
                    ...state,
                    set_row_checked: true
                }
            case CUSTOMER_GET_UPLOADED_FILES: 
                return {
                    ...state,
                    single_customer: { ...state.single_customer, uploads: action.payload },
                    new_uploads: [],
                    files_to_remove: []
                }
            case SET_DYNAMIC_LOADING:
                return {
                    ...state,
                    [action.payload.key]: action.payload.status
                }
        default:
            return state;
            break;
    }

}

export default customerReducer;


