import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  hideModal,
  supplierSelect,
  setSupplierId,
} from "../../../redux/actions/inventory/inventory.actions";

function InventorySuppler({
  inventory: { show_modal, suppliers },
  hideModal,
  supplierSelect,
  setSupplierId,
}) {
  return (
    <Modal
      centered
      dialogClassName='modal-container'
      show={show_modal.supplier}
      onHide={(e) => hideModal(e, "supplier")}
      backdrop='static'
      size='md'
      animation={true}
    >
      <Modal.Header closeButton>
        {" "}
        <Modal.Title>Select one supplier from the list</Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
            {suppliers.map((data, index) => {
              return (
                <div className='form-group'>
                  <div class='form-check'>
                    <label class='form-check-label'>
                      <input
                        type='radio'
                        className='form-check-input'
                        name='supplier'
                        onChange={() => supplierSelect(data.supplier_id)}
                      />
                      {data.name}
                      {/* <input
                        type='radio'
                        className='form-check-input'
                        name='supplier'
                        onChange={() => supplierSelect(data.supplier_id)}
                      />
                      {data.supplier.name} */}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-grey f-regular btn-md'
          onClick={(e) => hideModal(e, "supplier")}
        >
          Cancel
        </button>
        <button
          className='btn-primary btn f-regular btn-save btn-md'
          onClick={(e) => setSupplierId()}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  inventory: state.inventory,
});

export default connect(mapStateToProps, {
  hideModal,
  supplierSelect,
  setSupplierId,
})(InventorySuppler);
