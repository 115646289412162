import axios from 'axios';
import {auth} from './_user.service';
import { Redirect } from 'react-router-dom';
import { getToken } from '../Utils/Common';
const API_ROOT = process.env.REACT_APP_API_LINK || 'inventorylumen.test/api';

const resources = {};
const token = getToken();
const http = axios.create ({
  baseURL: API_ROOT,
  timeout: 60000,
  headers: {'Content-Type': 'application/json'},
});

http.interceptors.request.use (
  function (config) {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

let cancel;

const makeRequestCreator = () => {
  

  return async (query, formParams) => {
    let key = formParams.search;

    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    try {
      if (resources[key]) {
        return resources[key];
      }

      formParams.cancelToken = cancel.token;

      const res = await axios.post(query, formParams, {
        baseURL: API_ROOT,
        timeout: 60000,
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${token}`},
        cancelToken: cancel.token,
      });
      
      const result = res;
      resources[key] = result;

      return result;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
        // console.log('Request canceled', error.message);
      } else {
        // Handle usual errors
        // console.log('Something went wrong: ', error.message);
      }
    }
  };
};

export const search = makeRequestCreator()