import React, { Fragment, useCallback, useState} from 'react';
import { connect } from 'react-redux';
import {
    uploadImage,
    removeFile,
} from '../../../redux/actions/collection_receipt/collection_receipt.action';
import { useDropzone } from 'react-dropzone';
import { fileExtensionIcon } from '../../../Utils/Common';
function CollectionReceiptUpload({ upload_preview, uploadImage, removeFile, handleHover }) {
    const [uploadedFile, setUploadedFile] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        uploadImage(acceptedFiles);
        setUploadedFile(acceptedFiles[0]); // Assuming you are interested in the first file
    }, [uploadImage]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    // console.log("upload", upload_preview);

    return (
        <>
            {upload_preview ? (
                <Fragment>
                    <div className="text-center item-upload-img p-2">
                        {uploadedFile && (
                            <div
                                onMouseEnter={handleHover}
                                title={uploadedFile.name?.slice(0, 20) + (uploadedFile.name?.length > 20 ? "..." : "")}
                                onMouseLeave={() => handleHover('out')}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div style={{width: "100%"}}>
                                    <span className="file-icon-print">
                                        <img src={fileExtensionIcon(uploadedFile.name)} alt="file-types" />
                                    </span>
                                    <b >
                                        {uploadedFile.name?.slice(0, 20) + (uploadedFile.name?.length > 20 ? "..." : "")}
                                    </b>
                                </div>
                            </div>
                        )}
                        <br />
                        <button
                            onClick={removeFile}
                            className="btn btn-danger mt-2"
                        >
                            Remove File
                        </button>
                    </div>
                </Fragment>
            ) : (
                <div {...getRootProps()} className="cr-dropzone-line-style">
                    <input {...getInputProps()} />
                    <center>
                        <i className="ft ft-upload-cloud cr-upload-icon"></i>
                        <div className="cr-upload-message my-1">
                            Drag & Drop file here.
                            <br />
                        </div>
                        <div className="upload-message-or mb-2">or</div>
                        <div className="btn btn-info">Browse File</div>
                    </center>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    upload_preview: state.collection_receipt.create_payment.upload_preview,
});

export default connect(mapStateToProps, { uploadImage, removeFile })(
    CollectionReceiptUpload
);
