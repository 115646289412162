import {
    SET_LOADING,
    GET_DR_QUOTATION,
    SEARCH_INPUT,
    SI_DATA,
    BOOLEAN_VAR,
    GET_DR,
    HANDLE_SI_SELECT_PAYMENT_TYPE,
    GET_SI_TERMS_CONDITIONS,
    HANDLE_SI_SELECT_TERMS_CONDITIONS,
    HANDLE_SI_SELECT_TYPE_RECEIPT,
    MANUAL,
    HANDLE_SELECT_SI_TEMPLATE,
    GET_SI_TEMPLATES,
    GET_SI_CUSTOMERS,
    GET_QUOTATION_PRODUCTS,
    HANDLE_SI_SELECT_CUSTOMER,
    HANDLE_SI_SELECT_ITEM,
    ADD_ITEM,
    TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    REMOVE_ITEM,
    COMPUTE_DR,
    SORT_BY_COLUMN,
    SET_MODAL,
    MODAL_LOADING,
    CLEAR_DELIVERY,
    DELIVERY_CLEAR_UPLOAD,
    DELIVERY_TAX_SELECT_TYPE,
    DELIVERY_COMPUTATION_HANDLER,
    DELIVERY_INPUT_CHANGE,
    SEND_EMAIL,
    RESEND_EMAIL,
    PRE_LOAD,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    DR_REDIRECT,
    GET_DR_TEMPLATES,
    SELECT_DR_TEMPLATE,
    DYNAMIC_SEND_EMAIL,
    GET_DR_ROWS_TO_DELETE,
    CLEAR_DATA,
    BTN_FORM,
    GET_STATUS,
    GET_DR_STATUS,
    SET_CREATE_SO
} from '../../types';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
} from '../../../Services/_toast.service';
import $, { map } from 'jquery';
import { DeliveryService } from './_service.delivery';
import {
    SwalWarning,
    SwalError,
    SwalSuccessAutoRemove,
    SwallverfiyPass,
    SwalWarningHtml,
} from '../../../Services/_swal.service';
import { DialogTitle } from '@material-ui/core';
import { blockRoute } from '../helper/helper.actions';
import {
    getNumRows,
    settoPrint,
    gettoPrint,
    getUserData,
} from '../../../Utils/Common';
import { getSingleSalesOrder, soClearUpload } from './../sales_order/sales_order.actions';

export const handleChangeStatusView =
  (status_view) => async (dispatch, getState) => {
    dispatch({ type: GET_STATUS, payload: status_view });

    let {  search, dr_data } = getState().delivery;

    if (
      typeof dr_data[status_view] === "undefined" ||
      dr_data[status_view] == null
    ) {
      dispatch({ type: SEARCH_INPUT, payload: "" });
      dispatch(get_dr(1));
    } else {
      dispatch({
        type: SEARCH_INPUT,
        payload: dr_data[status_view].search,
      });
      dispatch({
        type: GET_DR,
        payload: {
          dr_data: dr_data,
          pagination: dr_data[status_view],
        },
      });
    }
  };

export const fetchQuotations = (value) => async () => {
    return DeliveryService.getQuotations({ search: value })
        .then((result) => {
            // transform the data into the format needed by the select component
            return result.data.quotation.data.map((data) => {
                return {
                    label: data.quotation_no,
                    value: data._id,
                    name: 'quotation',
                };
            });
        })
        .catch((err) => {
            ToastDanger('Failed to load Quotation.');
            // return an empty array if there was an error
            return [];
        });
};

export const getQuotations = () => async (dispatch, getState) => {
    const { search } = getState().delivery;
    const formdat = {
        search: search,
    };
    DeliveryService.getQuotations(formdat)
        .then((result) => {
            // console.log("result", result.data.quotation.data);
            const quotation = result.data.quotation.data;
            const arr = [];
            quotation.map((data) => {
                arr.push({
                    label: data.quotation_no,
                    value: data._id,
                    name: 'quotation',
                });
            });

            dispatch({ type: GET_DR_QUOTATION, payload: arr });
        })
        .catch((err) => {
            ToastDanger('Failed to load Quotation.');
        });
};

export const searchQuotation = (value) => async (dispatch) => {
    if (value.length >= 3) {
        fetchQuotations(value).then((results) => {
            dispatch({ type: GET_DR_QUOTATION, payload: results });
        });
    }
};

export const getQuotationItems = (val) => async (dispatch, getState) => {
    if (val) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    // console.log(val)
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: BOOLEAN_VAR, payload: true });
    DeliveryService.getItems(val.value)
        .then((result) => {
            const si_result = result.data.quotation;
            const _discount =
                si_result.discount_type == 1
                    ? (si_result.discount / 100) * si_result.sub_total
                    : si_result.discount;
            let _qty = 0;
            si_result.quotation_items.map((data, index) => {
                _qty += data.qty;
            });

            let { items } = getState().delivery;
            let item_data = [];
            // console.log("si_result", si_result);
            si_result.quotation_items.map((data, key) => {
                // console.log("data", data);
                let temp_item = {
                    items: items,
                    _id: data.product_id,
                    product_id: data.product_id,
                    sku: data.sku,
                    product_name: data.product_name,
                    attribute_string: data.attribute_string,
                    qty: data.qty,
                    product_unit_name: data.product_unit_name,
                    srp: data.srp,
                    unit_price: data.unit_price ? data.unit_price : data.srp,
                    amount: data.amount,
                    product_value: {
                        value: data.product_id,
                        label: data.product_name,
                    },
                    description: data ? data.description : '',
                    brand: data ? data.brand : '',
                    status_code: data ? data.status_code : 0,
                    barcode: data ? data.barcode : '',
                    discount: data.discount,
                    discount_type: data.discount_type
                        ? data.discount_type
                        : { value: null },
                    discount_amount: data.discount_amount
                        ? data.discount_amount
                        : 0,
                    new: true,
                };
                item_data.push(temp_item);
            });
            // console.log("si_result._id", si_result._id);
            const si_data = {
                items: item_data,
                customer: si_result.customer.name,
                customer_id: si_result.customer._id,
                sub_total: si_result.sub_total,
                discount: _discount,
                discount_type: si_result.discount_type,
                tax: si_result.tax,
                vat_amount: si_result.vat_amount ? si_result.vat_amount : 0.0,
                tax_type: si_result.tax_type
                    ? si_result.tax_type
                    : { value: 'none', label: 'None' },
                delivery_fee: si_result.other,
                total: si_result.total,
                remarks: si_result.remarks,
                total_qty: _qty,
                payment_terms: SiCatcher(si_result.payment_terms),
                receipt_type: SiCatcher(si_result.receipt_type),
                payment_mode: SiCatcher(si_result.payment_mode),
                status: '',
                manual_input: false,
                // serial_no: si_result.serial_no,
                quotation_no: si_result.quotation_no,
            };

            // console.log("si_data in getQuotationItems:", si_data);
            // console.log(si_data)

            dispatch({
                type: SI_DATA,
                payload: { data: si_data, _id: si_result._id },
            });
            dispatch({ type: SET_LOADING, payload: false });
            dispatch({ type: BOOLEAN_VAR, payload: false });
            dispatch({ type: PRE_LOAD, payload: true });
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err);
        });
};

const SiCatcher = (data) => {
    if (data === undefined && data == null) {
        data = {
            value: '',
            label: '',
        };
    }

    return data;
};

export const sumbit_delivery =
    (formdata, actions) => async (dispatch, getState) => {
        let { si_data, quotation_id } = getState().delivery;

        if (si_data.delivery_template_id == '') {
            return ToastDanger('Please Choose Delivery Template');
        }

        // if (si_data.manual_input === false && quotation_id === null) {
        //     return ToastDanger(
        //         'Please Choose Quotation or create manually'
        //     );
        // }

        if (si_data.is_selected === false) {
            return ToastDanger(
                'Please select if you want to Generate Sales Order'
            );
        }

        if (si_data.manual_input) {
            quotation_id = null;
        }

        if (si_data.manual_input === true) {
            quotation_id = null;

            // if (si_data.reference_no === '') {
            //     return ToastDanger('Reference NO is required.');
            // }
        }

        if (si_data.items.length ===0){
            return ToastDanger('Please add Product item at least one.');
        }

        si_data.items.map((item) => {
            if (item.srp <= 0) {
                return ToastDanger(
                    'Unit Price cannot be zero value, please include at least the amount'
                );
            }
        });

        formdata['delivery_template_id'] = si_data.delivery_template_id;
        formdata['generate_sales_order'] = si_data.generate_so;
        // console.log('formdata', formdata);
        
        dispatch({ type: SET_LOADING, payload: true });
        DeliveryService.createDR(formdata)
            .then((result) => {
                dispatch({ type: PRE_LOAD, payload: false });
                dispatch(clearDelivery());
                if (actions == 'print') {
                    settoPrint(true, result.data.id, 'Delivery');
                }

                window.location.href = '/deliveries';
            })
            .catch((err) => {
                if (err.data.errors.length > 0) {
                    dispatch({ type: SET_LOADING, payload: false });
                    ToastDanger(err.data.errors);
                } else {
                    ToastDanger('Failed create new Delivery Receipt');
                }
            });
    };

export const update_delivery =
    (formdata, actions) => async (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        const { delivery_template_id, generate_so } = getState().delivery.si_data;

        if (delivery_template_id == '') {
            return ToastDanger('Please Choose Delivery Template');
        }

        formdata['delivery_template_id'] = delivery_template_id;
        if (generate_so != null) {
            formdata.si_data.generate_sales_order = generate_so; 
        }
        // console.log("formdata", formdata)
        DeliveryService.updateDR(formdata)
            .then((result) => {
                dispatch({ type: PRE_LOAD, payload: false });
                // dispatch(drRedirect(true));
                dispatch(clearDelivery());
                if (actions == 'print') {
                    settoPrint(true, result.data.id, 'Delivery');
                }
                window.location.href = '/deliveries';
            })
            .catch((err) => {
                if (err.data.errors.length > 0) {
                    dispatch({ type: SET_LOADING, payload: false });
                    ToastDanger(err.data.errors);
                } else {
                    ToastDanger('Failed create new Delivery Receipt');
                }
            });
    };

export const drRedirect =
    (status = false) =>
    async (dispatch) => {
        dispatch({ type: DR_REDIRECT, payload: status });
    };

export const get_dr =
    (pageNumber, rows_per_page = null, pageOrigin = null) =>
    async (dispatch, getState) => {
        let { search, pagination, pagination_list, status_view, sort_order_name, sort_order_direction } =
            getState().delivery;

        let rows_per_page_val =
            (rows_per_page != null ? rows_per_page : getNumRows('delivery')) ||
            10;

        // const formdata = {
        //   search: search,
        //   activePage: pagination.activePage,
        //   page: pageNumber,
        //   order_direction: sort_order_direction,
        //   order_name: sort_order_name,
        //   paginate: rows_per_page_val, // rows_per_page
        //   product_id: pageOrigin ? pageOrigin["product_id"] : null, // for inventory_view param
        // };

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let formdata = {
            search,
            options: {
                page: pageNumber,
                limit: rows_per_page_val,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        if (pageOrigin) {
            switch (pageOrigin['origin']) {
                case 'inventory_view':
                    formdata.product_id = pageOrigin['product_id'];
                    break;
                case 'customer_history':
                    formdata.customer_id = pageOrigin['customer_id'];
                default:
                    break;
            }
        }

        DeliveryService.getDelivery(formdata, status_view)
            .then((res) => {
                let data_list = [];
                res.data.docs.map((data) => {
                data_list.push(data);
                });

                pagination_list[status_view] = data_list;
                
                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit,
                    totalItemsCount: res.data.total_docs,
                };

                // console.log("dr_data", dr_data);
                dispatch({
                    type: GET_DR_STATUS,
                    payload: { pagination_list: pagination_list, pagination: pagination, dr_data: res.data.docs },
                });
                dispatch(drRedirect(false));
            })
            .catch((err) => {});
    };

export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
        dispatch(get_dr(null, null, pageOrigin));
    };

export const handleSearchInputMUI =
    (e, pageOrigin = null) =>
    async (dispatch) => {
        dispatch({ type: SEARCH_INPUT, payload: e.target.value });
        if (e.key === 'Enter') {
            console.log('enter');
            dispatch(get_dr(1, null, pageOrigin));
        }
    };

// handle selec payment type
export const handleSelectPaymentType = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({ type: HANDLE_SI_SELECT_PAYMENT_TYPE, payload: selectOption });
};

// get all the payment_terms and conditions
export const getTermsConditions = () => async (dispatch) => {
    DeliveryService.getTermsConditions()
        .then((res) => {
            let terms_arr = [];
            let payment_terms = res.data.terms_conditions;

            payment_terms.map((val, i) => {
                let select = { value: val.content, label: val.title };
                terms_arr[i] = select;
            });
            terms_arr.unshift({ value: '', label: 'No Terms' });

            dispatch({ type: GET_SI_TERMS_CONDITIONS, payload: terms_arr });
        })
        .catch((error) => {
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
};

// handle select payment payment_terms
export const handleSelectTermsConditions =
    (selectOption) => async (dispatch) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }
        dispatch({
            type: HANDLE_SI_SELECT_TERMS_CONDITIONS,
            payload: selectOption,
        });
    };

export const handleSelectReceiptType = (selectedOption) => async (dispatch) => {
    if (selectedOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }
    dispatch({ type: HANDLE_SI_SELECT_TYPE_RECEIPT, payload: selectedOption });
    dispatch(enable_submit_btn());
};

export const checkBoxManual = (e) => async (dispatch, getState) => {
    if (e) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { si_data, item_template } = getState().delivery;

    let arr = [];
    let checked = e.target.checked;

    //console.log("item_template", item_template);
    arr.push(item_template);
    if (!checked) {
        arr = [];
    }
    if (si_data.items.length == 0) {
        dispatch({ type: MANUAL, payload: { check: checked, items: arr } });
        dispatch({ type: BOOLEAN_VAR, payload: true });
    } else {
        SwalWarning(
            'Warning!',
            `Selected item(s) will be overwritten, continue?`,
            () => {
                dispatch({ type: BOOLEAN_VAR, payload: true });
                dispatch({
                    type: MANUAL,
                    payload: { check: checked, items: arr },
                });
            },
            console.log('cancelled')
        );
    }
};

export const handleCreateSO = (selectedOption) => async (dispatch, getState) => {
    const selectedValue = selectedOption.value; 
    const isSelected = true; 
    const generateSO = selectedValue === 'yes'; 
  
    dispatch({
      type: SET_CREATE_SO,
      payload: {
        is_selected: isSelected,
        generate_so: generateSO,  
      },
    });
  };
  

const item_manual = (items) => {
    let data = {
        options: items,
        _id: null,
        sku: '',
        product_name: '',
        attribute_string: '',
        qty: 1,
        product_unit_name: '',
        srp: 0,
        amount: 0,
        selected: false,
        product_value: { value: '', label: 'Choose Product' },
    };

    return data;
};

export const addItem = (e) => async (dispatch, getState) => {
    if (e) {
      dispatch(blockRoute(true));
    } else {
      dispatch(blockRoute(false));
    }
  
    let { items, si_data } = getState().delivery;
    let data_items = si_data.items;

    let errors = false;
    data_items.map((item) => {
        if (item.qty <= 0) {
            ToastDanger('Quantity cannot be zero value, please add at least 1');
            errors = true;
        }
        if (item.srp <= 0) {
            ToastDanger(
                'Unit Price cannot be zero value, please include at least the amount'
            );
            errors = true;
        }
    });


    let data = item_manual(items);
    data_items.push(data);
    dispatch({ type: ADD_ITEM, payload: data_items });
  };
  

export const remItem = (key) => async (dispatch, getState) => {
    let { si_data } = getState().delivery;
    let data_items = si_data.items;
    data_items.splice(key, 1);
    dispatch({ type: ADD_ITEM, payload: data_items });
    dispatch(computationHandler());

    dispatch({ type: COMPUTE_DR, payload: si_data });
};

// // handle select sales invoice templates
// export const handleSelectSalesInvoiceTemplate = selectedOption => async dispatch => {

//     if(selectedOption){
//         dispatch(blockRoute(true));
//     }else{
//         dispatch(blockRoute(false));
//     }

//     let invoice_template_id = selectedOption.value;
//     let inv_default_template = { value: selectedOption.value, label: selectedOption.label };

//     dispatch({ type: HANDLE_SELECT_SI_TEMPLATE, payload: { inv_default_template: inv_default_template, invoice_template_id: invoice_template_id } });
// }

export const getDeliveryTemplates = () => async (dispatch) => {
    try {
        const res = await DeliveryService.getDeliveryTemplates();

        const dr_templates = res.data.delivery_templates;
        const dr_default_template = {
            value: res.data.selected_template._id,
            label: res.data.selected_template.template_name,
        };
        const delivery_template_id = res.data.delivery_template_id;

        let delivery_templates = [];
        dr_templates.map((val, i) => {
            let select = {
                value: val._id,
                label: val.template_name,
                name: 'delivery_template_id',
            };
            delivery_templates[i] = select;
        });

        dispatch({
            type: GET_DR_TEMPLATES,
            payload: {
                delivery_templates,
                dr_default_template,
                delivery_template_id,
            },
        });
    } catch (err) {
        ToastDanger('Network error, please reload the page.');
        $('.btn-remove').removeAttr('disabled').html('Remove');
        console.log(err);
    }
};

export const handleSelectDRTemplate = (selectedOption) => async (dispatch) => {
    if (selectedOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    console.log(selectedOption)

    const template = {
        value: selectedOption.value,
        label: selectedOption.label
    }

    const payload = {
        delivery_template_id: selectedOption.value,
        dr_default_template: template,
    };

    console.log(payload)

    dispatch({ type: SELECT_DR_TEMPLATE, payload });
};

// get all the select options
// export const getCustomers = (default_val) => async (dispatch) => {
//   DeliveryService.getCustomers()
//     .then((res) => {
//       let customers_arr = [];
//       let customers = res.data.customers.data;

//       customers.map((val, i) => {
//         let select = { value: val._id, label: val.name, name: "customer_id" };
//         customers_arr[i] = select;
//       });

//       if (default_val !== undefined) {
//         // console.log("default_val", default_val);
//         dispatch({
//           type: HANDLE_SI_SELECT_CUSTOMER,
//           payload: {
//             customer_id: default_val.value,
//             customer: default_val.label,
//             default_customer_value: default_val,
//           },
//         });
//       }

//       dispatch({ type: GET_SI_CUSTOMERS, payload: customers_arr });
//     })
//     .catch((error) => {
//       ToastDanger("Network error, please reload the page.");
//       console.log(error);
//     });
// };

// get all the select options
export const getCustomers = () => async (dispatch) => {
    DeliveryService.getCustomers('')
        .then((res) => {
            let customers_arr = [];
            let customers = res.data.customers.data;

            customers.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.name,
                    name: 'customer_id',
                };
                customers_arr[i] = select;
            });

            dispatch({ type: GET_SI_CUSTOMERS, payload: customers_arr });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                error.data.errors.map((err) => ToastDanger(err));
              } else {
                 ToastDanger("Network error, please reload the page.");
              }
            console.log(error);
        });
};

// get all the select options
export const searchCustomers = (search) => async (dispatch) =>
    new Promise((resolve, reject) => {
        DeliveryService.getCustomers(search)
            .then((res) => {
                let customers_arr = [];
                let customers = res.data.customers.data;

                customers.map((val, i) => {
                    let select = {
                        value: val._id,
                        label: val.name,
                        name: 'customer_id',
                    };
                    customers_arr[i] = select;
                });
                resolve(customers_arr);
                // dispatch({ type: GET_SI_CUSTOMERS, payload: customers_arr });
            })
            .catch((error) => {
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
                reject(error);
            });
    });

// // get the products
// export const getProducts =
//   (search = "") =>
//   async (dispatch) => {
//     const formdata = {
//       search: search,
//     };
//     DeliveryService.getProducts(formdata)
//       .then((res) => {
//         let items_arr = [];
//         let items = res.data.products.data;
//         // console.log("items", items);
//         items.map((item, i) => {
//           let attribute = "";
//           try {
//             if (
//               item.attribute_string === "()" ||
//               item.attribute_string === null
//             ) {
//               attribute = "";
//             } else {
//               let attr_replace_char = item.attribute_string
//                 .replace("(", "")
//                 .replace(")", "");
//               attribute =
//                 attr_replace_char.slice(0, 40) +
//                 (attr_replace_char.length > 40 ? "..." : "");
//             }
//           } catch (err) {}

//           let select = {
//             _id: item._id,
//             value: item._id,
//             label: item.product_name,
//             sub_label: attribute,
//             name: "item",
//             product_name: item.product_name,
//             barcode: item.barcode,
//             sku: item.sku,
//             qty: 1,
//             product_unit_id: item.product_unit_id ? item.product_unit_id : "",
//             product_unit_name:
//               item.product_unit !== null ? item.product_unit.name : "",
//             attribute_string: item.attribute_string,
//             srp: item.srp,
//             amount: item.srp,
//             description: item.description,
//             new: false,
//             status_code: item.status_code,
//           };

//           items_arr[i] = select;
//         });

//         dispatch({ type: GET_QUOTATION_PRODUCTS, payload: items_arr });
//       })
//       .catch((error) => {
//         ToastDanger("Network error, please reload the page.");
//         console.log(error);
//       });
//   };

// export const searchProducts = (search, index) => (dispatch) =>
//   new Promise((resolve, reject) => {
//     // do something was a success
//     const formdata = {
//       search: search,
//     };
//     DeliveryService.getProducts(formdata)
//       .then((res) => {
//         let items_arr = [];
//         let items = res.data.products.data;

//         items.map((item, i) => {
//           let attribute = "";
//           try {
//             if (
//               item.attribute_string === "()" ||
//               item.attribute_string === null
//             ) {
//               attribute = "";
//             } else {
//               let attr_replace_char = item.attribute_string
//                 .replace("(", "")
//                 .replace(")", "");
//               attribute =
//                 attr_replace_char.slice(0, 40) +
//                 (attr_replace_char.length > 40 ? "..." : "");
//             }
//           } catch (err) {}

//           let select = {
//             _id: item._id,
//             value: item._id,
//             label: item.product_name,
//             sub_label: attribute,
//             name: "item",
//             product_name: item.product_name,
//             barcode: item.barcode,
//             sku: item.sku,
//             qty: 1,
//             product_unit_id: item.product_unit_id ? item.product_unit_id : "",
//             product_unit_name:
//               item.product_unit !== null ? item.product_unit.name : "",
//             attribute_string: item.attribute_string,
//             srp: item.srp,
//             amount: item.srp,
//             description: item.description,
//             new: false,
//             status_code: item.status_code,
//           };

//           items_arr[i] = select;
//         });
//         resolve(items_arr);
//       })
//       .catch((error) => {
//         ToastDanger("Network error, please reload the page.");
//         console.log(error);
//         reject();
//       });

//     // do something was a fail
//   });

// get the products
export const getProducts =
    (search = '') =>
    async (dispatch) => {
        const formdata = {
            search: search,
        };
        DeliveryService.getProducts(formdata)
            .then((res) => {
                let items_arr = [];
                let items = res.data.products.data;
                items.map((item, i) => {
                    // console.log("items", items);
                    let attribute = '';
                    if (
                        item.attribute_string == '()' ||
                        item.attribute_string === null
                    ) {
                        attribute = '';
                    } else {
                        try {
                            let attr_replace_char = item.attribute_string
                                .replace('(', '')
                                .replace(')', '');
                            attribute =
                                attr_replace_char.slice(0, 40) +
                                (attr_replace_char.length > 40 ? '...' : '');
                        } catch (err) {
                            attribute = '';
                        }
                    }

                    let select = {
                        _id: item._id,
                        value: item._id,
                        label: item.product_name,
                        sub_label: attribute,
                        name: 'item',
                        barcode: item.barcode,
                        product_name: item.product_name,
                        brand: item.brand,
                        sku: item.sku,
                        qty: 1,
                        instock: item.instock,
                        product_unit_id: item.product_unit_id
                            ? item.product_unit_id
                            : '',
                        product_unit_name: item.product_unit
                            ? item.product_unit.name
                            : '',
                        attribute_string: item.attribute_string,
                        srp: item.srp,
                        amount: item.srp,
                        description: item.description,
                        lot_no:
                            item.single_lot != null &&
                            item.single_lot != undefined
                                ? item.single_lot.lot_no
                                : '',
                        status_code: item.status_code,
                    };

                    items_arr[i] = select;
                });
                // console.log("items_arr", items_arr);

                dispatch({ type: GET_QUOTATION_PRODUCTS, payload: items_arr });
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                  } else {
                     ToastDanger("Network error, please reload the page.");
                  }
                console.log(error);
            });
    };

export const searchProducts =
    (search, key, new_item_template) => (dispatch, getState) =>
        new Promise((resolve, reject) => {
            // do something was a success
            const formdata = {
                search: search,
            };
            DeliveryService.getProducts(formdata)
                .then((res) => {
                    let items_arr = [];
                    let items = res.data.products.data;

                    items.forEach((item, i) => {
                        let attribute = '';
                        try {
                            let attr_replace_char = item.attribute_string
                                .replace('(', '')
                                .replace(')', '');
                            attribute =
                                attr_replace_char.slice(0, 40) +
                                (attr_replace_char.length > 40 ? '...' : '');
                        } catch (err) {
                            attribute = '';
                        }

                        // console.log("item", item);
                        let select = {
                            _id: item._id,
                            value: item._id,
                            label: item.product_name,
                            sub_label: attribute,
                            name: 'item',
                            barcode: item.barcode,
                            brand: item.brand,
                            brand_id: item.brand_id,
                            product_name: item.product_name,
                            sku: item.sku,
                            qty: 1,
                            instock: item.instock,
                            product_unit_name: item ? item.product_unit_name : '', 
                            product_unit_id: item.product_unit_id,
                            category: item.category,
                            category_id: item.category_id,
                            attribute_string: item.attribute_string,
                            srp: item.srp,
                            amount: item.srp,
                            status_code: item.status_code,
                            description: item.description,
                        };

                        //items_arr[i] = select;
                        items_arr.push(select);
                    });
                    resolve(items_arr);
                })
                .catch((error) => {
                    // ToastDanger("Network error, please reload the page.");
                    console.log(error);
                    reject();
                });

            // do something was a fail
        });

export const handleOnInputChange = (search) => async (dispatch) => {
    const formdata = {
        search: search,
    };

    DeliveryService.getProducts(formdata)
        .then((res) => {
            let items_arr = [];
            let items = res.data.products.data;
            items.map((item, i) => {
                // console.log("item", item);
                let attribute = '';
                if (
                    item.attribute_string == '()' ||
                    item.attribute_string === null
                ) {
                    attribute = '';
                } else {
                    let attr_replace_char = item.attribute_string
                        .replace('(', '')
                        .replace(')', '');
                    attribute =
                        attr_replace_char.slice(0, 40) +
                        (attr_replace_char.length > 40 ? '...' : '');
                }

                let select = {
                    _id: item._id,
                    value: item._id,
                    label: item.product_name,
                    sub_label: attribute,
                    name: 'item',
                    barcode: item.barcode,
                    brand: item.brand,
                    product_name: item.product_name,
                    sku: item.sku,
                    qty: 1,
                    instock: item.instock,
                    product_unit_id: item.product_unit_id
                        ? item.product_unit_id
                        : '',
                    product_unit_name: item.product_unit
                        ? item.product_unit.name
                        : '',
                    attribute_string: item.attribute_string,
                    srp: item.srp,
                    amount: item.srp,
                };

                items_arr[i] = select;
            });
            // console.log("items_arr", items_arr);

            dispatch({ type: GET_QUOTATION_PRODUCTS, payload: items_arr });
            // callback(items_arr);

            return items_arr;
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            console.log(error);
        });
};

export const handleSelectCustomer = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    DeliveryService.getSingleCustomer(selectOption.value)
        .then((res) => {
            let default_customer_value = {
                value: res.data.customer._id,
                label: res.data.customer.name,
            };

            dispatch({
                type: HANDLE_SI_SELECT_CUSTOMER,
                payload: {
                    customer_id: selectOption.value,
                    customer: res.data.customer.name,
                    default_customer_value: default_customer_value,
                },
            });
            dispatch(enable_submit_btn());
        })
        .catch((error) => {
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
};

// handle Select Item
export const handleSelectItem =
    (selectOption, key) => async (dispatch, getState) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        let { si_data } = getState().delivery;
        let items = { ...si_data.items[key] };

        let default_product_value = {
            value: selectOption._id,
            label: selectOption.product_name,
        };
        
        const isProductAlreadySelected = si_data.items.some((item, i) => i !== key && item.product_id === selectOption._id);

        if (isProductAlreadySelected ) {
            // Display an error message (You can replace this with your preferred way of showing errors)
            ToastDanger("Product is already added on the list");
              // Remove the row
              dispatch(remItem(key));
        } else if (selectOption.instock === false){
            ToastWarning("Product is out of stock");
            // Remove the row
             dispatch(remItem(key));
        } else {
        items['product_value'] = default_product_value;
        items['sku'] = selectOption.sku;
        items["product_unit_id"] = selectOption.product_unit_id;
        items["product_unit_name"] = selectOption.product_unit_name;
        items['srp'] = parseFloat(selectOption.srp);
        items['amount'] =
            parseFloat(selectOption.srp) * parseFloat(items['qty']);
        items['_id'] = selectOption._id;
        items['product_id'] = selectOption._id;
        items['status_code'] = selectOption.status_code;
        items['selected'] = true;
        items['barcode'] = selectOption.barcode;
        items['brand'] = selectOption.brand;
        items["brand_id"] = selectOption.brand_id;
        items["category_id"] = selectOption.category_id;
        items["category"] = selectOption.category;
        items['description'] = selectOption.description;
        items['product_name'] = selectOption.product_name;
        items['discount_type'] = { label: 'None', value: 'none' }; // default discount type
        items['discount'] = 0; // default discount
        items['discount_amount'] = 0;
        si_data.items[key] = items;

        dispatch({ type: HANDLE_SI_SELECT_ITEM, payload: si_data.items });
        dispatch({ type: COMPUTE_DR, payload: compute_dr(si_data) });
        dispatch(enable_submit_btn());
        }
    };

export const ShowComboBox = (key) => async (dispatch, getState) => {
    let { si_data } = getState().delivery;
    let items = si_data.items;
    items[key]['selected'] = false;
    dispatch({ type: HANDLE_SI_SELECT_ITEM, payload: items });
};

export const changeQty = (e, key) => async (dispatch, getState) => {
    let { name, value } = e.target;

    let { si_data } = getState().delivery;
    let items = { ...si_data.items[key] };
    items[name] = parseFloat(value);
    items.amount = parseFloat(items.qty) * parseFloat(items.srp);

    if (items.discount_type && items.discount_type.value !== 'none') {
        let discountAmount = 0;
        if (items.discount_type.value === 'percent') {
            discountAmount = (items.discount / 100) * items.amount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = items.discount;
        }
        items.amount = items.amount - discountAmount;
        items.discount_amount = discountAmount ? discountAmount : 0;
    }

    si_data.items[key] = items;
    dispatch({ type: ADD_ITEM, payload: si_data.items });

    dispatch({ type: HANDLE_SI_SELECT_ITEM, payload: items });
    dispatch(computationHandler());

    // Add the delivery fee to the total after calling the computationHandler()
    si_data.total += parseFloat(si_data.delivery_fee);
    dispatch({ type: COMPUTE_DR, payload: si_data });
    dispatch(enable_submit_btn());
};

//** old */
// const compute_dr = (si_data) => {
//   let subtotal = 0,
//     total_qty = 0;
//   si_data.items.map((data, key) => {
//     if (data._id !== null) {
//       subtotal += parseFloat(NaN2zero(data.amount));
//       total_qty += parseFloat(NaN2zero(data.qty));
//     }
//   });
//   // console.log("si_data.discount_type", si_data.discount_type);
//   let discount;
//   switch (si_data.discount_type) {
//     case "":
//       discount = 0;
//       break;
//     case "none":
//       discount = 0;
//       break;
//     case "amount":
//       discount = si_data.discount;
//       break;
//     case "percent":
//       discount = (si_data.discount / 100) * subtotal;
//       break;
//     default:
//       discount = 0;
//       break;
//   }
//   // let discount    = si_data.discount_type == '' ? 0 : si_data.discount_type == 'none' ? 0 : si_data.discount_type == 'amount' ? si_data.discount : ((si_data.discount / 100) * subtotal);
//   si_data.subtotal = subtotal;
//   si_data.total_qty = total_qty;
//   let vat =
//     (parseFloat(NaN2zero(si_data.vat)) / 100) *
//     (subtotal - parseFloat(NaN2zero(discount)));
//   si_data.total =
//     NaN2zero(subtotal) +
//     parseFloat(NaN2zero(blank2Zero(si_data.delivery_fee))) +
//     vat -
//     parseFloat(NaN2zero(blank2Zero(discount)));
//   return si_data;
// };

//** MERN */
const compute_dr = (si_data) => {
    let subtotal = 0,
        total_qty = 0;
    si_data.items.map((data, key) => {
        if (data._id !== null) {
            subtotal += parseFloat(NaN2zero(data.amount));
            total_qty += parseFloat(NaN2zero(data.qty));
        }
    });

    // Ensure the discount value is a number
    let discountValue = parseFloat(NaN2zero(si_data.discount));

    let discount;
    switch (si_data.discount_type) {
        case '':
            discount = 0;
            break;
        case 'none':
            discount = 0;
            break;
        case 'amount':
            discount = discountValue;
            break;
        case 'percent':
            discount = (discountValue / 100) * subtotal;
            break;
        default:
            discount = 0;
            break;
    }

    // Update the previous delivery fee value
    si_data.delivery_fee_prev = parseFloat(
        NaN2zero(blank2Zero(si_data.delivery_fee))
    );

    si_data.subtotal = subtotal;
    si_data.total_qty = total_qty;

    return si_data;
};

export const vat_change = (e) => async (dispatch, getState) => {
    if (e.target.checked) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let check = e.target.checked;
    let { si_data } = getState().delivery;
    si_data.vat = check ? 12 : 0;
    dispatch(computationHandler());

    // Add the delivery fee to the total after calling the computationHandler()
    si_data.total += parseFloat(si_data.delivery_fee);
    dispatch({ type: COMPUTE_DR, payload: si_data });
};

export const deliveryFee = (e) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { si_data } = getState().delivery;
    si_data.delivery_fee = parseFloat(e.target.value); // Parse the delivery fee as a float
    dispatch(computationHandler());
};

export const discountType = (e) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { si_data } = getState().delivery;
    si_data.discount_type = e.target.value;
    dispatch(computationHandler());

    // Add the delivery fee to the total after calling the computationHandler()
    si_data.total += parseFloat(si_data.delivery_fee);
    dispatch({ type: COMPUTE_DR, payload: si_data });
};

export const discountInput = (e) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { si_data } = getState().delivery;
    si_data.discount = e.target.value;
    dispatch(computationHandler());

    // Add the delivery fee to the total after calling the computationHandler()
    si_data.total += parseFloat(si_data.delivery_fee);
    dispatch({ type: COMPUTE_DR, payload: si_data });
};

export const refNo = (e) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }
    let { si_data } = getState().delivery;
    si_data.reference_no = e.target.value;
    dispatch({ type: COMPUTE_DR, payload: si_data });
};

export const remarksInput = (e) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { si_data } = getState().delivery;
    si_data.remarks = e.target.value;
    dispatch({ type: COMPUTE_DR, payload: si_data });
};

const NaN2zero = (val) => {
    if (isNaN(val)) {
        val = 0;
    }
    return val;
};

const blank2Zero = (val) => {
    if (val == '' || val == null) {
        val = 0;
    }
    return val;
};

const enable_submit_btn = (boolean) => async (dispatch, getState) => {
    let { si_data } = getState().delivery;
    let disabled = true;
    if (
        si_data.items.length > 0 &&
        si_data.customer_id !== null &&
        si_data.total_qty > 0 &&
        si_data.receipt_type.value !== ''
    ) {
        disabled = false;
    }
    dispatch({ type: BOOLEAN_VAR, payload: disabled });
};

export const viewDr = (id) => async (dispatch, getState) => {
    if (id !== null || id !== '') {
        dispatch({ type: MODAL_LOADING, payload: true });
        DeliveryService.getDrData(id)
            .then((result) => {
                //console.log(result.data);
                // console.log(result.data.dr.template_setting);

                let { items } = getState().delivery;
                let data_items = [];
                let subs_setting = result.data.subs_setting;
                let dr = result.data.dr;
                let dr_default_template = [];

                //console.log('data', dr)

                if (dr.template_setting) {
                    dr_default_template = {
                        value: dr.template_setting._id,
                        label: dr.template_setting.template_name
                            ? dr.template_setting.template_name
                            : dr.template.template_name,
                    };
                }

                // console.log("test", result.data.dr.items);
                // console.log("TAX", result.data);
                result.data.dr.delivery_items.map((data, key) => {
                    let temp = {
                        options: items,
                        _id: data.product_id,
                        sku: data.sku,
                        product_name: data.product.product_name,
                        attribute_string: data.product.attribute_string,
                        qty: data.qty,
                        description: data.product.description,
                        brand: data.brand,
                        brand_id: data.brand_id ?? null,
                        // product_unit_name: data.product.product_unit
                        //   ? data.product.product_unit.name
                        //   : "",
                        product_id: data.product_id,
                        product_unit_name: data.product_unit_name,
                        product_unit_id: data.product_unit_id ?? null,
                        category: data.category ?? null,
                        category_id: data.category_id ?? null,
                        srp: data.srp,
                        unit_price: data.unit_price
                            ? data.unit_price
                            : data.srp,
                        amount: data.amount,
                        product_value: {
                            value: data.product_id,
                            label: data.product.product_name,
                        },
                        new: true,
                        status_code: data.product.status_code,
                        barcode: data.product ? data.product.barcode : '',
                        description: data.product
                            ? data.product.description
                            : '',
                        discount: data.discount,
                        discount_type: data.discount_type
                            ? data.discount_type
                            : { value: null },
                        discount_amount: data.discount_amount
                            ? data.discount_amount
                            : 0,
                    };
                    data_items.push(temp);
                });

                let data_dr = result.data.dr;

                // Convert all numeric values to numbers
                data_dr.sub_total = parseFloat(data_dr.sub_total);
                data_dr.discount = parseFloat(data_dr.discount);
                data_dr.vat_amount = data_dr.vat_amount
                    ? parseFloat(data_dr.vat_amount)
                    : 0.0;
                data_dr.tax = data_dr.tax ? parseFloat(data_dr.tax) : 0;
                data_dr.delivery_fee = parseFloat(data_dr.delivery_fee);
                data_dr.total = parseFloat(data_dr.total);

                let si_num = '';

                if (
                    data_dr.quotation !== undefined &&
                    data_dr.quotation != null
                ) {
                    // si_num =
                    //   data_dr.quotation.serial_no !== undefined
                    //     ? data_dr.quotation.serial_no
                    //     : "";
                    si_num =
                        data_dr.quotation.quotation_no !== undefined
                            ? data_dr.quotation.quotation_no
                            : '';
                }

                let si_data = {
                    items: data_items,
                    customer: data_dr.customer != null ? data_dr.customer : '',
                    customer_id: data_dr.customer_id,
                    customer_data: {
                        value: data_dr.customer_id,
                        label:
                            data_dr.customer != null
                                ? data_dr.customer.name
                                : '',
                    },
                    sub_total: data_dr.sub_total,
                    generate_sales_order: data_dr.generate_sales_order,
                    is_selected: data_dr.generate_sales_order !== null,
                    discount: data_dr.discount,
                    discount_type: data_dr.discount_type,
                    vat_amount: data_dr.vat_amount ? data_dr.vat_amount : 0.0,
                    tax: data_dr.tax ? data_dr.tax : 0,
                    tax_type: data_dr.tax_type,
                    delivery_fee: data_dr.delivery_fee,
                    total: data_dr.total,
                    remarks: data_dr.remarks,
                    total_qty: data_dr.total_qty,
                    payment_terms: data_dr.payment_terms,
                    receipt_type: data_dr.receipt_type,
                    payment_mode: data_dr.payment_mode,
                    status: data_dr.status,
                    reference_no: data_dr.reference_no, // reference should be from SI
                    reference_id: data_dr.reference_id,
                    manual_input: data_dr.is_manual,
                    // si_no: data_dr.si_num,
                    dr_id: data_dr._id,
                    delivery_date_string: data_dr.delivery_date,
                    // delivery_date_string: result.data.delivery_date,
                    dr_date: new Date(data_dr.delivery_date),
                    delivery_receipt_no: data_dr.delivery_receipt_no,
                    dr_status: data_dr.status,
                    latest_dr_no: result.data.latest_dr_no,
                    oldest_dr_no: result.data.oldest_dr_no,
                    subs_setting: subs_setting,
                    uploads: data_dr.uploads,
                    logo: data_dr.logo,
                    email_sent:
                        data_dr.email_sent != undefined
                            ? data_dr.email_sent
                            : false,
                    user_prepared_by: data_dr.user_prepared_by,
                    user_checked_by: data_dr.user_checked_by,
                    delivery_template_id: data_dr.delivery_template_id,
                    dr_default_template,
                    dr_default_template,
                    template_setting: data_dr.template_setting,
                    // template_setting: template_setting,
                };

                let default_customer_value = {
                    value:
                        data_dr.customer._id != null
                            ? data_dr.customer._id
                            : '',
                    label:
                        data_dr.customer != null ? data_dr.customer.name : '',
                };

                dispatch({ type: MODAL_LOADING, payload: false });
                dispatch({ type: COMPUTE_DR, payload: si_data });
                dispatch({ type: BOOLEAN_VAR, payload: false });

                dispatch({
                    type: HANDLE_SI_SELECT_CUSTOMER,
                    payload: {
                        customer_id: data_dr.customer_id,
                        customer:
                            data_dr.customer != null ? data_dr.customer : '',
                        default_customer_value: default_customer_value,
                    },
                });
                // console.log("result.data", result.data);
            })
            .catch((err) => {
                dispatch({ type: MODAL_LOADING, payload: false });
                console.log(err);
            });
    }
};

export const delDr = (id) => async (dispatch, getState) => {
    DeliveryService.delDrData(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            window.location.reload();
        })
        .catch((err) => {
            ToastDanger(err.data.message);
        });
};

export const setModal = (modal, status, id) => async (dispatch) => {
    dispatch(viewDr(id));
    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
    settoPrint(false, null, 'Delivery');
    if (status === false) {
        dispatch(delClearUpload());
        // dispatch(clearDelivery());
    }
};

export const setModalSo = (modal,  id, status) => async (dispatch) => {
    dispatch(getSingleSalesOrder(id));
    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
    settoPrint(false, null, 'Sales Order');
    if (status === false) {
        dispatch(soClearUpload());
        // dispatch(clearDelivery());
    }
};


export const delClearUpload = () => async (dispatch) =>
    dispatch({ type: DELIVERY_CLEAR_UPLOAD });

export const Print = (id) => async (dispatch) => {
    // console.log('print_id', id);
    DeliveryService.getPrint(id)
        .then((res) => {
            // let newBlob = new Blob([res.data], {type:"application/pdf"});
            // var arrBuffer = dispatch(base64ToArrayBuffer([res.data]));
            let newBlob = new Blob([res.data], { type: 'application/pdf' });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            const url = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            let current_datetime = new Date();
            let formatted_date =
                ('0' + (current_datetime.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + current_datetime.getDate()).slice(-2) +
                '-' +
                current_datetime.getFullYear();
            let file_name = 'deliveries-' + formatted_date + '.pdf';
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            window.open(link.href, '_blank', 'noopener,noreferrer');
            // link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
        })
        .catch((err) => {
            console.log(err);
        });
};

// handle prev, next page of modals
export const goPage = (direction) => async (dispatch, getState) => {
    dispatch({ type: MODAL_LOADING, payload: true });

    let { si_data } = getState().delivery;

    let params = { direction, _id: si_data.dr_id };

    try {
        const go_to = await DeliveryService.goPage(params);
        dispatch(viewDr(go_to.data._id));
    } catch (e) {
        console.log(e);
        ToastDanger('Server Error');
        dispatch({ type: MODAL_LOADING, payload: false });
    }
};

export const clearDelivery = () => async (dispatch) =>
    dispatch({ type: CLEAR_DELIVERY });

export const handleDiscountItem =
    (key, remove = false) =>
    async (dispatch, getState) => {
        let { si_data } = getState().delivery;
        let items = { ...si_data.items[key] };

        if (!items.product_name) {
            ToastDanger('Please add Product item at least one.');
            return;
        }

        if (remove) {
            items['discount'] = 0;
            items['discount_type'] = { value: 'none', label: 'None' };
            items['discount_amount'] = 0;
            items['amount'] = items.srp;
        }

        si_data.items[key] = items;

        dispatch({
            type: ADD_ITEM,
            payload: si_data.items,
        });

        dispatch({
            type: TOGGLE_DISCOUNT_ROW,
            payload: { key, remove },
        });
    };

export const handleInputDiscountProduct =
    (e, key) => async (dispatch, getState) => {
        let { name, value } = e.target;
        value = parseFloat(value);
        let { si_data } = getState().delivery;
        let items = { ...si_data.items[key] };

        // // Save original amount if not already saved
        // if (!items.hasOwnProperty('original_amount')) {
        //   items['original_amount'] = items.srp;
        // }

        let discountedAmount = 0;
        let discountAmount = 0;

        items.amount = items.srp * items.qty;

        if (value === '0') {
        } else if (items.discount_type.value === 'percent') {
            discountAmount = (value / 100) * items.amount;
            discountedAmount = items.amount - discountAmount;
            items.amount = discountedAmount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = value;
            discountedAmount = items.amount - value;
            items.amount = discountedAmount;
        }

        items[name] = parseFloat(value);
        items['discount_amount'] = discountAmount ? discountAmount : 0;

        si_data.items[key] = items;
        dispatch({ type: ADD_ITEM, payload: si_data.items });
    };

export const handleSelectDiscountTypeProduct =
    (selectOption, key) => async (dispatch, getState) => {
        let { si_data } = getState().delivery;
        let items = { ...si_data.items[key] };

        // Reset discount amount when changing discount type to 'amount'
        if (selectOption.value === 'amount' || 'percent' || 'none') {
            items.discount_amount = 0;
            items.amount = items.srp * items.qty;
        }

        items.discount_type = selectOption;
        items.discount = 0;

        si_data.items[key] = items;
        dispatch({ type: ADD_ITEM, payload: si_data.items });
    };

export const handleSelectDiscountType = (selectOption) => async (dispatch) => {
    const discountType =
        selectOption.value === 'none'
            ? 'none'
            : selectOption.value === 'percent'
            ? 'percent'
            : 'amount';

    if (selectOption.value === 'none') {
        dispatch({
            type: DELIVERY_INPUT_CHANGE,
            payload: { key: 'discount', value: 0 },
        });
    }

    // Save the whole selectOption object with the string representation of the value
    dispatch({
        type: DELIVERY_INPUT_CHANGE,
        payload: {
            key: 'discount_type',
            value: { ...selectOption, value: discountType },
        },
    });
};

export const handleInputChangeDiscount = (e) => async (dispatch, getState) => {
    if (e.target.value !== '') {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { si_data } = getState().delivery;

    if (si_data.discount_type.value == 'percent') {
        if (e.target.value > 100) {
            ToastDanger(
                'If your discount is percentage, discount should not be over 100'
            );
            dispatch({
                type: DELIVERY_INPUT_CHANGE,
                payload: { key: e.target.name, value: 100 },
            });
        } else if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: DELIVERY_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: DELIVERY_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    } else {
        if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: DELIVERY_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: DELIVERY_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    }
};

export const handleTaxType = (selectOption) => async (dispatch, getState) => {
    let { tax, default_tax } = getState().delivery.si_data;
    tax = selectOption.value === 'none' ? 0 : default_tax;

    dispatch({
        type: DELIVERY_TAX_SELECT_TYPE,
        payload: { tax, tax_type: selectOption },
    });
};

//**old */
// COMPUTATION HANDLER
// export const computationHandler = () => async (dispatch, getState) => {
//   let {
//     si_data: { items, discount, discount_type, tax, tax_type, delivery_fee },
//   } = getState().delivery;

//   // Subtotal and discount
//   const sub_total = items.reduce(
//     (sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp),
//     0
//   );
//   const discounted =
//     discount_type == 1 ? (parseFloat(sub_total) * discount) / 100 : discount;
//   const sub_total_discounted = sub_total - discounted + delivery_fee;

//   // Exclusive Vat     // Formula: price * (vat / 100)
//   const exclusive_vat = (parseFloat(sub_total_discounted) * tax) / 100;
//   const total_w_exclusive =
//     sub_total_discounted +
//     (parseFloat(sub_total_discounted) * tax) / 100 +
//     delivery_fee; // total amount

//   // Inclusive Vat   // Formula: price / ( (vat / 100) + 1)
//   const inclusive_amount = sub_total_discounted / (tax / 100 + 1);
//   const inclusive_vat = sub_total_discounted - inclusive_amount;

//   let payload = {};
//   switch (tax_type.value) {
//     case "exclusive":
//       payload = {
//         sub_total: sub_total,
//         vat_amount: exclusive_vat,
//         total: total_w_exclusive,
//       };
//       break;
//     case "inclusive":
//       payload = {
//         sub_total: sub_total,
//         vat_amount: inclusive_vat,
//         total: sub_total_discounted,
//       };
//       break;
//     default:
//       payload = {
//         vat_amount: 0.0,
//         sub_total: sub_total,
//         total: sub_total_discounted,
//       };
//       break;
//   }

//   // console.log(payload);
//   dispatch({ type: DELIVERY_COMPUTATION_HANDLER, payload });
// };

//**MERN */
export const computationHandler = () => async (dispatch, getState) => {
    let {
        si_data: {
            items,
            discount,
            discount_type,
            tax,
            tax_type,
            delivery_fee,
        },
    } = getState().delivery;

    // Subtotal and discount
    const sub_total = items.reduce(
        (sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp),
        0
    );
    const discounted =
        discount_type.value == 'percent'
            ? (parseFloat(sub_total) * discount) / 100
            : discount;
    const sub_total_discounted =
        sub_total - discounted + parseFloat(delivery_fee);

    // Exclusive Vat     // Formula: price * (vat / 100)
    const exclusive_vat = (parseFloat(sub_total_discounted) * tax) / 100;
    const total_w_exclusive = sub_total_discounted + exclusive_vat;

    // Inclusive Vat   // Formula: price / ( (vat / 100) + 1)
    const inclusive_amount = sub_total_discounted / (1 + tax / 100);
    const inclusive_vat = sub_total_discounted - inclusive_amount;

    let payload = {};
    switch (tax_type.value) {
        case 'exclusive':
            payload = {
                sub_total: sub_total,
                vat_amount: exclusive_vat,
                total: total_w_exclusive,
            };
            break;
        case 'inclusive':
            payload = {
                sub_total: sub_total,
                vat_amount: inclusive_vat,
                total: sub_total_discounted,
            };
            break;
        default:
            payload = {
                vat_amount: 0.0,
                sub_total: sub_total,
                total: sub_total_discounted,
            };
            break;
    }

    // console.log(payload);
    dispatch({ type: DELIVERY_COMPUTATION_HANDLER, payload });
};

export const sendEmail = (e, id) => async (dispatch, getState) => {
    let { email_config } = getState().delivery;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendSingleEmail(id));
        });
    } else {
        dispatch(sendSingleEmail(id));
    }
};

export const sendSingleEmail = (id) => async (dispatch, getState) => {
    dispatch({ type: SEND_EMAIL, payload: true });
    let { si_data } = getState().delivery;
    DeliveryService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: true });
        })
        .catch((err) => {
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }

            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: si_data.email_sent });
        });
};

export const reSendEmail = (e, id) => async (dispatch, getState) => {
    SwalWarning(
        'Are you sure?',
        'Do you really want to resend this email?',
        () => dispatch(sendEmail(e, id))
    );
};

export const getPdf = (e, id, delivery_receipt_no) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_BTN, payload: true });
    DeliveryService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = delivery_receipt_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch({ type: DOWNLOAD_BTN, payload: false });
        })

        .catch((err) => {
            dispatch({ type: DOWNLOAD_BTN, payload: false });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const emailConfig = () => async (dispatch) => {
    let x = 0;
    while (x < 3) {
        try {
            const result = await DeliveryService.getEmailConfig();
            dispatch({ type: EMAIL_SETTING_DATA, payload: result.data });
            x = 4;
        } catch (err) {
            // Temporary comment coz of connection timeout
            // ToastDanger('Failed to load email configuration.');
            console.log('error', err);
            x++;
        }
    }
};

const verfiyPass = (callback) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
        };
        DeliveryService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(result.data.message, callback('success'));
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const sendEmailDyanmic =
    (e, id, index) => async (dispatch, getState) => {
        let { dr_data } = getState().delivery;
        let dr = dr_data[index];

        if (dr.email_sent) {
            SwalWarning(
                'Are you sure?',
                'Do you really want to resend this email?',
                () => dispatch(sendByIndex(id, index))
            );
        } else {
            dispatch(sendByIndex(id, index));
        }
    };

const sendByIndex = (id, index) => async (dispatch, getState) => {
    let { email_config } = getState().delivery;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendEmailIndex(id, index));
        });
    } else {
        dispatch(sendEmailIndex(id, index));
    }
};

const sendEmailIndex = (id, index) => async (dispatch, getState) => {
    let { dr_data } = getState().delivery;
    let dr = dr_data[index];
    dr.sending_email = true;
    dr_data[index] = dr;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: dr_data });
    DeliveryService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            dr.sending_email = false;
            dr.email_sent = true;
            dr_data[index] = dr;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: dr_data });
        })
        .catch((err) => {
            // ToastDanger('Failed to send email.');
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            dr.sending_email = false;
            dr_data[index] = dr;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: dr_data });
        });
};

export const dynamiPdf = (e, id, index) => async (dispatch, getState) => {
    let { dr_data } = getState().delivery;
    let dr = dr_data[index];
    dr.download_pdf = true;
    dr_data[index] = dr;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: dr_data });
    DeliveryService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = dr.delivery_receipt_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            dr.download_pdf = false;
            dr_data[index] = dr;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: dr_data });
        })

        .catch((err) => {
            dr.download_pdf = false;
            dr_data[index] = dr;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: dr_data });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });

        // console.log(newArray);
        dispatch({ type: GET_DR_ROWS_TO_DELETE, payload: newArray });
    };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().delivery;
    let ir_no = [];

    rows_to_delete.map((rows, i) => {
        ir_no.push('<b>' + rows.name + '</b>');
    });

    SwalWarningHtml(
        'Warning!',
        `Do you want to remove the Delivery Receipt(s): ${ir_no.join(', ')}?`,
        () => dispatch(archiveList())
    );
};

//** MERN */
export const archiveList =
    (pageOrigin = null) =>
    async (dispatch, getState) => {
        let { rows_to_delete } = getState().delivery;
        let id_arr = [];

        rows_to_delete.map((row) => {
            id_arr.push(row.id);
        });
        let params = {
            ids: JSON.stringify(id_arr),
        };
        DeliveryService.archieveList(params)

            .then((res) => {
                switch (res.data.status) {
                    case 0:
                        ToastWarning(res.data.message);
                        break;
                    case 1:
                        ToastSuccess(res.data.message);
                        dispatch(get_dr());
                    default:
                        break;
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                console.log(error);
            });
    };

export const clear_data = () => async (dispatch) => {
    dispatch({ type: CLEAR_DATA });
};

export const cancelDr = () => async (dispatch, getState) => {
    let { si_data } = getState().delivery;
    SwalWarningHtml(
        'Are You Sure?',
        'You are about to cancel Purchase Order <b>' +
            si_data.delivery_receipt_no +
            '</b>. Continue?',
        () => dispatch(passwordCancel())
    );
};

const passwordCancel = () => async (dispatch) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
            role_id: getUserData().role_id,
            subscriber_id: getUserData().subscriber_id,
            id: getUserData()._id,
        };
        DeliveryService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(
                    result.data.message,
                    dispatch(submitCancel())
                );
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const submitCancel = () => async (dispatch, getState) => {
    let { si_data, btn_form } = getState().delivery;
    btn_form.cancel = true;
    dispatch({ type: BTN_FORM, payload: btn_form });
    DeliveryService.cancelDR(si_data.dr_id)
        .then((result) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            ToastSuccess(
                'Delivery Receipt: ' +
                    si_data.delivery_receipt_no +
                    ' cancelled'
            );
            dispatch(clearDelivery());
            window.location.href = '/deliveries';
        })
        .catch((err) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            try {
                ToastDanger(err.data.message);
            } catch (err) {
                ToastDanger('Failed to cancel Quotation');
            }
        });
};

export const autoPrint = () => async (dispatch) => {
    let toPrint = JSON.parse(gettoPrint('Delivery'));
    if (toPrint !== null) {
        try {
            if (toPrint.print) {
                dispatch(setModal('dr_print_modal', true, toPrint.id));
            }
        } catch (err) {}
    }
};
