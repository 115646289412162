import React, { useEffect, Fragment } from "react";
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function ProfileReceipt () {
    
return (

<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                <h3 className="content-header-title mb-0 d-inline-block">Profile</h3>
                <div className="breadcrumbs-top d-inline-block">
                    <div className="breadcrumb-wrapper mr-1">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><a href="#" className="text-white">Receipts</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div className="content-body margin-top-5">
            <section id="basic-examples">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header mb-0 pb-0">
                                <a href="/account/profile"><i className="ft ft-arrow-left f-regular"></i> Back</a>
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <h5 className="mt-2">List of Item Receipts</h5>
                                    <table className="table table-striped table-hover table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Item Receipts No.</th>
                                                <th>Suppliers</th>
                                                <th>Delivery Date</th>
                                                <th>Delivery Status</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="row-style-hover" width="5%">1021</td>
                                                <td className="row-style-hover">ASUS Co.</td>

                                                <td className="row-style-hover">10/16/2020</td>
                                                <td className="row-style-hover text-center"><span className="lbl-status lbl-full">Full</span></td>

                                                <td className="row-style-hover text-center">
                                                    <span className="lbl-status lbl-save">save</span>
                                                </td>

                                                <td>
                                                    <div className="btn-group">
                                                        <a href="#" className="btn btn-sm btn-info btn-sm"><i className="ft ft-edit"></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="row-style-hover" width="5%">1021</td>
                                                <td className="row-style-hover">ASUS Co.</td>

                                                <td className="row-style-hover">10/16/2020</td>
                                                <td className="row-style-hover text-center"><span className="lbl-status lbl-full">Full</span></td>

                                                <td className="row-style-hover text-center">
                                                    <span className="lbl-status lbl-save">save</span>
                                                </td>

                                                <td>
                                                    <div className="btn-group">
                                                        <a href="#" className="btn btn-sm btn-info btn-sm"><i className="ft ft-edit"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

); }


 
const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(ProfileReceipt);