import React, { Fragment, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { NumberFormat } from '../../Services/_numberformat.service.js';
import {
    handleSelectInvoiceTemplate,
    handleSelectReceiptType,
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleInputDiscountProduct,
    handleSelectDiscountTypeProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    setModal,
    getSalesOrders,
    getDeliveries,
    handleSelectSalesOrder,
    handleSelectDelivery,
    getTermsConditions,
    getInvoiceTemplates,
    getCustomers,
    getProducts,
    handleSelectPaymentType,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleCheckManual,
    SIDatePicker,
    clearInvoice,
    add_items,
    dynamicInputChange,
    searchProducst,
    clear_customer,
    searchCustomers,
    ShowComboBox,
    computationHandler,
    handleTaxType,
} from '../../redux/actions/invoice/invoice.actions.js';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import InvoiceBlankTable from '../layouts/table_template/InvoiceBlankTable.js';
import InvoiceCustomerModal from './modals/Invoice.customer.modal.js';
import PaymentTermsModal from './modals/PaymentTerms.modal.js';
import DatePicker from 'react-datepicker';
import { MiniSpinner } from '../layouts/Spinner.js';
import { Show, useAccess } from 'react-redux-permission';
import { SwalFormWarning } from '../../Services/_swal.service.js';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions.js';
import debounce from 'debounce-promise';
import DynamicFont from 'react-dynamic-font';
import { subscriberSetting } from '../../redux/actions/settings_app/setting_app.actions.js';
import { stockClass } from '../../Utils/Common.js';

function InvoiceCreate({
    invoice: {
        single_invoice,
        customers,
        terms_conditions,
        isSearchable,
        itemSelected,
        items,
        isDiscount,
        default_product_value,
        mini_loading,
        isOther,
        receipt_type_options,
        invoice_templates,
        si_default_template,
        default_select_type_receipt,
        edit_row_index,
        payment_terms_modal,
        edit_invoice_items,
        edit_attr,
        main_url,
        main_page,
        payment_type_options,
        customer_modal,
        sales_order_options,
        delivery_options,
        default_customer_value,
        instruction,
        is_disabled,
        loading,
        tax_types,
    },
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleInputDiscountProduct,
    handleSelectDiscountTypeProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectInvoiceTemplate,
    handleSelectReceiptType,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleSelectPaymentType,
    setModal,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getTermsConditions,
    getInvoiceTemplates,
    getCustomers,
    getProducts,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleCheckManual,
    getSalesOrders,
    getDeliveries,
    handleSelectSalesOrder,
    handleSelectDelivery,
    SIDatePicker,
    clearInvoice,
    add_items,
    dynamicInputChange,
    block_route,
    blockRoute,
    searchProducst,
    clear_customer,
    searchCustomers,
    subscriberSetting,
    computationHandler,
    handleTaxType,
    ShowComboBox,
    location,
}) {
    let history = useHistory();

    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                getTermsConditions(),
                getInvoiceTemplates(),
                getCustomers(),
                getProducts(),
                getSalesOrders(),
                getDeliveries(),
                clearInvoice(),
                blockRoute(false),
                clear_customer(),
                // subscriberSetting('invoice'),
            ]);
        }

        fetchAll();
    }, []);

    const {
        invoice_items,
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    } = single_invoice;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(invoice_items),
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    ]);

    const [selectedOption, setSelectedOption] = useState('salesOrder');

    const handleToggle = () => {
      setSelectedOption((prevOption) =>
        prevOption === 'salesOrder' ? 'delivery' : 'salesOrder'
      );
    };

    const handleSelect = selectedOption === 'salesOrder' ? handleSelectSalesOrder : handleSelectDelivery;


    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);


    // redirect after making SI from sales_order
    const [shouldRedirect, setShouldRedirect] = useState(location.makeSo ? false : true)

        
    useEffect(() => {
        console.log(location)
        if (location.makeSo) {
            handleSelectSalesOrder(location.makeSo);
            handleSelectDelivery(location.makeSo)
        }
       
        }, [location.makeSo]);

    if (single_invoice.is_manual){
        location.makeSo = null
    }

    const customFilter = (option, searchText) => {
        if (
            option.data.label
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
            option.data.barcode.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    // Issue is here; when newly created sales_order's "make invoice" button is clicked, SI's main_page state is set to true
    if (main_page && shouldRedirect) return <Redirect to={main_url} />;

    // let percent_value = [{value: 0, label: 'None'},{value: 1, label: 'Percent'}, {value: 2, label: 'Amount'}]
    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    const Option = (props) => {
        // console.log(props)
        return (
            <components.Option {...props}>
                <div>{props.data.label} [{props.data.brand}]</div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
                {/* <div style={{ fontSize: 11 }}>{props.data.lot_no}</div>
                <div style={{ fontSize: 11 }}>{props.data.brand}</div> */}
            </components.Option>
        );
    };
    // console.log("single_invoice", single_invoice);
    return (
        <BeforeUnloadComponent
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Sales
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <span className="text-white">
                                                Create
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                            <a className="heading-elements-toggle">
                                                {' '}
                                                <i className="la la-ellipsis-v font-medium-3"></i>
                                            </a>
                                            <div className="heading-elements">
                                                {' '}
                                                <ul className="list-inline mb-0"></ul>
                                            </div>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body pb-10m f-regular">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <div className="row">
                                                                <div className="col-sm-4">
                                                                    <label>
                                                                        Template{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <Select
                                                                                options={
                                                                                    invoice_templates
                                                                                }
                                                                                onChange={
                                                                                    handleSelectInvoiceTemplate
                                                                                }
                                                                                value={
                                                                                    si_default_template
                                                                                }
                                                                                isSearchable={
                                                                                    isSearchable
                                                                                }
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4" style={{marginTop:"6px"}}>
                                                                    <label></label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                        <Select
                                                                            options={[
                                                                                { value: 'salesOrder', label: 'Sales Order' },
                                                                                { value: 'delivery', label: 'Delivery' },
                                                                            ]}
                                                                            onChange={(selectedOption) => setSelectedOption(selectedOption.value)}
                                                                            value={{ value: selectedOption, label: selectedOption === 'salesOrder' ? 'Sales Order' : 'Delivery' }}
                                                                            isSearchable={isSearchable}
                                                                            required
                                                                        />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <label>
                                                                        Invoice
                                                                        Date
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <div className="date-picker-100">
                                                                                <DatePicker
                                                                                    onChange={(
                                                                                        date
                                                                                    ) =>
                                                                                        SIDatePicker(
                                                                                            date
                                                                                        )
                                                                                    }
                                                                                    selected={
                                                                                        single_invoice.invoice_date
                                                                                    }
                                                                                    name="invoice_date"
                                                                                    className="form-control f-regular"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                            >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                            {single_invoice.customer_id !== null ? (
                                                                <div className="col-md-4">
                                                                    <label>
                                                                        Customer{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <div className="form-control f-regular">
                                                                                {
                                                                                    default_customer_value.label
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                                <Show when="inv.sales.customers.create">
                                                                                    <div
                                                                                        className="a-tagicon icon-s"
                                                                                        onClick={() =>
                                                                                            setModal(
                                                                                                'customer_modal',
                                                                                                true
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="ft ft-plus-circle text-primary"></i>
                                                                                    </div>
                                                                                </Show>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                ):
                                                                (
                                                                <><div className="col-sm-4"></div></>
                                                                )}
                                                                <div className="col-md-4">
                                                                <section>
                                                                        <label>
                                                                        {selectedOption === 'salesOrder' ? 'Sales Order No.' : 'Delivery No.'}
                                                                        </label>
                                                                        <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <Select
                                                                            onChange={handleSelect}
                                                                            options={
                                                                                selectedOption === 'salesOrder'
                                                                                ? sales_order_options
                                                                                : delivery_options
                                                                            }
                                                                            isSearchable={isSearchable}
                                                                            required
                                                                            value={
                                                                                location.makeSo === undefined
                                                                                ? {
                                                                                    label: selectedOption === 'salesOrder'
                                                                                        ? single_invoice.sales_order_no
                                                                                        : single_invoice.delivery_receipt_no,
                                                                                    value: selectedOption === 'salesOrder'
                                                                                        ? single_invoice.sales_order_no
                                                                                        : single_invoice.delivery_receipt_no,
                                                                                }
                                                                                : {
                                                                                    label: selectedOption === 'salesOrder'
                                                                                        ? single_invoice.sales_order_no
                                                                                        : single_invoice.delivery_receipt_no,
                                                                                    value: selectedOption === 'salesOrder'
                                                                                        ? single_invoice.sales_order_no
                                                                                        : single_invoice.delivery_receipt_no,
                                                                                }
                                                                            }
                                                                            
                                                                            />
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>

                                                            
                                                           
                                                            
                                                            {single_invoice.customer ==
                                                            null ? (
                                                                <div className="table-responsive">
                                                                    <InvoiceBlankTable
                                                                    />
                                                                </div>
                                                            ) : mini_loading ? (
                                                                <MiniSpinner />
                                                            ) : (
                                                                <Fragment>
                                                                    <table className="table table-hover table-striped table-bordered f-regular">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-left td-16m">
                                                                                    SKU
                                                                                </th>
                                                                                <th className="text-center">
                                                                                    Product
                                                                                </th>
                                                                                <th className="text-center mw-6m">
                                                                                    Qty
                                                                                </th>
                                                                                <th className="text-center">
                                                                                    Unit
                                                                                </th>
                                                                                <th className="text-center">
                                                                                    SRP
                                                                                </th>
                                                                                <th className="text-center">
                                                                                    Unit/Price
                                                                                </th>
                                                                                <th className="text-center">
                                                                                    Amount
                                                                                </th>
                                                                                {/* <th className="text-center">
                                                                                    Action
                                                                                </th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {single_invoice.invoice_items.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => (
                                                                                    <Fragment
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {' '}
                                                                                        {/* This is the key part */}
                                                                                        <tr
                                                                                            className={stockClass(
                                                                                                item.status_code
                                                                                            )}
                                                                                        >
                                                                                            {/* <tr
                                                                                                key={index}
                                                                                                className={stockClass(
                                                                                                item.status_code
                                                                                                )}
                                                                                            > */}
                                                                                            <td className="table-data__sku ">
                                                                                                {
                                                                                                    item.sku
                                                                                                }
                                                                                            </td>
                                                                                            <td className="min-wdth-300 max-wdth-300">
                                                                                            <div>
                                                                                                                {/* <div className='f-right' onClick={() => ShowComboBox(index)}>
                                                                                                                    <i className='ft ft-chevron-down c-pointer'></i>
                                                                                                                </div> */}
                                                                                                                <div className='mw-90'>
                                                                                                                <Link to={`/inventory/view/${item._id}`} 
                                                                                                                    target="_blank" 
                                                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                                                    >
                                                                                                                    {item.product_name}
                                                                                                                </Link>
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        item.sku
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        item.description
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        item.brand
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                            </td>
                                                                                            <td
                                                                                                className="mw-6m text-center"
                                                                                                align="center"
                                                                                            >{
                                                                                                item.qty
                                                                                            }
                                                                                                
                                                                                            </td>
                                                                                            <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                {
                                                                                                    item.product_unit_name
                                                                                                }
                                                                                            </td>
                                                                                            <td
                                                                                                className="mw-8m text-right"
                                                                                                align="right"
                                                                                            >
                                                                                                {/* <input value={item.srp} onChange={e => dynamicInputChange(e, index)} className="form-control text-right" type="number" name="srp" min={0}/> */}
                                                                                                {NumberFormat(
                                                                                                    item.srp.toFixed(
                                                                                                        2
                                                                                                    )
                                                                                                )}
                                                                                            </td>
                                                                                            <td
                                                                                                className="mw-8m text-right unit-price"
                                                                                                align="right"
                                                                                            >
                                                                                                {item.unit_price}
                                                                                            </td>
                                                                                            <td
                                                                                                className="min-wdth-150 max-wdth-150"
                                                                                                align="right"
                                                                                            >
                                                                                                {item.amount}
                                                                                            </td>
                                                                                            
                                                                                        </tr>


                                                                                        {(item.discount_type &&
                                                                                            item
                                                                                                .discount_type
                                                                                                .value !==
                                                                                                'none') ||
                                                                                        item.showDiscountRow ? (
                                                                                            <tr>
                                                                                                <td
                                                                                                    colSpan={
                                                                                                        1
                                                                                                    }
                                                                                                ></td>
                                                                                                <td
                                                                                                    className="d-flex ali justify-content-center align-items-center"
                                                                                                    style={{
                                                                                                        gap: '5%',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Discount:
                                                                                                    </span>

                                                                                                    <span className="w-20 table-data__discount">
                                                                                                        {(() => {
                                                                                                            switch (
                                                                                                                item
                                                                                                                    .discount_type
                                                                                                                    .value
                                                                                                            ) {
                                                                                                                case 'percent':
                                                                                                                    return (
                                                                                                                        <div class="input-group">
                                                                                                                            <input
                                                                                                                                onChange={(
                                                                                                                                    e
                                                                                                                                ) =>
                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                        e,
                                                                                                                                        index
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    item.discount
                                                                                                                                }
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                                disabled
                                                                                                                            />
                                                                                                                            <div class="input-group-append">
                                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                    %
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                case 'amount':
                                                                                                                    return (
                                                                                                                        <input
                                                                                                                            onChange={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                handleInputDiscountProduct(
                                                                                                                                    e,
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                item.discount
                                                                                                                            }
                                                                                                                            name="discount"
                                                                                                                            className="form-control text-right f-regular"
                                                                                                                            type="number"
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    );
                                                                                                                default: // 0 disabled
                                                                                                                    return (
                                                                                                                        <input
                                                                                                                            value={
                                                                                                                                item.discount
                                                                                                                            }
                                                                                                                            disabled
                                                                                                                            name="discount"
                                                                                                                            className="form-control text-right f-regular"
                                                                                                                            type="number"
                                                                                                                        />
                                                                                                                    );
                                                                                                            }
                                                                                                        })()}
                                                                                                    </span>

                                                                                                    <span className="table-data__discount-dropdown">
                                                                                                        <Select
                                                                                                            name="discount_type"
                                                                                                            onChange={(
                                                                                                                selectOption
                                                                                                            ) =>
                                                                                                                handleSelectDiscountTypeProduct(
                                                                                                                    selectOption,
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            menuPortalTarget={
                                                                                                                document.body
                                                                                                            }
                                                                                                            value={
                                                                                                                percent_value[
                                                                                                                    item
                                                                                                                        .discount_type
                                                                                                                ]
                                                                                                            }
                                                                                                            defaultValue={
                                                                                                                item.discount_type
                                                                                                            }
                                                                                                            label="Single select"
                                                                                                            options={
                                                                                                                percent_value
                                                                                                            }
                                                                                                            isSearchable={
                                                                                                                false
                                                                                                            }  
                                                                                                            isDisabled={true}
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td></td>
                                                                                                <td></td>
                                                                                                <td></td>
                                                                                                <td></td>
                                                                                                <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                    -{' '}
                                                                                                    {NumberFormat(
                                                                                                        Number(
                                                                                                            item.discount_amount
                                                                                                        ).toFixed(
                                                                                                            2
                                                                                                        )
                                                                                                    )}
                                                                                                </td>

                                                                                                {/* ACTIONS BUTTON */}
                                                                                                {/* <td
                                                                                                    align="center"
                                                                                                    className="mw-2m"
                                                                                                >
                                                                                                    <button
                                                                                                        className="btn btn-sm btn-danger"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleDiscountItem(
                                                                                                                index,
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                        type="button"
                                                                                                    >
                                                                                                        <i className="ft ft-trash"></i>
                                                                                                    </button>
                                                                                                </td> */}
                                                                                            </tr>
                                                                                        ) : null}
                                                                                    </Fragment>
                                                                                )
                                                                            )}
                                                                            {/* <tr className="bg-primary bg-accent-2">
                                                                            <td className="min-wdth-100 max-wdth-100">{itemSelected.sku}</td>
                                                                            <td className="min-wdth-300 max-wdth-300"><Select components={{Option}} filterOption={customFilter} classNamePrefix="lp-copy-sel" options={items} onChange={handleSelectItem} isSearchable={isSearchable} value={default_product_value}/></td>
                                                                            <td className="min-wdth-120 max-wdth-100"><input onChange={handleInputQty} value={itemSelected.qty} name="qty" min={1} className="form-control" type="text"/></td>
                                                                            <td className="min-wdth-100 max-wdth-100 text-center">{itemSelected.product_unit_name}</td>
                                                                            <td className="min-wdth-150 max-wdth-150"><input onChange={handleInputSrp} value={itemSelected.srp} type="number" name="cost" className="form-control" min={1}/></td>
                                                                            <td className="min-wdth-150 max-wdth-150" align="right"><div className="td-padding">{itemSelectedAmount ?  NumberFormat(itemSelectedAmount.toFixed(2)) : 0}</div></td>
                                                                            <td className="text-center" width="5%"><a className="btn btn-sm btn-primary text-white" onClick={handleAddItem}>add</a></td>
                                                                        </tr> */}

                                                                        {/* ADD ITEMS BUTTON */}
                                                                            {/* <tr>
                                                                                <td
                                                                                    colSpan="8"
                                                                                    className="text-left"
                                                                                >
                                                                                    <button
                                                                                        className="btn btn-primary"
                                                                                        type="button"
                                                                                        onClick={
                                                                                            add_items
                                                                                        }
                                                                                    >
                                                                                        <i className="la la-plus"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr> */}
                                                                            <tr>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                {/* <td className="p-2">
                                                                                    &nbsp;
                                                                                </td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                <td className="p-2">
                                                                                    &nbsp;
                                                                                </td>
                                                                                {/* <td className="p-2">
                                                                                    &nbsp;
                                                                                </td> */}
                                                                            </tr>
                                                                            
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        4
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        Sub
                                                                                        Total
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right">
                                                                                    {NumberFormat(
                                                                                        single_invoice.sub_total.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        4
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        Discount
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {(() => {
                                                                                        switch (
                                                                                            single_invoice
                                                                                                .discount_type
                                                                                                .value
                                                                                        ) {
                                                                                            case 'percent':
                                                                                                return (
                                                                                                    <div class="input-group">
                                                                                                        <input
                                                                                                            onChange={
                                                                                                                handleInputChangeDiscount
                                                                                                            }
                                                                                                            value={
                                                                                                                single_invoice.discount
                                                                                                            }
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                        <div class="input-group-append">
                                                                                                            <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                %
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            case 'amount':
                                                                                                return (
                                                                                                    <input
                                                                                                        onChange={
                                                                                                            handleInputChangeDiscount
                                                                                                        }
                                                                                                        value={
                                                                                                            single_invoice.discount
                                                                                                        }
                                                                                                        name="discount"
                                                                                                        className="form-control text-right f-regular"
                                                                                                        type="number"
                                                                                                    />
                                                                                                );
                                                                                            default: // 0 disabled
                                                                                                return (
                                                                                                    <input
                                                                                                        value={
                                                                                                            single_invoice.discount
                                                                                                        }
                                                                                                        disabled
                                                                                                        name="discount"
                                                                                                        className="form-control text-right f-regular"
                                                                                                        type="number"
                                                                                                    />
                                                                                                );
                                                                                        }
                                                                                    })()}
                                                                                </td>
                                                                                <td
                                                                                    width="10%"
                                                                                    className="text-center padding-td-5"
                                                                                >
                                                                                    <Select
                                                                                        name="discount_type"
                                                                                        onChange={
                                                                                            handleSelectDiscountType
                                                                                        }
                                                                                        menuPortalTarget={
                                                                                            document.body
                                                                                        }
                                                                                        value={
                                                                                            percent_value[
                                                                                                single_invoice
                                                                                                    .discount_type
                                                                                            ]
                                                                                        }
                                                                                        defaultValue={
                                                                                            single_invoice.discount_type
                                                                                        }
                                                                                        label="Single select"
                                                                                        options={
                                                                                            percent_value
                                                                                        }
                                                                                        isSearchable={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        4
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        VAT
                                                                                        (
                                                                                        {
                                                                                            single_invoice.tax
                                                                                        }{' '}
                                                                                        %)
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right">
                                                                                    {NumberFormat(
                                                                                        single_invoice.vat_amount.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td className="padding-td-5 min-width-150">
                                                                                    <Select
                                                                                        name="tax_type"
                                                                                        onChange={
                                                                                            handleTaxType
                                                                                        }
                                                                                        value={
                                                                                            single_invoice.tax_type
                                                                                        }
                                                                                        menuPortalTarget={
                                                                                            document.body
                                                                                        }
                                                                                        options={
                                                                                            tax_types
                                                                                        }
                                                                                        isSearchable={
                                                                                            false
                                                                                        }
                                                                                        isDisabled={true}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        4
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        Delivery
                                                                                        Fee
                                                                                    </div>
                                                                                </td>
                                                                                <td width="12%">
                                                                                    {isOther ? (
                                                                                        <input
                                                                                            onChange={
                                                                                                handleInputAttr
                                                                                            }
                                                                                            value={
                                                                                                edit_attr.other
                                                                                            }
                                                                                            name="other"
                                                                                            className="form-control"
                                                                                            type="number"
                                                                                        />
                                                                                    ) : (
                                                                                        <div align="right">
                                                                                            {' '}
                                                                                            {
                                                                                                single_invoice.other
                                                                                            }{' '}
                                                                                        </div>
                                                                                    )}
                                                                                </td>

                                                                                {/* INSERT DELIVERY FEE BUTTON */}
                                                                                <td className="text-center">
                                                                                    {/* {isOther ? (
                                                                                        <Fragment>
                                                                                            <div className="btn-group">
                                                                                                <a
                                                                                                    className="btn btn-primary btn-sm"
                                                                                                    onClick={() =>
                                                                                                        handleAttrSubmit(
                                                                                                            'other'
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="ft ft-check text-white"></i>
                                                                                                </a>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    ) : (
                                                                                        <a
                                                                                            className="btn btn-info btn-sm"
                                                                                            onClick={() =>
                                                                                                toggleEditBtn(
                                                                                                    true,
                                                                                                    'isOther'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-edit text-white"></i>
                                                                                        </a>
                                                                                    )} */}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="4"></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        <strong>
                                                                                            Total
                                                                                        </strong>
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right">
                                                                                    {NumberFormat(
                                                                                        single_invoice.total.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </Fragment>
                                                            )}

                                                            <div className="col-12 mb-2"></div>
                                                            {single_invoice.customer !== null ?(
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <label>
                                                                                Remarks{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <textarea
                                                                                        onChange={
                                                                                            handleInputChange
                                                                                        }
                                                                                        value={
                                                                                            single_invoice.remarks
                                                                                        }
                                                                                        name="remarks"
                                                                                        rows="2"
                                                                                        className="form-control f-regular"
                                                                                    ></textarea>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Payment
                                                                                Mode:
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            payment_type_options
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectPaymentType
                                                                                        }
                                                                                        value={
                                                                                            single_invoice.payment_type
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Payment
                                                                                Terms:{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            terms_conditions
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectTermsConditions
                                                                                        }
                                                                                        value={
                                                                                            single_invoice.payment_terms
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                        <div
                                                                                            className="a-tagicon icon-s"
                                                                                            onClick={() =>
                                                                                                setModal(
                                                                                                    'payment_terms_modal',
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-plus-circle text-primary"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Receipt
                                                                                Type{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            receipt_type_options
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectReceiptType
                                                                                        }
                                                                                        value={
                                                                                            single_invoice.receipt_type
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ):(
                                                                <></>
                                                            )}
                                                           
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="row justify-content-end">
                                                    <div className="mr-2">
                                                        <div className="form-group">
                                                            {/* <Show when="inv.sales.invoices.draft"><button onClick={() => handleOnSubmit('create', 'draft')} type="button" className="btn btn-outline-light text-dark">Draft</button></Show>{" "}
                                                        <button onClick={() => handleOnSubmit('create', 'saved-email')} type="button" className="btn btn-blue">Save & Email</button>{" "}
                                                        {
                                                            (edit_row_index != null || isDiscount || isOther) ? <button type="button" className="btn btn-primary btn-save" disabled>Save</button>
                                                            : <button onClick={() => handleOnSubmit('create', 'saved')} type="submit" className="btn btn-primary btn-save">Save</button>
                                                        } */}
                                                            <Show when="inv.sales.invoices.draft">
                                                                <div className="btn-group btn-dpd">
                                                                    <button
                                                                        className="btn btn-default btn-outline-light text-dark btn-main"
                                                                        onClick={() => { handleOnSubmit(
                                                                            'create',
                                                                            'draft'
                                                                        );
                                                                            setShouldRedirect(true)
                                                                    }      
                                                                        }
                                                                        type="button"
                                                                    >
                                                                        Save as
                                                                        Draft
                                                                    </button>
                                                                    <button
                                                                        data-toggle="dropdown"
                                                                        className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                        type="button"
                                                                    >
                                                                        <span className="caret"></span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'create',
                                                                                        'draft-email'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Email
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'create',
                                                                                        'draft-print'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Print
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Show>{' '}
                                                            <div className="btn-group btn-dpd">
                                                                <button
                                                                    className="btn btn-primary  btn-main"
                                                                    onClick={() =>
                                                                        handleOnSubmit(
                                                                            'create',
                                                                            'saved'
                                                                        )
                                                                    }
                                                                    type="button"
                                                                >
                                                                    Save
                                                                    Invoice
                                                                </button>
                                                                <button
                                                                    data-toggle="dropdown"
                                                                    className="btn btn-primary dropdown-toggle btn-carret"
                                                                    type="button"
                                                                >
                                                                    <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {
                                                                        // (edit_row_index != null || isDiscount) &&
                                                                        // <li><a className="dpd-menu" href="#" onClick={() => handleOnSubmit('create', 'saved')}>Save</a></li>
                                                                    }
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'create',
                                                                                    'saved-email'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Email
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'create',
                                                                                    'saved-print'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Print
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <InvoiceCustomerModal
                    show={customer_modal}
                    onHide={() => setModal('customer_modal', false)}
                />
                <PaymentTermsModal
                    show={payment_terms_modal}
                    onHide={() => setModal('payment_terms_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    invoice: state.invoice,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    handleSelectInvoiceTemplate,
    handleSelectReceiptType,
    handleOnSubmit,
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleInputDiscountProduct,
    handleSelectDiscountTypeProduct,
    handleInputAttr,
    clearInvoice,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    SIDatePicker,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    setModal,
    handleCheckManual,
    getSalesOrders,
    getDeliveries,
    handleSelectSalesOrder,
    handleSelectDelivery,
    getTermsConditions,
    getInvoiceTemplates,
    getCustomers,
    getProducts,
    handleSelectPaymentType,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    add_items,
    dynamicInputChange,
    blockRoute,
    searchProducst,
    clear_customer,
    searchCustomers,
    subscriberSetting,
    computationHandler,
    handleTaxType,
    ShowComboBox,
})(InvoiceCreate);
