import React from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function CollectionReceiptTS() {
    return (
        <div className="card">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header">
                <h4 className="card-title">Collection Receipt  <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements"></div>
            </div>
            <div className="card-content collapse show pb-5">
                <div className="card-body">
                    
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    setting_app: state.setting_app
});

export default connect(mapStateToProps, { })(CollectionReceiptTS);
