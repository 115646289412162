import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';
import '../../layouts/template_styles/stealth-dr-01.css';

export class StealthDR01 extends React.PureComponent {
    // function ReceivingPrintContent({ po_items, receivingData, deliveryDate, single_receiving, }) {
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.si_data.items.length;

        this.state = {
            auth: this.props.auth,
            si_data: this.props.si_data,
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr>
                    <td className="st01-item-row .st01-border-r">&nbsp;</td>
                    <td className="st01-item-row .st01-border-r" colspan="2">
                        &nbsp;
                    </td>
                </tr>
            );
        }

        return tableRows;
    }

    renderPrint(page, totalPage) {
        let auth = this.state.auth;
        let si_data = this.props.si_data;

        this.setState({ totalItemsCount: this.props.si_data.items.length });

        // console.log(si_data);

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';

        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';

        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6 ">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );
        const tableRows = [];

        const totalItemsCount = si_data.items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        si_data.items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
            }
        });

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        const template_setting = this.props.si_data.template_setting;
        const customer_address =
            si_data.customer != null
                ? si_data.customer.street1 + ', ' + si_data.customer.city
                : '';
        const customer_mobile =
            si_data.customer != null
                ? si_data.customer.mobile
                    ? si_data.customer.mobile
                    : ''
                : '';

        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';

        return (
            <div className="margin-1p5em px-1 print-page">
                <div className="st01-h mt-2">
                    <div className="st01-hl">
                        <div className="f-20b st01-company">
                            {template_setting && template_setting.company_name}
                        </div>
                        <div className="st01-text-black">
                            {template_setting &&
                                template_setting.company_address}
                        </div>
                        <div className="st01-text-black">
                            VAT Reg. TIN:{' '}
                            {template_setting && template_setting.company_tin}
                        </div>
                    </div>
                    <div className="st01-hr">
                        <div className="f-20b">
                            <div className="st01-h-border pull-right">
                                {' '}
                                DELIVERY RECEIPT
                            </div>
                        </div>
                        <div className="st01-h-border pull-right">
                            <div className="f-12b text-center">D.R. NUMBER</div>
                            <div className="st01-f-18b">
                                {si_data.delivery_receipt_no}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="st01-print-body row st01-text-black">
                    <div className="col-md-12">
                        {si_data.status == 'draft' ? (
                            <div className="st01-watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="st01-t-first">
                            <tr>
                                <td>
                                    <span className="st01-bold">
                                        DELIVERED TO:
                                    </span>
                                    &nbsp;{' '}
                                    <span>
                                        <Link
                                            to={`/sales/customers/history/${si_data.customer._id}`}
                                            className="txt-underline st01-c-print"
                                            target="_blank"
                                        >
                                            {si_data.customer.name}
                                        </Link>
                                    </span>
                                </td>
                                <td width="30%">
                                    <span className="st01-bold">DATE:</span>{' '}
                                    &nbsp;{' '}
                                    <span>{si_data.delivery_date_string}</span>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="st01-bold">TIN:</span>
                                    &nbsp;{' '}
                                </td>
                                <td>
                                    <span className="st01-bold">PO NO.</span>
                                    &nbsp;{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="st01-bold">
                                        BUS STYLE:
                                    </span>
                                    &nbsp;{' '}
                                </td>
                                <td>
                                    <span className="st01-bold">SO NO:</span>
                                    &nbsp;{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="st01-bold">ADDRESS:</span>
                                    &nbsp; <span>{customer_address}</span>
                                </td>
                                <td>
                                    <span className="st01-bold">AGENT:</span>
                                    &nbsp;{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="st01-bold">CP:</span>&nbsp;{' '}
                                    {customer_mobile
                                        ? '+' + customer_mobile
                                        : ''}
                                </td>
                                <td>
                                    <span className="st01-bold">TERMS:</span>
                                    &nbsp;{' '}
                                    {si_data.payment_terms.label
                                        ? si_data.payment_terms.label
                                        : ''}{' '}
                                </td>
                            </tr>
                        </table>
                        <table className="st01-t-second">
                            <thead>
                                <tr align="center">
                                    <td width="15%">
                                        <span className="st01-bold">
                                            QUANTITY
                                        </span>
                                    </td>
                                    <td width="50%" colspan="2">
                                        <span className="st01-bold">
                                            DESCRIPTION
                                        </span>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {si_data.items.map((data, index) =>
                                    showItemsFrom <= index ? (
                                        showItemsTo >= index ? (
                                            <tr key={index}>
                                                <td className="st01-item-row .st01-border-r text-center">
                                                    {' '}
                                                    {data.qty}
                                                </td>
                                                <td
                                                    className="st01-item-row .st01-border-r"
                                                    colspan="2"
                                                    style={DynamicStringStyle}
                                                >
                                                    <Link
                                                        to={`/inventory/view/${data._id}`}
                                                        className="txt-underline"
                                                        target="_blank"
                                                    >
                                                        <DynamicFont
                                                            content={
                                                                'SKU:' +
                                                                data.sku
                                                            }
                                                        />
                                                    </Link>
                                                    ,{' '}
                                                    <DynamicFont
                                                        content={
                                                            data.product_name
                                                        }
                                                    />{' '}
                                                    ({data.product_unit_name})
                                                </td>
                                            </tr>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                                {page == totalPage ? (
                                    <>
                                        <tr>
                                            <td
                                                className="st01-item-row st01-align-top"
                                                rowspan="3"
                                            >
                                                {' '}
                                                <span className="st01-bold">
                                                    PREPARED BY
                                                </span>
                                                <br /> &nbsp;{' '}
                                                {si_data.user_prepared_by}
                                            </td>
                                            <td
                                                className="st01-item-row st01-t-border-r"
                                                width="32%"
                                            >
                                                {' '}
                                                <span className="st01-bold">
                                                    INVOICE TO FOLLOW UPON
                                                    ACCEPTANCEC OF GOODS
                                                </span>
                                            </td>
                                            <td
                                                className="st01-item-row st01-align-top st01-text"
                                                rowspan="3"
                                            >
                                                {' '}
                                                <span className="st01-bold">
                                                    RECEIVE THE ABOVE GOODS IN{' '}
                                                </span>
                                                <br />
                                                <span className="st01-bold">
                                                    GOOD ORDER AND CONDITION
                                                </span>{' '}
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                className="st01-item-row st01-align-top st01-t-border-none-b"
                                                rowspan="5"
                                            >
                                                <span>
                                                    REMARKS DELIVERY INSTRUCTION
                                                </span>
                                                <br /> <br />
                                                {si_data.remarks}
                                            </td>
                                        </tr>
                                        <tr> </tr>
                                        <tr>
                                            <td
                                                className="st01-item-row st01-align-top st01-t-border-none-b"
                                                rowspan="3"
                                            >
                                                <span className="st01-bold">
                                                    CHECKED BY
                                                </span>{' '}
                                                <br />
                                                &nbsp; {si_data.user_checked_by}
                                            </td>
                                            <td className="st01-item-row st01-align-top">
                                                <span className="st01-bold">
                                                    SIGNATURE
                                                </span>
                                                <br />
                                                &nbsp;
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="st01-item-row st01-align-top st01-t-border-none-r">
                                                <span className="st01-bold">
                                                    PRINTED NAME
                                                </span>
                                                <br />
                                                &nbsp;
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="st01-item-row st01-align-top st01-t-border-none-r st01-t-border-none-b">
                                                <span className="st01-bold">
                                                    DATE
                                                </span>
                                                <br />
                                                &nbsp;{' '}
                                                {si_data.delivery_date_string}
                                                <br />
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td
                                                className="st01-item-row st01-align-top"
                                                rowspan="3"
                                            >
                                                {' '}
                                                <span className="st01-bold">
                                                    PREPARED BY
                                                </span>
                                                <br /> &nbsp;{' '}
                                            </td>
                                            <td
                                                className="st01-item-row st01-t-border-r"
                                                width="32%"
                                            >
                                                {' '}
                                                <span className="st01-bold">
                                                    INVOICE TO FOLLOW UPON
                                                    ACCEPTANCEC OF GOODS
                                                </span>
                                            </td>
                                            <td
                                                className="st01-item-row st01-align-top st01-text"
                                                rowspan="3"
                                            >
                                                {' '}
                                                <span className="st01-bold">
                                                    RECEIVE THE ABOVE GOODS IN{' '}
                                                </span>
                                                <br />
                                                <span className="st01-bold">
                                                    GOOD ORDER AND CONDITION
                                                </span>{' '}
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                className="st01-item-row st01-align-top st01-t-border-none-b"
                                                rowspan="5"
                                            >
                                                <span>
                                                    REMARKS DELIVERY INSTRUCTION
                                                </span>
                                                <br /> <br />
                                                &nbps;
                                            </td>
                                        </tr>
                                        <tr> </tr>
                                        <tr>
                                            <td
                                                className="st01-item-row st01-align-top st01-t-border-none-b"
                                                rowspan="3"
                                            >
                                                <span className="st01-bold">
                                                    CHECKED BY
                                                </span>{' '}
                                                <br />
                                                &nbsp;
                                            </td>
                                            <td className="st01-item-row st01-align-top">
                                                <span className="st01-bold">
                                                    SIGNATURE
                                                </span>
                                                <br />
                                                &nbsp;
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="st01-item-row st01-align-top st01-t-border-none-r">
                                                <span className="st01-bold">
                                                    PRINTED NAME
                                                </span>
                                                <br />
                                                &nbsp;
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="st01-item-row st01-align-top st01-t-border-none-r st01-t-border-none-b">
                                                <span className="st01-bold">
                                                    DATE
                                                </span>
                                                <br />
                                                &nbsp; <br />
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 pb-0 mb-0">
                        <div className="st01-footer-text">
                            "THIS DOCUMENT IS NOT VALID FOR CLAIMING INPUT TAX"
                        </div>
                        <div className="st01-footer-text">
                            "THIS OFFICIAL RECEIPT SHALL BE VALID FOR FIVE(5)
                            YEARS FROM DATE OF ATP"
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pb-0 mb-0">
                        <span className="pull-right f-12">
                            Page {page}/{totalPage}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        // console.log(this.state);
        this.setState({ totalItemsCount: this.props.si_data.items.length });
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{si_data.status == 'draft' ? si_data.status : ''}</h1>
                </div>
            */}

                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemclassName="page-item"
                        linkclassName="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default StealthDR01;
