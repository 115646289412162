import http from '../../../Services/api.laravel.http';

export const ReturnsService = {
    getStockLogs: (formdata) =>
        http.post('/admin/returns/stock-logs', formdata),
    getReturns: (formdata) =>
        http.post('/admin/returns/stock-log-returns', formdata),
    getSingleStockLog: (id) => http.get('/admin/returns/stock-logs/' + id),
    postReturn: (formdata) =>
        http.post('/admin/returns/stock-logs/return/', formdata),
};
