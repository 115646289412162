import http from '../../../Services/api.laravel.http';

export const POSService = {
    scanBarcode: (formParams) => http.post('/admin/pos/scan', formParams),
    posStore: (formParams) => http.post('/admin/pos/store', formParams),
    posRemovePOSItem: (id) => http.get('/admin/pos/remove-item/' + id),
    posRemoveTransaction: (id, formParams) => http.put('/admin/pos/transaction/archive/' + id, formParams),
    posCloseTransaction: (formParams) => http.post('/admin/pos/transaction/close', formParams),
    posTransactionChecker: () => http.get('/admin/pos/checker'),
    posDaily: (formParams) => http.post('/admin/pos/daily', formParams),
    // posDailyView: (id) => http.get('/pos/daily/' + id),
    posDailyView: (formParams) => http.post('/admin/pos/daily/single', formParams),
    posSalesList: (formParams) => http.post('/admin/pos/sales', formParams),
    posTransactionView: (id) => http.get('/admin/pos/transaction/' + id),
    posTransactionViewEdit: (id) => http.get('/admin/pos/transaction/view/edit/' + id),
    posUpdateTransactionItem: (formParams) => http.post('/admin/pos/update', formParams),
    posUpdateTransaction: (formParams) => http.post('/pos/transaction/update', formParams)
}

