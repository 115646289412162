import http from "../../../Services/api.laravel.http";

export const SettingAppService = {
  //   getSettingApp: () => http.get("/settings/subscriber-setting"), // Note: this api also use in template setting
  SettingAppPost: (formParams) =>
    http.post("/settings/subscriber-setting", formParams), //why is this named app post?
  SettingAppLogoUpload: (formParams) =>
    http.post("/settings/subscriber-setting/logo-upload", formParams),
  SettingAppPostSetDefault: (formParams) =>
    http.post("/settings/subscriber-setting/set-default", formParams),
  SettingAppPostApplication: (formParams) =>
    http.post("/settings/subscriber-setting/application", formParams),
  getPaymentTerms: () => http.get("admin/term-condition"),
};
