import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { updateSingleTermCondition, handleInputChange, toggleDrawer } from '../../../../redux/actions/term_condition/term_condition.actions';
import ReactTooltip from 'react-tooltip';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

function TermConditionEditGlobal({ term_condition: { toggle_list, single_term_condition } , handleInputChange, updateSingleTermCondition, toggleDrawer, pageOrigin }) {

    return (
        <form onSubmit={e => updateSingleTermCondition(e, pageOrigin)}>
            <ReactTooltip  effect="solid" event="click"/>
            <section className="row f-regular">
                <div className="col-12">
                    {/* <label className="label-control f-regular">Name: <span className='text-danger'>*</span></label> */}
                    <div className="form-group row">
                        <div className="col-md-11 col-10">
                            <input value={single_term_condition.title} onChange={handleInputChange} type="text" name="title" className="form-control f-regular" placeholder="Enter Title" required/>
                        </div>
                        <div className="float-right pl-0 col-1 col-md-1">
                            <div className="input-group-prepend">
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group row">
                        <div className="col-md-4 col-12 label-control f-regular"></div>
                        <div className="col-md-7 col-10 text-right">
                            <button onClick={() => toggleDrawer(true, 'edit_modal', true)} type="button" className="btn btn-sm btn-danger terms-toggle f-regular mr-1"><FormatListBulletedIcon/></button>
                            <button type="submit" className="btn btn-primary btn-sm btn-update f-regular">Update</button>
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1"></div>  
                    </div>
                </div>
            </section>
        </form>
    )
}

const mapStateToProps = state => ({
	term_condition: state.term_condition
});

export default connect(mapStateToProps, { handleInputChange, updateSingleTermCondition, toggleDrawer })(TermConditionEditGlobal);

