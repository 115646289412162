import React from 'react';
import { Modal } from 'react-bootstrap';
import PaymentTermsCreateGlobal from './../../Settings/SalesInvoice/PaymentTerms/TermCondition.create.global';

function PaymentTermsModal({ show, onHide }) {
    return (
        <Modal centered dialogClassName="modal-container"  show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton dialogClassName="p-0">
                <Modal.Title> Payment Terms</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PaymentTermsCreateGlobal pageOrigin="sales_invoice"/>
            </Modal.Body>
        </Modal>
    )
}

export default PaymentTermsModal;
