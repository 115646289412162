import React, { Fragment, useState, useEffect } from "react";
import Spinner from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {  Show, useAccess } from "react-redux-permission";
import BusinessProfile from './Setting.business.profile';
import Application from './SettingApp.application';
import Logo from './SettingApp.logo';
import Theme from './SettingApp.theme';

function SettingsApp ({ setting_app: { single_setting_app, edit_form }, handleInputChange, handleSubmit, getSettingApp  }) {

    const [selectedTab,SetSelectedTab] = useState('Business Profile');

    const [tabs, setTabs] = useState([
        { key: 'Business Profile', class : 'nav-link inv-top-nav inv-top-nav-active mr-50' },
        { key: 'Application', class : 'nav-link inv-top-nav mr-50' },
        { key: 'Logo', class : 'nav-link inv-top-nav mr-50' },
        { key: 'Theme', class : 'nav-link inv-top-nav mr-50' },
    ]);

    function handleChangeTab(tab)
    {
      SetSelectedTab(tab);
      tabs.map((list) => {
        list.class = list.key == tab ? "nav-link inv-top-nav inv-top-nav-active mr-50": "nav-link inv-top-nav mr-50";
        return list;
      });
      setTabs(tabs);
    }
    
    
    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1.5">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link to="/settings/app" className="text-white f-regular">App</Link></li>
                                </ol>
                            </div>
                        </div>
                        {/* <a href="/settings/app/create" className="btn btn-primary pull-right btn-fix-header f-regular"><i className="ft ft-plus"></i> Create New</a> */}
                    </div>
                </div>
                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header"></div> */}
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                        <nav class="nav f-regular">
                                            {
                                                tabs.map(item => ( <a onClick={() => handleChangeTab(item.key) } class={item.class}>{item.key} {item.key == 'Theme' && <span className="mt-0 lbl-status lbl-coming-soon">Coming Soon</span>}</a> ))
                                            }
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="row f-regular">
                        <div className="col-sm-12">
                            {(() => {
                                switch(selectedTab){
                                    case 'Business Profile':
                                        return <BusinessProfile/>; 
                                    case 'Application':
                                        return <Application/>;
                                    case 'Logo':
                                        return <Logo/>
                                    case 'Theme':
                                        return <Theme/>
                                    default: 
                                        return null;
                                }
                            })()}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    setting_app: state.setting_app
});

export default connect(mapStateToProps, { })(SettingsApp);
