import React, { Fragment, useEffect, useState } from 'react';
import './box.css'
import { connect } from 'react-redux';
import { toggleItemDm, itemDmInput, computeItemDm } from '../../redux/actions/shipping/shipping.action';

const ItemDM = ({shipping : {selected_po}, index, toggleItemDm, itemDmInput, computeItemDm}) => {
    
    return (
        <div className='box-pop ml-19'>
            <div className='box-header'>
                <a onClick={() => toggleItemDm(index)}><i className='ft ft-x'></i></a>
            </div>
            <div className='row mb-1'>
                <label className='col-3'>Length</label>
                <div className='col-2'></div>
                <div className='col-7'>
                    <div className="input-group">
                        <input type="number" className='form-control text-right' name="length" placeholder='0' onChange={(e) => itemDmInput(e, index)} value={selected_po.purchase_order_items[index].item_dm.length}/>
                        <div className="input-group-append">
                            <span className="input-group-text">m</span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='row mb-1'>
                <label className='col-3'>Width</label>
                <div className='col-2'></div>
                <div className='col-7'>
                    <div className="input-group">
                        <input type="number" className='form-control text-right' name="width" placeholder='0' onChange={(e) => itemDmInput(e, index)} value={selected_po.purchase_order_items[index].item_dm.width}/>
                        <div className="input-group-append">
                            <span className="input-group-text">m</span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='row mb-1'>
                <label className='col-3'>Height</label>
                <div className='col-2'></div>
                <div className='col-7'>
                    <div className="input-group">
                        <input type="number" className='form-control text-right' name="height" placeholder='0' onChange={(e) => itemDmInput(e, index)} value={selected_po.purchase_order_items[index].item_dm.height}/>
                        <div className="input-group-append">
                            <span className="input-group-text">m</span>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div className='row mb-1'>
                <div className='col-12 text-right'>
                    <button className='btn btn-primary' onClick={() => computeItemDm(index)}>Ok</button>
                </div>
            </div>
        </div>
        
    )
}


const mapStateToProps = state => ({
    shipping : state.shipping
})

export default connect(mapStateToProps, {toggleItemDm, itemDmInput, computeItemDm})(ItemDM);