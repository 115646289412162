import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';

export class ReceivingPrintContent extends React.PureComponent {
    // function ReceivingPrintContent({ po_items, receivingData, deliveryDate, single_receiving, }) {

    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.po_items.length;

        this.state = {
            po_items: this.props.po_items,
            receivingData: this.props.receivingData,
            deliveryDate: this.props.deliveryDate,
            single_receiving: this.props.single_receiving,
            activePage: 1,
            itemsCountPerPage: 15,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    renderPrint(page, totalPage) {
        let img_url = '/app-assets/images/logo/sample-logo.png';

        let po_items = this.state.po_items;
        let receivingData = this.state.receivingData;
        let deliveryDate = this.state.deliveryDate;
        let single_receiving = this.state.single_receiving;

        const tableRows = [];

        const totalItemsCount = po_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        // if(this.state.print == 0)
        // {
        //     extraRowsCount -= 15;
        // }

        po_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
            }
        });

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';

        const template_setting = this.state.receivingData.template_setting;
        const supplierAddress = [
            receivingData.supplier.street1,
            receivingData.supplier.street2 && ', ',
            receivingData.supplier.street2,
            (receivingData.supplier.street1 ||
                receivingData.supplier.street2) &&
                ', ',
            receivingData.supplier.city,
            receivingData.supplier.city && receivingData.supplier.state && ', ',
            receivingData.supplier.state,
        ]
            .filter(Boolean)
            .join('');

        return (
            <div className="margin-1p5em px-1 print-page mt-1">
                <div className="print-logo-container print-header-container">
                    {/* { template_setting && template_setting.company_logo && <img src={template_setting.company_logo} className="print-logo"/> }
                    { template_setting && template_setting.company_logo === '' ? template_setting ? <div className="c-name">{template_setting.company_name}</div> : <div className="print-logo-empty"></div> : null}
                     */}
                    <img src={template_setting && "company_logo" in template_setting ? template_setting.company_logo : "/BlitzLogo.png"} alt="logo" className="print-logo" />
                    <div className="print-header text-right">
                        <span className="print-header-text">RECEIVING</span>
                    </div>
                </div>

                <div className="print-body row print-content-block">
                    <div className="col-md-12 pr-0">
                        <span className="f-20b">Invoice From</span>
                    </div>
                    <div className="col-md-4 col-print-4 ">
                        <span className="f-16b">
                            {receivingData.supplier ? (
                                <Link
                                    to={`/purchases/suppliers/history/${receivingData.supplier._id}`}
                                    className="txt-underline"
                                    target="_blank"
                                >
                                    {receivingData.supplier.name}
                                </Link>
                            ) : (
                                ''
                            )}
                        </span>
                        <p className="f-12">{supplierAddress}</p>
                    </div>
                    <div className="col-md-4 col-print-4 "></div>
                    <div className="col-md-4 col-print-4 pr-0">
                        <span className="f-16b">Receipt No</span>{' '}
                        <span className="f-right">
                            {receivingData.item_receipt_no}
                        </span>
                        <br />
                        <span className="f-16b">Date</span>{' '}
                        <span className="f-right">
                            {defaultFormat(deliveryDate)}
                        </span>
                    </div>
                    <div className="col-md-12 pr-0">
                        {receivingData.status == 'draft' ? (
                            <div class="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table table-striped table-print">
                            <thead>
                                <tr className="print-table-header">
                                    <th>#</th>
                                    <th>Item Description</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Received</th>
                                    <th className="text-center">SRP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {po_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td style={DynamicStringStyle}>
                                                    <Link
                                                        to={`/inventory/view/${item._id}`}
                                                        className="txt-underline"
                                                        target="_blank"
                                                    >
                                                        <DynamicFont
                                                            content={
                                                                item.product_name
                                                            }
                                                        />
                                                    </Link>
                                                    {item.barcode && (
                                                        <>
                                                            <br />
                                                            <span className="f-12 text-dark">
                                                                {
                                                                    item.barcode
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                    {item.description && (
                                                        <>
                                                            <br />
                                                            <span className="f-12 text-dark">
                                                                {
                                                                    item.description
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                    {item.brand != null && (
                                                        <>
                                                            <br />
                                                            <span className="f-12 text-dark">
                                                                {item.brand}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {formatPrice(
                                                        item.cost,
                                                        currencyDisplay
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {numberFormat(
                                                        item.received_qty,
                                                        0
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {formatPrice(
                                                        item.srp,
                                                        currencyDisplay
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div>
                    {page == totalPage ? (
                        <Fragment>
                            <div className="col-md-6 col-print-6">
                                <span className="f-14b">&nbsp;</span>
                                <br />
                                <br />
                                <span className="f-14b">Remarks</span>
                                <p className="f-12">
                                    {receivingData.remarks != null
                                        ? receivingData.remarks
                                        : ''}
                                    &nbsp;
                                </p>
                            </div>
                            <div className="col-md-2 col-print-2"></div>
                            {/* <div className="col-md-4 col-print-4 ">
                                <span className="f-14b">Sub Total:</span><span className="f-right f-14b">{numberFormat(re.sub_total)}</span><br/>
                                <span className="f-14b">Discount:</span><span className="f-right f-14b">{numberFormat(re.discount)} </span><br/>
                                <span className="f-14b">Tax:</span><span className="f-right f-14b">{numberFormat(re.vat, 0)}% </span><br/>
                                <section class="print-total">
                                    <span className="f-16b">Total:</span><span className="f-right f-16b">{numberFormat(re.total)}</span><br/>
                                </section>
                            </div> */}
                            <div className="col-12 pr-0">
                                <div className="row">
                                    <div className="col-4">
                                        <span className="f-14b">
                                            Received by:
                                        </span>
                                        <p className="f-12">
                                            {
                                                receivingData.users.received_by
                                                    .name
                                            }
                                            &nbsp;
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="f-14b">
                                            Checked by:
                                        </span>
                                        <p className="f-12">
                                            {
                                                receivingData.users.checked_by
                                                    .name
                                            }
                                            &nbsp;
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        {/* <span className="f-14b">Approved by:</span> */}
                                        <p className="f-12">&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="print-footer h-p25em">
                    {/* <div className="row">
                        <div className="col-md-6 col-print-6 pb-0 mb-0">
                            <ul className="f-16b ul-print-paw">
                                <li>Phone #</li>
                                <li>Address</li>
                                <li>Website</li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-10 col-print-10 pb-0 mb-0">
                            <ul className="f-12b ul-print-paw">
                                {template_setting && (
                                    <li>{template_setting.company_name}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_landline}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_email}</li>
                                )}
                                {template_setting && (
                                    <li className="mb-2">
                                        {template_setting.company_address}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-md-2 col-print-2 pb-0 mb-0">
                            <span className="f-right mt-25-f">
                                Page {page}/{totalPage}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        this.setState({ totalItemsCount: this.props.po_items.length });
        return (
            <Fragment>
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default ReceivingPrintContent;
