import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { removeItemTag, updateItemTags } from './../../../redux/actions/product/product.actions';
import { MiniSpinner } from '../../layouts/Spinner';

function ProductTagModal({ product:{ loading, is_remove_tag, removable_tags }, show, onHide, removeItemTag, updateItemTags, _item_tags_options }) {

    return (
        <Modal centered dialogClassName="modal-container" show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header className="pb-0" closeButton> <Modal.Title>All Custom Tags</Modal.Title> </Modal.Header>
            <Modal.Body>
                <div className="border-primary p-1 f-regular">
                    {
                        loading ? <MiniSpinner/> :
                        _item_tags_options.length > 0 ? 
                            _item_tags_options.map((tag, key) =>  
                                <Fragment>
                                    <div className="badge-custom">{tag.label} <span className="close-tag-icon" onClick={() => removeItemTag(key, tag.value)}>x</span></div>
                                </Fragment>
                            )
                        : <h4 className="text-center my-1">--You have no tags.--</h4>
                    }
                </div>
                <div className="mt-1 f-regular"></div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
    product: state.product
})

export default connect(mapStateToProps, { removeItemTag, updateItemTags })(ProductTagModal);
