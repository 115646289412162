import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';

export const InvoiceService = {
    getSingleInvoice: (id) => http.get('/admin/invoice/' + id),
    ArchiveInvoice: (formParams) =>
        http.post('/admin/invoice/archives/list', formParams),
    InvoiceCreate: (formParams) =>
        http.post('/admin/invoice', formParams),
    paginateWithSearchInvoices: (formParams, status) =>
        http.post('/admin/invoice/search/'+ status, formParams),
    updateSingleInvoice: (formParams, id) =>
        http.put('/admin/invoice/' + id, formParams),
    getSingleCustomer: (id) => http.get('/admin/customer/' + id),
    getCustomers: (formdata) => http.post('/admin/invoice/si-customer', formdata), // get all customer (new)
    getProducts: (formdata) =>
        http.post('/admin/invoice/si-product', formdata), // get all the products (new)
    getSalesOrders: (formdata) =>
        http.get('/admin/invoice/si-so', formdata), // get all the so (new)
    getSingleSalesOrder: (id) =>
        http.get('/admin/invoice/si-so/?id=' + id), // get all the so (new)
    getDeliveries: (formdata) =>
        http.get('/admin/invoice/si-dr', formdata), // get all the dr (new)
    getSingleDelivery: (id) =>
        http.get('/admin/invoice/si-dr/?id=' + id), // get all the dr (new)
    printPreview: (formParams) =>
        http.post('setting/invoice-setting/preview', formParams),
    getTermsConditions: () => http.get('admin/term-condition'),
    CheckInStock: (formParams) =>
        http.post('/admin/invoice/check-stock', formParams),
    goPage: (formParams) =>
        http.post('/admin/invoice/go-page', formParams),
    sendEmail: (id) => http.get('/admin/invoice/email/' + id),
    getPdf: (id) => httpWithFile.get('/admin/invoice/pdf/' + id),
    getEmailConfig: () => http.get('/settings/email/security'),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    getInvTemplates: () => http.get('/admin/invoice-template'),
    updatePaymentStatus: (formdata) =>
        http.post('/admin/invoice/update-payment', formdata),
    cancelInvoice: (id) => http.get('/admin/invoice/cancel/' + id),
};

// ** THIS ARE THE OLD API, WE KEEP THIS FOR REFERENCE ONLY
// getInvTemplates: () => http.get('/admin/setting/invoice-setting/get/invoice/themes'), // get all the products
// getInvTemplates: () => http.get('/admin/setting/invoice-setting/get/invoice/themes'), // get all the products
// getProducts: (formdata) => http.post('/admin/product/search', formdata), // get all the products (old)
// getCustomers: (search) => http.get('/admin/invoice/customer?search=' + search), // get all customer (old)
// getDisplayCustomer: (id) => http.get('/admin/invoice/selected/' + id), // get customer data base on id
