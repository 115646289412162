import * as TYPE from '../types';

const initialState = {
    po : [],
    selected_po : null,
    po_data : [],
    loading : false,
    supplier_name : '',
    po_date : '',
    freight_cost : 0,
    popToggle : false,
    other_fees : [],
    inputs : {
        broker_fee : 0,
        cost : 0,
        delivery_fee : 0,
        duties_taxes : 0,
        freight_cost : 0,
        item_ship_cost : 0,
        others : 0,
        port_charge : 0,
        rate : 0,
        rate_m3 : 0,
        total_boxes : 0,
        total_dimension : 0, //volume
        total_item_volume : 0,
        total_items : 0,
        total_shipping : 0,
        total_weight : 0
    },
    shippingData : [],
    isShipLoading : true,
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    sort_order_name : '',
    sort_order_direction : '',
    pageOrigin : '',
    rows_to_delete : [],
    set_row_checked: false,
    po_no : '',
    shp_no : '',
    shp_items : [],
    shipping : []

}

const shippingCostReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case TYPE.SHIPPING_PO_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPE.SHIPPING_PO:
            return {
                ...state,
                po: action.payload
            }
        case TYPE.SHIPPING_PO_DATA: 
            return {
                ...state,
                po_data : action.payload
            }
        case TYPE.SHIPPING_PO_SELECTED:
            return{
                ...state,
                selected_po : action.payload
            }
        case TYPE.FREIGHT_COST:
            return{
                ...state,
                freight_cost : action.payload
            }
        case TYPE.POP_TOGGLE :
            return {
                ...state,
                popToggle : action.payload
            }
        case TYPE.SHIPPING_INPUTS:
            return {
                ...state,
                inputs : action.payload
            }
        case TYPE.SHIPPING_OTHER_FEE:
            return {
                ...state,
                other_fees : action.payload
            }
        case TYPE.IS_SHIP_LOADING:
            return {
                ...state,
                isShipLoading : action.payload
            }
        case TYPE.SHIPPING_DATA :
            return {
                ...state,
                shippingData : action.payload.shipping,
                pagination : action.payload.pagination
            }
        case TYPE.SHIPPING_ROW_DELETE: 
            return {
                ...state,
                rows_to_delete : action.payload
            }
        case TYPE.SHIPPING_SET_ROW_CHECKED: 
            return {
                ...state,
                set_row_checked: true
            }
        case TYPE.SHIPPING_NO:
            {
                return {
                    ...state,
                    shp_no : action.payload
                }
            }
        case TYPE.SHIPPING_PO_NO:
            return {
                ...state,
                po_no : action.payload
            }
        case TYPE.SHIPPING:
            return {
                ...state,
                shipping : action.payload
            }
        default:
            return state;
    }
}


export default shippingCostReducer;