import http from "../../../Services/api.laravel.http";
import httpWithFile from "../../../Services/api.laravel.httpWithFile";

export const QuotationService = {
  getSingleQuotation: (id) => http.get("/admin/quotation/" + id),
  ArchiveQuotation: (formParams) =>
    http.post("/admin/quotation/archives/list", formParams),
  quotationPost: (formParams) => http.post("/admin/quotation", formParams),
  paginateWithSearchQuotations: (formParams, status) =>
  http.post("/admin/quotation/search/" + status, formParams),
  updateSingleQuotation: (formParams, id) =>
    http.put("/admin/quotation/" + id, formParams),
  getSingleCustomer: (id) => http.get("/admin/customer/" + id),
  // getCustomers: () => http.get("/admin/quotation/customer"), // get all customers
  // getQuotationTemplates: () => http.get('/admin/setting/quotation-setting/get/quotation/themes'),
  // getCustomers: (search) =>
  //   http.get("/admin/sales-invoice/customer?search=" + search), // get all customer (old)
  getCustomers: (search) =>
    http.get("/admin/customer/customer?search=" + search), // get all customer
  salesInvoicePost: (formParams) =>
    http.post("/admin/sales-invoice", formParams),
  // getProducts: (formdata) => http.post("/admin/product/search", formdata), // get all the products (old)
  getProducts: (formdata) => http.post("/admin/quotation/product", formdata), // get all the products
  getInvTemplates: () => http.get("/admin/sales-invoice-template"),
  getTermsConditions: () => http.get("admin/term-condition"),
  goPage: (formParams) => http.post("/admin/quotation/go-page", formParams),
  sendEmail: (id) => http.get("/admin/quotation/email/" + id),
  getPdf: (id) => httpWithFile.get("/admin/quotation/pdf/" + id),
  getEmailConfig: () => http.get("/settings/email/security"),
  verifyPassword: (formdata) => http.post("/user/password/verify", formdata),
  getQuotationTemplates: () => http.get("/admin/quotation-template"),
  cancelQuotation: (id) => http.get("/admin/quotation/cancel/" + id),
};
