import { SET_LOADING, GET_LOGS, GET_SINGLE_LOG, SET_MODAL, CLEAR_LOG, SEARCH_INPUT, SET_MINI_LOADING, SORT_BY_COLUMN } from '../types';

const initialState = {
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8},
    logs: [],
    single_log: {
        _id: '',
        log_event: '',
        log_table: '',
        log_old_values: [],
        log_new_values: [],
        log_url: '',
        log_ip_address: '',
        log_user_agent: '',
        new_items: [],
        old_items: [],                   
        created_at: '',
        user_details: {},
    },
    search: '',
    loading: false,
    mini_loading: false,
    view_modal: false,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
}

const logReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload
            }
        case GET_LOGS:
            return {
                ...state,
                logs: action.payload.logs,
                pagination: action.payload.pagination
            }
        case GET_SINGLE_LOG: 
            return {
                ...state,
                single_log: action.payload
            }
        case SET_MODAL:
            if(action.payload.status == false)
            {
                return {
                    ...state,
                    [action.payload.modal]: action.payload.status,
                    single_log: {
                        _id: '',
                        log_event: '',
                        log_table: '',
                        log_old_values: [],
                        log_new_values: [],
                        log_url: '',
                        log_ip_address: '',
                        log_user_agent: '',
                        new_items: [],
                        old_items: [],                   
                        created_at: '',
                        user_details: [],
                    },
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.modal]: action.payload.status
                }
            }
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload
            }
        case CLEAR_LOG:
            return {
                ...state,
                single_log: {
                    _id: '',
                    log_event: '',
                    log_table: '',
                    log_old_values: [],
                    log_new_values: [],
                    log_url: '',
                    log_ip_address: '',
                    log_user_agent: '',
                    new_items: [],
                    old_items: [],                   
                    created_at: '',
                    user_details: [],
                },
                search: '',
                view_modal: false,
                loading: false,
                mini_loading: false
            }
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        default:
            return state;
    }
}

export default logReducer;