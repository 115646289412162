import React, { Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MiniSpinner } from '../../../layouts/Spinner';
import { NumberFormat } from '../../../../Services/_numberformat.service';
import { connect } from 'react-redux';

function LogViewModal ({ single_log: { _id, log_event, log_table, log_old_values, log_new_values, log_url, log_ip_address, log_user_agent, new_items, old_items, created_at, user_details }, mini_loading, show, onHide }) {
    
    return (
        <Modal dialogClassName={log_event == 'create' || log_event == 'archive' ? 'modal-container' : 'log-show-modal'} show={show} size="lg" onHide={onHide} animation={true}>
            <Modal.Header closeButton><Modal.Title>Logs Information</Modal.Title> </Modal.Header>
            <Modal.Body>
                {
                    mini_loading ? <MiniSpinner/> :
                    <Fragment>
                        <section className="row mb-2">
                            <div className="col-sm-12">
                                <div className="mb-1"><span><strong>Log Event: </strong></span>{!log_event ? null : log_event.toUpperCase()}</div>
                                <div className="mb-1"><span><strong>Log Table: </strong></span>{log_table}</div>
                                <div className="mb-1"><span><strong>Log URL: </strong></span>{log_url}</div>
                                <div className="mb-1"><span><strong>Log IP Address: </strong></span>{log_ip_address}</div>
                                <div className="mb-1"><span><strong>Log's ID: </strong>{_id}</span></div>
                                <div className="mb-1"><span><strong>Created by: </strong></span>  {!user_details ? null : user_details.admin_name} </div>
                                <div className="mb-1"><span><strong>Created at: </strong></span>{created_at}</div>
                                <div className="mb-1"><span><strong>Log User Agent: </strong></span>{log_user_agent}</div>
                            </div>
                        </section>
                        <section className="row">
                            {
                                (log_event == 'create' || log_event == 'update') &&
                                <div className={log_event === 'create' ? 'col-sm-12' : 'col-sm-6'}>
                                    <div class="card card-body border-top-primary border-top-5 border-primary">
                                        <h4 class="card-title">LOG NEW VALUES </h4>
                                        {
                                            log_new_values && log_new_values.map(log => (
                                                <div>
                                                    {
                                                        Object.keys(log).map((key) => (
                                                            <div className="mb-1">
                                                                { <strong>{ key } : </strong> } 

                                                                {/* output the value */}
                                                                { (key == 'country' || key == 'terms') && ((log[key] == null || log[key] == '') ? '' : log[key].label) }
                                                                { 
                                                                    (key == 'amount' || key == 'srp' || key == 'cost' || key =='sub_total' || key == 'total') ? NumberFormat(log[key]) : (typeof log[key] !== 'object') ? log[key] : null
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    { log_new_values.length > 1 ? <hr className="border-secondary"/> : null }
                                                </div>
                                            ))
                                        }

                                        { new_items && <div> <hr className="border-primary"/> <div className="font-medium-3 text-bold-600 mb-1"><i className="ft ft-list"></i> ITEMS: </div> </div> }
                                        
                                        <div className="list-group">
                                            {
                                                new_items && new_items.map(item => (
                                                    <div className="list-group-item list-group-item-action flex-column align-items-start text-secondary">
                                                        {
                                                            Object.keys(item).map((key) => (
                                                                <div className="mb-1">
                                                                    { <strong>{ key } : </strong> } 
                                                                    { (key == 'amount' || key == 'srp' || key == 'cost' || key =='sub_total' || key == 'total')? NumberFormat(item[key]) : item[key] }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        { new_items && <div className="mt-1"> <div><strong>Total Items: </strong>{new_items.length}</div></div> }
                                    </div>
                                </div>
                            }

                            {
                                (log_event == 'update' || log_event == 'archive') &&
                                <div className={log_event === 'archive' ? 'col-sm-12' : 'col-sm-6'}>
                                    <div class="card card-body border-top-danger border-top-5 border-danger">
                                        <h4 class="card-title">LOG OLD VALUES </h4>
                                        {
                                            log_old_values && log_old_values.map(log => (
                                                <div>
                                                    {
                                                        Object.keys(log).map((key) => (
                                                            <div className="mb-1">
                                                                { <strong>{ key } : </strong> } 
                                                                                                                            
                                                                {/* output the value */}
                                                                { (key == 'country' || key == 'terms') && ((log[key] == null || log[key] == '') ? '' : log[key].label) }
                                                                { 
                                                                    (key == 'amount' || key == 'srp' || key == 'cost' || key =='sub_total' || key == 'total') ? NumberFormat(log[key]) : (typeof log[key] !== 'object') ? log[key] : null
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    { log_old_values.length > 1 ? <hr className="border-secondary"/> : null }
                                                </div>
                                            ))
                                        }

                                        { old_items && <div> <hr className="border-primary"/> <div className="font-medium-3 text-bold-600 mb-2"><i className="ft ft-list"></i> ITEMS: </div> </div> }
                                        
                                        <div className="list-group">
                                            {
                                                old_items && old_items.map(item => (
                                                    <div className="list-group-item list-group-item-action flex-column align-items-start text-secondary">
                                                        {
                                                            Object.keys(item).map((key) => (
                                                                <div className="mb-1">
                                                                    { <strong>{ key } : </strong> } 
                                                                    { (key == 'amount' || key == 'srp' || key == 'cost' || key =='sub_total' || key == 'total')? NumberFormat(item[key]) : item[key] }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        { old_items && <div className="mt-1"> <div><strong>Total Items: </strong>{old_items.length}</div></div> }
                                    </div>
                                </div>
                            }
                        </section>
                    </Fragment>
                }
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
    single_log: state.log.single_log,
    mini_loading: state.log.mini_loading
})

export default connect(mapStateToProps, {})(LogViewModal);