import {
  SET_LOADING,
  GET_CUSTOMERS,
  CUSTOMER_INPUT_CHANGE,
  GET_SINGLE_CUSTOMER,
  SET_PAGE,
  CLEAR_CUSTOMER,
  SEARCH_INPUT,
  SELECT_COUNTRY,
  HANDLE_MOBILE,
  ADD_FILE,
  CUSTOMER_REMOVE_FILE,
  CUSTOMER_UPDATE_FILE,
  SET_MINI_LOADING,
  DATE_FILTER_INPUT,
  CUSTOMER_UPLOADS_TO_REMOVE,
  SORT_BY_COLUMN,
  GET_ROWS_TO_DELETE,
  SET_ROW_CHECKED,
  CUSTOMER_HISTORY_DATE_INPUT_CLEAR,
  CUSTOMER_GET_UPLOADED_FILES,
  SET_DYNAMIC_LOADING,
  CUSTOMER_CLEAR_UPLOAD,
} from "../../types";

import { ToastSuccess, ToastDanger } from "../../../Services/_toast.service";
import $ from "jquery";
import { CustomerService } from "./_service.customer";
import { SwalWarning, SwalWarningHtml } from "../../../Services/_swal.service";
import {
  setModal,
  getCustomers as getCustomersSI,
  getSalesOrders,
  handleSelectCustomer as handleSelectCustomerSI,
} from "../sales_order/sales_order.actions"; // modal use in another modules
import {
  setModal as setModalQI,
  getCustomers as getCustomersQU,
  getQuotations,
  handleSelectCustomer as handleSelectCustomerQU,
} from "../quotation/quotation.actions"; // modal use in another modules
import {
  setModal as setModalDR,
  getCustomers as getCustomersDR,
  handleSelectCustomer as handleSelectCustomerDR,
} from "../delivery/delivery.actions"; // modal use in another modules
import { selectCustomer, setModal as setModalPOS } from "../pos/pos.actions"; // actions from POS
import { Toast } from "react-bootstrap";
import { blockRoute, setMUIPage } from "../helper/helper.actions";
import { getNumRows } from "../../../Utils/Common";

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
  if (e.target.value) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({
    type: CUSTOMER_INPUT_CHANGE,
    payload: { key: e.target.name, value: e.target.value },
  });
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
  dispatch({ type: SEARCH_INPUT, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) =>
  dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
  dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearCustomer = () => async (dispatch) =>
  dispatch({ type: CLEAR_CUSTOMER });

export const csClearUpload = () => async (dispatch) =>
  dispatch({ type: CUSTOMER_CLEAR_UPLOAD });

// set page
export const setPage =
  (page, status = true) =>
  async (dispatch) => {
    dispatch({ type: SET_PAGE, payload: { page, status } });
  };

// country select
export const handleSelectCountry = (selectOption) => async (dispatch) => {
  if (selectOption) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({ type: SELECT_COUNTRY, payload: selectOption });
};

// handle mobile
export const handleMobile = (mobile) => async (dispatch) => {
  if (mobile) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({ type: HANDLE_MOBILE, payload: mobile });
};

// fetch customers
export const getCustomers =
  (pageNumber, rows_per_page) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    let { search, pagination, sort_order_name, sort_order_direction } =
      getState().customer;
    let rows_per_page_val =
      rows_per_page != null ? rows_per_page : getNumRows("customer") || 10;

    // let searchPostParams = {
    //   search: search,
    //   activePage: pagination.activePage,
    //   page: pageNumber,
    //   rows_per_page: rows_per_page_val,
    //   sort_order_name: sort_order_name,
    //   sort_order_direction: sort_order_direction,
    // };

    //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
    let searchPostParams = {
      search,
      options: {
        page: pageNumber,
        limit: rows_per_page_val,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };

    CustomerService.paginateWithSearchCustomers(searchPostParams)
      .then((res) => {
        // let pagination = {
        //   totalCount: res.data.customers.total,
        //   activePage: res.data.customers.current_page,
        //   itemsCountPerPage: res.data.customers.per_page,
        //   totalItemsCount: res.data.customers.total,
        // };

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let pagination = {
          totalCount: res.data.total_docs,
          activePage: res.data.page,
          itemsCountPerPage: res.data.limit,
          totalItemsCount: res.data.total_docs,
        };

        dispatch({
          type: GET_CUSTOMERS,
          //   payload: { customers: res.data.customers.data, pagination },
          payload: { customers: res.data.docs, pagination: pagination },
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        // ToastDanger("Network error, please reload the page.");
        console.log(error);
      });
  };

// fetch single customer
export const getSingleCustomer =
  (id, status = null) =>
  async (dispatch) => {
    dispatch(setMiniLoading(true));

    CustomerService.getSingleCustomer(id)
      .then((res) => {
        let customer = res.data.customer;
        customer.tin = res.data.customer.tin ? res.data.customer.tin : "";

        dispatch({ type: GET_SINGLE_CUSTOMER, payload: customer });

        if (status == "delete") {
          dispatch(archive());
        }

        dispatch(setMiniLoading(false));
      })
      .catch((error) => {
        dispatch(setMiniLoading(false));
        // ToastDanger("Network error, please reload the page.");
        console.log(error);
        dispatch(setMiniLoading(false));
      });
  };

// store
export const customerPost = (e, pageOrigin) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_customer } = getState().customer;

  let formdata = new FormData();
  //   formdata.append("name", single_customer.name);
  //   formdata.append("business_name", single_customer.business_name || "");
  //   formdata.append("street1", single_customer.street1 || "");
  //   formdata.append("street2", single_customer.street2 || "");
  //   formdata.append("city", single_customer.city || "");
  //   formdata.append("state", single_customer.state || "");
  //   formdata.append("country", JSON.stringify(single_customer.country) || "");
  //   formdata.append("fax", single_customer.fax || "");
  //   formdata.append("email", single_customer.email || "");
  //   formdata.append("landline", single_customer.landline || "");
  //   formdata.append("mobile", single_customer.mobile || "");
  //   formdata.append("contact_person", single_customer.contact_person || "");
  //   formdata.append("tin", single_customer.tin || "");

  if (single_customer.uploads.length > 0) {
    for (const [key, value] of Object.entries(single_customer.uploads)) {
      formdata.append(`uploads[]`, value); // required to send the array of files
    }
  }

  let arrOrigin = ["quotation", "sales_order", "deliveries"];
  if (arrOrigin.includes(pageOrigin)) {
    formdata.append("select_customer", true);
  }

  $(".btn-save").attr("disabled", "disabled").html("Saving...");

  dispatch(setMUIPage(1));

  //   CustomerService.customerPost(formdata)
  CustomerService.customerPost(single_customer)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          const selectedCustomer = res.data.select_customer
            ? {
                value: res.data.select_customer._id,
                label: res.data.select_customer.name,
                name: "customer_id",
              }
            : {};

          if (pageOrigin == "customer") {
            dispatch(setPage("main_page"));
            dispatch(clearCustomer());
          }

          if (pageOrigin == "sales_order") {
            dispatch(getCustomersSI());
            dispatch(handleSelectCustomerSI(selectedCustomer));
            dispatch(setModal("customer_modal", false));
          }

          if (pageOrigin == "quotation") {
            dispatch(getCustomersQU());
            dispatch(handleSelectCustomerQU(selectedCustomer));
            dispatch(setModalQI("customer_modal", false));
          }

          if (pageOrigin == "deliveries") {
            dispatch(getCustomersDR(res.data.ret_val));
            dispatch(handleSelectCustomerDR(selectedCustomer));
            dispatch(setModalDR("customer_modal", false));
          }

          if (pageOrigin == "POS") {
            dispatch(getCustomersQU("pos"));
            dispatch(setModalPOS("customer_modal", false));
          }

          ToastSuccess(res.data.message);
          break;
        default:
          break;
      }

      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        // ToastDanger("Network error, please reload the page.");
      }
      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    });
};

// update
export const customerUpdate = (e) => async (dispatch, getState) => {
  e.preventDefault();

  // let { single_customer, new_uploads, files_to_remove } = getState().customer;
  let { single_customer, new_uploads, files_to_remove } = getState().customer;

  // let old_file = single_customer.uploads.length;
  // let new_file = new_uploads.length;
  // let limit = [old_file, new_file].reduce((a, b) => a + b, 0);

  //** LUMEN REACT */
  // let formdata = new FormData();
  // formdata.append("_id", single_customer._id);
  // formdata.append("name", single_customer.name);
  // formdata.append("business_name", single_customer.business_name || "");
  // formdata.append("street1", single_customer.street1 || "");
  // formdata.append("street2", single_customer.street2 || "");
  // formdata.append("city", single_customer.city || "");
  // formdata.append("state", single_customer.state || "");
  // formdata.append("country", JSON.stringify(single_customer.country) || "");
  // formdata.append("fax", single_customer.fax || "");
  // formdata.append("email", single_customer.email || "");
  // formdata.append("landline", single_customer.landline || "");
  // formdata.append("mobile", single_customer.mobile || "");
  // formdata.append("contact_person", single_customer.contact_person || "");
  // formdata.append("tin", single_customer.tin || "");

  // new added files
  // for (const [_key, _value] of Object.entries(new_uploads)) {
  //   formdata.append(`new_uploads[]`, _value);
  // }

  // // ids of files to remove
  // if (files_to_remove.length > 0) {
  //   formdata.append("files_to_remove", JSON.stringify(files_to_remove));
  // }

  // update post api
  $(".btn-save").attr("disabled", "disabled").html("Saving...");
  CustomerService.updateSingleCustomer(single_customer, single_customer._id)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          dispatch(setPage("main_page"));
          ToastSuccess(res.data.message);
          dispatch(clearCustomer());
          break;
        default:
          break;
      }
      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        // ToastDanger("Network error, please reload the page.");
      }
      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    });
};

export const setDynamicLoading = (key, status) => async (dispatch) => {
  dispatch({ type: SET_DYNAMIC_LOADING, payload: { key, status } });
};

// archive
export const moveToArchives = () => async (dispatch, getState) => {
  // let { single_customer } = getState().customer;
  let { rows_to_delete } = getState().customer;
  let id_arr = [];
  rows_to_delete.map((row) => {
    id_arr.push(row.id);
  });

  let params = {
    ids: JSON.stringify(id_arr),
  };

  CustomerService.ArchiveCustomer(params)
    .then((res) => {
      dispatch(setRowChecked());
      ToastSuccess(res.data.message);
      dispatch(clearCustomer());
      dispatch(getCustomers());
    })
    .catch((error) => {
      // ToastDanger("Network error, please reload the page.");
      console.log(error);
    });
};

// archieve
export const archive = () => async (dispatch, getState) => {
  let { single_customer } = getState().customer;
  SwalWarning(
    "Warning!",
    `Do you want to remove Customer: ${single_customer.name}?`,
    () => dispatch(moveToArchives())
  );
};

// handle the upload file input
export const addFile = (fileSelected) => async (dispatch, getState) => {
  if (fileSelected) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  let { uploads } = getState().customer.single_customer;

  if (uploads.some((upload) => upload.name === fileSelected[0].name)) {
    ToastDanger("Sorry duplicate files are not allowed.");
  } else {
    dispatch({ type: ADD_FILE, payload: fileSelected });
  }
};

// handle the upload file on update
export const updateFile = (fileSelected) => async (dispatch, getState) => {
  if (fileSelected) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  let {
    new_uploads,
    single_customer: { uploads },
  } = getState().customer;

  if (new_uploads.some((upload) => upload.name == fileSelected[0].name)) {
    ToastDanger("Sorry duplicate files are not allowed.");
  } else {
    dispatch({ type: CUSTOMER_UPDATE_FILE, payload: fileSelected });
  }
};

// clear the file input value
export const removeFile =
  (name, status, id = null) =>
  async (dispatch) => {
    if (status || name) {
      dispatch(blockRoute(true));
    } else {
      dispatch(blockRoute(false));
    }

    if (status == "old") {
      SwalWarning(
        "Warning!",
        "Are you sure you want to remove this file?",
        () => {
          dispatch({ type: CUSTOMER_UPLOADS_TO_REMOVE, payload: id });
          dispatch(uploadFiles());
        }
      );
    } else {
      // New file added
      SwalWarning(
        "Warning!",
        "Are you sure you want to remove this file?",
        () => {
          dispatch({
            type: CUSTOMER_REMOVE_FILE,
            payload: { name: name, status: status },
          });
        }
      );
    }
  };

// clear the file input value
export const removeFileEdit =
  (name, status, id = null) =>
  async (dispatch) => {
    if (status == "old") {
      dispatch({ type: CUSTOMER_UPLOADS_TO_REMOVE, payload: id });
    }

    dispatch({
      type: CUSTOMER_REMOVE_FILE,
      payload: { name: name, status: status },
    });
  };

export const uploadFiles = () => async (dispatch, getState) => {
  let { single_customer, new_uploads, files_to_remove } = getState().customer;

  dispatch(setDynamicLoading("upload_loading", true));

  try {
    let formdata = new FormData();
    formdata.append("customer_id", single_customer._id);

    // new added files
    for (const [_key, _value] of Object.entries(new_uploads)) {
      formdata.append(`new_uploads[]`, _value);
    }

    // ids of files to remove
    if (files_to_remove.length > 0) {
      formdata.append("files_to_remove", JSON.stringify(files_to_remove));
    }

    let res = await CustomerService.uploadFiles(formdata);

    dispatch({ type: CUSTOMER_GET_UPLOADED_FILES, payload: res.data.uploads });
    ToastSuccess(res.data.message);
    dispatch(setDynamicLoading("upload_loading", false));
  } catch (err) {
    // ToastDanger("Network error, please reload the page.");
    dispatch(setDynamicLoading("upload_loading", false));
    console.log(err);
  }
};

// returns selected rows only
export const getSelectedRows =
  (selectedRows, currentRows) => async (dispatch) => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current, i) => {
      selectedRows.map((selected, _i) => {
        if (selected.index == current.index) {
          let selected = { id: current.data[0], name: current.data[1] };
          newArray.push(selected);
        }
      });
    });
    // console.log(newArray);
    dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
  };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().customer;
  let names = "";
  let name_arr = [];
  rows_to_delete.map((rows, i) => {
    name_arr.push("<b>" + rows.name + "</b>");
  });
  names = name_arr.join(", ");

  SwalWarningHtml(
    "Warning!",
    `Do you want to remove the Customer(s): ${names}?`,
    () => dispatch(moveToArchives())
  );
};

// Sort by column name and direction
export const sortTableByColumn =
  (sort_order_name, sort_order_direction) => async (dispatch) => {
    let sortingParams = {
      sort_order_name: sort_order_name,
      sort_order_direction: sort_order_direction,
    };

    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getCustomers());
  };

export const handleSearchInputMUI = (e) => async (dispatch) => {
  dispatch({ type: SEARCH_INPUT, payload: e.target.value });
};

// set the rows unchecked
export const setRowChecked = () => async (dispatch) =>
  dispatch({ type: SET_ROW_CHECKED });

/**************************** =============================== *****************************/

/**************************** CUSTOMER HISTORY ACTIONS BELOW *****************************/

/**************************** =============================== *****************************/

// TOGGLE TABS [ QUOTATIONS, SALES INVOICES ]
export const handleChangeTab = (tab, id) => async (dispatch, getState) => {
  // let { display_tab } = getState().customer
  let pageOrigin = { customer: id };

  switch (tab) {
    case "quotations":
      dispatch(getQuotations(1, null, pageOrigin));
      break;
    case "sales_orders":
      dispatch(getSalesOrders(1, null, pageOrigin));
      break;
    default:
      break;
  }
};

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
  dispatch({
    type: DATE_FILTER_INPUT,
    payload: { key: [e.target.name], value: e.target.value },
  });
};

// HANDLE SUBMIT DATE FILTER FOR [ QUOTATIONS, SALES_INVOICES .... ]
export const handleDateFilterSubmit =
  (e, filterBy, pageOrigin = null) =>
  async (dispatch, getState) => {
    e.preventDefault();

    let { date_to, date_from } = getState().customer;
    const today = new Date();

   // console.log(pageOrigin);

    try {
      if (
        new Date(date_from).getTime() >= today.getTime() ||
        new Date(date_to).getTime() >= today.getTime()
      ) {
        ToastDanger("Your Date value can not be greater than today.");
      } else {
        let date_filter = { date_from: date_from, date_to: date_to };

        // CHECK TYPE OF PAGE TO FILTER
        switch (filterBy) {
          case "quotations":
            dispatch(getQuotations(1, null, pageOrigin, date_filter));
            break;
          case "sales_orders":
            dispatch(getSalesOrders(1, null, pageOrigin, date_filter));
            break;
          default:
            break;
        }

       // console.log(pageOrigin);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      ToastDanger("Something went wrong please contact your admin...");
    }
  };

// DATE INPUT CLEAR
export const dateFilterClear =
  (type, pageOrigin = null) =>
  async (dispatch) => {
    dispatch({ type: CUSTOMER_HISTORY_DATE_INPUT_CLEAR, payload: type });

    switch (type) {
      case "quotations":
        dispatch(getQuotations(1, null, pageOrigin));
        break;
      case "sales_orders":
        dispatch(getSalesOrders(1, null, pageOrigin));
        break;
      default:
        break;
    }
  };
