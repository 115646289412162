import React, { useEffect, Fragment, useState  } from "react";
import { Link } from 'react-router-dom';
import {MiniSpinner} from "../../../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/free-solid-svg-icons";
import { handleMobile, getRoles, handleSelectRole, handleChangeInput, updateAccount, getAccount } from '../../../../redux/actions/settings/user.actions';
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
function AccountEdit ({userManagement: { loading, roles, handleSelect, user_form, historyRedirect }, handleMobile, getRoles, handleSelectRole, handleChangeInput, updateAccount, match, getAccount}){
    
    let history = useHistory();

    useEffect(() => {
        getRoles();
        getAccount(match.params.id);
    }, [])

    if(historyRedirect)
    {
        history.push(historyRedirect);
    }

    console.log(user_form);


    return (
        
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item">
                                        <Link to='/settings/users' className="text-white"></Link>
                                        <a href="#" >Role</a>
                                    </li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">Create</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <Link to='/settings/users' ><i className="ft ft-arrow-left"></i> Back</Link>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body pb-4">
                                            {
                                                loading ? <MiniSpinner/> :
                                            <form  class="form-account-create" onSubmit={e => updateAccount(e)}>
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-12 col-12">
                                                        <section className="row f-regular">

                                                            <div className="col-12">
                                                                <h5 className="f-regular">User Information</h5>
                                                                <label className="label-control">Name: <span className='text-danger'>*</span></label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input type="text" className="form-control" onChange={handleChangeInput} placeholder="Full name of user" value={user_form.name} name="name" required />
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control">Birthday: </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input type="date" className="form-control" onChange={handleChangeInput} name="birthday" value={user_form.birthday} required  />
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control">Email: <span className='text-danger'>*</span></label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input type="email" className="form-control" onChange={handleChangeInput} name="email" value={user_form.email} required  />
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control">Contact Number: </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <PhoneInput countryCodeEditable={false} enableSearch="true" value={user_form.contact} country="ph" inputProps={{ name: 'mobile', required: false, autoFormat:true, enableSearch: true, class: 'form-control-regular w-100' }} placeholder="" masks={{ph: '... ... ....'}} onChange={mobile => handleMobile(mobile)}/>
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            
                                                        </section>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12 col-12">
                                                        <section className="row f-regular">
                                                            <div className="col-12">
                                                                <h5 className="f-regular">Account Information</h5>
                                                                <label className="label-control">Username: <span className='text-danger'>*</span></label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input type="text" className="form-control" value={user_form.username} onChange={handleChangeInput} name="username" required  />
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control">Password:</label>

                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input type="password" className="form-control" value={user_form.password} onChange={handleChangeInput} name="password"   />
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                    <div className="col-md-11 col-10">
                                                                        <small><span class='text-sm text-secondary'>Note: If you do not wish to edit your password you can leave this blank.</span></small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control">Confirm Password:</label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input type="password" className="form-control" onChange={handleChangeInput} value={user_form.password_confirmation} name="password_confirmation"   />
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control">Role: <span className='text-danger'>*</span></label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <Select placeholder="Select Role" isSearchable="true" onChange={handleSelectRole} value={user_form.role_select} options={roles}  name="role" required/>
                                                                    </div>
                                                                    <div className="pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-primary btn-save pull-right">Save</button>
                                                            </div> 
                                                        </section>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    userManagement: state.userManagement
})

export default connect(mapStateToProps, { handleMobile, getRoles, handleSelectRole, handleChangeInput, updateAccount, getAccount })(AccountEdit);