import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {useDropzone} from 'react-dropzone';
import { fileExtensionIcon } from '../../Utils/Common';
import { removeFile, handleHover, uploadFiles, updateFile, globalDropDown, setProgress } from '../../redux/actions/helper/helper.actions';
import { MiniSpinner } from './Spinner';
import {  ProgressBar } from 'react-bootstrap';

function DropDownAttachFileGlobal({ origin, origin_data, updateFile, handleHover, removeFile ,set_loading, files_to_remove, new_uploads, isHover, globalDropDown, uploadFiles, progress}) {

    const [showProgress, setShowProgress] = useState(0);

    useEffect(() => {
        if(progress > 0){
            setShowProgress(progress);
        }
    },[progress]);
    

    const onDrop = useCallback(acceptedFiles => {
        updateFile(acceptedFiles, origin);
    }, [])
 
    const {getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

    return (
        <div className={origin === 'customer' ? '' : 'inv-dropdown dropdown-menu dropdown-large p-1'} data-offset={origin === 'customer' ? '': '10,20'}>
           {
               origin !== 'customer' && 
                <div className="col-12 mb-3 px-0">
                    <div className="float-right">
                        <a onClick={e => globalDropDown(e, 'hide')} href="#"><i className="ft ft-x f-23 text-secondary font-weight-bold cursor-pointer"></i></a>
                    </div>
                </div>
           }
            
            <div className="col-12 my-2 px-0">
                <div {...getRootProps()} className="file-dropzone-line-print-modal">
                    <input {...getInputProps()}/>
                    <div className="file-upload-message-print-modal">Attach your files here...</div>
                </div>
                <div className="btn-groupmt mt-1">
                    {/* { new_uploads && new_uploads.length > 0 &&  <button onClick={() => uploadFiles(origin)} className="btn btn-primary btn-block btn-save">SAVE ATTACHMENT</button> } */}
                </div>
            </div>
            {
                new_uploads.length > 0  ? <ProgressBar now={progress} label={`${progress}%`}/> :
                <>
                    {
                        origin_data.uploads && origin_data.uploads.length > 0 && origin_data.uploads.map((upload, index ) => (
                            <>
                                <div onMouseEnter={handleHover} title={upload.name.slice(0, 20) + (upload.name.length > 20 ? "..." : "")} onMouseLeave={() => handleHover('out')} class="d-flex justify-content-between align-items-center">
                                    <div className="min-wdth-300">
                                        <span className="file-icon-print"><img src={fileExtensionIcon(upload.name)} alt="file-types"/></span>
                                        <div className="text-secondary file-icon-text">{upload.name.slice(0, 20) + (upload.name.length > 20 ? "..." : "")}</div>
                                    </div>
                    
                                    <div className="btn-group">
                                        <a href={upload.file_path} download target="_blank" className="btn btn-blue btn-sm"><i className="ft ft-download"></i></a>
                                        <button onClick={() => removeFile(upload.name, 'old', upload._id, origin)} className="btn btn-danger btn-sm"><i className="ft ft-x"></i></button>
                                    </div>
                                </div>
                                <div class="dropdown-divider"></div>
                            </>
                        ))
                    }
                </>
            }
        </div>
    )
}


const mapStateToProps = state => ({
    set_loading: state.helper.set_loading,
    isHover: state.helper.isHover,
    progress: state.helper.progress
})

export default connect(mapStateToProps, { updateFile, handleHover, removeFile, uploadFiles, globalDropDown })(DropDownAttachFileGlobal)

