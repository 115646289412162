import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';

export const ReceivingService = {
    getPO: () => http.get('/admin/po/type/unassigned'),
    getPoData: (id) => http.get('/admin/po/' + id),
    submitReceiving: (formdata) => http.post('/admin/receiving', formdata), // old name submitPo
    getReceiving: (formdata, status) => http.post('/admin/receiving/search/'+ status, formdata),
    viewReceiving: (id) => http.get('/admin/receiving/' + id),
    updateReceiving: (id, formdata) =>
        http.put('/admin/receiving/' + id, formdata),
    getUnReceiving: (id) => http.get('/admin/receiving/items/' + id),
    submiUnReceiving: (formdata) =>
        http.post('/admin/receiving/follow-up', formdata),
    getSupplier: (formdata) => http.post('admin/supplier/select', formdata), // get the supplier for select option
    getItemSupplier: (id) => http.get('/admin/receiving/selected/supplier-item/' + id), // get supplier item base on id
    getProducts: (formdata) => http.post('/admin/po/search-product', formdata), 
    getTermsConditions: () => http.get('admin/term-condition'),
    goPage: (formParams) => http.post('/admin/receiving/go-page', formParams),
    searchItem: (formdata) => http.post('/admin/product/search', formdata),
    getLotNoData: (formData) =>
        http.post('/admin/receiving/lotno-data', formData),
    sendEmail: (id) => http.get('/admin/receiving/email/' + id),
    getPdf: (id) => httpWithFile.get('/admin/receiving/pdf/' + id),
    getEmailConfig: () => http.get('/settings/email/security'),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    getReceivingTemplates: () => http.get('/admin/receiving-template'),
    archieveList: (formdata) =>
        http.post('/admin/receiving/archives/list', formdata),
    barcodeDownload: (formdata) =>
        http.post('/admin/receiving/barcode', formdata, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        }),
    cancelReceiving: (id) => http.get('/admin/receiving/cancel/' + id),
    getSingleProduct: (id) => http.get('/admin/po/product/' + id),
};
