import React, { useState } from 'react';
import { formatPrice } from '../../../Utils/Common';

function ProgressBarContent({ topCardName, graphs }) {
    const [showAll, setShowAll] = useState(false);

    const truncatedLabels = graphs.topProducts.map((product) => {
        if (product.name && typeof product.name === 'string') {
            if (product.name.length > 10) {
                return product.name.substring(0, 20) + '...';
            } else {
                return product.name;
            }
        } else {
            return '';
        }
    });

    // Calculate the maximum total sales
    const maxTotalSales = Math.max(
        ...graphs.topProducts.map((product) => product.totalSales)
    );

    const visibleProducts = showAll
        ? graphs.topProducts
        : graphs.topProducts.slice(0, 3);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    return (
        <>
            {topCardName === 'Top Item Sales' && (
                <div className="p-2">
                    {visibleProducts.map((product, index) => (
                        <div className="project" key={product._id}>
                            <div className="project-a mb-2">
                                <p className="m-0 blue-grey darken-4">
                                    {truncatedLabels[index]}
                                    <span className="text-bold float-right">
                                        {formatPrice(
                                            product.totalSales,
                                            'code',
                                            'currency',
                                            'PHP',
                                            {
                                                notation: 'compact',
                                            }
                                        )}
                                    </span>
                                </p>
                                <div
                                    className="progress mt-1 mb-0"
                                    style={{
                                        height: '7px',
                                    }}
                                >
                                    <div
                                        className="progress-bar bg-success"
                                        role="progressbar"
                                        style={{
                                            width: `${
                                                (product.totalSales /
                                                    maxTotalSales) *
                                                100
                                            }%`,
                                        }}
                                        aria-valuenow={
                                            (product.totalSales /
                                                maxTotalSales) *
                                            100
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {graphs.topProducts.length > 3 && (
                        <div className="w-100 text-center">
                            <button
                                onClick={toggleShowAll}
                                className="btn btn-link"
                            >
                                {showAll ? 'See Less' : 'See More'}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default ProgressBarContent;
