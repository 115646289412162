import * as TYPE from '../../types';
import { ToastSuccess, ToastDanger, ToastWarning } from '../../../Services/_toast.service';
import $ from "jquery";
import { SwalWarning, SwalWarningForm, SwalWarningHtml } from '../../../Services/_swal.service';
import ShippingService from './_service.shipping';
import { getNumRows, numOnly, safeDivide } from '../../../Utils/Common';
import { blockRoute, setMUIPage } from '../helper/helper.actions';


export const searchPO = (value) => async dispatch => new Promise((resolve, reject) =>  {
    let form = {
        search : value
    }
    ShippingService.searchPO(form).then(res => {
        let po_arr = [];
        let po = res.data.purchase_orders.data;

        po.map((val, i) => {
            let select = { value: val._id, label: val.po_no, name: 'purchase_order'};
            po_arr[i] = select
        })
        resolve(po_arr);
        dispatch({ type: TYPE.SHIPPING_PO_DATA, payload: po });

    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
});


export const PoChange = selectOption => async (dispatch, getState) => {
    if(selectOption){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }
    let { po_data } = getState().shipping;
    let selected = null
    po_data.map(data => {
        if(data._id == selectOption.value)
        {
            selected = data
        }
    })

    if(selected != null)
    {
        selected.purchase_order_items.map((items, index) => {
            selected.purchase_order_items[index].box = 0
            selected.purchase_order_items[index].box_weight = 0
            selected.purchase_order_items[index].box_dimension = 0
            selected.purchase_order_items[index].item_box = 0
            selected.purchase_order_items[index].item_dimension = 0
            selected.purchase_order_items[index].item_cost = 0
            selected.purchase_order_items[index].item_volume = 0
            selected.purchase_order_items[index].item_shipping_cost = 0
            selected.purchase_order_items[index].box_dm = {
                width : 0,
                height : 0,
                length : 0,
                weight : 0,
                visible : false
            }
            selected.purchase_order_items[index].item_dm = {
                width : 0,
                height : 0,
                length : 0,
                weight : 0,
                visible : false
            }
        })
    }
    dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : selected})
}

export const freight = (cost, rate) => async (dispatch, getState) => {
    let total = 0
    let { inputs } = getState().shipping;
    let temp = inputs
    try{
        total = cost * rate
    }
    catch(err)
    {
        total = 0
    }
    temp.freight_cost = parseFloat(total)
    temp.cost = parseFloat(cost)
    temp.rate = parseFloat(rate)
    temp.total_shipping = parseFloat(temp.freight_cost) + parseFloat(temp.broker_fee) + parseFloat(temp.duties_taxes) + parseFloat(temp.port_charge) + parseFloat(temp.delivery_fee)
    dispatch({type: TYPE.SHIPPING_INPUTS, payload : temp})
}

export const popUpToggleClick = () => async (dispatch, getState) => {
    let { popToggle } = getState().shipping;
    let toggle = popToggle ? false : true

    dispatch({type : TYPE.POP_TOGGLE, payload : toggle})
}

export const InputChange = (e) => async (dispatch, getState) => {
    let { inputs } = getState().shipping;
    let temp = inputs
    temp[e.target.name] = parseFloat(e.target.value)
    temp.total_shipping = parseFloat(temp.freight_cost) + parseFloat(temp.broker_fee) + parseFloat(temp.duties_taxes) + parseFloat(temp.port_charge) + parseFloat(temp.delivery_fee) + parseFloat(temp.others)
    dispatch({type: TYPE.SHIPPING_INPUTS, payload : temp})   
    dispatch(computeTotalFees())
}

export const ShippingChange = (key, name, value) => async (dispatch, getState) => {
    let { selected_po, inputs } = getState().shipping;
    let total_boxes = 0, total_weight = 0, total_dimension = 0, total_items = 0, total_item_volume = 0, rate_m3 = 0
    if(selected_po != null)
    {
        let temp = selected_po
        temp.purchase_order_items[key][name] = value
        temp.purchase_order_items.map(item => {
            total_boxes         += numOnly(item.box)
            total_weight        += numOnly(item.box_weight)
            total_dimension     += numOnly(item.box_dimension)
            total_items         += numOnly(item.item_box)
            total_item_volume   += numOnly(item.item_box) * numOnly(item.box_dimension)
        })

        let item_volume = numOnly(temp.purchase_order_items[key].box) * numOnly(temp.purchase_order_items[key].box_dimension)
        rate_m3 = safeDivide(inputs.total_shipping, total_dimension)
        
        temp.purchase_order_items[key].item_volume = item_volume

        inputs.total_boxes          = total_boxes
        inputs.total_weight         = total_weight
        inputs.total_items          = total_items
        inputs.total_item_volume    = total_item_volume
        inputs.rate_m3              = rate_m3
        inputs.item_ship_cost       = numOnly(rate_m3) * numOnly(total_item_volume)

        let item_shipping_cost = safeDivide(item_volume, rate_m3)
        let item_cost = safeDivide(item_shipping_cost, temp.purchase_order_items[key].item_box)
        temp.purchase_order_items[key].item_shipping_cost = item_shipping_cost
        temp.purchase_order_items[key].item_cost = item_cost.toFixed(4)
        console.log("item_shipping_cost", item_shipping_cost)
        console.log("item_volume", item_volume)
        console.log("rate_m3", rate_m3)
        console.log("temp.purchase_order_items[key]", temp.purchase_order_items[key])
        console.log("inputs", inputs)
        
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
        dispatch({type: TYPE.SHIPPING_INPUTS, payload : inputs})
    }
    
}

export const toggleDm = (key) => async (dispatch, getState) => {
    let { selected_po } = getState().shipping;
    if(selected_po != null)
    {
        let temp = selected_po
        const current = temp.purchase_order_items[key].box_dm.visible
        selected_po.purchase_order_items.map((data, index) => {
            temp.purchase_order_items[index].box_dm.visible = false
        })
        let visible = current ? false : true
        temp.purchase_order_items[key].box_dm.visible = visible
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
    }
}

export const computeDm = (key) => async (dispatch, getState) => {
    let { selected_po } = getState().shipping;
    if(selected_po != null)
    {
        let temp = selected_po
        temp.purchase_order_items[key].box_dm.visible = false
        const box_dm = temp.purchase_order_items[key].box_dm
        try{
            temp.purchase_order_items[key].box_dimension = box_dm.width * box_dm.height * box_dm.length
        }
        catch(err)
        {
            temp.purchase_order_items[key].box_dimension = 0
        }
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
    }
}

export const boxDmInput = (e, key) => async (dispatch, getState) => {
    let { selected_po } = getState().shipping;
    let name = e.target.name
    let val = e.target.value
    if(selected_po != null)
    {
        let temp = selected_po
        temp.purchase_order_items[key].box_dm[name] = val
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
    }
}

export const toggleItemDm = (key) => async (dispatch, getState) => {
    let { selected_po } = getState().shipping;
    if(selected_po != null)
    {
        let temp = selected_po
        const current = temp.purchase_order_items[key].item_dm.visible
        selected_po.purchase_order_items.map((data, index) => {
            temp.purchase_order_items[index].item_dm.visible = false
        })
        let visible = current ? false : true
        temp.purchase_order_items[key].item_dm.visible = visible
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
    }
}

export const computeItemDm = (key) => async (dispatch, getState) => {
    let { selected_po } = getState().shipping;
    if(selected_po != null)
    {
        let temp = selected_po
        temp.purchase_order_items[key].item_dm.visible = false
        const item_dm = temp.purchase_order_items[key].item_dm
        try{
            temp.purchase_order_items[key].item_dimension = item_dm.width * item_dm.height * item_dm.length
        }
        catch(err)
        {
            temp.purchase_order_items[key].item_dimension = 0
        }
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
    }
}

export const itemDmInput = (e, key) => async (dispatch, getState) => {
    let { selected_po } = getState().shipping;
    let name = e.target.name
    let val = e.target.value
    if(selected_po != null)
    {
        let temp = selected_po
        temp.purchase_order_items[key].item_dm[name] = val
        
        
        dispatch({type: TYPE.SHIPPING_PO_SELECTED, payload : temp})
    }
}


export const otherInput = (e, key) => async(dispatch, getState) => {
    let { other_fees } = getState().shipping;
    let name = e.target.name
    let val = e.target.value
    if(other_fees != null)
    {
        let temp = other_fees
        temp[key][name] = val
        dispatch({type: TYPE.SHIPPING_OTHER_FEE, payload : temp})
        // console.log("temp", temp)
        dispatch(computeTotalFees())
    }
}

 export const addOtherInput = () => async(dispatch, getState) => {
    let { other_fees } = getState().shipping;
    other_fees.push({
        name : '',
        value : 0
    })
    
    dispatch({type: TYPE.SHIPPING_OTHER_FEE, payload : other_fees})
}

const computeTotalFees = () => async(dispatch, getState) => {
    let { other_fees, inputs } = getState().shipping;
    let temp = inputs
    let total_fee = 0;
    if(other_fees.length > 0)
    {
        other_fees.map(data => {
            
            try{
                total_fee += parseFloat(data.value)
            }
            catch(err){
                console.log("err", err)
            }
        })
    }

    total_fee += parseFloat(temp.freight_cost) + parseFloat(temp.broker_fee) + parseFloat(temp.duties_taxes) + parseFloat(temp.port_charge) + parseFloat(temp.delivery_fee)
    temp.total_shipping = total_fee
    dispatch({type: TYPE.SHIPPING_INPUTS, payload : temp})   
}

export const saveShipping = (status, action = 'create') => async(dispatch, getState) => {
    let { selected_po, inputs, other_fees, shipping } = getState().shipping;
    const id = shipping._id !== undefined ? shipping._id : null
    let form = {
        po      : JSON.stringify(selected_po),
        inputs  : inputs,
        others  : other_fees,
        status  : status,
        id      : id
    }

    console.log("form", form)

    if(status == 'saved')
    {
        SwalWarning('Are You Sure?','You are about to save this Shipping Cost, saving this will stop you from editing any other changes. Continue?', () => dispatch(submitForm(form, action)))
    }
    else{
        dispatch(submitForm(form, action))
    }
}


const submitForm = (form, action) => async(dispatch) => {
    if(action == 'create')
    {
        ShippingService.saveShipping(form).then(res => {
            window.location.href = "/purchases/shipping-costs"
        })
        .catch(error => {
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
    }
    else
    {
        ShippingService.updateShipping(form).then(res => {
            window.location.href = "/purchases/shipping-costs"
        })
        .catch(error => {
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
        
    }
    
}

export const loadShipping = () => async(dispatch, getState) => {
    ShippingService.searchShipping().then(res => {
        let data = {
            shipping : res.data.data,
            pagination : {
                totalCount : res.data.total,
                itemsCountPerPage : res.data.per_page,
                totalItemsCount : res.data.total,
                pageRangeDisplayed : res.data.last_page
            }
        }
        dispatch({type : TYPE.SHIPPING_DATA, payload : data })
        console.log("data", data)
    })
    .catch(error => {
        // ToastDanger('Failed to load shipping data.');
        console.log(error);
    })
}

export const handleSearchInputMUI = () => async(dispatch, getState) => {

}

export const confirmDeleteDialogBox = (pageOrigin) => async (dispatch, getState) => {
    
    let { rows_to_delete } = getState().shipping;
    let ship = [];
    
    rows_to_delete.map((rows,i) => {  ship.push( "<b>"+rows.name + "</b>")  })
    SwalWarningHtml('Warning!', `Do you want to remove the Shipping cost(s): ${ship.join(", ")}?`, () => dispatch(moveToArchive(pageOrigin)));
}


// archieve
export const moveToArchive = (pageOrigin = null) => async (dispatch, getState) => {

    
    let { rows_to_delete } = getState().shipping;
    let id_arr = [];

    rows_to_delete.map(row => { id_arr.push(row.id) })
    let params = {  ids: JSON.stringify(id_arr) }
    
    ShippingService.deleteShipping(params).then(result => {
        dispatch(loadShipping())
    })
    .catch(error => {
        ToastDanger('Failed to delete shipping data.');
        console.log(error);
    })

};


// Sort by column name and direction
export const sortTableByColumn = (sort_order_name, sort_order_direction, pageOrigin = null) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction, 
    } 

    dispatch({ type: TYPE.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(loadShipping(1, null, pageOrigin));
}


// returns selected rows only 
export const getSelectedRows = (selectedRows, currentRows) => async dispatch => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current,i) => {
        selectedRows.map((selected, _i) => {
            if(selected.index == current.index)
            {
                let selected = { id: current.data[0], name: current.data[1] };
                newArray.push(selected);
            }
        })
    });
    // console.log(newArray);
    dispatch({ type: TYPE.SHIPPING_ROW_DELETE, payload: newArray });
}

export const setRowChecked = () => async dispatch => dispatch({type: TYPE.SHIPPING_SET_ROW_CHECKED}); 


export const getShipping = (id) => async (dispatch, getState) => {
    ShippingService.showShipping(id).then(result => {
        // console.log("result", result)
        const shipping = result.data.ship;
        const po = result.data.po;
        po.purchase_order_items = shipping.items
        dispatch({type : TYPE.SHIPPING_PO_NO, payload : shipping.po_no})
        dispatch({type : TYPE.SHIPPING_NO, payload : shipping.ship_no})
        dispatch({type : TYPE.SHIPPING_PO_SELECTED, payload : po})
        dispatch({type : TYPE.SHIPPING_INPUTS, payload : shipping.input_fee})
        dispatch({type : TYPE.SHIPPING_OTHER_FEE, payload : shipping.input_others})
        dispatch({type : TYPE.SHIPPING, payload : shipping})

    })
    .catch(err => {
        console.log("err", err);
    })
}