import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import ProductUnitCreateGlobal from '../../product_unit/ProductUnit.create.global';

function ProductUnitModal({ show, onHide }) {
    return (
        <Modal centered dialogClassName="modal-container" show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton> <Modal.Title>Unit</Modal.Title> </Modal.Header>
            <Modal.Body>
                <ProductUnitCreateGlobal pageOrigin="item"/>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
	// product_unit: state.product_unit
})

export default connect(mapStateToProps, {})(ProductUnitModal);
