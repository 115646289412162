import {
    SET_LOADING,
    GET_PURCHASE_ORDERS,
    PURCHASE_ORDER_INPUT_CHANGE,
    SET_PAGE,
    GET_SINGLE_PURCHASE_ORDER,
    CLEAR_PURCHASE_ORDER,
    SEARCH_INPUT,
    GET_PO_SUPPLIERS,
    GET_PO_PAYMENT_TERMS,
    HANDLE_PO_SELECT_SUPPLIER,
    HANDLE_PO_COST,
    HANDLE_PO_SELECT_ITEM,
    HANDLE_PO_QTY,
    HANDLE_PO_ADD_ITEM,
    HANDLE_PO_REMOVE_ITEM,
    HANDLE_PO_INPUT_ATTR,
    HANDLE_PO_SELECT_PAYMENT_TERMS,
    PO_GET_SUPPLIER_DATA,
    PO_GET_SUPPLIER_ITEMS,
    HANDLE_PO_TOGGLE_EDIT_BTN_ATTR,
    SET_MINI_LOADING,
    HANDLE_PO_TOGGLE_EDIT_ROW,
    HANDLE_PO_EDIT_INPUT_ROW,
    HANDLE_PO_EDIT_UPDATE_ROW,
    SET_MODAL,
    HANDLE_PO_SUBMIT_ATTR,
    HANDLE_PO_CHECK_VAT,
    SET_PO_ITEM_ID,
    GET_PO_SUPPLIERS_DEFAULT,
    SET_ROW_CHECKED,
    PO_ADD_FILE,
    PO_UPDATE_FILE,
    PO_REMOVE_FILE,
    PO_UPLOADS_TO_REMOVE,
    PO_GET_UPLOADED_FILES,
    GET_ROWS_TO_DELETE,
    SORT_BY_COLUMN,
    HANDLE_PO_SELECT_PAYMENT_MODE,
    HANDLE_SELECT_PO_TEMPLATE,
    GET_PO_TEMPLATES,
    SET_DATE_PICKER,
    SET_PO_ITEMS,
    PO_TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    PO_ITEMS_TEMPLATE,
    PO_CLEAR_UPLOAD,
    PO_SELECT_TAX_TYPE,
    GET_PO_SUB_SETTING,
    PO_COMPUTATION_HANDLER,
    SEND_EMAIL,
    RESEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    SET_CHECKED,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    PASSWORD_VERIFY,
    PASSWORD_VERIFY_MODAL,
    FILE_COUNT,
    CLEAR_DATA,
    BTN_FORM,
    GET_SINGLE_PRODUCT,
    GET_STATUS,
    GET_PO_STATUS
} from './../types';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    purchase_orders: [],
    status_view: 'all',
    pagination_list: {'0' : [], '1' : [], '2': [], 'all': []},
    subs_setting: [],
    single_purchase_order: {
        purchase_order_items: [],
        lastAddedDropdownIndex: null,
        discount_type: { value: 'none', label: 'None' },
        discount: 0,
        showDiscountRow: false,
        supplier_id: null,
        product_unit_id: null,
        supplier: null,
        vat_amount: 0,
        tax: 0,
        default_tax: 0,
        tax_type: { value: 'none', label: 'None' },
        other: 0,
        sub_total: 0,
        total: 0,
        remarks: '',
        payment_terms: { value: '', label: 'No Terms' },
        payment_mode: { value: '', label: 'None' },
        purchase_order_template_id: null,
        purchase_order_date: new Date(),
        subs_setting: [],
        uploads: [],
        logo: null,
        _id: null,
        email_sent: false,
        download_pdf: false,
        template_setting: [],
    },
    subs_setting: null,
    new_uploads: [],
    files_to_remove: [],
    items: [],
    edit_purchase_order_items: [],
    edit_attr: {
        discount: 0,
    },
    itemSelected: {
        _id: '',
        amount: 0.0,
        cost: 0.0,
        product_name: '',
        product_unit_id: '',
        product_unit_name: '',
        qty: 0,
        sku: '',
    },
    payment_mode_options: [
        { value: 'cash', label: 'Cash' },
        { value: 'credit card', label: 'Credit Card' },
        { value: 'cod', label: 'COD' },
        { value: 'check', label: 'Check' },
        { value: 'bank transfer', label: 'Bank Transfer' },
    ],
    po_templates: [],
    po_default_template: [],
    default_product_value: { value: '', label: 'Choose Product' },
    default_supplier_value: { value: '', label: 'Choose Supplier' },
    tax_types: [
        { value: 'none', label: 'None' },
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
    ],
    suppliers: [],
    suppliers_default: [],
    payment_terms: [],
    search: '',
    loading: false,
    mini_loading: false,
    isSearchable: true,
    isDiscount: false,
    isTax: false,
    isOther: false,
    isRemarks: false,
    edit_row_index: null,
    edit_attr: false,
    main_page: false,
    edit_page: false,
    print_modal: false,
    main_url: '/purchases/purchase-orders',
    create_url: '/purchases/purchase-orders/create',
    edit_url: '/purchases/purchase-orders/edit',
    id_obj: null,
    supplier_modal: false,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    diselect_row: false,
    set_row_checked: false,
    purchase_order_date: new Date(),
    payment_terms_modal: false,
    _test: null,
    item_template: {
        _id: '',
        amount: 0.0,
        cost: 0.0,
        product_name: '',
        product_unit_id: '',
        product_unit_name: '',
        qty: 0,
        sku: '',
        items: [],
        description: '',
        barcode: '',
        selected: false,
    },
    sending_email: false,
    supplier_item_only: true,
    download_pdf: false,
    email_config: [],
    password_vefiry: '',
    password_verify_modal: true,
    file_count: {
        number: 0,
        str: '0',
    },
    btn_form: {
        save: false,
        draft: false,
        email_save: false,
        cancel: false,
        approve: false,
    },
};

const purchaseOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PURCHASE_ORDERS:
            return {
                ...state,
                purchase_orders: action.payload.purchase_orders,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case GET_PO_STATUS:
            return {
                ...state,
                purchase_orders: action.payload.purchase_orders,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            };
        case GET_SINGLE_PURCHASE_ORDER:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    ...action.payload.single_purchase_order,
                },
                edit_purchase_order_items:
                    action.payload.single_purchase_order.purchase_order_items,
                edit_attr: action.payload.edit_attr,
                default_supplier_value: action.payload.default_supplier_value,
                po_default_template: action.payload.po_default_template,
            };
        case GET_PO_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload,
            };
        case GET_PO_SUPPLIERS_DEFAULT:
            return {
                ...state,
                suppliers_default: action.payload,
            };

        case GET_PO_PAYMENT_TERMS:
            return {
                ...state,
                payment_terms: action.payload,
            };
        case PURCHASE_ORDER_INPUT_CHANGE:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    [action.payload.key]: action.payload.value,
                },
            };
        case HANDLE_PO_SELECT_SUPPLIER:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    supplier_id: action.payload.value,
                },
                default_supplier_value: action.payload,
                default_product_value: { value: '', label: 'Choose Product' },
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    cost: 0.0,
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
            };
        case PO_GET_SUPPLIER_DATA:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    supplier: action.payload,
                },
            };
        case PO_GET_SUPPLIER_ITEMS:
            return {
                ...state,
                items: action.payload,
            };
        case SET_PO_ITEM_ID:
            return {
                ...state,
                id_obj: action.payload,
            };
        case HANDLE_PO_COST:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    cost: action.payload.cost,
                    amount: action.payload.amount,
                },
            };
        case HANDLE_PO_QTY:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    qty: action.payload.qty,
                    amount: action.payload.amount,
                },
            };
        case HANDLE_PO_SELECT_ITEM:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    ...action.payload.item_selected,
                },
                default_product_value: action.payload.default_product_value,
            };
        case HANDLE_PO_ADD_ITEM:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_items: action.payload.purchase_order_items,
                },
                edit_purchase_order_items: action.payload.purchase_order_items,
                edit_row_index: null,
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    cost: 0.0,
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
                default_product_value: { value: '', label: 'Choose Product' },
            };
        case HANDLE_PO_REMOVE_ITEM:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_items: action.payload.purchase_order_items,
                },
                edit_purchase_order_items: action.payload.purchase_order_items,
                edit_row_index: null,
            };
        case HANDLE_PO_INPUT_ATTR:
            return {
                ...state,
                edit_attr: {
                    ...state.edit_attr,
                    [action.payload.key]: action.payload.value,
                },
            };
        case HANDLE_PO_TOGGLE_EDIT_BTN_ATTR:
            return {
                ...state,
                [action.payload.attr]: action.payload.status,
            };
        case HANDLE_PO_SUBMIT_ATTR:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    [action.payload.key]: action.payload.value,
                },
                isDiscount: false,
            };
        case HANDLE_PO_TOGGLE_EDIT_ROW:
            return {
                ...state,
                edit_row_index: action.payload,
            };
        case HANDLE_PO_EDIT_INPUT_ROW:
            return {
                ...state,
                edit_purchase_order_items: action.payload,
            };
        case HANDLE_PO_EDIT_UPDATE_ROW:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_items: action.payload,
                },
                edit_row_index: null,
            };
        case HANDLE_PO_SELECT_PAYMENT_TERMS:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    payment_terms: action.payload,
                },
            };
        case HANDLE_PO_CHECK_VAT:
        // return {
        //     ...state,
        //     single_purchase_order: { ...state.single_purchase_order, tax: action.payload }
        // }
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case GET_STATUS:
            return {
                ...state,
                status_view: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload,
            };
        case SET_PAGE:
            if (action.payload.page == 'main_page') {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                    edit_page: false,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
        case SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case HANDLE_PO_SELECT_PAYMENT_MODE:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    payment_mode: action.payload,
                },
            };
        case GET_PO_TEMPLATES:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_template_id:
                        action.payload.purchase_order_template_id,
                },
                po_templates: action.payload.po_templates,
                po_default_template: action.payload.po_default_template,
            };
        case HANDLE_SELECT_PO_TEMPLATE:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_template_id:
                        action.payload.purchase_order_template_id,
                },
                po_default_template: action.payload.po_default_template,
            };
        case SET_DATE_PICKER:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_date: action.payload,
                },
            };
        case CLEAR_PURCHASE_ORDER:
            return {
                ...state,
                single_purchase_order: {
                    purchase_order_items: [],
                    lastAddedDropdownIndex: null,
                    discount_type: { value: 'none', label: 'None' },
                    discount: 0,
                    showDiscountRow: false,
                    supplier_id: null,
                    product_unit_id: null,
                    supplier: null,
                    vat_amount: 0,
                    tax: 0,
                    default_tax: 0,
                    tax_type: { value: 'none', label: 'None' },
                    other: 0,
                    sub_total: 0,
                    total: 0,
                    remarks: '',
                    payment_terms: { value: '', label: 'No Terms' },
                    payment_mode: { value: '', label: 'None' },
                    purchase_order_template_id: null,
                    purchase_order_date: new Date(),
                    subs_setting: [],
                    uploads: [],
                    logo: null,
                    email_sent: false,
                },
                edit_attr: {
                    discount: 0,
                },
                edit_purchase_order_items: [],
                items: [],
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    cost: 0.0,
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
                isDiscount: false,
                isTax: false,
                isOther: false,
                isRemarks: false,
                edit_row_index: null,
                create_page: false,
                view_page: false,
                edit_page: false,
                isEditRow: false,
                main_page: false,
                edit_page: false,
                print_modal: false,
                main_url: '/purchases/purchase-orders',
                create_url: '/purchases/purchase-orders/create',
                edit_url: '/purchases/purchase-orders/edit',
                item_id: null,
                supplier_modal: false,
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                diselect_row: false,
                set_row_checked: false,
                purchase_order_date: new Date(),
                payment_terms_modal: false,
            };
        case PO_UPDATE_FILE:
            return {
                ...state,
                new_uploads: [...state.new_uploads, ...action.payload],
            };
        case PO_REMOVE_FILE:
            if (action.payload.status == 'old') {
                return {
                    ...state,
                    single_purchase_order: {
                        ...state.single_purchase_order,
                        uploads: [
                            ...state.single_purchase_order.uploads.filter(
                                (file) => file.name !== action.payload.name
                            ),
                        ],
                    },
                };
            } else {
                return {
                    ...state,
                    new_uploads: [
                        ...state.new_uploads.filter(
                            (file) => file.name !== action.payload.name
                        ),
                    ],
                };
            }
        case PO_UPLOADS_TO_REMOVE:
            return {
                ...state,
                files_to_remove: [...state.files_to_remove, action.payload],
            };
        case PO_GET_UPLOADED_FILES:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    uploads: action.payload,
                },
                new_uploads: [],
                files_to_remove: [],
            };
        case SET_PO_ITEMS:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_items: action.payload,
                },
            };
        case PO_TOGGLE_DISCOUNT_ROW:
            const updatedItems =
                state.single_purchase_order.purchase_order_items.map(
                    (item, index) => {
                        if (index === action.payload.index) {
                            return {
                                ...item,
                                showDiscountRow: !action.payload.remove,
                            };
                        } else {
                            return item;
                        }
                    }
                );

            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_items: updatedItems,
                },
            };
        case SET_LAST_ADDED_DROPDOWN_INDEX:
            return {
                ...state,
                single_purchase_order: action.payload,
            };
        case PO_ITEMS_TEMPLATE:
            return {
                ...state,
                item_template: action.payload,
            };
        case PO_CLEAR_UPLOAD:
            return {
                ...state,
                new_uploads: [],
            };
        case PO_SELECT_TAX_TYPE:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    tax: action.payload.tax,
                    tax_type: action.payload.tax_type,
                },
            };
        case GET_PO_SUB_SETTING:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    ...action.payload,
                },
            };
        case PO_COMPUTATION_HANDLER:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    ...action.payload,
                },
            };
        case SEND_EMAIL:
            return {
                ...state,
                sending_email: action.payload,
            };
        case RESEND_EMAIL:
            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    email_sent: action.payload,
                },
            };
        case DYNAMIC_SEND_EMAIL:
            return {
                ...state,
                purchase_orders: action.payload,
            };
        case SET_CHECKED:
            return {
                ...state,
                supplier_item_only: action.payload,
            };
        case DOWNLOAD_BTN:
            return {
                ...state,
                download_pdf: action.payload,
            };
        case EMAIL_SETTING_DATA:
            return {
                ...state,
                email_config: action.payload,
            };
        case PASSWORD_VERIFY:
            return {
                ...state,
                password_vefiry: action.payload,
            };
        case PASSWORD_VERIFY_MODAL:
            return {
                ...state,
                password_verify_modal: action.payload,
            };
        case FILE_COUNT:
            return {
                ...state,
                file_count: action.payload,
            };
        case CLEAR_DATA:
            return {
                ...state,
                po_templates: [],
                po_default_template: [],
                default_product_value: { value: '', label: 'Choose Product' },
                default_supplier_value: { value: '', label: 'Choose Supplier' },
            };
        case BTN_FORM:
            return {
                ...state,
                btn_form: action.payload,
            };
        case GET_SINGLE_PRODUCT:
            const { productId, productDetails } = action.payload;

            return {
                ...state,
                single_purchase_order: {
                    ...state.single_purchase_order,
                    purchase_order_items: state.single_purchase_order.purchase_order_items.map(item => {
                        if (item._id === productId) {
                            return {
                                ...item,
                                productDetails,
                            };
                        }
                        return item;
                    }),
                },
            };

        default:
            return state;
    }
};

export default purchaseOrderReducer;
