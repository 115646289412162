import React, { Fragment, useEffect, useMemo } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {  Show, useAccess } from "react-redux-permission";
import { loadShipping, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows, setRowChecked} from '../../redux/actions/shipping/shipping.action';
import ReactTooltip from 'react-tooltip';
import { createTheme , MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { handleHover, setMUIPage, updateMUISetting } from "../../redux/actions/helper/helper.actions";
import { formattedDate, numberFormat } from '../../Utils/Common';
import MUIDataTable from "mui-datatables";


function ShippingCostTable ({ shipping : { shippingData, pagination, isShipLoading, sort_order_name, sort_order_direction, pageOrigin, set_row_checked }, 
    hoverIndex, loadShipping, handleSearchInputMUI, updateMUISetting, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows, setRowChecked, handleHover }) {

    useEffect(() => {
        loadShipping()
    }, [isShipLoading])

    const { hasPermission } = useAccess();
    const canCreate = hasPermission("inv.shipping-costs.store");
    const canDelete = hasPermission("inv.shipping-costs.destroy");
    const canEdit = hasPermission("inv.shipping-costs.update");
    // const canPrint = hasPermission("inv.sales.sales-invoices.print");
    const canViewCost = hasPermission("inv.shipping-costs.view");
    // const canCreateDR = hasPermission("inv.delivery.receipts.create");


    // START DEVOUNCED SEARCH
    // const debouncedSearch = useDebounce(search, 800);
    // const [oldDebounced, setOldDebounced] = useState('');
    // useEffect(() => {
    //   if (debouncedSearch) {
    //     if(debouncedSearch[0]){
    //         if(oldDebounced !== debouncedSearch[0]){
    //           if(debouncedSearch[0].length !== 1){
    //             setOldDebounced(debouncedSearch[0]);
    //             getSalesInvoices(1, null, pageOrigin);
    //           }
    //         }
    //     }
    //     else
    //     {
    //         if(oldDebounced !== ""){
    //           setOldDebounced("");
    //           getSalesInvoices(1, null, pageOrigin);
    //         }
    //     }
    //   } 
    // },[debouncedSearch]);
    // END DEVOUNCE SEARCH


    // if(edit_page) return <Redirect to={edit_url}/>
    console.log("hoverIndex", hoverIndex)
    const getMuiTheme = () => createTheme ({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    '&:nth-child(odd)': { 
                        backgroundColor: "rgba(0,0,0,.05)"
                    }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    '&:nth-child(2)': {
                        height: '61px'
                    },
                    '&:nth-child(11)': {
                        width: '150px'
                    },
                    '&:nth-child(11)': {
                        width: '180px'
                    }
                }
            },
            MUIDataTableHeadCell: {}
        }
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } },
        {name: 'ship_no', label: 'Shipping No', options: { display: true,
                customBodyRender: (value) => {
                    return (
                        <span> {value} </span>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            }
        },
        {name: 'po_no', label: 'PO No', options: { display: true, print: false , download: false ,
                customBodyRender: (value, tableMeta) => {
                    let po_id = shippingData[tableMeta['rowIndex']].po_id;
                    return (
                        <Link className="txt-underline" to={`/purchases/purchase-orders/edit/` + po_id}> {value} </Link>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            }
        },
        {name: 'total_fee', label: 'Total Fee', options : { display: true, filter: false, print: false, download: false,sort: false,
            customBodyRender: (value) => {
                    return (
                        <span> {numberFormat(value)} </span>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer text-right"}
                }
            }
        },
      
        {name: 'status', label: 'Status', options: {  display: true,
            customBodyRender: (value) => {
                return (
                    <span className={ "lbl-status lbl-" + value}> {value} </span>
                    )
                }
            }
        },
        {
            name: '', label: 'Actions', options: { display: true, filter: false, sort: false, print: false, download: false, 
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = shippingData[tableMeta['rowIndex']]._id;
                    if(!canEdit)
                    {
                        return false;
                    }

                    return (    
                        <div className="btn-group">
                            {
                            (hoverIndex == tableMeta.rowIndex) && 
                                <>
                                    <Link to={ '/purchases/shipping-costs/edit/' + id } className="btn btn-sm btn-grey btn-sm"><i className="ft ft-edit"></i></Link>
                                </>
                            }
                        </div>
                    )
                },
                setCellProps: (value) => {
                    return {className : "text-center"}
                }
            }
        }
        
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        filter : false,
        rowsPerPageOptions: [10,20,50,100,200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `shipping_cost_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: isShipLoading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            loadShipping(null, numberOfRows, pageOrigin)
            updateMUISetting('rows', 'sales_invoice', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState)
            switch (action) {
                case 'changePage':
                    // getSalesInvoices(tableState.page + 1, pagination.itemsCountPerPage, pageOrigin);
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'shippingData', null, tableState.columns);
                    break;
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if(canDelete)
            {
                confirmDeleteDialogBox(pageOrigin);
            }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {},
        onCellClick: (colData, cellMeta) => {
            // console.log(cellMeta)
            if(cellMeta.colIndex !== 9)
            {   
                // console.log('PO_PRINT_ID', sales_invoices[cellMeta.dataIndex]._id, sales_invoices);
                // if(canPrint)
                // {
                    // setPage('print_modal_si', sales_invoices[cellMeta.dataIndex]._id); 
                // }
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search" onChange={handleSearchInputMUI} className="custom-search-input"/>
                </div>
            )
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            // return { onMouseEnter: () => handleHover('in', rowIndex), onMouseLeave: () => handleHover('out', rowIndex) }
        },            
    };


    if(!canDelete)
    {
        options.selectableRows = false;
    }
    
    const _columns = updateMUISetting('view-columns', 'shippingData', null, columns);
    
    return (
        <>
            <Show when="inv.shipping-costs.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable title={""} data={shippingData} columns={_columns} options={options}/>
                </MuiThemeProvider> 
            </Show>
        </>
    );
}

const mapStateToProps = state => ({
    shipping : state.shipping,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage
});

export default connect(mapStateToProps, {loadShipping, handleSearchInputMUI, updateMUISetting, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows, setRowChecked, handleHover})(ShippingCostTable);