import * as TYPES from '../../types';
import { UserService } from './_service.users.js';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import history from '../../../history';
import { SwalWarning } from '../../../Services/_swal.service';
import { blockRoute } from '../helper/helper.actions';

export const setLoading = status => async dispatch => dispatch({ type: TYPES.SET_LOADING, payload: status });
export const setRedirect = status => async dispatch => dispatch({ type: TYPES.SET_REDIRECT, payload: status });

export const handleMobile = (mobile) => async (dispatch, getState) => {
    let { user_form } = getState().userManagement;
	user_form.mobile = mobile;
    dispatch({ type: TYPES.SET_USER_FORM, payload: user_form });

}

export const getRoles = (id) => async (dispatch, getState) => {
	dispatch(setRedirect(null));
    dispatch(setLoading(true));
    UserService.getRoles(id).then(res => {
        dispatch({ type: TYPES.SET_ROLES_DROPDOWN, payload: res.data.roles });
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
};

export const handleSelectRole = (selectOption) => async (dispatch, getState) => {
	let { user_form } = getState().userManagement;
	user_form.role = selectOption.value;
	user_form.role_select = selectOption;
    dispatch({ type: TYPES.SET_USER_FORM, payload: user_form });
}

export const handleChangeInput = e => async (dispatch, getState) => {

    if(e.target.value !== ''){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

	let { user_form } = getState().userManagement;
	user_form[e.target.name] = e.target.value;
    dispatch({ type: TYPES.SET_USER_FORM, payload: user_form });
}

export const saveAccount = (e) => async (dispatch, getState) =>{
    e.preventDefault();

    let { user_form } = getState().userManagement;

    dispatch(setLoading(true));
    $('.btn-save').attr('disabled','disabled').html('Saving...');
    UserService.saveAccount(user_form).then(res => {

    	switch(res.data.status){
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch({ type: TYPES.SET_USER_FORM, payload: {} });
                dispatch(setRedirect('/settings/users'));
                break;
            default:
                break;
        }

        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
        dispatch(setRedirect(null));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
}


export const getAccounts = (pageNumber, rows_per_page = null) => async (dispatch, getState) => {
    dispatch(setRedirect(null));
    dispatch(setLoading(true));

    let { search , pagination, sort_order_name, sort_order_direction } = getState().userManagement; 

    let searchPostParams = {
        search: search,
        activePage: pagination.activePage,
        page: pageNumber,
        rows_per_page: rows_per_page, 
        sort_order_name: sort_order_name,
        sort_order_direction: sort_order_direction
    }

    UserService.getAccounts(searchPostParams).then(res => {

        let pagination = {
            totalCount: res.data.accounts.total, 
            activePage: res.data.accounts.current_page, 
            itemsCountPerPage: res.data.accounts.per_page, 
            totalItemsCount: res.data.accounts.total,
        }

        dispatch({ type: TYPES.SET_ACCOUNTs, payload: { accounts: res.data.accounts.data, pagination: pagination } })
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
    });
};

export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getAccounts());
}

export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: TYPES.SET_ROLES_SEARCH, payload: e.target.value })
}

export const getSelectedRows = (selectedRows, currentRows) => async dispatch => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current,i) => {
        selectedRows.map((selected, _i) => {
            if(selected.index == current.index)
            {
                let selected = { id: current.data[0], name: current.data[1] };
                newArray.push(selected);
            }
        })
    });
    dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
}

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    
    let { rows_to_delete } = getState().rolesReducer;
    let names = '';
    rows_to_delete.map((rows,i) => {  names += rows.name + ", ";  })

    SwalWarning('Warning!', `Do you want to remove the Brand? "${names}"`, () => dispatch(moveToArchive()));
}

export const moveToArchive = () => async (dispatch, getState) => {

    let { rows_to_delete } = getState().rolesReducer;
    let id_arr = [];
    rows_to_delete.map(row => { id_arr.push(row.id) })

    let params = { 
        ids: JSON.stringify(id_arr)
    }

    dispatch(setLoading(true));
    UserService.ArchiveAccounts(params).then(res => {
        ToastSuccess(res.data.message);
        dispatch(setLoading(false));
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: null });
        dispatch(getAccounts());
    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
    });
};

export const getAccount = (id) => async (dispatch, getState) => {

    dispatch(setLoading(true));
    UserService.getAccount(id).then(res => {

        let account = res.data.user;

        let user_form = {
                        id: account._id,
                        birthday: account.subscriber_birthday,
                        name : account.admin_name,
                        mobile : account.contact_number,
                        email : account.admin_email,
                        username : account.admin_username,
                        role : account.role_id,
                        role_select : res.data.role_select,
                    }
        dispatch({ type: TYPES.SET_USER_FORM, payload: user_form });
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
};

export const updateAccount = (e) => async (dispatch, getState) =>{
    e.preventDefault();

    let { user_form } = getState().userManagement;

    dispatch(setLoading(true));
    $('.btn-save').attr('disabled','disabled').html('Saving...');
    UserService.updateAccount(user_form.id, user_form).then(res => {

        switch(res.data.status){
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch({ type: TYPES.SET_USER_FORM, payload: {} });
                dispatch(setRedirect('/settings/users'));
                break;
            default:
                break;
        }

        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
        dispatch(setRedirect(null));
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
}