import {
    SET_LOADING,
    SORT_BY_COLUMN,
    GET_ROWS_TO_DELETE,
    SET_ROW_CHECKED,
    GET_STATUS,
    SET_SUCCESS
} from '../../types';
import * as TYPES from '../../reducers/ReducerTypes/collectionReceipt';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from 'jquery';
import { CollectionReceiptService } from './_service.collection_receipt';
import { SwalWarningHtml } from '../../../Services/_swal.service';
import {
    getSelectAll,
    setModal,
    handleSelect,
} from '../product/product.actions';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { getNumRows } from '../../../Utils/Common';
import { SalesInvoiceService } from '../sales_invoice/_service.salesinvoice';

// handle inputs
export const CRHandleInputChange = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }
    console.log(e.target.name, e.target.value);
    dispatch({
        type: TYPES.CR_INPUT_CHANGE,
        payload: { key: e.target.name, value: e.target.value },
    });
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: TYPES.CR_SEARCH, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });

// clear data
export const clearCollectionReceipt = () => async (dispatch) =>
    dispatch({ type: TYPES.CLEAR_COLLECTION_RECEIPT });

// set page
export const setPage =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().collection_receipt;

        switch (page) {
            case 'main_collection_receipt_page':
                dispatch({ type: TYPES.SET_PAGE, payload: { page, status } });
                dispatch(clearCollectionReceipt());
                break;
            case 'edit_page':
                let edit_url_with_id = `${edit_url}/${id}`;
                dispatch({
                    type: TYPES.SET_PAGE,
                    payload: { page, status, edit_url: edit_url_with_id },
                });
                break;
            case 'delete_modal':
                dispatch(getSingleCollectionReceipt(id, 'delete'));
                break;
            default:
                break;
        }
    };

//tabs
export const handleChangeStatusView =
    (status_view) => async (dispatch, getState) => {
      dispatch({ type: GET_STATUS, payload: status_view });
  
      let {  search, collection_receipts } = getState().collection_receipt;
    //   console.log("collection_receipt", getState().collection_receipt)
  
      if (
        typeof collection_receipts[status_view] === "undefined" ||
        collection_receipts[status_view] == null
      ) {
        dispatch({ type: TYPES.CR_SEARCH, payload: "" });
        dispatch(getCollectionReceipt(1));
      } else {
        dispatch({
          type: TYPES.CR_SEARCH,
          payload: collection_receipts[status_view].cr_search,
        });
        dispatch({
          type: TYPES.GET_COLLECTION_RECEIPT,
          payload: {
            collection_receipts: collection_receipts,
            pagination: collection_receipts[status_view],
          },
        });
      }
    };
  

//  fetch cr
export const getCollectionReceipt =
    (pageNumber, rows_per_page = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));
        let {
            cr_search,
            pagination,
            pagination_list, 
            status_view,
            sort_order_name,
            sort_order_direction,
            // tab_name,
            filters,
        } = getState().collection_receipt;
        let rows_per_page_val =
            rows_per_page !== null
                ? rows_per_page
                : getNumRows('collection_receipt');

        // return only if the value of the key is not empty
        let keys = Object.keys(filters).filter((key) => filters[key] !== '');
        let newObj = {};
        keys.forEach((key) => (newObj[key] = filters[key]));

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let searchPostParams = {
            search: cr_search,
            filter: newObj,
            // tab_name,
            options: {
                page: pageNumber,
                limit: rows_per_page_val || 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        CollectionReceiptService.paginateWithSearchCollectionReceipt(
            searchPostParams, status_view
        )
            .then((res) => {
                let data_list = [];
                res.data.docs.map((data) => {
                data_list.push(data);
                });

                pagination_list[status_view] = data_list;

                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit,
                    totalItemsCount: res.data.total_docs,
                };

                dispatch({
                    type: TYPES.GET_CR_STATUS,
                    payload: {
                        pagination_list: pagination_list,
                        pagination: pagination,
                        collection_receipts: res.data.docs,
                    },
                });
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                // ToastDanger("Network error, please reload the page.");
                console.log(err);
            });
    };

// handle view tabs
export const CRHandleChangeTabs = (tabName) => async (dispatch, getState) => {
    dispatch({ type: TYPES.TAB_VIEW_CHANGE, payload: tabName });
    dispatch(getCollectionReceipt(1));
};

// fetch single collection_receipt
export const getSingleCollectionReceipt =
    (id, status = null) =>
    async (dispatch) => {
        if (!status) {
            dispatch(setLoading(true));
        }

        CollectionReceiptService.getSingleCollectionReceipt(id)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_SINGLE_COLLECTION_RECEIPT,
                    payload: res.data.docs,
                });

                if (!status) {
                    dispatch(setLoading(false));
                }
            })
            .catch((error) => {
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
                if (!status) {
                    dispatch(setLoading(false));
                }
            });
    };

// store
export const collectionPost = (e, pageOrigin) => async (dispatch, getState) => {
    e.preventDefault();

    let { single_collection_receipt } = getState().collection_receipt;

    if (pageOrigin === 'item') {
        single_collection_receipt['pull'] = true; // this will return the id and collection_receipt name
    }

    $('.btn-save').attr('disabled', 'disabled').html('Saving...');
    dispatch(setMUIPage(1));
    CollectionReceiptService.collectionReceiptPost(single_collection_receipt)
        .then((res) => {
            if (pageOrigin == 'collection_receipt') {
                // dispatch(blockRoute(false));
                dispatch(setPage('main_collection_receipt_page'));
            }

            if (pageOrigin == 'item') {
                let brandSelect = {
                    value: res.data.docs._id,
                    label: res.data.docs.name,
                };
                dispatch(handleSelect(brandSelect, 'brand_id'));

                dispatch(setModal('brand_modal', false));
                dispatch(getSelectAll());
            }
            dispatch(clearCollectionReceipt());
            ToastSuccess(res.data.message);
            $('.btn-save').removeAttr('disabled').html('Save');
        })
        .catch((error) => {
            if (error.data?.errors.length > 0) {
                ToastDanger(error.data.errors);
            }
            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(error);
        });
};

// Success pop up
// export const setSuccess = (status) => async (dispatch) =>
// dispatch({ type: SET_SUCCESS, payload: status });

const flattenObject = (obj, prefix = '') => {
    let flattened = {};
    for (const [key, value] of Object.entries(obj)) {
        const propName = prefix ? `${prefix}[${key}]` : key;
        if (typeof value === 'object' && value !== null) {
            flattened = { ...flattened, ...flattenObject(value, propName) };
        } else {
            flattened[propName] = value;
        }
    }
    return flattened;
};

// update
export const collectionPayment = (e) => async (dispatch, getState) => {
    e.preventDefault();
    dispatch(setLoading(true));
    let { single_collection_receipt, create_payment } = getState().collection_receipt;
    let cr = single_collection_receipt;

    // Ensure that the balance won't go negative
    if (create_payment.amount <= cr.balance) {
        cr.balance = cr.balance - create_payment.amount;

        if (cr.balance === 0){
            cr.status = 'paid';
        } else if (cr.balance < cr.total && cr.balance != 0){
            cr.status = 'partial';
        }
    }

    const formdata = new FormData();
    formdata.append('_id', cr._id || '');
    formdata.append('balance', cr.balance || 0);
    formdata.append('status', cr.status || '');
    formdata.append('sales_order_id', cr.sales_order_id || '');
    formdata.append('payment_date', create_payment.payment_date);
     // Handle payment_method separately
     if (typeof create_payment.payment_method === 'object' && create_payment.payment_method !== null) {
        const flattenedPaymentMethod = flattenObject(create_payment.payment_method, 'payment_method');
        Object.keys(flattenedPaymentMethod).forEach((property) => {
            formdata.append(property, flattenedPaymentMethod[property]);
        });
    } else {
        formdata.append('payment_method', create_payment.payment_method);
    }
    formdata.append('check_no', create_payment.check_no || '');
    formdata.append('bank', create_payment.bank || '');
    formdata.append('check_date', create_payment.check_date || '');
    formdata.append('amount', parseFloat(create_payment.amount) || 0);
    formdata.append('upload', create_payment.upload || '');
    formdata.append('upload_preview', create_payment.upload_preview || '');
    formdata.append('remarks', create_payment.remarks || '');
    formdata.append('ref_no', create_payment.ref_no || '');  
    formdata.append('account', create_payment.account || '');
    formdata.append('method', create_payment.method || '');
    // // Flatten and append updatedCollection properties to FormData dynamically
    // const flattenedCollection = flattenObject(cr);
    // Object.keys(flattenedCollection).forEach((property) => {
    //     formdata.append(property, flattenedCollection[property]);
    // });

    $('.btn-save').attr('disabled', 'disabled').html('Saving...');
    
    CollectionReceiptService.paymentCollectionReceipt(formdata)
        .then((res) => {

        //    console.log(res)
            // dispatch(
            //     updateSingleCollectionReceipt(
            //         data,
            //         data._id,
            //         actions
            //     )
            // )
            switch (res.data.status) {
                case 0:
                    ToastDanger(res.data.errors);
                    dispatch(setLoading(false));
                    break;
                case 1:
                    dispatch(CRSetModal('create_payment_modal', false))
                    dispatch(setPage('main_collection_receipt_page'));
                    ToastSuccess(res.data.message);
                    dispatch(getCollectionReceipt(1))
                    // dispatch(getSingleCollectionReceipt(single_collection_receipt._id))
                    dispatch(setLoading(false));
                    // dispatch(setSuccess(true)); // Set success to true
                    // setTimeout(() => {
                    //     dispatch(setSuccess(false)); // Set success back to false after 5 seconds
                    // }, 3000);
                    break;
                default:
                    break;
            }
            $('.btn-save').removeAttr('disabled').html('Save');
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                error.data.errors.map((err) => ToastDanger(err));
                dispatch(setLoading(false));
            } else {
                // ToastDanger("Network error, please reload the page.");
            }
            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(error);
        });
};

// archive
export const moveToArchive = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().collection_receipt;
    let id_arr = [];
    rows_to_delete.map((row) => {
        id_arr.push(row.id);
    });

    let params = {
        ids: JSON.stringify(id_arr),
    };

    CollectionReceiptService.ArchiveCollectionReceipt(params)
        .then((res) => {
            dispatch(setRowChecked());
            ToastSuccess(res.data.message);
            dispatch(getCollectionReceipt());
            dispatch(clearCollectionReceipt());
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            console.log(error);
        });
};

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.CR_SEARCH, payload: e.target.value });
};

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction) => async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getCollectionReceipt());
    };

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
    };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().collection_receipt;
    let names = [];
    rows_to_delete.map((rows, i) => {
        names.push('<b>' + rows.name + '</b>');
    });

    SwalWarningHtml(
        'Warning!',
        `Do you want to remove the Brand: ${names.join()}?`,
        () => dispatch(moveToArchive())
    );
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: SET_ROW_CHECKED });

// handle select of status and terms
export const CRHandleSelect =
    (selectedOption) => async (dispatch, getState) => {
        if (selectedOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }
        let { filters } = getState().collection_receipt;

        const { label, origin, value } = selectedOption;

        let payloadValue = {};
        let filter_value = value;

        if (origin === 'status') {
            payloadValue = { key: 'status_value', _value: { label, value } };
        }

        if (origin === 'payment_terms') {
            payloadValue = {
                key: 'payment_terms_value',
                _value: { label, value },
            };

            // order of object is required coz of the order in the field in collection
            filter_value = {
                value: selectedOption.value,
                label: selectedOption.label,
            };
        }

        // set the selected value
        dispatch({ type: TYPES.CR_HANDLE_SELECT, payload: payloadValue });

        // fetching the api using filtered
        dispatch({
            type: TYPES.CR_API_FILTER,
            payload: { ...filters, [origin]: filter_value },
        });
    };

// get all the terms
export const getCRPaymentTerms = () => async (dispatch) => {
    try {
        const res = await SalesInvoiceService.getTermsConditions();

        let terms_arr = [];
        let terms = res.data.terms_conditions;

        terms.map((val, i) => {
            let select = {
                origin: 'payment_terms',
                value: val.content,
                label: val.title,
            };
            terms_arr[i] = select;
        });

        dispatch({ type: TYPES.CR_GET_PAYMENT_TERMS, payload: terms_arr });
    } catch (error) {
        console.log(error);
    }
};

// date input onchange
export const CRDateFilterInput = (e) => (dispatch, getState) => {
    let { filters } = getState().collection_receipt;

    dispatch({
        type: TYPES.CR_API_FILTER,
        payload: { ...filters, [e.target.name]: e.target.value },
    });

    dispatch({
        type: TYPES.CR_DATE_FILTER_INPUT,
        payload: e.target.value,
    });
};

// clearing the selected and date values
export const CRClearFilterInputs = (e) => (dispatch) => {
    dispatch({ type: TYPES.CR_CLEAR_FILTER_INPUTS });
    dispatch(getCollectionReceipt(1));
};

export const CRTopSearchButton = (e) => (dispatch, getState) => {
    dispatch(getCollectionReceipt(1));
};

export const CRDrawerToggle = (open) => (dispatch, getState) => {
    dispatch({ type: TYPES.CR_DRAWER_TOGGLE, payload: open });
};

// handle modals
export const CRSetModal = (modal, status) => async (dispatch) => {
    // if (status === false) {
    //     dispatch({ type: TYPES.CLEAR_COLLECTION_RECEIPT });
    // }

    dispatch({
        type: TYPES.CR_SET_MODAL,
        payload: { modal, status },
    });
};

export const setSelectedPayment = (paymentData) => (dispatch) => {
    dispatch({
      type: TYPES.SET_SELECTED_PAYMENT,
      payload: paymentData,
    });
  };

// handle create payment input
export const CRCreatePaymentInput =
    (input, origin) => async (dispatch, getState) => {
        let payload;
        let { create_payment, single_collection_receipt } =
            getState().collection_receipt;

        if (origin !== 'payment_method') {
            let { name, value } = input.target;

            payload = { key: name, value };
        } else {
            // payment_method select
            payload = { key: origin, value: input };
        }

        dispatch({ type: TYPES.CR_HANDLE_CREATE_PAYMENT, payload });
    };

export const submitCreatePayment = (e) => async (dispatch, getState) => {
    e.preventDefault();
    // console.log('THIS CALL');
    let {
        create_payment: {
            payment_date,
            payment_method,
            check_no,
            bank,
            check_date,
            amount,
            upload,
        },
        single_collection_receipt: { total },
    } = getState().collection_receipt;

    // add validation to amount not greater than the total
    // console.log(amount, total);
    if (parseInt(amount) > total) {
        ToastDanger('The amount enter is greater than the balance Amount.');
    }

    // add validation upload attachment is required

    // call the api
};

// handle onChange upload image
export const uploadImage = (selectedFile) => async (dispatch) => {
    let preview = URL.createObjectURL(selectedFile[0]);

    // console.log(preview, selectedFile[0])

    dispatch({
        type: TYPES.CR_HANDLE_UPLOAD,
        payload: { upload: selectedFile[0], upload_preview: preview },
    });
};

export const removeFile = (e) => async (dispatch, getState) => {
    e.preventDefault();

    $('#itemUpload').val('');
    dispatch({ type: TYPES.CR_HANDLE_REMOVE_UPLOAD });
};
