import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MiniSpinner } from '../layouts/Spinner';
import { connect } from 'react-redux';
import Select from "react-select";
import { updateSingleAttributeTemplate, handleInputChange, handleInputChangeAttr, getUnitMeasurement, handleSelectOptionProperty, handleAddAttribute, handleRemoveAttr, handleSelectUnit, handSelectInputType, handSelectAddOption, handSelectRemoveOption, handChangeInputOption, handSelectValidation, getSingleAttributeTemplate, setModal} from "../../redux/actions/attribute_template/attribute_template.actions";
import PurchaseOrderBlankTable from "../layouts/table_template/PurchaseOrderBlankTable";
import { Redirect } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from "../../redux/actions/helper/helper.actions";

function AttributeTemplateEdit ({ attributes, attribute_template : { main_url, single_attribute_template, type_options, unit_options, unit_measurement, attributesList, 
	validation_options, mini_loading, edit_modal},handSelectInputType, handleSelectUnit, handleAddAttribute, updateSingleAttributeTemplate, handleInputChange, handleInputChangeAttr,
	 getUnitMeasurement, handleSelectOptionProperty, handleRemoveAttr, handSelectAddOption, handSelectRemoveOption, handChangeInputOption, handSelectValidation, 
	 getSingleAttributeTemplate, setModal, action, match, block_route, blockRoute }) {
	
	const default_attr = {'id' : '', 'label': '', inputy_type_value: null, unit_options: {}, property_default: null, validation: {}, unit_options_value: null, input_options: [{label: ''}] };
    
	const history = useHistory();
	useEffect(() => {
		// console.log(match.params.id);
        // getUnitMeasurement();
        getSingleAttributeTemplate(match.params.id);
        // handleAddAttribute(attributesList, default_attr);
        blockRoute(false);
    }, [])

	useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01')
        });

        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
            console.log('02');
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);


    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => {
        window.history.pushState(null, null, window.location.pathname);
        console.log('03')
    }

	// console.log(single_attribute_template);
	// console.log(attributesList);
	if(single_attribute_template.success) return <Redirect to={main_url}/>

	let attr_list = attributesList;

	return (
		<BeforeUnloadComponent 
			ignoreBeforeunloadDocument={true}
			blockRoute={block_route}
			modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
				SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
			}}
		>
		<div className="app-content content">
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Products</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1 f-regular">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/products/attribute-template" className="text-white">Attribute Templates</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                	{
							            mini_loading ? <MiniSpinner /> 
							            : 
						            	<Fragment>
											<ReactTooltip  effect="solid" event="click"/>
		                                    <div className="card-header">
		                                    	<Link to='/inventory/products?mode=Attributes'><i className="ft ft-arrow-left"></i> Back</Link>
		                                        <h4 className="card-title mt-2">Edit Attribute(s) <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
		                                        <div className="heading-elements"> <ul className="list-inline mb-0"></ul></div>
		                                    </div>
		                                    <div className="card-content collapse show">
		                                        <div className="card-body">
													<form onSubmit={updateSingleAttributeTemplate} id="attribute-form">
														<section className="row">
															<div className="col-sm-12 table-responsive">
																<table className="table table-hover table-striped table-bordered table-sm">
			                                                        <thead>
			                                                            <tr>
			                                                                <th className='text-center' >Label <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></th>
			                                                                <th className='text-center' >Unit Of Measuremnt <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></th>
			                                                                <th className='text-center' >Input Type <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></th>
			                                                                <th className='text-center' >Options <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></th>
			                                                                <th className='text-center' > </th>
			                                                            </tr>
			                                                        </thead>
			                                                        <tbody>
			                                                        	{ 	
			                                                        		(attr_list.length !== null) ?
				                                                        		(attr_list.length > 0) ?
					                                                        		attributesList.map((item, index) => (
				                                                                    	<tr key={index}>
							                                                                <td className='width-200 p-1 px-1'><input onChange={e => handleInputChangeAttr(index, e, attributesList)} value={item.label} required placeholder="Attribute name" type="text" id="attribute_label" name="attribute_label[]"  className="form-control"/></td>
							                                                                <td className='min-width-md p-1 px-1 '>
							                                                                	<div class='row'>
                                                            										<div class='col-md-6 col-lg-6 col-sm-6 padding-l-r-2px'>
							                                                                			<Select className="Select-a" onChange={e => handleSelectOptionProperty(index, e, attributesList)} value={item.property_default} required name="property" isSearchable="false" options={unit_measurement} placeholder="Choose Measurement" /> <br />
							                                                                		</div>
							                                                                		<div class='col-md-6 col-lg-6 col-sm-6 padding-l-r-2px'>
							                                                                			<Select name="unit" isSearchable="true" required onChange={e => handleSelectUnit(index, e, attributesList)} value={item.unit_options_value} options={item.unit_options} placeholder="Choose Unit"/>
							                                                                		</div>
							                                                                	</div>
							                                                                </td>
							                                                                <td className='width-200 p-1 px-1'>
							                                                                	<Select onChange={e => handSelectInputType(index, e, attributesList)} value={item.inputy_type_value} isSearchable="false" options={type_options} placeholder="Choose Type"/>
							                                                                </td>
							                                                                <td class='width-200 p-1 px-1'>
							                                                                	{
							                                                                		(item.inputy_type_value !== null) ?
						                                                                				(item.inputy_type_value.value === 'Checkbox' || item.inputy_type_value.value === 'Radio' || item.inputy_type_value.value === 'Dropdown' )?
						                                                                					<div>
								                                                                				{item.input_options.map((input_opt, input_opt_index) => (
								                                                                					
																													<div class="input-group mb-1">
																													  <input type="text" class="form-control" required placeholder="Input Option" aria-label="Input Option" value={input_opt.label} onChange={e => handChangeInputOption(index, e, attributesList, input_opt_index)}  />
																													  <div class="input-group-append">
																													    <button class="btn btn-sm btn-danger" type="button" onClick={e => handSelectRemoveOption(index, e, attributesList, input_opt_index)} ><i className="ft ft-x"></i></button>
																													  </div>
																													</div>
								                                                                				))}
								                                                                				<span><a className="btn btn-primary btn-sm text-white " onClick={e => handSelectAddOption(index, e, attributesList)} >Add Options</a></span>
							                                                                				</div>
						                                                                				:
							                                                                				(item.inputy_type_value !== null) ?
								                                                                				(item.inputy_type_value.value === 'Input Box')?
								                                                                				<Select onChange={e => handSelectValidation(index, e, attributesList)} value={item.validation} isSearchable="false" options={validation_options} placeholder="Choose Validation"/> 
								                                                                				:
								                                                                				<span></span>
								                                                                			: 
								                                                                			<span></span>
						                                                                			: 
						                                                                			<span><center><small class='text-default text-center'>Please, Select Input Type</small></center></span>
							                                                                	}
							                                                                	
							                                                                </td>
							                                                                <td class='max-wdth-100'><center><a className="btn btn-sm btn-danger align-middle text-white" onClick={e => handleRemoveAttr(index, attributesList)}> <i className="ft ft-trash"></i></a></center></td>
							                                                            </tr>
				                                                                	))
				                                                               	:
				                                                               	<tr><td class='text-center' colSpan="10"><span>You have no attribute, please press Add.</span></td></tr>
				                                                            :
				                                                            <tr><td class='text-center' colSpan="10"><span>You have no attribute, please press Add.</span></td></tr>   	
		                                                                }
			                                                        	<tr>
			                                                        		<td colSpan="10"><a className="btn btn-sm btn-primary text-white"  onClick={e => handleAddAttribute(attributesList, default_attr)} >Add Attributes</a></td>
			                                                        	</tr>
			                                                        </tbody>
		                                                        </table>
		                                                        <br /><br /><br /><br /><br /><br /><br />
															</div>
														</section>

														<div className="row justify-content-end">
															<div className="mr-2">
																<div className="form-group">
																	<a type="button" className="btn btn-sm btn-danger f-regular" href="/products/attribute-template">Cancel</a>{" "}
																	<button type="submit" className="btn btn-primary btn-sm btn-save f-regular">Save</button>
																</div>
															</div>
														</div>
													</form>
												</div>
		                                	</div>
	                                	</Fragment>
		                            }    	
                            	</div>	
                            </div>
                        </div>    
                    </section>
                </div>
            </div>
            <Modal dialogClassName="modal-dialog modal-sm" show={edit_modal} size="sm" onHide={ () => { setModal('edit_modal', null, false) }} animation={true}>
			    <Modal.Header closeButton>
	              <Modal.Title>Attribute Template Name</Modal.Title>
	            </Modal.Header>
			    <Modal.Body>
	                <div className='row'>
			        	<div className='col-md-12'>
			        		<p>Give your group of Attributes a Template Name to access them easier later (e.g Furniture Attributes, Phone Attributes)</p>
			        	</div>
			        	<div className='col-md-12'>
							<div className="form-group"> 
								<label className="label-control">Template Name: <span className='text-danger'>*</span></label>
								<input onChange={handleInputChange} value={single_attribute_template.template_name} required type="text" id="name" name="template_name" className="form-control" placeholder="Template Name"/>
							</div>
						</div>
			        </div>
			    </Modal.Body>
			    <Modal.Footer>
				   <Button variant="danger  btn-sm" onClick={ () => { setModal('edit_modal', null, false) }} >Close </Button>
				   <button type="submit" className="btn btn-sm btn-save btn-primary"  form="attribute-form" >Save</button>
			    </Modal.Footer>
			</Modal>
        </div>	
        </BeforeUnloadComponent>
	)
}

const mapStateToProps = state => ({
	attribute_template: state.attribute_template,
	attributes: state.attribute_template.attributes,
    block_route: state.helper.block_route
});

export default connect(mapStateToProps, { updateSingleAttributeTemplate, handleInputChange, handleInputChangeAttr, getUnitMeasurement, handleSelectOptionProperty,
	handleAddAttribute, handleRemoveAttr, handleSelectUnit, handSelectInputType, handSelectAddOption, handSelectRemoveOption, handChangeInputOption, handSelectValidation, 
	getSingleAttributeTemplate, setModal, blockRoute })(AttributeTemplateEdit);
