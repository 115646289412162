import React, { PropTypes , useEffect, useState } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export const customAlert = (header, message, callback) => {
    return confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='custom-alert'>
                        <div className="custom-alert-body text-center">
                            <i className="alert-icon la la-info-circle"></i>
                            <h1>{header}</h1>
                            <p>{message}</p>
                        </div>
                        
                        <button className="btn btn-outline-secondary" onClick={onClose}>No</button>
                        <button className="btn btn-primary float-right" onClick={() => {callback(); onClose();}} >Yes</button>
                    </div>
                    );
                }
            });
}