import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import {
    numberFormat,
    abbreviateNumber,
    formatPrice,
} from '../../Utils/Common';
import { connect } from 'react-redux';
import { Show, useAccess } from 'react-redux-permission';
import {
    getDashboardData,
    salesLineChart,
    topCustomersBarChart,
    createPieChart,
    generateRandomColors,
    useCardVisibility,
    useTopCardVisibility,
    useChartVisibility,
} from '../../redux/actions/home/home.actions';
import { Line, Pie, Doughnut, Bar } from 'react-chartjs-2';
import CardContent from './content/CardContent';
import PieContent from './content/PieContent';
import ProgressBarContent from './content/ProgressBarContent';

function Dashboard({
    home: { sm_boxes, graphs, loading, incoming_purchaseOrders, order_history },
    getDashboardData,
}) {
    useEffect(() => {
        getDashboardData();
    }, []);

    const [currentFilter, setCurrentFilter] = useState('year-to-date');

    const { cardVisibility, toggleCard } = useCardVisibility();
    const { topCardVisibility, toggleTopCard } = useTopCardVisibility();
    const { chartVisibility, toggleChart } = useChartVisibility();

    // Calculate the number of visible pie charts
    const visibleTopCardCount = Object.values(topCardVisibility).filter(
        (isVisible) => isVisible
    ).length;

    // Count the number of visible charts
    const visibleChartsCount = Object.values(chartVisibility).filter(
        (isVisible) => isVisible
    ).length;

    // Determine the column size and offset based on the number of visible charts
    let columnSize = 'col-6';
    let offset = '';
    if (visibleChartsCount === 1) {
        columnSize = 'col-6 offset-md-3'; // Center the card when only one chart is visible
    } else if (visibleChartsCount === 2) {
        columnSize = 'col-6';
    }
    // sales line graph
    const salesLineData = salesLineChart(graphs.sales);

    const salesLineOptions = {
        maintainAspectRatio: true,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: '#999',
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        callback: (value, index, values) => {
                            return `${formatPrice(
                                value,
                                'code',
                                'currency',
                                'PHP',
                                {
                                    notation: 'compact',
                                }
                            )}`;
                        },
                        fontColor: '#999',
                    },
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0.1)',
                    },
                },
            ],
        },
        legend: {
            display: true, // Display legend
            labels: {
                fontColor: '#999',
            },
        },
        tooltips: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            titleFontColor: '#fff',
            bodyFontColor: '#fff',
            callbacks: {
                label: (tooltipItem, data) => {
                    let label =
                        data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += `${formatPrice(
                        tooltipItem.yLabel,
                        'code',
                        'currency',
                        'PHP',
                        {
                            notation: 'compact',
                        }
                    )}`;
                    return label;
                },
            },
        },
    };

    // Extracting data for top customers
    const topCustomerNames = graphs.topCustomers.map(
        (customer) => customer.name
    );
    const topCustomerSales = graphs.topCustomers.map(
        (customer) => customer.totalSales
    );

    // Bar graph data
    const customerBarData = topCustomersBarChart(
        topCustomerNames,
        topCustomerSales
    );

    // Bar chart options
    const topCustomersBarOptions = {
        maintainAspectRatio: true,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: '#999', // Adjust x-axis tick font color
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: (value) =>
                            `${formatPrice(value, 'code', 'currency', 'PHP', {
                                notation: 'compact',
                            })}`,
                        fontColor: '#999', // Adjust y-axis tick font color
                    },
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0.1)', // Lighter gridline color
                    },
                },
            ],
        },
        legend: {
            display: false, // Hide legend
        },
        tooltips: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker tooltip background
            titleFontColor: '#fff', // Tooltip title font color
            bodyFontColor: '#fff', // Tooltip body font color
            callbacks: {
                label: (tooltipItem, data) => {
                    let label =
                        data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += `${formatPrice(
                        tooltipItem.yLabel,
                        'code',
                        'currency',
                        'PHP',
                        {
                            notation: 'compact',
                        }
                    )}`;
                    return label;
                },
            },
        },
    };

    const categoryPieChart = createPieChart(
        graphs.categories.labels,
        graphs.categories.data
    );

    const collectionPieChart = createPieChart(
        graphs.collections.labels,
        graphs.collections.data
    );

    // const topProductsData = {
    //     labels: truncatedLabels,
    //     datasets: [
    //         {
    //             data: graphs.topProducts.map((product) => product.totalSales),
    //             backgroundColor: generateRandomColors(
    //                 graphs.topProducts.length
    //             ),
    //             hoverBackgroundColor: generateRandomColors(
    //                 graphs.topProducts.length
    //             ),
    //         },
    //     ],
    //     options: {
    //         legend: {
    //             position: 'right',
    //             labels: {
    //                 boxWidth: 10,
    //             },
    //         },
    //         maintainAspectRatio: false,
    //         width: 20,
    //         height: 20,
    //     },
    // };

    return (
        <div className="content-body">
            <Show when="inv.dashboard">
                {/* FILTER NAV */}
                <section id="minimal-statistics">
                    <div className="row">
                        <div className="col-12">
                            {' '}
                            <div className="card ">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="media d-flex align-items-center">
                                            <p className="box-title mr-1">
                                                Filter:
                                            </p>
                                            <div
                                                className={`p-1 badge badge-pill mr-1 ${
                                                    currentFilter ===
                                                    'year-to-date'
                                                        ? 'badge-thm-gradient'
                                                        : 'badge-light'
                                                }`}
                                                onClick={(e) => {
                                                    setCurrentFilter(
                                                        'year-to-date'
                                                    );
                                                    getDashboardData(
                                                        'year-to-date'
                                                    );
                                                }}
                                            >
                                                <a className="">Year-to-date</a>
                                            </div>
                                            <div
                                                className={`p-1 badge badge-pill mr-1 ${
                                                    currentFilter === 'today'
                                                        ? 'badge-thm-gradient'
                                                        : 'badge-light'
                                                }`}
                                                onClick={(e) => {
                                                    setCurrentFilter('today');
                                                    getDashboardData('today');
                                                }}
                                            >
                                                <a className="">Today</a>
                                            </div>
                                            <div
                                                className={`p-1 badge badge-pill mr-1 ${
                                                    currentFilter ===
                                                    'yesterday'
                                                        ? 'badge-thm-gradient'
                                                        : 'badge-light'
                                                }`}
                                                onClick={(e) => {
                                                    setCurrentFilter(
                                                        'yesterday'
                                                    );
                                                    getDashboardData(
                                                        'yesterday'
                                                    );
                                                }}
                                            >
                                                <a className="">Yesterday</a>
                                            </div>
                                            <div
                                                className={`p-1 badge badge-pill mr-1 ${
                                                    currentFilter ===
                                                    'this-week'
                                                        ? 'badge-thm-gradient'
                                                        : 'badge-light'
                                                }`}
                                                onClick={(e) => {
                                                    setCurrentFilter(
                                                        'this-week'
                                                    );
                                                    getDashboardData(
                                                        'this-week'
                                                    );
                                                }}
                                            >
                                                <a className="">This Week</a>
                                            </div>
                                            <div
                                                className={`p-1 badge badge-pill mr-1 ${
                                                    currentFilter ===
                                                    'month-to-date'
                                                        ? 'badge-thm-gradient'
                                                        : 'badge-light'
                                                }`}
                                                onClick={(e) => {
                                                    setCurrentFilter(
                                                        'month-to-date'
                                                    );
                                                    getDashboardData(
                                                        'month-to-date'
                                                    );
                                                }}
                                            >
                                                <a className="">
                                                    Month-to-date
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{' '}
                    </div>
                </section>

                {/* SALES AND INVENTORY CARDS */}
                <section id="minimal-statistics">
                    <div className="row">
                        {Object.entries(cardVisibility).map(
                            ([cardName, isVisible]) =>
                                isVisible && (
                                    <div
                                        className="col-xl-3 col-lg-6 col-md-12"
                                        key={cardName}
                                    >
                                        <div className="card">
                                            <div className="card-header p-1">
                                                <div className="heading-elements">
                                                    <ul className="list-inline mb-0">
                                                        <li>
                                                            <a
                                                                onClick={() =>
                                                                    toggleCard(
                                                                        cardName
                                                                    )
                                                                }
                                                            >
                                                                <i className="ft-x"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-content collapse show">
                                                {/* Include the CardContent component with appropriate props */}
                                                {cardName === 'Total Sales' && (
                                                    <CardContent
                                                        iconClass="icon-basket-loaded icon-opacity success font-large-4"
                                                        value={`PHP ${
                                                            abbreviateNumber(
                                                                sm_boxes.total_sales
                                                            ) || 0
                                                        }`}
                                                        cardName={cardName}
                                                    />
                                                )}
                                                {cardName ===
                                                    'Gross Profit' && (
                                                    <CardContent
                                                        iconClass="icon-wallet icon-opacity danger font-large-4"
                                                        value={`PHP ${
                                                            abbreviateNumber(
                                                                sm_boxes.gross_profit
                                                            ) || 0
                                                        }`}
                                                        cardName={cardName}
                                                    />
                                                )}{' '}
                                                {cardName === 'Net Sales' && (
                                                    <CardContent
                                                        iconClass="ft-book icon-opacity info font-large-4"
                                                        value={`PHP ${
                                                            abbreviateNumber(
                                                                sm_boxes.net_sales
                                                            ) || 0
                                                        }`}
                                                        cardName={cardName}
                                                    />
                                                )}
                                                {cardName ===
                                                    'Inventory Value' && (
                                                    <CardContent
                                                        iconClass="ft-clipboard icon-opacity warning font-large-4"
                                                        value={`PHP ${
                                                            abbreviateNumber(
                                                                sm_boxes.inventory_value
                                                            ) || 0
                                                        }`}
                                                        cardName={cardName}
                                                    />
                                                )}
                                                {cardName ===
                                                    'Out Of Stock Items' && (
                                                    <CardContent
                                                        iconClass="ft-x-circle icon-opacity danger font-large-4"
                                                        value={
                                                            sm_boxes.outOfStock
                                                        }
                                                        cardName={cardName}
                                                    />
                                                )}
                                                {cardName ===
                                                    'Low Stock Items' && (
                                                    <CardContent
                                                        iconClass="ft-alert-circle icon-opacity warning font-large-4"
                                                        value={
                                                            sm_boxes.lowStock
                                                        }
                                                        cardName={cardName}
                                                    />
                                                )}
                                                {cardName ===
                                                    'Incoming Items' && (
                                                    <CardContent
                                                        iconClass="icon-tag success font-large-4"
                                                        value={
                                                            sm_boxes.incoming_items
                                                        }
                                                        cardName={cardName}
                                                    />
                                                )}
                                                {cardName === 'Collectible' && (
                                                    <CardContent
                                                        iconClass="ft-pocket icon-opacity info font-large-4"
                                                        value={
                                                            sm_boxes.collectible
                                                        }
                                                        cardName={cardName}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </section>

                {/* SALES AND CUSTOMERS CHARTS CARDS */}
                <section id="minimal-statistics">
                    <div className="row ">
                        {Object.entries(chartVisibility).map(
                            ([chartName, isVisible]) =>
                                isVisible && (
                                    <div className={columnSize} key={chartName}>
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="heading-elements">
                                                    <ul class="list-inline mb-0">
                                                        <li>
                                                            <li>
                                                                <a
                                                                    onClick={() =>
                                                                        toggleChart(
                                                                            chartName
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="ft-x"></i>
                                                                </a>
                                                            </li>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="card-content collapse show">
                                                <div className="card-body">
                                                    <div className="media">
                                                        <div className="media-body text-right align-self-bottom">
                                                            <span className="d-block mb-1 font-medium-1">
                                                                <div className="text-center">
                                                                    <h5 className="card-title my-auto">
                                                                        {
                                                                            chartName
                                                                        }{' '}
                                                                        (
                                                                        {
                                                                            currentFilter
                                                                        }
                                                                        )
                                                                    </h5>
                                                                </div>
                                                            </span>
                                                            {chartName ===
                                                                'Sales Chart' && (
                                                                <Line
                                                                    width={100}
                                                                    height={50}
                                                                    data={
                                                                        salesLineData
                                                                    }
                                                                    options={
                                                                        salesLineOptions
                                                                    }
                                                                />
                                                            )}
                                                            {chartName ===
                                                                'Top Customers' && (
                                                                <Bar
                                                                    width={100}
                                                                    height={50}
                                                                    data={
                                                                        customerBarData
                                                                    }
                                                                    options={
                                                                        topCustomersBarOptions
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </section>

                {/* TOP CARDS */}
                <section id="minimal-statistics">
                    <div className="row top-cards">
                        {Object.entries(topCardVisibility).map(
                            ([topCardName, isVisible]) =>
                                isVisible && (
                                    <div
                                        className={`col-xl-${
                                            visibleTopCardCount === 1
                                                ? 12
                                                : visibleTopCardCount === 2
                                                ? 6
                                                : 4
                                        }`}
                                        key={topCardName}
                                    >
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">
                                                    {topCardName}
                                                </h4>
                                                <div className="heading-elements">
                                                    <ul className="list-inline mb-0">
                                                        <li>
                                                            <a
                                                                onClick={() =>
                                                                    toggleTopCard(
                                                                        topCardName
                                                                    )
                                                                }
                                                            >
                                                                <i className="ft-x"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-content collapse show">
                                                <div className="card-body p-0">
                                                    <PieContent
                                                        topCardName={
                                                            topCardName
                                                        }
                                                        categoryPieChart={
                                                            categoryPieChart
                                                        }
                                                        collectionPieChart={
                                                            collectionPieChart
                                                        }
                                                        collection={
                                                            graphs.collections
                                                                .total
                                                        }
                                                    />
                                                    <ProgressBarContent
                                                        topCardName={
                                                            topCardName
                                                        }
                                                        graphs={graphs}
                                                    />{' '}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </section>
            </Show>
        </div>
    );
}

const mapStateToProps = (state) => ({
    home: state.home,
});

export default connect(mapStateToProps, { getDashboardData })(Dashboard);
