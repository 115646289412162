/* eslint-disable react/style-prop-object */
import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';
import '../../layouts/template_styles/mototruck.css';
import moment from 'moment';

export class MotoTruckTemplate extends React.PureComponent {
    // function SalesInvoicePrintContent({ po, auth }) {
    // let subId = auth.user.subscriber_id;
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.po.purchase_order_items.length;
        this.state = {
            po: this.props.po,
            auth: this.props.auth,
            activePage: 1,
            itemsCountPerPage: 10,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr key={'tr-' + i}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        // console.log(page, totalPage);
        const { po } = this.state;
        const totalItemsCount = po.purchase_order_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        po.purchase_order_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
                // console.log(extraRowsCount);
            }
        });

        // console.log(showItemsFrom, showItemsTo);
        // console.log(extraRowsCount);

        const DynamicStringStyle = {
            maxWidth: '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        let auth = this.state.auth;

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';
        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';
        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b temp-div pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const subs_setting = this.state.subs_setting;

        // get currency display from subscriber settings
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';
        console.log(subs_setting);
        const template_setting = this.state.po.template_setting;

        return (
            <div
                className="receipt-container margin-1p5em px-1 print-page mt-1"
                style={{ fontFamily: 'Arial' }}
            >
                {/* <div className="receipt-header text-center">
                    <div className="receipt-mototruck">
                        <div className="company-logo">
                            <img
                                src="/motoTruck.png"
                                alt="Logo"
                                style={{ width: '70px' }}
                            />
                        </div>
                        <div className="company-name">MOTO TRUCK</div>
                    </div>
                    <div className="company-details">
                        Owned & Operated by:{' '}
                        <strong>(MACRO TRUCK PARTS TRADING OPC)</strong> <br />
                        Don Sergio Osmeña St. Cagayan de Oro City
                        <br />
                        Tel. Nos. 856-4356, 859-1563, 0917-704-6036, Fax
                        852-1482 <br />
                        VAT Reg. TIN ___-___-___-___
                        <br />
                        Specialized in Truck Parts:{' '}
                        <strong>
                            FUSO, HINO, ISUZU, HOWO, FOTON, SHACMAN, & CHENGLONG{' '}
                        </strong>
                    </div>
                </div> */}

                <div className="print-body print-content-block">
                    {/* header */}
                    <table
                        className="table-no-border"
                        style={{
                            width: '100%',
                            textAlign: 'right',
                            marginBottom: '20px',
                        }}
                    >
                        <tr style={{ width: '100%', margin: '2px auto' }}>
                            <td style={{ width: '25%' }}>&nbsp;</td>
                            <td style={{ width: '25%' }}>&nbsp;</td>
                            <td style={{ width: '25%' }}>&nbsp;</td>
                            <td style={{ width: '25%' }}>
                                <p style={{ color: 'red' }}>
                                    PO # {po.purchase_order_no}
                                </p>
                            </td>
                        </tr>
                        <tr style={{ width: '100%' }}>
                            <td style={{ width: '25%' }}></td>
                            <td style={{ width: '25%' }}></td>
                            <td style={{ width: '25%' }}></td>
                            <td style={{ width: '25%' }}>
                                Date Approved: --/--/----
                            </td>
                        </tr>
                    </table>

                    {/* divider */}
                    <div
                        style={{
                            borderBottom: '1px solid gray',
                            width: '100%',
                            height: '1px',
                        }}
                    >
                        &nbsp;
                    </div>
                    {/* supplier info */}
                    <table
                        className="table-no-border"
                        style={{
                            textAlign: 'right',
                            marginTop: '20px',
                            width: '100%',
                            fontFamily: 'Arial',
                        }}
                    >
                        <tr
                            style={{
                                width: '100%',
                                margin: '2px 0',
                                padding: '0',
                            }}
                        >
                            <td style={{ width: '33%' }}>&nbsp;</td>
                            <td style={{ width: '33%' }}>&nbsp;</td>
                            <td style={{ width: '33%' }}>
                                <h2
                                    style={{
                                        fontWeight: 'normal',
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Arial',
                                    }}
                                >
                                    Supplier Information
                                </h2>
                            </td>
                        </tr>
                        <tr
                            style={{
                                width: '100%',
                                margin: '2px 0',
                                padding: '0',
                            }}
                        >
                            <td style={{ width: '33%' }}>&nbsp;</td>
                            <td style={{ width: '33%' }}></td>
                            <td style={{ width: '33%' }}>
                                <h3 style={{ fontFamily: 'Arial' }}>
                                    {po.supplier_details.name}
                                </h3>
                            </td>
                        </tr>
                        <tr
                            style={{ width: '100%', margin: '0', padding: '0' }}
                        >
                            <td style={{ width: '33%' }}>&nbsp;</td>
                            <td style={{ width: '33%' }}></td>
                            <td style={{ width: '33%' }}>
                                <h3 style={{ fontWeight: 'bold' }}>P:</h3>
                            </td>
                        </tr>
                    </table>

                    {/* date */}
                    {/* <div className="receipt-float">
                        <div className="charge">
                            <strong>CHARGE PURCHASE ORDER</strong>
                        </div>
                        <div className="date">
                            <strong>
                                DATE: {defaultFormat(po.sales_invoice_date)}
                            </strong>
                        </div>
                    </div> */}

                    {/* details */}
                    {/* <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-4">
                                <div>
                                    <strong>Supplier:</strong>{' '}
                                    {po.supplier.name}
                                </div>
                                <div>
                                    <strong>Address:</strong>{' '}
                                    {po.supplier.street1}
                                </div>
                                <div>
                                    <strong>Business Style:</strong>
                                </div>
                            </div>
                            <div className="col-4">
                                <div>
                                    <strong>Tin:</strong>
                                </div>
                                <div>
                                    <strong>PO NO:</strong>{' '}
                                    {po.purchase_order_no}
                                </div>
                                <div>
                                    <strong>Terms:</strong>{' '}
                                    {po.payment_terms.label}
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* purchase order items table */}
                    <table
                        className="table-with-border"
                        style={{ width: '100%', marginTop: '10px' }}
                    >
                        {po.status == 'draft' ? (
                            <div className="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <thead style={{ textAlign: 'left' }}>
                            <tr style={{ fontWeight: 'bold' }}>
                                <th style={{ padding: '5px' }}>qty</th>
                                <th style={{ padding: '5px' }}>unit</th>
                                <th style={{ padding: '5px' }}>name</th>
                                <th style={{ padding: '5px' }}></th>
                                <th style={{ padding: '5px' }}></th>
                            </tr>
                        </thead>

                        <tbody>
                            {po.purchase_order_items.map((item, index) => {
                                return (
                                    <tr key={`po_item ${index + 1}`}>
                                        <td
                                            width={'10%'}
                                            style={{
                                                textAlign: 'left',
                                                padding: '0.5rem 0.5rem',
                                                verticalAlign: 'top',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {item.qty}
                                        </td>
                                        <td
                                            width={'10%'}
                                            style={{
                                                textAlign: 'left',
                                                padding: '0.5rem 0.5rem',
                                                verticalAlign: 'top',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {item.product_unit
                                                ? item.product_unit.name
                                                : ''}
                                        </td>

                                        <td
                                            width={'60%'}
                                            style={{
                                                textAlign: 'left',
                                                padding: '0.5rem 0.5rem',
                                                verticalAlign: 'top',
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                fontSize: '12px',
                                                lineHeight: '1',
                                            }}
                                        >
                                            {item.product_name}
                                            {item.product ? (
                                                <>
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: '9px',
                                                        }}
                                                    >
                                                        {
                                                            item.product
                                                                .description
                                                        }
                                                    </span>
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {item.brand ? (
                                                <>
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: '9px',
                                                        }}
                                                    >
                                                        {item.brand.name}
                                                    </span>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'left',
                                                padding: '0.5rem 0.5rem',
                                                verticalAlign: 'top',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {item.sku}
                                        </td>

                                        <td
                                            style={{
                                                textAlign: 'left',
                                                padding: '0.5rem 0.5rem',
                                                verticalAlign: 'top',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {moment(
                                                po.purchase_order_date
                                            ).format('MM/DD/YY')}
                                        </td>
                                    </tr>
                                );
                            })}
                            {po.purchase_order_items.length < 5
                                ? this.renderExtrRow(
                                      5 - po.purchase_order_items.length
                                  )
                                : ''}
                        </tbody>
                    </table>

                    {/* remarks */}
                    <div
                        style={{
                            marginTop: '25px',
                            marginBottom: '50px',
                            width: '100%',
                        }}
                    >
                        <h2
                            style={{
                                color: 'red',
                                fontWeight: 'normal',
                                fontFamily: 'Arial',
                            }}
                        >
                            Remarks
                        </h2>
                        <p>{po.remarks}</p>
                    </div>

                    {/* users */}
                    <table
                        style={{
                            textAlign: 'center',
                            width: '50%',
                            margin: '100px auto',
                        }}
                        className="table-with-border"
                    >
                        <tr>
                            <td style={{ padding: '5px' }}>
                                <h3
                                    style={{
                                        fontWeight: 'normal',
                                        fontFamily: 'Arial',
                                    }}
                                >
                                    Prepared By
                                </h3>
                            </td>
                            <td style={{ padding: '5px' }}>
                                <h3
                                    style={{
                                        fontWeight: 'normal',
                                        fontFamily: 'Arial',
                                    }}
                                >
                                    Approved By
                                </h3>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    paddingTop: '20px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingBottom: '5px',
                                }}
                            >
                                <div
                                    style={{
                                        borderBottom: '1px solid black',
                                        width: '100%',
                                    }}
                                >
                                    &nbsp;
                                </div>
                                <h3
                                    style={{
                                        fontWeight: 'normal',
                                        fontFamily: 'Arial',
                                    }}
                                >
                                    {po.users.prepared_by.name}
                                </h3>
                            </td>
                            <td
                                style={{
                                    paddingTop: '20px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingBottom: '5px',
                                }}
                            >
                                <div
                                    style={{
                                        borderBottom: '1px solid black',
                                        width: '100%',
                                    }}
                                >
                                    &nbsp;
                                </div>
                                <h4
                                    style={{
                                        fontWeight: 'normal',
                                        fontFamily: 'Arial',
                                    }}
                                >
                                    {po.users.checked_by
                                        ? po.users.checked_by.name
                                        : ' '}
                                    &nbsp;
                                </h4>
                            </td>
                        </tr>
                    </table>

                    {/* <div className="receipt-table col-md-12 pr-0">
                        {po.status == 'draft' ? (
                            <div className="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table  table-striped table-print">
                            <thead className="receipt-thead">
                                <tr className="table-header">
                                    <th className="header-title text-center product-unit">
                                        QTY
                                    </th>
                                    <th className="header-title text-center product-unit">
                                        UNIT
                                    </th>
                                    <th className="header-title text-center">
                                        DESCRIPTION
                                    </th>
                                    <th className="header-title text-center product-unit">
                                        UNIT COST
                                    </th>
                                    <th className="text-center product-unit">
                                        TOTAL AMOUNT
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {po.purchase_order_items.map((data, index) =>
                                    showItemsFrom <= index ? (
                                        showItemsTo >= index ? (
                                            <>
                                                <tr key={index}>
                                                    <td
                                                        className="text-center"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <DynamicFont
                                                            content={data.qty}
                                                        />
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <DynamicFont
                                                            content={
                                                                data.product_unit_name
                                                            }
                                                        />
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${data._id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    data.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {data.description !=
                                                            '' && (
                                                            <>
                                                                <br />
                                                                <span className="f-10">
                                                                    {
                                                                        data.description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            data.cost,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            data.amount,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                </tr>

                                                {data.discount_type &&
                                                    data.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <div className="text-center">
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            Discount:{' '}
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                data.discount
                                                                            }
                                                                            {data
                                                                                .discount_type
                                                                                .label ===
                                                                            'Percent'
                                                                                ? ' %'
                                                                                : ''}
                                                                        </span>
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            margin: '0 0 0 10px',
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            Discount
                                                                            type:{' '}
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                data
                                                                                    .discount_type
                                                                                    .label
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {numberFormat(
                                                                    Number(
                                                                        data.discount_amount
                                                                    ).toFixed(2)
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div> */}
                    {page == totalPage ? (
                        <Fragment>
                            {/* <div className="container receipt-terms pr-0">
                                <div className="row receipt-terms-row">
                                    <div className="col-5 tab-footer left">
                                        <strong>TERMS & CONDITIONS:</strong>{' '}
                                        Interest of 12% per annum will be
                                        charged on all overdue accounts. In case
                                        of litigation, the court of the City of
                                        Cagayan de Oro shall have jurisdiction,
                                        and the supplier hereby agrees to pay
                                        for attorney’s fees and cost of suit
                                        resulting therefrom. Goods travel at
                                        buyer’s risk. Our responsibility ceases
                                        after goods delivered to the
                                        transportation are correct.
                                    </div>
                                    <div className="col-3 tab-footer left fs">
                                        No Return/Exchange will be entertained
                                        after 7 days from Date of Delivery.
                                    </div>
                                    <div className="col-4 tab-footer fs">
                                        <div>VATable Sales:</div>
                                        <div>VAT -Exempt Sales:</div>
                                        <div>Zero-Rated Sales:</div>
                                        <div>
                                            VAT Amount:{' '}
                                            {formatPrice(
                                                po.vat_amount,
                                                currencyDisplay
                                            )}
                                        </div>
                                        <div>
                                            <strong>TOTAL AMOUNT DUE:</strong>{' '}
                                            {formatPrice(
                                                po.vat_amount,
                                                currencyDisplay
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container receipt-acknowledgement">
                                <div className="row receipt-acknowledgement-row">
                                    <div className="col-5 tab-acknowledgement left">
                                        <div className="prepared-by">
                                            <div className="mb-5">
                                                Prepared by:
                                            </div>

                                            <div className="mt-5">
                                                ______________
                                            </div>
                                        </div>

                                        <div className="checked-by">
                                            <div className="mb-5">
                                                Checked by:
                                            </div>

                                            <div className="mt-5">
                                                ______________
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7 tab-acknowledgement right text-center">
                                        <h5>
                                            <strong>
                                                Customer’s Acknowledgement
                                            </strong>
                                        </h5>
                                        <p className="supplier-acknowledgement">
                                            Received the merchandise as
                                            indicated above in good order as to
                                            quantity and condition. All terms
                                            and conditions of sale are herein
                                            accepted
                                        </p>

                                        <div className="container ">
                                            <div className="row receipt-sig">
                                                <div className="col-6">
                                                    ____________________________{' '}
                                                    <br />
                                                    Printed Name of Authorized
                                                    Representative
                                                </div>
                                                <div className="col-6">
                                                    ____________________________{' '}
                                                    <br />
                                                    Signature
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container receipt-footer">
                                <div className="row">
                                    <div className="col-4">
                                        00 Bxs. (500x__ply) 0000-0000 BIR
                                        Authority to Print No. 098AU2021______
                                        Date Issued: _______ Valid Until:
                                        ________ Legacy Sales & Printing Press,
                                        Inc. TIN 003-053-883-000 VAT A. Luna
                                        Extn., 27 Cagayan de Oro City
                                    </div>
                                    <div className="col-4">
                                        <strong>
                                            {' '}
                                            BIR Permit to Use Loose-Leaf No.
                                            __________ <br />
                                            Date Issued: _____________
                                            <br /> <br />{' '}
                                        </strong>
                                        PRINTER’S ACCREDITATION NO.
                                        098MP20190000000008 Date Issued:
                                        03/18/19
                                    </div>
                                    <div className="col-4 text-center quote">
                                        <strong>
                                            “THIS CHARGE PURCHASE ORDER SHALL BE
                                            VALID FOR FIVE (5) YEARS FROM THE
                                            DATE OF ATP”
                                        </strong>
                                    </div>
                                </div>
                            </div> */}
                        </Fragment>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="row">
                    <div className="col-12 pb-0 mb-0">
                        <span className="pull-right f-12">
                            Page {page}/{totalPage}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let po = this.state.po;

        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{po.status == 'draft' ? po.status : ''}</h1>
                </div>
            */}
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemclassName="page-item"
                        linkclassName="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
// 	single_purchase_order: state.sales_invoice.single_purchase_order,
// 	auth: state.auth
// })

export default MotoTruckTemplate;
