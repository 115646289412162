import * as TYPES from './../types';

// state
const initialState = {
    email : ''
}

const forgetReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case TYPES.AUTH_USER:
            return {
                ...state,
                email: action.payload
            } 
        default:
            return state;
            break;
    }

}

export default forgetReducer;

