import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { formattedDate, numberFormat } from '../../Utils/Common';
import {
    getCollectionReceipt,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    CRHandleSelect,
    getCRPaymentTerms,
    CRDateFilterInput,
    CRClearFilterInputs,
    CRHandleInputChange,
    CRTopSearchButton,
    CRDrawerToggle,
    getSingleCollectionReceipt,
    CRSetModal,
} from '../../redux/actions/collection_receipt/collection_receipt.action';
import { Redirect, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import moment from 'moment';
import Select from 'react-select';
import Drawer from '@material-ui/core/Drawer';
import CollectionReceiptDrawer from './CollectionReceiptDrawer';
import CollectionReceiptCreatePaymentModal from './modals/CollectionReceiptCreatePaymentModal';

function CollectionReceiptList({
    collection_receipt: {
        collection_receipts,
        pagination,
        cr_search,
        loading,
        edit_url,
        edit_page,
        create_url,
        sort_order_name,
        sort_order_direction,
        set_row_checked,
        filters,
        isSearchable,
        status_value,
        payment_terms_options,
        payment_terms_value,
        create_payment_modal,
        status_options,
        drawer_toggle,
    },
    getCollectionReceipt,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    hoverIndex,
    handleHover,
    updateMUISetting,
    setMUIPage,
    muiPage,
    getCRPaymentTerms,
    CRSetModal,
    CRHandleSelect,
    CRDrawerToggle,
    CRDateFilterInput,
    CRHandleInputChange,
    CRTopSearchButton,
    CRClearFilterInputs,
    getSingleCollectionReceipt,
}) {
    const { hasPermission } = useAccess();
    const canCreate = true;
    const canDelete = true;
    const canEdit = true;
    const canview = true;

    const history = useHistory();
    useEffect(() => {
        getCollectionReceipt(muiPage);
        getCRPaymentTerms();
    }, []);

    // START DEVOUNCED SEARCH
    // const debouncedSearch = useDebounce(cr_search, 800);
    // const [oldDebounced, setOldDebounced] = useState('');
    // useEffect(() => {
    //     if (debouncedSearch) {
    //         if (oldDebounced !== debouncedSearch[0]) {
    //             if (debouncedSearch[0].length !== 1) {
    //                 setOldDebounced(debouncedSearch[0]);
    //                 getCollectionReceipt(1);
    //             }
    //         }
    //     }
    // }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    if (edit_page) return <Redirect to={edit_url} />;

    const changePage = (link) => {
        history.push(link);
    };

    const handleSelectFunc = (selectValue) => {
        CRHandleSelect(selectValue);
    };

    const DrawerCss = withStyles({
        // @global is handled by jss-plugin-global.
        '@global': {
            // You should target [class*="MuiButton-root"] instead if you nest themes.
            '.MuiDrawer-paperAnchorRight': {
                right: 0,
                width: '800px',
                height: '100%',
                bottom: 0,
                // marginTop: '12em',
                // marginBottom: '10em',
            },
        },
    })(() => null);

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(6)': {
                            height: '61px',
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        '&:nth-child(3)': {
                            // width: '250px',
                        },
                        '&:nth-child(6)': {
                            // width: '150px',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'collection_receipt_no',
            label: 'Collection No.',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{value}</>;
                },
            },
        },
        {
            name: 'sales_order_no',
            label: 'Sales Order',
            options: {
                display: true,
                customBodyRender: (value, tableMeta) => {
                    let data = collection_receipts[tableMeta['rowIndex']];
                    return (
                        // <div className="money-cell">₱ {value && numberFormat(value)}</div>
                        <Link
                            to={`/sales/sales-orders/edit/${data.sales_order_id}`}
                            className="txt-underline"
                        >
                            {value}
                        </Link>
                    );
                },
            },
        },
        {
            name: 'due_date_status',
            label: 'Due',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value) {
                        return (
                            <span className={'lbl-status lbl-' + value}>
                                {value}
                            </span>
                        );
                    } else {
                        return <span>N/A</span>;
                    }
                },
            },
        },
        {
            name: 'due_date',
            label: 'Due Date',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value
                                ? moment(value).format('MMM DD YYYY')
                                : 'N/A'}
                        </>
                    );
                },
            },
        },
        {
            name: 'customer',
            label: 'Customer',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Link
                                className="txt-underline"
                                to={`/sales/customers/history/${value._id}`}
                            >
                                {value.name}
                            </Link>
                        </>
                    );
                },
            },
        },
        {
            name: 'payment_terms',
            label: 'Terms',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <>{value['label']}</>;
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span className={'lbl-status lbl-' + value}>
                            {value}
                        </span>
                    );
                },
            },
        },
        {
            name: 'balance',
            label: 'Balance',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span> ₱ {numberFormat(value)}</span>;
                },
            },
        },
        {
            name: 'total',
            label: 'Amount',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div className="">₱ {numberFormat(value)}</div>;
                },
            },
        },
        {
            name: '',
            label: 'Actions',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let CRId = collection_receipts[tableMeta['rowIndex']]._id;
                    return (
                        <div className="btn-group">
                            <button
                                onClick={() => getCRView(CRId, 'drawer')}
                                className="btn btn-sm btn-primary"
                            >
                                <i className="ft ft-list"></i>
                            </button>
                            <button
                                onClick={() => getCRView(CRId, 'modal')}
                                className="btn btn-sm btn-blue"
                            >
                                <i className="ft ft-plus"></i>
                            </button>
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        // searchOpen: true,
        elevation: 2,
        filter: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `brands_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getCollectionReceipt(null, numberOfRows);
            updateMUISetting('rows', 'collection_receipts', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getCollectionReceipt(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'collection_receipts',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }

            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // Go to edit page when row is clicked
            if (canEdit) {
                // changePage(`${edit_url}/${rowData[0]}`);
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        value={cr_search}
                        onChange={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'collection_receipts',
        null,
        columns
    );

    // on Click of reference no
    const getCRView = (id, type) => {
        getSingleCollectionReceipt(id, 'collection_receipt');
        if (type === 'drawer') {
            CRDrawerToggle(true);
        }

        if (type === 'modal') {
            CRSetModal('create_payment_modal', true);
        }
    };

    // ** Drawer Content
    const CRHistorySideDrawer = () => (
        <div role="presentation" onKeyDown={() => CRDrawerToggle(false)}>
            <div className="toggle-list-header cr-side-link">
                <i
                    onClick={() => CRDrawerToggle(false)}
                    className="ft-arrow-right toggle-list-icon"
                ></i>
                <span>Collection Receipt History</span>
            </div>
            <CollectionReceiptDrawer />
        </div>
    );

    return (
        <>
            {' '}
            <DrawerCss />
            <Drawer
                anchor="right"
                open={drawer_toggle}
                onClose={() => CRDrawerToggle(false)}
            >
                {CRHistorySideDrawer()}
            </Drawer>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <div className="row mt-1">
                            {/* <div className="d-flex mt-1 f-regular"> */}
                            <div className="col-12 col-lg-3">
                                <div className="form-group no-gutters">
                                    <div className="d-flex align-content-between">
                                        <label className="pr-2">
                                            Filters:{' '}
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                CRDateFilterInput(e)
                                            }
                                            type="date"
                                            name="due_date_string"
                                            value={filters.due_date_string}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2">
                                <div className="form-group no-gutters">
                                    <Select
                                        options={status_options}
                                        className="cr-index-1"
                                        onChange={handleSelectFunc}
                                        value={status_value}
                                        isSearchable={isSearchable}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-2">
                                <div className="form-group no-gutters">
                                    <Select
                                        options={payment_terms_options}
                                        className="cr-index-1"
                                        onChange={handleSelectFunc}
                                        value={payment_terms_value}
                                        isSearchable={isSearchable}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="form-group no-gutters">
                                    <div className="input-group">
                                        <input
                                            onChange={(e) =>
                                                CRHandleInputChange(e)
                                            }
                                            value={cr_search}
                                            name="cr_search"
                                            type="text"
                                            className="form-control"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                onClick={() =>
                                                    CRTopSearchButton()
                                                }
                                                className="btn btn-sm btn-primary"
                                                type="button"
                                            >
                                                <i className="f-regular ft-search"></i>
                                            </button>
                                            <button
                                                onClick={() =>
                                                    CRClearFilterInputs()
                                                }
                                                className="btn btn-sm btn-danger"
                                                type="button"
                                            >
                                                <i className="f-regular ft-refresh-cw"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body cr-index-1">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={collection_receipts}
                                    columns={_columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>
                <CollectionReceiptCreatePaymentModal
                    show={create_payment_modal}
                    onHide={() => CRSetModal('create_payment_modal', false)}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    collection_receipt: state.collection_receipt,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getCollectionReceipt,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
    getCRPaymentTerms,
    CRSetModal,
    CRHandleSelect,
    CRDrawerToggle,
    CRDateFilterInput,
    CRHandleInputChange,
    CRTopSearchButton,
    CRClearFilterInputs,
    getSingleCollectionReceipt,
})(CollectionReceiptList);
