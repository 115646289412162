import React, { Fragment, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { MiniSpinner } from "../../layouts/Spinner";
import { numberFormat } from "../../../Utils/Common";
import {
  Print,
  goPage,
  sendEmail,
  reSendEmail,
  getPdf,
} from "../../../redux/actions/delivery/delivery.actions";
import { Link } from "react-router-dom";
import DeliveryPrintContent from "../custom_template/BlitzDefault.content";
import { useReactToPrint } from "react-to-print";
import { globalDropDown } from "../../../redux/actions/helper/helper.actions";
import DropDownAttachFileGlobal from "../../layouts/DropDownAttachFile.global.js";
import DeliveryCustomTemplate from "../custom_template/DeliveryCustomTemplate";

function DeliveryPrintModal({
  delivery: { modal_loading, si_data, sending_email, download_pdf },
  show,
  onHide,
  Print,
  edit_url,
  auth,
  goPage,
  globalDropDown,
  new_uploads,
  files_to_remove,
  sendEmail,
  reSendEmail,
  getPdf,
}) {
  const componentRef = useRef();
  const status_lbl = (
    <span className={`lbl-status mr-1 lbl-${si_data.status}`}>
      {si_data.status}
    </span>
  );

  // previous button
  const previousBtn = (
    <>
      {" "}
      {si_data.latest_dr_no != si_data.delivery_receipt_no && (
        <div className='mdl-arrow-left arr-link'>
          <Link onClick={() => goPage("prev")}>
            <i className='la la-angle-left mdl-arrow'></i>
          </Link>
        </div>
      )}{" "}
    </>
  );

  // next button
  const nextBtn = (
    <>
      {" "}
      {si_data.oldest_dr_no != si_data.delivery_receipt_no && (
        <div className='mdl-arrow-right arr-link'>
          <Link onClick={() => goPage("next")}>
            <i className='la la-angle-right mdl-arrow'></i>
          </Link>
        </div>
      )}
    </>
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let file_counter =
    (si_data.uploads ? si_data.uploads.length : 0) +
    new_uploads.length -
    files_to_remove.length;

  // console.log(si_data.template_setting && si_data.template_setting.template_label)

  // console.log("dr", si_data);
  return (
    <Modal
      centered
      dialogClassName='modal-wdth'
      show={show}
      onHide={onHide}
      size='lg'
      animation={true}
    >
      <Modal.Header className='inv-bg-header'>
        <div className='col-4 pl-0'>
          <div className='modal-status'>{status_lbl}</div>
        </div>
        <div className='col-8 pr-0'>
          <div className='float-right'>
            <Link
              to={`/deliveries/edit/${si_data.dr_id}`}
              className='btn btn-circle btn-inv btn-grey mr-1 icon-edit-c'
            >
              <i className='ft ft-edit cursor-pointer'></i>
            </Link>
            <button
              onClick={(e) => globalDropDown(e, "show")}
              className='btn btn-circle btn-inv btn-grey mr-1 icon-paper-c relative'
            >
              <i className='ft ft-paperclip cursor-pointer'></i>
              {file_counter > 0 ? (
                <span className='badge badge-pill badge-sm badge-danger badge-up badge-file'>
                  {file_counter > 9 ? "9+" : file_counter}
                </span>
              ) : (
                <></>
              )}
            </button>
            <DropDownAttachFileGlobal
              origin='delivery'
              origin_data={si_data}
              new_uploads={new_uploads}
              files_to_remove={files_to_remove}
            />
            {si_data.email_sent ? (
              <button
                className='btn btn-circle btn-inv btn-grey mr-1'
                onClick={(e) => reSendEmail(e, si_data.dr_id)}
              >
                <i
                  className={
                    sending_email
                      ? "icon-loading-custom icon-20 cursor-pointer"
                      : "ft-custom-mail-check cursor-pointer"
                  }
                ></i>
              </button>
            ) : (
              <button
                className='btn btn-circle btn-inv btn-grey mr-1'
                onClick={(e) => sendEmail(e, si_data.dr_id)}
              >
                <i
                  className={
                    sending_email
                      ? "icon-loading-custom icon-20 cursor-pointer"
                      : "ft-custom-mail-x cursor-pointer"
                  }
                ></i>
              </button>
            )}
            <button
              className='btn btn-circle btn-inv btn-grey mr-1 icon-pdf-c'
              onClick={(e) => getPdf(e, si_data.dr_id, si_data.delivery_receipt_no)}
            >
              <i
                className={
                  download_pdf
                    ? "icon-loading-custom icon-20 cursor-pointer"
                    : "la la-file-pdf-o cursor-pointer"
                }
              ></i>
            </button>
            <button
              onClick={handlePrint}
              className='btn btn-circle btn-inv btn-grey mr-1'
            >
              <i className='ft ft-printer cursor-pointer'></i>
            </button>
            <button
              onClick={onHide}
              className='btn btn-circle btn-inv m-close-btn cursor-pointer'
            >
              <i className='ft ft-x cursor-pointer'></i>
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className='unset-scroll p-0 m-0'>
        <div className='sd-border'>
          {previousBtn}
          {modal_loading ? (
            <MiniSpinner />
          ) : (
            <DeliveryCustomTemplate componentRef={componentRef} />
          )}
          {nextBtn}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  delivery: state.delivery,
  auth: state.auth,
  new_uploads: state.delivery.new_uploads,
  files_to_remove: state.delivery.files_to_remove,
});

export default connect(mapStateToProps, {
  Print,
  goPage,
  globalDropDown,
  sendEmail,
  reSendEmail,
  getPdf,
})(DeliveryPrintModal);

// <Fragment>
//     <DeliveryPrintContent si_data={si_data} auth={auth} print="0" print_rows="5" /><div style={{'display': 'none'}}>
//     <DeliveryPrintContent si_data={si_data} auth={auth} ref={componentRef} print="1" print_rows="19" /></div>
// </Fragment>
