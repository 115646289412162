import React, { Fragment, useEffect, useMemo } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect } from 'react-redux';
import { getPurchaseOrders, handleSearchInput, sendEmail, handleChangeStatusView } from '../../redux/actions/purchase_order/purchase_order.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PurchaseOrderTable from './PurchaseOrder.table';
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';
import '../../assets/purchase.css';

function PurchaseOrder ({ purchase_order: { purchase_orders, loading, search, pagination, print_modal, edit_url, edit_page, create_url, match}, 
    getPurchaseOrders, handleSearchInput, handleChangeStatusView }) {
    const { hasPermission } = useAccess();

    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Purchases</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item"><Link to="/purchases/purchase-orders/" className="text-white">Purchase Orders</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content-body margin-top-5'>
                <section id='basic-examples'>
                <div className='row'>
                    <div className='col-sm-12'>
                    <div id='what-is' className=''>
                        <div className='card-content collapse show'>
                        <div className='card-body no-padding-top no-padding-left-right'>
                            <section className='row'>
                            <div className='col-sm-12'>
                                <ul className='nav nav-tabs f-regular'>
                                {/* old params 'all', '1', '2'. 're-stock' */}
                                <li className=''>
                                    <a
                                    onClick={() => handleChangeStatusView("all")}
                                    className='nav-link active'
                                    id='base-tab1'
                                    data-toggle='tab'
                                    aria-controls='tab1'
                                    href='#tabs'
                                    aria-expanded='true'
                                    >
                                    Purchase Order list
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() => handleChangeStatusView("draft")}
                                    className='nav-link'
                                    id='base-tab2'
                                    data-toggle='tab'
                                    aria-controls='tab2'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Draft
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() =>
                                        handleChangeStatusView("saved")
                                    }
                                    className='nav-link'
                                    id='base-tab3'
                                    data-toggle='tab'
                                    aria-controls='tab3'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Saved
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() =>
                                        handleChangeStatusView("cancelled")
                                    }
                                    className='nav-link'
                                    id='base-tab3'
                                    data-toggle='tab'
                                    aria-controls='tab3'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Cancelled
                                    </a>
                                </li>
                                </ul>
                                <div className='tab-content px-1 pt-1 card-box-top card'>
                                <div
                                    role='tabpanel'
                                    className='tab-pane active table-responsive '
                                    id='tabs'
                                    aria-expanded='true'
                                    aria-labelledby='base-tab1'
                                >
                                    <Show when="inv.purchases.purchase-orders.view">
                                    <div className="content-body margin-top-5">
                                        <section id="basic-examples">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">
                                                                List of Purchase Orders 
                                                                <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                                                        
                                                                <Show when="inv.purchases.purchase-orders.create"><Link to={create_url} className="btn btn-primary btn-sm pull-right btn-fix-header"><i className="ft ft-plus"></i> Create New</Link></Show>
                                                            </h4>
                                                        </div>
                                                        <div className="card-content collapse show">
                                                            <div className="card-body">
                                                                <section className="row">
                                                                    <div className="col-sm-12">
                                                                        <PurchaseOrderTable/>
                                                                    </div>
                                                                </section>   
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </Show>
                                </div>
                                </div>
                            </div>
                            </section>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    purchase_order: state.purchase_order
})

export default connect(mapStateToProps, { getPurchaseOrders, handleSearchInput, handleChangeStatusView })(PurchaseOrder);