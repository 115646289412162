import React, { useEffect, Fragment, useCallback } from "react";
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import {load_data , formSubmit, uploadImage, profile_modal, removeImage, submit_image} from '../../../redux/actions/profile/profile.actions';


function ProfileUpload ({ profile : {user, required, submit_str, disable_btn}, show, onHide, uploadImage, profile_modal, removeImage, submit_image } ) {
    const onDrop = useCallback(acceptedFiles => {
        uploadImage(acceptedFiles)
        // console.log(acceptedFiles)
     }, [])
     
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    return (
        <Modal centered dialogClassName="modal-container"  show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton dialogClassName="p-0">
                <Modal.Title>Upload new Profile picture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 text-center">
                        {
                              user.upload != "" ?
                            <Fragment>
                                <div className="text-center item-upload-img p-2 m-auto">
                                        <img src={ user.upload_preview ? user.upload_preview : user.image} className="img-fluid mx-wdth-100"/><br/>
                                        <button onClick={removeImage} className="btn btn-danger mt-2">Remove Image</button>
                                </div>
                            </Fragment>
                            :
                            <div {...getRootProps()} className="dropzone-line-style m-auto">
                                <input {...getInputProps()}/>
                                <center>
                                    <i className="ft ft-upload-cloud upload-icon"></i>
                                    <div className="upload-message my-1">Drag & Drop <br/>Files Here</div>
                                    <div className="upload-message-or mb-2">or</div>
                                    <div className="btn btn-info btn">Browse Files</div>
                                </center>
                            </div>
                        }
                    </div>
                </div>
                <div className="row mt-1m">
                    <div className="col-md-12">
                        {/* <button className="btn btn-secondary float-right" onClick={profile_modal}>Cancel</button> */}
                        {
                            disable_btn || user.upload === ""?
                            <button className="btn btn-primary float-right" onClick={submit_image} >{submit_str}</button>
                            :
                            <span></span>
                        }
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, { load_data , profile_modal, uploadImage, removeImage, submit_image})(ProfileUpload);
