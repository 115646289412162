
import React, { useEffect, Fragment, useCallback } from "react";
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDropzone } from 'react-dropzone';
import {load_data, dynamicInputChange, handleMobile, formSubmit, removeImage, uploadImage} from '../../redux/actions/profile/profile.actions';

function Profile_Change_Pass ({profile : {user, required, submit_str},
    load_data, dynamicInputChange, handleMobile, formSubmit, removeImage, uploadImage}) {
    
    useEffect(() => {
        load_data();
    },[])
    const onDrop = useCallback(acceptedFiles => {
        uploadImage(acceptedFiles)
        // console.log(acceptedFiles)
     }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    // console.log("required",required);
    return (

    <div className="app-content content">
        <ReactTooltip effect="solid" event="click" />
        <div className="content-wrapper">
            <div className="content-wrapper-before"></div>
            <div className="content-header row">
                <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                    <h3 className="content-header-title mb-0 d-inline-block">Profile</h3>
                    <div className="breadcrumbs-top d-inline-block">
                        <div className="breadcrumb-wrapper mr-1">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item "><a href="#" className="text-white">Edit Profile</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-body margin-top-5">
                <section id="basic-examples f-regular">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header mb-0 pb-0">
                                    <a href="/account/profile"><i className="ft ft-arrow-left f-regular"></i> Back</a>
                                </div>
                                <form className="card-content collapse show" onSubmit={(e) => formSubmit(e)} encType="multipart/form-data">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                            {
                                                user.image != '' ?
                                                <Fragment>
                                                    <div className="text-center item-upload-img p-2">
                                                            <img src={user.image} className="img-fluid mx-wdth-100"/><br/>
                                                            <button onClick={removeImage} className="btn btn-danger mt-2">Remove Image</button>
                                                    </div>
                                                </Fragment>
                                                :
                                                <div {...getRootProps()} className="dropzone-line-style">
                                                    <input {...getInputProps()}/>
                                                    <center>
                                                        <i className="ft ft-upload-cloud upload-icon"></i>
                                                        <div className="upload-message my-1">Drag & Drop <br/>Files Here</div>
                                                        <div className="upload-message-or mb-2">or</div>
                                                        <div className="btn btn-info btn">Browse Files</div>
                                                    </center>
                                                </div>
                                            }
                                            
                                            
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                

                                                <label className="label-control f-regular">Fullname</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <input type="text" name="name" className={required.name.property + " form-control" } required="" onChange={(e) => dynamicInputChange(e)} value={user.name} />
                                                        <span className="text-danger">{required.name.message}</span>
                                                    </div>
                                                </div>

                                                <label className="label-control f-regular">Birthday</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        {/* <DatePicker onChange={date=> setdatePick(date)} selected={user.brithday} className="form-control f-regular" autoComplete="off"/> */}
                                                        <input type="date" name="birthday" className={required.birthday.property + " form-control" } required="" onChange={(e) => dynamicInputChange(e)} value={user.birthday} />
                                                        <span className="text-danger">{required.birthday.message}</span>
                                                    </div>
                                                </div>

                                                <label className="label-control f-regular">Email</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <input type="email" name="email" className={required.email.property + " form-control" } required="" onChange={(e) => dynamicInputChange(e)} value={user.email} />
                                                        <span className="text-danger">{required.email.message}</span>
                                                    </div>
                                                </div>
                                                <label className="label-control f-regular">Username</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <input type="text" name="username" className={required.username.property + " form-control" } required="" onChange={(e) => dynamicInputChange(e)} value={user.username} />
                                                        <span className="text-danger">{required.username.message}</span>
                                                    </div>
                                                </div>
                                                <label className="label-control f-regular">Contact Number</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <PhoneInput countryCodeEditable={false} enableSearch="true" value={user.contact} country="ph" inputProps={{ name: 'contact', required: false, autoFormat:true, enableSearch: true, class: 'form-control-regular w-100' }} placeholder="" masks={{ph: '... ... ....'}} onChange={mobile => handleMobile(mobile)}/>
                                                        {/* <input type="text" name="contact" className="form-control" required="" onChange={(e) => dynamicInputChange(e)} value={user.contact} /> */}
                                                        <span className="text-danger">{required.contact.message}</span>
                                                    </div>
                                                </div>

                                                
                                            
                                            </div>
                                            <div className="col-md-6">
                                                
                                                <label className="label-control f-regular">&nbsp;</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <i>Note: If you do not wish to edit your password you can leave this blank.</i>
                                                    </div>
                                                </div>
                                                <label className="label-control f-regular">Current Password</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <input type="password" name="current_pass" className={required.current_pass.property + " form-control" }  onChange={(e) => dynamicInputChange(e)} autoComplete="old password" value={user.current_pass} />
                                                        <span className="text-danger">{required.current_pass.message}</span>
                                                    </div>
                                                </div>
                                                <label className="label-control f-regular">New Password</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <input type="password" name="new_pass" className={required.new_pass.property + " form-control" } onChange={(e) => dynamicInputChange(e)}  autoComplete="off" value={user.new_pass} />
                                                        <span className="text-danger">{required.new_pass.message}</span>
                                                    </div>
                                                </div>

                                                <label className="label-control f-regular">Confirm Password</label>
                                                <div className="form-group row">
                                                    <div className="col-md-11 col-10">
                                                        <input type="password" name="confirm_pass" className={required.confirm_pass.property + " form-control" }  autoComplete="off" onChange={(e) => dynamicInputChange(e)} value={user.confirm_pass} />
                                                        <span className="text-danger">{required.confirm_pass.message}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                        <div className="col-md-12 text-right">
                                            <button type="submit" className="btn btn-primary btn-save f-regular" >{submit_str}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>


); }

 

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, { load_data, dynamicInputChange, handleMobile, formSubmit, removeImage, uploadImage})(Profile_Change_Pass);