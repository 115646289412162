import * as TYPES from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";

export const setLoading = status => async dispatch => dispatch({ type: TYPES.SET_LOADING_INV, payload: status });



export const handleChangeStockView = (stock_view) => async (dispatch, getState) => {
    dispatch({ type: TYPES.GET_STOCK_VIEW, payload: stock_view });
    

    let {inventory_list, search, pagination_list} = getState().inventory;
    let attr_list = inventory_list[stock_view];

    if(typeof(pagination_list[stock_view]) === 'undefined' || pagination_list[stock_view] == null )
    {
        dispatch({ type: TYPES.SET_SEARCH, payload: "" });
        // dispatch(getInventoryList(1));
    }
    else
    {
        dispatch({ type: TYPES.SET_SEARCH, payload: pagination_list[stock_view].search });
        dispatch({ type: TYPES.SET_PAGINATION, payload: {pagination_list: pagination_list, pagination: pagination_list[stock_view]} });
    }
};
