import React, { Fragment, useRef, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MiniSpinner } from '../../layouts/Spinner';
import PurchaseOrderPrintContent from './PurchaseOrderPrintContent';
import { Link } from 'react-router-dom';
import { goPage, sendEmail, reSendEmail, getPdf} from '../../../redux/actions/purchase_order/purchase_order.actions';
import { useReactToPrint } from 'react-to-print';
import { globalDropDown } from '../../../redux/actions/helper/helper.actions';
import DropDownAttachFileGlobal from '../../layouts/DropDownAttachFile.global.js';
import Pdf from "react-to-pdf";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PoCustomTemplate from '../custom_template/PoCustomTemplate';

function PurchaseOrderModalPrint({single_purchase_order, subs_setting, mini_loading, show, onHide, download_pdf, edit_url, goPage, globalDropDown,new_uploads, files_to_remove, 
	sendEmail, sending_email, reSendEmail, getPdf, file_count }) {


	const componentRef = useRef();

	const status_lbl = <span className={`lbl-status mr-1 lbl-${single_purchase_order.status}`}>{single_purchase_order.status}</span>;
  
	const previousBtn = (
		<>{ single_purchase_order.latest_po != single_purchase_order.purchase_order_no && <div className="mdl-arrow-left"> <Link onClick={() => goPage('prev')}><i className="la la-angle-left mdl-arrow"></i></Link> </div> }</>
	)

	const nextBtn = (
		<>{ single_purchase_order.oldest_po != single_purchase_order.purchase_order_no && <div className="mdl-arrow-right"> <Link onClick={() => goPage('next')}><i className="la la-angle-right mdl-arrow"></i></Link> </div> }</>
	)
	
	const handlePrint = useReactToPrint({
	    content: () => componentRef.current,
	});

	const refpdf = React.createRef();
	let file_counter = ((single_purchase_order.uploads ? single_purchase_order.uploads.length : 0) + (new_uploads.length)) - (files_to_remove.length);
	return (
		<Modal centered dialogClassName="modal-wdth" show={show} onHide={onHide} size="lg" animation={true} autoFocus={false}>
		    <Modal.Header className="inv-bg-header">
					<div className="col-4 pl-0">
						<div className="modal-status">
							{status_lbl}
						</div>
					</div>
					<div className="col-8 pr-0">
						<div className="float-right">
							<Link to={`${edit_url}/${single_purchase_order._id}`} className="btn btn-circle btn-inv btn-grey mr-1 icon-edit-c"><i className="ft ft-edit cursor-pointer"></i></Link>
							<button onClick={e => globalDropDown(e, 'show')} className="btn btn-circle btn-inv btn-grey mr-1 icon-paper-c relative"><i className="ft ft-paperclip"></i>{file_counter > 0 ? <span className="badge badge-pill badge-sm badge-danger badge-up badge-file">{file_counter > 9 ? '9+' : file_counter}</span> : <></>}</button>
							<DropDownAttachFileGlobal origin="purchase_order" origin_data={single_purchase_order} new_uploads={new_uploads} files_to_remove={files_to_remove}/>
							{
								single_purchase_order.email_sent ? 
								<button className="btn btn-circle btn-inv btn-grey mr-1" onClick={(e) => reSendEmail(e, single_purchase_order._id)}><i className={sending_email ? "icon-loading-custom icon-20 cursor-pointer" : "ft-custom-mail-check cursor-pointer"}></i></button>
								:
								<button className="btn btn-circle btn-inv btn-grey mr-1" onClick={(e) => sendEmail(e, single_purchase_order._id)}><i className={sending_email ? "icon-loading-custom icon-20 cursor-pointer" : "ft-custom-mail-x cursor-pointer"}></i></button>
							}
							<button className="btn btn-circle btn-inv btn-grey mr-1 icon-pdf-c" onClick={(e) => getPdf(e, single_purchase_order._id, single_purchase_order.purchase_order_no)}><i className={download_pdf ?  "icon-loading-custom icon-20 cursor-pointer" :"la la-file-pdf-o cursor-pointer"}></i></button>
							<button onClick={handlePrint} className="btn btn-circle btn-inv btn-grey mr-1"><i className="ft ft-printer cursor-pointer"></i></button>
							<button onClick={onHide} className="btn btn-circle btn-inv m-close-btn cursor-pointer"><i className="ft ft-x cursor-pointer"></i></button>
						</div>
					</div>
	        </Modal.Header>
		    <Modal.Body className="unset-scroll p-0 m-0">
				<div className="sd-border">
					{previousBtn}
						{ mini_loading ? <div className="my-5"><MiniSpinner/></div> : <PoCustomTemplate componentRef={componentRef}/> }
          			{nextBtn}
				</div>
		    </Modal.Body>
		</Modal>
	  )
}

const mapStateToProps = state => ({
	single_purchase_order: state.purchase_order.single_purchase_order,
	subs_setting: state.purchase_order.subs_setting,
	new_uploads: state.purchase_order.new_uploads,
	files_to_remove: state.purchase_order.files_to_remove,
	mini_loading: state.purchase_order.mini_loading,
	sending_email : state.purchase_order.sending_email,
	download_pdf : state.purchase_order.download_pdf,
	file_count : state.purchase_order.file_count
})

export default connect(mapStateToProps, { goPage, globalDropDown, sendEmail, reSendEmail, getPdf })(PurchaseOrderModalPrint)
