import { SET_LOADING, SET_MODAL, CLEAR_USER_LOG, SEARCH_INPUT, GET_SINGLE_USER_LOG, GET_USER_LOGS, SET_MINI_LOADING, SORT_BY_COLUMN } from '../../types';
import { ToastDanger } from '../../../Services/_toast.service';
import { UserLogService } from './_service.userlog';
import { getNumRows } from '../../../Utils/Common';

// handle edit inputs
export const handleSearchInput = e => async dispatch => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value })
}

// loading
export const setLoading = status => async dispatch => dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = status => async dispatch => dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearUserLog = () => async dispatch => dispatch({ type: CLEAR_USER_LOG  });

// set modal
export const setModal = (modal, id, status = true) => async dispatch => {

    if(status)
    {
        if(modal == 'create_modal')
        {
            // create_modal
            dispatch(clearUserLog());
            dispatch({ type: SET_MODAL, payload: { modal, status } });
        }
        else
        {
            // view_modal, edit_modal, delete_modal, 
            dispatch(getSingleUserLog(id));
            dispatch({ type: SET_MODAL, payload: { modal, status } });
        }

    }
    else
    {
        // clear all the states and modals
        dispatch(clearUserLog()); 
    }
}

// get log users 
export const getUserLogs = (pageNumber, rows_per_page = null) => async (dispatch, getState) => {
    dispatch(setLoading(true)); 

    let { search, pagination, sort_order_name, sort_order_direction } = getState().user_log; 
    let rows_per_page_val = rows_per_page != null ? rows_per_page : getNumRows('user_log');
    
    // let searchPostParams = {
    //     search: search,
    //     activePage: pagination.activePage,
    //     page: pageNumber,
    //     rows_per_page: rows_per_page_val, 
    //     sort_order_name: sort_order_name,
    //     sort_order_direction: sort_order_direction
    // }

       //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
       let searchPostParams = {
        search,
        options: {
          page: pageNumber,
          limit: rows_per_page_val,
          sort_by: sort_order_name,
          sort_order: sort_order_direction,
        },
      };

    UserLogService.paginateWithSearchUserLogs(searchPostParams).then(res => {

        // let pagination = {
        //     totalCount: res.data.user_logs.total, 
        //     activePage: res.data.user_logs.current_page, 
        //     itemsCountPerPage: res.data.user_logs.per_page, 
        //     totalItemsCount: res.data.user_logs.total
        // }

          //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
          let pagination = {
            totalCount: res.data.total_docs,
            activePage: res.data.page,
            itemsCountPerPage: res.data.limit,
            totalItemsCount: res.data.total_docs,
          };

        dispatch({ type: GET_USER_LOGS, payload: { user_logs: res.data.docs, pagination: pagination }})
        dispatch(setLoading(false));
    })
    .catch(error => {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
};


// get single
export const getSingleUserLog = id => async dispatch => {
    dispatch(setMiniLoading(true));

    UserLogService.getSingleUserLog(id).then(res => {

        dispatch({ type: GET_SINGLE_USER_LOG, payload: res.data.user_log });
        dispatch(setMiniLoading(false));
    })
    .catch(error => {
        dispatch(setMiniLoading(false));
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
}


export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value })
}

// Sort by column name and direction
export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getUserLogs());
}
