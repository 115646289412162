import React, { useEffect, Fragment } from "react";
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function ProfileInvoice () {
    
return (

<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                <h3 className="content-header-title mb-0 d-inline-block">Profile</h3>
                <div className="breadcrumbs-top d-inline-block">
                    <div className="breadcrumb-wrapper mr-1">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><a href="#" className="text-white">Sales Invoice</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div className="content-body margin-top-5">
            <section id="basic-examples">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header mb-0 pb-0">
                                <a href="/account/profile"><i className="ft ft-arrow-left f-regular"></i> Back</a>
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <h5 className="mt-2">List of Sales Invoice</h5>
                                    <table className="table table-striped table-hover table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>S.I. No.</th>
                                                <th>Customer</th>
                                                <th>Created by</th>
                                                <th>Payment</th>
                                                <th>Delivery</th>
                                                <th>Status</th>
                                                <th width="5%">Quotation</th>
                                                <th>Total</th>
                                                <th width="5%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="row-style-hover" width="5%">1021</td>
                                                <td className="row-style-hover">PC HUB</td>

                                                <td className="row-style-hover">John Doe</td>
                                                <td className="row-style-hover text-center"><span className="lbl-status lbl-unpaid">Unpaid</span></td>
                                                <td className="row-style-hover text-center"><span className="lbl-status lbl-pending">Pending</span></td>
                                                <td className="row-style-hover text-center">
                                                    <span className="lbl-status lbl-save">save</span>
                                                </td>
                                                <td className="row-style-hover">
                                                    <center><span>8016</span></center>
                                                </td>
                                                <td className="row-style-hover text-right">500</td>

                                                <td>
                                                    <div className="btn-group">
                                                        <a href="#" className="btn btn-sm btn-info btn-sm"><i className="ft ft-edit"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="row-style-hover" width="5%">1021</td>
                                                <td className="row-style-hover">PC HUB</td>

                                                <td className="row-style-hover">John Doe</td>
                                                <td className="row-style-hover text-center"><span className="lbl-status lbl-unpaid">Unpaid</span></td>
                                                <td className="row-style-hover text-center"><span className="lbl-status lbl-pending">Pending</span></td>
                                                <td className="row-style-hover text-center">
                                                    <span className="lbl-status lbl-save">save</span>
                                                </td>
                                                <td className="row-style-hover">
                                                    <center><span>8016</span></center>
                                                </td>
                                                <td className="row-style-hover text-right">500</td>

                                                <td>
                                                    <div className="btn-group">
                                                        <a href="#" className="btn btn-sm btn-info btn-sm"><i className="ft ft-edit"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

); }


 
const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(ProfileInvoice);