import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { handleAddVolumePricing, handleRemoveVolumePricing, handleInputChangeVolumePricing, handleSelectDiscountTypeVolumePricing } from '../../redux/actions/product/product.actions';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { onlyNumber } from '../../Utils/Common';


function ProductVolume({ product: { single_product: { volume_price }, discount_type_options }, handleAddVolumePricing, handleRemoveVolumePricing, handleInputChangeVolumePricing, handleSelectDiscountTypeVolumePricing }) {

    return (
        <Fragment>
            <ReactTooltip  effect="solid" event="click"/>
            <h4 className="form-section"><i className="ft-clipboard"></i> Volume Pricing  </h4>
            <div className="row mt-3">
                <div className="col-md-6">
                    <table className="f-regular">
                        <thead>
                            <tr>
                                <td className="min-wdth-100 pl-1 text-dark">Quantity</td>
                                <td className="min-wdth-200 pl-1 text-dark">Discount Type</td>
                                <td className="min-wdth-100 pl-1 text-dark">Discount</td>
                                <td> <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                volume_price.map((x, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className="p-1">
                                                <input onKeyPress={e => onlyNumber(e)} type="number" name="qty" className="form-control text-right" value={x.qty} onChange={e => handleInputChangeVolumePricing(e, key)} min={0} required/>
                                            </td>
                                            <td className="p-1">
                                                <Select isSearchable={true} name="discount_type" onChange={(e) => handleSelectDiscountTypeVolumePricing(e, key)} defaultValue={x.discount_type} options={discount_type_options}/>
                                            </td>
                                            <td className="p-1">
                                                <input onKeyPress={e => onlyNumber(e)} type="number" className="form-control text-right" name="discount" value={x.discount} onChange={e => handleInputChangeVolumePricing(e, key)} min={0} step="any" required/>
                                            </td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-mini btn-sm btn-danger" onClick={() => handleRemoveVolumePricing(key)}><i className="ft ft-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4" className="text-right">
                                    <button type="button" className="btn btn-primary btn-sm pull-right" onClick={handleAddVolumePricing}><i className="ft ft-plus"></i></button> 
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    product: state.product
})

export default connect(mapStateToProps, { handleAddVolumePricing, handleRemoveVolumePricing, handleInputChangeVolumePricing, handleSelectDiscountTypeVolumePricing })(ProductVolume);

