import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';

export const SalesOrderService = {
    getSingleSalesOrder: (id) => http.get('/admin/sales-order/view/' + id),
    ArchiveOrder: (formParams) =>
        http.post('/admin/sales-order/archives/list', formParams),
    SalesOrderCreate: (formParams) =>
        http.post('/admin/sales-order', formParams),
    paginateWithSearchOrders: (formParams, status) =>
        http.post('/admin/sales-order/search/'+ status, formParams),
    updateSingleSalesOrder: (formParams, id) =>
        http.put('/admin/sales-order/' + id, formParams),
    getSingleCustomer: (id) => http.get('/admin/customer/' + id),
    getCustomers: (formdata) => http.post('/admin/sales-order/so-customer', formdata), // get all customer (new)
    getProducts: (formdata, cancelToken) =>
        http.post('/admin/sales-order/so-product', formdata, { cancelToken }), // get all the products (new)
    getQuotations: (formdata) =>
        http.get('/admin/sales-order/so-quotation', formdata), // get all the quotations (new)
    getSingleQuotation: (id) =>
        http.get('/admin/sales-order/so-quotation/?id=' + id), // get all the quotations (new)
    printPreview: (formParams) =>
        http.post('setting/order-setting/preview', formParams),
    getTermsConditions: () => http.get('admin/term-condition'),
    CheckInStock: (formParams) =>
        http.post('/admin/sales-order/check-stock', formParams),
    goPage: (formParams) =>
        http.post('/admin/sales-order/go-page', formParams),
    sendEmail: (id) => http.get('/admin/sales-order/email/' + id),
    getPdf: (id) => httpWithFile.get('/admin/sales-order/pdf/' + id),
    getEmailConfig: () => http.get('/settings/email/security'),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    getInvTemplates: () => http.get('/admin/sales-order-template'),
    updatePaymentStatus: (formdata) =>
        http.post('/admin/sales-order/update-payment', formdata),
    cancelOrder: (id) => http.get('/admin/sales-order/cancel/' + id),
};

// ** THIS ARE THE OLD API, WE KEEP THIS FOR REFERENCE ONLY
// getInvTemplates: () => http.get('/admin/setting/order-setting/get/order/themes'), // get all the products
// getInvTemplates: () => http.get('/admin/setting/order-setting/get/order/themes'), // get all the products
// getProducts: (formdata) => http.post('/admin/product/search', formdata), // get all the products (old)
// getCustomers: (search) => http.get('/admin/sales-order/customer?search=' + search), // get all customer (old)
// getDisplayCustomer: (id) => http.get('/admin/sales-order/selected/' + id), // get customer data base on id
