import http from "../../../Services/api.laravel.http";

export const BrandService = {
  getSingleBrand: (id) => http.get("/admin/brand/" + id),
  getBrand: (id) => http.get("/admin/brand/"),
  paginateWithSearchBrands: (formParams) =>
    http.post("/admin/brand/search", formParams),
  brandPost: (formParams) => http.post("/admin/brand", formParams),
  updateSingleBrand: (formParams, id) =>
    http.put("/admin/brand/" + id, formParams),
  ArchiveBrand: (formParams) =>
    http.post("/admin/brand/archives/list", formParams),
};
