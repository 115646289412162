import React from 'react';
import axios from "axios";
import $ from "jquery";
import { Redirect } from 'react-router-dom';


class NoAccess extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount()
  {

  }
  
  
  render()
  {
    return (

      <div className="app-content content">
        <div className="content-wrapper">
            <div className="content-wrapper-before"></div>
            <div className="content-header row">
            </div>
            <div className="content-body">
                <section className="flexbox-container">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-10 box-shadow-2 p-0">
                            <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                                <div className="card-header border-0">
                                    <div className="font-large-1  text-center text-danger">
                                        You have no access to this page
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
      </div>
    );

  }
}

export default NoAccess;
