import React, { Fragment } from 'react';
import { MiniSpinner } from '../layouts/Spinner';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import AttributeTemplateCreateGlobal from './AttributeTemplate.create.global';
import { Link } from 'react-router-dom';


function AttributeTemplateCreate ({ attributes, attribute_template : { main_url, single_attribute_template, loading }}) {
	
	if(single_attribute_template.success) return <Redirect to={main_url}/>

	return (
		<div className="app-content content">
			<ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Products</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/products/attribute-template" className="text-white f-regular">Attribute Templates</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
									{
										loading ? <MiniSpinner /> 
										: 
										<Fragment>
											<div className="card-header">
												<ReactTooltip  effect="solid" event="click"/>
												<Link to='/inventory/products?mode=Attributes'><i className="ft ft-arrow-left"></i> Back</Link>
												<h4 className="card-title mt-2">Add Attribute(s) <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4> 
												<div className="heading-elements"> <ul className="list-inline mb-0"></ul></div>
											</div>
											<div className="card-content collapse show">
												<div className="card-body">
													<AttributeTemplateCreateGlobal pageOrigin="attribute_template"/>
												</div>
											</div>
										</Fragment>
									}
                            	</div>	
                            </div>
                        </div>    
                    </section>
                </div>
            </div>
        </div>	
	)
}

const mapStateToProps = state => ({
	attribute_template: state.attribute_template,
	attributes: state.attribute_template.attributes,
});

export default connect(mapStateToProps, { })(AttributeTemplateCreate);
