import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function ReturnsFilter({ onFilterChange }) {
    const [activeFilter, setActiveFilter] = useState('return');

    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
        onFilterChange(filter); // Call the function to update the filter in getReturns
    };

    return (
        <>
            <label>Filter Movement:</label>
            <ButtonGroup
                aria-label="Stock log filter"
                className="stock-log-filter m-1"
            >
                <Button
                    variant={
                        activeFilter === 'return' ? 'primary' : 'secondary'
                    }
                    onClick={() => handleFilterChange('return')}
                    size="sm"
                >
                    Sales Return
                </Button>
                <Button
                    variant={
                        activeFilter === 'pull_out' ? 'primary' : 'secondary'
                    }
                    onClick={() => handleFilterChange('pull_out')}
                    size="sm"
                >
                   Purchase Return
                </Button>
            </ButtonGroup>
        </>
    );
}

export default ReturnsFilter;
