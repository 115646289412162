import React, { Fragment, useEffect } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { NumberFormat } from "../../Services/_numberformat.service";
import {
  handleSelectCustomer,
  toggleEditBtn,
  handleAddItem,
  handleRemoveItem,
  handleInputAttr,
  handleInputChange,
  handleInputSrp,
  handleCheckVat,
  handleInputQty,
  handleSelectItem,
  handleSelectTermsConditions,
  handleOnSubmit,
  handleSelectSalesInvoiceTemplate,
  handleSelectTypeInvoice,
  getInvoiceTemplates,
  handleEditInputRow,
  handleToggleEditRow,
  handleUpdateRow,
  handleAttrSubmit,
  setModal,
  handleSelectPayment,
  getSingleQuotation,
  getProducts,
  getTermsConditions,
  getCustomers,
  handleSelectPaymentType,
  handleSelectDiscountType,
  handleInputChangeDiscount,
  QUDatePicker,
  computationHandler,
  handleTaxType,
  searchProducst,
  add_items,
} from "../../redux/actions/quotation/quotation.actions";
import { connect } from "react-redux";
import { MiniSpinner } from "../layouts/Spinner";
import { Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import QuotationCustomerModal from "./modals/Quotation.customer.modal";
import PaymentTermsModal from "./modals/PaymentTerms.modal";
import { Show, useAccess } from "react-redux-permission";
import { Link } from "react-router-dom";
import DynamicFont from "react-dynamic-font";
import debounce from "debounce-promise";
import { stockClass } from "../../Utils/Common";

function QuotationCreateSalesInvoice({
  quotation: {
    single_quotation,
    mini_loading,
    customers,
    terms_conditions,
    isSearchable,
    itemSelected,
    items,
    isDiscount,
    default_product_value,
    count_items,
    isOther,
    inv_templates,
    type_invoice_options,
    inv_default_template,
    isQuotationNo,
    edit_row_index,
    edit_quotation_items,
    receipt_type_options,
    payment_terms_modal,
    edit_attr,
    type_payment_options,
    main_url,
    main_page,
    default_customer_value,
    payment_mode_options,
    customer_modal,
    tax_types,
    loading,
  },
  handleSelectCustomer,
  toggleEditBtn,
  handleAddItem,
  handleRemoveItem,
  handleInputAttr,
  handleInputChange,
  handleInputSrp,
  handleCheckVat,
  handleInputQty,
  handleSelectSalesInvoiceTemplate,
  handleSelectTypeInvoice,
  handleSelectItem,
  handleSelectPaymentType,
  handleSelectTermsConditions,
  handleOnSubmit,
  getInvoiceTemplates,
  handleEditInputRow,
  handleToggleEditRow,
  handleUpdateRow,
  setModal,
  handleAttrSubmit,
  handleSelectPayment,
  getSingleQuotation,
  getProducts,
  getTermsConditions,
  getCustomers,
  match,
  handleSelectDiscountType,
  handleInputChangeDiscount,
  QUDatePicker,
  computationHandler,
  handleTaxType,
  searchProducst,
  add_items,
}) {
  useEffect(() => {
    async function fetchAll() {
      Promise.all([
        getProducts(),
        getTermsConditions(),
        getCustomers(),
        getInvoiceTemplates(),
        getSingleQuotation(match.params.id),
      ]);
    }

    fetchAll();
  }, []);

  const { quotation_items, discount, discount_type, tax, tax_type, other } =
    single_quotation;
  useEffect(() => {
    computationHandler();
  }, [
    JSON.stringify(quotation_items),
    discount,
    discount_type,
    tax,
    tax_type,
    other,
  ]);

  const customFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.barcode.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (main_page) return <Redirect to={main_url} />;

  // computed subtotal
  // const subTotalComputed = single_quotation.quotation_items.reduce((sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp), 0);
  // const discount = single_quotation.discount_type == 1 ? ((parseFloat(subTotalComputed) * single_quotation.discount) / 100) : single_quotation.discount;
  // const subTotalDiscounted = subTotalComputed - discount;
  // const totalPriceComputed = subTotalDiscounted + ((parseFloat(subTotalDiscounted) * single_quotation.vat) / 100) + single_quotation.other;
  // // formatted price
  // const subTotal = NumberFormat(subTotalComputed.toFixed(2));
  // const total = NumberFormat(totalPriceComputed.toFixed(2));
  const itemSelectedAmount = itemSelected.qty * itemSelected.srp;
  let percent_value = [
    { value: 0, label: "None" },
    { value: 1, label: "Percent" },
    { value: 2, label: "Amount" },
  ];

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <div style={{ fontSize: 11 }}>{props.data.sku}</div>
        <div style={{ fontSize: 11 }}>{props.data.description}</div>
      </components.Option>
    );
  };

  return (
    <div className='app-content content'>
      <div className='content-wrapper max-1920 mx-auto'>
        <div className='content-wrapper-before'></div>
        <div className='content-header row'>
          <div className='content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header'>
            <h3 className='content-header-title mb-0 d-inline-block'>Sales</h3>
            <div className='breadcrumbs-top d-inline-block'>
              <div className='breadcrumb-wrapper mr-1'>
                <ol className='breadcrumb f-regular'>
                  <li className='breadcrumb-item'>
                    <Link to={main_url} className='text-white'>
                      Quotation No.
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>
                    <Link to='#' className='text-white'>
                      {single_quotation.quotation_no}
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className='content-body margin-top-5'>
          <section id='basic-examples'>
            <div className='row f-regular'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header mb-0 pb-0'>
                    <div>
                      <Link to={main_url}>
                        <i className='ft ft-arrow-left'></i> Back
                      </Link>
                    </div>
                  </div>
                  <div className='card-content collapse show'>
                    <div className='card-body'>
                      {mini_loading ? (
                        <MiniSpinner />
                      ) : (
                        <form>
                          <ReactTooltip effect='solid' event='click' />
                          <div className='row f-regular'>
                            <div className='col-md-12'>
                              <div className='row'>
                                <div className='col-sm-4'>
                                  <label>
                                    Template{" "}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      <Select
                                        options={inv_templates}
                                        onChange={
                                          handleSelectSalesInvoiceTemplate
                                        }
                                        value={inv_default_template}
                                        isSearchable={isSearchable}
                                        required
                                      />
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-4'></div>
                                <div className='col-sm-4'>
                                  <label>
                                    Quotation No.{" "}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      <div className='form-control f-regular'>
                                        {single_quotation.quotation_no}
                                      </div>
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-4'>
                                  <label>
                                    Customer
                                    <span className='text-danger'>*</span>
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      {/* <Select options={customers} value={default_customer_value} isSearchable={isSearchable} onChange={handleSelectCustomer} placeholder="Select Customer..." required/> */}
                                      <div className='form-control f-regular'>
                                        {default_customer_value.label}
                                      </div>
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                        <Show when='inv.sales.customers.create'>
                                          <div
                                            className='a-tagicon icon-s'
                                            onClick={() =>
                                              setModal("customer_modal", true)
                                            }
                                          >
                                            <i className='ft ft-plus-circle text-primary'></i>
                                          </div>
                                        </Show>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-md-4'></div>
                                <div className='col-sm-4'>
                                  <label>Invoice Date</label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      <div className='date-picker-100'>
                                        <DatePicker
                                          onChange={(date) =>
                                            QUDatePicker(
                                              date,
                                              "sales_invoice_date"
                                            )
                                          }
                                          selected={
                                            new Date(
                                              single_quotation.sales_invoice_date
                                            )
                                          }
                                          name='sales_invoice_date'
                                          className='form-control f-regular'
                                        />
                                      </div>
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {single_quotation.customer && (
                                <Fragment>
                                  <div className='table-responsive'>
                                    <table className='table table-hover table-striped table-bordered'>
                                      <thead>
                                        <tr>
                                          <th className='min-wdth-100 '>SKU</th>
                                          <th className='min-wdth-300 '>
                                            Product
                                          </th>
                                          <th className='min-wdth-120 mw-6m'>
                                            Qty
                                          </th>
                                          <th className='min-wdth-100 '>
                                            Unit
                                          </th>
                                          <th className='min-wdth-150 '>
                                            Unit/Price
                                          </th>
                                          <th className='min-wdth-150 '>
                                            Amount
                                          </th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {single_quotation.quotation_items.map(
                                          (item, index) => (
                                            <tr
                                              key={index}
                                              className={stockClass(
                                                item.status_code
                                              )}
                                            >
                                              <td className=''>
                                                <DynamicFont
                                                  content={item.sku}
                                                />
                                              </td>
                                              <td className='min-wdth-300 max-wdth-300'>
                                                {/* {item.product_name} <br/> <small className="text-secondary">{item.attribute_string != '()' ? item.attribute_string : ''}</small> */}
                                                <AsyncSelect
                                                  value={{
                                                    label: item.product_name,
                                                    value: item._id,
                                                  }}
                                                  components={{ Option }}
                                                  classNamePrefix='lp-copy-sel'
                                                  isLoading={loading}
                                                  loadingMessage={() =>
                                                    "searching..."
                                                  }
                                                  defaultOptions={item.items}
                                                  loadOptions={debounce(
                                                    (value) =>
                                                      searchProducst(
                                                        value,
                                                        index
                                                      ),
                                                    100
                                                  )}
                                                  isSearchable={true}
                                                  cacheOptions
                                                  onChange={(val) =>
                                                    handleSelectItem(val, index)
                                                  }
                                                  placeholder='Select Products...'
                                                  required
                                                />
                                              </td>
                                              <td
                                                className='mw-6m text-center'
                                                align='center'
                                              >
                                                <input
                                                  value={
                                                    edit_quotation_items[index]
                                                      .qty
                                                  }
                                                  onChange={(e) =>
                                                    handleEditInputRow(index, e)
                                                  }
                                                  className='form-control'
                                                  type='number'
                                                  name='qty'
                                                />
                                              </td>
                                              <td className='min-wdth-100 max-wdth-100 text-center'>
                                                {item.product_unit_name}
                                              </td>
                                              <td
                                                className='mw-8m text-right'
                                                align='right'
                                              >
                                                {NumberFormat(
                                                  item.srp.toFixed(2)
                                                )}
                                              </td>
                                              <td
                                                className='min-wdth-150 max-wdth-150'
                                                align='right'
                                              >
                                                {NumberFormat(
                                                  item.srp.toFixed(2)
                                                )}
                                              </td>
                                              <td
                                                align='center'
                                                className='mw-2m'
                                              >
                                                <div className='btn-group text-center'>
                                                  <button
                                                    className='btn btn-sm btn-danger'
                                                    onClick={(e) =>
                                                      handleRemoveItem(index)
                                                    }
                                                    type='button'
                                                  >
                                                    <i className='ft ft-trash'></i>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                        <tr>
                                          <td colSpan='7' className='text-left'>
                                            <button
                                              className='btn btn-primary'
                                              type='button'
                                              onClick={add_items}
                                            >
                                              <i className='la la-plus'></i>
                                            </button>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                        </tr>
                                        <tr>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                          <td className='p-2'></td>
                                        </tr>
                                        <tr>
                                          <td colSpan={4}></td>
                                          <td>
                                            <div align='right'>Sub Total</div>
                                          </td>
                                          <td align='right'>
                                            {NumberFormat(
                                              single_quotation.sub_total.toFixed(
                                                2
                                              )
                                            )}
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td colSpan={4}></td>
                                          <td>
                                            <div align='right'>Discount</div>
                                          </td>
                                          <td>
                                            {(() => {
                                              switch (
                                                single_quotation.discount_type
                                              ) {
                                                case 1:
                                                  return (
                                                    <div className='input-group'>
                                                      <input
                                                        onChange={
                                                          handleInputChangeDiscount
                                                        }
                                                        value={
                                                          single_quotation.discount
                                                        }
                                                        name='discount'
                                                        className='form-control text-right f-regular'
                                                        type='number'
                                                      />
                                                      <div className='input-group-append'>
                                                        <span className='input-group-text py-0 f-regular discount-1'>
                                                          %
                                                        </span>
                                                      </div>
                                                    </div>
                                                  );
                                                case 2:
                                                  return (
                                                    <input
                                                      onChange={
                                                        handleInputChangeDiscount
                                                      }
                                                      value={
                                                        single_quotation.discount
                                                      }
                                                      name='discount'
                                                      className='form-control text-right f-regular'
                                                      type='number'
                                                    />
                                                  );
                                                default: // 0 disabled
                                                  return (
                                                    <input
                                                      value={
                                                        single_quotation.discount
                                                      }
                                                      disabled
                                                      name='discount'
                                                      className='form-control text-right f-regular'
                                                      type='number'
                                                    />
                                                  );
                                              }
                                            })()}
                                          </td>
                                          <td
                                            width='10%'
                                            className='text-center padding-td-5'
                                          >
                                            <Select
                                              name='discount_type'
                                              onChange={
                                                handleSelectDiscountType
                                              }
                                              menuPortalTarget={document.body}
                                              value={
                                                percent_value[
                                                  single_quotation.discount_type
                                                ]
                                              }
                                              defaultValue={
                                                percent_value[
                                                  single_quotation.discount_type
                                                ]
                                              }
                                              label='Single select'
                                              options={percent_value}
                                              isSearchable={false}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={4}></td>
                                          <td>
                                            <div align='right'>
                                              VAT ({single_quotation.tax} %)
                                            </div>
                                          </td>
                                          <td align='right'>
                                            {NumberFormat(
                                              single_quotation.vat_amount.toFixed(
                                                2
                                              )
                                            )}
                                          </td>
                                          <td className='padding-td-5 min-width-150'>
                                            <Select
                                              name='tax_type'
                                              onChange={handleTaxType}
                                              value={single_quotation.tax_type}
                                              menuPortalTarget={document.body}
                                              options={tax_types}
                                              isSearchable={false}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={4}></td>
                                          <td>
                                            <div align='right'>
                                              Delivery Fee
                                            </div>
                                          </td>
                                          <td width='12%'>
                                            {isOther ? (
                                              <input
                                                onChange={handleInputAttr}
                                                value={edit_attr.other}
                                                name='other'
                                                className='form-control'
                                                type='number'
                                              />
                                            ) : (
                                              <div align='right'>
                                                {" "}
                                                {single_quotation.other}{" "}
                                              </div>
                                            )}
                                          </td>
                                          <td className='text-center'>
                                            {isOther ? (
                                              <Fragment>
                                                <div className='btn-group'>
                                                  <a
                                                    className='btn btn-primary btn-sm'
                                                    onClick={() =>
                                                      handleAttrSubmit("other")
                                                    }
                                                  >
                                                    <i className='ft ft-check text-white'></i>
                                                  </a>
                                                </div>
                                              </Fragment>
                                            ) : (
                                              <a
                                                className='btn btn-info btn-sm'
                                                onClick={() =>
                                                  toggleEditBtn(true, "isOther")
                                                }
                                              >
                                                <i className='ft ft-edit text-white'></i>
                                              </a>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan='4'></td>
                                          <td>
                                            <div align='right'>
                                              <strong>Total</strong>
                                            </div>
                                          </td>
                                          <td align='right'>
                                            {NumberFormat(
                                              single_quotation.total.toFixed(2)
                                            )}
                                          </td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Fragment>
                              )}

                              <div className='col-12 mb-2'></div>
                              <div className='row f-regular'>
                                <div className='col-md-4'>
                                  <label>
                                    Remarks{" "}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      <textarea
                                        onChange={handleInputChange}
                                        value={single_quotation.remarks}
                                        rows='2'
                                        placeholder='Remarks'
                                        name='remarks'
                                        className='form-control f-regular'
                                      ></textarea>
                                      {/* <div className="form-control f-regular">{single_quotation.remarks} </div> */}
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-4'>
                                  <label>
                                    Payment Mode{" "}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      <Select
                                        options={payment_mode_options}
                                        onChange={handleSelectPaymentType}
                                        value={single_quotation.payment_mode}
                                        isSearchable={isSearchable}
                                      />
                                      {/* <div className="form-control f-regular">{single_quotation.payment_mode.label}</div> */}
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-4'>
                                  <label>
                                    Payment Terms{" "}
                                    <span className='text-danger'>*</span>
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10 col-10 pr-0'>
                                      <Select
                                        options={terms_conditions}
                                        onChange={handleSelectTermsConditions}
                                        value={single_quotation.payment_terms}
                                        isSearchable={isSearchable}
                                      />
                                      {/* <div className="form-control f-regular">{single_quotation.terms.label}</div> */}
                                    </div>
                                    <div className='float-right pl-0 col-md-2 col-2'>
                                      <div className='input-group-prepend'>
                                        <span
                                          href='#'
                                          className='tool-tip text-primary'
                                          data-tip='this is a tool tip'
                                        >
                                          <i className='la la-question-circle'></i>
                                        </span>
                                        <div
                                          className='a-tagicon icon-s'
                                          onClick={() =>
                                            setModal(
                                              "payment_terms_modal",
                                              true
                                            )
                                          }
                                        >
                                          <i className='ft ft-plus-circle text-primary'></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-4'>
                                  <div className='form-group'>
                                    <label>
                                      Receipt Type{" "}
                                      <span className='text-danger'>*</span>
                                    </label>
                                    <div className='form-group row'>
                                      <div className='col-md-10 col-10 pr-0'>
                                        <Select
                                          options={receipt_type_options}
                                          onChange={handleSelectTypeInvoice}
                                          value={single_quotation.receipt_type}
                                          isSearchable={isSearchable}
                                          required
                                        />
                                      </div>
                                      <div className='float-right pl-0 col-md-2 col-2'>
                                        <div className='input-group-prepend'>
                                          <span
                                            href='#'
                                            className='tool-tip text-primary'
                                            data-tip='this is a tool tip'
                                          >
                                            <i className='la la-question-circle'></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                      <div className='row justify-content-end'>
                        <div className='mr-2'>
                          <div className='form-group'>
                            <Show when='inv.sales.sales-orders.draft'>
                              <button
                                onClick={() =>
                                  handleOnSubmit("create-si", "draft")
                                }
                                type='button'
                                className='btn btn-outline-light text-dark'
                              >
                                Draft
                              </button>
                            </Show>{" "}
                            {edit_row_index != null || isDiscount || isOther ? (
                              <button
                                type='button'
                                className='btn btn-primary btn-save'
                                disabled
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleOnSubmit("create-si", "saved")
                                }
                                type='submit'
                                className='btn btn-primary btn-save'
                              >
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <QuotationCustomerModal
        show={customer_modal}
        onHide={() => setModal("customer_modal", false)}
      />
      <PaymentTermsModal
        show={payment_terms_modal}
        onHide={() => setModal("payment_terms_modal", false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  quotation: state.quotation,
});

export default connect(mapStateToProps, {
  handleOnSubmit,
  handleSelectCustomer,
  toggleEditBtn,
  handleAddItem,
  handleRemoveItem,
  handleInputAttr,
  handleInputChange,
  handleInputSrp,
  handleCheckVat,
  handleInputQty,
  handleSelectItem,
  handleSelectTermsConditions,
  handleSelectSalesInvoiceTemplate,
  handleSelectTypeInvoice,
  getInvoiceTemplates,
  handleEditInputRow,
  handleToggleEditRow,
  handleUpdateRow,
  handleAttrSubmit,
  handleSelectPayment,
  getSingleQuotation,
  getProducts,
  getTermsConditions,
  getCustomers,
  handleSelectPaymentType,
  handleSelectDiscountType,
  handleInputChangeDiscount,
  setModal,
  QUDatePicker,
  computationHandler,
  handleTaxType,
  searchProducst,
  add_items,
})(QuotationCreateSalesInvoice);
