import React from 'react';
import { Modal } from 'react-bootstrap';
import { setModal } from './../../../../redux/actions/settings_app/setting_app.actions';
import { connect } from 'react-redux';
import TermConditionCreateGlobal from './../../SalesInvoice/PaymentTerms/TermCondition.create.global';

function SettingAppPaymentTermsModal({ payment_terms_modal , setModal}) {
    return (
        <Modal centered dialogClassName="modal-container"  show={payment_terms_modal} onHide={() => setModal('payment_terms_modal', false)} size="md" animation={true}>
            <Modal.Header closeButton dialogClassName="p-0">
                <Modal.Title> Payment Terms</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TermConditionCreateGlobal pageOrigin="setting_app"/>
            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = state => ({
    payment_terms_modal: state.setting_app.payment_terms_modal
});

export default connect(mapStateToProps, { setModal })(SettingAppPaymentTermsModal);

