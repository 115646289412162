import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';
import '../../layouts/template_styles/mototruck.css';

export class MotoTruckTemplate extends React.PureComponent {
    // function SalesInvoicePrintContent({ invoice, auth }) {
    // let subId = auth.user.subscriber_id;
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.invoice.invoice_items.length;
        this.state = {
            invoice: this.props.invoice,
            auth: this.props.auth,
            activePage: 1,
            itemsCountPerPage: 10,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr className="empty-row" key={'tr-' + i}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    renderBottomRows(invoice, currencyDisplay) {
        const bottomRowsDetails = [
            {
                articlesCell: {
                    title: 'VATable',
                    subTitle: 'VAT-Exempt',
                    value: '',
                },
                unitPriceCell: {
                    title: 'TOTAL SALES',
                    subTitle: `(VAT ${
                        invoice.tax_type &&
                        invoice.tax_type.value === 'exclusive'
                            ? 'EXCLUSIVE'
                            : 'INCLUSIVE'
                    })`,
                    value: '',
                },
            },
            {
                articlesCell: {
                    title: 'VAT Zero Rated Sale',
                    subTitle: null,
                    value: '',
                },
                unitPriceCell: {
                    title: 'LESS: VAT',
                    subTitle: 'TOTAL',
                    value: '',
                },
            },
            {
                articlesCell: {
                    title: 'VAT - 12%',
                    subTitle: null,
                    value: formatPrice(invoice.vat_amount, currencyDisplay),
                },
                unitPriceCell: {
                    title: 'Less: SC/PWD',
                    subTitle: 'Discount',
                    value: '',
                },
            },
        ];

        return bottomRowsDetails
            .map((details) => (
                <tr className="bottom-row">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="d-flex">
                        <span className="col-9 text-right">
                            <div
                                style={{
                                    fontSize: '8px !important',
                                    borderRight: '1px solid black',
                                }}
                            >
                                {details.articlesCell.title}
                            </div>
                            <div
                                style={{
                                    fontSize: '8px !important',
                                    borderRight: '1px solid black',
                                }}
                            >
                                {details.articlesCell.subTitle || ''} &nbsp;
                            </div>
                        </span>
                        <span
                            className="col-3"
                            style={{ fontSize: '8px !important' }}
                        >
                            {details.articlesCell.value}
                        </span>
                    </td>
                    <td>
                        <div style={{ fontSize: '8px !important' }}>
                            {details.unitPriceCell.title}
                        </div>
                        <div style={{ fontSize: '8px !important' }}>
                            {details.unitPriceCell.subTitle}
                        </div>
                    </td>
                    <td>{details.unitPriceCell.value}</td>
                </tr>
            ))
            .concat(
                <tr className="bottom-row total-amount">
                    {/* <td style={{ borderRight: "0 !important" }}>&nbsp;</td>
                    <td style={{ borderRight: 'none' }}>&nbsp;</td>
                    <td style={{ borderRight: 'none' }}>&nbsp;</td> */}

                    <td
                        className="text-right title"
                        style={{ paddingRight: '6px' }}
                        colSpan={4}
                    >
                        TOTAL AMOUNT DUE
                    </td>
                    <td style={{ fontSize: '12px' }} className="amount-due">
                        {formatPrice(invoice.total, currencyDisplay)}
                    </td>
                </tr>
            );
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        // console.log(page, totalPage);
        const { invoice } = this.state;
        const totalItemsCount = invoice.invoice_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        invoice.invoice_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
                // console.log(extraRowsCount);
            }
        });

        // console.log(showItemsFrom, showItemsTo);
        // console.log(extraRowsCount);

        const DynamicStringStyle = {
            maxWidth: '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        let auth = this.state.auth;

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';
        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';
        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b temp-div pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const template_setting = this.state.invoice.template_setting;
        const template = this.state.invoice.template;
        const subs_setting = this.state.subs_setting;
        console.log(subs_setting);
        // const currencyDisplay =
        //     'currency' in subs_setting &&
        //     'code' &&
        //     subs_setting.currency &&
        //     subs_setting.currency.code &&
        //     subs_setting.currency.code.checked
        //         ? 'code'
        //         : 'symbol';
        const currencyDisplay = 'code';

        return (
            <div className="receipt-container margin-1p5em px-1 print-page mt-1">
                <div className="receipt-header text-center">
                    {/* { template_setting && template_setting.company_logo && <img src={template_setting.company_logo} className="print-logo"/> }
            { template_setting && template_setting.company_logo === '' ? template_setting ? <div className="c-name">{template_setting.company_name}</div> : <div className="print-logo-empty"></div> : null}
             */}
                    <div className="receipt-mototruck">
                        <div className="company-logo">
                            <img
                                src="/motoTruck.png"
                                alt="Logo"
                                style={{ width: '70px' }}
                            />
                        </div>
                        <div className="company-name">MOTO TRUCK</div>
                    </div>
                    <div className="company-details">
                        Owned & Operated by:{' '}
                        <strong>MACRO TRUCK PARTS TRADING OPC</strong> <br />
                        Osmeña St. Barangay 28 (Pob.) District 1 9000 Cagayan de
                        Oro City (Capital) Misamis Oriental Philippines
                        <br />
                        Tel. Nos. 856-4356, 859-1563, 0917-704-6036, Fax
                        852-1482 <br />
                        VAT Reg. TIN ___-___-___-___
                        <br />
                        Specialized in Truck: FUSO, HINO, ISUZU, HOWO, FOTON,
                        SHACMAN, CHENGLONG & CREATEK
                    </div>
                </div>

                <div className="print-body print-content-block">
                    <div class="receipt-float">
                        <div className="charge">
                            {/* <strong> {template.template_name}</strong> */}
                        </div>
                        <div
                            className="date text-uppercase"
                            style={{ lineHeight: 1.2 }}
                        >
                            <strong> {template.template_name}</strong>
                            <p>No: {invoice.invoice_no}</p>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row justify-content-between">
                            <div class="col-6">
                                {/* details left side */}
                                {[
                                    {
                                        title: 'CHARGED TO',
                                        value: invoice.customer.name,
                                    },
                                    { title: 'TIN/SC-TIN', value: '' },
                                    { title: 'OSCA/PWD ID No.', value: '' },
                                    {
                                        title: 'Address',
                                        value: invoice.customer.street1,
                                    },
                                ].map((detail) => (
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                        }}
                                    >
                                        <strong>{detail.title}: &nbsp;</strong>{' '}
                                        <span
                                            style={{
                                                width: '100px',
                                                display: 'inline-block',
                                                borderBottom: '1px solid black',
                                                flexGrow: '1',
                                            }}
                                        >
                                            {detail.value}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div class="col-5">
                                {/* details right side */}
                                {[
                                    {
                                        title: 'Date',
                                        value: defaultFormat(
                                            invoice.invoice_date
                                        ),
                                    },
                                    {
                                        title: 'Terms',
                                        value: invoice.payment_terms.label,
                                    },
                                    { title: 'Business Style', value: '' },
                                ].map((detail) => (
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                        }}
                                    >
                                        <strong>{detail.title}: &nbsp;</strong>{' '}
                                        <span
                                            style={{
                                                width: '100px',
                                                display: 'inline-block',
                                                borderBottom: '1px solid black',
                                                flexGrow: '1',
                                            }}
                                        >
                                            {detail.value}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="receipt-table col-md-12 pr-0 mt-1">
                        {invoice.status == 'draft' ? (
                            <div class="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table new-table table-bordered">
                            <thead className="receipt-thead">
                                <tr className="table-header">
                                    <th className="text-center">QTY</th>
                                    <th className="text-center">Unit</th>
                                    <th className="text-center product-description">
                                        ARTICLES
                                    </th>
                                    <th className="product-price text-center">
                                        <div>
                                            Unit <br /> price
                                        </div>
                                    </th>
                                    <th className="text-center">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice.invoice_items.map((data, index) =>
                                    showItemsFrom <= index ? (
                                        showItemsTo >= index ? (
                                            <>
                                                <tr key={index}>
                                                    <td
                                                        className="text-center"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <DynamicFont
                                                            content={data.qty}
                                                        />
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <DynamicFont
                                                            content={
                                                                data.product_unit_name
                                                            }
                                                        />
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${data._id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    data.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {data.sku !==
                                                            '' && (
                                                            <>
                                                                <br />
                                                                <span className="f-10">
                                                                    {
                                                                        data.sku
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {data.description !==
                                                            '' && (
                                                            <>
                                                                <br />
                                                                <span className="f-10">
                                                                    {
                                                                        data.description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {data.barnd !==
                                                            '' && (
                                                            <>
                                                                <br />
                                                                <span className="f-10">
                                                                    {
                                                                        data.brand
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            data.srp,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            data.amount,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                </tr>

                                                {data.discount_type &&
                                                    data.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <div className="text-center">
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            Discount:{' '}
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                data.discount
                                                                            }
                                                                            {data
                                                                                .discount_type
                                                                                .label ===
                                                                            'Percent'
                                                                                ? ' %'
                                                                                : ''}
                                                                        </span>
                                                                    </span>

                                                                    <span
                                                                        style={{
                                                                            margin: '0 0 0 10px',
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            Discount
                                                                            type:{' '}
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                data
                                                                                    .discount_type
                                                                                    .label
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {formatPrice(
                                                                    Number(
                                                                        data.discount_amount
                                                                    ).toFixed(
                                                                        2
                                                                    ),
                                                                    currencyDisplay
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}

                                {this.renderExtrRow(extraRowsCount)}
                                {this.renderBottomRows(
                                    invoice,
                                    currencyDisplay
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-8 p-0" style={{ fontSize: '8px' }}>
                        Accounts are payable upon demand interest at 12% per
                        annum will be chartge the past due accounts. In case
                        court action is instituted, customer agrees to submite
                        to the jurisdiction of the court of the City of Cagayan
                        de Oro and to pay 25% surcharged for Attorney's fees and
                        legal expenses
                    </div>
                    {page == totalPage ? (
                        <Fragment>
                            <div className="container d-flex receipt-footer col-12 justify-content-between  mt-2 p-0">
                                <div
                                    className="col-4 p-0"
                                    style={{ fontSize: '9px', lineHeight: 1 }}
                                >
                                    <div> 500 Bks. (50x3) 16,251 - 41,250</div>
                                    <div>BIR Authority to Print No.</div>
                                    <div>Date Issued:</div>
                                    <div>
                                        LEGACY SALES & PRINTING PRESS, INC.
                                    </div>
                                    <div>
                                        A. Luna Extn. 27 Cagayan de Oro City
                                    </div>
                                    <div>TIN 003-053-883-000 VAT</div>
                                </div>
                                <div
                                    className="col-3 d-flex flex-column justify-content-start align-content-center"
                                    style={{
                                        fontSize: '9px',
                                    }}
                                >
                                    <div>Printer's Accreditation No. </div>
                                    <div>Date Issued: </div>
                                </div>
                                <div className="col-5 col-3 d-flex flex-column justify-content-start align-content-center">
                                    <div
                                        style={{
                                            borderBottom: '1px solid black',
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <div className="text-center">
                                        <span style={{ fontSize: '12px' }}>
                                            CUSTOMER AUTHORIZED REPRESENTATIVE
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="container receipt-terms pr-0">
                                <div className="row receipt-terms-row">
                                    <div className="col-5 tab-footer left">
                                        <strong>TERMS & CONDITIONS:</strong>{' '}
                                        Interest of 12% per annum will be
                                        charged on all overdue accounts. In case
                                        of litigation, the court of the City of
                                        Cagayan de Oro shall have jurisdiction,
                                        and the customer hereby agrees to pay
                                        for attorney’s fees and cost of suit
                                        resulting therefrom. Goods travel at
                                        buyer’s risk. Our responsibility ceases
                                        after goods delivered to the
                                        transportation are correct.
                                    </div>
                                    <div className="col-3 tab-footer left fs">
                                        No Return/Exchange will be entertained
                                        after 7 days from Date of Delivery.
                                    </div>
                                    <div className="col-4 tab-footer fs">
                                        <div>VATable Sales:</div>
                                        <div>VAT -Exempt Sales:</div>
                                        <div>Zero-Rated Sales:</div>
                                        <div>
                                            VAT Amount:{' '}
                                            {formatPrice(
                                                invoice.vat_amount,
                                                currencyDisplay
                                            )}
                                        </div>
                                        <div>
                                            <strong>TOTAL AMOUNT DUE:</strong>{' '}
                                            {formatPrice(
                                                invoice.vat_amount,
                                                currencyDisplay
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container receipt-acknowledgement">
                                <div className="row receipt-acknowledgement-row">
                                    <div className="col-5 tab-acknowledgement left">
                                        <div className="prepared-by">
                                            <div className="mb-5">
                                                Prepared by:
                                            </div>

                                            <div className="mt-5">
                                                ______________
                                            </div>
                                        </div>

                                        <div className="checked-by">
                                            <div className="mb-5">
                                                Checked by:
                                            </div>

                                            <div className="mt-5">
                                                ______________
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-7 tab-acknowledgement right text-center">
                                        <h5>
                                            <strong>
                                                Customer’s Acknowledgement
                                            </strong>
                                        </h5>
                                        <p className="customer-acknowledgement">
                                            Received the merchandise as
                                            indicated above in good order as to
                                            quantity and condition. All terms
                                            and conditions of sale are herein
                                            accepted
                                        </p>

                                        <div className="container ">
                                            <div className="row receipt-sig">
                                                <div className="col-6">
                                                    ____________________________{' '}
                                                    <br />
                                                    Printed Name of Authorized
                                                    Representative
                                                </div>
                                                <div className="col-6">
                                                    ____________________________{' '}
                                                    <br />
                                                    Signature
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container receipt-footer">
                                <div className="row">
                                    <div className="col-4">
                                        00 Bxs. (500x__ply) 0000-0000 BIR
                                        Authority to Print No. 098AU2021______
                                        Date Issued: _______ Valid Until:
                                        ________ Legacy Sales & Printing Press,
                                        Inc. TIN 003-053-883-000 VAT A. Luna
                                        Extn., 27 Cagayan de Oro City
                                    </div>
                                    <div className="col-4">
                                        <strong>
                                            {' '}
                                            BIR Permit to Use Loose-Leaf No.
                                            __________ <br />
                                            Date Issued: _____________
                                            <br /> <br />{' '}
                                        </strong>
                                        PRINTER’S ACCREDITATION NO.
                                        098MP20190000000008 Date Issued:
                                        03/18/19
                                    </div>
                                    <div className="col-4 text-center quote">
                                        <strong>
                                            “THIS CHARGE INVOICE SHALL BE VALID
                                            FOR FIVE (5) YEARS FROM THE DATE OF
                                            ATP”
                                        </strong>
                                    </div>
                                </div>
                            </div> */}
                        </Fragment>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="row">
                    <div className="col-12 pb-0 mb-0">
                        <span className="pull-right f-12">
                            Page {page}/{totalPage}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let invoice = this.state.invoice;

        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{invoice.status == 'draft' ? invoice.status : ''}</h1>
                </div>
            */}
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
// 	single_invoice: state.invoice.single_invoice,
// 	auth: state.auth
// })

export default MotoTruckTemplate;
