import React from 'react';
import { connect} from 'react-redux';
import { onlyNumber } from '../../../Utils/Common';
import Switch from "react-switch";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function TemplateFormGlobal({ form_data, formActions: { inputSetDefault, handleInput, handleContacts } }) {

    return (
        <>
            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Set as Default: </label>
                <div className="col-md-7 col-11">
                    <Switch onColor="#6967CE" onChange={inputSetDefault} checked={form_data.is_default}/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Template Name: <span className='text-danger'>*</span></label>
                <div className="col-md-7 col-11">
                    <input onChange={handleInput} value={form_data.template_name} type="text" name="template_name" className="form-control f-regular" placeholder="Template Name" required/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Company Name: <span className='text-danger'>*</span></label>
                <div className="col-md-7 col-11">
                    <input onChange={handleInput} value={form_data.company_name} type="text" name="company_name" className="form-control f-regular" placeholder="Company Name" required/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Company Address: </label>
                <div className="col-md-7 col-11">
                    <textarea onChange={handleInput} value={form_data.company_address} name="company_address" rows="3" className="form-control f-regular" placeholder="Company Address"></textarea>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Company Mobile: </label>
                <div className="col-md-7 col-11">
                    <PhoneInput onChange={mobile => handleContacts('mobile', mobile)} value={form_data.company_mobile} countryCodeEditable={false} enableSearch="true" value={form_data.company_mobile ? form_data.company_mobile : ''} country="ph" inputProps={{ name: 'mobile', required: false, autoFormat:true, enableSearch: true }}  inputClass="react-mobile-input w-100" placeholder="Company Mobile" masks={{ph: '... ... ....'}}/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Company Landline: </label>
                <div className="col-md-7 col-11">
                    <input onChange={e => handleContacts('landline', e.target.value)} value={form_data.company_landline} onKeyPress={e => onlyNumber(e)} value={form_data.company_landline} type="number" name="company_landline" className="form-control f-regular" placeholder="Company Landline"/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Company Email: </label>
                <div className="col-md-7 col-11">
                    <input onChange={handleInput} type="text" value={form_data.company_email} name="company_email" className="form-control f-regular" placeholder="Company Email Address"/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>

            <div className="form-group row f-regular">
                <label className="col-md-4 col-sm-12">Company TIN: </label>
                <div className="col-md-7 col-11">
                    <input onChange={handleInput} onKeyPress={e => onlyNumber(e)} type="number" value={form_data.company_tin} name="company_tin" className="form-control f-regular" placeholder="Company TIN"/>
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                    </div>
                </div>  
            </div>
        </>
    )
}

const matpStateToProps = state => ({
    // 
})

export default connect(matpStateToProps, null)(TemplateFormGlobal)
