import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';

export const SalesInvoiceService = {
    getSingleSalesInvoice: (id) => http.get('/admin/sales-invoice/' + id),
    ArchiveInvoice: (formParams) =>
        http.post('/admin/sales-invoice/archives/list', formParams),
    SalesInvoiceCreate: (formParams) =>
        http.post('/admin/sales-invoice', formParams),
    paginateWithSearchInvoices: (formParams) =>
        http.post('/admin/sales-invoice/search', formParams),
    updateSingleSalesInvoice: (formParams, id) =>
        http.put('/admin/sales-invoice/' + id, formParams),
    getSingleCustomer: (id) => http.get('/admin/customer/' + id),
    getCustomers: (formdata) => http.post('/admin/sales-invoice/si-customer', formdata), // get all customer (new)
    getProducts: (formdata) =>
        http.post('/admin/sales-invoice/si-product', formdata), // get all the products (new)
    getQuotations: (formdata) =>
        http.get('/admin/sales-invoice/si-quotation', formdata), // get all the quotations (new)
    getSingleQuotation: (id) =>
        http.get('/admin/sales-invoice/si-quotation/?id=' + id), // get all the quotations (new)
    printPreview: (formParams) =>
        http.post('setting/invoice-setting/preview', formParams),
    getTermsConditions: () => http.get('admin/term-condition'),
    CheckInStock: (formParams) =>
        http.post('/admin/sales-invoice/check-stock', formParams),
    goPage: (formParams) =>
        http.post('/admin/sales-invoice/go-page', formParams),
    sendEmail: (id) => http.get('/admin/sales-invoice/email/' + id),
    getPdf: (id) => httpWithFile.get('/admin/sales-invoice/pdf/' + id),
    getEmailConfig: () => http.get('/settings/email/security'),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    getInvTemplates: () => http.get('/admin/sales-invoice-template'),
    updatePaymentStatus: (formdata) =>
        http.post('/admin/sales-invoice/update-payment', formdata),
    cancelInvoice: (id) => http.get('/admin/sales-invoice/cancel/' + id),
};

// ** THIS ARE THE OLD API, WE KEEP THIS FOR REFERENCE ONLY
// getInvTemplates: () => http.get('/admin/setting/invoice-setting/get/invoice/themes'), // get all the products
// getInvTemplates: () => http.get('/admin/setting/invoice-setting/get/invoice/themes'), // get all the products
// getProducts: (formdata) => http.post('/admin/product/search', formdata), // get all the products (old)
// getCustomers: (search) => http.get('/admin/sales-invoice/customer?search=' + search), // get all customer (old)
// getDisplayCustomer: (id) => http.get('/admin/sales-invoice/selected/' + id), // get customer data base on id
