import * as TYPES from './../types';

// state
const initialState = {
    user: {},
    access: [],
    role: {},
    permissions: [],
    auth_loading: true,
    inputs : {
        email : '',
        password : '',
        confirm_password : '',
        token : '',
        username : '',
        fname : '',
        lname : '',
        company_name : '',
        industry : ''
    },
    email : '',
    status  : {
        success : true,
        message : '',
        result : false,
        message_arr : []
    },
    loading : false,
    industry : [
        "Accounting",
        "Airlines / Aviation",
        "Alternative Dispute Resolution",
        "Alternative Medicine",
        "Animation",
        "Apparel / Fashion",
        "Architecture / Planning",
        "Arts / Crafts",
        "Automotive",
        "Aviation / Aerospace",
        "Banking / Mortgage",
        "Biotechnology / Greentech",
        "Broadcast Media",
        "Building Materials",
        "Business Supplies / Equipment",
        "Capital Markets / Hedge Fund / Private Equity",
        "Chemicals",
        "Civic / Social Organization",
        "Civil Engineering",
        "Commercial Real Estate",
        "Computer Games",
        "Computer Hardware",
        "Computer Networking",
        "Computer Software / Engineering",
        "Computer / Network Security",
        "Construction",
        "Consumer Electronics",
        "Consumer Goods",
        "Consumer Services",
        "Cosmetics",
        "Dairy",
        "Defense / Space",
        "Design",
        "E - Learning",
        "Education Management",
        "Electrical / Electronic Manufacturing",
        "Entertainment / Movie Production",
        "Environmental Services",
        "Events Services",
        "Executive Office",
        "Facilities Services",
        "Farming",
        "Financial Services",
        "Fine Art",
        "Fishery",
        "Food Production",
        "Food / Beverages",
        "Fundraising",
        "Furniture",
        "Gambling / Casinos",
        "Glass / Ceramics / Concrete",
        "Government Administration",
        "Government Relations",
        "Graphic Design / Web Design",
        "Health / Fitness",
        "Higher Education / Acadamia",
        "Hospital / Health Care",
        "Hospitality",
        "Human Resources / HR",
        "Import / Export",
        "Individual / Family Services",
        "Industrial Automation",
        "Information Services",
        "Information Technology / IT",
        "Insurance",
        "International Affairs",
        "International Trade / Development",
        "Internet",
        "Investment Banking / Venture",
        "Investment Management / Hedge Fund / Private Equity",
        "Judiciary",
        "Law Enforcement",
        "Law Practice / Law Firms",
        "Legal Services",
        "Legislative Office",
        "Leisure / Travel",
        "Library",
        "Logistics / Procurement",
        "Luxury Goods / Jewelry",
        "Machinery",
        "Management Consulting",
        "Maritime",
        "Market Research",
        "Marketing / Advertising / Sales",
        "Mechanical or Industrial Engineering",
        "Media Production",
        "Medical Equipment",
        "Medical Practice",
        "Mental Health Care",
        "Military Industry",
        "Mining / Metals",
        "Motion Pictures / Film",
        "Museums / Institutions",
        "Music",
        "Nanotechnology",
        "Newspapers / Journalism",
        "Non - Profit / Volunteering",
        "Oil / Energy / Solar / Greentech",
        "Online Publishing",
        "Other Industry",
        "Outsourcing / Offshoring",
        "Package / Freight Delivery",
        "Packaging / Containers",
        "Paper / Forest Products",
        "Performing Arts",
        "Pharmaceuticals",
        "Philanthropy",
        "Photography",
        "Plastics",
        "Political Organization",
        "Primary / Secondary Education",
        "Printing",
        "Professional Training",
        "Program Development",
        "Public Relations / PR",
        "Public Safety",
        "Publishing Industry",
        "Railroad Manufacture",
        "Ranching",
        "Real Estate / Mortgage",
        "Recreational Facilities / Services",
        "Religious Institutions",
        "Renewables / Environment",
        "Research Industry",
        "Restaurants",
        "Retail Industry",
        "Security / Investigations",
        "Semiconductors",
        "Shipbuilding",
        "Sporting Goods",
        "Sports",
        "Staffing / Recruiting",
        "Supermarkets",
        "Telecommunications",
        "Textiles",
        "Think Tanks",
        "Tobacco",
        "Translation / Localization",
        "Transportation",
        "Utilities",
        "Venture Capital / VC",
        "Veterinary",
        "Warehousing",
        "Wholesale",
        "Wine / Spirits",
        "Wireless",
        "Writing / Editing"
    ]
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.AUTH_LOADING:
            return {
                ...state,
                auth_loading: action.payload
            } 
        case TYPES.AUTH_USER:
            return {
                ...state,
                user: action.payload
            } 
        case TYPES.AUTH_ROLE:
            return {
                ...state,
                role: action.payload
            } 
        case TYPES.AUTH_ACCESS:
            return {
                ...state,
                access: action.payload
            } 
        case TYPES.AUTH_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            }   
        case TYPES.AUTH_INPUTS:
            return {
                ...state,
                inputs : action.payload
            }
        case TYPES.SET_LOADING :
            return{
                ...state,
                loading : action.payload
            }
        case TYPES.STATUS:
            return {
                ...state,
                status : action.payload
            }
        default:
            return state;
            break;
    }

}

export default authReducer;

