import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { formattedDate } from "../../Utils/Common";
import {
  getBrands,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
} from "../../redux/actions/brand/brand.actions";
import { Redirect, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { Show, useAccess } from "react-redux-permission";
import { Link } from "react-router-dom";
import moment from "moment";

function Brand({
  brand: {
    brands,
    pagination,
    search,
    loading,
    edit_url,
    edit_page,
    create_url,
    sort_order_name,
    sort_order_direction,
    set_row_checked,
  },
  getBrands,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
}) {
  const { hasPermission } = useAccess();
  const canCreate = hasPermission("inv.products.brands.create");
  const canDelete = hasPermission("inv.products.brands.delete");
  const canEdit = hasPermission("inv.products.brands.edit");
  const canview = hasPermission("inv.products.brands.view");

  const history = useHistory();

  useEffect(() => {
    getBrands();
  }, [search]);

  if (edit_page) return <Redirect to={edit_url} />;

  const changePage = (link) => {
    history.push(link);
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "rgba(0,0,0,.05)",
            },
          },
        },
        MUIDataTableBodyCell: {},
        MUIDataTableHeadCell: {
          root: {
            "&:nth-child(3)": {
              width: "250px",
            },
            "&:nth-child(6)": {
              width: "150px",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "_id",
      label: "",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = brands[tableMeta["rowIndex"]]._id;

          if (!canEdit) {
            return <span>{value}</span>;
          }

          return <Link to={`${edit_url}/${id}`}>{value}</Link>;
        },
        setCellProps: (value) => {
          return { className: "text-center" };
        },
      },
    },
    { name: "description", label: "Description" },
    {
      name: "created_at",
      label: "Created at",
      options: {
        display: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let created_at = brands[tableMeta["rowIndex"]].created_at;
          return <>{moment(created_at).format("YYYY-MM-DD - hh:mmA")}</>;
        },
      },
    },
    {
      name: "updated_at",
      label: "Updated at",
      options: {
        display: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let updated_at = brands[tableMeta["rowIndex"]].updated_at;
          return <>{moment(updated_at).format("YYYY-MM-DD - hh:mmA")}</>;
        },
      },
    },
  ];

  const options = {
    serverSide: true,
    jumpToPage: true,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPageOptions: [10, 20],
    rowsPerPage: pagination.itemsCountPerPage,
    count: pagination.totalItemsCount,
    page: pagination.activePage - 1,
    search: false,
    searchOpen: true,
    elevation: 2,
    sortOrder: {
      name: sort_order_name,
      direction: sort_order_direction,
    },
    downloadOptions: {
      filename: `brands_${formattedDate()}.csv`,
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Spinner />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onChangeRowsPerPage: (numberOfRows) => {
      getBrands(null, numberOfRows);
    },
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      switch (action) {
        case "changePage":
          getBrands(tableState.page + 1);
          break;
        case "sort":
          // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
          let column_name = tableState.sortOrder.name;
          let direction = tableState.sortOrder.direction;
          sortTableByColumn(column_name, direction);
          break;
        case "search":
          handleSearchInputMUI(tableState.searchText);
          break;
        case "rowSelectionChange":
          let selectedRows = tableState.selectedRows.data;
          let currentRows = tableState.data;
          getSelectedRows(selectedRows, currentRows);
          break;
        case "propsUpdate":
          // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
          if (set_row_checked) {
            tableState.selectedRows["data"] = [];
            tableState.selectedRows["lookup"] = {};
            tableState.selectedRows["showResponsive"] = false;
          } else {
            return tableState;
          }
        default:
          console.log("action not handled.");
      }
    },
    onRowsDelete: () => {
      // Confirm Delete Modal
      if (canDelete) {
        confirmDeleteDialogBox();
      }

      return false;
    },
    onRowClick: (rowData, rowMeta) => {
      // Go to edit page when row is clicked
      if (canEdit) {
        // changePage(`${edit_url}/${rowData[0]}`);
      }
    },
  };

  if (!canDelete) {
    options.selectableRows = false;
  }

  return (
    <Fragment>
      <Redirect to='/inventory/products?mode=Brands' />
      <div className='app-content content'>
        <ReactTooltip effect='solid' event='click' />
        <div className='content-wrapper max-1920 mx-auto'>
          <div className='content-wrapper-before'></div>
          <div className='content-header row'>
            <div className='content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header'>
              <h3 className='content-header-title mb-0 d-inline-block'>
                Product{" "}
              </h3>
              <div className='breadcrumbs-top d-inline-block'>
                <div className='breadcrumb-wrapper mr-1.5'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item '>
                      <a
                        href='/inventory/products?mode=Brands'
                        className='text-white f-regular'
                      >
                        Brands
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
              <Show when='inv.products.brands.create'>
                <Link
                  to={create_url}
                  className='btn btn-primary pull-right btn-fix-header f-regular'
                >
                  <i className='ft ft-plus'></i> Create New
                </Link>
              </Show>
            </div>
          </div>
          <div className='content-body'>
            <section className='row f-regular'>
              <div className='col-sm-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-title'>
                      List of Brands{" "}
                      <a
                        href='#'
                        className='tool-tip'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </a>
                    </h4>
                    <a className='heading-elements-toggle'>
                      <i className='la la-ellipsis-v font-medium-3'></i>
                    </a>
                    <div className='heading-elements'>
                      <ul className='list-inline mb-0'></ul>
                    </div>
                  </div>
                  <div className='card-content collapse show'>
                    <div className='card-body'>
                      <Show when='inv.products.brands.view'>
                        <MuiThemeProvider theme={getMuiTheme()}>
                          <MUIDataTable
                            data={brands}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </Show>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  brand: state.brand,
});

export default connect(mapStateToProps, {
  getBrands,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
})(Brand);
