import * as TYPE from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import { SwalWarning, SwalError, SwalSuccessAutoRemove,SwallverfiyPass } from '../../../Services/_swal.service';
import { Toast } from 'react-bootstrap';
import {EmailSettingService} from './_service.emailsetting';
import { getNumRows } from '../../../Utils/Common';
import {setPage as PoModal} from '../purchase_order/purchase_order.actions';

const spinLG = '<span class="spinner-border" role="status" aria-hidden="true"></span>';

export const loadConfig = () => async dispatch  =>
{
    EmailSettingService.getConfig().then(res => {
        // console.log("res", res.data);
        let data = res.data;
        let config = {
            host : data.email_host,
            port : data.email_port,
            protocol : data.email_protocol,
            username : data.email_username,
            password : data.email_password,
            test_email : data.test_email,
            require_password : data.require_password
        }
        dispatch({type : TYPE.EMAIL_SETTING_DATA, payload : config});
    })
    .catch(err => {

    })
}

export const dynamicChange = (e) => async (dispatch, getState) => {
    let { name, value } = e.target;
    let {config} = getState().emailsetting;
    config[name] = value;
    dispatch({type : TYPE.EMAIL_SETTING_DATA, payload : config});
}

export const passwordtoggle = (e) => async (dispatch, getState) => {
    let {password_toggle} = getState().emailsetting;
    let toggle = password_toggle;
    toggle = toggle == 'password' ? 'text' : 'password';
    dispatch({type : TYPE.PASSWORD_TOGGLE, payload : toggle});
}

export const toggle_test = () => async (dispatch, getState) => {
    let {show_test} = getState().emailsetting;
    let toggle = show_test;
    toggle = show_test ? false : true;
    dispatch({type : TYPE.EMAIL_SETTING_TOGGLE_TEST, payload : toggle});
}

export const submitConfig = (e) => async (dispatch, getState) => {
    e.preventDefault();
    let {config, btn_lbl} = getState().emailsetting;
    btn_lbl.submit = 'Saving...';
    dispatch({type : TYPE.BTN_SUB, payload : btn_lbl});
    EmailSettingService.setConfig(config).then(result => {
        ToastSuccess(result.data.message);
        btn_lbl.submit = 'Save Settings';
        dispatch({type : TYPE.BTN_SUB, payload : btn_lbl});
    }).catch(result => {
        ToastDanger('Failed to update credentials');
        btn_lbl.submit = 'Save Settings';
        dispatch({type : TYPE.BTN_SUB, payload : btn_lbl});
    })
}

export const testEmail = (e) => async (dispatch, getState) => {
    let {config, btn_lbl, test_result} = getState().emailsetting;
    btn_lbl.test = 'Sending...';
    btn_lbl.loading = true;
    btn_lbl._class = 'btn-blue';
    btn_lbl.icon = 'icon-loading-custom icon-15';
    test_result.show = false;
    dispatch({type : TYPE.EMAIL_SETTING_TEST_RESULT, payload : test_result});
    dispatch({type : TYPE.BTN_SUB, payload : btn_lbl});
    EmailSettingService.testConfig(config).then(result => {
        btn_lbl.test = 'Send Test Email';
        btn_lbl.loading = false;
        btn_lbl._class = 'btn-green';
        btn_lbl.icon = 'ft ft-check';
        dispatch({type : TYPE.BTN_SUB, payload : btn_lbl});
        let message = 'Email sent to '+config.test_email+'. Please check Spam folder too!';
        test_result.show = true;
        test_result.status = true;
        test_result.message = message;
        dispatch({type : TYPE.EMAIL_SETTING_TEST_RESULT, payload : test_result});

    }).catch(err => {
        btn_lbl.test = 'Send Test Email';
        btn_lbl.loading = false;
        btn_lbl._class = 'btn-danger';
        btn_lbl.icon = 'ft ft-rotate-cw';
        dispatch({type : TYPE.BTN_SUB, payload : btn_lbl});
        let message = err.data.message != undefined ? err.data.message : 'Failed to load error message';
        test_result.show = true;
        test_result.status = false;
        test_result.message = message;
        dispatch({type : TYPE.EMAIL_SETTING_TEST_RESULT, payload : test_result});
    })
}

export const clickBtn = (e) => async (dispatch, getState ) => {
    let {btn_lbl} = getState().emailsetting;
    let btn = btn_lbl;
    if(e == 'submit')
    {
        btn[e] = 'Updating...';
    }
    else{
        btn[e] = 'Sending...';
    }
    dispatch({type : TYPE.BTN_SUB, payload : btn});
}

export const securityChange = (e) => async(dispatch, getState) =>{
    let {config} = getState().emailsetting;
    const old_config = config.require_password;
    let password = config.require_password ? false : true;
    config.require_password = password;
    dispatch({type : TYPE.EMAIL_SETTING_DATA, payload : config});
    EmailSettingService.securityConfig(config).then(res => {
        ToastSuccess('Email security configuration has been updated.');
    }).catch(err => {
        ToastDanger('Failed to update security configuration.');
        config.require_password = old_config;
        dispatch({type : TYPE.EMAIL_SETTING_DATA, payload : config});
    })
}


//  fetch brands
export const getLogs = (pageNumber, rows_per_page = null) => async (dispatch, getState) => {

    try {

        let { pagination, sort_order_name, sort_order_direction } = getState().emailsetting;
        let rows_per_page_val = rows_per_page !== null ? rows_per_page :  getNumRows('email_logs');
        
        dispatch(setLoading(true));

        let params = {
            // search,
            activePage: pagination.activePage,
            page: pageNumber,
            rows_per_page: rows_per_page_val, 
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction
        }
    
        const res = await EmailSettingService.getLogs(params);
        let log_data = [];
        res.data.logs.data.map((data, index) => {
            data.single_email = false;
            log_data.push(data);
        })
        let paginationParam = {
            totalCount: res.data.logs.total, 
            activePage: res.data.logs.current_page, 
            itemsCountPerPage: res.data.logs.per_page, 
            totalItemsCount: res.data.logs.total,
        }
        
        dispatch({ type: TYPE.EMAIL_SETTING_LOGS, payload: { logs: log_data, pagination: paginationParam } })
        dispatch(setLoading(false));

    } catch (err) {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
        console.log(err);
    }

}


// Sort by column name and direction
export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: TYPE.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getLogs());
}


export const setLoading = (status) => async dispatch => dispatch({ type: TYPE.SET_LOADING, payload: status });
export const setMiniLoading = (status) => async dispatch => dispatch({ type: TYPE.SET_MINI_LOADING, payload: status });

export const setModal = (modal, status, id = null) => async dispatch => {

    switch (modal) {
        case 'log_modal':
            dispatch({ type: TYPE.SET_MODAL, payload: { modal, status } });
            if(id){
                dispatch(getSingleLog(id));
            }
            break;
        default:
            dispatch({ type: TYPE.SET_MODAL, payload: { modal, status } });
            break;
    }
};


export const getSingleLog = (id) => async dispatch => {
    
    try {
        dispatch(setMiniLoading(true));
        
        const res = await EmailSettingService.getSingleLog(id);

        dispatch({ type: TYPE.EMAIL_SETTING_SINGLE_LOG, payload: res.data.log });
        dispatch(setMiniLoading(false));

    } catch (error) {
        dispatch(setMiniLoading(false));
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    }

}



export const emailConfig = () => async(dispatch) => {
    EmailSettingService.getEmailConfig().then(result => {
        console.log('get config');
        dispatch({type : TYPE.EMAIL_SETTING_DATA, payload : result.data});
    })
    .catch(err => {
        try{
            ToastDanger(err.data.message);
        }
        catch(error)
        {
            console.log("error", err);
            ToastDanger('Failed to load email configuration.');
        }
    })
}

const verfiyPass = (callback) => {
    SwallverfiyPass(function(password){
        let formdata = {
            password : password
        }
        EmailSettingService.verifyPassword(formdata).then(result => {
            SwalSuccessAutoRemove(result.data.message, callback('success'))
        })
        .catch(err => {
            console.log("err", err);
            try{
                SwalError(err.data.message);
            }
            catch(error)
            {
                console.log("error", error);
                SwalError('Unknown error occured')
            }
            // reject(err)
        })
    });
}

export const sendSingleEmail = (id) => async(dispatch, getState) => {
    dispatch({type : TYPE.SEND_EMAIL, payload: true});
    EmailSettingService.sendEmail(id).then(result => {
        ToastSuccess(result.data.message);
        dispatch({type : TYPE.SEND_EMAIL, payload: false});
        dispatch(setModal('log_modal', false));
    }).catch(err => {
        
        if(err.data == undefined)
        {
            ToastDanger('Failed to send email.');
        }
        else{
            ToastDanger(err.data.message);
        }
        
        dispatch({type : TYPE.SEND_EMAIL, payload: false});
    })
}

export const reSendEmail = (e, id) => async (dispatch, getState) => {
    SwalWarning("Are you sure?","Do you really want to resend this email?", () => dispatch(sendEmail(e, id)));
}


export const sendEmail = (e, id) => async (dispatch, getState) => {
    let {config} = getState().emailsetting;
    if(config.require_password)
    {
        verfiyPass(function(result){
            dispatch(sendSingleEmail(id))
        })
    }
    else{
        dispatch(sendSingleEmail(id))
    }
}


export const sendEmailDyanmic = (e, id, index) => async (dispatch, getState) => {
    // let {logs} = getState().emailsetting;
    // let data = logs[index];
    // if(data.email_sent)
    // {
        SwalWarning("Are you sure?","Do you really want to resend this email?", () => dispatch(sendByIndex(id, index)));
    // }
    // else
    // {
    //     dispatch(sendByIndex(id, index));
    // }
   
}

const sendByIndex = (id,index) => async (dispatch, getState) => {
    let {config} = getState().emailsetting;
    if(config.require_password)
    {
        verfiyPass(function(result){
            dispatch(sendEmailIndex(id, index))
        })
    }
    else{
        dispatch(sendEmailIndex(id, index))
    }
}

const sendEmailIndex = (id, index) => async(dispatch, getState) => {
    let {logs} = getState().emailsetting;
    let data = logs[index];
    data.single_email = true;
    logs[index] = data;
    dispatch({type : TYPE.DYNAMIC_SEND_EMAIL, payload : logs});
    EmailSettingService.sendEmail(id).then(result => {
        ToastSuccess(result.data.message);
        data.single_email = false;
        data.email_sent = 1;
        logs[index] = data;
        dispatch({type : TYPE.DYNAMIC_SEND_EMAIL, payload : logs});
    }).catch(err => {
        
        if(err.data == undefined)
        {
            ToastDanger('Failed to send email.');
            console.log("err", err)
        }
        else{
            ToastDanger(err.data.message);
        }
        data.sending_email = false;
        logs[index] = data;
        dispatch({type : TYPE.DYNAMIC_SEND_EMAIL, payload : logs});
    })
}


export const setPage = (id, type) => async(dispatch,getState) => {
    console.log("type", type);
    if(type == 'po')
    {
        let {edit_url} = getState().purchase_order
        dispatch(PoModal('print_modal',id))
    }
}