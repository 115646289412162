import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { getReceiving, handleSearchInput, deleteReceving, redirectTo, handleChangeStatusView } from '../../redux/actions/receiving/receiving.actions';
import ReactTooltip from 'react-tooltip';
import { customAlert } from '../../Utils/Confirm';
import ReceivingTable from './Receiving.table';
import {  Show, useAccess } from "react-redux-permission";
import { Link, Redirect } from 'react-router-dom';


function Receiving ({ receiving: { receivings, pagination, search, loading, redirect }, getReceiving, handleSearchInput, deleteReceving, redirectTo, handleChangeStatusView }) {

    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Receiving </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item "><Link to="#" className="text-white">Lists</Link></li>
                                </ol>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className='content-body margin-top-5'>
                <section id='basic-examples'>
                <div className='row'>
                    <div className='col-sm-12'>
                    <div id='what-is' className=''>
                        <div className='card-content collapse show'>
                        <div className='card-body no-padding-top no-padding-left-right'>
                            <section className='row'>
                            <div className='col-sm-12'>
                                <ul className='nav nav-tabs f-regular'>
                                {/* old params 'all', '1', '2'. 're-stock' */}
                                <li className=''>
                                    <a
                                    onClick={() => handleChangeStatusView("all")}
                                    className='nav-link active'
                                    id='base-tab1'
                                    data-toggle='tab'
                                    aria-controls='tab1'
                                    href='#tabs'
                                    aria-expanded='true'
                                    >
                                    Receiving list
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() => handleChangeStatusView("draft")}
                                    className='nav-link'
                                    id='base-tab2'
                                    data-toggle='tab'
                                    aria-controls='tab2'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Draft
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() =>
                                        handleChangeStatusView("saved")
                                    }
                                    className='nav-link'
                                    id='base-tab3'
                                    data-toggle='tab'
                                    aria-controls='tab3'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Saved
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() =>
                                        handleChangeStatusView("cancelled")
                                    }
                                    className='nav-link'
                                    id='base-tab3'
                                    data-toggle='tab'
                                    aria-controls='tab3'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Cancelled
                                    </a>
                                </li>
                                </ul>
                                <div className='tab-content px-1 pt-1 card-box-top card'>
                                <div
                                    role='tabpanel'
                                    className='tab-pane active table-responsive '
                                    id='tabs'
                                    aria-expanded='true'
                                    aria-labelledby='base-tab1'
                                >
                                <Show when="inv.receiving.itemReceipts.view">
                                    <div className="content-body margin-top-5">
                                        <section className="row">
                                            <div className="col-sm-12">
                                                <div id="what-is" className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">List Of Item Receipts <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                                                            <Show when="inv.receiving.itemReceipts.create"><Link to="/receivings/create"  className="btn btn-primary pull-right btn-sm"><i className="ft ft-plus"></i> Create New</Link></Show>
                                                        </h4>
                                                    </div>
                                                    <div className="card-content collapse show">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-12 ">
                                                                    <ReceivingTable />  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </Show>
                                </div>
                                </div>
                            </div>
                            </section>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    receiving: state.receiving
});

export default connect(mapStateToProps, { getReceiving, handleSearchInput, deleteReceving, redirectTo, handleChangeStatusView })(Receiving);
