import {
  SET_LOADING,
  GET_SINGLE_SUPPLIER,
  SUPPLIER_INPUT_CHANGE,
  CLEAR_SUPPLIER,
  SEARCH_INPUT,
  GET_SUPPLIERS,
  SELECT_COUNTRY,
  HANDLE_MOBILE,
  SET_PAGE,
  GET_SUPPLIER_HISTORY_PURCHASE_ORDERS,
  SUPPLIER_HISTORY_DATE_FILTER_INPUT,
  SUPPLIER_HISTORY_PURCHASE_ORDER_DATE_FILTER,
  SUPPLIER_HISTORY_DATE_INPUT_CLEAR,
  SET_MINI_LOADING,
  GET_SUPPLIER_HISTORY_PRODUCTS,
  GET_SUPPLIER_HISTORY_SINGLE_PO,
  GET_SUPPLIER_HISTORY_SINGLE_PRODUCT,
  SET_PAYMENT_DETAILS,
  SORT_BY_COLUMN,
  GET_ROWS_TO_DELETE,
  SET_ROW_CHECKED,
} from "../../types";
import { ToastSuccess, ToastDanger } from "../../../Services/_toast.service";
import $ from "jquery";
import { SupplierService } from "./_service.supplier";
import { SwalWarning, SwalWarningHtml } from "../../../Services/_swal.service";
import { getSelectAll, setModal, handleSelectSupplier } from "../product/product.actions";
import {
  setModal as setModalPO,
  getSupplier as getSupplierPO,
  getPurchaseOrders,
  handleSelectSupplier as handleSelectSuppplierPO,
} from "../purchase_order/purchase_order.actions";
import { setModal as setModalRE, getSupplier as getSupplierRE } from "../receiving/receiving.actions";
import { blockRoute, setMUIPage } from "../helper/helper.actions";
import { getNumRows } from "../../../Utils/Common";

// handle Inputs
export const handleInputChange = (e) => async (dispatch) => {
  if (e.target.value) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({
    type: SUPPLIER_INPUT_CHANGE,
    payload: { key: e.target.name, value: e.target.value },
  });
};

// handle search
export const handleSearchInput = (e) => async (dispatch) => {
  dispatch({ type: SEARCH_INPUT, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) => dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) => dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearSupplier = () => async (dispatch) => dispatch({ type: CLEAR_SUPPLIER });

// country select
export const handleSelectCountry = (selectOption) => async (dispatch) => {
  if (selectOption) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({ type: SELECT_COUNTRY, payload: selectOption });
};

// handle mobile
export const handleMobile = (mobile) => async (dispatch) => {
  dispatch({ type: HANDLE_MOBILE, payload: mobile });
};

// set page
export const setPage =
  (page, status = true) =>
  async (dispatch) => {
    dispatch({ type: SET_PAGE, payload: { page, status } });
  };

// fetch suppliers
export const getSuppliers =
  (pageNumber, rows_per_page = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    let { search, pagination, sort_order_name, sort_order_direction } = getState().supplier;
    let rows_per_page_val = rows_per_page != null ? rows_per_page : getNumRows("supplier");

    let searchPostParams = {
      // search: search,
      // activePage: pagination.activePage,
      // page: pageNumber,
      // rows_per_page: rows_per_page_val,
      // sort_order_name: sort_order_name,
      // sort_order_direction: sort_order_direction
      search,
      options: {
        page: pageNumber ? pageNumber : pagination.activePage,
        limit: rows_per_page_val ? rows_per_page_val : 10,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };

    SupplierService.paginateWithSearchSuppliers(searchPostParams)
      .then((res) => {
        dispatch({
          type: GET_SUPPLIERS,
          payload: {
            suppliers: res.data.docs,
            pagination: {
              //   totalCount: res.data.suppliers.total,
              //   activePage: res.data.suppliers.current_page,
              //   itemsCountPerPage: res.data.suppliers.per_page,
              //   totalItemsCount: res.data.suppliers.total,
              totalCount: res.data.total_docs,
              activePage: res.data.page,
              itemsCountPerPage: res.data.limit,
              totalItemsCount: res.data.total_docs,
            },
          },
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        // if (error.data.errors.length > 0) {
        //   error.data.errors.map((err) => ToastDanger(err));
        // } else {
        //   ToastDanger("Network error, please reload the page.");
        // }
        dispatch(setLoading(false));

        // $(".btn-save").removeAttr("disabled").html("Save");
        console.log(error);
      });
  };

// fetch single supplier
export const getSingleSupplier =
  (id, status = null) =>
  async (dispatch) => {
    if (!status) {
      dispatch(setLoading(true));
    }

    SupplierService.getSingleSupplier(id)
      .then((res) => {
        dispatch({ type: GET_SINGLE_SUPPLIER, payload: res.data.supplier });
        dispatch({
          type: SET_PAYMENT_DETAILS,
          payload: res.data.supplier.account_details ? res.data.supplier.account_details : [],
        });

        if (status == "delete") {
          dispatch(archive());
        }

        if (status == "history") {
          let id_params = { supplier_id: id };
          // dispatch(getPurchaseOrders(1, id_params));
        }

        if (!status) {
          dispatch(setLoading(false));
        }
      })
      .catch((error) => {
        if (error.data.errors.length > 0) {
          error.data.errors.map((err) => ToastDanger(err));
        } else {
          ToastDanger("Network error, please reload the page.");
        }

        // $(".btn-save").removeAttr("disabled").html("Save");
        console.log(error);
        // ToastDanger("Network error, please reload the page.");
        // console.log(error);
        if (!status) {
          dispatch(setLoading(false));
        }
      });
  };

// store
export const supplierPost = (e, pageOrigin) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_supplier, payment_details } = getState().supplier;

  var error_count = 0;

  payment_details.map((payment_detail, index) => {
    let line = index + 1;
    if (payment_detail.mode === "edit") {
      ToastDanger("Please save account details #" + line + " before proceeding");
      error_count++;
    }
  });

  if (error_count >= 1) {
    return false;
  }

  single_supplier.payment_details = payment_details;

  $(".btn-save").attr("disabled", "disabled").html("Saving...");
  dispatch(setMUIPage(1));
  SupplierService.supplierPost(single_supplier)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          if (pageOrigin == "supplier") {
            dispatch(setPage("main_page"));
          }

          if (pageOrigin == "purchase_order") {
            dispatch(setModalPO("supplier_modal", false));
            dispatch(getSupplierPO());
            let selectPOSupplier = {
              value: res.data.supplier._id,
              label: res.data.supplier.name,
            };
            let supplier_item_only = false;
            dispatch(handleSelectSuppplierPO(selectPOSupplier, supplier_item_only));
          }

          if (pageOrigin == "item") {
            dispatch(getSelectAll());
            dispatch(setModal("supplier_modal", false));
            let supplierSelect = [{ value: res.data.supplier._id, label: res.data.supplier.name }];
            dispatch(handleSelectSupplier(supplierSelect));
          }

          if (pageOrigin == "receiving") {
            dispatch(getSupplierRE());
            dispatch(setModalRE("supplier_modal", false));
          }

          dispatch(clearSupplier());
          ToastSuccess(res.data.message);
          break;
        default:
          break;
      }

      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        ToastDanger("Network error, please reload the page.");
      }

      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    });
};

// update
export const supplierUpdate = (e) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_supplier, payment_details } = getState().supplier;

  var error_count = 0;

  payment_details.map((payment_detail, index) => {
    let line = index + 1;
    if (payment_detail.mode === "edit") {
      ToastDanger("Please save account details #" + line + " before proceeding");
      error_count++;
    }

    payment_detail.created_at = "";
    payment_detail.updated_at = "";
    payment_detail.user_updated = "";
  });

  if (error_count >= 1) {
    return false;
  }

  let formParams = {
    name: single_supplier.name,
    business_name: single_supplier.business_name || "",
    street1: single_supplier.street1 || "",
    street2: single_supplier.street2 || "",
    city: single_supplier.city || "",
    state: single_supplier.state || "",
    country: single_supplier.country || "",
    landline: single_supplier.landline || "",
    mobile: single_supplier.mobile || "",
    fax: single_supplier.fax || "",
    email: single_supplier.email || "",
    contact_person: single_supplier.contact_person || "",
    payment_details: payment_details,
  };

  // update post api
  $(".btn-save").attr("disabled", "disabled").html("Saving...");
  SupplierService.updateSingleSupplier(formParams, single_supplier._id)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          dispatch(setPage("main_page"));
          ToastSuccess(res.data.message);
          dispatch(clearSupplier());
          break;
        default:
          break;
      }
      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        ToastDanger("Network error, please reload the page.");
      }

      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
      // ToastDanger("Network error, please reload the page.");
      // $(".btn-save").removeAttr("disabled").html("Save");
      // console.log(error);
    });
};

// archive
export const moveToArchives = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().supplier;
  let id_arr = [];
  rows_to_delete.map((row) => {
    id_arr.push(row.id);
  });

  let params = {
    ids: JSON.stringify(id_arr),
  };

  SupplierService.ArchiveSupplier(params)
    .then((res) => {
      dispatch(setRowChecked());
      ToastSuccess(res.data.message);
      dispatch(clearSupplier());
      dispatch(getSuppliers());
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        ToastDanger("Network error, please reload the page.");
      }

      // $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
      // ToastDanger("Network error, please reload the page.");
      // console.log(error);
    });
};

export const archive = () => async (dispatch, getState) => {
  let { single_supplier } = getState().supplier;

  SwalWarning("Warning!", `Do you want to remove Supplier: ${single_supplier.name}?`, () => dispatch(moveToArchives()));
};

/**************************** =============================== *****************************/

/**************************** SUPPLIER HISTORY ACTIONS BELOW *****************************/

/**************************** =============================== *****************************/

// TOGGLE TABS [ PUCHASE ORDERS, PRODUCTS ]
export const handleChangeTab = (tab, id) => async (dispatch, getState) => {
  // let { display_tab } = getState().supplier

  let id_params = { supplier_id: id };

  switch (tab) {
    case "purchase_orders":
      // dispatch(getPurchaseOrders(1, id_params));
      break;
    case "items":
      // dispatch(getProductsPaginate(1, id));
      break;
    default:
      break;
  }
};

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
  dispatch({
    type: SUPPLIER_HISTORY_DATE_FILTER_INPUT,
    payload: { key: [e.target.name], value: e.target.value },
  });
};

// HANDLE SUBMIT DATE FILTER FOR [ PURCHASE ORDER, PRODUCTS ]
export const handleDateFilterSubmit = (e, filterBy) => async (dispatch, getState) => {
  e.preventDefault();

  let { date_to, date_from, single_supplier } = getState().supplier;

  const today = new Date();

  try {
    if (new Date(date_from).getTime() >= today.getTime() || new Date(date_to).getTime() >= today.getTime()) {
      ToastDanger("Your Date value can not be greater than today.");
    } else {
      console.log("supplier_id:", single_supplier._id)

      let pageOrigin = {
        supplier_id: single_supplier._id,
        origin: "supplier_date_filter",
      };
      let date_filter = { date_from: date_from, date_to: date_to };

      // CHECK TYPE OF INVOICE
      switch (filterBy) {
        case "purchase_orders":
          dispatch(getPurchaseOrders(1, null, pageOrigin, date_filter));
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(`Error: ${error}`);
    ToastDanger("Something went wrong please contact your admin...");
  }
};

// DATE INPUT CLEAR
export const dateFilterClear = (type, id) => async (dispatch) => {
  dispatch({ type: SUPPLIER_HISTORY_DATE_INPUT_CLEAR, payload: type });
  let id_params = { supplier_id: id };

  switch (type) {
    case "purchase_orders":
      // dispatch(getPurchaseOrders(1, id_params ));
      break;
    case "items":
      break;
    default:
      break;
  }
};

export const handleAddPayment = (payment_details, default_payment_details_) => async (dispatch, getState) => {
  let new_payment_details = payment_details.concat({
    bank_name: "",
    bank_address: "",
    account_number: "",
    account_name: "",
    swift_code: "",
    mode: "edit",
    has_saved: 0,
  });
  dispatch({ type: SET_PAYMENT_DETAILS, payload: new_payment_details });
};

export const handleRemovePayment = (payment_details, key) => async (dispatch, getState) => {
  payment_details = payment_details.filter((item, item_key) => key !== item_key);
  dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
};

export const handChangePaymentInput = (key, e, payment_details) => async (dispatch, getState) => {
  payment_details[key][e.target.name] = e.target.value;
  dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
};

export const savePayment = (e, payment_details, key, mode) => async (dispatch, getState) => {
  e.preventDefault();
  payment_details[key].mode = mode;
  payment_details[key].has_saved = 1;
  dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
};

export const handleRemovePaymentAPI = (payment_details, key) => async (dispatch, getState) => {
  if (payment_details[key]._id) {
    dispatch(setLoading(true));
    SupplierService.supplieAcountDelete({ _id: payment_details[key]._id })
      .then((res) => {
        payment_details = payment_details.filter((item, item_key) => key !== item_key);
        dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.data.errors.length > 0) {
          error.data.errors.map((err) => ToastDanger(err));
        } else {
          ToastDanger("Network error, please reload the page.");
        }

        // $(".btn-save").removeAttr("disabled").html("Save");
        console.log(error);
        dispatch(setLoading(false));
      });
  } else {
    payment_details = payment_details.filter((item, item_key) => key !== item_key);
    dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
  }
};

export const savePaymentApi = (e, payment_details, key, mode, supplier_id) => async (dispatch, getState) => {
  e.preventDefault();
  payment_details[key].mode = mode;
  payment_details[key].has_saved = 1;
  payment_details[key].supplier_id = supplier_id;

  SupplierService.supplieAcountUpdate(payment_details[key])
    .then((res) => {
      // payment_details  = payment_details.filter((item, item_key) => key !== item_key);
      dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
      dispatch(setLoading(false));
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        ToastDanger("Network error, please reload the page.");
      }

      // $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
      // ToastDanger("Network error, please reload the page.");
      // console.log(error);
      dispatch(setLoading(false));
    });

  // dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
};

// returns selected rows only
export const getSelectedRows = (selectedRows, currentRows) => async (dispatch) => {
  // console.log(selectedRows, currentRows)
  let newArray = [];

  currentRows.map((current, i) => {
    selectedRows.map((selected, _i) => {
      if (selected.index == current.index) {
        let selected = { id: current.data[0], name: current.data[1] };
        newArray.push(selected);
      }
    });
  });
  // console.log(newArray);
  dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
};

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().customer;
  let names = "";
  let name_arr = [];
  rows_to_delete.map((rows, i) => {
    name_arr.push("<b>" + rows.name + "</b>");
  });
  names = name_arr.join(", ");

  SwalWarningHtml("Warning!", `Do you want to remove the Supplier(s): ${names}?`, () => dispatch(moveToArchives()));
};

// Sort by column name and direction
export const sortTableByColumn = (sort_order_name, sort_order_direction) => async (dispatch) => {
  let sortingParams = {
    sort_order_name: sort_order_name,
    sort_order_direction: sort_order_direction,
  };

  dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
  dispatch(getSuppliers());
};

export const handleSearchInputMUI = (e) => async (dispatch) => {
  dispatch({ type: SEARCH_INPUT, payload: e.target.value });
  if (e.key === "Enter") {
    console.log("enter");
    // dispatch(getPurchaseOrders(1));
  }
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) => dispatch({ type: SET_ROW_CHECKED });
