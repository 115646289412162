import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { termConditionPost, handleInputChange, toggleDrawer, clearTermCondition } from '../../../../redux/actions/term_condition/term_condition.actions';
import ReactTooltip from 'react-tooltip';
import RestoreIcon from '@material-ui/icons/Restore';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

function TermConditionCreateGlobal({ handleInputChange, termConditionPost, toggleDrawer, pageOrigin, clearTermCondition }) {

    useEffect(() => {
        clearTermCondition();
    }, []);

    return (
        <form onSubmit={e => termConditionPost(e, pageOrigin)}>
            <ReactTooltip  effect="solid" event="click"/>
            <section className="row f-regular">
                <div className="col-12">
                    {/* <label className="label-control f-regular">Name: <span className='text-danger'>*</span></label> */}
                    <div className="form-group row">
                        <div className="col-md-11 col-10">
                            <input onChange={handleInputChange} type="text" name="title" className="form-control f-regular" placeholder="Enter Title" required/>
                        </div>
                        <div className="float-right pl-0 col-1 col-md-1">
                            <div className="input-group-prepend">
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group row">
                        <div className="col-md-4 col-12 label-control f-regular"></div>
                        <div className="col-md-7 col-10 text-right">
                            { pageOrigin === 'setting_app' && <button onClick={() => toggleDrawer(true, pageOrigin)} type="button" className="btn btn-sm terms-toggle f-regular mr-1 text-white"><FormatListBulletedIcon/></button> }
                            <button type="submit" className="btn btn-primary btn-sm btn-save f-regular">Save</button>
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1"></div>  
                    </div>
                </div>
            </section>
        </form>
    )
}

const mapStateToProps = state => ({
	term_condition: state.term_condition
});

export default connect(mapStateToProps, { handleInputChange, termConditionPost, toggleDrawer, clearTermCondition })(TermConditionCreateGlobal);

