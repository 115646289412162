import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// import SalesOrderPrintModal from './modals/SalesOrder.modal.print';
import { setMUIPage } from '../../redux/actions/helper/helper.actions';
import { Show, useAccess } from 'react-redux-permission';
import { Link, useParams, useLocation } from 'react-router-dom';
import StockLogTable from '../returns/StockLog.table';
import ReturnsTable from './Returns.table';

function Returns({ returns: { edit_url, edit_page, stock_logs }, muiPage }) {
    let params = new URLSearchParams(useLocation().search);
    const [selectedTab, SetSelectedTab] = useState('Items');
    const [tabs, setTabs] = useState([
        {
            key: 'Transaction Search',
            class: 'nav-link inv-top-nav inv-top-nav-active',
            _status: 1,
            permission: 'inv.inventory.returns.stock-log',
        },
        {
            key: 'Completed Returns',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.inventory.returns.view',
        },
    ]);

    function handleChangeTab(tab) {
        setMUIPage(1);
        SetSelectedTab(tab);
        tabs.map((list) => {
            list.class =
                list.key == tab
                    ? 'nav-link inv-top-nav inv-top-nav-active'
                    : 'nav-link inv-top-nav';

            return list;
        });
        setTabs(tabs);
    }

    useEffect(() => {
        let tab = params.get('mode') || 'Transaction Search'; // Default tab
        handleChangeTab(tab); // Explicitly handle the tab change
        setMUIPage(muiPage);
    }, []);

    // useMemo(() => {
    //     getSalesOrders();
    // },[])

    if (edit_page) return <Redirect to={edit_url} />;

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Returns{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a href="#" className="text-white">
                                            Stock Returns
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header"></div> */}
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                        <div className="">
                                            <nav class="nav f-regular nav-product-inv">
                                                {tabs.map((item) => (
                                                    <Show
                                                        when={item.permission}
                                                    >
                                                        <a
                                                            onClick={() =>
                                                                handleChangeTab(
                                                                    item.key
                                                                )
                                                            }
                                                            class={item.class}
                                                        >
                                                            {item.key}
                                                        </a>
                                                    </Show>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                List
                                                <a
                                                    href="#"
                                                    className="tool-tip"
                                                    data-tip="this is a tool tip"
                                                >
                                                    <i className="la la-question-circle"></i>
                                                </a>
                                            </h4>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <section className="row f-regular">
                                                    {(() => {
                                                        switch (selectedTab) {
                                                            case 'Transaction Search':
                                                                return (
                                                                    <StockLogTable />
                                                                );
                                                            case 'Completed Returns':
                                                                return (
                                                                    <ReturnsTable />
                                                                );
                                                            default:
                                                                return null;
                                                        }
                                                    })()}
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    returns: state.returns,
});

export default connect(mapStateToProps, {})(Returns);
