import React, { Fragment, useState } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import DatePicker from "react-datepicker";
import { customAlert } from '../../Utils/Confirm';
import { map } from "jquery";
import {  Show, useAccess } from "react-redux-permission";

function AdjustmentCreate ({ }) {

    const item_receipt = [
        {
            sku : '012344',
            lot : '00001',
            items : 'T3 Gloves',
            attr : 'Color : Blue, Size : Small',
            unit : 'pc',
            order : 48,
            received : 47,
            balance : '-1'
        },
        {
            sku : '012345',
            lot : '00001',
            items : 'T3 Gloves',
            attr : 'Color : Blue, Size : Small',
            unit : 'pc',
            order : 48,
            received : 48,
            balance : '0'
        },
        {
            sku : '012346',
            lot : '00001',
            items : 'T3 Gloves',
            attr : 'Color : Blue, Size : Small',
            unit : 'pc',
            order : 48,
            received : 46,
            balance : '-2'
        }
    ]

    const item_adj = [
        {
            sku : '012344',
            lot : '00001',
            items : 'T3 Gloves',
            attr : 'Color : Blue, Size : Small',
            unit : 'pc',
            order : 48,
            received : 47,
            balance : 1
        },
        
        {
            sku : '012346',
            lot : '00001',
            items : 'T3 Gloves',
            attr : 'Color : Blue, Size : Small',
            unit : 'pc',
            order : 48,
            received : 46,
            balance : 2
        }
    ]

    const [report_date, setRerportDate] = useState(new Date());
    
    return (
        
        <div className="app-content content">
            <ReactTooltip  effect="solid"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Receiving Stock Adjustment Report</h3>

                      
                    </div>
                </div>
                <div className="content-body margin-top-5">
                  <section className="row">
                      <div className="col-sm-12">
                          <div className="card">
                              <div className="">
                                  <div className="card-body">
                                      <div className="row f-regular">
                                            <div className="col-md-4">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Status : </label>&nbsp;<span className="lbl-danger lbl-status">New</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Report Date</label>
                                                            <div className="date-picker-100">
                                                                <DatePicker onChange={date=> setRerportDate(date)} selected={report_date} className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Item Receipt #</label>
                                                            <select className="form-control">
                                                                <option value="">Select Item Receipt</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Supplier</label>
                                                            <input type="text" className="form-control" readOnly/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Delivery Date</label>
                                                            <input type="text" className="form-control" readOnly/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Reference No.</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-condensed table-bordered f-regular">
                                                        <thead>
                                                            <tr>
                                                                <th>SKU</th>
                                                                <th>Lot #</th>
                                                                <th>Items</th>
                                                                <th>Attributes</th>
                                                                <th>Unit</th>
                                                                <th>Qty Ordered</th>
                                                                <th>Qty Received</th>
                                                                <th>Balance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                item_receipt.map((data, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{data.sku}</td>
                                                                            <td>{data.lot}</td>
                                                                            <td>{data.items}</td>
                                                                            <td>{data.attr}</td>
                                                                            <td>{data.unit}</td>
                                                                            <td className="text-right">{data.order}</td>
                                                                            <td className="text-right">{data.received}</td>
                                                                            <td className="text-right">{data.balance}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                      </div>
                                   
                                  </div>

                              </div>
                          </div>
                      </div>
                      <div className="col-md-12">
                          <div className="card">
                              <div className="card-body">
                                  <div className="row">
                                      <div className="col-md-12">
                                          <div className="form-group">
                                            <button className="btn btn-primary text-white">Adjustment Report</button>
                                          </div>
                                      </div>
                                      <div className="col-md-12">
                                          <div className="table-responsive">
                                              <table className="table table-condensed table-bordered f-regular">
                                                  <thead>
                                                      <tr>
                                                          <th>SKU</th>
                                                          <th>Lot #</th>
                                                          <th>Items</th>
                                                          <th>Attributes</th>
                                                          <th>Reason</th>
                                                          <th>Adjustment</th>
                                                          <th>Action</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {
                                                          item_adj.map((data, key) => {
                                                              return (
                                                              <tr key={key}>
                                                                  <td>
                                                                      {data.sku}
                                                                  </td>
                                                                  <td>{data.lot}</td>
                                                                  <td>{data.items}</td>
                                                                  <td>{data.attr}</td>
                                                                  <td>
                                                                      <select className="form-control">
                                                                          <option value="">Damage</option>
                                                                          <option value="">Missing</option>
                                                                          <option value="">Theft</option>
                                                                          <option value="">Wrong Item</option>
                                                                          <option value="">Return</option>
                                                                          <option value="">Change SKU</option>
                                                                          <option value="">Cancelled</option>
                                                                      </select>
                                                                  </td>
                                                                  <td>
                                                                      <input type="number" value={data.balance}  className="form-control text-right"/>
                                                                  </td>
                                                                  <td>
                                                                      <select className="form-control">
                                                                          <option value="">Return Stock</option>
                                                                          <option value="">Return Supplier</option>
                                                                          <option value="">Write-off</option>
                                                                          <option value="">Change SKU</option>
                                                                      </select>
                                                                  </td>
                                                              </tr>
                                                              )
                                                          })
                                                      }
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="card-footer">
                                <div className="row justify-content-end mt-3">
                                    <div className="mr-2">
                                        <div className="form-group">
                                            <Show when="inv.receiving.adjustment.draft"><button className="btn btn-outline-light text-dark " type="button">Draft</button></Show>
                                            <button className="btn btn-primary text-white margin-l-5" type="button" >Save</button>
                                            {/* <a className="btn btn-danger text-white margin-l-5" href="/receivings">Cancel</a> */}
                                        </div>
                                    </div>
                                </div>
                             </div>
                          </div>
                      </div>
                  </section>
              </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    
});

export default connect(mapStateToProps, { })(AdjustmentCreate);
