import React, { Fragment, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { hideModal, setProcess, perSupplierChange} from "../../../redux/actions/inventory/inventory.actions";
import AsyncSelect from 'react-select/async';
import {  handleSelectSupplier, SearchSupplier, getSupplier } from '../../../redux/actions/purchase_order/purchase_order.actions';

function InventoryMultiSuppler( {
    inventory : {show_modal, po_per_supplier},
    purchase_order : {loading, suppliers_default}, 
    hideModal, setProcess, SearchSupplier, handleSelectSupplier, getSupplier, perSupplierChange}) {
    useEffect(()=>{
        getSupplier()
    },[])
    return (
        
        <Modal centered dialogClassName="modal-container" show={show_modal.po} onHide={(e) => hideModal(e, 'po')} backdrop="static" size="md" animation={true}>
            <Modal.Header closeButton> <Modal.Title>Process list item(s)</Modal.Title> </Modal.Header>
            <Modal.Body className="overflow-unset">
                <div className="row">
                    <ReactTooltip  effect="solid" event="click"/>
                    <div className="col-md-12">
                        <div className="form-group">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" className="form-check-input" checked={po_per_supplier} onChange={(e) => perSupplierChange(e)} /> Generate P.O. by Supplier 
                                </label> 
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>
                        {
                            po_per_supplier ? 
                            <div className="form-group"></div>:
                            <div className="form-group">
                                <label>Select Supplier</label>
                                <AsyncSelect isLoading={loading} loadingMessage={() => 'searching...'} defaultOptions={suppliers_default} loadOptions={SearchSupplier} isSearchable={true} cacheOptions onChange={handleSelectSupplier} placeholder="Select Supplier..." required/>
                            </div>
                        }
                        
                    </div>
                </div>
               
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-grey f-regular btn-md" onClick={(e) => hideModal(e, 'po')}>Cancel</button>
                <button className="btn-primary btn f-regular btn-save btn-md" onClick={(e) => setProcess()}>Ok</button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => ({
	inventory: state.inventory,
    purchase_order: state.purchase_order,
});

export default connect(mapStateToProps, {hideModal, setProcess, SearchSupplier, handleSelectSupplier, getSupplier, perSupplierChange})(InventoryMultiSuppler);

