import React, {Fragment, useEffect } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getCategories, getSingleCategory, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox } from '../../redux/actions/category/category.actions';
import { Redirect, useHistory } from 'react-router-dom';
import { formattedDate } from '../../Utils/Common';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';
import moment from 'moment';


function Category ({ category: { loading, pagination, search, categories, create_url, edit_url, sort_order_name, sort_order_direction, set_row_checked }, getCategories, getSingleCategory, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox }) {

    const { hasPermission } = useAccess();
    const canCreate = hasPermission("inv.products.categories.create");
    const canDelete = hasPermission("inv.products.categories.delete");
    const canEdit = hasPermission("inv.products.categories.edit");
    const canview = hasPermission("inv.products.categories.view");

    const history = useHistory();

	useEffect(() => {
        if(canview)
        {
            getCategories();
        }
    },[search]);
    
    const changePage = link => history.push(link);


    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MUIDataTableBodyCell: {},
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(3)': {
                        width: '150px'
                    }
                }
            }
        }
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'name', label: 'Name' }, 
        {name: 'parent_category_id', label: 'Parent Category', options: {
                filter: false, print: false, download: false,
                customBodyRender: (value) => (
                    <span>{value == 0 ? 'NO' : 'YES'}</span>
                )
            } 
        }, 
        {name: 'description', label: 'Description', options: {
                setCellProps: (value) => ({
                    style: {
                        'minWidth':'100px'
                    }
                }),
            } 
        }, 
        {name: 'created_at', label: 'Created at', options: { 
                print: false , download: false ,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let created_at = categories[tableMeta['rowIndex']].created_at;
                    
                    return (    
                        <>{moment(created_at).format('YYYY-MM-DD - hh:mmA')}</>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            }
        },
        {name: 'updated_at', label: 'Updated at', options: { print: false , download: false,
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    let updated_at = categories[tableMeta['rowIndex']].updated_at;
                    
                    return (    
                        <>{moment(updated_at).format('YYYY-MM-DD - hh:mmA')}</>
                    )
                },
            } 
        },
    ];

  

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        filter : false,
        rowsPerPageOptions: [10,20,50,100],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `attribute_templates_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
          getCategories(null, numberOfRows)
        },
        onTableChange: (action, tableState) => {
            console.log(action, tableState)
            switch (action) {
                case 'changePage':
                  getCategories(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if(canDelete)
            {
                confirmDeleteDialogBox();
            }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page    
            if(canEdit)
            {
               changePage(`${edit_url}/${rowData[0]}`); 
            }
            
        }
    };

    if(!canDelete)
    {
        options.selectableRows = false;
    }

    
    return (
        <Fragment>
        <Redirect to="/inventory/products?mode=Categories"/>
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block"> Product</h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item f-regular">
                                        <a href="/category" className="text-white"> Categories</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <Show when="inv.products.categories.create">
                            <Link to={create_url} className="btn btn-primary pull-right btn-fix-header"><i className="ft ft-plus"></i> Create New</Link>
                        </Show>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">List of Categories <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                        <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                                        <div className="heading-elements">
                                            <ul className="list-inline mb-0"></ul>
                                        </div>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                        <Show when="inv.products.categories.view">
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <MUIDataTable data={categories} columns={columns} options={options}/>
                                            </MuiThemeProvider> 
                                        </Show>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    category: state.category
});

export default connect(mapStateToProps, { getCategories, getSingleCategory, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox })(Category)