import React, { Fragment, useEffect } from "react";
import Spinner from "../../../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { getTermsConditions, handleSearchInput, setPage } from '../../../../redux/actions/term_condition/term_condition.actions';
import ReactTooltip from 'react-tooltip';
import { Redirect } from "react-router-dom";

function TermCondition ({ term_condition: { terms_conditions, pagination, search, loading, create_url, edit_page, edit_url }, getTermsConditions, handleSearchInput, setPage }) {

    useEffect(() => {
        getTermsConditions();
    },[search]);

    if(edit_page) return <Redirect to={edit_url} />

    return (
        <section id="basic-examples">
            <ReactTooltip effect="solid" event="click"/>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">List of Payment Terms <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span></h4>
                            <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                            <div className="heading-elements">
                                <ul className="list-inline mb-0"> </ul>
                            </div>
                            <a href={create_url} className="btn btn-primary pull-right btn-fix-header"><i className="ft ft-plus"></i> Create New</a>
                        </div>
                        <div className="card-content collapse show">
                            <div className="card-body">
                                <section className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover table-bordered mb-0 mt-0">
                                                <thead>
                                                   <tr>
                                                        <td colSpan="7">
                                                            <div className="pull-left"> <h4><b>Total: {pagination.totalCount}</b></h4> </div>
                                                            <div className="form-inline pull-right">
                                                                <input onChange={handleSearchInput} name="search" type="text" className="form-control input-sm" placeholder="Search here..." />
                                                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                            </div>
                                                        </td>
                                                    </tr>               
                                                </thead>
                                            </table>
                                        </div>
                                        {
                                            loading ? <Spinner/> : 
                                                terms_conditions.length > 0 ? 
                                                    <Fragment>
                                                        <table className="table table-striped table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Title</th>
                                                                    <th>Content</th>
                                                                    <th>Created at</th>
                                                                    <th>Updated at</th>
                                                                    <th width="5%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    terms_conditions.map(term_condition => (
                                                                        <tr key={term_condition._id}>
                                                                            <td onClick={() => setPage('edit_page', term_condition._id)} className="row-style-hover">{term_condition.title}</td>
                                                                            <td onClick={() => setPage('edit_page', term_condition._id)} className="row-style-hover" width="30%">{term_condition.content}</td>
                                                                            <td onClick={() => setPage('edit_page', term_condition._id)} className="row-style-hover">{term_condition.created_at}</td>
                                                                            <td onClick={() => setPage('edit_page', term_condition._id)} className="row-style-hover">{term_condition.updated_at}</td>
                                                                            <td>
                                                                                <div className="btn-group">
                                                                                    <button className="btn btn-sm btn-info btn-sm" onClick={() => setPage('edit_page', term_condition._id)}> <i className="ft ft-edit"></i> </button>   
                                                                                    <button className="btn btn-sm btn-danger btn-sm" onClick={() => setPage('delete_modal', term_condition._id)}><i className="ft ft-trash"></i></button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>

                                                        {
                                                            pagination.totalItemsCount > 10 && 
                                                            <div className="d-flex justify-content-center">
                                                                <Pagination
                                                                    className="pagination"
                                                                    itemClass='page-item'
                                                                    linkClass='page-link'
                                                                    activePage={pagination.activePage}
                                                                    itemsCountPerPage={pagination.itemsCountPerPage}
                                                                    totalItemsCount={pagination.totalItemsCount}
                                                                    pageRangeDisplayed={pagination.pageRangeDisplayed}
                                                                    onChange={getTermsConditions}
                                                                />
                                                            </div>
                                                        }        
                                                    </Fragment>
                                                    :  <h1 align="center" className="my-5">-- No Data --</h1>   

                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    term_condition: state.term_condition
});

export default connect(mapStateToProps, { getTermsConditions, handleSearchInput, setPage })(TermCondition);
