import React, { Fragment, useEffect, useMemo } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {  Show, useAccess } from "react-redux-permission";
import { loadShipping } from '../../redux/actions/shipping/shipping.action';
import ReactTooltip from 'react-tooltip';
import ShippingCostTable from './ShippingCostTable';

function ShippingCosts ({ shipping : { shippingData, pagination, isShipLoading}, loadShipping }) {
    const { hasPermission } = useAccess();
    const  create_url = '/purchases/shipping-costs/create';
    
    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Shipping Costs</h3>
                        
                    </div>
                </div>
                <Show when="inv.shipping-costs.view">
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                List of Purchase Orders 
                                                <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                                           
                                                <Show when="inv.shipping-costs.store"><Link to={create_url} className="btn btn-primary btn-sm pull-right btn-fix-header"><i className="ft ft-plus"></i> Create New</Link></Show>
                                            </h4>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <section className="row">
                                                    <div className="col-sm-12">
                                                        <ShippingCostTable/>
                                                    </div>
                                                </section>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Show>
            </div>
            
        </div>
    );
}

const mapStateToProps = state => ({
    shipping : state.shipping
})

export default connect(mapStateToProps, {loadShipping})(ShippingCosts);