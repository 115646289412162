import React from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import SITSList from './SITS.list';
import SITSDuplicate from "./SITSDuplicate";
import SITSUpdate from "./SITSUpdate";

function SITemplateSetting({ show_component }) {

    return (
        <div className="card">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header">
                <h4 className="card-title">Sales Invoice {show_component === 'duplicate' && "- Create Duplicate"} {show_component === 'duplicate-main' && "- Create Duplicate"} {show_component === 'edit_copy' && "- Update"}<a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements"></div>
            </div>
            <div className="card-content collapse show pb-5">
                <div className={show_component != 'main' ? 'pt-0 card-body' : 'card-body'}>
                    {(() => {
                        switch (show_component) {
                            case 'main':
                                return <SITSList/>;
                            case 'duplicate':
                            case 'duplicate-main':
                                return <SITSDuplicate/>
                            case 'edit_copy':
                                return <SITSUpdate/>
                            default:
                                return null;
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    show_component: state.si_template_setting.show_component
});

export default connect(mapStateToProps, { })(SITemplateSetting);
