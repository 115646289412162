import {
  GET_SUPPLIERS,
  GET_SINGLE_SUPPLIER,
  SUPPLIER_INPUT_CHANGE,
  CLEAR_SUPPLIER,
  SET_LOADING,
  SEARCH_INPUT,
  SET_PAGE,
  SELECT_COUNTRY,
  HANDLE_MOBILE,
  GET_SUPPLIER_HISTORY_PURCHASE_ORDERS,
  SUPPLIER_HISTORY_DATE_FILTER_INPUT,
  SUPPLIER_HISTORY_PURCHASE_ORDER_DATE_FILTER,
  SUPPLIER_HISTORY_DATE_INPUT_CLEAR,
  SET_MINI_LOADING,
  GET_SUPPLIER_HISTORY_PRODUCTS,
  GET_SUPPLIER_HISTORY_SINGLE_PO,
  GET_SUPPLIER_HISTORY_SINGLE_PRODUCT,
  SET_PAYMENT_DETAILS,
  SORT_BY_COLUMN,
  GET_ROWS_TO_DELETE,
  SET_ROW_CHECKED,
} from "../types";

const initialState = {
  pagination: {
    totalCount: 0,
    activePage: 1,
    itemsCountPerPage: 1,
    totalItemsCount: 10,
    pageRangeDisplayed: 8,
  },
  suppliers: [],
  single_supplier: {
    name: "",
    business_name: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: {},
    fax: "",
    email: "",
    landline: "",
    mobile: "",
    contact_person: "",
  },
  default_payment_details_: {
    bank_name: "",
    bank_address: "",
    account_number: "",
    account_name: "",
    swift_code: "",
    mode: "edit",
    has_saved: 0,
  },
  payment_details: [],
  single_purchase_order: [],
  single_product: [],
  purchase_orders: [],
  display_tab: { purchase_orders: false, items: false },
  search: "",
  date_from: new Date(),
  date_to: new Date(),
  loading: false,
  mini_loading: false,
  isSearchable: true,
  main_page: false,
  edit_page: false,
  delete_modal: false,
  history_page: false,
  purchase_order_page: false,
  product_page: false,
  print_modal: false,
  main_url: "/purchases/suppliers",
  create_url: "/purchases/suppliers/create",
  edit_url: "/purchases/suppliers/edit",
  history_url: "/purchases/suppliers/history",
  product_url: "/purchases/suppliers/history/product",
  rows_to_delete: null,
  sort_order_name: null,
  sort_order_direction: null,
  set_row_checked: false,
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_MINI_LOADING:
      return {
        ...state,
        mini_loading: action.payload,
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload.suppliers,
        pagination: action.payload.pagination,
        set_row_checked: false,
      };
    case GET_SINGLE_SUPPLIER:
      return {
        ...state,
        single_supplier: action.payload,
      };
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        payment_details: action.payload,
      };
    case SUPPLIER_INPUT_CHANGE:
      return {
        ...state,
        single_supplier: {
          ...state.single_supplier,
          [action.payload.key]: action.payload.value,
        },
      };
    case SEARCH_INPUT:
      return {
        ...state,
        search: action.payload,
      };
    case SELECT_COUNTRY:
      return {
        ...state,
        single_supplier: { ...state.single_supplier, country: action.payload },
      };
    case HANDLE_MOBILE:
      return {
        ...state,
        single_supplier: { ...state.single_supplier, mobile: action.payload },
      };
    case SET_PAGE:
      return {
        ...state,
        [action.payload.page]: action.payload.status,
      };
    case SUPPLIER_HISTORY_DATE_FILTER_INPUT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SUPPLIER_HISTORY_PURCHASE_ORDER_DATE_FILTER:
      return {
        ...state,
        purchase_orders: action.payload.purchase_orders,
        pagination: action.payload.pagination,
      };
    case SUPPLIER_HISTORY_DATE_INPUT_CLEAR:
      return {
        ...state,
        date_from: new Date(),
        date_to: new Date(),
        date_filter: false,
        display_tab: { ...state.display_tab, [action.payload]: true },
      };
    case GET_SUPPLIER_HISTORY_PURCHASE_ORDERS:
      return {
        ...state,
        purchase_orders: action.payload.purchase_orders,
        pagination: action.payload.pagination,
        display_tab: { purchase_orders: true, products: false },
      };
    case GET_SUPPLIER_HISTORY_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        pagination: action.payload.pagination,
        display_tab: { purchase_orders: false, products: true },
      };
    case GET_SUPPLIER_HISTORY_SINGLE_PO:
      return {
        ...state,
        single_purchase_order: action.payload,
      };
    case GET_SUPPLIER_HISTORY_SINGLE_PRODUCT:
      return {
        ...state,
        single_product: action.payload,
      };
    case CLEAR_SUPPLIER:
      return {
        ...state,
        single_supplier: [],
        payment_details: [], // clear payment details as well after clearing single supplier
        display_tab: { purchase_orders: false, products: false },
        search: "",
        loadingl: false,
        main_page: false,
        edit_page: false,
        delete_modal: false,
        history_page: false,
        purchase_order_page: false,
        product_page: false,
        print_modal: false,
        main_url: "/purchases/suppliers",
        create_url: "/purchases/suppliers/create",
        edit_url: "/purchases/suppliers/edit",
        history_url: "/purchases/suppliers/history",
        product_url: "/purchases/suppliers/history/product",
        rows_to_delete: null,
      };
    case SORT_BY_COLUMN:
      return {
        ...state,
        sort_order_name: action.payload.sort_order_name,
        sort_order_direction: action.payload.sort_order_direction,
      };
    case GET_ROWS_TO_DELETE:
      return {
        ...state,
        rows_to_delete: action.payload,
      };
    case SET_ROW_CHECKED:
      return {
        ...state,
        set_row_checked: true,
      };
    default:
      return state;
      break;
  }
};

export default supplierReducer;
