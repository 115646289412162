import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner, { MiniSpinner } from "../../layouts/Spinner";
import {  Show, useAccess } from "react-redux-permission";
import { numberFormat, onlyNumber, formattedDate } from "../../../Utils/Common";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { handleSearchInputMUI, posDaily, posTransactionViewEdit, sortTableByColumn, posWarningConfirmation } from '../../../redux/actions/pos/pos.actions';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { handleHover } from "../../../redux/actions/helper/helper.actions";
import moment from "moment";
import { Title } from "@material-ui/icons";

function POSDailyTransaction({ pos: { loading, pos_cut_off_transactions, pagination, search, sort_order_name, sort_order_direction, set_row_checked, pos_loading }, 
    handleSearchInputMUI, posDaily, posTransactionViewEdit, sortTableByColumn, posWarningConfirmation, pos_daily_id, hoverIndex, handleHover }) {

    const [index_loading, setIndexLoading] = useState(null);
        
    const renderSkeleton = (numberOfRows) => {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(<Skeleton animation="wave" height={60}/>);
        }

        return tableRows;
    }


    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles(
        theme => ({
          centeredHeader: {
            '& span': {
              display:'flex',
              justifyContent: 'left',
              alignItems: 'center',
            },
          },
        })
    );
    const classes = useStyles();    

    // THIS SET THE ROW BACKGROUND COLOR   
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    '&:nth-child(5)': {
                        height: '61px',
                        width: '80px'
                    }
                }
            }
        }
    });


    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'serial_no', label: 'Transaction No.', options: {
            customBodyRender: (value, tableMeta) => {
                let rowIndex = tableMeta['rowIndex'];
                    return ( <span>{ value }</span> )
                }
            }
        },
        {name: 'created_at', label: 'Time', options: { print: false , download: false, sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let rowIndex = tableMeta['rowIndex'];
                    return ( 
                        <span>{moment(value).format("hh:mmA")}</span>
                    )
                },
                setCellProps : value => {
                    return {className : "text-center"}
                },
            } 
        },
        {name: 'total_qty', label: 'Items', options: { print: false , download: false, sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let rowIndex = tableMeta['rowIndex'];
                return ( <span>{value }</span> )
            },
            setCellProps : value => {
                    return {className : "text-center"}
                },
            } 
        },
        {name: 'grand_total', label: 'Total', options: { print: false , download: false, sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let rowIndex = tableMeta['rowIndex'];
                    return ( <span>{value}</span> )
                },
                setCellProps : value => {
                    return {className : "text-center"}
                },
            } 
        },
        {name: '', label: '', options: { print: false , download: false, sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let rowIndex = tableMeta['rowIndex'];
                let item = pos_cut_off_transactions[tableMeta['rowIndex']];
                // return ( <button onClick={() => posWarningConfirmation('transaction', item)} className="btn btn-grey btn-sm"> <i className="ft ft-edit"></i></button> )
                return ( <>{ hoverIndex == tableMeta.rowIndex ? <button onClick={() => posWarningConfirmation('transaction', item)} className="btn btn-grey btn-sm"> <i className="ft ft-edit"></i></button> : '' }</> )	
            },
            setCellProps : value => {
                return {className : "text-center"}
            },
        } 
    }
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20],
        rowsPerPage: pagination.itemsCountPerPage ? pagination.itemsCountPerPage : 1,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        selectableRows: false,
        elevation: 2,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `pos_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? renderSkeleton(10) : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            posDaily(pos_daily_id, null, numberOfRows);
            console.log('RUN HERE')
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    posDaily(pos_daily_id, tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    // getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => { },
        onRowClick: (rowData, rowMeta) => {
            let rowID = rowData[0];
            let rowIndex = rowMeta.rowIndex;
            // popOverRef(rowIndex, rowID)
            // handleSetPopOver(true, rowIndex)

            // posTransactionViewEdit(rowID, 'receipt');
            // setIndexLoading(prevState => rowIndex);
        },
        setRowProps: (rowData, dataIndex, rowIndex) => {
            let transaction = pos_cut_off_transactions[rowIndex];
            if (transaction && transaction.status === "cancelled") {
                return {
                    style: {
                        backgroundColor: "#f8f8f8",
                        // pointerEvents: "none", 
                        textDecoration:" line-through"
                    },
                    title: "cancelled"
                }
            } else {
                return { onMouseEnter: () => handleHover('in', rowIndex), onMouseLeave: () => handleHover('out', rowIndex) }
            }
        },
        
    };

    // console.log("pos_cut_off_transactions", pos_cut_off_transactions)

    return (
        <>  
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={pos_cut_off_transactions} columns={columns} options={options}/>
            </MuiThemeProvider>
        </>
    )
}

const mapStateToProps = state => ({
	pos: state.pos,
    pos_daily_id: state.pos.id,
    hoverIndex: state.helper.hoverIndex
});

export default connect(mapStateToProps, { handleSearchInputMUI, posDaily, posTransactionViewEdit, sortTableByColumn, posWarningConfirmation, handleHover })(POSDailyTransaction);

