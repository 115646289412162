import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';

export class BlitzDefaultContent extends React.PureComponent {
    // function SalesInvoicePrintContent({ invoice, auth }) {
    // let subId = auth.user.subscriber_id;
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.invoice.invoice_items.length;
        this.state = {
            invoice: this.props.invoice,
            auth: this.props.auth,
            activePage: 1,
            itemsCountPerPage: 15,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr key={'tr-' + i}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        // console.log(page, totalPage);
        const { invoice } = this.state;
        const totalItemsCount = invoice.invoice_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        invoice.invoice_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
                // console.log(extraRowsCount);
            }
        });

        // console.log(showItemsFrom, showItemsTo);
        // console.log(extraRowsCount);

        const DynamicStringStyle = {
            maxWidth: '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        let auth = this.state.auth;

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';
        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';
        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b temp-div pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Webinvoicete</li>
                    </ul>
                </div>
            );

        const customerAddress = [
            invoice.customer.street1,
            invoice.customer.street2 && ', ',
            invoice.customer.street2,
            (invoice.customer.street1 || invoice.customer.street2) && ', ',
            invoice.customer.city,
            invoice.customer.city && invoice.customer.state && ', ',
            invoice.customer.state,
        ]
            .filter(Boolean)
            .join('');

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const subs_setting = this.state.subs_setting;

        const currencyDisplay =
        subs_setting &&
        subs_setting.currency &&
        subs_setting.currency.code &&
        subs_setting.currency.code.checked
            ? 'code'
            : 'symbol';

        const template_setting = this.state.invoice.template_setting;

        return (
            <div className="margin-1p5em px-1 print-page mt-1">
                <div className="print-logo-container print-header-container">
                    {/* {template_setting && template_setting.company_logo && (
            <img src={template_setting.company_logo} className='print-logo' />
          )}
          {template_setting && template_setting.company_logo === "" ? (
            template_setting ? (
              <div className='c-name'>{template_setting.company_name}</div>
            ) : (
              <div className='print-logo-empty'></div>
            )
          ) : null} */}
                    <img src={template_setting && "company_logo" in template_setting ? template_setting.company_logo : "/BlitzLogo.png"} alt="logo" className="print-logo" />
                    <div className="print-header text-right">
                        <span className="print-header-text">INVOICE</span>
                    </div>
                </div>

                <div className="print-body row print-content-block">
                    <div className="col-md-12 pr-0">
                        <span className="f-20b">Invoice to:</span>
                    </div>
                    <div className="col-md-4 col-print-4 ">
                        <span className="f-16b">
                            <Link
                                to={`/sales/customers/history/${
                                    invoice.customer ? invoice.customer._id : null
                                }`}
                                className="txt-underline"
                                target="_blank"
                            >
                                {invoice.customer ? invoice.customer.name : null}
                            </Link>
                        </span>
                        <p className="f-12">{customerAddress}</p>
                    </div>
                    <div className="col-md-4 col-print-4"></div>
                    <div className="col-md-4 col-print-4 pr-0">
                        <span className="f-16b">IN No</span>{' '}
                        <span className="f-right">{invoice.invoice_no}</span>
                        <br />
                        <span className="f-16b">Date</span>{' '}
                        <span className="f-right">
                            {defaultFormat(invoice.invoice_date)}
                        </span>
                    </div>
                    <div className="col-md-12 pr-0">
                        {invoice.status == 'draft' ? (
                            <div class="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table table-striped table-print">
                            <thead>
                                <tr className="print-table-header" key={-1}>
                                    <th>#</th>
                                    <th>Item Description</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Unit</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice.invoice_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${item.product_id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    item.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {item.sku !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item.sku
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {item.description !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                          {item.brand !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .brand
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {/* {
                                                            item.products.brand != null &&  <><br/><span className="f-12 text-dark">{item.products.brand.name}</span></>
                                                        } */}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.unit_price
                                                            ? formatPrice(
                                                                  item.unit_price,
                                                                  currencyDisplay
                                                              )
                                                            : formatPrice(
                                                                  item.srp,
                                                                  currencyDisplay
                                                              )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.product_unit_name
                                                            ? item.product_unit_name
                                                            : ''}
                                                    </td>
                                                    <td className="text-center">
                                                        {numberFormat(
                                                            item.qty,
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            item.amount,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                </tr>

                                                {item.discount_type &&
                                                    item.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <span>
                                                                    <span>
                                                                        Discount:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item.discount
                                                                        }
                                                                        {item
                                                                            .discount_type
                                                                            .label ===
                                                                        'Percent'
                                                                            ? ' %'
                                                                            : ''}
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    style={{
                                                                        margin: '0 0 0 10px',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        Discount
                                                                        type:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item
                                                                                .discount_type
                                                                                .label
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {numberFormat(
                                                                    Number(
                                                                        item.discount_amount
                                                                    ).toFixed(2)
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div>
                    {page == totalPage ? (
                        <Fragment>
                            <div className="col-md-6 col-print-6">
                                <span className="f-14b">&nbsp;</span>
                                <br />
                                <br />
                                <span className="f-14b">Remarks</span>
                                <p className="f-12">
                                    {invoice.remarks != null ? invoice.remarks : ''}{' '}
                                    &nbsp;
                                </p>

                                <span className="f-14b">Payment Terms</span>
                                <p className="f-12">
                                    {invoice.payment_terms.value != ''
                                        ? invoice.payment_terms.value
                                        : ''}{' '}
                                    &nbsp;
                                </p>

                                <span className="f-14b">Payment Type:</span>
                                <p className="f-12">
                                    {invoice.payment_type
                                        ? invoice.payment_type.value != ''
                                            ? capitalize(invoice.payment_type.value)
                                            : ''
                                        : ''}{' '}
                                    &nbsp;
                                </p>
                            </div>
                            <div className="col-md-2 col-print-2 pr-0"></div>
                            <div className="col-md-4 col-print-4 pr-0">
                                <span className="f-14b">Sub Total:</span>
                                <span className="f-right f-14b">
                                    {formatPrice(invoice.sub_total, currencyDisplay)}
                                </span>
                                <br />
                                <span className="f-14b">
                                    Discount{' '}
                                    {invoice.discount &&
                                    invoice.discount_type.value === 'percent'
                                        ? `(${numberFormat(
                                              invoice.discount,
                                              0
                                          )}% ${invoice.tax_type.label.slice(0, 4)})`
                                        : null}
                                    :
                                </span>
                                <span className="f-right f-14b">
                                    {invoice.discount &&
                                    invoice.discount_type.value === 'percent'
                                        ? formatPrice(
                                              invoice.sub_total *
                                                  (invoice.discount / 100),
                                              currencyDisplay
                                          )
                                        : formatPrice(
                                              invoice.discount,
                                              currencyDisplay
                                          )}{' '}
                                </span>
                                <br />
                                <span className="f-14b">
                                    VAT{' '}
                                    {invoice.tax
                                        ? `(${numberFormat(invoice.tax, 0)}%)`
                                        : null}
                                </span>
                                <span className="f-right f-14b">
                                    {invoice.tax
                                        ? formatPrice(
                                              invoice.vat_amount,
                                              currencyDisplay
                                          )
                                        : null}
                                </span>
                                <br />
                                <span className="f-14b">Delivery Fee:</span>
                                <span className="f-right f-14b">
                                    {formatPrice(invoice.other, currencyDisplay)}{' '}
                                </span>
                                <br />
                                <section className="print-total">
                                    <span className="f-16b">Total:</span>
                                    <span className="f-right f-16b">
                                        {formatPrice(invoice.total, currencyDisplay)}
                                    </span>
                                    <br />
                                </section>
                            </div>
                            <div className="col-12 pr-0">
                                <div className="row">
                                    <div className="col-4">
                                        <span className="f-14b">
                                            Prepared by:
                                        </span>
                                        <p className="f-12">
                                            {invoice.user_prepared_by}&nbsp;
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="f-14b">
                                            Checked by:
                                        </span>
                                        <p className="f-12">
                                            {invoice.user_checked_by}&nbsp;
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        {/* <span className="f-14b">Approved by:</span> */}
                                        <p className="f-12">&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="print-footer h-p25em">
                    <div className="row">
                        <div className="col-md-10 col-print-10 pb-0 mb-0">
                            <ul className="f-12b ul-print-paw">
                                {template_setting && (
                                    <li>{template_setting.company_name}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_landline}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_email}</li>
                                )}
                                {template_setting && (
                                    <li className="mb-2">
                                        {template_setting.company_address}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-md-2 col-print-2 pb-0 mb-0">
                            <span className="f-right mt-25-f">
                                Page {page}/{totalPage}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="page-break" />
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let invoice = this.state.invoice;

        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{invoice.status == 'draft' ? invoice.status : ''}</h1>
                </div>
            */}
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
// 	invoicengle_invoice: state.invoice.invoicengle_invoice,
// 	auth: state.auth
// })

export default BlitzDefaultContent;
