import http from '../../../Services/api.laravel.http';

export const HelperService = {
	poAttach: (formParams, options) => http.post('/admin/po/upload/files', formParams, options), // purchase order attachment files
	quAttach: (formParams, options) => http.post('/admin/quotation/upload/files', formParams, options), // quotation attachment files
	siAttach: (formParams, options) => http.post('/admin/sales-invoice/upload/files', formParams, options), // sales invoice attachment files
	soAttach: (formParams, options) => http.post('/admin/sales-order/upload/files', formParams, options), // sales order attachment files
	invoiceAttach: (formParams, options) => http.post('/admin/invoice/upload/files', formParams, options), // invoice attachment files
	receivingAttach: (formParams, options) => http.post('/admin/receiving/upload/files', formParams, options), // receiving attachment files
	deliveryAttach: (formParams, options) => http.post('/admin/delivery/upload/files', formParams, options), // delivery attachment files
	csAttach: (formParams, options) => http.post('/admin/customer/upload/files', formParams, options), // customer attachment files
}
