import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { getDRTemplateSettings, toggleComponent, confirmDialogBox } from "../../../../redux/actions/dr_template_setting/dr_template_setting.actions";
import { MiniSpinner } from "../../../layouts/Spinner";


function SITSList({ loading, dr_template_settings, getDRTemplateSettings, toggleComponent, confirmDialogBox }) {

    useEffect(() => {
        getDRTemplateSettings()
    },[])


    return (
        <div className="row">
            {
                loading ? <div className="col-6 justify-content-center py-5 px-5"><MiniSpinner/></div> :
                <>
                    {
                        dr_template_settings && dr_template_settings.map(dr => (
                            <div className="col-lg-2 col-sm-6 col-12">
                                <div className="card temp-box">
                                    <div className="card-header temp-header row py-0 my-0 px-0 mx-0">
                                        <div className="mr-auto">
                                            <i className="ft ft-more-horizontal temp-box-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                { !dr.main_template && <button onClick={() => toggleComponent('edit_copy', dr)} class="dropdown-item" type="button">Edit</button> }
                                                { dr.is_default === 1 ?  null : !dr.is_custom_template ? <button onClick={() => confirmDialogBox('set_default', dr)} class="dropdown-item" type="button">Set as Default</button> : null }
                                                { dr.main_template ? <button onClick={() => toggleComponent('duplicate-main', dr)} class="dropdown-item" type="button">Duplicate</button> : <button onClick={() => toggleComponent('duplicate', dr)} class="dropdown-item" type="button">Duplicate</button> }
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                           { !dr.main_template && <i onClick={() => confirmDialogBox('archive', dr)} className="ft ft-x temp-box-icon font-weight-bold"></i> }
                                        </div>
                                    </div>
                                    <div className="card-body my-0 p-0">
                                        <img src={dr.template_image} alt="dr" className="img-fluid cursor-pointer"/>
                                    </div>
                                </div>
                                <div className="text-center">{ dr.is_default === 1 && <i className="ft ft-check-circle temp-default-check"></i> } {dr.template_name}</div>
                                {
                                    dr.is_default === 1 && <div className="text-center text-primary"><span className="lbl-status temp-coming-soon">DEFAULT</span></div>
                                }
                            </div>
                        ))
                    }

                    <div className="col-lg-2 col-sm-6 col-12">
                        <div className="card temp-box">
                            <div className="card-header temp-header row py-0 my-0 px-0 mx-0">
                                <div className="m-auto">
                                    <span className="mt-0 lbl-status temp-coming-soon">Coming Soon</span>
                                </div>
                            </div>
                            <div className="card-body my-0 p-0">
                                <span><i className="ft ft-plus-circle temp-plus-icon"></i></span>
                                <img src="/template-setting/blank.png" alt="dr" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}


const mapStateToProps = state => ({
    setting_app: state.setting_app,
    loading: state.dr_template_setting.loading,
    dr_template_settings: state.dr_template_setting.dr_template_settings
});

export default connect(mapStateToProps, { getDRTemplateSettings, toggleComponent, confirmDialogBox })(SITSList);
