import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getStockLogs,
    sortTableByColumn,
    getSelectedRows,
    setModal,
    handleDateFilterSubmit,
} from '../../redux/actions/returns/return.actions';
import { useDebounce } from 'use-debounce';
import StockLogFilter from './StockLog.filter.global';
import LogsDateFilter from './LogsDate.filter';
import ReturnStockModal from './modals/returnStockModal';

function StockLogTable({
    returns: { stock_logs, pagination, search },
    getStockLogs,
    getSelectedRows,
    sortTableByColumn,
    setModal,
    handleDateFilterSubmit,
}) {
    const [movementFilter, setMovementFilter] = useState('out'); // Track active movement filter
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');

    useEffect(() => {
        getStockLogs(1, null, 'out'); // Default filter is 'out'
    }, []);

    useEffect(() => {
        if (debouncedSearch[0] && oldDebounced !== debouncedSearch[0]) {
            if (debouncedSearch[0].length !== 1) {
                setOldDebounced(debouncedSearch[0]);
                getStockLogs(1, null);
            }
        }
    }, [debouncedSearch]);

    const handleFilterChange = (filter) => {
        setMovementFilter(filter);
        handleDateFilterSubmit(
            { preventDefault: () => {} },
            'stock_logs',
            'out',
            filter
        );
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const sortedLogs = [...stock_logs].sort((a, b) => {
        if (!sortColumn) return 0; // No sorting applied
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA === undefined || valueB === undefined) return 0;
        if (sortOrder === 'asc') {
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        }
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    });

    return (
        <main className="w-100">
            <section className="filter-container">
                <div className="w-100">
                    <LogsDateFilter
                        filterBy="stock_logs"
                        pageOrigin="out"
                        movementFilter={movementFilter}
                    />
                </div>
                <div className="w-100">
                    <StockLogFilter onFilterChange={handleFilterChange} />
                </div>
            </section>

            <section className="ds-table-container">
                <table
                    id="ds-table-to-print"
                    className="ds-table ds-table-striped"
                >
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('created_at')}>
                                Date{' '}
                                {sortColumn === 'created_at' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('_id')}>
                                ID{' '}
                                {sortColumn === '_id' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('sku')}>
                                SKU{' '}
                                {sortColumn === 'sku' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('product_name')}>
                                Product Name{' '}
                                {sortColumn === 'product_name' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('Brand')}>
                                Brand{' '}
                                {sortColumn === 'Brand' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('description')}>
                                Description{' '}
                                {sortColumn === 'description' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('quantity')}>
                                Qty{' '}
                                {sortColumn === 'quantity' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('reference_no')}>
                                Reference No.{' '}
                                {sortColumn === 'reference_no' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('supplier')}>
                                Supplier{' '}
                                {sortColumn === 'supplier' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('movement')}>
                                Movement{' '}
                                {sortColumn === 'movement' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedLogs.length === 0 ? (
                            <tr>
                                <td
                                    colSpan="10"
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                    }}
                                >
                                    Sorry, there is no matching data to display.
                                </td>
                            </tr>
                        ) : (
                            sortedLogs.map((log) => (
                                <tr key={log._id}>
                                    <td>{log.created_at?.substring(0, 10)}</td>
                                    <td>
                                        {' '}
                                        {log._id.slice(0, 3)}...
                                        {log._id.slice(-3)}
                                    </td>
                                    <td>{log.sku}</td>
                                    <td>
                                        <Link to={`/inventory/view/${log._id}`}>
                                            {log.product_name}
                                        </Link>
                                    </td>
                                    <td>{log.brand_name}</td>
                                    <td>{log.description}</td>
                                    <td>{log.quantity}</td>
                                    <td>{log.reference_no}</td>
                                    <td>{log.supplier}</td>
                                    <td>{log.movement}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-grey"
                                            onClick={() =>
                                                setModal(
                                                    'return_stock_modal',
                                                    true,
                                                    log
                                                )
                                            }
                                        >
                                            <i class="icon-action-undo"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </section>

            <ReturnStockModal
                className="modal-centered"
                onHide={() => setModal('return_stock_modal', null)}
            />
        </main>
    );
}

const mapStateToProps = (state) => ({
    returns: state.returns,
});

export default connect(mapStateToProps, {
    getStockLogs,
    getSelectedRows,
    sortTableByColumn,
    setModal,
    handleDateFilterSubmit,
})(StockLogTable);
