import React, { Fragment,  useEffect } from 'react';
import Select from "react-select";
import { connect } from 'react-redux';
import { handleSelectParentCategory, handleInputChange, categoryPost, getParentCategory, parentCategoryCheckbox } from '../../redux/actions/category/category.actions';
import ReactTooltip from 'react-tooltip';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';

function CategoryCategoryGlobal({ category: { category_page, main_url,single_category, default_parent_category, parent_category },
    handleSelectParentCategory, handleInputChange, categoryPost, getParentCategory, parentCategoryCheckbox, pageOrigin, block_route, blockRoute }) {

    let history = useHistory();

    useEffect(() => {
        blockRoute(false);
    },[])

    useEffect(() => {

        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => window.history.pushState(null, null, window.location.pathname);


    useEffect(() => {
        getParentCategory();
    }, [])


    if(category_page) return <Redirect to={main_url}/>

    return (
        <BeforeUnloadComponent 
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
                SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
            }}
        >
        <form onSubmit={e => categoryPost(e, pageOrigin)}>
			<ReactTooltip  effect="solid" event="click"/>
            <section className="row f-regular">
                <div className="col-12">
                    <label className="label-control">Name: <span className='text-danger'>*</span></label>
                    <div className="form-group row">
                        <div className="col-md-11 col-10">
                            <input onChange={handleInputChange} type="text" name="name" className="form-control" placeholder="Name.." required/>
                        </div>
                        <div className="pl-0 col-1 col-md-1">
                            <div className="input-group-prepend">
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
                {
                    pageOrigin == 'category' && 
                    <Fragment>
                        <div className="col-12">
                            <div className="form-group row">
                                <div className="col-md-11 col-10">
                                    <div class="custom-control custom-checkbox">
                                        <input onChange={parentCategoryCheckbox} type="checkbox" value={true} class="custom-control-input" id="parent-category"/>
                                        <label class="custom-control-label" for="parent-category"><strong>Parent Category</strong></label>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-12">
                            {
                                !single_category.parent_category_checked && 
                                <div className="form-group row">
                                    <div className="col-md-11 col-10">
                                    <Select onChange={handleSelectParentCategory} options={parent_category} isSearchable={true} value={default_parent_category}/>
                                    </div>
                                    <div className="pl-0 col-1 col-md-1">
                                        <div className="input-group-prepend">
                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                        </div>
                                    </div>  
                                </div>
                            }
                        </div>
                        <div className="col-12">
                            <label className="label-control">Description:</label>
                            <div className="form-group row">
                                <div className="col-md-11 col-10">
                                    <textarea onChange={handleInputChange} name="description" rows="3" className="form-control" placeholder="Description"></textarea>
                                </div>
                                <div className="pl-0 col-1 col-md-1">
                                    <div className="input-group-prepend">
                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </Fragment>
                }
                <div className="col-12">
                    <div className="form-group row">
                        <label className="col-md-4 label-control"></label>
                        <div className="col-md-7 col-10 text-right">
                            <button type="submit" className="btn btn-primary btn-save">Save</button>
                        </div>
                        <div className="pl-0 col-2 col-md-1"></div>  
                    </div>
                </div>
            </section>
        </form>
        </BeforeUnloadComponent>
    )
}

const mapStateToProps = state => ({
	category: state.category,
    block_route: state.helper.block_route
});

export default connect(mapStateToProps, { handleSelectParentCategory, handleInputChange, categoryPost, getParentCategory, parentCategoryCheckbox, blockRoute })(CategoryCategoryGlobal);
