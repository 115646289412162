import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { customAlert } from '../../Utils/Confirm';
import { map } from "jquery";
import {handleChangeStockView} from '../../redux/actions/delivery_adjustment/delivery_adjustment';

function DeliveryAdjustment ({deliveryadjs : {stock_view}, handleChangeStockView}) {

    const list = [
        {
            id : '00001',
            date : '09/16/2020',
            ir : '0001',
            supplier : 'Big Pix',
            delivery_date : '10/10/2020',
            items : 1,
            qty : '10 pcs',
            reason : 'Damage',
            adjustment : '1pc',
            in_cost : '1,800.00',
            status : 'new'
        },
        {
            id : '00002',
            date : '09/17/2020',
            ir : '0003',
            supplier : 'Big Pix',
            delivery_date : '10/10/2020',
            items : 6,
            qty : '4 pcs',
            reason : 'Wrong Item',
            adjustment : '1pc',
            in_cost : '1,800.00',
            status : 'reported'
        },
        {
            id : '00003',
            date : '09/18/2020',
            ir : '0005',
            supplier : 'Cement Factory',
            delivery_date : '09/25/2020',
            items : 6,
            qty : '30 sacks',
            reason : 'Missing',
            adjustment : '4 sacks',
            in_cost : '1,800.00',
            status : 'settled'
        },
        {
            id : '00004',
            date : '09/18/2020',
            ir : '0006',
            supplier : 'Cement Factory',
            delivery_date : '09/20/2020',
            items : 10,
            qty : '6 sacks',
            reason : 'Damage',
            adjustment : '3 sacks',
            in_cost : '1,800.00',
            status : 'settled'
        }
    ]

    
    
    return (
        
        <div className="app-content content">
            <ReactTooltip  effect="solid"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Delivery Receipt Stock Adjustments</h3>
                        <a href="/delivery/adjustment/create"  className="btn btn-primary pull-right btn-fix-header"><i className="ft ft-plus"></i> Adjustment Report</a>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                  <section className="row" id="basic-examples">
                      <div className="col-sm-12">
                          <div id="what-is" className="">
                             
                              <div className="card-content collapse show">
                                  <div className="card-body no-padding-top no-padding-left-right">
                                      <div className="row">
                                            <div className="col-md-12 ">
                                                <ul className="nav nav-tabs f-regular">
                                                    <li className="">
                                                        <a onClick={() => handleChangeStockView('all')} className="nav-link active" id="base-tab1" data-toggle="tab" aria-controls="tab1" href="#tabs" aria-expanded="true">
                                                            All
                                                        </a>
                                                    </li>
                                                    <li className="">
                                                        <a  onClick={() => handleChangeStockView('1')} className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#tabs" aria-expanded="false">
                                                            New
                                                        </a>
                                                    </li>
                                                    <li className="">
                                                        <a onClick={() => handleChangeStockView('2')} className="nav-link" id="base-tab3" data-toggle="tab" aria-controls="tab3" href="#tabs" aria-expanded="false">
                                                            Reported
                                                        </a>
                                                    </li>
                                                    <li className="">
                                                        <a onClick={() => handleChangeStockView('3')} className="nav-link" id="base-tab3" data-toggle="tab" aria-controls="tab3" href="#tabs" aria-expanded="false">
                                                            Returned
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content px-1 pt-1 card-box-top card">
                                                    <div role="tabpanel" className="tab-pane active table-responsive " id="tabs" aria-expanded="true" aria-labelledby="base-tab1">
                                                        <div className="pb-4m">
                                                            <table className="table table-striped table-hover table-bordered mb-0 f-regular">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="13">
                                                                            <div className="pull-left"> <h4><b>Total: {list.length}</b></h4> </div>
                                                                        <div className="form-inline pull-right">
                                                                            <input  name="search" type="text" className="form-control input-sm" placeholder="Search here..." />
                                                                            <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                                                                        </div>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-center">
                                                                            <input type="checkbox" />
                                                                        </th>
                                                                        <th>Report #</th>
                                                                        <th>Date Reported</th>
                                                                        <th>DR #</th>
                                                                        <th>Customer</th>
                                                                        <th>Delivery Date</th>
                                                                        <th>Items</th>
                                                                        <th>Qty</th>
                                                                        <th>Reason</th>
                                                                        <th>Adjustment</th>
                                                                        <th>In Price</th>
                                                                        <th>Status</th>
                                                                        <th>
                                                                            
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        list.map((data, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className="text-center"> 
                                                                                        <input type="checkbox" />
                                                                                    </td>
                                                                                    <td>{data.id}</td>
                                                                                    <td>{data.date}</td>
                                                                                    <td>{data.ir}</td>
                                                                                    <td>{data.supplier}</td>
                                                                                    <td>{data.delivery_date}</td>
                                                                                    <td>{data.items}</td>
                                                                                    <td>{data.qty}</td>
                                                                                    <td>{data.reason}</td>
                                                                                    <td>{data.adjustment}</td>
                                                                                    <td>{data.in_cost}</td>
                                                                                    <td className="text-center"><span className={"lbl-status lbl-" + data.status}>{data.status}</span></td>
                                                                                    <td>
                                                                                        <a href="#" className="btn btn-sm btn-grey btn-sm"><i class="ft ft-edit"></i></a>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                      </div>
                                   
                                  </div>

                              </div>
                          </div>
                      </div>
                  </section>
              </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    deliveryadjs : state.deliveryadjs
});

export default connect(mapStateToProps, { handleChangeStockView})(DeliveryAdjustment);
