import React, { Fragment, useState } from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Show, useAccess } from "react-redux-permission";
import POSBarcode from './POS.barcode';
import POSMenu from './POS.menu';

function POSCreate({ pos }){

    const [selectTab, setSelectTab] = useState('Barcode');

    return (
        <div className="card-content collapse show">
            <div className="card-body no-padding-top no-padding-left-right">
                <section className="row">
                    <div className="col-sm-12">
                        <ul className="nav nav-tabs f-regular">
                            <li className="">
                                <a onClick={() => setSelectTab('Barcode')} className="nav-link active" id="base-tab1" data-toggle="tab" aria-controls="tab1" href="#tabs" aria-expanded="true">
                                    Barcode
                                </a>
                            </li>
                            <li className="">
                                <a onClick={() => setSelectTab('Menu')} className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#tabs" aria-expanded="false">
                                    Menu
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content px-1 pt-1 card-box-top card">
                            <div role="tabpanel" className="tab-pane active table-responsive " id="tabs" aria-expanded="true" aria-labelledby="base-tab1">
                            {(() => {
                                switch(selectTab){
                                    case 'Barcode':
                                        return <POSBarcode/>
                                    case 'Menu':
                                        return <POSMenu/>
                                    default:
                                        return null;
                                }
                            })()}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
	pos: state.pos
})

export default connect(mapStateToProps, { })(POSCreate);
