import * as TYPE from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import { SalesInvoiceTemplateSettingService } from './_service.si_template_setting';
import { blockRoute, validateImage } from '../helper/helper.actions';
import { SwalWarning } from '../../../Services/_swal.service';
import { SettingAppService } from '../settings_app/_service.setting_app';


// loading
export const setLoading = status => async dispatch => dispatch({ type: TYPE.SET_LOADING, payload: status });


// Clear SI Template Setting
export const clearSITemplateSetting = () => async dispatch => dispatch({ type: TYPE.CLEAR_SI_TEMPLATE_SETTING });


// set crop isset
export const cropIsset = (bool) => async dispatch => dispatch({ type: TYPE.SI_TS_CROP_ISSET, payload: bool });


// toggle component
export const toggleComponent = (comp = 'main', si = null)  => async dispatch => {
    
    switch (comp) {
        case 'duplicate-main':
            dispatch(getSettingApp(si._id));
            break;
        case 'duplicate':
            dispatch(getSingleSITS(si._id, comp));
            break;
        case 'edit_copy':
            dispatch(getSingleSITS(si._id));
            break;
        case 'main':
            dispatch(clearSITemplateSetting());
        default:
            break;
    }

    dispatch({ type: TYPE.SET_SI_TS_COMPONENT, payload: comp });
}


// Get Sales Invoice Template Settings
export const getSITemplateSettings = () => async dispatch => {
    
    dispatch(setLoading(true));

    try {
        const res = await SalesInvoiceTemplateSettingService.getSITemplateSettings();
      
        dispatch({ type: TYPE.GET_SI_TEMPLATE_SETTING, payload: res.data.si_template_settings });
        dispatch(setLoading(false));

    } catch (error) {
        console.log(error);
        ToastDanger(error.message);
        dispatch(setLoading(false));
    }   
}


// handle contact
export const handleContacts = (type, number) => async dispatch => {

    if(number){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

    let payload = {};
    if(type === 'mobile'){
        payload = { name: 'company_mobile', value: `+${number}`}; // mobile
    }else{
        payload = { name: 'company_landline', value: parseFloat(number) }; // landline
    }

    dispatch({ type: TYPE.HANDLE_SI_TEMPLATE_INPUT, payload });
}


// handle inputs
export const handleInput = (e) => async dispatch => {

    if(e){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

    let { name, value } = e.target;
    dispatch({ type: TYPE.HANDLE_SI_TEMPLATE_INPUT, payload: { name, value } });
}


// handle submit
export const onSubmitSITS = (e, type = null) => async (dispatch, getState) => {
    e.preventDefault();

    let { single_si_template_setting, crop_trigger } = getState().si_template_setting;

    // if(single_si_template_setting.logo_preview != '' && crop_trigger === false){
    //     return ToastDanger('Please set your crop logo by pulling or dragging the dotted lines.');
    // }

    if(single_si_template_setting.company_landline && single_si_template_setting.company_landline.length < 4){
        return ToastDanger('Landline is invalid, please provide your company landline');
    }

    if(single_si_template_setting.company_mobile && single_si_template_setting.company_mobile.length < 4){
        return ToastDanger('Mobile is invalid, please provide your company landline');
    }

    // post || update
    switch (type) {
        case 'duplicate':
            return dispatch(storeSITS());
        case 'update':
            if(single_si_template_setting.off_default === true){
                return SwalWarning('Warning!', `You're about to off a Default Template if you do "Blitz Default" will be set as default. Do you want to proceed?`, () => dispatch(updateSITS()));
            }else{
                return dispatch(updateSITS());
            }

        default:
            return null;
    }
  
}


export const storeSITS = () => async (dispatch, getState) => {

    const { single_si_template_setting } = getState().si_template_setting;

    try {   

        $('.btn-save').attr('disabled','disabled').html('Saving...');

        const formdata = new FormData();
        formdata.append('sales_invoice_template_id',    single_si_template_setting.sales_invoice_template_id);
        formdata.append('template_name',                single_si_template_setting.template_name);
        formdata.append('company_name',                 single_si_template_setting.company_name);
        formdata.append('company_address',              single_si_template_setting.company_address);
        formdata.append('company_email',                single_si_template_setting.company_email);
        formdata.append('company_landline',             single_si_template_setting.company_landline);
        formdata.append('company_mobile',               single_si_template_setting.company_mobile);
        formdata.append('company_tin',                  single_si_template_setting.company_tin);
        formdata.append('is_default',                   single_si_template_setting.is_default);
        formdata.append('file_logo',                    single_si_template_setting.file_logo);

        const res = await SalesInvoiceTemplateSettingService.storeSITS(formdata);

        switch (res.data.status) {
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch(toggleComponent());
                dispatch(blockRoute(false));
                dispatch(clearSITemplateSetting());
                break;
            default:
                break;
        }

        $('.btn-save').removeAttr('disabled').html('Save');
    } catch (err) {
        console.log(err);
        ToastDanger(err.data.message);
        $('.btn-save').removeAttr('disabled').html('Save');
    }
}


export const updateSITS = () => async (dispatch, getState) => {

    const { single_si_template_setting } = getState().si_template_setting;

    try {   

        $('.btn-update').attr('disabled','disabled').html('Updating...');

        const formdata = new FormData();
        formdata.append('_id',                          single_si_template_setting._id);
        formdata.append('sales_invoice_template_id',    single_si_template_setting.sales_invoice_template_id);
        formdata.append('template_name',                single_si_template_setting.template_name);
        formdata.append('company_name',                 single_si_template_setting.company_name);
        formdata.append('company_address',              single_si_template_setting.company_address);
        formdata.append('company_email',                single_si_template_setting.company_email);
        formdata.append('company_landline',             single_si_template_setting.company_landline);
        formdata.append('company_tin',                  single_si_template_setting.company_tin);
        formdata.append('company_mobile',               single_si_template_setting.company_mobile);
        formdata.append('is_default',                   single_si_template_setting.is_default);
        formdata.append('type',                         single_si_template_setting.type);
        formdata.append('file_logo',                    single_si_template_setting.file_logo);
        formdata.append('off_default',                  single_si_template_setting.off_default);

        const res = await SalesInvoiceTemplateSettingService.updateSITS(formdata, single_si_template_setting._id);

        switch (res.data.status) {
            case 0:
                ToastDanger(res.data.errors);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch(blockRoute(false));
                dispatch(toggleComponent());
                dispatch(clearSITemplateSetting());
                break;
            default:
                break;
        }

        $('.btn-update').removeAttr('disabled').html('Update');
    } catch (err) {
        console.log(err);
        ToastDanger(err.data.message);
        $('.btn-update').removeAttr('disabled').html('Update');
    }
}


// handle input set as default new template to be created
export const inputSetDefault = (is_default) => async dispatch => {

    let payload = {
        is_default,
        off_default: is_default === false ? true : false 
    };

    dispatch({ type: TYPE.HANDLE_SET_SI_DEFAULT_TEMPLATE, payload });
}


export const confirmDialogBox = (type, data = null, is_remove = null) => async dispatch => {

    switch (type) 
    {
        case 'set_default':
            SwalWarning('Warning!', `Do you want to set "${data.template_name}" as your default Sales Invoice Template`, () => dispatch(setAsDefaultGlobal(data)));
            break;
        case 'archive':
            if(data.is_default === 1) {
                SwalWarning('Warning!', `You're about to remove a Default Template if you remove this "Blitz Default" will be set as default. Do you want to proceed?`, () => dispatch(SITemplateSettingArchive(data, 'default')));
            }else {
                SwalWarning('Warning!', `Are you sure, you want to remove "${data.template_name}"`, () => dispatch(SITemplateSettingArchive(data)));
            }
            break;
        case 'logo_remove':
            SwalWarning('Warning!', 'Do you want to remove this logo?', () => dispatch(removeLogo(is_remove)));
            break;
        case 'clear_upload':
            dispatch(removeLogo('clear_upload'));
        default:
            break;
    }
}


export const setAsDefaultGlobal = si => async dispatch => {
    try {
        // console.log(si)
        const res = await SalesInvoiceTemplateSettingService.setAsDefault({ _id: si._id });        
        ToastSuccess(res.data.message);
        dispatch(getSITemplateSettings());

    }catch(err) {
        console.log(err);
        ToastDanger(err.message);
    }
}

export const SITemplateSettingArchive = (si, type = null) => async dispatch => {

    try {
        
        let params = { _id: si._id, type };
        
        const res = await SalesInvoiceTemplateSettingService.siTemplateSettingArchive(params);

        switch (res.data.status) {
            case 0:
                ToastDanger(res.data.message);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch(getSITemplateSettings());
                break;
            default:
                break;
        }

    } catch (err) {
        console.log(err);
        ToastDanger(err.message);
    }
}


export const showCropping = (bool) => async dispatch => { 
    
    if(bool === false){
        dispatch(handleCropLogo(null, false));
    }

    dispatch({ type: TYPE.SI_TS_SHOW_CROP, payload: bool })
};


// remove logo 
export const removeLogo = (type = null) => async dispatch => {
    $('#itemUpload').val('');

    let payload = {};

    if(type == 'clear_upload'){
        payload = { logo_preview: '' }; //clear temporary onload 
    }else{
        payload = {
            type: type ? type : '',
            logo: '', 
            logo_preview: '',
        }
    }

    dispatch({ type: TYPE.SI_TS_REMOVE_LOGO, payload });
}

// upload logo preview
export const uploadLogo = selectedFile => async dispatch => {
    
    if(selectedFile){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

    let validate_image = validateImage(selectedFile);
    if(validate_image.status === 1){
        $('#sits-mainbtn').show();
        return ToastDanger(validate_image.errors[0]);
    }

    let payload = URL.createObjectURL(selectedFile[0]);

    dispatch({ type: TYPE.SI_TS_LOGO_PREVIEW, payload });
}

// handle logo crop
export const handleCropLogo = (file_logo, crop_trigger = true) => async dispatch => {
    dispatch({ type: TYPE.SI_TS_CROP_LOGO, payload: { file_logo, crop_trigger } });
}

// crop trigger 
export const cropTrigger = bool => async dispatch => dispatch({ type: TYPE.SI_TS_CROP_TRIGGER, payload: bool }); 


// get single po ts
export const getSingleSITS = (id, comp = null) => async dispatch => {
    
    try {
        
        dispatch(setLoading(true));

        const { data } = await SalesInvoiceTemplateSettingService.getSingleSITS(id);

        let single_si_template_setting = data.si_template_setting;
        single_si_template_setting['logo'] = single_si_template_setting.template_logo ? single_si_template_setting.template_logo.file_path : '';
        single_si_template_setting['is_default'] = single_si_template_setting.is_default == 1 ? true : false;

        if(comp === 'duplicate'){
            single_si_template_setting['template_name'] = '';
            single_si_template_setting['company_name'] = '';
            single_si_template_setting['logo'] = '';
        }

        dispatch({ type: TYPE.GET_SINGLE_SI_TS, payload: single_si_template_setting });
        dispatch(setLoading(false));

    } catch (err) {
        console.log(err);
        ToastDanger(err.message);
        dispatch(setLoading(false));
    }
} 



// get subsciber_setting
export const getSettingApp = (template_id) => async dispatch => {

    try {
        dispatch(setLoading(true));

        const res = await SettingAppService.getSettingApp();
        const subscriber_setting = res.data.subscriber_setting;

        // clear data
        subscriber_setting['template_name'] = '';
        subscriber_setting['sales_invoice_template_id'] = template_id ;
        subscriber_setting['company_name'] = '';
        subscriber_setting['logo'] = '';

        dispatch({ type: TYPE.GET_SINGLE_SI_TS, payload: subscriber_setting });
        dispatch(setLoading(false));
        
    } catch (err) {
        console.log(err);
        ToastDanger(err.message);
        dispatch(setLoading(false));
    }
}
