import React, { Fragment } from 'react';
import { dateFilterInput, dateFilterClear, handleDateFilterSubmit } from '../../redux/actions/supplier/supplier.actions';
import { connect } from 'react-redux';

function SupplierDateFilterGlobal({ supplier: { date_from, date_to }, dateFilterInput, dateFilterClear, handleDateFilterSubmit, filterBy }) {
    return (
        <Fragment>
            <form onSubmit={e => handleDateFilterSubmit(e, filterBy)}>
                <div className="row mt-1 f-regular">
                    <div className="col-sm-12 col-md-4">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">From: <span className='text-danger'>*</span></label>
                            <div className="col-8">
                                <input type="date" name="date_from" value={date_from} onChange={dateFilterInput} className="form-control" required/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">To: <span className='text-danger'>*</span></label>
                            <div className="col-8">
                                <input type="date" name="date_to" value={date_to} onChange={dateFilterInput} className="form-control" required/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">  
                        <div class="btn-group" role="group">
                            <button className="btn btn-primary"> Filter </button>              
                            <a onClick={() => dateFilterClear('purchase_orders')} className="btn btn-danger text-white"><i className="ft ft-spinner"></i> Clear</a>  
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}


const mapStateToProps = state => ({
	supplier: state.supplier
});

export default connect(mapStateToProps, { dateFilterInput, dateFilterClear, handleDateFilterSubmit })(SupplierDateFilterGlobal);
