import {
    SET_LOADING,
    SEARCH_INPUT,
    SET_PAGE,
    SET_MINI_LOADING,
    GET_SINGLE_SALES_INVOICE,
    GET_SALES_INVOICES,
    SALES_INVOICE_INPUT_CHANGE,
    GET_SI_CUSTOMERS,
    GET_SI_PRODUCTS,
    GET_SI_TERMS_CONDITIONS,
    CLEAR_SALES_INVOICE,
    HANDLE_SI_SELECT_TERMS_CONDITIONS,
    HANDLE_SI_SELECT_ITEM,
    HANDLE_SI_REMOVE_ITEM,
    HANDLE_SI_ADD_ITEM,
    HANDLE_SI_SRP,
    HANDLE_SI_QTY,
    HANDLE_SI_INPUT_ATTR,
    HANDLE_SI_TOGGLE_EDIT_BTN_ATTR,
    HANDLE_SI_SELECT_CUSTOMER,
    HANDLE_SI_CHECK_VAT,
    GET_SI_TEMPLATES,
    HANDLE_SELECT_SI_TEMPLATE,
    HANDLE_SI_SELECT_TYPE_RECEIPT,
    HANDLE_SI_TOGGLE_EDIT_ROW,
    HANDLE_SI_EDIT_INPUT_ROW,
    HANDLE_SI_EDIT_UPDATE_ROW,
    HANDLE_SI_SUBMIT_ATTR,
    HANDLE_SI_SELECT_PAYMENT,
    HANDLE_SI_SELECT_PAYMENT_TYPE,
    SET_SI_ITEM_ID,
    SET_MODAL,
    HANDLE_CHECK_MANUAL,
    SI_GET_QUOTATIONS,
    HANDLE_SI_SELECT_QUOTATION,
    SET_DATE_PICKER,
    SORT_BY_COLUMN,
    SET_QU_ITEM_ID,
    GET_ROWS_TO_DELETE,
    SET_ROW_CHECKED,
    ITEMS_TEMPLATE,
    ADD_ITEM,
    TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    SI_SET_CUSTOMER_DATA,
    SI_CLEAR_UPLOAD,
    SI_SELECT_TAX_TYPE,
    SI_COMPUTATION_HANDLER,
    SEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    RESEND_EMAIL,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    BTN_FORM,
} from './../../types';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
} from '../../../Services/_toast.service';
import $ from 'jquery';
import { SalesInvoiceService } from './_service.salesinvoice';
import {
    SwalWarning,
    SwalError,
    SwalSuccessAutoRemove,
    SwallverfiyPass,
    SwalWarningHtml,
    SwalUpdatePayment,
    SwalVerifyUpdatePayment,
} from '../../../Services/_swal.service';
import { QuotationService } from '../quotation/_service.quotation';
import { loadUnReceived } from '../receiving/receiving.actions';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { getNumRows, getSubSetting, getUserData } from '../../../Utils/Common';
import { getSettingApp } from '../settings_app/setting_app.actions';

// DISCOUNT
export const handleSelectDiscountType = (selectOption) => async (dispatch) => {
    // console.log(selectOption)
    // dispatch({ type: SALES_INVOICE_INPUT_CHANGE, payload: { key: 'discount', value: 0 }})
    // dispatch({ type: SALES_INVOICE_INPUT_CHANGE, payload: { key: 'discount_type', value: selectOption.value } });

    // ** Updated select
    dispatch({
        type: SALES_INVOICE_INPUT_CHANGE,
        payload: { key: 'discount', value: 0 },
    });
    dispatch({
        type: SALES_INVOICE_INPUT_CHANGE,
        payload: { key: 'discount_type', value: selectOption },
    });
};

export const handleInputChangeDiscount = (e) => async (dispatch, getState) => {
    if (e.target.value !== '') {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { single_sales_invoice } = getState().sales_invoice;

    if (single_sales_invoice.discount_type.value === 'percent') {
        if (e.target.value > 100) {
            ToastDanger(
                'If your discount is percentage, discount should not be over 100'
            );
            dispatch({
                type: SALES_INVOICE_INPUT_CHANGE,
                payload: { key: e.target.name, value: 100 },
            });
        } else if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: SALES_INVOICE_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: SALES_INVOICE_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    } else {
        if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: SALES_INVOICE_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: SALES_INVOICE_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    }
};

export const handleDiscountItem =
    (index, remove = false) =>
    async (dispatch, getState) => {
        let { single_sales_invoice } = getState().sales_invoice;
        let items = { ...single_sales_invoice.sales_invoice_items[index] };

        if (!items.product_name) {
            ToastDanger('Please add Product item at least one.');
            return;
        }

        if (remove) {
            items['discount'] = 0;
            items['discount_type'] = { value: 'none', label: 'None' };
            items['discount_amount'] = 0;
            items['amount'] = items.srp * items.qty;
        }

        single_sales_invoice.sales_invoice_items[index] = items;

        dispatch({
            type: ADD_ITEM,
            payload: single_sales_invoice.sales_invoice_items,
        });

        dispatch({
            type: TOGGLE_DISCOUNT_ROW,
            payload: { index, remove },
        });
    };

export const handleInputDiscountProduct =
    (e, index) => async (dispatch, getState) => {
        let { name, value } = e.target;
        value = parseFloat(value);
        let { single_sales_invoice } = getState().sales_invoice;
        let items = { ...single_sales_invoice.sales_invoice_items[index] };

        // // Save original amount if not already saved
        // if (!items.hasOwnProperty('original_amount')) {
        //   items['original_amount'] = items.srp;
        // }

        let discountedAmount = 0;
        let discountAmount = 0;

        items.amount = items.srp * items.qty;

        if (value === '0') {
        } else if (items.discount_type.value === 'percent') {
            discountAmount = (value / 100) * items.amount;
            discountedAmount = items.amount - discountAmount;
            items.amount = discountedAmount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = value;
            discountedAmount = items.amount - value;
            items.amount = discountedAmount;
        }

        items[name] = parseFloat(value);
        items['discount_amount'] = discountAmount ? discountAmount : 0;

        single_sales_invoice.sales_invoice_items[index] = items;
        dispatch({
            type: ADD_ITEM,
            payload: single_sales_invoice.sales_invoice_items,
        });
    };

export const handleSelectDiscountTypeProduct =
    (selectOption, index) => async (dispatch, getState) => {
        let { single_sales_invoice } = getState().sales_invoice;
        let items = { ...single_sales_invoice.sales_invoice_items[index] };

        // Reset discount amount when changing discount type to 'amount'
        if (selectOption.value === 'amount' || 'percent' || 'none') {
            items.discount_amount = 0;
            items.amount = items.srp * items.qty;
        }

        items.discount_type = selectOption;
        items.discount = 0;

        single_sales_invoice.sales_invoice_items[index] = items;
        dispatch({
            type: ADD_ITEM,
            payload: single_sales_invoice.sales_invoice_items,
        });
    };

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value !== '') {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: SALES_INVOICE_INPUT_CHANGE,
        payload: { key: e.target.name, value: e.target.value },
    });
};

// handle edit inputs
// export const handleSearchInput = e => async dispatch => dispatch({ type: SEARCH_INPUT, payload: e.target.value })
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    // dispatch(getSalesInvoices(1));
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearSalesInvoice = () => async (dispatch) =>
    dispatch({ type: CLEAR_SALES_INVOICE });

// set modal
export const setPage =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().sales_invoice;

        switch (page) {
            case 'main_page':
                dispatch({ type: SET_PAGE, payload: { page, status } });
                dispatch(clearSalesInvoice());
                break;
            case 'edit_page':
                let edit_url_with_id = `${edit_url}/${id}`;
                dispatch({
                    type: SET_PAGE,
                    payload: { page, status, edit_url: edit_url_with_id },
                });
                break;
            case 'delete_modal':
                dispatch(getSingleSalesInvoice(id, 'delete'));
                break;
            case 'print_modal_si':
                if (status) {
                    dispatch(getSingleSalesInvoice(id));
                } else {
                    dispatch(siClearUpload());
                }
                dispatch({ type: SET_PAGE, payload: { page, status } });
                break;
            default:
                // print_modal
                dispatch({ type: SET_PAGE, payload: { page, status } });
        }
    };

export const siClearUpload = () => async (dispatch) =>
    dispatch({ type: SI_CLEAR_UPLOAD });

// create selectOptions use in suppliers
const createSelectOption = (lists) => {
    let selectOptions = [];

    lists.map((val, i) => {
        let select = { value: val._id, label: val.name, i: i };

        selectOptions[i] = select;
    });

    return selectOptions;
};

export const handleSelectCustomer =
    (selectOption) => async (dispatch, getState) => {
        SalesInvoiceService.getSingleCustomer(selectOption.value)
            .then((res) => {
                let default_customer_value = {
                    value: res.data.customer._id,
                    label: res.data.customer.name,
                };

                dispatch({
                    type: HANDLE_SI_SELECT_CUSTOMER,
                    payload: {
                        customer_id: selectOption.value,
                        customer: res.data.customer,
                        default_customer_value: default_customer_value,
                    },
                });
                let { item_template } = getState().sales_invoice;
                let items_data = [item_template];
                dispatch({ type: ADD_ITEM, payload: items_data });
            })
            .catch((error) => {
                ToastDanger('Network error, please reload the page.');
                console.log(error);
            });
    };

// handle select payment terms
export const handleSelectTermsConditions =
    (selectOption) => async (dispatch) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        dispatch({
            type: HANDLE_SI_SELECT_TERMS_CONDITIONS,
            payload: selectOption,
        });
    };

// handle selec payment type
export const handleSelectPaymentType = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({ type: HANDLE_SI_SELECT_PAYMENT_TYPE, payload: selectOption });
};

// // handle select payment
// export const handleSelectPayment = selectOption => async dispatch => {
//     dispatch({ type: HANDLE_SI_SELECT_PAYMENT,payload: selectOption });
// }

// handle Select Item
export const handleSelectItem = (selectOption, index) => async (dispatch, getState) => {
    
    if(selectOption){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }
    
    let {single_sales_invoice} = getState().sales_invoice;
    let items   = {...single_sales_invoice.sales_invoice_items[index]};

    // Check if the product is already selected in other rows
    const isProductAlreadySelected = single_sales_invoice.sales_invoice_items.some((item, i) => i !== index && item.product_id === selectOption._id);
    if (isProductAlreadySelected ) {
        // Display an error message (You can replace this with your preferred way of showing errors)
        ToastDanger("Product is already added on the list");
          // Remove the row
          dispatch(handleRemoveItem(index));
      } else if (selectOption.instock === false){
        ToastWarning("Product is out of stock");
        // Remove the row
         dispatch(handleRemoveItem(index));
      }else {
        items['_id'] = selectOption._id;
        items['product_id'] = selectOption._id;
        items['sku'] = selectOption.sku;
        items['product_name'] = selectOption.product_name;
        items['qty'] = parseInt(selectOption.qty);
        items['product_unit_id'] = selectOption.product_unit_id;
        items['product_unit_name'] = selectOption.product_unit_name;
        items['attribute_string'] = selectOption.attribute_string;
        items['srp'] = parseFloat(selectOption.srp);
        items['unit_price'] = parseFloat(selectOption.srp); // new input field
        items['amount'] = parseFloat(selectOption.amount);
        items['status_code'] = selectOption.status_code;
        items['barcode'] = selectOption.barcode;
        items['description'] = selectOption.description;
        items['selected'] = true;
        items['brand'] = selectOption.brand;
        items['discount_type'] = { label: 'None', value: 'none' }; // default discount type
        items['discount'] = 0; // default discount
        items['discount_amount'] = 0;

        single_sales_invoice.sales_invoice_items[index] = items;
        dispatch({type : ADD_ITEM, payload : single_sales_invoice.sales_invoice_items});
    } 
}

export const ShowComboBox = (index) => async (dispatch, getState) => {
    let { single_sales_invoice } = getState().sales_invoice;
    let items = { ...single_sales_invoice.sales_invoice_items[index] };
    items['selected'] = false;
    single_sales_invoice.sales_invoice_items[index] = items;
    dispatch({
        type: ADD_ITEM,
        payload: single_sales_invoice.sales_invoice_items,
    });
};

// handle Add Item
export const handleAddItem = (e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { itemSelected, single_sales_invoice } = getState().sales_invoice;

    const params = {
        _id: itemSelected._id,
        qty: itemSelected.qty,
        product_name: itemSelected.product_name,
    };

    const checkInStock = await dispatch(checkInStockQty(params));

    const duplicate = single_sales_invoice.sales_invoice_items.filter(
        (item) => item._id == itemSelected._id
    );

    if (checkInStock['error']) {
        ToastDanger(checkInStock['error']);
    } else if (itemSelected.qty == 0 || isNaN(itemSelected.qty)) {
        ToastDanger('Quantity field is required and cannot be a string value');
    } else if (itemSelected.srp == 0 || isNaN(itemSelected.srp)) {
        ToastDanger('Srp is required and cannot be a string value');
    } else if (itemSelected.product_name == '') {
        ToastDanger('Product Item is required, please select at least one');
    } else if (duplicate.length > 0) {
        ToastDanger(`${duplicate[0].product_name} is already on the list.`);
    } else {
        let sales_invoice_items =
            single_sales_invoice.sales_invoice_items.concat(itemSelected);

        dispatch({
            type: HANDLE_SI_ADD_ITEM,
            payload: { sales_invoice_items: sales_invoice_items },
        });
    }
};

// Check In Stock Qty of Product
export const checkInStockQty = (params) => async (dispatch, getState) => {
    let data = [];

    try {
        const checkInstock = await SalesInvoiceService.CheckInStock(params);
        data['status'] = checkInstock.data.status;
        data['error'] = checkInstock.data.error;
    } catch (e) {
        data['status'] = 0;
        data['error'] = e.error;
    }

    return data;
};

// Handle Remove Item
export const handleRemoveItem = (index) => async (dispatch, getState) => {
    if (index) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { sales_invoice_items } = getState().sales_invoice.single_sales_invoice;
    // removing value in array base on index
    let _sales_invoice_items = sales_invoice_items.filter(
        (_, i) => i !== index
    );

    dispatch({
        type: HANDLE_SI_REMOVE_ITEM,
        payload: { sales_invoice_items: _sales_invoice_items },
    });
};

// handle Srp
export const handleInputSrp = (e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { itemSelected } = getState().sales_invoice;

    let srp = parseFloat(e.target.value);
    let amount = itemSelected.qty * srp;

    dispatch({ type: HANDLE_SI_SRP, payload: { srp: srp, amount: amount } });
};

// handle Qty
export const handleInputQty = (e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { itemSelected } = getState().sales_invoice;
    let multipliedPrice = e.target.value * itemSelected.srp;

    dispatch({
        type: HANDLE_SI_QTY,
        payload: { qty: e.target.value, amount: parseFloat(multipliedPrice) },
    });
};

// handle discount, other
export const handleInputAttr = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let name = e.target.name;
    let value = e.target.value;

    if (name == 'other') {
        dispatch({
            type: HANDLE_SI_INPUT_ATTR,
            payload: { key: e.target.name, value: parseFloat(value) },
        });
    } else {
        dispatch({
            type: HANDLE_SI_INPUT_ATTR,
            payload: { key: e.target.name, value: parseInt(value) },
        });
    }
};

// handle toggle edit discount, other
export const toggleEditBtn = (status, attr) => async (dispatch, getState) => {
    // true || false, isDiscount, isOther
    dispatch({
        type: HANDLE_SI_TOGGLE_EDIT_BTN_ATTR,
        payload: { attr: attr, status: status },
    });
};

// handle discount, other, submit
export const handleAttrSubmit = (attr) => async (dispatch, getState) => {
    let { edit_attr } = getState().sales_invoice;
    let attr_val = edit_attr[attr];

    if (isNaN(attr_val)) {
        ToastDanger('Field required at least 0 value');
    } else {
        dispatch({
            type: HANDLE_SI_SUBMIT_ATTR,
            payload: { key: attr, value: attr_val },
        });
    }
};

// handle vat
export const handleCheckVat = (e) => async (dispatch) => {
    if (e.target.checked) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let checked;

    if (e.target.checked) {
        checked = 12;
    } else {
        checked = 0;
    }

    dispatch({ type: HANDLE_SI_CHECK_VAT, payload: checked });
};

// handle edit row
export const handleToggleEditRow = (index) => async (dispatch) => {
    dispatch({ type: HANDLE_SI_TOGGLE_EDIT_ROW, payload: index });
};

// handle input edit row
export const handleEditInputRow = (index, e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { edit_sales_invoice_items } = getState().sales_invoice;
    let name = e.target.name;
    edit_sales_invoice_items[index][name] = parseFloat(e.target.value);

    dispatch({
        type: HANDLE_SI_EDIT_INPUT_ROW,
        payload: edit_sales_invoice_items,
    });
};

// handle update row
export const handleUpdateRow = (index) => async (dispatch, getState) => {
    let { edit_sales_invoice_items } = getState().sales_invoice;

    let qty = edit_sales_invoice_items[index].qty;
    let srp = edit_sales_invoice_items[index].srp;

    if (qty == 0 || isNaN(qty)) {
        ToastDanger('Qty is required and cannot be a string value');
    } else if (srp == 0 || isNaN(srp)) {
        ToastDanger('Srp is required and cannot be a string value');
    } else {
        let updated_amount = srp * qty;
        edit_sales_invoice_items[index].amount = updated_amount;

        dispatch({
            type: HANDLE_SI_EDIT_UPDATE_ROW,
            payload: edit_sales_invoice_items,
        });
    }
};

// handle select sales invoice templates
export const handleSelectSalesInvoiceTemplate =
    (selectedOption) => async (dispatch) => {
        if (selectedOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        let template_id = selectedOption.value;
        let si_default_template = {
            value: selectedOption.value,
            label: selectedOption.label,
        };

        dispatch({
            type: HANDLE_SELECT_SI_TEMPLATE,
            payload: { si_default_template, template_id },
        });
    };

// handle select status [SI || DR]
export const handleSelectReceiptType = (selectedOption) => async (dispatch) => {
    if (selectedOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let default_select_type_receipt = {
        value: selectedOption.value,
        label: selectedOption.label,
    };

    dispatch({
        type: HANDLE_SI_SELECT_TYPE_RECEIPT,
        payload: {
            default_select_type_receipt: default_select_type_receipt,
            receipt_type: selectedOption,
        },
    });
};

// fetch sales invoice templates
export const getInvoiceTemplates = () => async (dispatch) => {
    SalesInvoiceService.getInvTemplates()
        .then((res) => {
            let sales_invoice_templates = res.data.sales_invoice_templates;
            let si_default_template = {
                value: res.data.selected_template._id,
                label: res.data.selected_template.template_name,
            };
            let template_id = res.data.sales_invoice_template_id;

            let si_template_arr = [];
            sales_invoice_templates.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.template_name,
                    name: 'invoice_template_id',
                };

                si_template_arr[i] = select;
            });

            dispatch({
                type: GET_SI_TEMPLATES,
                payload: {
                    sales_invoice_templates: si_template_arr,
                    si_default_template: si_default_template,
                    template_id: template_id,
                },
            });
        })
        .catch((error) => {
            ToastDanger('Network error, please reload the page.');
            $('.btn-remove').removeAttr('disabled').html('Remove');
            console.log(error);
        });
};

// get all the terms and conditions
export const getTermsConditions = () => async (dispatch) => {
    SalesInvoiceService.getTermsConditions()
        .then((res) => {
            let terms_arr = [];
            let terms = res.data.terms_conditions;

            terms.map((val, i) => {
                let select = { value: val.content, label: val.title };
                terms_arr[i] = select;
            });

            terms_arr.unshift({ value: '', label: 'None' });

            dispatch({ type: GET_SI_TERMS_CONDITIONS, payload: terms_arr });
        })
        .catch((error) => {
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
};

// get all the select options
export const getCustomers = (search = "") => async (dispatch) => {
    const formdata = {
        search: search,
    };
    SalesInvoiceService.getCustomers(formdata)
        .then((res) => {
            let customers_arr = [];
            let customers = res.data.docs;

            customers.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.name,
                    name: 'customer_id',
                };
                customers_arr[i] = select;
            });

            dispatch({ type: GET_SI_CUSTOMERS, payload: customers_arr });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                error.data.errors.map((err) => ToastDanger(err));
              } else {
                 ToastDanger("Network error, please reload the page.");
              }
            console.log(error);
        });
};

// get all the select options
export const searchCustomers = (search) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const formdata = {
            search: search,
        };
        SalesInvoiceService.getCustomers(formdata)
            .then((res) => {
                let customers_arr = [];
                let customers = res.data.docs;

                customers.map((val, i) => {
                    let select = {
                        value: val._id,
                        label: val.name,
                        name: 'customer_id',
                    };
                    customers_arr[i] = select;
                });
                resolve(customers_arr);
                // dispatch({ type: GET_SI_CUSTOMERS, payload: customers_arr });
            })
            .catch((error) => {
                // ToastDanger('Network error, please reload the page.');
                console.log(error);
                reject(error)
            });
    });

// get the products
export const getProducts =
    (search = "") =>
    async (dispatch) => {
        const formdata = {
            search: search,
        };
        SalesInvoiceService.getProducts(formdata)
            .then((res) => {
                let items_arr = [];
                let items = res.data.docs;

                // console.log(items)

                items.map((item, i) => {
                    // console.log("item", item);
                    let attribute = '';
                    // if(item.attribute_string === '()' || item.attribute_string === null){
                    //     attribute = '';
                    // }
                    // else{

                    //     try
                    //     {
                    //         let attr_replace_char = item.attribute_string.replace('(','').replace(')', '');
                    //         attribute = attr_replace_char.slice(0, 40) + (attr_replace_char.length > 40 ? "..." : "");
                    //     }
                    //     catch(err){
                    //         attribute = '';
                    //     }

                    // }

                    // let select = {
                    //     _id: item._id,
                    //     value: item._id,
                    //     label: item.product_name,
                    //     sub_label: attribute,
                    //     name: 'item',
                    //     product_name: item.product_name,
                    //     barcode: item.barcode,
                    //     sku: item.sku,
                    //     qty: 1,
                    //     product_unit_id: item.product_unit_id ? item.product_unit_id : '',
                    //     product_unit_name: item.product_unit !== null ? item.product_unit.name : '',
                    //     attribute_string: item.attribute_string,
                    //     srp: item.srp,
                    //     unit_price: item.srp, // this is editable new field
                    //     amount: item.srp,
                    //     description : item.description,
                    //     lot_no : item.single_lot != null && item.single_lot != undefined ? item.single_lot.lot_no : '',
                    //     status_code : item.status_code
                    // }

                    let select = {
                        _id: item._id,
                        value: item._id,
                        label: item.product_name,
                        name: 'item',
                        product_name: item.product_name,
                        barcode: item.barcode,
                        brand: item.brand,
                        sku: item.sku,
                        qty: 1,
                        instock: item.instock,
                        product_unit_name: item.product_unit || '',
                        srp: item.srp,
                        unit_price: item.srp, // this is editable new field
                        amount: item.srp,
                        description: item.description,
                    };

                    items_arr[i] = select;
                });

                dispatch({ type: GET_SI_PRODUCTS, payload: items_arr });
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                  } else {
                    ToastDanger("Network error, please reload the page.");
                  }
                console.log(error);
            });
    };

export const searchProducst = (search, index) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        // do something was a success
        const formdata = {
            search: search,
        };
        SalesInvoiceService.getProducts(formdata).then(res => {
        
            let items_arr = [];
            let items = res.data.docs;
            items.map((item, i) => {
                    let attribute = '';
                    if (
                        item.attribute_string == '()' ||
                        item.attribute_string === null
                    ) {
                        attribute = '';
                    } else {
                        try {
                            let attr_replace_char = item.attribute_string
                                .replace('(', '')
                                .replace(')', '');
                            attribute =
                                attr_replace_char.slice(0, 40) +
                                (attr_replace_char.length > 40 ? '...' : '');
                        } catch (err) {
                            attribute = '';
                        }
                    }

                    let select = {
                        _id: item._id,
                        value: item._id,
                        label: item.product_name,
                        sub_label: attribute,
                        name: 'item',
                        barcode: item.barcode,
                        brand: item.brand,
                        product_name: item.product_name,
                        sku: item.sku,
                        qty: 1,
                        instock: item.instock,
                        product_unit_name: item ? item.product_unit_name : '',
                        attribute_string: item.attribute_string,
                        srp: item.srp,
                        amount: item.srp,
                        status_code: item.status_code,
                        description: item.description,
                    };

                    //items_arr[i] = select;
                    items_arr.push(select);
                });
                resolve(items_arr);
            })
            .catch((error) => {
                ToastDanger('Network error, please reload the page.');
                console.log(error);
                reject();
            });

        // do something was a fail
    });

//  fetch
export const getSalesInvoices =
    (pageNumber, rows_per_page = null, pageOrigin = null, date_filter = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));

        let { search, pagination, sort_order_name, sort_order_direction } =
            getState().sales_invoice;
        let rows_per_page_val =
            rows_per_page != null ? rows_per_page : getNumRows('sales_invoice');

        // let params = {
        //     search: search,
        //     activePage: pagination.activePage,
        //     page: pageNumber,
        //     rows_per_page: rows_per_page_val,
        //     sort_order_name: sort_order_name,
        //     sort_order_direction: sort_order_direction
        // }

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let params = {
            search: search,
            options: {
                page: pageNumber,
                limit: rows_per_page_val || 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        if (pageOrigin) {
            switch (pageOrigin['origin']) {
                case 'inventory_view':
                    params.product_id = pageOrigin['product_id'];
                    break;
                case 'customer_history':
                    params.customer_id = pageOrigin['customer_id'];
                default:
                    break;
            }
        }

        // Date Filtering
        if (date_filter) {
            params.date_from = date_filter['date_from'];
            params.date_to = date_filter['date_to'];
        }

        //console.log(params)

        // console.log(params);
        SalesInvoiceService.paginateWithSearchInvoices(params)
            .then((res) => {
                // let pagination = {
                //     totalCount: res.data.sales_invoices.total,
                //     activePage: res.data.sales_invoices.current_page,
                //     itemsCountPerPage: res.data.sales_invoices.per_page,
                //     totalItemsCount: res.data.sales_invoices.total
                // }

                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit,
                    totalItemsCount: res.data.total_docs,
                };

                // let sales = [];
                // res.data.sales_invoices.data.map((data) =>{
                //     data.sending_email = false;
                //     data.download_pdf = false;
                //     sales.push(data);
                // });

                let docs = res.data.docs;

                dispatch({
                    type: GET_SALES_INVOICES,
                    payload: { sales_invoices: docs, pagination: pagination },
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(setLoading(false));
                // ToastDanger('Network error, please reload the page.');
                console.log(error);
            });
    };

// get the sales invoice base on id
export const getSingleSalesInvoice =
    (id, status = null) =>
    async (dispatch) => {
        dispatch(clearSalesInvoice());
        dispatch(setMiniLoading(true));

        // SalesInvoiceService.getSingleSalesInvoice(id).then(res => {
        //     if(res.data.status == 1)
        //     {
        //         let single_sales_invoice = res.data.sales_invoice;
        //         let sales_invoice_items = res.data.sales_invoice.items;
        //         let si_default_template = [];
        //         let subs_setting = res.data.subs_setting;

        //         dispatch(getSettingApp());
        //         const subsetting = getSubSetting();
        //         single_sales_invoice['default_tax'] = subsetting.tax_rate;

        //         if(single_sales_invoice['terms'] == null){
        //             single_sales_invoice['terms'] = subsetting.payment_terms ? subsetting.payment_terms : { value: 'none', label: 'None' };
        //         }

        //         if(single_sales_invoice['tax'] === null){
        //             single_sales_invoice['tax'] = 0;
        //         }

        //         if(single_sales_invoice.receipt_type == null){
        //             single_sales_invoice['receipt_type'] = { value: 'sales invoice', label: 'Sales Invoice' };
        //         }

        //         let edit_attr = {
        //             discount: res.data.sales_invoice.discount ? res.data.sales_invoice.discount : 0,
        //             vat: res.data.sales_invoice.vat ? res.data.sales_invoice.vat : 0,
        //             other:  res.data.sales_invoice.other ? res.data.sales_invoice.other : 0.00
        //         }

        //         let default_customer_value = { value: single_sales_invoice.customer._id, label: single_sales_invoice.customer.name };

        //         if(single_sales_invoice.template_setting != null)
        //         {
        //             si_default_template = { value: single_sales_invoice.template_setting._id, label: single_sales_invoice.template_setting.template_name  };
        //         }

        //         let sales_invoice_items_arr = [];
        //         // console.log("sales_invoice_items", sales_invoice_items);
        //         sales_invoice_items.map(item => {
        //             let status_code = item.products ? item.products.status_code : 0;
        //             let formatted = { ...item, _sales_invoice_item_id: item._id, status_code : status_code}
        //             sales_invoice_items_arr.push(formatted)
        //         })

        //         single_sales_invoice.sales_invoice_date = single_sales_invoice.sales_invoice_date ? new Date(single_sales_invoice.sales_invoice_date) : new Date();
        //         single_sales_invoice.subs_setting = subs_setting;
        //         single_sales_invoice.total =  single_sales_invoice.grand_total;

        //         dispatch({ type: GET_SINGLE_SALES_INVOICE,
        //             payload: {
        //                 single_sales_invoice: single_sales_invoice,
        //                 sales_invoice_items: sales_invoice_items_arr,
        //                 edit_attr: edit_attr,
        //                 default_customer_value: default_customer_value,
        //                 si_default_template: si_default_template,
        //                 subs_setting: res.data.subs_setting
        //             }
        //         })
        //     }

        //     if(status == 'delete')
        //     {
        //         dispatch(archive());
        //     }

        //     dispatch(setMiniLoading(false));

        // })
        // .catch(error => {
        //     dispatch(setMiniLoading(false));
        //     ToastDanger('Network error, please reload the page.');
        //     console.log(error);
        // });

        try {
            const res = await SalesInvoiceService.getSingleSalesInvoice(id);

            if (res.data.status === 1) {
                let single_sales_invoice = res.data.docs;
                let sales_invoice_items = res.data.docs.sales_invoice_items;
                let si_default_template = [];
                let subs_setting = res.data.subs_setting;

                // console.log(single_sales_invoice)

                // dispatch(getSettingApp());
                // const subsetting = getSubSetting();
                // single_sales_invoice['default_tax'] = subsetting.tax_rate;

                if (single_sales_invoice['payment_terms'] === null) {
                    single_sales_invoice['terms'] = single_sales_invoice[
                        'payment_terms'
                    ] || { value: 'none', label: 'None' };
                }

                single_sales_invoice['payment_mode'] =
                    single_sales_invoice.payment_mode || { label: '' };

                single_sales_invoice['payment_type'] =
                    single_sales_invoice.payment_mode;
                single_sales_invoice['quotation_no'] =
                    single_sales_invoice.reference_no || null;
                single_sales_invoice['quotation_id'] =
                    single_sales_invoice.reference_id || null;

                // if(single_sales_invoice['tax'] === null){
                //     single_sales_invoice['tax'] = 0;
                // }

                // if(single_sales_invoice.receipt_type === null){
                //     single_sales_invoice['receipt_type'] = { value: 'sales invoice', label: 'Sales Invoice' };
                // }

                let edit_attr = {
                    discount: single_sales_invoice.discount
                        ? single_sales_invoice.discount
                        : 0,
                    vat: single_sales_invoice.vat
                        ? single_sales_invoice.vat
                        : 0,
                    other: single_sales_invoice.other
                        ? single_sales_invoice.other
                        : 0.0,
                };

                let default_customer_value = {
                    value: single_sales_invoice.customer._id,
                    label: single_sales_invoice.customer.name,
                };

                if (single_sales_invoice.template_setting != null) {
                    si_default_template = {
                        value: single_sales_invoice.template_setting._id,
                        label: single_sales_invoice.template_setting
                            .template_name
                            ? single_sales_invoice.template_setting
                                  .template_name
                            : single_sales_invoice.template.template_name,
                    };
                }

                // let sales_invoice_items_arr = [];
                // console.log("sales_invoice_items", sales_invoice_items);
                // sales_invoice_items.map(item => {
                //     let status_code = item.products ? item.products.status_code : 0;
                //     let formatted = { ...item, _sales_invoice_item_id: item._id, status_code : status_code}
                //     sales_invoice_items_arr.push(formatted)
                // })

                single_sales_invoice.sales_invoice_date =
                    single_sales_invoice.date
                        ? new Date(single_sales_invoice.date)
                        : new Date();
                single_sales_invoice.total = single_sales_invoice.total;
                // single_sales_invoice.subs_setting = subs_setting;

                const _payload = {
                    single_sales_invoice: single_sales_invoice,
                    sales_invoice_items: sales_invoice_items,
                    edit_attr: edit_attr,
                    default_customer_value: default_customer_value,
                    si_default_template: si_default_template,
                    // subs_setting: res.data.subs_setting
                };
                //console.log( _payload )

                dispatch({ type: GET_SINGLE_SALES_INVOICE, payload: _payload });
            }

            if (status == 'delete') {
                dispatch(archive());
            }

            dispatch(setMiniLoading(false));
        } catch (error) {
            dispatch(setMiniLoading(false));
            ToastDanger('Network error, please reload the page.');
            console.log(error);
        }
    };

// handle submit form
export const handleOnSubmit =
    (method, status) => async (dispatch, getState) => {
        const { single_sales_invoice } = getState().sales_invoice;

        if (single_sales_invoice.is_manual) {
            single_sales_invoice.quotation_id = null;
        }

        let errors = [];
        single_sales_invoice.sales_invoice_items.map((item) => {
            if (item.qty <= 0) {
                errors.push(
                    'Quantity cannot be zero value, please add at least 1'
                );
            }
            if (item.srp <= 0) {
                errors.push(
                    'Unit Price cannot be zero value, please include at least the amount'
                );
            }
        });

        if (single_sales_invoice.sales_invoice_items.length == 0) {
            ToastDanger('Please add Product item at least one.');
        } else if (errors.length > 0) {
            ToastDanger(errors);
        } else {
            let stat_arr = status.split('-');
            let stat = stat_arr[0];
            let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;
            // console.log("single_sales_invoice.sales_invoice_items", single_sales_invoice.sales_invoice_items);
            const data = {
                _id: single_sales_invoice._id,
                sales_invoice_no: single_sales_invoice.sales_invoice_no,
                transaction_no: single_sales_invoice.transaction_no,
                serial_no: single_sales_invoice.serial_no,
                reference_id: single_sales_invoice.quotation_id || null,
                reference_no: single_sales_invoice.is_manual
                    ? single_sales_invoice.reference_no
                    : single_sales_invoice.quotation_no,
                customer: single_sales_invoice.customer,
                sales_invoice_items: single_sales_invoice.sales_invoice_items,
                discount: single_sales_invoice.discount,
                discount_type: single_sales_invoice.discount_type,
                vat_amount: single_sales_invoice.vat_amount,
                tax: single_sales_invoice.tax,
                tax_type: single_sales_invoice.tax_type,
                other: single_sales_invoice.other,
                sub_total: single_sales_invoice.sub_total,
                total: single_sales_invoice.total,
                remarks: single_sales_invoice.remarks,
                payment_terms: single_sales_invoice.payment_terms,
                payment_mode: single_sales_invoice.payment_type,
                template_id: single_sales_invoice.template_id,
                payment: single_sales_invoice.payment,
                receipt_type: single_sales_invoice.receipt_type,
                status: stat,
                sales_invoice_date: single_sales_invoice.sales_invoice_date,
            };

            // console.log(data);
            if (single_sales_invoice.is_manual == true) {
                data['is_manual'] = true;
            
                // if (data['reference_no'] === null || data['reference_no'] === '') {
                //     ToastDanger('Reference No field is required.');
                //     return; // This will exit the function and stop further execution
                // }
            } else {
                data['is_manual'] = false;
            }
            

            //console.log("actions", actions);
            if (method === 'create') {
                // if(status == 'draft') return dispatch(salesInvoicePost(data));
                // if(status == 'saved') return SwalWarning('Are You Sure?','You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?', () => dispatch(salesInvoicePost(data)))
                // if(status == 'saved-email') return SwalWarning('Are You Sure?','You are about to save and send to email this Sales Invoice, saving this will stop you from editing any other changes. Continue?', () => dispatch(salesInvoicePost(data, true)))

                if (status === 'draft') {
                    data['status'] = 'draft';
                    return dispatch(salesInvoicePost(data, actions));
                }
                if (status === 'draft-print') {
                    data['status'] = 'draft_and_print';
                    return dispatch(salesInvoicePost(data, actions));
                }
                if (status === 'draft-email') {
                    data['status'] = 'draft_and_email';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to draft and email this Sales Invoice. Continue?',
                        () => dispatch(salesInvoicePost(data, actions))
                    );
                }
                // if(status == 'saved' || status == 'saved-print') return SwalWarning('Are You Sure?','You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?', () => dispatch(salesInvoicePost(data, actions)))

                if (status === 'saved') {
                    data['status'] = 'saved';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(salesInvoicePost(data, actions))
                    );
                }

                if (status === 'saved-print') {
                    data['status'] = 'saved_and_print';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(salesInvoicePost(data, actions))
                    );
                }

                if (status === 'saved-email') {
                    data['status'] = 'saved_and_email';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save and email this Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(salesInvoicePost(data, actions))
                    );
                }
            } else {
                // update
                // if(status == 'draft') return dispatch(updateSingleSalesInvoice(data, data._id));
                // if(status == 'saved') return SwalWarning('Are You Sure?','You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?', () => dispatch(updateSingleSalesInvoice(data, data._id)))

                // if(status == 'draft' || status == 'draft-print') return dispatch(updateSingleSalesInvoice(data, data._id, actions));

                if (status === 'draft') {
                    data['status'] = 'draft';
                    return dispatch(
                        updateSingleSalesInvoice(data, data._id, actions)
                    );
                }

                if (status === 'draft-print') {
                    data['status'] = 'draft_and_print';
                    return dispatch(
                        updateSingleSalesInvoice(data, data._id, actions)
                    );
                }

                if (status === 'draft-email') {
                    data['status'] = 'draft_and_email';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to draft and email this Sales Invoice. Continue?',
                        () =>
                            dispatch(
                                updateSingleSalesInvoice(
                                    data,
                                    data._id,
                                    actions
                                )
                            )
                    );
                }

                if (status === 'saved') {
                    data['status'] = 'saved';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                updateSingleSalesInvoice(
                                    data,
                                    data._id,
                                    actions
                                )
                            )
                    );
                }

                if (status === 'saved-print') {
                    data['status'] = 'saved_and_print';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                updateSingleSalesInvoice(
                                    data,
                                    data._id,
                                    actions
                                )
                            )
                    );
                }

                if (status == 'saved-email') {
                    data['status'] = 'saved_and_email';
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save and send email this Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                updateSingleSalesInvoice(
                                    data,
                                    data._id,
                                    actions
                                )
                            )
                    );
                }

                // if(status == 'saved' || status == 'saved-print') return SwalWarning('Are You Sure?','You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?', () => dispatch(updateSingleSalesInvoice(data, data._id, actions)))
                // if(status == 'saved-email'){
                //     data['send_to_email'] = true;
                //     return SwalWarning('Are You Sure?','You are about to save and send email this Sales Invoice, saving this will stop you from editing any other changes. Continue?', () => dispatch(updateSingleSalesInvoice(data, data._id, actions)))
                // }
            }
        }
    };

// store
export const salesInvoicePost = (data, actions) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setMUIPage(1));
    try {
        const res = await SalesInvoiceService.SalesInvoiceCreate(data);
        if (res.data.status == 0) {
            ToastDanger(res.data.errors);
        } else if (res.data.status == 1) {
            dispatch(setPage('main_page'));
            ToastSuccess(res.data.message);

            if (actions == 'print') {
                dispatch(setPage('print_modal_si', res.data.id));
            }
        }
        dispatch(setLoading(false));
        $('.btn-save').removeAttr('disabled').html('Save');
    } catch (error) {
        if (error.data.errors.length > 0) {
            ToastDanger(error.data.errors);
        } else {
            ToastDanger('Network error, please reload the page.');
        }
        console.log(error);
    }
};

// update
export const updateSingleSalesInvoice =
    (data, id, actions) => async (dispatch) => {
        dispatch(setLoading(true));
        SalesInvoiceService.updateSingleSalesInvoice(data, id)
            .then((res) => {
                // switch(res.data.status){
                //     case 0:
                //         ToastDanger(res.data.errors);
                //         break;
                //     case 1:
                //         dispatch(setPage('main_page'));
                //         ToastSuccess(res.data.message);
                //         break;
                //     default:
                //         break;
                // }

                if (res.data.status == 0) {
                    ToastDanger(res.data.errors);
                } else if (res.data.status == 1) {
                    dispatch(setPage('main_page'));
                    ToastSuccess(res.data.message);
                    if (actions == 'print') {
                        dispatch(setPage('print_modal_si', res.data.id));
                    }
                }

                dispatch(setLoading(false));
                $('.btn-save').removeAttr('disabled').html('Save');
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                dispatch(setLoading(false));
                console.log(error);
            });
    };

// archieve
export const moveToArchive =
    (pageOrigin = null) =>
    async (dispatch, getState) => {
        let { rows_to_delete } = getState().sales_invoice;
        let id_arr = [];

        rows_to_delete.map((row) => {
            id_arr.push(row.id);
        });
        let params = { ids: JSON.stringify(id_arr) };

        SalesInvoiceService.ArchiveInvoice(params)
            .then((res) => {
                dispatch(setRowChecked());
                switch (res.data.status) {
                    case 0:
                        ToastWarning(res.data.message);
                        if (pageOrigin !== null) {
                            if (pageOrigin['origin'] == 'customer_history') {
                                dispatch(getSalesInvoices(1, null, pageOrigin));
                            }
                        } else {
                            dispatch(clearSalesInvoice());
                            dispatch(getSalesInvoices());
                        }
                        break;
                    case 1:
                        ToastSuccess(res.data.message);
                        if (pageOrigin !== null) {
                            if (pageOrigin['origin'] == 'customer_history') {
                                dispatch(getSalesInvoices(1, null, pageOrigin));
                            }
                        } else {
                            dispatch(clearSalesInvoice());
                            dispatch(getSalesInvoices());
                        }
                    default:
                        break;
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                    console.log(error);
                }
                // ToastDanger('Network error, please reload the page.');
            });
    };

export const archive = () => async (dispatch, getState) => {
    let { single_sales_invoice } = getState().sales_invoice;

    SwalWarning(
        'Warning!',
        `Do you want to remove the Sales Invoice No: ${single_sales_invoice.sales_invoice_no}?`,
        () => dispatch(moveToArchive())
    );
};

export const setModal = (modal, status) => async (dispatch) => {
    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
};

// Check manual
export const handleCheckManual = (e) => async (dispatch, getState) => {
    let { sales_invoice_items } = getState().sales_invoice.single_sales_invoice;
    let checked = e.target.checked;
    let sales_invoice_items_arr = [];

    if (sales_invoice_items.length == 0) {
        dispatch({
            type: HANDLE_CHECK_MANUAL,
            payload: {
                is_manual: checked,
                sales_invoice_items: sales_invoice_items_arr,
            },
        });
    } else {
        SwalWarning(
            'Warning!',
            `Selected item(s) will be overwritten, continue?`,
            () => {
                dispatch({
                    type: HANDLE_CHECK_MANUAL,
                    payload: {
                        is_manual: checked,
                        sales_invoice_items: sales_invoice_items_arr,
                    },
                });
            }
        );
    }
};

// Get quotations
export const getQuotations = () => async (dispatch) => {
    let getParams = { use_select: true };

    SalesInvoiceService.getQuotations(getParams)
        .then((res) => {
            const quotations = res.data.docs;
            const quotation_options = [];

            console.log(quotations)

            quotations.map((q) => {
                let select = { value: q._id, label: q.quotation_no };
                quotation_options.push(select);
            });

            dispatch({ type: SI_GET_QUOTATIONS, payload: quotation_options });
        })
        .catch((error) => {
            // ToastDanger('Network error, please reload the page.');
            console.log(error);
        });
};

// select quotation
export const handleSelectQuotation =
    (selectOption, current_sales_invoice_id) => async (dispatch) => {
        console.log(selectOption)
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        dispatch(setMiniLoading(true));
        SalesInvoiceService.getSingleQuotation(selectOption.value)
            .then((res) => {
                // console.log(res.data)
                let single_quotation = res.data.docs;
                let quotation_items = res.data.docs.quotation_items;
                // Check if the current sales_invoice has an id
                if (current_sales_invoice_id) {
                    // Replace the single_quotation id with the current sales_invoice id
                    single_quotation._id = current_sales_invoice_id;
                }

                if (single_quotation['payment_terms'] == null) {
                    single_quotation['terms'] = {
                        value: '',
                        label: 'Choose Terms',
                    };
                } else {
                    single_quotation['terms'] =
                        single_quotation['payment_terms'];
                }

                if (single_quotation['payment_mode'] == null) {
                    single_quotation['payment_type'] = {
                        value: '',
                        label: 'None',
                    };
                } else {
                    single_quotation['payment_type'] =
                        single_quotation['payment_mode'];
                }

                if (single_quotation.receipt_type == null) {
                    single_quotation['receipt_type'] = {
                        value: 'sales invoice',
                        label: 'Sales Invoice',
                    };
                }

                let edit_attr = {
                    discount: single_quotation.discount
                        ? single_quotation.discount
                        : 0,
                    vat: single_quotation.vat ? single_quotation.vat : 0,
                    other: single_quotation.other
                        ? single_quotation.other
                        : 0.0,
                };

                let default_customer_value = {
                    value: single_quotation.customer._id,
                    label: single_quotation.customer.name,
                };

                let quotation_items_arr = [];

                quotation_items.map((item) => {
                    let formatted = {
                        ...item,
                        _sales_invoice_item_id: item._id,
                        unit_price: item.srp,
                    };
                    quotation_items_arr.push(formatted);
                });

                // quotation select
                single_quotation['quotation_no'] = selectOption.label;
                single_quotation['quotation_id'] = selectOption.value;

                single_quotation.status = null;

                let dataPayload = {
                    single_sales_invoice: single_quotation,
                    sales_invoice_items: quotation_items_arr,
                    edit_attr: edit_attr,
                    default_customer_value: default_customer_value,
                };

                //console.log(dataPayload)

                dispatch({
                    type: HANDLE_SI_SELECT_QUOTATION,
                    payload: dataPayload,
                });
            })
            .catch((error) => {
                ToastDanger('Network error, please reload the page.');
                console.log(error);
                dispatch(setMiniLoading(false));
            });

        dispatch(setMiniLoading(false));
    };

// handle input datepicker
export const SIDatePicker = (date) => async (dispatch) => {
    if (date) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({ type: SET_DATE_PICKER, payload: date });
};

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getSalesInvoices(1, null, pageOrigin));
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    // dispatch(getSalesInvoices(1));
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
    };

export const confirmDeleteDialogBox =
    (pageOrigin) => async (dispatch, getState) => {
        let { rows_to_delete } = getState().quotation;
        let sales_invoice_no = [];
        rows_to_delete.map((rows, i) => {
            sales_invoice_no.push('<b>' + rows.name + '</b>');
        });
        SwalWarningHtml(
            'Warning!',
            `Do you want to remove the Sales Invoice(s): ${sales_invoice_no.join(
                ', '
            )}?`,
            () => dispatch(moveToArchive(pageOrigin))
        );
    };

// set the rows unchecked
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: SET_ROW_CHECKED });

// handle prev, next page of modals
export const goPage = (direction) => async (dispatch, getState) => {
    dispatch(setMiniLoading(true));
    let {
        single_sales_invoice: { _id },
    } = getState().sales_invoice;

    let params = {
        direction,
        _id,
    };

    try {
        const go_to = await SalesInvoiceService.goPage(params);
        dispatch(getSingleSalesInvoice(go_to.data._id));
    } catch (e) {
        console.log(e);
        ToastDanger('Server Error');
        dispatch(setMiniLoading(false));
    }
};

export const add_items = () => async(dispatch, getState) => {
    let { single_sales_invoice, item_template } = getState().sales_invoice;
    let items_data = single_sales_invoice.sales_invoice_items;
    item_template.new = true;

    let errors = [];
    items_data.map(item => {
        if(item.qty <= 0){
            errors.push('Quantity cannot be zero value, please add at least 1' );
        }
        if(item.srp <= 0){
            errors.push('Unit Price cannot be zero value, please include at least the amount');
        }
    });

    if(errors.length > 0){
        ToastDanger(errors);
    }else{
        items_data.push(item_template);
        dispatch({type : ADD_ITEM, payload : items_data});
    }
};

export const dynamicInputChange = (e, index) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { name, value } = e.target;
    let { single_sales_invoice } = getState().sales_invoice;
    let items = { ...single_sales_invoice.sales_invoice_items[index] };

    if (name == 'qty') {
        if (value <= 0) {
            value = 1;
        }
    }
    items[name] = parseFloat(value);
    // if unit_price is change srp will be disregard in the computation
    if (items.srp !== items.unit_price) {
        items.amount = parseFloat(items.qty) * parseFloat(items.unit_price);
    } else {
        items.amount = parseFloat(items.qty) * parseFloat(items.srp);
    }

    if (items.discount_type && items.discount_type.value !== 'none') {
        let discountAmount = 0;
        if (items.discount_type.value === 'percent') {
            discountAmount = (items.discount / 100) * items.amount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = items.discount;
        }
        items.amount = items.amount - discountAmount;
        items.discount_amount = discountAmount ? discountAmount : 0;
    }

    single_sales_invoice.sales_invoice_items[index] = items;
    dispatch({
        type: ADD_ITEM,
        payload: single_sales_invoice.sales_invoice_items,
    });

    let bool = false;
    // po_items.map((data, key) =>{
    //     if(data.cost <= 0 || data.srp <= 0 || data.order_qty <= 0)
    //     {
    //         bool = true;
    //     }
    // })
    // dispatch({type : BTN_SUB, payload : bool});
};

export const clear_customer = () => async (dispatch) => {
    let default_customer_value = { value: '', label: '' };
    dispatch({ type: SI_SET_CUSTOMER_DATA, payload: default_customer_value });
};

export const handleTaxType = (selectOption) => async (dispatch, getState) => {
    let { tax, default_tax } = getState().sales_invoice.single_sales_invoice;

    tax = selectOption.value === 'none' ? 0 : default_tax;

    dispatch({
        type: SI_SELECT_TAX_TYPE,
        payload: { tax, tax_type: selectOption },
    });
};

// COMPUTATION HANDLER
export const computationHandler = () => async (dispatch, getState) => {
    let {
        single_sales_invoice: {
            sales_invoice_items,
            discount,
            discount_type,
            tax,
            tax_type,
            other,
        },
    } = getState().sales_invoice;

    discount = parseFloat(discount) || 0;
    //console.log(discount_type, discount)

    // Subtotal and discount
    const sub_total = sales_invoice_items.reduce(
        (sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp),
        0
    );
    const discounted =
        discount_type.value === 'percent'
            ? (parseFloat(sub_total) * discount) / 100
            : discount;
    const sub_total_discounted = sub_total - discounted + other;

    // Exclusive Vat     // Formula: price * (vat / 100)
    const exclusive_vat = (parseFloat(sub_total_discounted) * tax) / 100;
    //const total_w_exclusive = sub_total_discounted + ((parseFloat(sub_total_discounted) * tax) / 100) + other; // total amount
    const total_w_exclusive =
        sub_total_discounted + (parseFloat(sub_total_discounted) * tax) / 100; // total amount

    // Inclusive Vat   // Formula: price / ( (vat / 100) + 1)
    const inclusive_amount = sub_total_discounted / (tax / 100 + 1);
    const inclusive_vat = sub_total_discounted - inclusive_amount;

    let payload = {};
    switch (tax_type.value) {
        case 'exclusive':
            payload = {
                sub_total: sub_total,
                vat_amount: exclusive_vat,
                total: total_w_exclusive,
            };
            break;
        case 'inclusive':
            payload = {
                sub_total: sub_total,
                vat_amount: inclusive_vat,
                total: sub_total_discounted,
            };
            break;
        default:
            payload = {
                vat_amount: 0.0,
                sub_total: sub_total,
                total: sub_total_discounted,
            };
            break;
    }

    // console.log('COMPUTE HANDLER',payload);
    dispatch({ type: SI_COMPUTATION_HANDLER, payload });
};

export const sendEmail = (e, id) => async (dispatch, getState) => {
    let { email_config } = getState().sales_invoice;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendSingleEmail(id));
        });
    } else {
        dispatch(sendSingleEmail(id));
    }
};
const sendSingleEmail = (id) => async (dispatch, getState) => {
    dispatch({ type: SEND_EMAIL, payload: true });
    let { single_sales_invoice } = getState().sales_invoice;
    SalesInvoiceService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: true });
        })
        .catch((err) => {
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({
                type: RESEND_EMAIL,
                payload: single_sales_invoice.email_sent,
            });
        });
};

export const reSendEmail = (e, id) => async (dispatch, getState) => {
    SwalWarning(
        'Are you sure?',
        'Do you really want to resend this email?',
        () => dispatch(sendEmail(e, id))
    );
};

export const sendEmailDyanmic =
    (e, id, index) => async (dispatch, getState) => {
        let { sales_invoices } = getState().sales_invoice;
        if (sales_invoices[index].email_sent) {
            SwalWarning(
                'Are you sure?',
                'Do you really want to resend this email?',
                () => dispatch(sendByIndex(id, index))
            );
        } else {
            dispatch(sendByIndex(id, index));
        }
    };

const sendByIndex = (id, index) => async (dispatch, getState) => {
    let { email_config } = getState().sales_invoice;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendEmailIndex(id, index));
        });
    } else {
        dispatch(sendEmailIndex(id, index));
    }
};

const sendEmailIndex = (id, index) => async (dispatch, getState) => {
    let { sales_invoices } = getState().sales_invoice;
    let sales = sales_invoices[index];
    sales.sending_email = true;
    sales_invoices[index] = sales;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: sales_invoices });
    SalesInvoiceService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            sales.sending_email = false;
            sales.email_sent = true;
            sales_invoices[index] = sales;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: sales_invoices });
        })
        .catch((err) => {
            // ToastDanger('Failed to send email.');
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            sales.sending_email = false;
            sales_invoices[index] = sales;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: sales_invoices });
        });
};

export const getPdf = (e, id, sales_invoice_no) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_BTN, payload: true });
    SalesInvoiceService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = sales_invoice_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch({ type: DOWNLOAD_BTN, payload: false });
        })

        .catch((err) => {
            dispatch({ type: DOWNLOAD_BTN, payload: false });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const dynamiPdf = (e, id, index) => async (dispatch, getState) => {
    let { sales_invoices } = getState().sales_invoice;
    let sales = sales_invoices[index];
    sales.download_pdf = true;
    sales_invoices[index] = sales;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: sales_invoices });
    SalesInvoiceService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = sales.serial_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            sales.download_pdf = false;
            sales_invoices[index] = sales;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: sales_invoices });
        })

        .catch((err) => {
            sales.download_pdf = false;
            sales_invoices[index] = sales;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: sales_invoices });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const emailConfig = () => async (dispatch) => {
    let x = 0;

    while (x < 3) {
        try {
            const result = await SalesInvoiceService.getEmailConfig();
            dispatch({ type: EMAIL_SETTING_DATA, payload: result.data });
            x = 4;
        } catch (err) {
            // Temporary comment coz connection it timeout
            // ToastDanger('Failed to load email configuration.');
            console.log('error', err);
            x++;
        }
    }
};

const verfiyPass = (callback) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
        };
        SalesInvoiceService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(result.data.message, callback('success'));
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const clickedPaymentWarning = (si) => async (dispatch) => {
    if (si.payment_status === 'paid') {
        SwalVerifyUpdatePayment('Warning!', 'Please enter passcode', () =>
            dispatch(updatePaymentStatus(si))
        );
    } else {
        await dispatch(getSettingApp());
        const subsetting = getSubSetting();

        let priceCode = ''; // initial price value
        if (subsetting.currency.currency_name != null) {
            if (subsetting.currency.code.checked === true) {
                priceCode = subsetting.currency.code.value;
            }

            if (subsetting.currency.symbol.checked === true) {
                priceCode = subsetting.currency.symbol.value;
            }
        }

        SwalUpdatePayment(
            'Warning!',
            `Are you sure you want to mark as "Paid" <br> Sales Invoice No. ${si.serial_no} with the <br> price of ${priceCode}${si.total_price} `,
            () => dispatch(updatePaymentStatus(si))
        );
    }
};

export const updatePaymentStatus = (si) => async (dispatch) => {
    let resolve = {
        message: '',
        status: 0,
    };

    try {
        const res = await SalesInvoiceService.updatePaymentStatus(si);

        if (res.data.status === 1) {
            resolve.status = 1;
            resolve.message = res.data.message;

            dispatch(getSalesInvoices());
        }
    } catch (err) {
        console.log(err);
        ToastDanger('Server Error');
    }

    return resolve;
};

export const cancelSi = () => async (dispatch, getState) => {
    let { single_sales_invoice } = getState().sales_invoice;
    // console.log("single_sales_invoice", single_sales_invoice);
    SwalWarningHtml(
        'Are You Sure?',
        'You are about to cancel Sales Invoice <b>' +
            single_sales_invoice.serial_no +
            '</b>. Continue?',
        () => dispatch(passwordCancel())
    );
};

const passwordCancel = () => async (dispatch) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
        };
        SalesInvoiceService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(
                    result.data.message,
                    dispatch(submitCancel())
                );
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const submitCancel = () => async (dispatch, getState) => {
    let { single_sales_invoice, btn_form } = getState().sales_invoice;
    btn_form.cancel = true;
    dispatch({ type: BTN_FORM, payload: btn_form });
    SalesInvoiceService.cancelInvoice(single_sales_invoice._id)
        .then((result) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            ToastSuccess(
                'Sales Invoice: ' +
                    single_sales_invoice.serial_no +
                    ' cancelled'
            );
            dispatch(setPage('main_page'));
        })
        .catch((err) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            try {
                ToastDanger(err.data.message);
            } catch (err) {
                ToastDanger('Failed to cancel Sales Invoice');
            }
        });
};