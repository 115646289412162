import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    downloadTemplate,
    importProduct,
    searchAttribute,
    selectAttributeTemplate,
} from '../../../redux/actions/product/product.actions';
import { useDropzone } from 'react-dropzone';
import { fileExtensionIcon } from '../../../Utils/Common';
import {
    removeFile,
    handleHover,
    uploadFiles,
    updateFile,
    globalDropDown,
    setProgress,
} from '../../../redux/actions/helper/helper.actions';
import { MiniSpinner } from '../../layouts/Spinner';
import { ProgressBar } from 'react-bootstrap';
import debounce from 'debounce-promise';
import AsyncSelect from 'react-select/async';

function ImportModal({
    show,
    onHide,
    import_product,
    downloadTemplate,
    imported_products,
    finish_import,
    downloading_template,
    progress,
    importProduct,
    searchAttribute,
    selectAttributeTemplate,
    attribute_templates,
    default_attribute_templates_value,
    selected_attribute_template,
}) {
    const [showProgress, setShowProgress] = useState(0);
    useEffect(() => {
        if (progress > 0) {
            setShowProgress(progress);
        }
    }, [progress]);

    useEffect(() => {
        searchAttribute();
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        importProduct(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        disabled: import_product,
    });

    // console.log('attribute_templates', attribute_templates);
    // console.log('selected_attribute_template', selected_attribute_template);
    return (
        <Modal
            centered
            dialogClassName="modal-container"
            show={show}
            onHide={onHide}
            size="md"
            animation={true}
        >
            <Modal.Header closeButton>
                {' '}
                <Modal.Title>Import Products</Modal.Title>{' '}
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center mb-1">
                    <label className="mr-2">Add Template Attribute:</label>
                    <div className="flex-grow-1">
                        <AsyncSelect
                            classNamePrefix="lp-copy-sel"
                            loadingMessage={() => 'Searching...'}
                            defaultOptions={[
                                { value: null, label: 'None' },
                                ...attribute_templates,
                            ]}
                            value={selected_attribute_template}
                            noOptionsMessage={({ inputValue }) =>
                                inputValue
                                    ? 'No Attribute'
                                    : 'Add Template Attribute'
                            }
                            loadOptions={debounce(
                                (value) => searchAttribute(value),
                                1000
                            )}
                            onInputChange={(inputValue) => {
                                if (!inputValue) searchAttribute('');
                            }}
                            onChange={(selectedOption) =>
                                selectAttributeTemplate(
                                    selectedOption || {
                                        value: null,
                                        label: 'None',
                                    }
                                )
                            }
                            placeholder="Select Attribute..."
                            menuPortalTarget={document.body} // Render dropdown in the body
                            menuPosition="fixed" // Fixed positioning for dropdown
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }), // Ensure dropdown is above modal
                            }}
                        />
                    </div>
                </div>

                <div className="row mb-1m">
                    <div className="col-md-12 text-center">
                        {downloading_template ? (
                            <button className="btn btn-blue">
                                <i className="icon-loading-custom icon-15"></i>
                            </button>
                        ) : (
                            <button
                                onClick={downloadTemplate}
                                className="btn btn-blue"
                            >
                                <i className="ft ft-download"></i> Download
                                Template
                            </button>
                        )}
                    </div>
                </div>

                <div className="row mb-1m">
                    <div className="col-md-12 text-center">
                        <div className="col-12 my-2 px-0">
                            <div
                                {...getRootProps()}
                                className={
                                    (import_product ? 'op-25' : '') +
                                    ' file-dropzone-line-print-modal'
                                }
                            >
                                <input {...getInputProps()} />
                                <div className="file-upload-message-print-modal">
                                    Upload Product Template here...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {import_product ? (
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <MiniSpinner />
                        </div>
                    </div>
                ) : finish_import ? (
                    <div className="row">
                        <div className="col-md-12 mb-1m ">
                            <div className=" alert alert-success">
                                {imported_products.success} new product(s) has
                                been imported.
                            </div>
                        </div>
                        {imported_products.duplicate.length > 0 ? (
                            <div className="col-md-12  mb-1m ">
                                <div className="alert alert-danger">
                                    <label>
                                        Duplicate Entries (
                                        {imported_products.duplicate.length})
                                    </label>
                                    <ul>
                                        {imported_products.duplicate.map(
                                            (item, index) => {
                                                return (
                                                    <li key={index}>
                                                        {' '}
                                                        {
                                                            item.product_name
                                                        } ({' '}
                                                        {'SKU : ' +
                                                            item.sku +
                                                            ', Barcode :' +
                                                            item.barcode}{' '}
                                                        )
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    product: state.product,
    progress: state.helper.progress,
    import_product: state.product.import_product,
    downloading_template: state.product.downloading_template,
    imported_products: state.product.imported_products,
    finish_import: state.product.finish_import,
    attribute_templates: state.product.attribute_templates,
    default_attribute_templates_value:
        state.product.default_attribute_templates_value,
    selected_attribute_template: state.product.selected_attribute_template,
});

export default connect(mapStateToProps, {
    downloadTemplate,
    importProduct,
    searchAttribute,
    selectAttributeTemplate,
})(ImportModal);
