import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../../../Utils/Common';
import Select from 'react-select';
import {
    CRCreatePaymentInput,
    collectionPayment,
} from '../../../redux/actions/collection_receipt/collection_receipt.action';
import CollectionReceiptUpload from './CollectionReceiptUpload';
import Spinner from "../../layouts/Spinner";
function CollectionReceiptCreatePaymentModal({
    show,
    onHide,
    cr: {
        single_collection_receipt,
        create_payment,
        payment_method_options,
        isSearchable,
        loading
    },
    CRCreatePaymentInput,
    collectionPayment,
}) {
    const {
        status,
        reference_no,
        reference_id,
        sales_order_no,
        transaction_type,
        due_date_status,
        date,
        due_date,
        total,
        balance,
        customer,
        payment_terms,
    } = single_collection_receipt;

    const {
        payment_date,
        payment_method,
        check_no,
        bank,
        check_date,
        amount,
        upload,
        remarks,
        ref_no,
        account,
        method
    } = create_payment;

    // console.log("amount", amount);
    // console.log("payment_method", payment_method.label);
    return (
        
        <Modal
            centered
            dialogClassName="modal-container"
            show={show}
            onHide={onHide}
            size="md"
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           { loading ? <Spinner/> : <form
                    onSubmit={collectionPayment}
                    className="container-fluid"
                >
                    <div className="d-flex mb-3">
                        <div className="mr-auto">
                            <div className="d-flex">
                                <div className="mr-1">Sales Order No.:</div>{' '}
                                <div className="cr-h-label">{sales_order_no}</div>
                            </div>
                            <div className="d-flex">
                                <div className="mr-1">Customer :</div>{' '}
                                <div className="cr-h-label">
                                    {customer?.name}
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="mr-1">Date:</div>{' '}
                                <div className="cr-h-label">
                                    {moment(date).format('MMMM DD, YYYY')}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="d-flex">
                                <div className="mr-1">Amount:</div>
                                <div className="cr-h-label">
                                    ₱ {numberFormat(total || 0)}
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="mr-1">Balance:</div>{' '}
                                <div className="cr-h-label cr-f-bal">
                                    ₱ {numberFormat(balance || 0)} 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <div className="row">
                            <label
                                for="staticEmail"
                                className="col-sm-4 col-form-label"
                            >
                                Payment Date:
                            </label>
                            <div className="col-sm-8">
                                <input
                                    type="date"
                                    onChange={(e) =>
                                        CRCreatePaymentInput(e, 'payment_date')
                                    }
                                    name="payment_date"
                                    className="form-control"
                                    value={payment_date}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <label
                                for="staticEmail"
                                className="col-sm-4 col-form-label"
                            >
                                Payment Method:
                            </label>
                            <div className="col-sm-8">
                                <Select
                                    options={payment_method_options}
                                    onChange={(e) =>
                                        CRCreatePaymentInput(
                                            e,
                                            'payment_method'
                                        )
                                    }
                                    name="payment_method"
                                    value={payment_method}
                                    isSearchable={isSearchable}
                                    className="cr-index-1"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="row">
                            <label
                                for="staticEmail"
                                className="col-sm-4 col-form-label cr-h-label"
                            >
                                Details:
                            </label>
                        </div>
                        {payment_method.value === 'online' ? ( 
                            <>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Ref No.:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'ref_no')
                                        }
                                        type="text"
                                        name="ref_no"
                                        className="form-control"
                                        value={ref_no}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Method:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'method')
                                        }
                                        type="text"
                                        name="method"
                                        className="form-control"
                                        value={method}
                                    />
                                </div>
                            </div>
                            </>
                        ) : payment_method.value === 'check' ? (
                        <>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Check No:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'check_no')
                                        }
                                        type="text"
                                        name="check_no"
                                        className="form-control"
                                        value={check_no}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Bank:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'bank')
                                        }
                                        type="text"
                                        name="bank"
                                        className="form-control"
                                        value={bank}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Check Date:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'check_date')
                                        }
                                        type="date"
                                        name="check_date"
                                        className="form-control"
                                        value={check_date}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Account:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'account')
                                        }
                                        type="text"
                                        name="account"
                                        className="form-control"
                                        value={account}
                                    />
                                </div>
                            </div>
                        </>
                        ) : payment_method.value === 'bank transfer' ?
                        (
                            <>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Ref No.:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'ref_no')
                                        }
                                        type="text"
                                        name="ref_no"
                                        className="form-control"
                                        value={ref_no}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Bank:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'bank')
                                        }
                                        type="text"
                                        name="bank"
                                        className="form-control"
                                        value={bank}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                                <label
                                    for="staticEmail"
                                    className="col-sm-4 col-form-label pl-1"
                                >
                                    <div className="d-flex justify-content-end">
                                        <span>Account:</span>
                                    </div>
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        onChange={(e) =>
                                            CRCreatePaymentInput(e, 'account')
                                        }
                                        type="text"
                                        name="account"
                                        className="form-control"
                                        value={account}
                                    />
                                </div>
                            </div>
                            </>
                        ) : (
                            <>  </>
                        )}
                        <div className="row mb-1">
                            <label
                                for="staticEmail"
                                className="col-sm-4 col-form-label pl-1"
                            >
                                <div className="d-flex justify-content-end">
                                    <span>Remarks:</span>
                                </div>
                            </label>
                            <div className="col-sm-8">
                                <textarea
                                    onChange={(e) =>
                                        CRCreatePaymentInput(e, 'remarks')
                                    }
                                    type="text"
                                    name="remarks"
                                    className="form-control"
                                    value={remarks}
                                />
                            </div>
                        </div>

                        <div className="row mb-1">
                            <label
                                for="staticEmail"
                                className="col-sm-4 col-form-label pl-1"
                            >
                                <div className="d-flex justify-content-end">
                                    <span>Amount:</span>
                                </div>
                            </label>
                            <div className="col-sm-8">
                                <input
                                    onChange={(e) =>
                                        CRCreatePaymentInput(e, 'amount')
                                    }
                                    value={amount}
                                    name="amount"
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <CollectionReceiptUpload />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mb-1 mt-3">
                        <button className="btn btn-primary" >Save</button>
                    </div>
                </form>}
                
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    cr: state.collection_receipt,
});

export default connect(mapStateToProps, {
    CRCreatePaymentInput,
    collectionPayment,
})(CollectionReceiptCreatePaymentModal);

// export default CollectionReceiptCreatePaymentModal;
