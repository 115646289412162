import React, { useEffect, Fragment } from "react";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { formattedDate } from "../../../../Utils/Common";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {  Show, useAccess } from "react-redux-permission";
import ReactTooltip from 'react-tooltip';
import {Link} from 'react-router-dom';

function EmailLogs () {

    return (
        <div className="card card-p-200">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header">
                <h4 className="card-title">Email Logs  <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements">
                    <ul className="list-inline mb-0"></ul>
                </div>
            </div>
            <div className="card-content collapse show">
                <div className="card-body">
                    {/* <Show when="inv.setting.app.view"> </Show> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    
});

export default connect(mapStateToProps, { })(EmailLogs);
