import { getNumRows } from '../../../Utils/Common';
import * as TYPES from '../../types';
import { InventoryService } from "./_service.inventory.js";


// Set Loading
export const setLoading = status => async dispatch => dispatch({ type: TYPES.SET_LOADING, payload: status });

// Set Modal
export const setModal = (modal, status) => async dispatch => dispatch({ type: TYPES.SET_MODAL, payload: { modal, status } });


export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value })
    // dispatch(getReceiving(1)); 
}

export const getStockLogs = (pageNumber, rows_per_page = null, pageOrigin, date_filter = null) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));

        let { search, pagination, sort_order_name, sort_order_direction } = getState().stock_logs; 
        let rows_per_page_val = rows_per_page != null ? rows_per_page : getNumRows('stock_logs');

    // let params = {
    //   search: search,
    //   activePage: pagination.activePage,
    //   page: pageNumber,
    //   rows_per_page: rows_per_page_val,
    //   sort_order_name: sort_order_name,
    //   sort_order_direction: sort_order_direction,
    // };

      //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
      let params = {
        search: search,
        options: {
          page: pageNumber ? pageNumber : pagination.activePage,
          limit: rows_per_page_val ? rows_per_page_val : 10,
          sort_by: sort_order_name,
          sort_order: sort_order_direction,
        },
        product_id: pageOrigin ? pageOrigin.product_id : null
      };

        // Custom History Date Filtering
    if (date_filter) {
        params.date_from = date_filter["date_from"];
        params.date_to = date_filter["date_to"];
      }

    InventoryService.getStockLogs(params)
      .then((result) => {

        // pagination: { totalCount: 0, activePage : 1, itemsCountPerPage: 1, totalItemsCount: 0, pageRangeDisplayed: 3, search: '' }
        // pagination.activePage = result.data.current_page;
        // pagination.totalCount = result.data.total;
        // pagination.search = search;

        // let pagination = {
        //   totalCount: result.data.total,
        //   activePage: result.data.current_page,
        //   itemsCountPerPage: result.data.per_page,
        //   totalItemsCount: result.data.total,
        // };

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let payload = {
                stock_logs: result.data.docs ,
                pagination: {
                    totalCount: result.data.total_docs, 
                    activePage: result.data.page, 
                    itemsCountPerPage: result.data.limit, 
                    totalItemsCount: result.data.total_docs 
                }
            }

            dispatch({ type: TYPES.GET_STOCK_LOGS_DATA, payload });})

        } catch (err) {
            console.log(err)
            dispatch(setLoading(false));
        }
    
    }
  
  
export const sortTableByColumn = (sort_order_name, sort_order_direction, pageOrigin = null) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getStockLogs(null, null, pageOrigin));
}