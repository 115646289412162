import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import SupplierCreateGlobal from '../../supplier/Supplier.create.global';

function ReceivingSupplierModal({ show, onHide }) {

    return (
        <Modal centered dialogClassName="modal-container-style" show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton dialogClassName="p-0">
	          <Modal.Title></Modal.Title>
	        </Modal.Header>
            <Modal.Body>
                <SupplierCreateGlobal pageOrigin="receiving"/>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
    //
})

export default connect(mapStateToProps, { })(ReceivingSupplierModal);