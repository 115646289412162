import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner, { MiniSpinner } from "../../layouts/Spinner";
import {  Show, useAccess } from "react-redux-permission";
import { numberFormat, onlyNumber } from "../../../Utils/Common";
import { setModal, handleSearchInputMUI, posDaily, posTransactionView, cancelPosTransactionView} from '../../../redux/actions/pos/pos.actions';
import moment from 'moment';
import POSTransactionTable from './POS.transaction.table';
import POSReceipt from './POS.receipt';

function POSTransaction({ pos: { pos_daily, pos_cutoff , pos_cut_off_transactions, pos_user, subs_setting, pos_serial_no, pos_created_at, 
    pos_item_list, pos_sub_total, pos_discount, pos_grand_total, pos_discount_type, pos_loading, pos_transaction_edit,
    pagination, search, loading, sort_order_name, sort_order_direction, set_row_checked  }, 
    handleSearchInputMUI, posDaily, trans_id, setTransID, posTransactionView , cancelPosTransactionView}) {
        
    useEffect(() => {
        posDaily(trans_id);
        
        return () => {
            setTransID(null); // set the trans_id to null from parent component
            cancelPosTransactionView(); // remove unclose card
        }
    },[]);

    return (
        <div className="card">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-content collapse show">
                <div className="card-body">
                    <div className="row pos-receipt-parent">
                        <div className="col-lg-6 col-md-12 pr-0 mb-5 py-5">
                            <div className="mb-2">
                                <h4 className="card-title">POS Daily Transaction <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                <div className="heading-elements">
                                    <ul className="list-inline mb-0"></ul>
                                </div>
                            </div>

                            <div className="my-2 row">
                                <div className="col-12 mb-2">
                                    <div>Date: {pos_cutoff ? moment(pos_daily.date).format('MMMM D, YYYY') : ''}</div>
                                    <div>Location: { pos_cutoff && pos_daily.location }</div>
                                </div>  
                                
                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="pos-details">Opening Time: {pos_cutoff.opening_time && moment(pos_cutoff.opening_time, 'h:mm').format('h:mm A')}</div>
                                    <div className="pos-details">Closing Time: {pos_cutoff.closing_time && moment(pos_cutoff.closing_time, 'h:mm').format('h:mm A')}</div>
                                </div>

                                <div className="col-md-6 col-lg-5 col-12">
                                    <div className="pos-details">Total Sales: &nbsp; {pos_daily.total_sales &&  numberFormat(pos_daily.total_sales)}</div>
                                    <div className="pos-details">Total Cost: &nbsp; {pos_daily.total_costs &&  numberFormat(pos_daily.total_costs)}</div>
                                    <div className="pos-details">Net Profit: &nbsp; {pos_daily.total_profit && numberFormat(pos_daily.total_profit)}</div>
                                </div>

                                <div className="col-12 mb-2">
                                    <div className="pos-details">Cashier: &nbsp; {pos_user && pos_user.admin_name}</div>
                                    <div className="pos-details">Beginning Balance: &nbsp; {pos_daily && pos_daily.beginning_balance}</div>
                                </div>

                                {/* <div className="col-6 mt-3 mb-2">
                                    <div className="form-group row">
                                        <div className="col-md-11 col-10">
                                            <input type="text" name="search" className="form-control" placeholder="Search"/>
                                        </div>
                                        <div className="float-right pl-0 col-2 col-md-1">
                                            <div className="input-group-prepend">
                                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                            </div>
                                        </div>  
                                    </div>
                                </div> */}
                            </div>
                            <POSTransactionTable/>
                            {/* { loading ?  <div className="my-5 py-5 align-self-center"><MiniSpinner/></div> : <POSTransactionTable/> } */}
                        </div>
               
                        <div className="col-lg-6 col-md-12 pl-0">
                            <POSReceipt/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
	pos: state.pos
})

export default connect(mapStateToProps, { setModal, handleSearchInputMUI, posDaily, posTransactionView, cancelPosTransactionView })(POSTransaction);

