
import React, { useEffect, Fragment } from "react";
import Spinner from "../layouts/Spinner";
import { Redirect, useHistory, Link } from 'react-router-dom';
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ProfileInformation from  '../profile/ProfileInformation';

function Profile () {
  
return (
 
<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2  fix-header">
                <h3 className="content-header-title mb-0 d-inline-block text-white">Profile</h3>
            </div>
        </div>

        <div className="content-body margin-top-5 mt-2">
            <section className="row f-regular">
                <div className="col-sm-12">
                    <div className="card">
                        {/*
                        <div className="card-header"></div>
                        */}
                        <div className="card-content collapse show">
                            <div className="card-body">
                                <div className="">
                                    <nav class="nav f-regular">
                                        <Link to={'/account/profile'} class="nav-link inv-top-nav mr-50 inv-top-nav-active">Information</Link>
                                        <Link to={'/account/profile/subcription'} class="nav-link mr-50 inv-top-nav">Billing</Link>
                                        <Link to={'/account/invoice_receipts'} class="nav-link mr-50 inv-top-nav">Sales Invoice/Receipt</Link>                                        
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="basic-examples">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <ProfileInformation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

);

}

const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(Profile);