import { SET_MINI_LOADING , AUTH_USER, REQUIRED_INPUT, BTN_SUB, HANDLE_ITEM_REMOVE_UPLOAD, HANDLE_ITEM_INPUT_UPLOAD, SET_MODAL, 
    AVATAR_MODAL, BOOLEAN_VAR, BOOLEAN_PROFILE, EDIT_AUTH_USER, PROFILE_BTN, PASSWORD_TOGGLE} from '../../types';
import { ToastSuccess, ToastDanger, ToastWarning, ToastQuestion } from '../../../Services/_toast.service';
import { ProfileService } from './_service.profile';
import { confirmAlert } from 'react-confirm-alert';
import {setUser} from '../../../Utils/Common'
import $ from "jquery";

export const load_data = () =>async dispatch =>{
    
    dispatch(setMiniLoading(true));
    ProfileService.getUser().then(res => {
        // console.log("DATA", res.data.user);
        const data = res.data.user;
        let user = {
            name : data.admin_name,
            last_name : data.lname,
            birthday : data ? data.subscriber_birthday : null,
            email : data.admin_email,
            username : data.admin_username,
            contact : data.contact_number,
            image : data.upload == undefined || data.upload  == '' ? '/profile.png' : data.upload[0].file_path,
            upload : data.upload ? data.upload : '',
            current_pass : '',
            new_pass : '',
            confirm_pass : '',
            uploadPreview: data.upload && data.upload[0] ? data.upload[0].file_path : '',
        }
        // console.log("user",user);
        dispatch({type : AUTH_USER, payload: user});
        dispatch(setMiniLoading(false));
    }).catch(err => {
        console.log(err);
        dispatch(setMiniLoading(false));
    })
    
}

export const setMiniLoading = status => async dispatch => dispatch({ type: SET_MINI_LOADING, payload: status });

export const dynamicInputChange = (e) =>  async(dispatch, getState) =>{
    let { name, value } = e.target;
    let {user} = getState().profile;
    
    user[name] = value;
    dispatch({type : AUTH_USER, payload: user});
}


export const setdatePick = (date) => async(dispatch, getState) => {
    
    let {user} = getState().profile;
    user.birthday = date;
    dispatch({type : AUTH_USER, payload: user});
}

export const handleMobile = (mobile) => async(dispatch, getState) => {
    let {user} = getState().profile;
    user.contact = mobile;
    dispatch({type : AUTH_USER, payload: user});
}

export const submit_image = (e) =>async (dispatch, getState) =>
{
    let {user, required} = getState().profile;
    let formdata = new FormData();
    formdata.append('upload', user.upload);
    dispatch({type : BTN_SUB, payload: 'Saving...'});
    ProfileService.uploadProfile(formdata).then(result => {
        setUser(result.data);
        if (result.data && Array.isArray(result.data.upload) && result.data.upload.length > 0) {
            user.image = result.data.upload[0].file_path;
            user.upload_preview = result.data.upload[0].file_path;
            user.upload = result.data.upload;
        } else {
            user.image = '/profile.png';
            user.upload_preview = "";
            user.upload = [];
        }
        dispatch({type : AUTH_USER,  payload : user})
        dispatch(setModal('profile_picture_modal'))
        dispatch({type : BTN_SUB, payload: 'Save'});
        dispatch({type : BOOLEAN_VAR, payload : false})
    }).
    catch(err => {
        console.log("error", err);
        // dispatch({type : BTN_SUB, payload: 'Save'});
    })

    // if(user.upload != '') { formdata.append('upload', user.upload);  } 
}

export const formSubmit = (e) => async (dispatch, getState) => {
    e.preventDefault();
    let {user, required} = getState().profile;
    let pass = true;
    if(user.current_pass != '' || user.new_pass != '' || user.confirm_pass != '')
    {   
        
        if(user.current_pass == '')
        {
            required.current_pass.property = 'input-danger';
            required.current_pass.message = 'Current password is required.';
            pass = false;
        }
        if(user.new_pass == '' )
        {
            required.new_pass.property = 'input-danger';
            required.new_pass.message = 'New password is required.';
            pass = false;
        }
        if(user.confirm_pass == '')
        {
            required.confirm_pass.property = 'input-danger';
            required.confirm_pass.message = 'Confirm password is required.';
            pass = false;
        }
        if(user.new_pass != user.confirm_pass && (user.new_pass != '' || user.confirm_pass != ''))
        {
            required.new_pass.property = 'input-danger';
            required.new_pass.message = 'Password did not match.';
            required.confirm_pass.property = 'input-danger';
            required.confirm_pass.message = 'Password did not match.';
            pass = false;
        }
        
    }
    

    dispatch({type : REQUIRED_INPUT, payload: required});
    if(pass)
    {   
        let formdata = new FormData();
        formdata.append('current_pass', user.current_pass);
        formdata.append('new_pass', user.new_pass);
        dispatch({type : BTN_SUB, payload: 'Saving...'});
        ProfileService.updateCred(formdata).then(result => {
            dispatch({type : BTN_SUB, payload: 'Save'});
            user.current_pass = '';
            user.new_pass = '';
            user.confirm_pass = '';
            dispatch({type : AUTH_USER,  payload : user})
            required.new_pass.property = '';
            required.new_pass.message = '';
            required.confirm_pass.property = '';
            required.confirm_pass.message = '';
            required.current_pass.property = '';
            required.current_pass.message = '';
            dispatch({type : REQUIRED_INPUT, payload: required});

            ToastSuccess('Your password has been updated');
            // window.location.href = '/account/profile';
        }).catch(err => {
            // console.log(err.status);
            dispatch({type : BTN_SUB, payload: 'Save'});
            if(err.status == 401)
            {
                required.current_pass.property = 'input-danger';
                required.current_pass.message = err.data.message;
                dispatch({type : REQUIRED_INPUT, payload: required});
                
            }
        })
    }
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export const removeImage = e => async (dispatch, getState) => {
    e.preventDefault();

    $('#itemUpload').val('');
    dispatch({ type: HANDLE_ITEM_REMOVE_UPLOAD });
    dispatch({type : BOOLEAN_VAR, payload : false})
}

// handle onChange upload image
export const uploadImage = selectedFile => async dispatch => {
    
    let preview = URL.createObjectURL(selectedFile[0]);

    // console.log(preview, selectedFile[0])

    dispatch({ type: HANDLE_ITEM_INPUT_UPLOAD, payload: { upload: selectedFile[0], upload_preview: preview } })
    dispatch({type : BOOLEAN_VAR, payload : true})
}


export const profile_modal = () => async (dispatch, getState) => {
    let {profile_img} = getState().profile;
    profile_img = profile_img ? false : true;
    dispatch({type : AVATAR_MODAL, payload : profile_img})
}

// handle modals
export const setModal = (modal, status) => async dispatch => {

    if(modal == 'profile_picture_modal')
    {
        dispatch({type : AVATAR_MODAL, payload : false})
    }
    
}

export const toggle_profile = (bool) => async (dispatch, getState) => {
    let {user} = getState().profile;
    dispatch({type : BOOLEAN_PROFILE, payload : bool})
    dispatch({type : AUTH_USER,  payload : user})
}

export const update_info = (e) => async (dispatch, getState) => {
    e.preventDefault();
    // console.log("submit");
    let {user, edit_user} = getState().profile;

    // let formdata = new FormData();
    // formdata.append('name', edit_user.name);
    // formdata.append('email', edit_user.email);
    // formdata.append('username', edit_user.username);
    // formdata.append('contact', edit_user.contact);
    dispatch({type : PROFILE_BTN, payload: 'Saving...'});
    ProfileService.updateInfo(edit_user).then(result => {
        dispatch({type : PROFILE_BTN, payload : 'Save'})
        setUser(result.data);
        dispatch({type : AUTH_USER,  payload : edit_user})
        dispatch({type : BOOLEAN_PROFILE, payload : false})
    }).catch(err => {
        console.log("err",err)
        if (err.data.errors.length > 0) {
            err.data.errors.map((err) => ToastDanger(err));
          } else {
            // ToastDanger("Network error, please reload the page.");
          }
        dispatch({type : PROFILE_BTN, payload : 'Save'})
    })
}


export const dynamicInputChangeEdit = (e) =>  async(dispatch, getState) =>{
    let { name, value } = e.target;
    let {edit_user} = getState().profile;
    let input = edit_user;
    
    input[name] = value;
    dispatch({type : EDIT_AUTH_USER, payload : input});
}


export const passwordtoggle = (e) => async(dispatch, getState) => {
    let {password_toggle} = getState().profile;
    let toggle = password_toggle;
   // console.log("param", e);
    toggle[e] = toggle[e] == 'password' ? 'text' : 'password';
    dispatch({type : PASSWORD_TOGGLE, payload : toggle});
}