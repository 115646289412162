import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';
import { search } from '../../../Services/api.laravel.search';

export const PurchaseOrderService = {
    getSinglePurchaseOrder: (id) => http.get('/admin/po/' + id),
    ArchivePurchaseOrder: (formParams) =>
        http.post('/admin/po/archives/list', formParams),
    purchaseOrderPost: (formParams) => http.post('/admin/po', formParams),
    paginateWithSearchPurchaseOrder: (formParams, status) =>
        http.post('/admin/po/search/' + status, formParams),
    updateSinglePurchaseOrder: (formParams, id) =>
        http.put('/admin/po/' + id, formParams),
    getItemSupplier: (id) => http.get('/admin/po/selected/item/' + id), // get supplier item base on id
    getDisplaySupplier: (id) => http.get('/admin/po/selected/' + id), // get the supplier data base on ida
    getSupplier: (formdata) => http.post('admin/po/supplier', formdata), // get the supplier for select option
    getPaymentTerms: () => http.get('admin/term-condition'), // get payment terms
    // getPOTemplates: () => http.get('/admin/setting/purchase-order-setting/get/purchase-order/themes'),
    // searchSupplier : (formParams) => search.post('/admin/supplier/search', formParams),
    // searchSupplier: (formParams) =>
    //     search('/admin/supplier/search', formParams),
    goPage: (formParams) => http.post('/admin/po/go-page', formParams),
    // getProducts: (formdata) => http.post('/admin/product/supplier/search', formdata), // get all the products
    getProducts: (formdata) => http.post('/admin/po/search-product', formdata), // get all the products
    sendEmail: (id) => http.get('/admin/po/email/' + id),
    getPdf: (id) => httpWithFile.get('/admin/po/preview/' + id),
    getEmailConfig: () => http.get('/settings/email/security'),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    getPOTemplates: () => http.get('/admin/po-template'),
    cancelPo: (id) => http.get('/admin/po/cancel/' + id),

    getSingleProduct: (id) => http.get('/admin/po/product/' + id),
};
