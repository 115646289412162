import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { customAlert } from "../../Utils/Confirm";
import InventoryList from "./Inventory.list";
import {
  handleChangeStockView,
  // getInventoryList,
} from "../../redux/actions/inventory/inventory.actions";
import { Show, useAccess } from "react-redux-permission";
import { Link } from "react-router-dom";

function Inventory({
  inventory: { inventory_list, po_request_count, po_request_count_str },
  handleChangeStockView,
}) {
  //** fetching data for all stock views ("all", "low", and "out-of-stock")  */
  // useEffect(() => {
  //   handleChangeStockView("low");
  //   getInventoryList(1, false, null, null);
  //   handleChangeStockView("out-of-stock");
  //   getInventoryList(1, false, null, null);
  //   handleChangeStockView("re-stock");
  //   getInventoryList(1, false, null, null);
  //   handleChangeStockView("all");
  // }, []);
  return (
    <div className='app-content content'>
      <ReactTooltip effect='solid' event='click' />
      <div className='content-wrapper max-1920 mx-auto'>
        <div className='content-wrapper-before'></div>
        <div className='content-header row'>
          <div className='content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header'>
            <h3 className='content-header-title mb-0 d-inline-block'>
              Inventory{" "}
            </h3>

            <div className='breadcrumbs-top d-inline-block'>
              <div className='breadcrumb-wrapper mr-1'>
                <ol className='breadcrumb f-regular'>
                  <li className='breadcrumb-item '>
                    <a href='#' className='text-white'>
                      Lists
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            {/* <Show when="inv.products.items.create">
                          <Link to="/products/items/create"  className="btn btn-primary pull-right btn-fix-header f-regular"><i className="ft ft-plus"></i> Create Product</Link>
                        </Show> */}
          </div>
        </div>
        <div className='content-body margin-top-5'>
          <section id='basic-examples'>
            <div className='row'>
              <div className='col-sm-12'>
                <div id='what-is' className=''>
                  <div className='card-content collapse show'>
                    <div className='card-body no-padding-top no-padding-left-right'>
                      <section className='row'>
                        <div className='col-sm-12'>
                          <ul className='nav nav-tabs f-regular'>
                            {/* old params 'all', '1', '2'. 're-stock' */}
                            <li className=''>
                              <a
                                onClick={() => handleChangeStockView("all")}
                                className='nav-link active'
                                id='base-tab1'
                                data-toggle='tab'
                                aria-controls='tab1'
                                href='#tabs'
                                aria-expanded='true'
                              >
                                All Items
                              </a>
                            </li>
                            <li className=''>
                              <a
                                onClick={() => handleChangeStockView("low")}
                                className='nav-link'
                                id='base-tab2'
                                data-toggle='tab'
                                aria-controls='tab2'
                                href='#tabs'
                                aria-expanded='false'
                              >
                                Low Stock
                              </a>
                            </li>
                            <li className=''>
                              <a
                                onClick={() =>
                                  handleChangeStockView("out-of-stock")
                                }
                                className='nav-link'
                                id='base-tab3'
                                data-toggle='tab'
                                aria-controls='tab3'
                                href='#tabs'
                                aria-expanded='false'
                              >
                                Out of Stock
                              </a>
                            </li>
                            {po_request_count > 0 ? (
                              <li className=''>
                                <a
                                  onClick={() =>
                                    handleChangeStockView("re-stock")
                                  }
                                  className='nav-link nav-notification'
                                  id='restock-tab'
                                  data-toggle='tab'
                                  aria-controls='tab3'
                                  href='#tabs'
                                  aria-expanded='false'
                                >
                                  Re-Stock{" "}
                                  <span className='badge badge-danger badge-pill badge-notification'>
                                    {po_request_count_str}
                                  </span>
                                </a>
                              </li>
                            ) : (
                              <section></section>
                            )}
                          </ul>
                          <div className='tab-content px-1 pt-1 card-box-top card'>
                            <div
                              role='tabpanel'
                              className='tab-pane active table-responsive '
                              id='tabs'
                              aria-expanded='true'
                              aria-labelledby='base-tab1'
                            >
                              <Show when='inv.inventory.all.view'>
                                <InventoryList />
                              </Show>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  inventory: state.inventory,
});

export default connect(mapStateToProps, {
  handleChangeStockView,
  // getInventoryList,
})(Inventory);
