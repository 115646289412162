import {
  SET_LOADING,
  SORT_BY_COLUMN,
  SET_MODAL,
  GET_ROWS_TO_DELETE,
  SET_ROW_CHECKED,
} from "../../types";
import {
  GET_BRANDS,
  BRAND_INPUT_CHANGE,
  GET_SINGLE_BRAND,
  CLEAR_BRAND,
  BRAND_SEARCH,
  SEARCH_INPUT,
  SET_PAGE,
} from "../../reducers/ReducerTypes/brand";
import { ToastSuccess, ToastDanger } from "../../../Services/_toast.service";
import $ from "jquery";
import { BrandService } from "./_service.brand";
import { SwalWarning, SwalWarningHtml } from "../../../Services/_swal.service";
import {
  getSelectAll,
  setModal,
  handleSelect,
} from "../product/product.actions";
import { blockRoute, setMUIPage } from "../helper/helper.actions";
import { getNumRows } from "../../../Utils/Common";

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
  if (e.target.value) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({
    type: BRAND_INPUT_CHANGE,
    payload: { key: e.target.name, value: e.target.value },
  });
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
  dispatch({ type: BRAND_SEARCH, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) =>
  dispatch({ type: SET_LOADING, payload: status });

// clear data
export const clearBrand = () => async (dispatch) =>
  dispatch({ type: CLEAR_BRAND });

// set page
export const setPage =
  (page, id, status = true) =>
  async (dispatch, getState) => {
    let { edit_url } = getState().brand;

    switch (page) {
      case "main_brand_page":
        dispatch({ type: SET_PAGE, payload: { page, status } });
        dispatch(clearBrand());
        break;
      case "edit_page":
        let edit_url_with_id = `${edit_url}/${id}`;
        dispatch({
          type: SET_PAGE,
          payload: { page, status, edit_url: edit_url_with_id },
        });
        break;
      case "delete_modal":
        dispatch(getSingleBrand(id, "delete"));
        break;
      default:
        break;
    }
  };

//  fetch brands
export const getBrands =
  (pageNumber, rows_per_page = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    let { brand_search, pagination, sort_order_name, sort_order_direction } =
      getState().brand;
    let rows_per_page_val =
      rows_per_page !== null ? rows_per_page : getNumRows("brand");

    // let searchPostParams = {
    //   search: brand_search,
    //   activePage: pagination.activePage,
    //   page: pageNumber,
    //   rows_per_page: rows_per_page_val,
    //   sort_order_name: sort_order_name,
    //   sort_order_direction: sort_order_direction,
    // };

    //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
    let searchPostParams = {
      search: brand_search,
      options: {
        page: pageNumber,
        limit: rows_per_page_val || 10,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };

    BrandService.paginateWithSearchBrands(searchPostParams)
      .then((res) => {
        // let pagination = {
        //   totalCount: res.data.brands.total,
        //   activePage: res.data.brands.current_page,
        //   itemsCountPerPage: res.data.brands.per_page,
        //   totalItemsCount: res.data.brands.total,
        // };

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let pagination = {
          totalCount: res.data.total_docs,
          activePage: res.data.page,
          itemsCountPerPage: res.data.limit,
          totalItemsCount: res.data.total_docs,
        };

        dispatch({
          type: GET_BRANDS,
          // payload: { brands: res.data.brands.docs, pagination: pagination },
          payload: { brands: res.data.docs, pagination: pagination },
        });
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        // ToastDanger("Network error, please reload the page.");
        console.log(err);
      });
  };

// fetch single brand
export const getSingleBrand =
  (id, status = null) =>
  async (dispatch) => {
    if (!status) {
      dispatch(setLoading(true));
    }

    BrandService.getSingleBrand(id)
      .then((res) => {
        dispatch({ type: GET_SINGLE_BRAND, payload: res.data.brand });

        if (!status) {
          dispatch(setLoading(false));
        }
      })
      .catch((error) => {
        // ToastDanger("Network error, please reload the page.");
        console.log(error);
        if (!status) {
          dispatch(setLoading(false));
        }
      });
  };

// store
export const brandPost = (e, pageOrigin) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_brand } = getState().brand;
  
  if(pageOrigin === 'item'){
    single_brand['pull'] = true; // this will return the id and brand name
  }

  $(".btn-save").attr("disabled", "disabled").html("Saving...");
  dispatch(setMUIPage(1));
  BrandService.brandPost(single_brand)
    .then((res) => {
      if (pageOrigin == "brand") {
        // dispatch(blockRoute(false));
        dispatch(setPage("main_brand_page"));
      }

      if (pageOrigin == "item") {
        let brandSelect = {
          value: res.data.docs._id,
          label: res.data.docs.name,
        };
        dispatch(handleSelect(brandSelect, "brand_id"));

        dispatch(setModal("brand_modal", false));
        dispatch(getSelectAll());
      }
      dispatch(clearBrand());
      ToastSuccess(res.data.message);
      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      if (error.data?.errors.length > 0) {
        ToastDanger(error.data.errors);
      }
      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    });
};

// update
export const brandUpdate = (e) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_brand } = getState().brand;

  $(".btn-save").attr("disabled", "disabled").html("Saving...");
  BrandService.updateSingleBrand(single_brand, single_brand._id)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          dispatch(setPage("main_brand_page"));
          ToastSuccess(res.data.message);
          break;
        default:
          break;
      }
      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        // ToastDanger("Network error, please reload the page.");
      }
      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    });
};

// archive
export const moveToArchive = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().brand;
  let id_arr = [];
  rows_to_delete.map((row) => {
    id_arr.push(row.id);
  });

  let params = {
    ids: JSON.stringify(id_arr),
  };

  BrandService.ArchiveBrand(params)
    .then((res) => {
      dispatch(setRowChecked());
      ToastSuccess(res.data.message);
      dispatch(getBrands());
      dispatch(clearBrand());
    })
    .catch((error) => {
      // ToastDanger("Network error, please reload the page.");
      console.log(error);
    });
};

export const handleSearchInputMUI = (e) => async (dispatch) => {
  dispatch({ type: BRAND_SEARCH, payload: e.target.value });
};

// Sort by column name and direction
export const sortTableByColumn =
  (sort_order_name, sort_order_direction) => async (dispatch) => {
    let sortingParams = {
      sort_order_name: sort_order_name,
      sort_order_direction: sort_order_direction,
    };

    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getBrands());
  };

// returns selected rows only
export const getSelectedRows =
  (selectedRows, currentRows) => async (dispatch) => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current, i) => {
      selectedRows.map((selected, _i) => {
        if (selected.index == current.index) {
          let selected = { id: current.data[0], name: current.data[1] };
          newArray.push(selected);
        }
      });
    });
    // console.log(newArray);
    dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
  };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().brand;
  let names = [];
  rows_to_delete.map((rows, i) => {
    names.push("<b>" + rows.name + "</b>");
  });

  SwalWarningHtml(
    "Warning!",
    `Do you want to remove the Brand: ${names.join()}?`,
    () => dispatch(moveToArchive())
  );
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
  dispatch({ type: SET_ROW_CHECKED });
