import React, { useEffect, Fragment } from "react";
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function ProfileChangeBilling () {
    
return (
<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                <h3 className="content-header-title mb-0 d-inline-block">Profile</h3>
                <div className="breadcrumbs-top d-inline-block">
                    <div className="breadcrumb-wrapper mr-1">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><a href="#" className="text-white">Billing Period</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div className="content-body margin-top-5">
            <section id="basic-examples">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header mb-0 pb-0">
                                <a href="/account/profile"><i className="ft ft-arrow-left f-regular"></i> Back</a>
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <form>
                                                <section className="row f-regular">
                                                    <div className="col-12 mt-2">
                                                        <label className="label-control f-regular">Billing Period: <span className="text-danger">*</span></label>

                                                        <select className="form-control">
                                                            <option value="grapefruit" selected>Monthly</option>
                                                            <option value="lime">Yearly</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-12 mt-4">
                                                        <div className="form-group row">
                                                            <div className="col-md-7 col-10 text-right"><button type="submit" className="btn btn-primary btn-save f-regular">Save</button></div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

); }


 
const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(ProfileChangeBilling);