import React from 'react'

class PageNotFound extends React.Component {
	render() {
		return (
			<div className="text-center">
				<h1>404 Not Found</h1>
			</div>
		)
	}
}

export default PageNotFound;