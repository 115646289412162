import React, { Fragment, useState, useEffect, useRef } from "react";
import Spinner, { MiniSpinner } from "../../layouts/Spinner";
import Select from 'react-select'
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {  Show, useAccess } from "react-redux-permission";
import { handleInputChange, handleSubmit, getSettingApp, handleContacts, setDefaultWarning } from './../../../redux/actions/settings_app/setting_app.actions';
import { onlyNumber } from '../../../Utils/Common';
import { blockRoute } from "../../../redux/actions/helper/helper.actions";
import BeforeUnloadComponent from 'react-beforeunload-component';
import { SwalFormWarning } from "../../../Services/_swal.service";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

let Asterisk = () => {
    return <span style={{color: "red"}}>*</span>
}

function SettingBusinessProfile({ setting_app: { single_setting_app, mini_loading }, industries, handleInputChange, handleSubmit, getSettingApp, blockRoute, block_route, handleContacts, setDefaultWarning }) {

    const history = useHistory();

    const checkProfileCompleted = () => [single_setting_app.company_name, single_setting_app.company_address, single_setting_app.company_mobile, single_setting_app.company_email].filter(item => item).length === 4

    // check if user filled up all required fields
    const [profileCompleted, setProfileCompleted] = useState(checkProfileCompleted() || false)

    useEffect(() => {
        getSettingApp();
        blockRoute(false);
    },[]);

    useEffect(() => {
        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }
    
    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => window.history.pushState(null, null, window.location.pathname);

    return (
        <BeforeUnloadComponent 
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
                SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
            }}
        >
        <div className="card card-p-200">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header">
                <h4 className="card-title">Business Profile  <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements">
                    <ul className="list-inline mb-0"></ul>
                </div>
                {
                    profileCompleted  ? null : mini_loading ? null :  <p style={{color: "red"}}>Please complete required fields to access inventory</p>
                }
                 <Asterisk/> = Required fields
                
            </div>
            <div className="card-content collapse show">
                <div className="card-body">
                    {
                        mini_loading ? <MiniSpinner/> : 
                            <Show when="inv.setting.app.view">
                                <form onSubmit={e => {handleSubmit('create', e); setProfileCompleted(checkProfileCompleted())}}>
                                    <section className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Company Name <Asterisk/> : </label>
                                                <div className="col-md-7 col-11">
                                                    <input onChange={handleInputChange} value={single_setting_app.company_name} type="text" name="company_name" className="form-control f-regular" placeholder="Company Name" required/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Company Email <Asterisk/> : </label>
                                                <div className="col-md-7 col-11">
                                                    <input onChange={handleInputChange} type="text" name="company_email" value={single_setting_app.company_email} className="form-control f-regular" placeholder="Company Email"/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Company Address <Asterisk/> : </label>
                                                <div className="col-md-7 col-11">
                                                    <textarea onChange={handleInputChange} value={single_setting_app.company_address} name="company_address" rows="3" className="form-control f-regular" placeholder="Company Address"></textarea>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>  
                                 
                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Company Mobile <Asterisk/> : </label>
                                                <div className="col-md-7 col-11">
                                                    <PhoneInput countryCodeEditable={false} enableSearch="true" value={single_setting_app.company_mobile ? single_setting_app.company_mobile : ''} country="ph" inputProps={{ name: 'mobile', required: false, autoFormat:true, enableSearch: true }}  inputClass="react-mobile-input w-100" placeholder="Company Mobile" masks={{ph: '... ... ....'}} onChange={mobile => handleContacts('mobile', mobile)}/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>  

                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Company Landline: </label>
                                                <div className="col-md-7 col-11">
                                                    <input onChange={e => handleContacts('landline', e.target.value)} onKeyPress={e => onlyNumber(e)} value={single_setting_app.company_landline} type="tel" pattern="[0-9]{2}-[0-9]{4}-[0-9]{4}" name="company_landline" className="form-control f-regular" placeholder="e.g. 12-3214-6549"/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="Format: 12-3214-6549"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>  

                                           
                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Company TIN: </label>
                                                <div className="col-md-7 col-11">
                                                    <input onKeyPress={e => onlyNumber(e)} onChange={handleInputChange} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{3}" name="company_tin" value={single_setting_app.company_tin} className="form-control f-regular" placeholder="123-456-789-000"/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="Format: 123-456-789-000"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Industry: </label>
                                                <div className="col-md-7 col-11">
                                                    {/* <input onChange={handleInputChange} type="text" name="additional_information" value={single_setting_app.industry} className="form-control f-regular" placeholder="Additional Information"/> */}
                                                    <Select value={{label: single_setting_app.industry, value: single_setting_app.industry}} options={industries.map(item => ({value: item, label: item}))} onChange={item => handleInputChange({target: {name: "industry", value: item.value}})}/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-4 col-sm-12">Payment Mode: </label>
                                                <div className="col-md-7 col-11">
                                                    {/* <input onChange={handleInputChange} type="text" name="additional_information" value={single_setting_app.industry} className="form-control f-regular" placeholder="Additional Information"/> */}
                                                    <Select value={single_setting_app.payment_mode} options={single_setting_app.payment_mode_options} onChange={(item) => handleInputChange({target: {name: "payment_mode", value: item}})}/>
                                                </div>
                                                <div className="float-right pl-0 col-1 col-md-1">
                                                    <div className="input-group-prepend">
                                                        <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className="float-md-right float-sm-right mr-4 mt-2">
                                                <div className="col-12">
                                                    <button onClick={setDefaultWarning} type="button" className="btn btn-blue mr-1 btn-set-default"> Set As Default</button>
                                                    <button type="submit" className="btn btn-primary btn-save"> Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            </Show>
                    }
                </div>
            </div>
        </div>
        </BeforeUnloadComponent>
    )
}


const mapStateToProps = state => ({
    setting_app: state.setting_app,
    block_route: state.helper.block_route,
    industries: state.auth.industry
});

export default connect(mapStateToProps, { handleInputChange, handleSubmit, getSettingApp, blockRoute, handleContacts, setDefaultWarning })(SettingBusinessProfile);

