import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Redirect, useHistory } from 'react-router-dom';
import { getAttributeTemplates, getSingleAttributeTemplate, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows } from "../../redux/actions/attribute_template/attribute_template.actions";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { formattedDate } from "../../Utils/Common";
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';


function AttributeTemplate ({ attribute_template: { edit_url, attribute_templates, pagination, loading, search, sort_order_name, sort_order_direction, set_row_checked }, getSingleAttributeTemplate, getAttributeTemplates, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows }) {
    const { hasPermission } = useAccess();
    const canDelete = hasPermission("inv.products.attribute-template.delete");
    const canEdit = hasPermission("inv.products.attribute-template.edit");
    const canview = hasPermission("inv.products.attribute-template.view");

    let history = useHistory();

    useEffect(() => { if(canview){getAttributeTemplates();} },[search]);

    function changePage(link) { history.push(link); }

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    '&:nth-child(6)': {
                        height: '61px'
                    }
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(3)': {
                        width: '200px'
                    }
                }
            }
        }
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'name', label: 'Template Name', options : {
            setCellProps : value => {
                return {className : "cursor-pointer"}
              }
            } 
        },
        {name: 'description', label: 'Description' , options : {
          setCellProps : value => {
              return {className : "cursor-pointer"}
            }
          },
          setCellHeaderProps: value => {
            return { className : "cursor-pointer" }
          }
        }, 
        {name: 'created_at', label: 'Created at', options: { 
                print: false , download: false ,
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            }
        },
        {name: 'updated_at', label: 'Updated at', options: { print: false , download: false,
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            } 
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        rowsPerPageOptions: [10,20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `attribute_templates_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
          getAttributeTemplates(null, numberOfRows)
        },
        onTableChange: (action, tableState) => {
            console.log(action, tableState)
            switch (action) {
                case 'changePage':
                  getAttributeTemplates(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if(canDelete)
            {
                confirmDeleteDialogBox();
            }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page    
            if(canEdit)
            {
                changePage(`${edit_url}/${rowData[0]}`);
            }
            
        }
    };

    if(!canDelete)
    {
        options.selectableRows = false;
    }


    return (
        <Fragment>
        <Redirect to="/inventory/products?mode=Attributes"/>
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block"> Products</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "> <a href="/attribute-template" className="text-white f-regular">  Attribute Templates </a> </li>
                                </ol>
                            </div>
                        </div>
                        <Show when="inv.products.attribute-template.create">
                            <Link to="/products/attribute-template/create" className="btn btn-primary pull-right btn-fix-header" ><i className="ft ft-plus"></i> Create New</Link>
                        </Show>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">List of Attribute Templates <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                        <a className="heading-elements-toggle"> <i className="la la-ellipsis-v font-medium-3"></i> </a>
                                        <div className="heading-elements">  <ul className="list-inline mb-0"> </ul> </div>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                            <Show when="inv.products.attribute-template.view">
                                                <MuiThemeProvider theme={getMuiTheme()}>
                                                    <MUIDataTable title={"List of Attribute Templates"} data={attribute_templates} columns={columns} options={options}/>
                                                </MuiThemeProvider> 
                                            </Show>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    attribute_template: state.attribute_template
});

export default connect(mapStateToProps, { getAttributeTemplates, getSingleAttributeTemplate, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows })(AttributeTemplate);
