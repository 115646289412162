import {
    SET_LOADING,
    GET_PRODUCTS,
    PRODUCT_INPUT_CHANGE,
    GET_SINGLE_PRODUCT,
    SET_PAGE,
    CLEAR_PRODUCT,
    SEARCH_INPUT,
    GET_SELECT_ALL,
    PRODUCT_HANDLE_SELECT,
    PRODUCT_ATTR_HANDLE_SELECT,
    HANDLE_UPDATE_ATTR_LIST,
    HANDLE_ATTR_CHECKBOX,
    RESET_ATTRIBUTE_LIST_VALUE,
    SET_MINI_LOADING,
    HANDLE_ITEM_INPUT_UPLOAD,
    HANDLE_ITEM_REMOVE_UPLOAD,
    HANDLE_ITEM_FORM_DISABLE,
    HANDLE_ITEM_SELECT_SUPPLIER,
    HANDLE_SUPPLIER_COST,
    HANDLE_ADD_VOLUME_PRICING,
    HANDLE_REMOVE_VOLUME_PRICING,
    HANDLE_INPUT_VOLUME_PRICING,
    HANDLE_SELECT_DISCOUNT_TYPE_VOLUME_PRICING,
    HANDLE_ON_CHANGE_ITEM_TAGS,
    HANDLE_REMOVE_ITEM_TAG,
    UPDATE_ITEM_TAGS,
    SET_MODAL,
    COPY_SKU_TO_BARCODE,
    EDIT_BARCODE,
    SORT_BY_COLUMN,
    GET_ROWS_TO_DELETE,
    IS_SALE_PRICE,
    POS_GET_PRODUCTS,
    IS_TRACK,
    SET_LOADING_TAG,
    IMPORT_MODAL_PRODUCT,
    IMPORT_PRODUCT,
    DOWNLOADING_TEMPLATE,
    IMPORTED_PRODUCT,
    FINISH_IMPORT,
    SET_ROW_CHECKED,
    UPDATE_SUPPLIER,
    EDIT_COST,
    PRODUCT_UPLOAD_ATTRIBUTE_TEMPLATES,
    SELECT_ATTRIBUTE_TEMPLATE,
} from '../../types';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
    ToastQuestion,
} from '../../../Services/_toast.service';
import $ from 'jquery';
import { ProductService } from './_service.product';
import {
    SwalError,
    SwalSuccessAutoRemove,
    SwalSuccessSupplierRemove,
    SwalWarning,
    SwalWarningHtml,
    SwallverfiyPass,
} from '../../../Services/_swal.service';
import {
    getNumRows,
    getToken,
    getURL,
    getUser,
    getUserData,
    numberFormat,
} from '../../../Utils/Common';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { clearAttributeTemplate } from '../attribute_template/attribute_template.actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: PRODUCT_INPUT_CHANGE,
        payload: { key: e.target.name, value: e.target.value },
    });
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
};

// handle modals
export const setModal = (modal, status) => async (dispatch) => {
    if (modal == 'tag_modal') {
        dispatch(updateItemTags(null, 'tag_modal'));
    }

    if (modal === 'attribute_modal' && status === false) {
        dispatch(clearAttributeTemplate());
    }
    if (modal == 'import_modal') {
        dispatch({ type: IMPORT_MODAL_PRODUCT, payload: status });
    }

    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_MINI_LOADING, payload: status });
export const setLoadingTag = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING_TAG, payload: status });

// handle form_disable
export const handleFormDisable = (status) => async (dispatch) =>
    dispatch({ type: HANDLE_ITEM_FORM_DISABLE, payload: status });

// clear data
export const clearProduct = () => async (dispatch) => {
    dispatch({ type: CLEAR_PRODUCT });
};

// set page
export const setPage =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().product;

        switch (page) {
            case 'main_page':
                dispatch({
                    type: SET_PAGE,
                    payload: { page: page, status: status },
                });
                dispatch(clearProduct());
                // dispatch(resetAttributeListValue());
                break;
            case 'edit_page':
                // dispatch(resetAttributeListValue());
                let edit_url_with_id = `${edit_url}/${id}`;
                dispatch({
                    type: SET_PAGE,
                    payload: { page, status, edit_url: edit_url_with_id },
                });
                break;
            case 'delete_modal':
                dispatch(getSingleProduct(id, 'delete'));
                break;
            default:
                break;
        }
    };

// create select options
const createSelectOptions = (list) => {
    let selectOptions = [];

    list.map((val, i) => {
        let select = {
            label: val.name,
            value: val._id,
            i: i,
            attribute_lists: val.attribute_lists,
        };

        selectOptions[i] = select;
    });
    return selectOptions;
};

// Reset values of attribute_lists
export const resetAttributeListValue = () => async (dispatch, getState) => {
    let { attribute_templates } = getState().product;

    let _attribute_templates = attribute_templates;

    _attribute_templates.map((attr, index) => {
        attr.attribute_lists.map((list, i) => {
            attribute_templates[index].attribute_lists[i].value = undefined;
            attribute_templates[index].attribute_lists[i].options = undefined;
        });
    });

    dispatch({
        type: RESET_ATTRIBUTE_LIST_VALUE,
        payload: _attribute_templates,
    });
};

// handle Select Options
export const handleSelect = (selectOption, name) => async (dispatch) => {
    let default_key = name + '_default';

    if (selectOption.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: PRODUCT_HANDLE_SELECT,
        payload: {
            key: name,
            value: selectOption.value,
            default_key: default_key,
            default_value: selectOption,
        },
    });
};

// handle select supplier
export const handleSelectSupplier =
    (selectOption, origin = 'create') =>
    async (dispatch, getState) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        let { supplier_id_default } = getState().product.single_product;
        let selectOptions = [];
        // adding default cost and initial_stock
        selectOption &&
            selectOption.map((val, i) => {
                let select;
                select = { ...val, cost: 0, initial_stock: 0 };
                selectOptions[i] = select;
            });

        // this will overwrite an existing cost values
        if (supplier_id_default != null) {
            selectOptions.map((item1) => {
                return Object.assign(
                    item1,
                    supplier_id_default.find((item2) => {
                        return item1.label === item2.label;
                    })
                );
            });
        }

        if (origin === 'edit') {
            // ** EDIT PAGE TRIGGER
            let filterArr = selectOptions.filter((sup) =>
                sup.hasOwnProperty('i')
            );

            let checkSupplierParams = filterArr[filterArr.length - 1]; // ** only get the newly selected
            // ** Check Added Supplier API Call
            const result = await ProductService.checkAddSupplier({
                add_supplier: [checkSupplierParams],
            });

            const supplier = result.data.docs[0];
            if (supplier.quantity > 0) {
                SwalWarningHtml(
                    'Warning!',
                    `You\'ve been removed this <b>${supplier.supplier_name}</b> <br/> do you want to restore it?`,
                    () => {
                        selectOptions.map((sup) => {
                            if (sup.value === supplier.supplier_id) {
                                sup['initial_stock'] = supplier.quantity;
                                sup['cost'] = supplier.cost;
                                sup['restored'] = true;
                            }
                        });

                        dispatch({
                            type: HANDLE_ITEM_SELECT_SUPPLIER,
                            payload: selectOptions,
                        });
                    }
                );
            } else {
                dispatch({
                    type: HANDLE_ITEM_SELECT_SUPPLIER,
                    payload: selectOptions,
                });
            }
        } else {
            // ** CREATE PAGE TRIGGER
            dispatch({
                type: HANDLE_ITEM_SELECT_SUPPLIER,
                payload: selectOptions,
            });
        }
    };

// handle on Change Supplier Cost
export const SupplierCostOnChange =
    (index, e) => async (dispatch, getState) => {
        let { supplier_id_default } = getState().product.single_product;

        supplier_id_default[index][e.target.name] = parseFloat(e.target.value);

        // console.log(e.target.value, key)
        dispatch({ type: HANDLE_SUPPLIER_COST, payload: supplier_id_default });
    };

// handle select template and attribute_lists
export const handleSelectAttribute =
    (selectOption, name) => async (dispatch) => {
        // console.log(selectOption)
        let default_key = name + '_default';

        dispatch(setMiniLoading(true));
        dispatch({
            type: PRODUCT_ATTR_HANDLE_SELECT,
            payload: {
                key: name,
                value: selectOption.value,
                default_key: default_key,
                default_value: selectOption,
                attribute_lists: selectOption.attribute_lists,
            },
        });
        dispatch(setMiniLoading(false));
    };

// handle Input and Dropdown of attribute list
export const handleSelectUpdateAttributeList =
    (index, e) => async (dispatch, getState) => {
        let { single_product } = getState().product;

        let attribute_lists = single_product.attribute_lists;
        attribute_lists[index].value = e.target.value;

        dispatch({ type: HANDLE_UPDATE_ATTR_LIST, payload: attribute_lists });
    };

// handle checkbox and radio button of attribute list
export const handleAttrCheckbox =
    (attr_list_index, choice_index, type, e) => async (dispatch, getState) => {
        // 0 "100" "checkbox"
        let { attribute_lists } = getState().product.single_product;

        let selected_attr_list = attribute_lists[attr_list_index];
        let options = selected_attr_list.options;

        if (type == 'radio') {
            options = undefined;
        }

        if (options == undefined) {
            options = {};
            selected_attr_list.choices.map(
                (attr, i) => (options[attr] = 'unchecked')
            );
        }

        if (e.target.checked) {
            options[choice_index] = 'checked';
        } else {
            options[choice_index] = 'unchecked';
        }

        selected_attr_list.options = options;
        attribute_lists[attr_list_index] = selected_attr_list;

        dispatch({ type: HANDLE_ATTR_CHECKBOX, payload: attribute_lists });
    };

// handle onChange upload image
export const uploadImage = (selectedFile) => async (dispatch) => {
    let preview = URL.createObjectURL(selectedFile[0]);

    // console.log(preview, selectedFile[0])

    dispatch({
        type: HANDLE_ITEM_INPUT_UPLOAD,
        payload: { upload: selectedFile[0], upload_preview: preview },
    });
};

export const removeImage = (e) => async (dispatch, getState) => {
    e.preventDefault();

    $('#itemUpload').val('');
    dispatch({ type: HANDLE_ITEM_REMOVE_UPLOAD });
};

// fetch products
export const getProducts =
    (pageNumber, rows_per_page = null, pageOrigin = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));

        let { search, pagination, sort_order_name, sort_order_direction } =
            getState().product;
        let rows_per_page_val =
            rows_per_page != null ? rows_per_page : getNumRows('product');

        // let searchPostParams = {
        //     search: search,
        //     activePage: pagination.activePage,
        //     page: pageNumber,
        //     order_name : sort_order_name,
        //     order_direction : sort_order_direction,
        //     rows_per_page: rows_per_page_val,
        //     supplier_id: pageOrigin ? pageOrigin['supplier_id'] : null // supplier's items
        // }
        // paginate : rows_per_page_val,

        let searchPostParams = {
            search,
            options: {
                page: pageNumber,
                limit: rows_per_page_val || 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        ProductService.paginateWithSearchProducts(searchPostParams)
            .then((res) => {
                let _products = [];
                // res.data.products.data.map((data, index) => {
                //     let product_unit = data.product_unit != null ? data.product_unit.name : '';

                //     let suppliers = [];
                //     data.supplier_items && data.supplier_items.map(_supplier => suppliers.push(_supplier.supplier));

                //     let temp_data = {
                //         _id : data._id,
                //         product_image: data.item_upload ? data.item_upload.file_path : window.location.origin + '/noimage.jpg',
                //         sku : data.sku,
                //         barcode: data.barcode,
                //         product_name: data.product_name,
                //         description: data.description,
                //         attributes: data.attribute_string,
                //         brand: data.brand && data.brand.name,
                //         category: data.category && data.category.name,
                //         uom: data.product_unit && data.product_unit.name,
                //         cost: data.cost,
                //         srp : numberFormat(data.srp),
                //         suppliers: suppliers
                //     }
                //     _products.push(temp_data);
                // });

                res.data.docs.map((data) => {
                    let temp = {
                        _id: data._id,
                        product_image: data.product_image,
                        sku: data.sku,
                        barcode: data.barcode,
                        product_name: data.product_name,
                        description: data.description,
                        attributes: data.attributes,
                        brand: data.brands,
                        category: data.categories,
                        uom: data.product_units,
                        cost: data.cost,
                        srp: numberFormat(data.srp),
                        suppliers: data.suppliers,
                    };
                    _products.push(temp);
                });

                // let pagination = {
                //     totalCount: res.data.products.total,
                //     activePage: res.data.products.current_page,
                //     itemsCountPerPage: res.data.products.per_page,
                //     totalItemsCount: res.data.products.total
                // }

                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit || 10,
                    totalItemsCount: res.data.total_docs,
                };

                // insert to POS reducer
                if (pageOrigin == 'POS') {
                    dispatch({
                        type: POS_GET_PRODUCTS,
                        payload: {
                            products: _products,
                            pagination: pagination,
                        },
                    });
                }

                dispatch({
                    type: GET_PRODUCTS,
                    payload: { products: _products, pagination: pagination },
                });
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                // ToastDanger('Network error, please reload the page.');
                console.log(err);
            });
    };

// fetch all select
export const getSelectAll = () => async (dispatch) => {
    try {
        const result = await ProductService.getSelectAll();

        if (result.data.status == 1) {
            let dataPayload = {
                categories: createSelectOptions(result.data.docs.categories),
                brands: createSelectOptions(result.data.docs.brands),
                suppliers: createSelectOptions(result.data.docs.suppliers),
                product_units: createSelectOptions(
                    result.data.docs.product_units
                ),
                tags: createSelectOptions(result.data.docs.product_tags),
                attribute_templates: createSelectOptions(
                    result.data.docs.attributes_templates
                ),
            };

            dataPayload.attribute_templates.unshift({
                value: '',
                label: 'None',
            });
            // console.log(dataPayload)
            dispatch({ type: GET_SELECT_ALL, payload: dataPayload });
        }
    } catch (err) {
        dispatch(setLoading(false));
        console.log(err);
        // ToastDanger('Network error, please reload the page.');
    }
};

export const skuSkipValidation = () => async (dispatch, getState) => {
    let { sku, _id } = getState().product.single_product;
    try {
        let params = {
            sku: sku,
            product_id: _id ? _id : null,
        };

        await ProductService.skuSkipValidation(params);
        $('#sku').removeClass('is-invalid');
        dispatch(handleFormDisable(true));
    } catch (err) {
        if (err.data.errors) {
            ToastDanger(err.data.errors);
            $('#sku').addClass('is-invalid');
        } else {
            ToastDanger('Network error, please reload the page.');
        }
        console.log(err);
    }
    dispatch(handleFormDisable(false));
};

// fetch single product
export const getSingleProduct =
    (id, status = null) =>
    async (dispatch) => {
        dispatch(setMiniLoading(true));

        ProductService.getSingleProduct(id)
            .then((res) => {
                if (res.data.status == 1) {
                    // console.log(res.data.product);
                    let product = res.data.docs;
                    let brand_id_default = {
                        value: product.brands._id,
                        label: product.brands.name,
                    };
                    let category_id_default = {
                        value: product.categories._id,
                        label: product.categories.name,
                    };

                    // Check if Multiple supplier
                    let supplier_id_default = {};
                    let supplier_id = null;

                    let select_value = [];
                    let suppliers = product.supplier;

                    suppliers.map((sup) => {
                        let initial_stock = sup.initial_stock
                            ? sup.initial_stock
                            : 0;
                        // select_value.push({ supplier_item_id: sup.supplier_id, value: sup.supplier_id, label: sup.name, cost: sup.cost, initial_stock: initial_stock, old: true });
                        select_value.push({
                            value: sup.supplier_id,
                            label: sup.name,
                            cost: sup.cost,
                            initial_stock: initial_stock,
                            product_lot_id: sup.product_lot_id
                                ? sup.product_lot_id
                                : null,
                            old: true,
                        });
                    });

                    supplier_id_default = select_value;
                    supplier_id = select_value;

                    let attribute_template_id_default = '';
                    let attribute_lists = [];
                    let attribute_template = product.attribute_template;
                    if (
                        attribute_template.length > 0 ||
                        attribute_template != ''
                    ) {
                        //  console.log(attribute_template);
                        attribute_template_id_default = {
                            value: attribute_template._attribute_template._id,
                            label: attribute_template._attribute_template.name,
                        };

                        // parent attribute template
                        attribute_template._attribute_template.attributes.map(
                            (parent_attributes, i) => {
                                attribute_lists.push(parent_attributes);
                            }
                        );

                        // loop the attribute_list and assign values from product attribute template which is the child data
                        attribute_lists.map((parent_attr, index) => {
                            attribute_template.attributes.map((child_attr) => {
                                if (parent_attr.id == child_attr.id) {
                                    attribute_lists[index].value =
                                        child_attr.value;
                                    attribute_lists[index].options =
                                        child_attr.options;
                                    attribute_lists[
                                        index
                                    ].product_attribute_id = child_attr._id;
                                }
                            });
                        });
                    } else {
                        attribute_template_id_default = {
                            value: '',
                            label: 'Choose Attribute Template',
                        };
                    }

                    let product_unit_id_default = {};
                    // let item_upload = res.data.product.item_upload ? res.data.product.item_upload.file_path : '';

                    if (product.product_unit !== null) {
                        product_unit_id_default = {
                            value: product.product_units._id,
                            label: product.product_units.name,
                        };
                    }

                    // volume prices
                    let volume_prices = product.volume_pricing;
                    let volume_prices_value = [];

                    if (volume_prices.length > 0) {
                        volume_prices.map((v) => {
                            let discount_type = {};

                            if (v.discount_type == 'fix') {
                                discount_type = {
                                    value: v.discount_type,
                                    label: 'Fix Price',
                                };
                            } else {
                                discount_type = {
                                    value: v.discount_type,
                                    label: 'By Percent',
                                };
                            }

                            let formatted_volume = {
                                volume_price_id: v._id,
                                qty: v.qty,
                                discount_type: discount_type,
                                discount: v.discount,
                            };
                            // let formatted_volume = { _id: v._id, qty: v.qty, discount_type: discount_type, discount: v.discount };

                            volume_prices_value.push(formatted_volume);
                        });
                    } else {
                        volume_prices_value.push({
                            qty: 0,
                            discount_type: { value: 'fix', label: 'Fix Price' },
                            discount: 0,
                        });
                    }

                    // console.log(volume_prices_value);

                    let item_tags = product.product_tags;
                    let item_tags_value = [];
                    if (item_tags.length > 0) {
                        item_tags.map((item) => {
                            let select = {
                                value: item.product_tag_id,
                                label: item.name,
                            };
                            item_tags_value.push(select);
                        });
                    }

                    let upload_preview;
                    let uploaded = null;
                    if (product.uploads) {
                        upload_preview = product.uploads.file_path;
                        uploaded = product.uploads;
                        // ** if file do not exists from the node app directory
                        if (!product.uploads.file_path) {
                            upload_preview = null;
                        }
                    }

                    let fetchData = {
                        _id: product._id,
                        sku: product.sku,
                        product_name: product.product_name,
                        description: product.description,
                        barcode: product.barcode,
                        brand_id: product.brand_id,
                        category_id: product.category_id,
                        supplier_id: supplier_id,
                        product_unit_id: product.product_unit_id,
                        srp: product.srp,
                        delivery_fee: product.delivery_fee,
                        customization_fee: product.customization_fee,
                        low_threshold: product.low_threshold,
                        initial_stock: product.initial_stock,
                        sale_price: product.sale_price,
                        brand_id_default: brand_id_default,
                        category_id_default: category_id_default,
                        supplier_id_default: supplier_id_default,
                        attribute_template_id:
                            product.attribute_template.attribute_template_id,
                        attribute_template_id_default:
                            attribute_template_id_default,
                        attribute_lists: attribute_lists,
                        product_unit_id_default: product_unit_id_default,
                        brand: product.brand,
                        category: product.category,
                        supplier: product.supplier,
                        attribute_template: product.attribute_template,
                        product_unit: product.product_unit,
                        attribute_string: product.attribute_string,
                        upload: uploaded,
                        _uploaded: uploaded,
                        upload_preview,
                        volume_price: volume_prices_value,
                        item_tags: item_tags_value,
                        is_sale_price: product.sale_price > 0 ? true : false,
                        is_track: product.is_track,
                        mute_stock: product.mute_stock,
                        mute_threshold: product.mute_threshold,
                    };

                    dispatch({ type: GET_SINGLE_PRODUCT, payload: fetchData });
                    dispatch(setMiniLoading(false));

                    if (status == 'delete') {
                        dispatch(archive());
                    }
                }
            })
            .catch((error) => {
                dispatch(setMiniLoading(false));
                ToastDanger('Network error, please reload the page.');
                $('.btn-save').removeAttr('disabled').html('Save');
                console.log(error);
            });
    };

// store
export const handleSubmit = (status, e) => async (dispatch, getState) => {
    e.preventDefault();

    let { single_product, item_tags_options } = getState().product;

    let supplier_id_value = '';
    let total_initialStock = 0;
    // check if supplier is array
    if (Array.isArray(single_product.supplier_id)) {
        let supplier_id = [];
        single_product.supplier_id.map((supplier) => {
            supplier_id.push({
                id: supplier.value,
                cost: supplier.cost,
                initial_stock: supplier.initial_stock,
                old: supplier?.old ? supplier.old : false, // This is required so that we know if there is a new supplier added
                product_lot_id: supplier.product_lot_id
                    ? supplier.product_lot_id
                    : null,
                archived: supplier?.archived ? supplier?.archived : null,
                restored: supplier?.restored ? supplier?.restored : null,
            });
            total_initialStock += supplier.initial_stock || 0;
        });

        supplier_id_value = JSON.stringify(supplier_id);
    } else {
        total_initialStock = single_product.supplier_id.initial_stock;
        supplier_id_value = single_product.supplier_id;
    }

    // Volume Pricing
    let volume_price = single_product.volume_price;
    let volume_price_arr = [];
    // do not submit if qty and discount is 0
    volume_price.map((x) => {
        if (x.qty != 0 && x.discount != 0) {
            let formatted_volume_price = {
                volume_price_id: x.volume_price_id,
                qty: x.qty,
                discount_type: x.discount_type.value,
                discount: x.discount,
            };

            volume_price_arr.push(formatted_volume_price);
        }
    });

    // item tags
    let item_tags = single_product.item_tags;

    let formdata = new FormData();
    formdata.append('_id', single_product._id);
    formdata.append('sku', single_product.sku);
    formdata.append('product_name', single_product.product_name);
    formdata.append('description', single_product.description);
    formdata.append('barcode', single_product.barcode);
    formdata.append('brand', single_product.brand_id);
    formdata.append('category', single_product.category_id);
    formdata.append('supplier', supplier_id_value);
    formdata.append(
        'attribute_template',
        single_product.attribute_template_id || ''
    );
    formdata.append('product_unit', single_product.product_unit_id);
    formdata.append('cost', single_product.cost);
    formdata.append('srp', single_product.srp);
    formdata.append('delivery_fee', single_product.delivery_fee);
    formdata.append('customization_fee', single_product.customization_fee);
    formdata.append('low_threshold', single_product.low_threshold);
    formdata.append('initial_stock', total_initialStock);
    formdata.append('sale_price', single_product.sale_price);
    formdata.append('attribute_string', single_product.attribute_string);
    formdata.append(
        'attribute_lists',
        JSON.stringify(single_product.attribute_lists)
    );
    formdata.append('upload_preview', single_product.upload_preview);
    formdata.append('is_sale_price', single_product.is_sale_price);
    formdata.append(
        '_uploaded',
        JSON.stringify(single_product._uploaded) || ''
    ); // has uploaded file
    formdata.append('is_track', single_product.is_track);
    formdata.append('mute_threshold', single_product.mute_threshold);
    formdata.append('mute_stock', single_product.mute_stock);

    // Conditions for submiting data
    if (volume_price_arr.length > 0) {
        formdata.append('volume_price', JSON.stringify(volume_price_arr));
    }
    if (item_tags.length > 0) {
        formdata.append('item_tags', JSON.stringify(item_tags));
    }
    if (single_product.upload != '') {
        formdata.append('upload', single_product.upload);
    }
    if (status == 'post') return dispatch(postProduct(formdata));
    if (status == 'update')
        return dispatch(productUpdate(formdata, single_product._id));
};

// post
export const postProduct = (formdata) => async (dispatch) => {
    $('.btn-save').attr('disabled', 'disabled').html('Saving...');
    dispatch(setMUIPage(1));
    ProductService.productPost(formdata)
        .then((res) => {
            if (res.data.status) {
                dispatch(setPage('main_page'));
                ToastSuccess(res.data.message);
                dispatch(clearProduct());
            }
            $('.btn-save').removeAttr('disabled').html('Save');
        })
        .catch((err) => {
            if (err.data.errors.length > 0) {
                ToastDanger(err.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(err);
        });
};

// update
export const productUpdate = (formdata, id) => async (dispatch) => {
    $('.btn-save').attr('disabled', 'disabled').html('Saving...');
    ProductService.updateSingleProduct(formdata, id)
        .then((res) => {
            if (res.data.status) {
                dispatch(setPage('main_page'));
                ToastSuccess(res.data.message);
                dispatch(clearProduct());
            }
            $('.btn-save').removeAttr('disabled').html('Save');
        })
        .catch((err) => {
            if (err.data.errors.length > 0) {
                ToastDanger(err.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(err);
        });
};

// archieve
export const moveToArchive = () => async (dispatch, getState) => {
    // let { single_product } = getState().product;
    let { rows_to_delete } = getState().product;
    let id_arr = [];
    rows_to_delete.map((row) => {
        id_arr.push(row.id);
    });

    let params = {
        ids: JSON.stringify(id_arr),
    };

    ProductService.ArchiveProduct(params)
        .then((res) => {
            ToastSuccess(res.data.message);
            dispatch(getProducts());
        })
        .catch((err) => {
            if (err.data.errors.length > 0) {
                ToastDanger(err.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }

            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(err);
            dispatch(clearProduct());
        });
    dispatch({ type: SET_ROW_CHECKED }); // THIS UNCHECKED THE COLUMN AFTER DELETED
};

export const archive = () => async (dispatch, getState) => {
    let { single_product } = getState().product;

    SwalWarning(
        'Warning!',
        `Do you want to remove Item SKU: ${single_product.sku}?`,
        () => dispatch(moveToArchive())
    );
};

// Volume Pricing Add Row
export const handleAddVolumePricing = () => async (dispatch, getState) => {
    let { volume_price } = getState().product.single_product;

    let add_volumen_pricing = [
        ...volume_price,
        {
            qty: 0,
            discount_type: { value: 'fix', label: 'Fix Price' },
            discount: 0,
        },
    ];

    dispatch({ type: HANDLE_ADD_VOLUME_PRICING, payload: add_volumen_pricing });
};

// Volume Pricing Remove Row
export const handleRemoveVolumePricing =
    (index) => async (dispatch, getState) => {
        let { volume_price } = getState().product.single_product;

        let list = [...volume_price];
        list.splice(index, 1);

        dispatch({ type: HANDLE_REMOVE_VOLUME_PRICING, payload: list });
    };

//  Volume Pricing Input
export const handleInputChangeVolumePricing =
    (e, index) => async (dispatch, getState) => {
        let { volume_price } = getState().product.single_product;

        let { name, value } = e.target;
        let list = [...volume_price];
        list[index][name] = name == 'qty' ? parseInt(value) : parseFloat(value);

        dispatch({ type: HANDLE_INPUT_VOLUME_PRICING, payload: list });
    };

// Volume Pricing Select Discount Type
export const handleSelectDiscountTypeVolumePricing =
    (selectOption, index) => async (dispatch, getState) => {
        let { volume_price } = getState().product.single_product;

        let list = [...volume_price];
        list[index]['discount_type'] = selectOption;

        dispatch({
            type: HANDLE_SELECT_DISCOUNT_TYPE_VOLUME_PRICING,
            payload: list,
        });
    };

// handle item tags in create page
export const handleOnChangeItemTags =
    (newValue, actionMeta) => async (dispatch) => {
        let newValuesArr = [];

        if (newValue != null) {
            newValue.map((select) => {
                let toLowerCase = {
                    ...select,
                    label: select.label.toLowerCase(),
                    value: select.value.toLowerCase(),
                };
                newValuesArr.push(toLowerCase);
            });
        }

        dispatch({ type: HANDLE_ON_CHANGE_ITEM_TAGS, payload: newValuesArr });
    };

// handle item tags in edit page
export const updateOnChangeItemTags =
    (newValue, actionMeta) => async (dispatch) => {
        // console.log(newValue);
        // console.log(actionMeta);
        let newValuesArr = [];

        if (newValue != null) {
            newValue.map((select) => {
                let toLowerCase = {
                    ...select,
                    label: select.label.toLowerCase(),
                    value: select.value.toLowerCase(),
                };
                newValuesArr.push(toLowerCase);
            });
        }

        // dispatch to remove item per item_id api call
        if (actionMeta.action == 'remove-value') {
            let removeAction = actionMeta.action; // remove-value
            let selectValue = actionMeta.removedValue.item_tag_id; // item_tag_id to removed
            // dispatch(updateItemTags(selectValue, removeAction))
        }

        if (actionMeta.action == 'select-option') {
            let select_option = actionMeta.action; // select_option
            let selectValue = actionMeta.option.value; // item_tag_id to removed
            // dispatch(updateItemTags(selectValue, select_option))
        }

        //  console.log(newValuesArr);

        dispatch({ type: HANDLE_ON_CHANGE_ITEM_TAGS, payload: newValuesArr });
    };

// Update Item Tags use for may process
export const updateItemTags =
    (select_value, origin = null) =>
    async (dispatch, getState) => {
        let {
            single_product: { _id, item_tags },
            removable_tags,
        } = getState().product;

        origin == 'tag_modal' || origin == 'remove_tag'
            ? dispatch(setLoading(true))
            : dispatch(setLoadingTag(true));

        // console.log(select_value, origin);

        try {
            let updateParams;

            if (origin == 'tag_modal') {
                updateParams = { product_id: _id, get_tags: 'get_tags' }; // fetching tags
            } else if (origin == 'remove_tag') {
                updateParams = {
                    product_id: _id,
                    remove_tag: origin,
                    removable_tags: JSON.stringify(removable_tags),
                }; // clieck remove item tag from modal
            } else if (origin == 'remove-value') {
                // updateParams = { product_id: _id, item_tag_id: select_value, remove_value: 'remove_value' }; // click remove tag from select
                updateParams = {
                    product_id: _id,
                    remove_value: 'remove_value',
                }; // click remove tag from select
            } else if (origin == 'select-option') {
                // updateParams = { product_id: _id, item_tag_id: select_value, select_value: 'select_value' }; // click select item
                updateParams = {
                    product_id: _id,
                    select_value: 'select_value',
                }; // click select item
            } else {
                updateParams = { product_id: _id, add_new_tag: select_value }; // default when creating new tag
            }

            const res = await ProductService.updateItemTags(updateParams);
            // console.log(res.data);
            let res_all_tags = res.data.docs.all_tags;
            let res_product_tags = res.data.docs.product_tags;

            let item_tags_arr = [];
            let item_tag_options_arr = [];

            // tags from product
            res_product_tags.map((item) => {
                // let select = { item_tag_id: item._id, value: item.tag._id, label: item.tag.tag_name };
                let select = { value: item.product_tag_id, label: item.name };
                item_tags_arr.push(select);
            });

            // all tags
            res_all_tags.map((tag_opt) => {
                // let tag_options = { value: tag_opt._id, label: tag_opt.tag_name };
                let tag_options = { value: tag_opt._id, label: tag_opt.name };
                item_tag_options_arr.push(tag_options);
            });

            let item_tags_update;
            if (origin == 'get_tags') {
                item_tags_update = [
                    ...item_tags_arr.filter(
                        (tag1) =>
                            !item_tags.find((tag2) => tag1.value === tag2.value)
                    ),
                    ...item_tags,
                ];
            } else {
                item_tags_update = item_tags_arr;
            }

            if (res.data.status == 0) {
                ToastDanger(res.data.errors);
            }

            dispatch({
                type: UPDATE_ITEM_TAGS,
                payload: {
                    item_tags_options: item_tag_options_arr,
                    item_tags_update: item_tags_update,
                },
            });
            origin == 'tag_modal' || origin == 'remove_tag'
                ? dispatch(setLoading(false))
                : dispatch(setLoadingTag(false));
        } catch (err) {
            ToastDanger('Network error, please reload the page.');
            origin == 'tag_modal' || origin == 'remove_tag'
                ? dispatch(setLoading(false))
                : dispatch(setLoadingTag(false));
            console.log(err);
        }
    };

// Remove item tag from modal
export const removeItemTag = (index, id) => async (dispatch, getState) => {
    let { _item_tags_options, removable_tags } = getState().product;

    let removable = [...removable_tags];
    removable.push(id);

    let list = [..._item_tags_options];
    list.splice(index, 1);

    SwalWarning(
        'Warning!',
        `Do you want to remove Item tag: ${_item_tags_options[index].label}?`,
        () => {
            // console.log(list, item_tags_options, removable);
            dispatch({
                type: HANDLE_REMOVE_ITEM_TAG,
                payload: {
                    _item_tags_options: list,
                    removable_tags: removable,
                },
            });
            dispatch(updateItemTags(null, 'remove_tag'));
        }
    );
};

// Product export
export const exportProduct = () => async (dispatch, getState) => {
    ProductService.exportProducts()
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            let current_datetime = new Date();
            let formatted_date =
                ('0' + (current_datetime.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + current_datetime.getDate()).slice(-2) +
                '-' +
                current_datetime.getFullYear();
            let file_name = 'product_exported-' + formatted_date + '.xlsx';
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            ToastDanger('Network error, please reload the page.');
            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(error);
            dispatch(setLoading(false));
        });
};

// SKU copy to Barcode function
export const checkBarcodeValidation =
    (id = null) =>
    async (dispatch, getState) => {
        let { sku, barcode } = getState().product.single_product;

        if (sku == null || sku == '') {
            ToastDanger('SKU does not have a value to copy.');
        } else {
            // let params = {
            //     sku: sku,
            //     id: id
            // }

            let params = {
                barcode: sku,
                id: id,
            };

            ProductService.checkBarcodeValidation(params)
                .then((res) => {
                    switch (res.data.status) {
                        case 1:
                            dispatch({
                                type: COPY_SKU_TO_BARCODE,
                                payload: sku,
                            });
                            break;
                        default:
                            ToastDanger(res.data.message);
                            break;
                    }
                })
                .catch((err) => {
                    if (err.data.errors) {
                        ToastDanger(err.data.errors);
                        console.log(err);
                    } else {
                        ToastDanger('Network error, please reload the page.');
                        console.log(err);
                    }
                });
        }
    };

// Edit Barcode when disabled
export const editBarcode = () => async (dispatch) =>
    dispatch({ type: EDIT_BARCODE });

export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin) => async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getProducts(null, null, pageOrigin));
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[3],
                    };
                    newArray.push(selected);
                }
            });
        });
        dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
    };
export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().product;
    let names = '';
    let name_arr = [];
    // console.log("rows_to_delete", rows_to_delete);
    rows_to_delete.map((rows, i) => {
        name_arr.push('<b>' + rows.name + '</b>');
    });
    names = name_arr.join();

    SwalWarningHtml(
        'Warning!',
        `Do you want to remove the Product ${names}?`,
        () => dispatch(moveToArchive())
    );
};

export const isSalePrice = (e) => async (dispatch, getState) => {
    const { single_product } = getState().product;
    single_product['is_sale_price'] = e.target.checked;

    if (e.target.checked == false) {
        single_product['sale_price'] = 0;
    }

    dispatch({ type: IS_SALE_PRICE, payload: single_product });
};

// Do Not Track
export const DoNotTrackSwitch =
    (event, index) => async (dispatch, getState) => {
        let { single_product } = getState().product;
        let product = single_product;
        product[index] = event;
        single_product = product;
        dispatch({ type: GET_SINGLE_PRODUCT, payload: single_product });
        // dispatch({ type: IS_TRACK, payload: event });
    };

export const importModal = (event) => async (dispatch) => {
    dispatch({ type: IMPORT_MODAL_PRODUCT, payload: true });
};

//downloadTemplate
export const downloadTemplate = () => async (dispatch, getState) => {
    dispatch({ type: DOWNLOADING_TEMPLATE, payload: true });
    try {
        const { selected_attribute_template } = getState().product;
        const formdata = {
            attribute_template_id: selected_attribute_template.value,
        };

        const result = await ProductService.downloadTemplate(formdata);
        const file = new Blob([result.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;

        // Get current date and format it as `MonthDayYear` (e.g., Oct292024)
        const currentDate = new Date();
        const month = currentDate.toLocaleString('default', { month: 'short' });
        const day = currentDate.getDate();
        const year = currentDate.getFullYear();
        const formattedDate = `${month}${day}${year}`;

        // Set filename based on presence of `attribute_template_id`
        const filename = formdata.attribute_template_id
            ? `BiltzUpload-${formattedDate}_${formdata.attribute_template_id}.xlsx`
            : `BiltzUpload-${formattedDate}.xlsx`;

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch({ type: DOWNLOADING_TEMPLATE, payload: false });
    } catch (err) {
        ToastDanger('Error, failed to generate Product-template.xlsx');
        dispatch({ type: DOWNLOADING_TEMPLATE, payload: false });
    }
};

export const importProduct = (file) => async (dispatch) => {
    let form = new FormData();
    form.append('excel', file[0]);
    let progress = 0;
    dispatch({ type: IMPORT_PRODUCT, payload: true });
    dispatch({ type: FINISH_IMPORT, payload: false });
    ProductService.importProduct(form, (event) => {
        progress = Math.round((100 * event.loaded) / event.total);
        // console.log("progress", progress);
    })
        .then((result) => {
            dispatch({ type: IMPORT_PRODUCT, payload: false });
            // console.log('result', result);

            if (result.data.data.length === 0) {
                ToastWarning(
                    'No products were imported, check for duplicate SKU/BARCODE entries'
                );
            } else {
                ToastSuccess('Product(s) has been imported');
            }
            dispatch({ type: IMPORTED_PRODUCT, payload: result.data });
            dispatch({ type: FINISH_IMPORT, payload: true });
            dispatch(getProducts());
        })
        .catch((err) => {
            dispatch({ type: IMPORT_PRODUCT, payload: false });
            console.log('err', err);
            try {
                ToastDanger(err.data.errors[0]);
            } catch (err) {
                ToastDanger('Unknown error occured.');
            }
        });
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: SET_ROW_CHECKED });

// Remove supplier
export const removeSupplier =
    (selectedSupplier, origin) => async (dispatch, getState) => {
        const {
            single_product: { supplier_id, supplier_id_default },
        } = getState().product;

        const newSupplier = supplier_id_default.filter(
            (sup) => sup.value !== selectedSupplier.value
        );

        if (origin === 'edit') {
            SwalWarningHtml(
                'Are You Sure?',
                'You are about to remove <b>' +
                    selectedSupplier.label +
                    '</b>,  Continue?',
                () => dispatch(verifyPassWordForAction(selectedSupplier))
            );
        } else {
            dispatch({
                type: UPDATE_SUPPLIER,
                payload: {
                    supplier_id_default: newSupplier, // to only update the UI
                    supplier_id,
                },
            });
        }
    };

const verifyPassWordForAction =
    (selectedSupplier) => async (dispatch, getState) => {
        const {
            single_product: { supplier, supplier_id_default },
        } = getState().product;

        const update_supplier = supplier_id_default.map((sup) => {
            if (sup.value === selectedSupplier.value) {
                sup['archived'] = true; // this will know that we need to archieved this supplier
            }

            return sup;
        });

        const new_supplier_id_default = supplier_id_default.filter(
            (sup) => sup.value !== selectedSupplier.value
        );

        SwallverfiyPass(async (password) => {
            let formdata = {
                password: password,
                admin_email: getUserData().admin_email,
                id: getUserData()._id,
            };

            try {
                const result = await ProductService.verifyPassword(formdata);
                SwalSuccessSupplierRemove(
                    'Supplier will update after you saved the form.',
                    dispatch({
                        type: UPDATE_SUPPLIER,
                        payload: {
                            supplier_id_default: new_supplier_id_default, // to only update the UI
                            supplier_id: update_supplier,
                        },
                    })
                );
            } catch (e) {
                console.log(e);
                SwalError(e.data.message || 'Server Error, please try again.');
            }
        });
    };

export const editCost = (editable_cost) => async (dispatch, getState) => {
    let cost = editable_cost ? false : true;
    dispatch({ type: EDIT_COST, payload: cost });
};

// get all the select options
export const searchAttribute =
    (search = '') =>
    async (dispatch) =>
        new Promise((resolve, reject) => {
            const formdata = {
                search: search,
            };
            ProductService.searchAttribute(formdata)
                .then((res) => {
                    // console.log('resr', res);
                    let attributes_arr = [];
                    let attribute_templates = res.data.data;

                    attribute_templates.map((val, i) => {
                        let select = {
                            value: val._id,
                            label: val.name,
                            name: 'attribute_template_id',
                        };
                        attributes_arr[i] = select;
                    });
                    resolve(attributes_arr);
                    dispatch({
                        type: PRODUCT_UPLOAD_ATTRIBUTE_TEMPLATES,
                        payload: attributes_arr,
                    });
                })
                .catch((error) => {
                    // ToastDanger("Network error, please reload the page.");
                    console.log(error);
                    reject(error);
                });
        });

// handle Select Attribute Template
export const selectAttributeTemplate =
    (selectOption) => async (dispatch, getState) => {
        let { selected_attribute_template } = getState().product;
        let data = { ...selected_attribute_template };

        data['value'] = selectOption.value;
        data['label'] = selectOption.label;

        dispatch({ type: SELECT_ATTRIBUTE_TEMPLATE, payload: data });
    };
