import { getNumRows } from '../../../Utils/Common';
import * as TYPES from '../../types';
import { ReceivingService } from './_service.receiving';
import { getSinglePurchaseOrder, poClearUpload, clearPurchaseOrder} from '../purchase_order/purchase_order.actions'


// Set Loading
export const setLoading = status => async dispatch => dispatch({ type: TYPES.SET_LOADING, payload: status });

// Set Modal
// export const setModal = (modal, status) => async dispatch => dispatch({ type: TYPES.SET_MODAL, payload: { modal, status } });
export const setModal =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().purchase_order;

        switch (page) {
            case 'main_page':
                dispatch({ type: TYPES.SET_MODAL, payload: { page, status } });
                dispatch(clearPurchaseOrder());
                break;
            case 'edit_page':
                let edit_url_with_id = `${edit_url}/${id}`;
                dispatch({
                    type: TYPES.SET_MODAL,
                    payload: { page, status, edit_url: edit_url_with_id },
                });
                break;
            case 'delete_modal':
                dispatch(getSinglePurchaseOrder(id, 'delete'));
                break;
            case 'print_modal':
                if (status) {
                    dispatch(getSinglePurchaseOrder(id));
                } else {
                    dispatch(poClearUpload());
                }
                dispatch({ type: TYPES.SET_MODAL, payload: { page, status } });
                break;
            default:
            // nothing
        }
    };

// Search
export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value })
    // dispatch(getReceiving(1)); 
}

    /*
        Lot No Data
        - Lot No  => delivery_receives_item ['lot_no']
        - PO No  => delivery_receives ['purchase_order_id']
        - Item Receipt No => delivery_receives ['lot_no']
        - Date Delivered => delivery_receives ['delivery_date']
        - Qty Delivered => delivery_receives_items ['received_qty']
        - Qty Available => delivery_receives_items ['order_qty] // Not Sure
        - Cost => delivery_receives_items ['cost]
        - Expry => delivery_receives_items ['expiry]
    */

// Get Lot No Data
export const getLotNoData = (pageNumber, rows_per_page = null, pageOrigin) => async (dispatch, getState) => {

    try {
        dispatch(setLoading(true));

        let { search, pagination, sort_order_name, sort_order_direction } = getState().lotnodata; 
        let rows_per_page_val = rows_per_page != null ? rows_per_page : getNumRows('lot_no_data');

        // let searchPostParams = {
        //     search: search,
        //     activePage: pagination.activePage,
        //     page: pageNumber,
        //     order_direction: sort_order_direction,
        //     order_name : sort_order_name,
        //     paginate : rows_per_page_val, //rows_per_page
        //     product_id: pageOrigin ? pageOrigin['product_id'] : null
        // }

        let params = {
            search: search,
            options: {
              page: pageNumber ? pageNumber : pagination.activePage,
              limit: rows_per_page_val ? rows_per_page_val : 10,
              sort_by: sort_order_name,
              sort_order: sort_order_direction,
            },
            product_id: pageOrigin ? pageOrigin.product_id : null
          };

        const res = await ReceivingService.getLotNoData(params);

        let lotno_data = [];
        res.data.docs.map(del => {

            let data = {};
            data['_id']                 = del._id;
            data['reference_id']        = del ? del.reference_id : null;
            data['reference_no']        = del ? del.reference_no : null;
            data['item_receipt_no']     = del.item_receipt_no;
            data['date_delivered']      = del.delivery_date;
            data['expiry']              = del.expiry
            del.delivery_receive_items.map(item => {
                data['lot_no']        = item.lot_no;
                data['received_qty'] = item.received_qty;
                data['order_qty'] = item.order_qty;
                data['cost']          = item.cost
                // data['expiry']        = item.expiry_date
            });
            //data['qty_available'] = del.lot_no_item !== null ? (del.lot_no_item.qty !== undefined ? del.lot_no_item.qty : data['qty_available']) : data['qty_available'];
            lotno_data.push(data);
        });

  

        let payload = {
            lotno_data,
            pagination: {
                totalCount: res.data.total_docs, 
                activePage: res.data.page, 
                itemsCountPerPage: res.data.limit, 
                totalItemsCount: res.data.total_docs 
            }
        }

        // console.log(payload);

        dispatch({ type: TYPES.GET_LOT_NO_DATA, payload });
        dispatch(setLoading(false));

    } catch (err) {
        console.log(err)
        dispatch(setLoading(false));
    }

}



export const sortTableByColumn = (sort_order_name, sort_order_direction, pageOrigin = null) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getLotNoData(null, null, pageOrigin));
}