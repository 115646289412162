import React, {Component, Fragment } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { handleSelectAttribute, handleSelectUpdateAttributeList, handleAttrCheckbox, setModal } from './../../redux/actions/product/product.actions';
import ReactTooltip from 'react-tooltip';
import {  Show, useAccess } from "react-redux-permission";

function ProductAttributes ({ product:{ attribute_templates, single_product: { attribute_lists, attribute_template_id_default }}, handleSelectAttribute, handleSelectUpdateAttributeList, handleAttrCheckbox, setModal }){

	// const toObject = (arr) => {
	//   var rv = {};
	//   for (var i = 0; i < arr.length; ++i)
	//     rv[i] = arr[i];
	//   return rv;
	// }
	
	const isset = (accessor) => {
		try { 
			return typeof accessor() !== 'undefined'
	 	} catch (e) { 
		 return false 
		}
	}
	
	const checkIfchecked = (checkbox, options) => {
		if(isset(() => options[checkbox]))
        {
        	if(options[checkbox] == 'checked')
        	{
        		return true;
        	}
        	else
        	{
        		return false;
        	}
        }
        else
        {
        	return false;
        }
	}

	// RENDER INPUT FIELDS
	const renderInput = (attr,index) => {
		return (
			<Fragment>
				<label className="col-md-4 label-control f-regular" title={attr.description}>
					{attr.label} { attr.unit ? '(' + attr.unit + ')' : ""}:
				</label>
				<div className="col-md-8 f-regular">
					<input onChange={(e) => handleSelectUpdateAttributeList(index, e)} type={attr.validation == 'Numeric' ? 'number' : 'text'} id={attr.index} name={attr.label} className="form-control" placeholder={"Enter " + attr.label} value={attr.value}/>
				</div>
			</Fragment>
		);
	}

	// RENDER CHECKBOXES
	const renderCheckbox = (attr, index) => {	
		return (
			<Fragment>
				<label className="col-md-4 label-control f-regular" title={attr.description}>
					{attr.label} { attr.unit ? '(' + attr.unit + ')' : ""}:
				</label>
				<div className="col-md-8">
					{
						attr.choices.map((checkbox, i) => (
						<Fragment key={i}>
							<div className="custom-control custom-checkbox f-regular">
								<input id={attr.label + i} checked={checkIfchecked(checkbox, attr.options)} onChange={e => handleAttrCheckbox(index, checkbox, 'checkbox', e)}value={checkbox} className="custom-control-input" type="checkbox"/>
								<label htmlFor={attr.label + i} className="custom-control-label" data-toggle="tooltip" data-placement="top">{checkbox}</label>
							</div>
						</Fragment>
						))
					}
				</div>
			</Fragment> 
		)
	}

	return (
		<Fragment>
            <ReactTooltip  effect="solid" event="click"/>			
			<h4 className="form-section"> <i className="ft-clipboard"></i> Attributes </h4>
			<section className="row f-regular">
				<div className="col-md-6">
					<div className="form-group row">
						<label className="col-md-4 col-12">
							Attributes Template
						</label>
						<div className="col-md-6 col-9">
							<Select onChange={select => handleSelectAttribute(select, 'attribute_template_id')} options={attribute_templates} value={attribute_template_id_default} name="attribute_template_id" placeholder="Choose Attributes Template..." isSearchable={true}/>
						</div>
						<div className="float-left pl-0 col-1 col-md-1">
							<Show when="inv.products.attribute-template.create">
								<a className="a-tagicon icon-s" onClick={() => setModal('attribute_modal',true)}><i className="ft ft-plus-circle text-primary mt-2"></i></a>
							</Show>
						</div>
						<div className="float-right pl-0 col-1 col-md-1">
                            <div className="input-group-prepend">
                                <span className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>
					</div>															
				</div>

				<div className="col-md-6">
					<div className="form-group row">
						{/* <label className="col-md-8 push-right"> <a href="/products/attribute-template" className="btn btn-md btn-primary"> <i className="ft ft-plus"></i> </a></label> */}
					</div>
					<div className="col-md-4"></div>									
				</div>

				{
					attribute_lists && attribute_lists.length > 0 ? (
						<div className="card card-body border-primary">
							<div className="row">
								{
									attribute_lists.map((attr, index )=> (
										<div className="col-sm-6" key={index}>
											<div className="form-group row">
												{ 
													attr.type == 'Input Box' && 
													renderInput(attr, index)
												}

												{ 
													attr.type == 'Checkbox' && 
													renderCheckbox(attr, index)
												}

												
												{ 
													attr.type == 'Radio' && 
													<Fragment>
														<label className="col-md-4 label-control" title={attr.description}>
															{attr.label} { attr.unit ? '(' + attr.unit + ')' : ""}:
														</label>
														<div className="col-sm-8">
															{
																attr.choices.map((radio, i) => (
																	<Fragment key={i}>
																		<div className="custom-control custom-radio">
																			<input checked={checkIfchecked(radio, attr.options)} onChange={e => handleAttrCheckbox(index, radio, 'radio', e)} value={radio} id={attr.label + i} type="radio" name={attr.label} className="custom-control-input" />
																			<label className="custom-control-label" htmlFor={attr.label + i}>{radio}</label>
																		</div>
																	</Fragment>
																))
															}
														</div>
													</Fragment>
												}

												{ 
													attr.type == 'Dropdown' && 
													<Fragment>
														<label className="col-md-4 label-control" title={attr.description}>
															{attr.label} { attr.unit ? '(' + attr.unit + ')' : ""}:
														</label>
														<div className="col-sm-8">
															<select onChange={e => handleSelectUpdateAttributeList(index, e)} value={attr.value} name={attr.label} className="custom-select">
																<option value="">Choose here ...</option>
																{
																	attr.choices.map((label, index) => (
																		<option key={index} value={label}>{label}</option>
																	))
																}
															</select>
														</div>
													</Fragment>
												}
											</div>
										</div>
									))
								}
							</div>
						</div>
					) : (null)
				}
			</section>
		</Fragment>
	)
}

const mapStateToProps = state => ({
    product: state.product
})

export default connect(mapStateToProps, { handleSelectAttribute, handleSelectUpdateAttributeList, handleAttrCheckbox, setModal })(ProductAttributes);