import React, { useEffect } from 'react';
import Select from 'react-select';
import Spinner from '../../components/layouts/Spinner';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  handleInputChange,
  handleSelectParentCategory,
  updateCategory,
  getSingleCategory,
  getParentCategory,
  parentCategoryCheckbox,
} from '../../redux/actions/category/category.actions';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';

function CategoryEdit({
  category: {
    single_category,
    default_parent_category,
    category_page,
    main_url,
    parent_category,
  },
  handleSelectParentCategory,
  updateCategory,
  handleInputChange,
  getSingleCategory,
  getParentCategory,
  parentCategoryCheckbox,
  match,
  block_route,
  blockRoute,
}) {
  const history = useHistory();
  useEffect(() => {
    getSingleCategory(match.params.id);
    blockRoute(false);
  }, []);

  useEffect(() => {
    // When onClick navbar prevent another dialog box
    window.addEventListener('beforeunload', function (e) {
      // the absence of a returnValue property on the event will guarantee the browser unload happens
      delete e['returnValue'];
      blockRoute(false);
      console.log('01');
    });

    if (block_route) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
      console.log('02');
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block_route]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (block_route) {
      SwalFormWarning(
        'Warning',
        'Do you want to leave without saving',
        () => goLastLocation(),
        () => blockAgain()
      );
    }
  };

  const goLastLocation = () => {
    history.goBack();
    blockRoute(false);
  };

  const blockAgain = () => {
    window.history.pushState(null, null, window.location.pathname);
    console.log('03');
  };

  if (category_page) return <Redirect to={main_url} />;

  console.log(single_category);

  return (
    <BeforeUnloadComponent
      ignoreBeforeunloadDocument={true}
      blockRoute={block_route}
      modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
        SwalFormWarning(
          'Warning',
          'Do you want to leave without saving',
          () => handleModalLeave(),
          () => handleModalCancel()
        );
      }}
    >
      <div className="app-content content">
        <div className="content-wrapper max-1920 mx-auto">
          <div className="content-wrapper-before"></div>
          <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
              <h3 className="content-header-title mb-0 d-inline-block">
                Sales
              </h3>
              <div className="breadcrumbs-top d-inline-block">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb f-regular">
                    <li className="breadcrumb-item">
                      <a href={main_url} className="text-white">
                        Categories
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#" className="text-white">
                        Edit
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body margin-top-5">
            <section id="basic-examples">
              <div className="row f-regular">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header mt-0 mb-0 pb-0">
                      <Link to={main_url}>
                        <i className="ft ft-arrow-left"></i> Back
                      </Link>
                    </div>
                    <div className="card-content collapse show">
                      <div className="card-body pb-4">
                        <div className="row">
                          <div className="col-md-4 col-12">
                            {single_category.length == 0 ? (
                              <Spinner />
                            ) : (
                              <form onSubmit={updateCategory}>
                                <ReactTooltip effect="solid" event="click" />
                                <section className="row f-regular">
                                  <div className="col-12">
                                    <label className="label-control">
                                      Name:{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="form-group row">
                                      <div className="col-md-11 col-10">
                                        <input
                                          onChange={handleInputChange}
                                          value={single_category.name}
                                          type="text"
                                          name="name"
                                          className="form-control"
                                          placeholder="Name.."
                                          required
                                        />
                                      </div>
                                      <div className="pl-0 col-1 col-md-1">
                                        <div className="input-group-prepend">
                                          <span
                                            href="#"
                                            className="tool-tip text-primary"
                                            data-tip="this is a tool tip"
                                          >
                                            <i className="la la-question-circle"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group row">
                                      <div className="col-md-11 col-10">
                                        <div class="custom-control custom-checkbox">
                                          <input
                                            onChange={parentCategoryCheckbox}
                                            type="checkbox"
                                            checked={
                                              single_category.parent_category_checked
                                            }
                                            class="custom-control-input"
                                            id="parent-category"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="parent-category"
                                          >
                                            <strong>Parent Category</strong>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    {!single_category.parent_category_checked && (
                                      <div className="form-group row">
                                        <div className="col-md-11 col-10">
                                          <Select
                                            onChange={
                                              handleSelectParentCategory
                                            }
                                            options={parent_category}
                                            isSearchable={true}
                                            value={default_parent_category}
                                          />
                                        </div>
                                        <div className="pl-0 col-1 col-md-1">
                                          <div className="input-group-prepend">
                                            <span
                                              href="#"
                                              className="tool-tip text-primary"
                                              data-tip="this is a tool tip"
                                            >
                                              <i className="la la-question-circle"></i>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12">
                                    <label className="label-control">
                                      Description:
                                    </label>
                                    <div className="form-group row">
                                      <div className="col-md-11 col-10">
                                        <textarea
                                          onChange={handleInputChange}
                                          value={single_category.description}
                                          name="description"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Description"
                                        ></textarea>
                                      </div>
                                      <div className="pl-0 col-1 col-md-1">
                                        <div className="input-group-prepend">
                                          <span
                                            href="#"
                                            className="tool-tip text-primary"
                                            data-tip="this is a tool tip"
                                          >
                                            <i className="la la-question-circle"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-4 label-control"></label>
                                      <div className="col-md-7 col-10 text-right">
                                        <button
                                          type="submit"
                                          className="btn btn-primary btn-save"
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <div className="pl-0 col-2 col-md-1"></div>
                                    </div>
                                  </div>
                                </section>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </BeforeUnloadComponent>
  );
}

const mapStateToProps = (state) => ({
  category: state.category,
  block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
  blockRoute,
  handleSelectParentCategory,
  updateCategory,
  handleInputChange,
  getSingleCategory,
  getParentCategory,
  parentCategoryCheckbox,
})(CategoryEdit);
