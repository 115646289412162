import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    handleSearchInput,
    redirectTo,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    emailConfig,
    sendEmailDyanmic,
    dynamiPdf,
    confirmDeleteDialogBox,
    autoPrint,
} from '../../redux/actions/receiving/receiving.actions';
import { getLotNoData, setModal } from '../../redux/actions/receiving/lotnodata.actions';
import ReactTooltip from 'react-tooltip';
import {
    formattedDate,
    truncateString,
    numberFormat,
    gettoPrint,
} from '../../Utils/Common';
import { customAlert } from '../../Utils/Confirm';
import { Redirect, useHistory, Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import ReceivingPrintModal from './modals/Receiving.modal.print';
import ReceivingBarcodeModal from './modals/Receiving.modal.barcode';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import { defaultFormat } from '../../Utils/DateFormat';
import PurchaseOrderModalPrint from '../purchase_order/modals/PurchaseOrder.modal.print'

function LotNoData({
    lotnodata: {
        lotno_data,
        pagination,
        search,
        loading,
        sort_order_name,
        sort_order_direction,
        print_modal,
        edit_url
    },
    getLotNoData,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    setModal,
    handleHover,
    hoverIndex,
    updateMUISetting,
    muiPage,
    set_row_checked,
    pageOrigin,
}) {
    const { hasPermission } = useAccess();
    const canCreate = hasPermission('inv.receiving.itemReceipts.create');
    const canDelete = hasPermission('inv.receiving.itemReceipts.delete');
    const canEdit = hasPermission('inv.receiving.itemReceipts.edit');
    const canView = hasPermission('inv.receiving.itemReceipts.view');
    const canPrint = hasPermission('inv.receiving.itemReceipts.print');

    useEffect(() => {
        getLotNoData(muiPage, null, pageOrigin);
        emailConfig();
        autoPrint();
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getLotNoData(1, null, pageOrigin);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const history = useHistory();
    const btnDelete = (id, lot_no) => {
        var message = 'You are about to remove ' + lot_no + '. Continue?';
        customAlert('Are you sure?', message, function () {
            // /(id);
        });
    };

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles((theme) => ({
        centeredHeader: {
            '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
        },
    }));
    const classes = useStyles();

    const changePage = (link) => {
        history.push(link);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(5)': {
                            height: '61px',
                        },
                        '&:nth-child(11)': {
                            width: '350px',
                        },
                    },
                },
                MUIDataTableHeadCell: {},
            },
        });
    // console.log("receiving_print", receiving_print);
    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'lot_no',
            label: 'Lot No.',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //  setModal('print_modal', lotno_data[cellMeta.dataIndex]._id, true);
                    return <Link className="txt-underline">{value}</Link>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: 'reference_no',
            label: 'Reference No.',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let reference_id =
                        lotno_data[tableMeta['rowIndex']].reference_id;
                    if (reference_id) {
                        return (
                            <Link
                                className="txt-underline"
                                onClick={() => setModal('print_modal', reference_id)}
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return <span>{value}</span>;
                    }
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: 'item_receipt_no',
            label: 'Item Receipt No.',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: 'date_delivered',
            label: 'Date Delivered',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{defaultFormat(value)}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: 'order_qty',
            label: 'Ordered Qty',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: 'received_qty',
            label: 'Received Qty',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: 'cost',
            label: 'Cost',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: 'expiry',
            label: 'Expiry',
            options: {
                display: true,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value ? defaultFormat(value) : 'N/A'}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        // selectableRows: false,
        filter: true,
        search: false,
        searchOpen: true,
        filter: false,
        responsive: 'stacked',
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `lotNo_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getLotNoData(null, numberOfRows, pageOrigin);
            updateMUISetting('rows', 'receiving', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getLotNoData(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'receiving',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            confirmDeleteDialogBox();
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page
            // changePage(`${edit_url}/${rowData[0]}`)
        },
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex != 6) {
                if (!canPrint) {
                    return false;
                }
                // changePage(`${edit_url}/${lotno_data[cellMeta.dataIndex]._id}`);
                //  setModal('print_modal', lotno_data[cellMeta.dataIndex]._id, true);
            }
        },
        onFilterChange: (column, filterList, type) => {
            // console.log(column, filterList, type);
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onChange={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    const _columns = updateMUISetting(
        'view-columns',
        'lotno_data',
        null,
        columns
    );


    return (
        <>
            <Show when="inv.receiving.itemReceipts.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={lotno_data}
                        columns={_columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>
            <PurchaseOrderModalPrint
                show={print_modal}
                onHide={() => setModal('print_modal', null, false)}
                edit_url={edit_url}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    lotnodata: state.lotnodata,
    hoverIndex: state.helper.hoverIndex,
});

export default connect(mapStateToProps, {
    getLotNoData,
    sortTableByColumn,
    handleSearchInputMUI,
    setModal,
    handleHover,
    updateMUISetting,
})(LotNoData);
