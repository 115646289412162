import React from 'react';

function CardContent({ iconClass, value, cardName }) {
    return (
        <div className="card-body">
            <div className="media">
                <div className="align-self-top">
                    <i className={iconClass}></i>
                </div>
                <div className="media-body text-right align-self-bottom mt-3">
                    <span class="d-block mb-1 font-medium-1">{cardName}</span>
                    <h1 className="mb-0">{value}</h1>
                </div>
            </div>
        </div>
    );
}

export default CardContent;
