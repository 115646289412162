import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { formattedDate, numberFormat } from '../../Utils/Common';
import {
    getCollectionReceipt,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    CRHandleSelect,
    getCRPaymentTerms,
    CRDateFilterInput,
    CRClearFilterInputs,
    CRHandleInputChange,
    CRTopSearchButton,
    CRSetModal,
    setSelectedPayment 
} from '../../redux/actions/collection_receipt/collection_receipt.action';
import { Redirect, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import moment from 'moment';
import Select from 'react-select';
import Drawer from '@material-ui/core/Drawer';
import CollectionReceiptDrawer from './CollectionReceiptDrawer';
import CollectionReceiptCreatePaymentModal from './modals/CollectionReceiptCreatePaymentModal';
import PaymentModal from './modals/PaymentModal';

function PaymentTable({
    collection_receipt: {
        collection_receipts,
        cr_search,
        loading,
        edit_url,
        edit_page,
        sort_order_name,
        sort_order_direction,
        set_row_checked,
        payment_modal
    },
    paymentHistory, 
    pagination,
    getCollectionReceipt,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    hoverIndex,
    handleHover,
    updateMUISetting,
    setMUIPage,
    muiPage,
    getCRPaymentTerms,
    CRHandleSelect,
    CRSetModal,
    setSelectedPayment 
}) {
    const { hasPermission } = useAccess();
    const canCreate = true;
    const canDelete = true;
    const canEdit = true;
    const canview = true;

    const history = useHistory();
    useEffect(() => {
        getCollectionReceipt(muiPage);
        getCRPaymentTerms();
    }, []);

    // START DEVOUNCED SEARCH
    // const debouncedSearch = useDebounce(cr_search, 800);
    // const [oldDebounced, setOldDebounced] = useState('');
    // useEffect(() => {
    //     if (debouncedSearch) {
    //         if (oldDebounced !== debouncedSearch[0]) {
    //             if (debouncedSearch[0].length !== 1) {
    //                 setOldDebounced(debouncedSearch[0]);
    //                 getCollectionReceipt(1);
    //             }
    //         }
    //     }
    // }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    if (edit_page) return <Redirect to={edit_url} />;

    const changePage = (link) => {
        history.push(link);
    };

    const handleSelectFunc = (selectValue) => {
        CRHandleSelect(selectValue);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(6)': {
                            height: '61px',
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        '&:nth-child(1)': {
                            width: '140px',
                        },
                        '&:nth-child(2)': {
                            width: '140px',
                        },
                    },
                },
            },
        });

        const columns = [
            {
                name: 'created_at',
                label: 'Payment Date',
                options: {
                    display: true,
                    print: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                {value ? moment(value).format('MM/DD/YYYY'): 'N/A'}
                            </>
                        );
                    },
                },
            },
            {
                name: 'payment_method',
                label: 'Method',
                options: {
                    display: true,
                    print: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <>{value['label']}</>;
                    },
                },
            },
            {
                name: 'amount',
                label: 'Amount',
                options: {
                    display: true,
                    print: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span> ₱ {numberFormat(value)}</span>;
                    },
                },
            },
            {
                name: 'balance',
                label: 'Balance',
                options: {
                    display: true,
                    print: false,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className=' cr-f-bal'> ₱ {numberFormat(value)}</span>;
                    },
                },
            },
        ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        // searchOpen: true,
        elevation: 2,
        filter: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `brands_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getCollectionReceipt(null, numberOfRows);
            updateMUISetting('rows', 'collection_receipts', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getCollectionReceipt(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'collection_receipts',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }

            return false;
        },
        // onRowClick: (rowData, rowMeta) => {
        //     // Go to edit page when row is clicked
        //     if (canEdit) {
        //         // changePage(`${edit_url}/${rowData[0]}`);
        //     }
        // },
        onRowClick: (rowData, rowMeta) => {
            const selectedPaymentData = paymentHistory[rowMeta.dataIndex];
            setSelectedPayment(selectedPaymentData);
            CRSetModal('payment_modal', true);
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        value={cr_search}
                        onChange={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'payment_history',
        null,
        columns
    );

    return (
        <>
            {' '}
            <div className="card-content collapse show">
            <div className="content-header row px-2">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block payment-history">
                            Collection{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                            Payments
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body cr-index-1">
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            data={paymentHistory} 
                            columns={_columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
                <PaymentModal
                    show={payment_modal}
                    onHide={() => CRSetModal('payment_modal', false)}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    collection_receipt: state.collection_receipt,
    // hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getCollectionReceipt,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
    getCRPaymentTerms,
    CRHandleSelect,
    CRDateFilterInput,
    CRHandleInputChange,
    CRTopSearchButton,
    CRClearFilterInputs,
    CRSetModal,
    setSelectedPayment 
})(PaymentTable);
