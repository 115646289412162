import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Spinner from '../layouts/Spinner';
import { Redirect, Prompt, Link } from 'react-router-dom';
import BrandCreateGlobal from './Brand.create.global';
import { clearBrand, handleInputChange } from '../../redux/actions/brand/brand.actions';
import { customAlert } from '../../Utils/Confirm';
import NavigationPrompt from "react-router-navigation-prompt";
import { SwalFormWarning } from '../../Services/_swal.service';


function BrandCreate({ brand: { single_brand, main_url, main_page, }, clearBrand }) {

    if(main_page) return <Redirect to={main_url}/>

    return (
        <div className="app-content content">
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Sales</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item"><Link to={main_url} className="text-white">Brands</Link></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">Create</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        {/* <Link to={main_url}><i className="ft ft-arrow-left f-regular"></i> Back</Link> */}
                                        <Link to={main_url}><i className="ft ft-arrow-left f-regular"></i> Back</Link>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <BrandCreateGlobal pageOrigin="brand"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    brand: state.brand
});

export default connect(mapStateToProps, { clearBrand })(BrandCreate);