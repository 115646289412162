import {
    SET_LOADING,
    SORT_BY_COLUMN,
    GET_ROWS_TO_DELETE,
    SET_ROW_CHECKED,
    GET_STATUS,
    // SET_SUCCESS,
} from './../types';

import * as TYPES from './ReducerTypes/collectionReceipt';
import moment from 'moment';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    tabs: {
        all: 'all',
        upcoming: 'upcoming',
        due: 'due',
        overdue: 'overdue',
    },
    tab_name: 'all',
    filters: {
        due_date_string: '',
        status: '',
        payment_terms: '',
    },
    cr_search: '',
    collection_receipts: [],
    status_view: 'all',
    pagination_list: {'0' : [], '1' : [], '2': [], 'all': []},
    single_collection_receipt: [],
    isSearchable: true,
    status_options: [
        { origin: 'status', value: '', label: 'None' },
        { origin: 'status', value: 'unpaid', label: 'Unpaid' },
        { origin: 'status', value: 'partial', label: 'Partial' },
        { origin: 'status', value: 'paid', label: 'Paid' },
    ],
    status_value: { value: '', label: 'Status' },
    payment_terms_options: [],
    payment_terms_value: { value: '', label: 'Terms' },
    filter_key_value: null, // { status: 'unpaid' } can have multiple objects
    loading: false,
    // success:false,
    drawer_toggle: false,
    main_collection_receipt_page: false,
    transaction_types: {
        sales_invoice: 'sales_invoice',
        delivery_receipt: 'delivery_receipt',
        collection_receipt: 'collection_receipt',
    },
    create_payment: {
        payment_date: moment(new Date()).format('YYYY-MM-DD'), // default is current date
        payment_method: { value: '', label: 'None' },
        check_no: '',
        bank: '',
        check_date: '',
        amount: '',
        upload: '',
        upload_preview: '',
        remarks: '',
        ref_no: '',
        account: '',
        method: ''
    },
    payment_method_options: [
        { value: 'cash', label: 'Cash' },
        { value: 'online', label: 'Online' },
        // { value: 'credit card', label: 'Credit Card' },
        { value: 'cod', label: 'COD' },
        { value: 'check', label: 'Check' },
        { value: 'bank transfer', label: 'Bank Transfer' },
    ],
    create_payment_modal: false,
    payment_modal: false,
    edit_page: false,
    delete_modal: false,
    main_url: '/collections/collection-receipts',
    create_url: '/collections/collection-receipts/create',
    edit_url: '/collections/collection-receipts/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    formChange: false,
    selectedPayment: null,
};

const collectionReceiptReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_COLLECTION_RECEIPT:
            return {
                ...state,
                collection_receipts: action.payload.collection_receipts,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case TYPES.GET_CR_STATUS:
            return {
                ...state,
                collection_receipts: action.payload.collection_receipts,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            };
        case TYPES.GET_SINGLE_COLLECTION_RECEIPT:
            return {
                ...state,
                single_collection_receipt: action.payload,
            };
        case TYPES.CR_INPUT_CHANGE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        case TYPES.CR_SEARCH:
            return {
                ...state,
                cr_search: action.payload,
            };
        case GET_STATUS:
            return {
                ...state,
                status_view: action.payload
            }
        case TYPES.TAB_VIEW_CHANGE:
            return {
                ...state,
                tab_name: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        // case SET_SUCCESS:
        //     return {
        //         ...state,
        //         success: action.payload,
        //     };
        case TYPES.SET_PAGE:
            if (action.payload.page == 'main_collection_receipt_page') {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                };
            } else if (action.payload.page == 'edit_page') {
                return {
                    ...state,
                    main_collection_receipt_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case TYPES.CR_HANDLE_SELECT:
            return {
                ...state,
                [action.payload.key]: action.payload._value,
            };
        case TYPES.CR_GET_PAYMENT_TERMS:
            return {
                ...state,
                payment_terms_options: action.payload,
            };
        case TYPES.CR_DATE_FILTER_INPUT:
            return {
                ...state,
                date_filter_value: action.payload,
            };
        case TYPES.CR_CLEAR_FILTER_INPUTS:
            return {
                ...state,
                status_value: { value: '', label: 'Status' },
                payment_terms_value: { value: '', label: 'Terms' },
                date_filter_value: '',
                filters: {
                    due_date_string: '',
                    status: '',
                    payment_terms: '',
                },
            };
        case TYPES.CR_API_FILTER:
            return {
                ...state,
                filters: action.payload,
            };
        case TYPES.CR_DRAWER_TOGGLE:
            return {
                ...state,
                drawer_toggle: action.payload,
            };
        case TYPES.CR_SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case TYPES.CR_HANDLE_CREATE_PAYMENT:
            return {
                ...state,
                create_payment: {
                    ...state.create_payment,
                    [action.payload.key]: action.payload.value,
                },
            };
        case TYPES.CR_HANDLE_UPLOAD:
            return {
                ...state,
                create_payment: {
                    ...state.create_payment,
                    upload: action.payload.upload,
                    upload_preview: action.payload.upload_preview,
                },
            };
        case TYPES.CR_HANDLE_REMOVE_UPLOAD:
            return {
                ...state,
                create_payment: {
                    ...state.create_payment,
                    upload: '',
                    upload_preview: '',
                },
            };
        case TYPES.SET_SELECTED_PAYMENT:
            return {
                ...state,
                selectedPayment: action.payload,
            };
        case TYPES.CLEAR_COLLECTION_RECEIPT:
            return {
                ...state,
                single_collection_receipt: [],
                cr_search: '',
                loading: false,
                main_collection_receipt_page: false,
                edit_page: false,
                delete_modal: false,
                create_payment: {
                    payment_date: moment(new Date()).format('YYYY-MM-DD'), // default is current date
                    payment_method: { value: '', label: 'None' },
                    check_no: '',
                    bank: '',
                    check_date: '',
                    amount: '',
                    upload: '',
                    upload_preview: '',
                    remarks: '',
                    ref_no: '',
                    account: '', 
                    method: ''
                },
                main_url: '/collections/collection-receipts',
                create_url: '/collections/collection-receipts/create',
                edit_url: '/collections/collection-receipts/edit',
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                set_row_checked: false,
                formChange: false,
            };
        default:
            return state;
            break;
    }
};

export default collectionReceiptReducer;
