import * as TYPE from '../types';

// state
const initialState = {
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 0, pageRangeDisplayed: 8, search: '' },
    stock_view: 'all',
    inventory_list: {'0' : [], '1' : [], '2': [], 'all': []},
    pagination_list: [],
    search_list: [],
    loading_inv: false,
    search: '',
}

const deliveryadjsRecuder = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.SET_LOADING_INV:
            return {
                ...state,
                loading_inv: action.payload
            }
        case TYPE.GET_STOCK_VIEW:
            return {
                ...state,
                stock_view: action.payload
            }
        case TYPE.SET_SEARCH:
            return {
                ...state,
                search: action.payload
            }
         
            
        default:
            return state;
            break;
    }

}

export default deliveryadjsRecuder;

