import React, { Fragment, useRef, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { MiniSpinner } from "../../layouts/Spinner";
import {
  setPage,
  goPage,
  sendEmail,
  reSendEmail,
  getPdf,
} from "../../../redux/actions/quotation/quotation.actions";
import QuotationPrintContent from "./QuotationPrintContent";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { globalDropDown } from "../../../redux/actions/helper/helper.actions";
import DropDownAttachFileGlobal from "../../layouts/DropDownAttachFile.global.js";
import QuotationCustomTemplate from "../custom_template/QuotationCustomTemplate";

function QuotationModalPrint({
  single_quotation,
  mini_loading,
  print_modal_qu,
  setPage,
  onHide,
  download_pdf,
  edit_url,
  goPage,
  new_uploads,
  files_to_remove,
  globalDropDown,
  subs_setting,
  sendEmail,
  sending_email,
  reSendEmail,
  getPdf,
}) {
  const componentRef = useRef();
  const status_lbl = (
    <span className={`lbl-status mr-1 lbl-${single_quotation.status}`}>
      {single_quotation.status}
    </span>
  );
  // const payment_status = single_quotation.payment_type && single_quotation.payment_type.value != '' ? <span className={`lbl-status mr-1 lbl-payment-type lbl-${single_quotation.payment_type.label}`}>{single_quotation.payment_type.label}</span> : '';
 
  // console.log("Si", single_quotation)
  // previous button
  const previousBtn = (
    <>
      {single_quotation.latest_quotation_no !=
        single_quotation.quotation_no && (
        <div className='mdl-arrow-left arr-link'>
          <Link onClick={() => goPage("prev")}>
            <i className='la la-angle-left mdl-arrow'></i>
          </Link>
        </div>
      )}
    </>
  );

  // next button
  const nextBtn = (
    <>
      {single_quotation.oldest_quotation_no !=
        single_quotation.quotation_no && (
        <div className='mdl-arrow-right arr-link'>
          <Link onClick={() => goPage("next")}>
            <i className='la la-angle-right mdl-arrow'></i>
          </Link>
        </div>
      )}
    </>
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let file_counter =
    (single_quotation.uploads ? single_quotation.uploads.length : 0) +
    new_uploads.length -
    files_to_remove.length;

  return (
    <Modal
      centered
      dialogClassName='modal-wdth'
      show={print_modal_qu}
      onHide={() => setPage("print_modal_qu", null, false)}
      size='lg'
      animation={true}
    >
      <Modal.Header className='inv-bg-header'>
        {/* <Modal.Title> Quotation</Modal.Title> */}
        <div className='col-4 pl-0'>
          <div className='modal-status'>{status_lbl}</div>
        </div>
        <div className='col-8 pr-0'>
          <div className='float-right'>
            <Link
              to={`${edit_url}/${single_quotation._id}`}
              className='btn btn-circle btn-inv btn-grey mr-1 icon-edit-c'
            >
              <i className='ft ft-edit cursor-pointer'></i>
            </Link>
            <button
              onClick={(e) => globalDropDown(e, "show")}
              className='btn btn-circle btn-inv btn-grey mr-1 icon-paper-c relative'
            >
              <i className='ft ft-paperclip cursor-pointer'></i>
              {file_counter > 0 ? (
                <span className='badge badge-pill badge-sm badge-danger badge-up badge-file'>
                  {file_counter > 9 ? "9+" : file_counter}
                </span>
              ) : (
                <></>
              )}
            </button>
            <DropDownAttachFileGlobal
              origin='quotation'
              origin_data={single_quotation}
              new_uploads={new_uploads}
              files_to_remove={files_to_remove}
            />
            {single_quotation.email_sent ? (
              <button
                className='btn btn-circle btn-inv btn-grey mr-1'
                onClick={(e) => reSendEmail(e, single_quotation._id)}
              >
                <i
                  className={
                    sending_email
                      ? "icon-loading-custom icon-20 cursor-pointer"
                      : "ft-custom-mail-check cursor-pointer"
                  }
                ></i>
              </button>
            ) : (
              <button
                className='btn btn-circle btn-inv btn-grey mr-1'
                onClick={(e) => sendEmail(e, single_quotation._id)}
              >
                <i
                  className={
                    sending_email
                      ? "icon-loading-custom icon-20 cursor-pointer"
                      : "ft-custom-mail-x cursor-pointer"
                  }
                ></i>
              </button>
            )}
            <button
              className='btn btn-circle btn-inv btn-grey mr-1 icon-pdf-c'
              onClick={(e) =>
                getPdf(e, single_quotation._id, single_quotation.quotation_no)
              }
            >
              <i
                className={
                  download_pdf
                    ? "icon-loading-custom icon-20 cursor-pointer"
                    : "la la-file-pdf-o cursor-pointer"
                }
              ></i>
            </button>
            <button
              onClick={handlePrint}
              className='btn btn-circle btn-inv btn-grey mr-1'
            >
              <i className='ft ft-printer cursor-pointer'></i>
            </button>
            <button
              onClick={() => setPage("print_modal_qu", null, false)}
              className='btn btn-circle btn-inv m-close-btn cursor-pointer'
            >
              <i className='ft ft-x cursor-pointer'></i>
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className='unset-scroll p-0 m-0'>
        <div className='sd-border'>
          {previousBtn}
            { mini_loading ? <div className="my-5"><MiniSpinner/></div> : <QuotationCustomTemplate componentRef={componentRef}/> }
          {nextBtn}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  single_quotation: state.quotation.single_quotation,
  print_modal_qu: state.quotation.print_modal_qu,
  mini_loading: state.quotation.mini_loading,
  new_uploads: state.quotation.new_uploads,
  files_to_remove: state.quotation.files_to_remove,
  subs_setting: state.quotation.subs_setting,
  sending_email: state.quotation.sending_email,
  download_pdf: state.quotation.download_pdf,
});

export default connect(mapStateToProps, {
  setPage,
  goPage,
  globalDropDown,
  sendEmail,
  reSendEmail,
  getPdf,
})(QuotationModalPrint);
