import React, { useEffect } from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {  Show, useAccess } from "react-redux-permission";
import { removeLogoWarning, setModal, showCropping } from '../../../redux/actions/settings_app/setting_app.actions';
import UploadLogoModal from './modals/UploadLogo.modal';
import { useHistory } from 'react-router-dom';
import { blockRoute } from "../../../redux/actions/helper/helper.actions";
import BeforeUnloadComponent from 'react-beforeunload-component';
import { SwalFormWarning } from "../../../Services/_swal.service";
import { MiniSpinner } from '../../../components/layouts/Spinner';
import SettingAppLogoCrop from './SettingApp.logo.crop';

function SettingsAppLogo ({ setting_app: { single_setting_app, mini_loading, show_crop }, setModal, blockRoute, block_route, removeLogoWarning, showCropping }) {

    const history = useHistory();

    useEffect(() => {
        blockRoute(false);
        showCropping(false);
    },[]);

    useEffect(() => {
        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }
    
    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => window.history.pushState(null, null, window.location.pathname);

    return (
        <BeforeUnloadComponent 
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
                SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
            }}
        >
        <div className="card card-p-200">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header">
                <h4 className="card-title">Logo  <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements">
                    <ul className="list-inline mb-0"></ul>
                </div>
            </div>
            <div className="card-content collapse show">
                <div className="card-body pt-0">
                    <Show when="inv.setting.app.view">
                        <section className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <div className="f-regular mb-3">
                                        {
                                            (show_crop || single_setting_app.logo == '') ? <div className="item-upload-img-setting-logo"><SettingAppLogoCrop/></div>
                                            : 
                                            <>
                                                <div className="item-upload-img-setting-logo">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="float-right d-flex">
                                                                <label onClick={() => showCropping(true)}><i className="ft ft-edit mr-1 cursor-pointer"></i></label>
                                                                <label onClick={() => removeLogoWarning()}><i className="ft ft-x close-logo-c"></i></label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="text-center p-2 dropzone-line-style-setting-logo">
                                                        {
                                                            mini_loading ? <div className="py-5"><MiniSpinner/></div>
                                                            : 
                                                            <>
                                                                <img src={single_setting_app.logo === '' ? '/noimage.jpg' : single_setting_app.logo} className="img-fluid max-200"/><br/>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <UploadLogoModal/> */}
                    </Show>
                </div>
            </div>
        </div>
        </BeforeUnloadComponent>
    )
}

const mapStateToProps = state => ({
    setting_app: state.setting_app,
    block_route: state.helper.block_route
});

export default connect(mapStateToProps, { setModal, blockRoute, removeLogoWarning, showCropping })(SettingsAppLogo);
