import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TermConditionCreateGlobal from './TermCondition.create.global';

function TermConditionCreate({ term_condition: { main_page, main_url }}) {

    if(main_page) return <Redirect to={main_url}/>

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item"><a href={main_url} className="text-white">Terms & Conditions</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">Create</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <a href={main_url}><i className="ft ft-arrow-left"></i> Back</a>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                           <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <TermConditionCreateGlobal pageOrigin="payment_terms"/>
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
	term_condition: state.term_condition
});

export default connect(mapStateToProps, {})(TermConditionCreate);
