import React from 'react';

const SoPrint = ({ so_data }) => {
    const printPackingSlip = () => {
        const printWindow = window.open('', '_blank');
        const printContent = `
            <html>
                <head>
                    <title>Packing Slip - ${so_data.so_no}</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        .container { width: 80%; margin: auto; padding: 20px; text-align: center; }
                        .header { margin-bottom: 20px; text-align: center; }
                        .header h3 { margin: 0; }
                        .item-list, .footer { margin-bottom: 20px; }
                        .row { display: flex; justify-content: space-between; }
                        .row div { flex: 1; text-align: center; }
                        .item { display: flex; justify-content: space-between; }
                        .item div { flex: 1; text-align: center; }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="header">
                            <h3>Packing Slip - Sales Order # ${
                                so_data.so_no
                            }</h3>
                            <p>Total Items: ${
                                so_data.sales_order_items.length
                            }</p>
                        </div>
                        <div class="item-list">
                            <div class="row">
                                <strong>Product</strong>
                                <strong>Quantity</strong>
                                <strong>SKU</strong>
                            </div>
                            ${so_data.sales_order_items
                                .map(
                                    (item) => `
                                <div class="item">
                                    <div>${item.product_name}</div>
                                    <div>${item.qty}</div>
                                    <div>${item.sku}</div>
                                </div>
                            `
                                )
                                .join('')}
                        </div>
                        <div class="footer">
                        </div>
                    </div>
                </body>
            </html>
        `;

        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <div>
            <button className="btn btn-secondary" onClick={printPackingSlip}>
                Print
            </button>
        </div>
    );
};

export default SoPrint;
