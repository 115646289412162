import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import { getAttributeTemplates, getSingleAttributeTemplate, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows } from "../../redux/actions/attribute_template/attribute_template.actions";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { formattedDate } from "../../Utils/Common";
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { handleHover, setMUIPage, updateMUISetting } from "../../redux/actions/helper/helper.actions";
import moment from "moment";

function AttributeTemplateList ({ attribute_template: { create_url, edit_url, attribute_templates, pagination, loading, search_attr, sort_order_name, sort_order_direction, set_row_checked }, 
    getSingleAttributeTemplate, getAttributeTemplates, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn, getSelectedRows, handleHover, hoverIndex, updateMUISetting, setMUIPage, muiPage }) {
  
    const { hasPermission } = useAccess();
    const canDelete = hasPermission("inv.products.attribute-template.delete");
    const canEdit = hasPermission("inv.products.attribute-template.edit");
    const canview = hasPermission("inv.products.attribute-template.view");

    let history = useHistory();

    useEffect(() => { if(canview){getAttributeTemplates(muiPage);} },[]);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search_attr, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        
      if (debouncedSearch) {
        if(oldDebounced !== debouncedSearch[0])
        {
            if(debouncedSearch[0].length !== 1){
                setOldDebounced(debouncedSearch[0]);
                getAttributeTemplates();
            }
        }
      } 
    },[debouncedSearch]);
    // END DEVOUNCE SEARCH

    function changePage(link) { history.push(link); }

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4!important"
                    }
                }
            },
            MUIDataTableBodyCell: {
                root: {
                  '&:nth-child(2)': {
                        height: '61px'
                    }
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(6)': {
                        width: '150px'
                    }
                }
            }
        }
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'name', label: 'Template Name', options : { display: true,
            setCellProps : value => {
                return {className : "cursor-pointer"}
              }
            } 
        },
        {name: 'description', label: 'Description' , options : { display: true,
            setCellProps : value => {
              return {className : "cursor-pointer"}
            }
          },
          setCellHeaderProps: value => {
            return { className : "cursor-pointer" }
          }
        }, 
        {name: 'created_at', label: 'Created at', options: { display: true, print: false , download: false ,
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    let created_at = attribute_templates[tableMeta["rowIndex"]].created_at;
                    return <>
                    {created_at ?
                        moment(created_at, "YYYY-MM-DD hh:mma", true).isValid() ? 
                        created_at :
                        moment(created_at).format("YYYY-MM-DD - hh:mmA") 
                    : null}
                    </>;
                },
            }
        },
        { name: 'updated_at', label: 'Updated at', options: { display: true, print: false , download: false,
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    let updated_at = attribute_templates[tableMeta["rowIndex"]].updated_at;
                    return <>
                    {updated_at ?
                        moment(updated_at, "YYYY-MM-DD hh:mma", true).isValid() ? 
                        updated_at :
                        moment(updated_at).format("YYYY-MM-DD - hh:mmA") 
                    : null}
                    </>;
                },
            } 
        },
        { name: '', label: 'Action', options: { display: true, filter: false, sort: false, print: false, download: false, 
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = attribute_templates[tableMeta['rowIndex']]._id;
                    let name = attribute_templates[tableMeta['rowIndex']].name;

                    if(!canEdit)
                    {
                        return false;
                    }
                    
                    if(name === 'Default Template'){
                        return (    
                            <span className="btn btn-sm btn-grey disabled"><i className="ft ft-edit"></i></span>
                        )
                    }

                    return (    
                        <div className="btn-group">
                            { 
                                hoverIndex == tableMeta.rowIndex && <Link to={`${edit_url}/${id}`} className="btn btn-sm btn-grey"><i className="ft ft-edit"></i></Link>
                            }
                        </div>
                    )
                },
                setCellProps: (value) => {
                    return {className : "text-center"}
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        rowsPerPageOptions: [10,20,50,100,200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `attribute_templates_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
          getAttributeTemplates(null, numberOfRows)
          updateMUISetting('rows', 'attribute_template', numberOfRows)
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState)
            switch (action) {
                case 'changePage':
                    getAttributeTemplates(tableState.page + 1, pagination.itemsCountPerPage);
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'attribute_template', null, tableState.columns);
                    break;
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if(canDelete)
            {
                confirmDeleteDialogBox();
            }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page    
            if(canEdit)
            {
                // changePage(`${edit_url}/${rowData[0]}`);
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search" value={search_attr} onChange={handleSearchInputMUI}  className="custom-search-input"/>
                </div>
            )
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return { onMouseEnter: () => handleHover('in', rowIndex), onMouseLeave: () => handleHover('out', rowIndex) }
        },
    };

    if(!canDelete)
    {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting('view-columns', 'attribute_template', null, columns);

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">List of Attribute Templates <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                        <div className="pull-right">
                            <Show when="inv.products.attribute-template.create">
                                <Link to={create_url} className="btn btn-sm btn-primary text-white" ><i className="ft ft-plus"></i> Create New</Link>
                            </Show>
                        </div>
                    </h4>
                    <a className="heading-elements-toggle"> <i className="la la-ellipsis-v font-medium-3"></i> </a>
                    <div className="heading-elements">  <ul className="list-inline mb-0"> </ul> </div>
                </div>
                <div className="card-content collapse show">
                    <div className="card-body">
                        <Show when="inv.products.attribute-template.view">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable title={"List of Attribute Templates"} data={attribute_templates} columns={_columns} options={options}/>
                            </MuiThemeProvider> 
                        </Show>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    attribute_template: state.attribute_template,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage
});

export default connect(mapStateToProps, { getAttributeTemplates, getSingleAttributeTemplate, handleSearchInputMUI, confirmDeleteDialogBox, sortTableByColumn,
     getSelectedRows, handleHover, updateMUISetting, setMUIPage })(AttributeTemplateList);
