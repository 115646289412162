import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleComponent, handleContacts, handleInput, onSubmitIRTS, inputSetDefault, confirmDialogBox, showCropping } from '../../../../redux/actions/ir_template_setting/ir_template_setting.actions';
import { MiniSpinner } from '../../../layouts/Spinner';
import IRTSLogoCrop from './IRTSLogoCrop';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { blockRoute } from '../../../../redux/actions/helper/helper.actions';
import { useHistory, Link } from 'react-router-dom';
import { SwalFormWarning } from '../../../../Services/_swal.service';
import TemplateFormGlobal from '../TemplateForm.global';

function IRTSDuplicate({ loading, single_ir_template_setting, toggleComponent, handleContacts, handleInput, onSubmitIRTS, inputSetDefault, showCropping, show_crop, confirmDialogBox, blockRoute, block_route}) {

    let history = useHistory();

    useEffect(() => {
        showCropping(false);
        blockRoute(false);
    }, [])

    useEffect(() => {
        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => window.history.pushState(null, null, window.location.pathname);


    const editLogo = () => {
        showCropping(true);
        // $('#irts-mainbtn').hide();
    }

    const formActions = {
        'handleInput': handleInput,
        'inputSetDefault': inputSetDefault,
        'handleContacts': handleContacts,
    }

    return (
        <>
            {
                loading ? <div className="col-6 justify-content-center py-5 px-5"><MiniSpinner/></div> :
                <BeforeUnloadComponent 
                    blockRoute={block_route}
                    ignoreBeforeunloadDocument={true}
                    modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
                        SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
                    }}
                >
                    <div className="col-md-6 col-sm-12">
                        <form onSubmit={e => onSubmitIRTS(e, 'duplicate')}>
                            <section className="row">
                                <div className="col-12 mb-3">
                                    <Link onClick={() => toggleComponent()}><i className="ft ft-arrow-left f-regular"></i> Back</Link>
                                </div>
                                <div className="col-12">
                                    
                                    <TemplateFormGlobal form_data={single_ir_template_setting} formActions={formActions}/>

                                    <div className="form-group row f-regular">
                                        <label className="col-md-4 col-sm-12">Company Logo: </label>
                                        <div className="col-md-7 col-11">
                                            {
                                                (show_crop || single_ir_template_setting.logo == '') ? <div className="item-upload-img-setting-logo"><IRTSLogoCrop pageOrigin="update"/></div>
                                                :
                                                <>
                                                    <div className="item-upload-img-setting-logo">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="float-right d-flex">
                                                                    <label onClick={editLogo}><i className="ft ft-edit mr-1 cursor-pointer"></i></label>
                                                                    <label onClick={() => confirmDialogBox('logo_remove', single_ir_template_setting, 'remove_logo')}><i className="ft ft-x close-logo-c"></i></label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="text-center p-2 dropzone-line-style-setting-logo">
                                                            <img src={single_ir_template_setting.logo === '' ? '/noimage.jpg' : single_ir_template_setting.logo} className="img-fluid max-200"/>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="float-right pl-0 col-1 col-md-1">
                                            <div className="input-group-prepend">
                                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                            </div>
                                        </div>  
                                    </div>

                                    <div className="float-md-right float-sm-right mr-4 mt-2">
                                        <div id="irts-mainbtn" className="col-12">
                                            <button onClick={() => toggleComponent()} type="button" className="btn btn-danger mr-1"> Cancel</button>
                                            <button type="submit" className="btn btn-primary btn-save"> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </BeforeUnloadComponent>
            }
        </>
    )
}


const mapStateToProps = state => ({
    single_ir_template_setting: state.ir_template_setting.single_ir_template_setting,
    show_crop: state.ir_template_setting.show_crop,
    loading: state.ir_template_setting.loading,
    block_route: state.helper.block_route,
});


export default connect(mapStateToProps, { toggleComponent, handleContacts, handleInput, onSubmitIRTS, inputSetDefault, showCropping, confirmDialogBox, blockRoute })(IRTSDuplicate);
