
import React, { useEffect } from "react";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {load_data, dynamicInputChange, formSubmit, passwordtoggle} from '../../redux/actions/profile/profile.actions';

function Security ({profile : {user, required, submit_str, password_toggle},
    load_data, dynamicInputChange, formSubmit, passwordtoggle}) {
    
    useEffect(() => {
        load_data();
    },[])
    
    return (

    <div className="app-content content">
        <ReactTooltip effect="solid" event="click" />
        <div className="content-wrapper">
            <div className="content-wrapper-before"></div>
            <div className="content-header row">
                <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                    <h3 className="content-header-title mb-0 d-inline-block">Profile</h3>
                    <div className="breadcrumbs-top d-inline-block">
                        <div className="breadcrumb-wrapper mr-1">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item "><a className="text-white">Security</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-body margin-top-5">
                <div id="basic-examples f-regular">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header mb-0 pb-0">
                                    <a href="/account/profile"><i className="ft ft-arrow-left f-regular"></i> Back</a>
                                </div>
                                <form className="card-content collapse show" onSubmit={(e) => formSubmit(e)} encType="multipart/form-data">
                                    <div className="card-body">
                                        
                                        <div className="row justify-content-md-center">
                                            <div className="col-md-4">
                                                <div className="form-group text-center">
                                                    <h1 className="f3-m"><i className="icon-lock"></i></h1>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="label-control f-regular">Current Password</label>
                                                    <div class="input-group">
                                                        <input type={password_toggle.current_pass} name="current_pass" className={required.current_pass.property + " form-control br-0" }  onChange={(e) => dynamicInputChange(e)} autoComplete="old password" value={user.current_pass} required />
                                                        <div class="input-group-append">
                                                            <button class={required.current_pass.property + " btn btn-eye" } data-index="current_pass" type="button" onClick={() =>passwordtoggle("current_pass")}><i class={password_toggle.current_pass == 'password' ? 'la la-eye-slash' : 'la la-eye'}></i></button>
                                                        </div>
                                                    </div>
                                                    <span className="text-danger">{required.current_pass.message}</span>
                                                    
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <label className="label-control f-regular">New Password</label>
                                                    <div class="input-group">
                                                        <input type={password_toggle.current_pass} name="new_pass" className={required.new_pass.property + " form-control br-0" } onChange={(e) => dynamicInputChange(e)}  autoComplete="off" value={user.new_pass} required/>
                                                        <div class="input-group-append">
                                                            <button class={required.new_pass.property + " btn btn-eye" } data-index="new_pass" type="button" onClick={() =>passwordtoggle("current_pass")}><i class={password_toggle.current_pass == 'password' ? 'la la-eye-slash' : 'la la-eye'}></i></button>
                                                        </div>
                                                    </div>
                                                    <span className="text-danger">{required.new_pass.message}</span>
                                                </div>

                                                
                                                <div className="form-group row">
                                                    <label className="label-control f-regular">Confirm Password</label>
                                                    <div class="input-group">
                                                        <input type={password_toggle.current_pass} name="confirm_pass" className={required.confirm_pass.property + " form-control br-0" }  autoComplete="off" onChange={(e) => dynamicInputChange(e)} value={user.confirm_pass} required/>
                                                        <div class="input-group-append">
                                                            <button class={required.confirm_pass.property + " btn btn-eye" } type="button" data-index="con_pass" onClick={() =>passwordtoggle("current_pass")}><i class={password_toggle.current_pass == 'password' ? 'la la-eye-slash' : 'la la-eye'}></i></button>
                                                        </div>
                                                    </div>
                                                    <span className="text-danger">{required.confirm_pass.message}</span>
                                                    
                                                </div>

                                            </div>
                                            
                                        </div>
                                        <div className="col-md-12 text-right">
                                            <button type="submit" className="btn btn-primary btn-save f-regular" >{submit_str}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


); }

 

const mapStateToProps = state => ({
    profile: state.profile
});

export default connect(mapStateToProps, { load_data, dynamicInputChange, formSubmit, passwordtoggle})(Security);