import React, { useEffect, Fragment } from "react";
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

function Profile_Change_Sub () {
    
return (

<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                <h3 className="content-header-title mb-0 d-inline-block">Profile</h3>
                <div className="breadcrumbs-top d-inline-block">
                    <div className="breadcrumb-wrapper mr-1">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item "><a href="#" className="text-white">Subscription</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div className="content-body margin-top-5">
            <section id="basic-examples">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header mb-0 pb-0">
                                <a href="/account/profile"><i className="ft ft-arrow-left f-regular"></i> Back</a>
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className="text-center mt-3">
                                        <h3 className="text-info">Subscription Plan</h3>
                                    </div>
                                    <div className="card-deck mt-5 mb-5 pb-4">
                                        <div className="card pull-up box-subs">
                                            <div className="card-header text-center box-head">
                                                <h5 className="text-primary"><strong>STANDARD</strong></h5>
                                            </div>

                                            <div className="card-body">
                                                <div className="text-center"><h1><strong>$0 </strong></h1><span className="text-muted subs-month">Month</span></div>
                                                <ul className="mt-3 ">
                                                    <li><strong>Members: </strong>2 Team Members</li>
                                                    <li><strong>Inventory locations: </strong>1 location</li>
                                                   
                                                    <li><strong>Integrations: </strong>2</li>
                                                    <li><strong>Orders:</strong> 100 Orders</li>
                                                    <li><strong>Unlimited</strong> support included</li>
                                                    <li><strong>2 hours</strong> setup assistance included</li>
                                                </ul>
                                                <div className="text-center mt-2">
                                                    <button type="button" className="btn btn-primary " disabled>Subscribed</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card pull-up box-subs">
                                            <div className="card-header box-head">
                                                <h5 className="text-primary"><strong>PRO</strong></h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="text-center"><h1><strong>$99 </strong></h1><span className="text-muted subs-month">Month</span></div>
                                                <ul className="mt-3 "> 
                                                    <li><strong>Members: </strong>10 Team Members</li>
                                                    <li><strong>Inventory locations: </strong>10 locations</li>
                                                 
                                                    <li><strong>Integrations: </strong>3</li>
                                                    <li><strong>Orders:</strong> 10000 Orders</li>
                                                    <li><strong>Unlimited</strong> support included</li>
                                                    <li><strong>4 hours</strong> setup assistance included</li>
                                                </ul>
                                                <div className="text-center mt-2">
                                                    <button type="button" className="btn btn-outline-primary">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card pull-up box-subs">
                                            <div className="card-header box-head">
                                                <h5 className="text-primary"><strong>ENTERPRISE</strong></h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="text-center"><h1><strong>$199 </strong></h1><span className="text-muted subs-month"> Month</span></div>
                                                <ul className="mt-3 ">
                                                    <li><strong>Members: </strong>50 Team Members</li>
                                                    <li><strong>Inventory locations: </strong>50 locations</li>
                                                  
                                                    <li><strong>Integrations: </strong>5</li>
                                                    <li><strong>Orders:</strong> 50000 Orders</li>
                                                    <li><strong>Unlimited</strong> support included</li>
                                                    <li><strong>2 hours</strong> setup assistance included</li>
                                                </ul>
                                                <div className="text-center mt-2">
                                                    <button type="button" className="btn btn-outline-primary">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
); }


 
const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(Profile_Change_Sub);