import http from "../../../Services/api.laravel.http";

export const InventoryService = {
  getInvetoryList: (formParams, stock) =>
    http.post("/admin/inventory/list/" + stock, formParams),
  getInvetoryItem: (_id) => http.get("/admin/inventory/view/" + _id),
  setRestock: (formdata) => http.put("/admin/po-request/store", formdata),
  getRestock: () => http.get("/admin/po-request"),
  removeRestock: (id, formdata) =>
    http.put("/admin/po-request/delete/" + id, formdata),
  processRestock: (formdata) =>
    http.post("/admin/po-request/process", formdata),
  removeMultipleRestock: (formdata) =>
    http.post("/admin/po-request/bulk/delete", formdata),
  // getStockLogs: (id, formdata) => http.post('/admin/product/stock-logs/' + id, formdata), //old
  getStockLogs: (formdata) => http.post('/admin/inventory/product/stock-logs', formdata), //new
  verifyPassword: (formdata) => http.post("/user/password/verify", formdata),
  updateStock: (id, formdata) =>
    http.post("/admin/product/stock-adjustment/" + id, formdata),
};
