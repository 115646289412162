import React, { Fragment, useRef, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MiniSpinner } from '../../layouts/Spinner';
import { connect } from 'react-redux';

function verifyPassModal({purchase_order : {password_verify_modal}, show, onHide }) {
    return (
        <Modal centered dialogClassName="modal-container"  show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton dialogClassName="p-0">
                <Modal.Title> Payment Terms</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <MiniSpinner/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
const mapStateToProps = state => ({
    purchase_order: state.purchase_order,
})

export default connect(mapStateToProps, { })(verifyPassModal);