import * as TYPES from '../types';

// state
const initialState = {
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    search: '',
    lotno_data: [],
    single_lotno_data: {},
    sort_order_name: null,
    sort_order_direction: null,
    loading: false,
    lotno_modal: false,
    edit_page: false,
    print_modal: false,
    edit_url: '/purchases/purchase-orders/edit',
}

const lotnoReducer = (state = initialState, action) => {
   switch (action.type) {
       case TYPES.GET_LOT_NO_DATA:
           return {
               ...state,
                lotno_data: action.payload.lotno_data,
                pagination: action.payload.pagination,
           }
        case TYPES.SEARCH_INPUT:
            return {
                ...state,
                search: action.payload
            }
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPES.SET_MODAL:
            if (action.payload.page == 'main_page') {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                    edit_page: false,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
       default:
           return state;
           break;
   }

}

export default lotnoReducer;

