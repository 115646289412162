import * as TYPES from '../types';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        totalItemsCount: 10,
    },
    search: '',
    batch_list: [],
    batch_data: null,
    so_data: null,
    single_pick_and_pack: {},
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    formChange: false,
    loading: false,
    modalLoading: false,
    lotno_modal: false,
    selected_batch: [],
    date_from: new Date(),
    date_to: new Date(),
    isSearchable: true,
    main_page: false,
    edit_page: false,
    delete_modal: false,
    main_url: '/fulfillment/pick-and-pack',
    // create_url: '/fulfillment/pick-and-pack',
    // edit_url: '/fulfillment/pick-and-pack',
    pick_url: '/fulfillment/pick-and-pack/pick',
    pack_url: '/fulfillment/pick-and-pack/pack',
};

const pickAndPackReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_PICK_AND_PACK_DATA:
            return {
                ...state,
                batch_list: action.payload.batch_list,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case TYPES.SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case TYPES.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case TYPES.SET_MODAL_LOADING: // New case for modal loading
            return {
                ...state,
                modalLoading: action.payload,
            };
        case TYPES.PICK_AND_PACK_VIEW:
            return {
                ...state,
                batch_list: action.payload.batch_list,
            };
        case TYPES.BATCH_PICK_AND_PACK:
            return {
                ...state,
                selected_batch: action.payload,
            };
        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case TYPES.DATE_FILTER_INPUT:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        case TYPES.PICK_AND_PACK_DATE_INPUT_CLEAR:
            return {
                ...state,
                date_from: new Date(),
                date_to: new Date(),
                date_filter: false,
                display_tab: { ...state.display_tab, [action.payload]: true },
            };
        case TYPES.CLEAR_BATCH:
            return {
                ...state,
                single_pick_and_pack: [],
                search: '',
                loading: false,
                main_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/fulfillment/pick-and-pack',
                // create_url: '/fulfillment/pick-and-pack',
                // edit_url: '/fulfillment/pick-and-pack',
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                set_row_checked: false,
                formChange: false,
            };
        case TYPES.SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case TYPES.SET_PAGE:
            if (action.payload.page == 'main_page') {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                    edit_page: false,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
        case TYPES.SCAN_BATCH:
            return {
                ...state,
                search: '',
                batch_data: action.payload,
            };
        case TYPES.SCAN_SO:
            return {
                ...state,
                search: '',
                so_data: action.payload,
            };
        case TYPES.SCAN_PRODUCT:
            return {
                ...state,
                search: '',
                batch_data: action.payload,
            };
        case TYPES.SUBMIT_COMPLETE:
            return {
                ...state,
                batch_data: action.payload,
            };
        case TYPES.BATCH_CHECKER:
            return {
                ...state,
                search: '',
                batch_data: action.payload,
            };
        case TYPES.SO_CHECKER:
            return {
                ...state,
                search: '',
                so_data: action.payload,
            };
        case TYPES.SCAN_HANDLE_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        default:
            return state;
            break;
    }
};

export default pickAndPackReducer;
