import { SET_MINI_LOADING, AUTH_USER, REQUIRED_INPUT, BTN_SUB, HANDLE_ITEM_REMOVE_UPLOAD, HANDLE_ITEM_INPUT_UPLOAD, SET_PAGE, 
    AVATAR_MODAL, BOOLEAN_VAR, BOOLEAN_PROFILE, EDIT_AUTH_USER, PROFILE_BTN, PASSWORD_TOGGLE} from '../types';

// state
const initialState = {
   user : {
       name : 'loading...',
       last_name : 'loading...',
       birthday : 'loading...',
       email : 'loading...',
       username : 'loading...',
       contact : 'loading...',
       image : '/profile.png',
       current_pass : '',
       new_pass : '',
       confirm_pass : '',
       upload : '',
       upload_preview : ''
   },

   edit_user : {
        name : 'loading...',
        last_name : 'loading...',
        birthday : 'loading...',
        email : 'loading...',
        username : 'loading...',
        contact : 'loading...',
        image : '/profile.png',
        current_pass : '',
        new_pass : '',
        confirm_pass : '',
        upload : '',
        upload_preview : ''
    },

   required : {
        name : {
            property : '',
            message : ''
        },
        last_name : {
            property : '',
            message : ''
        },
        birthday : {
            property : '',
            message : ''
        },
        email : {
            property : '',
            message : ''
        },
        username : {
            property : '',
            message : ''
        },
        contact : {
            property : '',
            message : ''
        },
        image : {
            property : '',
            message : ''
        },
        current_pass : {
            property : '',
            message : ''
        },
        new_pass : {
            property : '',
            message : ''
        },
        confirm_pass : {
            property : '',
            message : ''
        }
   },
   mini_loading: false,
   search : '',
   submit_str : 'Save',
   profile_img : false,
   disable_btn : false,
   edit_profile : false,
   profile_btn : 'Save',
   password_toggle : {
       current_pass : 'password',
       new_pass : 'password',
       con_pass : 'password'
   }
}

const profileReducer = (state = initialState, action) => {
   switch (action.type) {
       case SET_MINI_LOADING:
           return {
               ...state,
               mini_loading: action.payload
           }
        case AUTH_USER:
           return {
               ...state,
               user:action.payload,
               edit_user : action.payload
           }
        case REQUIRED_INPUT:
            return {
                ...state,
                required : action.payload
            }
       case BTN_SUB:
            return {
                ...state,
                submit_str : action.payload
            }
        case HANDLE_ITEM_REMOVE_UPLOAD:
            return {
                ...state,
                user: { ...state.user, upload: '',  upload_preview: '', image: '' },
            }
        case HANDLE_ITEM_INPUT_UPLOAD:
            return {
                ...state,
                user: { ...state.user, upload: action.payload.upload, upload_preview: action.payload.upload_preview },
            }
        case AVATAR_MODAL :
            return {
                ...state,
                profile_img : action.payload
            }
        case BOOLEAN_VAR :
            return{
                ...state,
                disable_btn : action.payload
            }
        case BOOLEAN_PROFILE :
            return{
                ...state,
                edit_profile : action.payload
            }
        case EDIT_AUTH_USER :
            return{
                ...state,
                edit_user : action.payload
            }
        case PROFILE_BTN :
            return{
                ...state,
                profile_btn : action.payload
            }
        case PASSWORD_TOGGLE:
            return{
                ...state,
                password_toggle : action.payload
            }
       default:
           return state;
   }
}


export default profileReducer;