import React from 'react';
import { Pie, Doughnut } from 'react-chartjs-2';
import { formatPrice } from '../../../Utils/Common';

function PieContent({
    topCardName,
    categoryPieChart,
    collectionPieChart,
    collection,
}) {
    // Extracting counts from labels and summing them up
    const totalCategory = categoryPieChart.labels.reduce((total, label) => {
        const count = parseInt(label.split(':')[1].trim()); // Extracting count from label
        return total + count;
    }, 0);
    return (
        <div className="col-12 p-0">
            {topCardName === 'Top Category Sales' && (
                <>
                    <div className="mx-auto px-5 pie-body">
                        <Doughnut
                            data={categoryPieChart}
                            options={categoryPieChart.options}
                        />
                    </div>
                    <div className="total-collection w-100 d-flex justify-content-between">
                        <div>Total: </div>
                        <div className="text-bold">{totalCategory}</div>
                    </div>
                </>
            )}
            {topCardName === 'Collections' && (
                <>
                    <div className="mx-auto px-5 pie-body">
                        <Pie
                            data={collectionPieChart}
                            options={collectionPieChart.options}
                        />
                    </div>
                    <div className="total-collection w-100 d-flex justify-content-between">
                        <div>Total Collection Amount: </div>
                        <div className="text-bold">
                            {formatPrice(
                                collection,
                                'code',
                                'currency',
                                'PHP',
                                {
                                    notation: 'compact',
                                }
                            )}{' '}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PieContent;
