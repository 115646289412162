import * as TYPE from "../../types";
import { ToastSuccess, ToastDanger } from "../../../Services/_toast.service";
import { SwalWarning, SwalWarningAPI } from "../../../Services/_swal.service";
import $, { param } from "jquery";
import { SettingAppService } from "./_service.setting_app";
import listCurrencies from "./currencies.json";
import listLanguages from "./languages.json";
import { blockRoute, validateImage } from "../helper/helper.actions";
import {
  checkValueIfEmpty,
  getSubSetting,
  imageURLtoBlob,
  setSubSetting,
} from "../../../Utils/Common";
import { AuthService } from "../auth/auth.service";
import { getUserDetails } from "../auth/auth.actions";

// set loading
export const setMiniLoading = (status) => async (dispatch) =>
  dispatch({ type: TYPE.SET_MINI_LOADING, payload: status });

// handle inputs
export const handleInputChange = (e) => async (dispatch, getState) => {
  let { name, value } = e.target;

  if (value !== "") {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  let _value;
  if (value) {
    switch (name) {
      case "company_contact":
        _value = value;
        break;
      case "tax_rate":
        _value = parseFloat(value);
        break;
      default:
        _value = value;
        break;
    }
  }

  dispatch({
    type: TYPE.SETTING_APP_INPUT_CHANGE,
    payload: { name, value: _value },
  });
};

// setModal
export const setModal =
  (modal, status = true) =>
  async (dispatch) => {
    if (modal === "upload_modal" || status === false) {
      // dispatch({ type: TYPE.SETTING_APP_REMOVE_LOGO, payload: { logo_preview: '' } });
    }

    dispatch({ type: TYPE.SET_MODAL, payload: { modal, status } });
  };

// get the current settings
export const getSettingApp = () => async (dispatch, getState) => {
  let { single_setting_app } = getState().setting_app;

  let x = 0;
  // Doing this to refetch it again due to connection timeout
  while (x < 3) {
    try {
      dispatch(setMiniLoading(true));
      //   const res = await SettingAppService.getSettingApp();
      //** MERN */
      const res = await AuthService.getUserDetails();
      const subscriber_setting = res.data.user.subscriber_settings;

      //console.log("subs_setting", res.data.user.subscriber_settings);

      if (subscriber_setting != null) {
        const payload = {
          company_name: subscriber_setting.company_name,
          company_address: subscriber_setting.company_address,
          company_mobile: subscriber_setting.company_mobile,
          company_landline: subscriber_setting.company_landline,
          company_email: subscriber_setting.company_email,
          company_tin: subscriber_setting.company_tin,
          additional_information: subscriber_setting.additional_information,
          industry: subscriber_setting.industry,
          payment_mode: subscriber_setting.payment_mode || { value: '', label: 'None' },
          is_default: subscriber_setting.is_default,
          currency: checkValueIfEmpty(
            subscriber_setting.currency,
            single_setting_app.currency
          ),
          timezone: checkValueIfEmpty(
            subscriber_setting.timezone,
            single_setting_app.timezone
          ),
          tax_type: checkValueIfEmpty(
            subscriber_setting.tax_type,
            single_setting_app.tax_type
          ),
          tax_rate: checkValueIfEmpty(
            subscriber_setting.tax_rate,
            single_setting_app.tax_rate
          ),
          payment_terms: checkValueIfEmpty(
            subscriber_setting.payment_terms,
            single_setting_app.payment_terms
          ),
          language: checkValueIfEmpty(
            subscriber_setting.language,
            single_setting_app.language
          ),
          logo: subscriber_setting.logo
            ? subscriber_setting.logo.file_path
            : single_setting_app.logo,
          logo_preview: "",
        };

        dispatch({ type: TYPE.GET_SETTING_APP, payload });
        setSubSetting(payload);
      }

      dispatch(setMiniLoading(false));
      x = 4;
    } catch (err) {
      x++;
      // Temporary remove this, coz of connection timeout
      // ToastDanger('Server Error.');
      dispatch(setMiniLoading(false));
      console.log(err);
    }
  }
};

// handle submit
export const handleSubmit = (status, e) => async (dispatch, getState) => {
  e.preventDefault();

  const { single_setting_app } = getState().setting_app;

  const requiredIncomplete = [single_setting_app.company_name, single_setting_app.company_address, single_setting_app.company_email, single_setting_app.company_mobile].some(item => !item)

  if (requiredIncomplete) {
    return ToastDanger("Required fields cannot be empty")
  }

  if (
    single_setting_app.company_landline &&
    single_setting_app.company_landline.length < 4
  ) {
    return ToastDanger(
      "Landline is invalid, please provide your company landline"
    );
  }

  if (
    single_setting_app.company_mobile &&
    single_setting_app.company_mobile.length < 4
  ) {
    return ToastDanger(
      "Mobile is invalid, please provide your company landline"
    );
  }

  const params = {
    business_profile: {
      // for business profile tab
      company_name: single_setting_app.company_name,
      company_address: single_setting_app.company_address,
      company_mobile: single_setting_app.company_mobile,
      company_landline: single_setting_app.company_landline,
      company_email: single_setting_app.company_email,
      company_tin: single_setting_app.company_tin,
      // additional_information: single_setting_app.additional_information,
      industry: single_setting_app.industry,
      payment_mode: single_setting_app.payment_mode
    },
    application: {
      // for application tab
      currency: single_setting_app.currency,
      tax_type: single_setting_app.tax_type,
      tax_rate: single_setting_app.tax_rate,
      timezone: single_setting_app.timezone,
      payment_terms: single_setting_app.payment_terms,
      language: single_setting_app.language,
    },
  };

  switch (status) {
    case "create":
      dispatch(settingAppPost(params.business_profile));
      break;
    case "app_settings":
      if (
        single_setting_app.tax_type.value == "enclusive" ||
        single_setting_app.tax_type.value == "inclusive"
      ) {
        if (single_setting_app.tax_rate < 1) {
          return ToastDanger("Tax Rate is required if inclusive or exclusive");
        }
      }

      dispatch(SettingAppPostApplication(params.application));
      break;
    default:
      return;
  }
};

// create
export const settingAppPost = (formParams) => async (dispatch) => {
  try {
    $(".btn-save").attr("disabled", "disabled").html("Saving...");
    const res = await SettingAppService.SettingAppPost(formParams);

    switch (res.data.status) {
      case 0:
        ToastDanger(res.data.errors);
        $(".btn-save").removeAttr("disabled").html("Save");
        break;
      case 1:
        getUserDetails()
        ToastSuccess(res.data.message);
        dispatch(blockRoute(false));
        dispatch(getSettingApp());
        // window.location.reload()
        break;
      default:
        break;
    }
  } catch (err) {
    ToastDanger("Network error, please reload the page.");
    $(".btn-save").removeAttr("disabled").html("Save");
    dispatch(blockRoute(false));
    console.log(err);
  }
};

export const SettingAppPostApplication = (formParams) => async (dispatch) => {
  try {
    $(".btn-save").attr("disabled", "disabled").html("Saving...");

    const res = await SettingAppService.SettingAppPostApplication(formParams);

    ToastSuccess(res.data.message);
    dispatch(getSettingApp());
    dispatch(blockRoute(false));
    $(".btn-save").removeAttr("disabled").html("Save");
  } catch (err) {
    ToastDanger("Server Error.");
    $(".btn-save").removeAttr("disabled").html("Save");
    console.log(err);
  }
};

// Global Select element
export const globalSettingAppSelect =
  (name, selectOption) => async (dispatch, getState) => {
    if (selectOption) {
      dispatch(blockRoute(true));
    } else {
      dispatch(blockRoute(false));
    }

    switch (name) {
      case "currency":
        let currencyObj = {
          currency_value: {
            value: selectOption.value,
            label: selectOption.label,
          },
          currency_name: selectOption.value,
          symbol: { name: "symbol", value: selectOption.symbol, checked: true },
          code: { name: "code", value: selectOption.code, checked: false },
        };

        // dispatch({ type: TYPE.SELECT_CURRENCY, payload: currencyObj })
        dispatch({
          type: TYPE.SETTING_APP_GLOBAL_SELECT,
          payload: { name, value: currencyObj },
        });
        break;
      case "timezone":
      case "tax_type":
      case "payment_terms":
      case "language":
        dispatch({
          type: TYPE.SETTING_APP_GLOBAL_SELECT,
          payload: { name, value: selectOption },
        });
        break;
      default:
        break;
    }
  };

// HANDLE SELECT CURRENCY RADIO
export const setCurrencyRadio = (e) => async (dispatch, getState) => {
  let {
    single_setting_app: { currency },
  } = getState().setting_app;
  let { value, checked } = e.target;

  if (e) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  if (checked) {
    if (currency[value]["name"] === "symbol") {
      if (value === "symbol") {
        currency["symbol"]["checked"] = true;
        currency["code"]["checked"] = false;
      }
    }

    if (currency[value]["name"] === "code") {
      if (value === "code") {
        currency["code"]["checked"] = true;
        currency["symbol"]["checked"] = false;
      }
    }

    dispatch({ type: TYPE.SELECT_CURRENCY_RADIO, payload: currency });
  }
};

// upload logo
export const uploadLogo = (selectedFile) => async (dispatch) => {
  if (selectedFile) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  let validate_image = validateImage(selectedFile);
  if (validate_image.status === 1) {
    return ToastDanger(validate_image.errors[0]);
  }

  let payload = URL.createObjectURL(selectedFile[0]);

  dispatch({ type: TYPE.SETTING_APP_UPLOAD_LOGO, payload });
};

export const removeLogoWarning = () => async (dispatch) =>
  SwalWarning("Warning!", "Do you want to remove this image?", () =>
    dispatch(removeLogo())
  );

// remove logo
export const removeLogo = () => async (dispatch) => {
  $("#itemUpload").val("");
  dispatch(settingAppLogoUpload(null, "remove"));
};

// Logo upload
export const settingAppLogoUpload =
  (logo = null, type = null) =>
  async (dispatch, getState) => {
    try {
      $(".btn-save").attr("disabled", "disabled").html("Saving...");

      let formdata = new FormData();
      formdata.append("logo", logo);
      formdata.append("type", type);

      const logoUpload = await SettingAppService.SettingAppLogoUpload(formdata);
      // console.log(logoUpload.data.message);
      switch (logoUpload.data.status) {
        case 0:
          ToastDanger(logoUpload.data.errors);
          break;
        case 1:
          ToastSuccess(logoUpload.data.message);
          type === "upload" && dispatch(setModal("upload_modal", false));

          if (type === "remove") {
            dispatch({ type: TYPE.SETTING_APP_REMOVE_LOGO });
          }

          dispatch(getSettingApp());
          dispatch(blockRoute(false));
          dispatch(showCropping(false));
          break;
        default:
          break;
      }

      $(".btn-save").removeAttr("disabled").html("Save");
    } catch (error) {
      ToastDanger("Network error, please reload the page.");
      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    }
  };

// GET CURRENCIES
export const getCurrencies = () => async (dispatch) => {
  let currencies = listCurrencies;

  let formattedCurrencies = currencies.map((currency, i) => {
    let formatted = {
      value: currency.name,
      label: `${currency.name} (${currency.symbol})`,
      symbol: currency.symbol,
      code: currency.code,
    };
    return formatted;
  });

  // console.log(formattedCurrencies)
  dispatch({ type: TYPE.GET_CURRENCIES, payload: formattedCurrencies });
};

// GET LANGUAGES
export const getLanguages = () => async (dispatch) => {
  let languages = Object.entries(listLanguages);

  let formattedLanguages = languages.map((language, i) => {
    let formatted = {
      value: language[1]["name"],
      label: `${language[1]["name"]} (${language[0].toUpperCase()})`,
    };
    return formatted;
  });

  dispatch({ type: TYPE.GET_LANGUAGES, payload: formattedLanguages });
};

// GET PAYMENT TERMS
export const getPaymentTerms = () => async (dispatch) => {
  try {
    const res = await SettingAppService.getPaymentTerms();

    if (res.data.terms_conditions != null) {
      let payment_terms = res.data.terms_conditions.map((term) => {
        let formatted = { value: term.content, label: term.title };
        return formatted;
      });

      payment_terms.unshift({ value: "", label: "None" }); // added none to select

      dispatch({ type: TYPE.SA_PAYMENT_TERMS, payload: payment_terms });
    }
  } catch (err) {
    ToastDanger("Network error, please reload the page.");
    console.log(err);
  }
};

//  subscriber setting populate data in transaction forms
export const subscriberSetting =
  (origin = null) =>
  async (dispatch) => {
    try {
      dispatch(getSettingApp());
      let subsetting = getSubSetting();
      let payment_terms = subsetting.payment_terms
        ? subsetting.payment_terms
        : { value: "", label: "None" };

      // subsetting for transactions
      const payloadData = {
        terms: payment_terms,
        default_tax: subsetting.tax_rate ? subsetting.tax_rate : 0,
        tax: subsetting.tax_type.value === "none" ? 0 : subsetting.tax_rate,
        tax_type: subsetting.tax_type
          ? subsetting.tax_type
          : { value: "none", label: "None" },
      };

      // origin to populate
      switch (origin) {
        case "purchase_order":
          dispatch({ type: TYPE.GET_PO_SUB_SETTING, payload: payloadData });
          break;
        case "quotation":
          dispatch({ type: TYPE.GET_QU_SUB_SETTING, payload: payloadData });
          break;
        case "sales_invoice":
          dispatch({ type: TYPE.GET_SI_SUB_SETTING, payload: payloadData });
          break;
        case "sales_order":
            dispatch({ type: TYPE.GET_SO_SUB_SETTING, payload: payloadData });
            break;
        case "delivery":
          dispatch({
            type: TYPE.GET_DELIVERY_SUB_SETTING,
            payload: payloadData,
          });
        default:
          break;
      }

      // console.log(subsetting);
      dispatch({ type: TYPE.SET_SUB_SETTING, payload: subsetting });
    } catch (err) {
      ToastDanger("Server Error");
      console.log(err);
    }
  };

export const showCropping = (bool) => async (dispatch) =>
  dispatch({ type: TYPE.SHOW_CROP, payload: bool });

// handle contact
export const handleContacts = (type, number) => async (dispatch) => {
  if (number) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  let payload = {};
  if (type === "mobile") {
    payload = { name: "company_mobile", value: `+${number}` }; // mobile
  } else {
    payload = { name: "company_landline", value: number }; // landline
  }

  dispatch({ type: TYPE.SETTING_APP_INPUT_CHANGE, payload });
};

export const setDefaultWarning = () => async (dispatch) => {
  SwalWarningAPI(
    "Warning!",
    "This will set the values to all your Template Settings, do you want to continue?",
    () => dispatch(setDefaultBusinessProfile())
  );
};

// Set As Default action
export const setDefaultBusinessProfile = () => async (dispatch, getState) => {
  const { single_setting_app } = getState().setting_app;

  let resolve = {
    status: 0,
    message: "",
  };

  try {
    const params = {
      company_name: single_setting_app.company_name,
      company_address: single_setting_app.company_address,
      company_email: single_setting_app.company_email,
      company_landline: single_setting_app.company_landline,
      company_mobile: single_setting_app.company_mobile,
      company_tin: single_setting_app.company_tin,
      additional_information: single_setting_app.additional_information,
    };

    const res = await SettingAppService.SettingAppPostSetDefault(params);

    switch (res.data.status) {
      case 0:
        resolve.status = 0;
        ToastDanger(res.data.errors);
        blockRoute(false);
        break;
      case 1:
        resolve.message = res.data.message;
        resolve.status = 1;
        blockRoute(false);
      default:
        break;
    }
  } catch (error) {
    ToastDanger("Server Error");
    console.log(error);
    blockRoute(false);
  }

  return resolve;
};
