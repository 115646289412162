import * as TYPE from '../types';

const initialState = {
    sub_templates: [],
    sub_default_template: { value: '', label: 'Choose Main Template' },
    create_global: {
        template_id: '',
        template_type: '',
        trans_type: [],
        template_name: '',
        company_name: '',
        company_address: '',
        company_email: '',
        company_landline: '',
        company_mobile: '',
        is_default: false,
        logo: '',
        logo_preview: '',
        file_logo: null,
    },
    transaction_types: [
        { value: 'purchase_order', label: 'Purchase Order' },
        { value: 'quotation', label: 'Quotation' },
        { value: 'sales_invoice', label: 'Sales Invoice' },
        { value: 'item_receipt', label: 'Item Receipt' },
        { value: 'delivery', label: 'Delivery Receipt' },
    ],
    loading: false,
    show_crop: false,
    crop_trigger: false,
    crop_isset: false,
    main_url: '/settings/template',
    redirect_to: '',
}

const templateSettingGlobalReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case TYPE.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPE.CREATE_TEMPLATE_REDIRECT:
            return {
                ...state,
                redirect_to: action.payload
            }
        case TYPE.GET_GL_SUB_TEMPLATES:
            return {
                ...state,
                sub_templates: action.payload
            }
        case TYPE.SELECT_GL_SUB_TEMPLATES:
            return {
                ...state,
                create_global: { ...state.create_global, template_type: action.payload.template_type, template_id: action.payload.template_id }
            }
        case TYPE.HANDLE_GL_TEMPLATE_INPUT:
            return {
                ...state,
                create_global: { ...state.create_global, [action.payload.name]: action.payload.value }
            }
        case TYPE.SELECT_GL_MULTI_TEMPLATES:
            return {
                ...state,
                create_global: { ...state.create_global, trans_type: action.payload }
            }
        case TYPE.HANDLE_SET_GL_DEFAULT_TEMPLATE:
            return {
                ...state,
                create_global: { ...state.create_global, ...action.payload }
            }
        case TYPE.CLEAR_GL_TEMPLATE_SETTING:
            return {
                ...state,
                sub_templates: [],
                sub_default_template: { value: '', label: 'Choose Main Template' },
                create_global: {
                    template_id: '',
                    template_type: '',
                    trans_type: [],
                    template_name: '',
                    company_name: '',
                    company_address: '',
                    company_email: '',
                    company_landline: '',
                    company_mobile: '',
                    is_default: false,
                    logo: '',
                    logo_preview: '',
                    file_logo: null,
                },
                loading: false,
                show_crop: false,
                crop_trigger: false,
                crop_isset: false,
                main_url: '/settings/template',
                redirect_to: ''
            }
        case TYPE.GL_TS_SHOW_CROP:
            return {
                ...state,
                show_crop: action.payload
            }
        case TYPE.GL_TS_LOGO_PREVIEW:
            return {
                ...state,
                create_global: { ...state.create_global, logo_preview: action.payload },
                show_crop: true
            }
        case TYPE.GL_TS_CROP_LOGO:
            return {
                ...state,
                create_global: { ...state.create_global, file_logo: action.payload.file_logo },
                crop_trigger: action.payload.crop_trigger
            }
        case TYPE.GL_TS_REMOVE_LOGO:
            return {
                ...state,
                create_global: { ...state.create_global, ...action.payload }
            }
        case  TYPE.GL_TS_CROP_TRIGGER:
            return {
                ...state,
                crop_trigger: action.payload
            }
        case TYPE.GL_TS_CROP_ISSET:
            return {
                ...state,
                crop_isset: action.payload
            }
        default:
            return state;
    }
}


export default templateSettingGlobalReducer;