import * as TYPES from '../types';
import moment from 'moment';

const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    stock_logs: [],
    returns: [],
    return_stock_logs: {
        new_uploads: [],
        files_to_remove: [],
        qty: 0,
        remarks: '',
    },
    tax_types: [
        { value: 'none', label: 'None' },
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
    ],
    subs_setting: null,
    loading: false,
    mini_loading: false,
    delete_modal: false,
    edit_row_index: null,
    main_page: false,
    edit_page: false,
    print_modal_si: false,
    main_url: '/inventory/returns',
    create_url: '/inventory/returns/create',
    edit_url: '/inventory/returns/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    diselect_row: false,
    set_row_checked: false,
    search: '',
    date_from: moment(new Date()).format('MMM DD YYYY'),
    date_to: moment(new Date()).format('MMM DD YYYY'),
};

const returnsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_STOCK_LOGS:
            return {
                ...state,
                stock_logs: action.payload.stock_logs,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case TYPES.GET_RETURNS_DATA:
            return {
                ...state,
                returns: action.payload.returns,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case TYPES.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
                modalData: action.payload.data || null,
            };
        case TYPES.RETURN_HANDLE_INPUT:
            return {
                ...state,
                return_stock_logs: {
                    ...state.return_stock_logs,
                    [action.payload.name]: action.payload.value,
                },
            };
        case TYPES.RETURN_UPDATE_QTY:
            return {
                ...state,
                return_stock_logs: {
                    ...state.return_stock_logs,
                    qty: action.payload,
                },
            };
        case TYPES.RETURN_CLEAR:
            return {
                ...state,
                return_stock_logs: action.payload.return_stock_logs,
            };
        case TYPES.DATE_FILTER_INPUT:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        case TYPES.LOGS_DATE_INPUT_CLEAR:
            return {
                ...state,
                date_from: new Date(),
                date_to: new Date(),
                date_filter: false,
                display_tab: { ...state.display_tab, [action.payload]: true },
            };
        default:
            return state;
    }
};

export default returnsReducer;
