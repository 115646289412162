import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateSingleTermCondition, handleInputChange, getSingleTermCondition } from '../../../../redux/actions/term_condition/term_condition.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";

function TermConditionEdit({ term_condition: { single_term_condition, main_page, main_url } , handleInputChange, updateSingleTermCondition, getSingleTermCondition, match}) {
    let history = useHistory();
    useEffect(() => {
        getSingleTermCondition(match.params.id);
    }, [])

    if(main_page) return <Redirect to={main_url}/>

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item"><a href={main_url} className="text-white">Terms & Conditions</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">Create</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <a href={main_url}><i className="ft ft-arrow-left"></i> Back</a>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body f-regular">
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <form onSubmit={updateSingleTermCondition}>
                                                        <ReactTooltip  effect="solid" event="click"/>
                                                        <section className="row f-regular">
                                                            <div className="col-12">
                                                                <label className="label-control f-regular">Name: <span className='text-danger'>*</span></label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                       <input value={single_term_condition.title} onChange={handleInputChange} type="text" name="title" className="form-control" placeholder="Enter Title" required/>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="label-control f-regular">Description:</label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <textarea value={single_term_condition.content} onChange={handleInputChange} name="content" rows="4" className="form-control" placeholder="Content" required></textarea>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                                <div className="form-group row">
                                                                    <div className="col-md-4 col-12 label-control f-regular"></div>
                                                                    <div className="col-md-7 col-10 text-right">
                                                                        <button type="submit" className="btn btn-primary btn-save f-regular">Save</button>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-2 col-md-1"></div>  
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
	term_condition: state.term_condition
});

export default connect(mapStateToProps, { handleInputChange, updateSingleTermCondition, getSingleTermCondition, })(TermConditionEdit);
