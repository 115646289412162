import http from './api.laravel.http';

export const API = {
  verifyToken: () => http.get('/admin/verify/token')
}


// DEPPRECIATED CHECK UTILS INSTED
export const auth = {

    get token () {
      return this._token || JSON.parse(localStorage["appState"]).user.auth_token
    }
  
}