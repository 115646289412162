import * as TYPE from './../types';

// state
const initialState = {
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 0, pageRangeDisplayed: 3, search: '' },
    stock_view: 'all',
    inventory_list: {'0' : [], '1' : [], '2': [], 'all': [], 're-stock' : []},
    pagination_list: [],
    search_list: [],
    loading_inv: false,
    search: '',
    inventory_item: { attributes : { arr : []}, supplier: []},
    inventory_view_list: {  
            'Lot No.' : [], 
            'Purchase Orders' : [], 
            'Item Reciepts': [], 
            'Qoutations': [],
            'Sales Invoices': [],
            'Delivery Receipt': [],
            'Stock Logs': []
    },
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    po_request : [],
    po_request_count : 0,
    po_request_count_str : '0', //max to 99, if max reach diplay to "99+"
    po_process : false,
    show_modal : {
        supplier : false,
        po : false
    },
    suppliers : [],
    supplier_id : null,
    po_per_supplier : true,
    target_index : 0,
    delete_general : false,
    selected_rows : [],
    stock_logs : [],
    inv_brand: null,
    date_from: new Date(),
    date_to: new Date(),
}

const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.SET_LOADING_INV:
            return {
                ...state,
                loading_inv: action.payload
            }
        case TYPE.GET_STOCK_VIEW:
            return {
                ...state,
                stock_view: action.payload
            }
        case TYPE.SET_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        case TYPE.GET_INVENTORY_ITEM:
            return {
                ...state,
                inventory_item: action.payload
            }    
        case TYPE.SET_PAGINATION:
            return {
                ...state,
                pagination_list: action.payload.pagination_list,
                pagination: action.payload.pagination,
            }
        case TYPE.GET_INVENTORY_LIST:
            return {
                ...state,
                pagination_list: action.payload.pagination_list,
                pagination: action.payload.pagination,
                inventory_list: action.payload.inventory_list,
            }    

        /* Added by Michael Antoni */
        case TYPE.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPE.INVENTORY_LIST:
            return{
                ...state,
                inventory_list : action.payload
            }
        case TYPE.PO_REQUEST:
            return{
                ...state,
                po_request : action.payload
            }
        case TYPE.PO_REQUEST_COUNT :
            return{
                ...state,
                po_request_count : action.payload.value,
                po_request_count_str : action.payload.str
            }
        case TYPE.BTN_SUB:
            return {
                ...state,
                po_process : action.payload
            }
        case TYPE.SET_MODAL:
            return {
                ...state,
                show_modal : action.payload
            }
        case TYPE.GET_SUPPLIERS:
            return {
                ...state,
                suppliers : action.payload
            }
        case TYPE.INV_PER_SUPPLIER: //resuing types from another module
            return {
                ...state,
                po_per_supplier : action.payload
            }
        case TYPE.INV_INDEX:
            return {
                ...state,
                target_index : action.payload
            }
        case TYPE.GET_SINGLE_SUPPLIER:
            return{
                ...state,
                supplier_id : action.payload
            }
        case TYPE.DELETE_GENERAL :
            return {
                ...state,
                delete_general : action.payload
            }
        case TYPE.SELECTED_ROWS :
            return{
                ...state,
                selected_rows : action.payload
            }
        case TYPE.STOCK_LOGS:
            return{
                ...state,
                stock_logs : action.payload.stock_logs,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            }
        case TYPE.INV_FILTER_BRAND:
            return {
                ...state,
                inv_brand: action.payload
            }
        case TYPE.DATE_FILTER_INPUT:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case TYPE.INVENTORY_DATE_INPUT_CLEAR:
            return {
                ...state,
                date_from: new Date(),
                date_to: new Date(),
                date_filter: false,
                display_tab: { ...state.display_tab, [action.payload]: true },
            }
        default:
            return state;
            break;
    }

}

export default inventoryReducer;

