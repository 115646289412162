import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';
import { search } from '../../../Services/api.laravel.search';

const ShippingService = {
    searchPO: (formParams) => http.post('/admin/po/search', formParams),
    saveShipping: (formParams) => http.post('/admin/shipping-costs/store', formParams),
    updateShipping: (formParams) => http.post('/admin/shipping-costs/update', formParams),
    searchShipping: (formParams) => http.post('/admin/shipping-costs', formParams),
    deleteShipping: (formParams) => http.post('/admin/shipping-costs/destory', formParams),
    showShipping : (id) => http.get('/admin/shipping-costs/view/' + id)
}


export default ShippingService