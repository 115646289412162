import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import {
    getSuppliers,
    handleSearchInput,
    getSingleSupplier,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
} from '../../redux/actions/supplier/supplier.actions';
import ReactTooltip from 'react-tooltip';
import { formattedDate, truncateObjectId } from '../../Utils/Common';
import { Redirect, useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

function Supplier({
    supplier: {
        suppliers,
        set_row_checked,
        pagination,
        search,
        loading,
        edit_url,
        create_url,
        history_url,
        sort_order_name,
        sort_order_direction,
    },
    getSuppliers,
    handleSearchInput,
    getSingleSupplier,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    hoverIndex,
    updateMUISetting,
    setMUIPage,
    muiPage,
}) {
    const { hasPermission } = useAccess();

    const canCreate = hasPermission('inv.purchases.suppliers.create');
    const canDelete = hasPermission('inv.purchases.suppliers.delete');
    const canEdit = hasPermission('inv.purchases.suppliers.edit');
    const canview = hasPermission('inv.purchases.suppliers.view');
    const canViewHistroy = hasPermission('inv.purchases.suppliers.history');

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');

    const [copiedId, setCopiedId] = useState(null);

    useEffect(() => {
        if (debouncedSearch) {
            if (debouncedSearch[0]) {
                if (oldDebounced !== debouncedSearch[0]) {
                    if (debouncedSearch[0].length !== 1) {
                        if (canview) {
                            setOldDebounced(debouncedSearch[0]);
                            getSuppliers();
                        }
                    }
                }
            } else {
                if (oldDebounced !== '') {
                    if (canview) {
                        setOldDebounced('');
                        getSuppliers();
                    }
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    useEffect(() => {
        getSuppliers(muiPage);
    }, []);

    let history = useHistory();
    const changePage = (link) => history.push(link);

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(2)': {
                            height: '61px',
                            width: '250px',
                        },
                        '&:nth-child(3)': {
                            width: '250px',
                            'text-align': 'justify',
                        },
                        '&:nth-child(4)': {
                            width: '250px',
                        },
                        '&:nth-child(5)': {
                            width: '250px',
                        },
                        '&:nth-child(6)': {
                            width: '150px',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: 'ID',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                customBodyRender: (_, tableMeta) => {
                    const supplierId = suppliers[tableMeta['rowIndex']]._id;
                    return (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <span>{truncateObjectId(supplierId)}</span>
                            {hoverIndex === tableMeta.rowIndex ? (
                                <span style={{ marginLeft: '15px' }}>
                                    <span
                                        style={{ position: 'relative' }}
                                        onMouseLeave={() =>
                                            setTimeout(
                                                () => setCopiedId(null),
                                                230
                                            )
                                        }
                                    >
                                        <CopyToClipboard
                                            text={supplierId}
                                            onCopy={() => {
                                                setCopiedId(supplierId);
                                            }}
                                        >
                                            {/* <FontAwesomeIcon
                                                icon={faClipboard}
                                                // style={{ color: '#32cafe' }}
                                                style={{ color: 'blue' }}
                                                size="lg"
                                            /> */}
                                              <i  className="ft-copy" style={{fontSize: "14px"}}></i>
                                        </CopyToClipboard>
                                        {copiedId === supplierId ? (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    left: '-20px',
                                                    width: '60px',
                                                    height: '60px',
                                                }}
                                            >
                                                <a
                                                    data-tip="Copied!"
                                                    data-for={`copied-${supplierId}`}
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '100%',
                                                    }}
                                                >
                                                    &nbsp;
                                                </a>
                                                <ReactTooltip
                                                    id={`copied-${supplierId}`}
                                                    type="success"
                                                    effect="solid"
                                                    scrollHide={false}
                                                    resizeHide={false}
                                                    place="top"
                                                    delayHide={5000}
                                                />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: 'name',
            label: 'Name',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = suppliers[tableMeta['rowIndex']]._id;
                    return (
                        <Link
                            className="txt-underline"
                            to={`${history_url}/${id}`}
                        >
                            {value}
                        </Link>
                    );
                },
                setCellHeaderProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
        {
            name: 'street1',
            label: 'Address',
            options: {
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const supplier = suppliers[tableMeta.rowIndex];
                    const addressComponents = [
                        supplier.street1,
                        supplier.street2 && ', ',
                        supplier.street2,
                        (supplier.street1 || supplier.street2) && ', ',
                        supplier.city,
                        supplier.city && supplier.state && ', ',
                        supplier.state,
                    ]
                        .filter(Boolean)
                        .join('');

                    return <div>{addressComponents || 'N/A'}</div>;
                },
            },
        },

        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value) => value || 'N/A',
            },
        },
        {
            name: 'mobile',
            label: 'Contact',
            options: {
                filter: false,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value) => value || 'N/A',
            },
        },
        {
            name: '',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = suppliers[tableMeta['rowIndex']]._id;

                    if (!canEdit) {
                        return false;
                    }

                    return (
                        <>
                            {hoverIndex == tableMeta.rowIndex && (
                                <Link
                                    to={`${edit_url}/${id}`}
                                    className="btn btn-sm btn-grey"
                                >
                                    <i className="ft ft-edit"></i>
                                </Link>
                            )}
                        </>
                    );
                },
                // setCellProps: (value) => {
                //     return { className: 'text-center' };
                // },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        filter: false,
        searchOpen: true,
        searchProps: {
            onBlur: (e) => {
                console.log('onBlur!');
            },
            onKeyUp: (e) => {
                console.log('onKeyUp!');
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100, 200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `suppliers_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getSuppliers(null, numberOfRows);
            updateMUISetting('rows', 'supplier', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getSuppliers(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'supplier',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }

            return false;
        },
        onCellClick: (colData, cellMeta) => {
            // console.log(purchase_orders[cellMeta.dataIndex]._id);
            if (cellMeta.colIndex != 6) {
                if (canViewHistroy) {
                    // changePage(`${history_url}/${suppliers[cellMeta.dataIndex]._id}`);
                }
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };
    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'supplier',
        null,
        columns
    );

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Purchases
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a
                                            href="supplier"
                                            className="text-white"
                                        >
                                            {' '}
                                            Suppliers
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <Show when="inv.purchases.suppliers.view">
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                List of Suppliers{' '}
                                                <a
                                                    href="#"
                                                    className="tool-tip"
                                                    data-tip="this is a tool tip"
                                                >
                                                    <i className="la la-question-circle"></i>
                                                </a>
                                                <Show when="inv.purchases.suppliers.create">
                                                    <Link
                                                        to={create_url}
                                                        className="btn btn-primary pull-right btn-sm"
                                                    >
                                                        <i className="ft ft-plus"></i>{' '}
                                                        Create New
                                                    </Link>
                                                </Show>
                                            </h4>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <section className="row">
                                                    <div className="col-sm-12">
                                                        <MuiThemeProvider
                                                            theme={getMuiTheme()}
                                                        >
                                                            <MUIDataTable
                                                                title={
                                                                    'List of Suppliers'
                                                                }
                                                                data={suppliers}
                                                                columns={
                                                                    _columns
                                                                }
                                                                options={
                                                                    options
                                                                }
                                                            />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Show>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    supplier: state.supplier,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getSuppliers,
    handleSearchInput,
    getSingleSupplier,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
})(Supplier);
