import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  getCategories,
  getSingleCategory,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
} from "../../redux/actions/category/category.actions";
import { useHistory } from "react-router-dom";
import { formattedDate, truncateObjectId } from "../../Utils/Common";
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { Show, useAccess } from "react-redux-permission";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  handleHover,
  setMUIPage,
  updateMUISetting,
} from "../../redux/actions/helper/helper.actions";
import moment from "moment";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

function CategoryList({
  category: {
    loading,
    pagination,
    cat_search,
    categories,
    create_url,
    edit_url,
    sort_order_name,
    sort_order_direction,
    set_row_checked,
  },
  getCategories,
  getSingleCategory,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  handleHover,
  hoverIndex,
  updateMUISetting,
  setMUIPage,
  muiPage,
}) {
  const { hasPermission } = useAccess();
  const canCreate = hasPermission("inv.products.categories.create");
  const canDelete = hasPermission("inv.products.categories.delete");
  const canEdit = hasPermission("inv.products.categories.edit");
  const canview = hasPermission("inv.products.categories.view");

  const history = useHistory();

  useEffect(() => {
    if (canview) {
      getCategories(muiPage);
    }
  }, []);

  // START DEVOUNCED SEARCH
  const debouncedSearch = useDebounce(cat_search, 800);
  const [oldDebounced, setOldDebounced] = useState("");
  useEffect(() => {
    if (debouncedSearch) {
      if (oldDebounced !== debouncedSearch[0]) {
        if (debouncedSearch[0].length !== 1) {
          setOldDebounced(debouncedSearch[0]);
          let isInputSearch =
            cat_search === "" || cat_search === null ? false : true;
          getCategories(1, null, isInputSearch);
        }
      }
    }
  }, [debouncedSearch]);
  // END DEVOUNCE SEARCH

  const [copiedId, setCopiedId] = useState(null);

  const changePage = (link) => history.push(link);

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "rgba(0,0,0,.05)",
            },
          },
        },
        MuiTableRow: {
          hover: {
            "&:hover": {
              backgroundColor: "#bfcfe4 !important",
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            "&:nth-child(2)": {
              height: "61px",
            },
          },
        },
        MUIDataTableHeadCell: {
          root: {
            "&:nth-child(7)": {
              width: "150px",
            },
            "&:nth-child(4)": {
              width: "400px",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        display: true,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
        customBodyRender: (_, tableMeta) => {
          const categoryId = categories[tableMeta['rowIndex']]._id;
          return (
              <div style={{ whiteSpace: 'nowrap' }}>
                  <span>{truncateObjectId(categoryId)}</span>
                  {hoverIndex === tableMeta.rowIndex ? (
                      <span style={{ marginLeft: '15px' }}>
                          <span
                              style={{ position: 'relative' }}
                              onMouseLeave={() =>
                                  setTimeout(
                                      () => setCopiedId(null),
                                      230
                                  )
                              }
                          >
                              <CopyToClipboard
                                  text={categoryId}
                                  onCopy={() => {
                                      setCopiedId(categoryId);
                                  }}
                              >
                                  {/* <FontAwesomeIcon
                                      icon={faClipboard}
                                      // style={{ color: '#32cafe' }}
                                      style={{ color: 'blue' }}
                                      size="lg"
                                  /> */}
                                    <i  className="ft-copy" style={{fontSize: "14px"}}></i>
                              </CopyToClipboard>
                              {copiedId === categoryId ? (
                                  <span
                                      style={{
                                          position: 'absolute',
                                          left: '-20px',
                                          width: '60px',
                                          height: '60px',
                                      }}
                                  >
                                      <a
                                          data-tip="Copied!"
                                          data-for={`copied-${categoryId}`}
                                          style={{
                                              display: 'inline-block',
                                              width: '100%',
                                          }}
                                      >
                                          &nbsp;
                                      </a>
                                      <ReactTooltip
                                          id={`copied-${categoryId}`}
                                          type="success"
                                          effect="solid"
                                          scrollHide={false}
                                          resizeHide={false}
                                          place="top"
                                          delayHide={5000}
                                      />
                                  </span>
                              ) : (
                                  ''
                              )}
                          </span>
                      </span>
                  ) : (
                      ''
                  )}
              </div>
          );
      },
      },
    },
    { name: "name", label: "Name", options: { display: true } },
    {
      name: "parent_category_id",
      label: "Parent Category",
      options: {
        display: true,
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (value) => {
          // if parent_category_id == 0 it is parent else null child
          return (
            <>
              {value === 0 ? (
                <i className="ft ft-check f-regular"></i>
              ) : (
                <i className="ft ft-x f-regular"></i>
              )}
            </>
          );
        },
        setCellProps: (value) => {
          return { className: "text-left" };
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        display: true,
        customBodyRender: value => value || "N/A",
        setCellProps: (value) => ({
          style: {
            minWidth: "100px",
          },
        }),
      },
    },
    // {
    //   name: "created_at",
    //   label: "Created at",
    //   options: {
    //     display: true,
    //     print: false,
    //     download: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       let created_at = categories[tableMeta["rowIndex"]].created_at;

    //       return <>{moment(created_at).format("YYYY-MM-DD - hh:mmA")}</>;
    //     },
    //     setCellProps: (value) => {
    //       return { className: "cursor-pointer" };
    //     },
    //   },
    // },
    // {
    //   name: "updated_at",
    //   label: "Updated at",
    //   options: {
    //     display: true,
    //     print: false,
    //     download: false,
    //     setCellProps: (value) => {
    //       return { className: "cursor-pointer" };
    //     },
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       let updated_at = categories[tableMeta["rowIndex"]].updated_at;

    //       return <>{moment(updated_at).format("YYYY-MM-DD - hh:mmA")}</>;
    //     },
    //   },
    // },
    {
      name: "",
      label: "",
      options: {
        display: true,
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = categories[tableMeta["rowIndex"]]._id;

          if (!canEdit) {
            return false;
          }

          return (
            <div className="btn-group">
              {hoverIndex == tableMeta.rowIndex && (
                <Link to={`${edit_url}/${id}`} className="btn btn-sm btn-grey">
                  <i className="ft ft-edit"></i>
                </Link>
              )}
            </div>
          );
        },
        setCellProps: (value) => {
          return { className: "text-center" };
        },
      },
    },
  ];

  const options = {
    serverSide: true,
    jumpToPage: true,
    filterType: "dropdown",
    responsive: "standard",
    search: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 20, 50, 100, 200],
    rowsPerPage: pagination.itemsCountPerPage,
    count: pagination.totalItemsCount,
    page: pagination.activePage - 1,
    sortOrder: {
      name: sort_order_name,
      direction: sort_order_direction,
    },
    downloadOptions: {
      filename: `attribute_templates_${formattedDate()}.csv`,
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Spinner />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onChangeRowsPerPage: (numberOfRows) => {
      getCategories(null, numberOfRows);
      updateMUISetting("rows", "category", numberOfRows);
    },
    onTableChange: (action, tableState) => {
      // console.log(action, tableState)
      switch (action) {
        case "changePage":
          getCategories(tableState.page + 1, pagination.itemsCountPerPage);
          setMUIPage(tableState.page + 1);
          break;
        case "sort":
          // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
          let column_name = tableState.sortOrder.name;
          let direction = tableState.sortOrder.direction;
          sortTableByColumn(column_name, direction);
          break;
        case "search":
          // handleSearchInputMUI(tableState.searchText);
          break;
        case "rowSelectionChange":
          let selectedRows = tableState.selectedRows.data;
          let currentRows = tableState.data;
          getSelectedRows(selectedRows, currentRows);
          break;
        case "propsUpdate":
          if (set_row_checked) {
            tableState.selectedRows["data"] = [];
            tableState.selectedRows["lookup"] = {};
            tableState.selectedRows["showResponsive"] = false;
          } else {
            return tableState;
          }
        case "viewColumnsChange":
          updateMUISetting("columns", "category", null, tableState.columns);
          break;
        default:
        // console.log('action not handled.');
      }
    },
    onRowsDelete: () => {
      // Confirm Delete Modal
      if (canDelete) {
        confirmDeleteDialogBox();
      }

      return false;
    },
    onRowClick: (rowData, rowMeta) => {
      // redirect to edit page
      if (canEdit) {
        //    changePage(`${edit_url}/${rowData[0]}`);
      }
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div className="custom-search">
          <i className="ft ft-search"></i>{" "}
          <input
            type="text"
            name="search"
            onKeyUp={handleSearchInputMUI}
            className="custom-search-input"
          />
        </div>
      );
    },
    setRowProps: (data, dataIndex, rowIndex) => {
      return {
        onMouseEnter: () => handleHover("in", rowIndex),
        onMouseLeave: () => handleHover("out", rowIndex),
      };
    },
  };

  if (!canDelete) {
    options.selectableRows = false;
  }

  const _columns = updateMUISetting("view-columns", "category", null, columns);

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            List of Categories{" "}
            <a href="#" className="tool-tip" data-tip="this is a tool tip">
              <i className="la la-question-circle"></i>
            </a>
            <div className="pull-right">
              <Show when="inv.products.categories.create">
                <Link
                  to={create_url}
                  className="btn btn-sm btn-primary text-white"
                >
                  <i className="ft ft-plus"></i> Create New
                </Link>
              </Show>
            </div>
          </h4>
          <a className="heading-elements-toggle">
            <i className="la la-ellipsis-v font-medium-3"></i>
          </a>
          <div className="heading-elements">
            <ul className="list-inline mb-0"></ul>
          </div>
        </div>
        <div className="card-content collapse show">
          <div className="card-body">
            <Show when="inv.products.categories.view">
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  data={categories}
                  columns={_columns}
                  options={options}
                />
              </MuiThemeProvider>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  category: state.category,
  hoverIndex: state.helper.hoverIndex,
  muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
  getCategories,
  getSingleCategory,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  handleHover,
  updateMUISetting,
  setMUIPage,
})(CategoryList);
