import React, { Fragment, Component } from 'react';
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Redirect, useHistory, Link } from 'react-router-dom';
 
function ProfileSubscription () {
  
return (

<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2  fix-header">
                <h3 className="content-header-title mb-0 d-inline-block text-white">Profile</h3>
            </div>
        </div>

        <div className="content-body margin-top-5 mt-2">
            <section className="row f-regular">
                <div className="col-sm-12">
                    <div className="card">
                        {/*
                        <div className="card-header"></div>
                        */}
                        <div className="card-content collapse show">
                            <div className="card-body">
                                <div className="">
                                    <nav class="nav f-regular">
                                        <Link to={'/account/profile'} className="nav-link mr-50 inv-top-nav ">Information</Link>
                                        <Link to={'/account/profile/subcription'} className="nav-link mr-50 inv-top-nav inv-top-nav-active">Billing</Link>
                                        <Link to={'/account/invoice_receipts'} className="nav-link mr-50 inv-top-nav">Sales Invoice/Receipt</Link>                                                
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="basic-examples">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="wrap-box">
                                                <div className="">
                                                    <strong className="title-head font-medium-2">Subscription Plan</strong> <a href="/account/profile/profile_change_subscription" className="title-head pull-right text-primary">Change</a>
                                                </div>
                                                <div className="card sales-box">
                                                    <div className="card-content">
                                                        <div className="card-body">
                                                            <div className="media d-flex">
                                                                <div className="align-left p-1">
                                                                    <i class="ft ft-package info icon-sub"></i>
                                                                </div>

                                                                <div className="media-body align-self-bottom mt-1 text-center">
                                                                    <strong className=" font-medium-2 ">Standard Plan</strong>
                                                                    <p className="d-block mb-1 ">Expires on Dec 2021</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrap-box">
                                                <div className="">
                                                    <strong className="title-head font-medium-2 mt-1">Billing Period</strong> <a href="/account/profile/change_billing" className="title-head pull-right text-primary">Change</a>
                                                </div>

                                                <div className="card sales-box">
                                                    <div className="card-content">
                                                        <div className="card-body">
                                                            <div className="media d-flex">
                                                                <div className="media-body align-self-bottom mt-1">
                                                                    <strong className=" font-medium-2 ">MONTHLY</strong>
                                                                    <p className="d-block mb-1 ">Next renewal:25 Nov 2020</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrap-box">
                                                <div className=""><strong className="title-head font-medium-2 mt-1">Payment Method</strong> <a href="#" className="title-head pull-right text-primary">Change</a></div>
                                                <div className="card sales-box">
                                                    <div className="card-content">
                                                        <div className="card-body">
                                                            <div className="media d-flex">
                                                                <div className="align-left p-1">
                                                                    <img src="/app-assets/images/paypal.png" alt="payment-logo" className="payment-logo" />
                                                                </div>

                                                                <div className="media-body align-self-bottom mt-1 text-center">
                                                                    <p className=" font-medium-2 ">**** **** **** 3253</p>
                                                                    <strong className=" font-medium-2 ">John Doe</strong>
                                                                    <p className="d-block mb-1 ">Expires on Dec 2021</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 ml-2">
                                            <strong className="font-medium-2 mt-1">Billing Info</strong>
                                            <div className="row mt-2">
                                                <div className="col-md-6 ">
                                                    <strong>Billing Address:</strong>
                                                    <div className="form-group row">
                                                        <div className="col-md-11 col-10">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>

                                                    <strong>Country:</strong>
                                                    <div className="form-group row">
                                                        <div className="col-md-11 col-10">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>

                                                    <strong>Email Address:</strong>
                                                    <div className="form-group row">
                                                        <div className="col-md-11 col-10">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <strong>Company Name:</strong>
                                                    <div className="form-group row">
                                                        <div className="col-md-11 col-10">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>

                                                    <strong>Vat Number:</strong>
                                                    <div className="form-group row">
                                                        <div className="col-md-11 col-10">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>

                                                    <strong>Company Address:</strong>
                                                    <div className="form-group row">
                                                        <div className="col-md-11 col-10">
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>


    );
  }

const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(ProfileSubscription);