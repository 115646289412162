import React, {
    Fragment,
    useEffect,
    useMemo,
    useCallback,
    useState,
} from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect } from 'react-redux';
import {
    getPurchaseOrders,
    setPage,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    sendEmailDyanmic,
    dynamiPdf,
    emailConfig,
} from '../../redux/actions/purchase_order/purchase_order.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PurchaseOrderModalPrint from './modals/PurchaseOrder.modal.print';
import verifyPassModal from './modals/verifyModal';
import { useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { formattedDate } from '../../Utils/Common';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import { defaultFormat } from '../../Utils/DateFormat';

function PurchaseOrderTable({
    purchase_order: {
        purchase_orders,
        loading,
        search,
        pagination,
        print_modal,
        edit_url,
        edit_page,
        create_url,
        item_id,
        sort_order_name,
        sort_order_direction,
        set_row_checked,
        password_verify_modal,
    },
    supplier: { history_url },
    setPage,
    getPurchaseOrders,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    pageOrigin,
    handleHover,
    hoverIndex,
    updateMUISetting,
    sendEmailDyanmic,
    dynamiPdf,
    emailConfig,
    setMUIPage,
    muiPage,
}) {
    const history = useHistory();
    const { hasPermission } = useAccess();
    const canCreate = hasPermission('inv.purchases.purchase-orders.create');
    const canDelete = hasPermission('inv.purchases.purchase-orders.delete');
    const canEdit = hasPermission('inv.purchases.purchase-orders.edit');
    const canview = hasPermission('inv.purchases.purchase-orders.view');
    const canPrint = hasPermission('inv.purchases.purchase-orders.print');
    const canReceive = hasPermission('inv.receiving.itemReceipts.create');

    useEffect(() => {
        if (canview) {
            getPurchaseOrders(muiPage, null, pageOrigin);
        }
        emailConfig();
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    if (canview) {
                        setOldDebounced(debouncedSearch[0]);
                        getPurchaseOrders(1, null, pageOrigin);
                    }
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles((theme) => ({
        centeredHeader: {
            '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
        },
    }));
    const classes = useStyles();

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(2)': {
                            height: '61px',
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        wordWrap: 'break-word',
                        '&:nth-child(10)': {
                            width: '150px',
                        },

                        '&:nth-child(11)': {
                            // actions column
                            minWidth: '200px',
                            width: '200px',
                            textAlign: 'center !important',
                        },
                    },
                },
                MuiTableSortLabel: {
                    root: {
                        display: 'none',
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'purchase_order_no',
            label: 'PO No',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = purchase_orders[tableMeta['rowIndex']]._id;
                    return (
                        <Link
                            className="txt-underline"
                            onClick={() => setPage('print_modal', id)}
                        >
                            {value}
                        </Link>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'created_at',
            label: 'Date Created',
            options: {
                display: true,
                customBodyRender: (value) => {
                    return <span> {value.substr(0, 10)} </span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'supplier',
            label: 'Supplier',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    let id = purchase_orders[tableMeta['rowIndex']].supplier_id;
                    return (
                        // <span><Link className="txt-underline" to={`${history_url}/${value != undefined ? value._id  : ''}`}>{value != undefined ? value.name : ''}</Link></span>
                        <span>
                            <Link
                                className="txt-underline"
                                to={`${history_url}/${id ?? ''}`}
                            >
                                {value ?? ''}
                            </Link>
                        </span>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                display: true,
                customBodyRender: (value) => {
                    return (
                        <span className={'lbl-status lbl-' + value}>
                            {' '}
                            {value}{' '}
                        </span>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: '_total_items',
            label: 'Items',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'text-center ' };
                },
                customBodyRender: (value) => {
                    return <div className="text-center">{value}</div>;
                },
            },
        },
        {
            name: '_total_qty',
            label: 'Requested',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'text-center ' };
                },
                customBodyRender: (value) => {
                    return <div className="text-center">{value}</div>;
                },
            },
        },
        {
            name: '',
            label: 'Receive Status',
            options: {
                display: true,
                filter: false,
                sort: false,
                download: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let data = purchase_orders[tableMeta['rowIndex']];

                    // console.log(data)
                    return (
                        // <div>
                        //     {
                        //         // hoverIndex == tableMeta.rowIndex &&
                        //          (data.delivery_id == null && data.status !== 'draft' && data.status !== 'cancelled') ?
                        //              canReceive == true ?
                        //                     <Link to={ "/receivings/create?po_id=" + data._id + '&po_no=' + data.purchase_order_no } className='btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white'>Receive</Link>
                        //                     :
                        //                     <Link to="#" className='btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white disabled'>Receive</Link>
                        //             :
                        //             <span className='text-secondary'><div>{data.status == 'draft' || 'cancelled' ? 'N/A' : <span className="lbl-status lbl-received">Received</span> }</div></span>
                        //     }

                        // </div>
                        <div>
                            {data.status === 'saved' &&
                            data.delivery != null ? (
                                <span className="lbl-status lbl-received">
                                    Received
                                </span>
                            ) : data.status === 'saved' ? (
                                data.delivery ? (
                                    <Link
                                        to={`/receivings/edit/${data.delivery._id}`}
                                        className="txt-underline"
                                    >
                                        {data.delivery.item_receipt_no}
                                    </Link>
                                ) : (
                                    <Link
                                        to={`/receivings/create?po_id=${data._id}&po_no=${data.purchase_order_no}`}
                                        className="btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white"
                                    >
                                        Receive PO
                                    </Link>
                                )
                            ) : data.status === 'draft' ||
                              data.status === 'cancelled' ? (
                                <span>N/A</span>
                            ) : (
                                <span className="text-secondary">
                                    <div>N/A</div>
                                </span>
                            )}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-center ' };
                },
            },
        },

        {
            name: '',
            label: 'Shipping cost',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let data = purchase_orders[tableMeta['rowIndex']];
                    return (
                        <div>
                            {
                                // hoverIndex == tableMeta.rowIndex &&
                                data.shipping_id == null &&
                                data.status !== 'draft' &&
                                data.status !== 'cancelled' ? (
                                    canReceive == true ? (
                                        <Link
                                            to={
                                                '/receivings/create?po_id=' +
                                                data._id +
                                                '&po_no=' +
                                                data.purchase_order_no
                                            }
                                            className="btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white"
                                        >
                                            SC
                                        </Link>
                                    ) : (
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white disabled"
                                        >
                                            SC
                                        </Link>
                                    )
                                ) : (
                                    <span className="text-secondary">
                                        <div>
                                            {data.status == 'draft' ||
                                            'cancelled' ? (
                                                'N/A'
                                            ) : (
                                                <span className="lbl-status lbl-received">
                                                    SC
                                                </span>
                                            )}
                                        </div>
                                    </span>
                                )
                            }
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-center ' };
                },
            },
        },
        {
            name: 'remarks',
            label: 'Remarks',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'mw-20m' };
                },
                customBodyRender: (value) =>
                    <p style={{fontSize: "0.875rem", color: "black"}} title={value}>
                    {
                        !value
                            ? ''
                            : typeof value === 'string' && value.length > 20
                            ? value.slice(0, 20) + '...'
                            : value
                    }
                    </p>
                   ,
            },
        },
        {
            name: '',
            label: 'Actions',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = purchase_orders[tableMeta['rowIndex']]._id;
                    if (!canEdit) {
                        return false;
                    }

                    return (
                        <div className="btn-group">
                            {(hoverIndex == tableMeta.rowIndex ||
                                purchase_orders[tableMeta['rowIndex']]
                                    .sending_email ||
                                purchase_orders[tableMeta['rowIndex']]
                                    .download_pdf) && (
                                <>
                                    <Link
                                        to={
                                            '/purchases/purchase-orders/edit/' +
                                            id
                                        }
                                        className="btn btn-sm btn-grey btn-sm"
                                    >
                                        <i className="ft ft-edit"></i>
                                    </Link>
                                    <button
                                        className="btn btn-sm btn-blue"
                                        onClick={(e) =>
                                            sendEmailDyanmic(
                                                e,
                                                id,
                                                tableMeta.rowIndex
                                            )
                                        }
                                    >
                                        <i
                                            className={
                                                purchase_orders[
                                                    tableMeta['rowIndex']
                                                ].sending_email
                                                    ? 'icon-loading-custom icon-15'
                                                    : purchase_orders[
                                                          tableMeta['rowIndex']
                                                      ].email_sent
                                                    ? 'custom-resend'
                                                    : 'ft ft-navigation'
                                            }
                                        ></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) =>
                                            dynamiPdf(e, id, tableMeta.rowIndex)
                                        }
                                    >
                                        <i
                                            className={
                                                purchase_orders[
                                                    tableMeta['rowIndex']
                                                ].download_pdf
                                                    ? 'icon-loading-custom icon-15'
                                                    : 'ft-custom-pdf'
                                            }
                                        ></i>
                                    </button>
                                </>
                            )}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'text-center ' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard', //
        search: false,
        filter: false,
        searchOpen: true,
        searchProps: {
            onBlur: (e) => {
                console.log('onBlur!');
            },
            onKeyUp: (e) => {
                console.log('onKeyUp!');
            },
        },
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `po_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getPurchaseOrders(null, numberOfRows, pageOrigin);
            updateMUISetting('rows', 'purchase_order', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState)
            switch (action) {
                case 'changePage':
                    getPurchaseOrders(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                    break;
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'purchase_order',
                        null,
                        tableState.columns
                    );
                    break;
                case 'changeRowsPerPage':
                    // updateMUISetting('rows', 'purchase_order', tableState.rowsPerPage);
                    break;
                case 'onFilterDialogOpen':
                    break;
                case 'onSearchClose':
                    break;
                default:
                    // console.log(tableState);
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            confirmDeleteDialogBox(pageOrigin);
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page
            // changePage(`${edit_url}/${rowData[0]}`)
            // showmodal
        },
        onCellClick: (colData, cellMeta) => {
            // console.log(cellMeta)
            // console.log(cellMeta.dataIndex);
            if (cellMeta.colIndex !== 7 && cellMeta.colIndex !== 8) {
                // console.log('PO_PRINT_ID', purchase_orders[cellMeta.dataIndex]._id, purchase_orders);
                if (canPrint) {
                    // setPage('print_modal', purchase_orders[cellMeta.dataIndex]._id);
                }
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'purchase_order',
        null,
        columns
    );

    return (
        <>
            <Show when="inv.purchases.purchase-orders.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={purchase_orders}
                        columns={_columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>
            <PurchaseOrderModalPrint
                show={print_modal}
                onHide={() => setPage('print_modal', null, false)}
                edit_url={edit_url}
            />
            <verifyPassModal
                show={password_verify_modal}
                onHide={() => setPage('password_verify_modal', null, false)}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    purchase_order: state.purchase_order,
    supplier: state.supplier,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getPurchaseOrders,
    setPage,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    sendEmailDyanmic,
    dynamiPdf,
    emailConfig,
    setMUIPage,
})(PurchaseOrderTable);
