import { SET_LOADING, SET_MODAL, CLEAR_LOG, SEARCH_INPUT, GET_SINGLE_LOG, GET_LOGS, SET_MINI_LOADING, SORT_BY_COLUMN } from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import { LogService } from './_service.log';
import { getNumRows } from '../../../Utils/Common';


// handle edit inputs
export const handleSearchInput = e => async dispatch => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value })
}

// loading
export const setLoading = status => async dispatch => dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = status => async dispatch => dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearLog = () => async dispatch => dispatch({ type: CLEAR_LOG  });


// set modal
export const setModal = (modal, id, status = true) => async dispatch => {

    if(status)
    {
        if(modal == 'create_modal')
        {
            // create_modal
            dispatch(clearLog());
            dispatch({ type: SET_MODAL, payload: { modal, status } });
        }
        else
        {
            // view_modal, edit_modal, delete_modal, 
            dispatch(getSingleLog(id));
            dispatch({ type: SET_MODAL, payload: { modal, status } });
        }

    }
    else
    {
        // clear all the states and modals
        dispatch(clearLog()); 
    }
}

// get logs
export const getLogs  = (pageNumber, rows_per_page = null) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    let { search, pagination, sort_order_name, sort_order_direction } = getState().log; 
    let rows_per_page_val = rows_per_page != null ? rows_per_page : getNumRows('log');

    let searchPostParams = {
        search: search,
        activePage: pagination.activePage,
        page: pageNumber,
        rows_per_page: rows_per_page_val, 
        sort_order_name: sort_order_name,
        sort_order_direction: sort_order_direction
    }

    LogService.paginateWithSearchLogs(searchPostParams).then(res => {

        let pagination = {
            totalCount: res.data.logs.total, 
            activePage: res.data.logs.current_page, 
            itemsCountPerPage: res.data.logs.per_page, 
            totalItemsCount: res.data.logs.total
        }

        dispatch({ type: GET_LOGS, payload: { logs: res.data.logs.data, pagination: pagination }})
        dispatch(setLoading(false));
    })
    .catch(error => {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
};


// get single log
export const getSingleLog = id => async dispatch => {
    dispatch(setMiniLoading(true));
    LogService.getSingleLog(id).then(res => {
       // console.log(res.data)
        dispatch({ type: GET_SINGLE_LOG, payload: res.data.log });
        dispatch(setMiniLoading(false));
    })
    .catch(error => {
        dispatch(setMiniLoading(false));
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
}


export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value })
}


// Sort by column name and direction
export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getLogs());
}
