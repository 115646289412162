import React, { Fragment, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  brandPost,
  handleInputChange,
} from "../../redux/actions/brand/brand.actions";
import ReactTooltip from "react-tooltip";
import {
  SwalError,
  SwalFormWarning,
  SwalWarning,
} from "../../Services/_swal.service";
import BeforeUnloadComponent from "react-beforeunload-component";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { blockRoute } from "../../redux/actions/helper/helper.actions";

function BrandCreateGlobal({
  brand: { single_brand, main_brand_page, main_url },
  handleInputChange,
  brandPost,
  pageOrigin,
  blockRoute,
  block_route,
}) {
  let history = useHistory();

  useEffect(() => {
    blockRoute(false);
  }, []);

  useEffect(() => {
    if (block_route) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
    }

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block_route]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (block_route) {
      SwalFormWarning(
        "Warning",
        "Do you want to leave without saving",
        () => goLastLocation(),
        () => blockAgain()
      );
    }
  };

  const goLastLocation = () => {
    history.goBack();
    blockRoute(false);
  };

  const blockAgain = () =>
    window.history.pushState(null, null, window.location.pathname);

  if (main_brand_page) return <Redirect to={main_url} />;

  return (
    <Fragment>
      <BeforeUnloadComponent
        blockRoute={block_route}
        ignoreBeforeunloadDocument={true}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          SwalFormWarning(
            "Warning",
            "Do you want to leave without saving",
            () => handleModalLeave(),
            () => handleModalCancel()
          );
        }}
      >
        <form onSubmit={(e) => brandPost(e, pageOrigin)}>
          <ReactTooltip effect='solid' event='click' />
          <section className='row f-regular'>
            <div className='col-12'>
              <label className='label-control f-regular'>
                Name: <span className='text-danger'>*</span>
              </label>
              <div className='form-group row'>
                <div className='col-md-11 col-10'>
                  <input
                    onChange={(e) => handleInputChange(e)}
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='Name'
                    required
                  />
                </div>
                <div className='float-right pl-0 col-1 col-md-1'>
                  <div className='input-group-prepend'>
                    <span
                      href='#'
                      className='tool-tip text-primary'
                      data-tip='this is a tool tip'
                    >
                      <i className='la la-question-circle'></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {pageOrigin == "brand" && (
              <div className='col-12'>
                <label className='label-control f-regular'>Description:</label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <textarea
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      name='description'
                      rows='3'
                      className='form-control'
                      placeholder='Description'
                    ></textarea>
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='col-12'>
              <div className='form-group row'>
                <div className='col-md-4 col-12 label-control f-regular'></div>
                <div className='col-md-7 col-10 text-right'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-save f-regular'
                  >
                    Save
                  </button>
                </div>
                <div className='float-right pl-0 col-2 col-md-1'></div>
              </div>
            </div>
          </section>
        </form>
      </BeforeUnloadComponent>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  brand: state.brand,
  block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
  handleInputChange,
  brandPost,
  blockRoute,
})(BrandCreateGlobal);
