import http from '../../../Services/api.laravel.http';

export const SalesInvoiceTemplateSettingService = {
	getSITemplateSettings: () => http.get('/admin/sales-invoice-template/settings/sales-invoice-template'),
	storeSITS: (formParams) => http.post('/admin/sales-invoice-template/settings/sales-invoice-template', formParams),
   	setAsDefault: (formParams) => http.post('/admin/sales-invoice-template/settings/default', formParams),
  	siTemplateSettingArchive: (formParams) => http.post('/admin/sales-invoice-template/settings/archive', formParams),
   	getSingleSITS: (id) => http.get(`/admin/sales-invoice-template/settings/${id}`),
   	updateSITS: (formParams) => http.post(`/admin/sales-invoice-template/settings/update`, formParams),
}
