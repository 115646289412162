import React, { Fragment } from 'react'

function SalesOrderBlankTable({ is_manual }) {

    // THIS BLANK TABLE IS USE IN SALES ORDER [MD]

    return (
        <Fragment>
            <table className="table table-hover table-striped table-bordered f-regular">
                <thead>
                    <tr>
                        <th className='min-wdth-100 max-wdth-100 text-center'>SKU</th>
                        <th className='min-wdth-300 max-wdth-300 text-center'>Product</th>
                        <th className='min-wdth-75 max-wdth-75 text-center'>Qty</th>
                        <th className='min-wdth-75 max-wdth-75 text-center'>Unit</th>
                        <th className='min-wdth-150 max-wdth-150 text-center'>Unit/Price</th>
                        <th className='min-wdth-100 max-wdth-100 text-center'>Amount</th>
                        <th width="5px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr align="center" className="bg-warning bg-accent-2">
                        <td colSpan={7}>
                           {
                               is_manual ? '-- Please select Customer --' : '-- Please select Quotation No. --'
                           }
                        </td>
                    </tr>
                    
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={4}></td>
                        <td align="right">Sub Total</td>
                        <td align="right">0.00</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={4}></td>
                        <td align="right">Discount</td>
                        <td align="right"> 0 %</td>
                        <td>&nbsp;</td>
                    </tr>

                    <tr>
                        <td colSpan={4}></td>
                        <td align="right">Vat</td>
                        <td align="right"> 0 %</td>
                        <td>&nbsp;</td>
                    </tr>

                    <tr>
                        <td colSpan={4}></td>
                        <td align="right">Delivery Fee</td>
                        <td align="right"> 0.00</td>
                        <td>&nbsp;</td>
                    </tr>

                    <tr>
                        <td colSpan="4"></td>
                        {/* <td align="right">Total</td>
                        <td align="center">0</td>
                        <td>&nbsp;</td> */}
                        <td><div align="right"><strong>Total</strong></div></td>
                        <td align="right">0.00</td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    )
}

export default SalesOrderBlankTable
