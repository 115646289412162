import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { uploadImage, removeImage } from '../../redux/actions/product/product.actions';
import { useDropzone } from 'react-dropzone';

function ProductUpload({ upload_preview, uploadImage, removeImage }) {

    const onDrop = useCallback(acceptedFiles => {
        uploadImage(acceptedFiles)
        // console.log(acceptedFiles)
     }, [])
 
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <Fragment>
            <h4 className="form-section"><i className="ft-clipboard"></i> Item Image  </h4>
            <div className="row mt-2 justify-content-center">
                <div className="col-12 col-sm-12 f-regular">
                    <div className="card">
                        <div className="card-body">
                            <center>
                                {
                                    upload_preview ? 
                                    <Fragment>
                                    <div className="text-center item-upload-img p-2">
                                            <img src={upload_preview} className="img-fluid mx-wdth-100"/><br/>
                                            <button onClick={removeImage} className="btn btn-danger mt-2">Remove Image</button>
                                    </div>
                                    </Fragment>
                                    :
                                    <div {...getRootProps()} className="dropzone-line-style">
                                        <input {...getInputProps()}/>
                                        <center>
                                            <i className="ft ft-upload-cloud upload-icon"></i>
                                            <div className="upload-message my-1">Drag & Drop <br/>Files Here</div>
                                            <div className="upload-message-or mb-2">or</div>
                                            <div className="btn btn-info btn">Browse Files</div>
                                        </center>
                                    </div>
                                }
                            </center>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-6">
                    <div className="row justify-content-center mt-5 pt-5">
                        <div className="col-6">
                            <input id="itemUpload" type="file" name="image" onChange={uploadImage}/><br/>
                            <span className="text-danger">maximum file upload 2MB</span>
                        </div>
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    upload_preview: state.product.single_product.upload_preview,
})

export default connect(mapStateToProps, { uploadImage, removeImage })(ProductUpload);

