import * as TYPE from './../types';
import { ATTR_SEARCH } from './ReducerTypes/attribute';
// state
const initialState = {
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    attribute_templates: [],
    single_attribute_template: { success: false, template_name: null, template_description: null,  attributes_list: []},
    attributes: [],
    search_attr: '',
    create_modal: false,
    view_modal: false,
    delete_modal: false,
    edit_modal: false,
    loading: false,
    unit_measurement: [],
    unit_options: {},
    type_options: [
        { value: 'Input Box', label: 'Text Field', name: 'type'},
        { value: 'Checkbox', label: 'Checkbox', name: 'type'},
        { value: 'Radio', label: 'Radio', name: 'type'},
        { value: 'Dropdown', label: 'Dropdown', name: 'type'},
    ],
    validation_options: [
        { value: 'String', label: 'Letters Only', name: 'validation'},
        { value: 'Numeric', label: 'Numbers Only', name: 'validation'},
        { value: 'Alpha Numeric', label: 'Alphanumeric', name: 'validation'},
    ],
    default_attr: {'id' : '', 'label': '', 'desc': '', 'unit': '', 'validation': '', 'options': '', unit_options: {}},
    attributesList: [],
    main_url: '/inventory/products?mode=Attributes',
    // edit_url: '/products/attribute-template/edit',
    create_url: '/inventory/products/attribute-template/create',
    edit_url: '/inventory/products/attribute-template/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false
}

const attributeTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.GET_ATTRIBUTE_TEMPLATES:
            return {
                ...state,
                attribute_templates: action.payload.attribute_templates,
                pagination: action.payload.pagination,
                set_row_checked: false
            }
        case TYPE.GET_SINGLE_ATTRIBUTE_TEMPLATE:
            return {
                ...state,
                single_attribute_template: action.payload.single_attribute_template
            }
        case TYPE.GET_ATTRIBUTES_FOR_TEMPLATE:
            return {
                ...state,
                attributes: action.payload
            }
        case TYPE.ATTRIBUTE_TEMPLATE_INPUT_CHANGE:
            return {
                ...state,
                single_attribute_template: { ...state.single_attribute_template, [action.payload.key]: action.payload.value }
            }
        case TYPE.ATTRIBUTE_TEMPLATE_INPUT_CHECK:
            return {
                ...state,
                single_attribute_template: { ...state.single_attribute_template, attributes: action.payload }
            }
        case TYPE.UPDATE_ATTRIBUTES_WITH_CHECK:
            return {
                ...state,
               attributes: action.payload
            }
        case ATTR_SEARCH: 
            return {
                ...state,
                search_attr: action.payload
            }
        case TYPE.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPE.SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload
            }
        case TYPE.SET_UNIT_MEASUREMENT: 
            return {
                ...state,
                unit_options: action.payload
            }   
        case TYPE.GET_UNIT_MEASUREMENT:
            return {
                ...state,
                unit_measurement: action.payload
            }
        case TYPE.SET_ATTR_LIST:
            return {
                ...state,
                attributesList: action.payload
            }    
        case TYPE.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status
            }
        case TYPE.HANDLE_SELECT_TYPE:
 
            if(action.payload.name == 'type')
            {
                return {
                    ...state,
                    single_attribute: { ...state.single_attribute, ...action.payload },
                    default_type_value: action.default_type_value
                }
            }
            else
            {
                // select is either unit || validation  
                return {
                    ...state,
                    single_attribute: { ...state.single_attribute, [action.payload.key]: action.payload.value },
                    [action.payload.default_key]: action.payload.default_value
                }
            }    
        case TYPE.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPE.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            }
        case TYPE.SET_ROW_CHECKED: 
            return {
                ...state,
                set_row_checked: true
            }
        case TYPE.CLEAR_ATTRIBUTE_TEMPLATE:
            return {
                pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
                attribute_templates: [],
                single_attribute_template: { success: false, template_name: null, template_description: null,  attributes_list: []},
                attributes: [],
                search_attr: '',
                create_modal: false,
                view_modal: false,
                delete_modal: false,
                edit_modal: false,
                loading: false,
                unit_measurement: [],
                unit_options: {},
                type_options: [
                    { value: 'Input Box', label: 'Text Field', name: 'type'},
                    { value: 'Checkbox', label: 'Checkbox', name: 'type'},
                    { value: 'Radio', label: 'Radio', name: 'type'},
                    { value: 'Dropdown', label: 'Dropdown', name: 'type'},
                ],
                validation_options: [
                    { value: 'String', label: 'Letters Only', name: 'validation'},
                    { value: 'Numeric', label: 'Numbers Only', name: 'validation'},
                    { value: 'Alpha Numeric', label: 'Alphanumeric', name: 'validation'},
                ],
                default_attr: {'id' : '', 'label': '', 'desc': '', 'unit': '', 'validation': '', 'options': '', unit_options: {}},
                attributesList: [],
                main_url: '/inventory/products?mode=Attributes',
                create_url: '/inventory/products/attribute-template/create',
                edit_url: '/inventory/products/attribute-template/edit',
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                set_row_checked: false
            }
        default:
            return state;
            break;
    }
}

export default attributeTemplateReducer;

