import http from '../../../Services/api.laravel.http';
var qs = require('qs');

export const AttributeTemplateService = {
 	getSingleAttributeTemplate: (id) => http.get('/admin/attribute-template/' + id),
  	ArchiveAttributeTemplate: (formParams) => http.post('/admin/attribute-template/archives/list', formParams),
  	paginateWithSearchAttributeTemplates: (formParams) => http.post('/admin/attribute-template/search', formParams),
	// api used in attributes
 	getAttributes: () => http.get('/admin/attribute'), // get the attributes
 	getSingleAttribute: (id) => http.get('/admin/attribute/' + id), // get the attributes base on id

 	getUnits: () => http.get('/admin/attribute-template/unit/measurement'),
 	attributeTemplatePost: (formParams) => http.post('/admin/attribute-template', formParams),
 	updateSingleAttributeTemplate: (formParams, id) => http.put('/admin/attribute-template/' + id, formParams),
}
