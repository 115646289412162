import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { uploadLogo,  showCropping, handleCropLogo, confirmDialogBox, cropIsset, cropTrigger } from '../../../../redux/actions/dr_template_setting/dr_template_setting.actions';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { dataURLtoFile, makeRandomID } from '../../../../Utils/Common';
import { ToastDanger } from '../../../../Services/_toast.service';
import $ from 'jquery';


function DRTSLogoCrop ({ single_dr_template_setting, uploadLogo , showCropping, handleCropLogo, confirmDialogBox, pageOrigin, cropIsset, crop_isset, crop_trigger, cropTrigger }) {

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
    });

    const [completedCrop, setCompletedCrop] = useState(null);

    const onLoad = useCallback((img) => {
        // imgRef.current = img;

        imgRef.current = img;
        const aspect = 4 / 3 ; //  aspect ratio: 4 / 3 or 16 / 9
        const width = img.width / aspect < img.height * aspect ? 50 : ((img.height * aspect) / img.width) * 50;
        const height = img.width / aspect > img.height * aspect ? 50 : (img.width / aspect / img.height) * 50;
        const y = (100 - height) / 2;
        const x = (100 - width) / 2;
    
        setCrop({
            unit: '%',
            width,
            height,
            x,
            y,
            // aspect,  // uncomment this if you want to
        });

        return false; // Return false if you set crop state in here.        
    }, []);


    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

    }, [completedCrop]);


    const onChange = (c) => {
        setCrop(c);
        cropTrigger(true);
    }

    // shows cropped image
    const cropVisibility = () => {
        $('.crop-img').css("display", "block");
        $('.crop-container').css("display", "none");
        $('#drts-mainbtn').show();
    }

    const btnCrop = () => {

        if(crop_trigger === false){
            return ToastDanger('Please set your crop logo by pulling or dragging the dotted lines.');
        }

        let snap = previewCanvasRef.current;
        let cropped_name = makeRandomID(20);
        let croppedlogo = dataURLtoFile(snap.toDataURL(), `${cropped_name}.jpg`);
        // console.log(croppedlogo);
        handleCropLogo(croppedlogo);
        cropIsset(true);
        cropVisibility();
    }
   
    // clear upload when close in edit page
    const clearUpload = () => {
        showCropping(false);
        confirmDialogBox('clear_upload', null, '');
        cropIsset(false);
        $('#drts-mainbtn').show();
    }

    const onDrop = useCallback( async (acceptedFiles) => {
        $('#drts-mainbtn').hide();
        uploadLogo(acceptedFiles);
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {
                        single_dr_template_setting.logo != "" && 
                        <>
                            <div className="float-left d-flex">
                                <label> &nbsp;</label>
                            </div> 
                            <div className="float-right d-flex">
                                <label onClick={clearUpload}> <i className="ft ft-x close-logo-c"></i></label>
                            </div>
                        </>
                    }

                    {
                        (single_dr_template_setting.logo_preview != '') && (pageOrigin === 'create') && 
                        <>
                            <div className="float-left d-flex">
                                <label></label>
                            </div> 
                            <div className="float-right d-flex">
                                <label onClick={clearUpload}> <i className="ft ft-x close-logo-c"></i></label>
                            </div>
                        </>
                    }

                </div>
            </div>

            <div className="f-regular">
                {
                    single_dr_template_setting.logo_preview !== '' ? 
                    <div className="text-center p-2 w-logo">
                        <div className="crop-container">
                            <ReactCrop
                                // minHeight={150}
                                maxHeight={150}
                                src={single_dr_template_setting.logo_preview} 
                                onImageLoaded={onLoad}
                                crop={crop} 
                                onChange={onChange} 
                                onComplete={(c) => setCompletedCrop(c)}
                                imageStyle={{ maxHeight: '300px'}}
                                // onDragEnd={btnCrop}
                            />
                        </div>
                        <div className="crop-img">
                            <canvas ref={previewCanvasRef} style={{ width: Math.round(completedCrop?.width ?? 0),height: Math.round(completedCrop?.height ?? 0)}}/>
                        </div>
                    </div>
                    :
                    <div {...getRootProps()} className="dropzone-line-style-setting-logo">
                        <input {...getInputProps()}/>
                        <center>
                            <i className="ft ft-upload-cloud upload-icon"></i>
                            <div className="">Drag & Drop <br/>Image Here</div>
                            <div className="upload-message-or mb-2">or</div>
                            <div className="btn btn-info btn">Browse Files</div>
                        </center>
                    </div>
                }

                {
                    single_dr_template_setting.logo_preview && 
                    <div className="crop-footer mt-2">
                        <div className="row">
                            <div className="col-12 pb-1">
                                <div className="float-right d-flex">
                                    {
                                        !crop_isset && 
                                        <>
                                            <div {...getRootProps()}> <input {...getInputProps()}/> <div className="btn btn-light text-white btn-sm mr-2">Choose File</div> </div>
                                            <button onClick={btnCrop} type="button" className="btn btn-sm btn-info">Set Crop</button> 
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}


const mapStateToProps = state => ({
    single_dr_template_setting: state.dr_template_setting.single_dr_template_setting,
    crop_trigger: state.dr_template_setting.crop_trigger,
    crop_isset: state.dr_template_setting.crop_isset,
});

export default connect(mapStateToProps, { uploadLogo, showCropping, handleCropLogo, confirmDialogBox, cropIsset, cropTrigger })(DRTSLogoCrop);
