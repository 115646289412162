import * as TYPES from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from 'jquery';
import { SwalWarning, SwalWarningForm } from '../../../Services/_swal.service';
import { PurchaseOrderService } from '../purchase_order/_service.purchase_order';
import { ReceivingService } from '../receiving/_service.receiving';
import { HomeService } from './_service.home';
import { useState } from 'react';

export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });

export const getDashboardData = (filter) => async (dispatch) => {
    try {
        dispatch(setLoading(true));

        if (!filter) filter = 'year-to-date';

        const res = await HomeService.getCounts({ filter });

        // console.log(res.data);
        let payload = {
            sm_boxes: {
                // out_of_stock: res.data.dashboard.out_of_stock,
                // low_stock: res.data.dashboard.low_stock,
                // purchase_orders: res.data.dashboard.purchase_orders,
                // sales_this_week: res.data.dashboard.sales_this_week,
                outOfStock: res.data.dashboard.outOfStock,
                lowStock: res.data.dashboard.lowStock,
                purchaseOrders: res.data.dashboard.purchaseOrders,
                salesThisWeek: res.data.dashboard.salesThisWeek,
                inStock: res.data.dashboard.inStock,
                toReceive: res.data.dashboard.toReceive,
                incoming_items: res.data.dashboard.incoming_items,
                total_sales: res.data.dashboard.total_sales,
                gross_profit: res.data.dashboard.gross_profit,
                net_sales: res.data.dashboard.net_sales,
                inventory_value: res.data.dashboard.inventory_value,
                collectible: res.data.dashboard.collectible,
            },
            graphs: {
                topCustomers: res.data.graphs.topCustomers,
                topProducts: res.data.graphs.topProducts,
                topPurchasedProducts: res.data.graphs.topPurchasedProducts,
                sales: res.data.graphs.sales,
                categories: res.data.graphs.categories,
                purchaseHistory: res.data.graphs.purchaseHistory,
                inventoryLevels: res.data.graphs.inventoryLevels,
                collections: res.data.graphs.collections,
            },
            incoming_purchase_orders:
                res.data.dashboard.incoming_purchase_orders,
            order_history: res.data.dashboard.order_history,
        };

        dispatch({ type: TYPES.DASHBOARD_DATA, payload });

        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
        console.log(error);
    }
};

export const salesLineChart = (salesData) => {
    return {
        labels: salesData.sales.labels,
        datasets: [
            {
                label: 'Cost over time',
                data: [0, 90, 120, 240, 140, 250, 190],
                backgroundColor: 'rgba(255,145,73,.6)',
                borderColor: 'transparent',
                pointBorderColor: '#5175E0',
                pointBackgroundColor: '#FFF',
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                data: salesData.cost.data,
            },
            {
                label: 'Revenue over timet',
                data: [0, 150, 140, 105, 190, 230, 270],
                backgroundColor: 'rgba(209,212,219,1)',
                borderColor: 'transparent',
                pointBorderColor: '#D1D4DB',
                pointBackgroundColor: '#FFF',
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                data: salesData.sales.data,
            },
        ],
    };
};

export const topCustomersBarChart = (topCustomerNames, topCustomerSales) => {
    // Define an array of colors for the top customers
    const topCustomerColors = [
        'rgba(255,145,73,0.8)',
        'rgba(255, 73, 97,0.8)',
        'rgba(209,212,219,0.8)',
    ]; // Add more colors as needed

    return {
        labels: topCustomerNames,
        datasets: [
            {
                label: 'Total Sales',
                backgroundColor: topCustomerColors.slice(
                    0,
                    topCustomerNames.length
                ), // Assign colors based on the number of top customers
                borderColor: 'transparent',
                borderWidth: 1,
                hoverBackgroundColor: topCustomerColors.map((color) =>
                    color.replace(/,[^,]+?\)$/, ',1)')
                ), // Set hover color with increased opacity
                hoverBorderColor: 'transparent',
                data: topCustomerSales,
            },
        ],
    };
};

export function generateRandomColors(numColors) {
    const defaultColors = ['#666EE8', '#FF4961', '#28D094', '#FF9149'];
    const colors = [...defaultColors];

    for (let i = defaultColors.length; i < numColors; i++) {
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
        colors.push(color);
    }

    return colors;
}

export function createPieChart(labels, data) {
    const colors = generateRandomColors(labels.length);
    const labeledData = labels.map((label, index) => {
        return `${label}: ${data[index]}`;
    });
    const datasets = [
        {
            data: data,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
        },
    ];

    return {
        labels: labeledData,
        datasets: datasets,
        options: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 10,
                    generateLabels: function (chart) {
                        var data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            return data.labels.map(function (label, i) {
                                const style = {
                                    color: data.datasets[0].backgroundColor[i],
                                };
                                return {
                                    text: data.labels[i],
                                    fillStyle: style.color,
                                    strokeStyle: style.color,
                                    index: i,
                                };
                            });
                        }
                        return [];
                    },
                },
            },
            maintainAspectRatio: false,
            width: 20,
            height: 20,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return data.labels[tooltipItem.index];
                    },
                },
            },
        },
    };
}

// Function to manage the visibility of cards
export const useCardVisibility = () => {
    const [cardVisibility, setCardVisibility] = useState({
        'Total Sales': true,
        'Gross Profit': true,
        'Net Sales': true,
        'Inventory Value': true,
        'Out Of Stock Items': true,
        'Low Stock Items': true,
        'Incoming Items': true,
        Collectible: true,
    });

    const toggleCard = (cardName) => {
        setCardVisibility((prevState) => ({
            ...prevState,
            [cardName]: !prevState[cardName],
        }));
    };

    return { cardVisibility, toggleCard };
};

// Function to manage the visibility of pie charts
export const useTopCardVisibility = () => {
    const [topCardVisibility, setTopCardVisibility] = useState({
        'Top Item Sales': true,
        'Top Category Sales': true,
        Collections: true,
    });

    const toggleTopCard = (topCardName) => {
        setTopCardVisibility((prevState) => ({
            ...prevState,
            [topCardName]: !prevState[topCardName],
        }));
    };

    return { topCardVisibility, toggleTopCard };
};

// Function to manage the visibility of pie charts
export const useChartVisibility = () => {
    const [chartVisibility, setChartVisibility] = useState({
        'Sales Chart': true,
        'Top Customers': true,
    });

    const toggleChart = (chartName) => {
        setChartVisibility((prevState) => ({
            ...prevState,
            [chartName]: !prevState[chartName],
        }));
    };

    return { chartVisibility, toggleChart };
};
