import React, { useState } from 'react';
import { render } from 'react-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Spinner from '../components/layouts/Spinner';
import $ from 'jquery';

export const SwalSuccess = (message) => {
    Swal.fire('Success', `${message}`, 'success');
};

export const SwalError = (message) => {
    if (Array.isArray(message)) {
        let errors = message.toString();
        Swal.fire('Error', `${errors.split(',').join('<br/>')}`, 'error');
    } else {
        Swal.fire('Error', `${message}`, 'error');
    }
};

export const SwalWarning = (title, message, callback) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.value) {
            callback();
        }
    });
};

export const SwalWarningTransaction = (title, message, callback) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
        input: 'text',
        inputPlaceholder: 'Enter your remarks here',
    }).then((result) => {
        if (result.value) {
            callback(result.value);
        }
    });
};


export const SwalWarningStock = (title, html) => {
    return Swal.fire({
        title: title,
        html: html, 
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#848482',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
        reverseButtons: true,
    });
};



export const SwalWarningHtml = (title, content, callback) => {
    Swal.fire({
        title: title,
        html: content,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.value) {
            callback();
        }
    });
};

export const SwalFormWarning = (title, message, confirm, cleanUp) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.value) {
            confirm();
        }
        cleanUp();
    });
};

export const SwalWarningForm = (title, message, confirm) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        input: 'password',
        inputAttributes: {
            autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Submit',
        // showLoaderOnConfirm: true,
        preConfirm: (code) => {
            if (code == '123456') {
                return true;
            } else {
                Swal.showValidationMessage('Request Passcode failed.');
            }
        },
        allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
        //   console.log(result)
        if (result.isConfirmed) {
            confirm(); // callback
        }
    });
};

// export const SwalWarningReceipt = (title, message, successText, successCallback, confirm) => {
//     Swal.fire({
//         title: title,
//         text: message,
//         icon: 'warning',
//         input: 'password',
//         inputAttributes: {
//             autocapitalize: 'off',
//         },
//         showCancelButton: true,
//         confirmButtonColor: '#6967CE',
//         cancelButtonColor: '#fa626b',
//         confirmButtonText: 'Submit',
//         // showLoaderOnConfirm: true,
//         preConfirm: (code) => {
//             if (code == '123456') {
//                 return true;
//             } else {
//                 Swal.showValidationMessage('Request Passcode failed.');
//             }
//         },
//         allowOutsideClick: () => !Swal.isLoading(),
//     }).then((result) => {
//         if (result.isConfirmed) {
//             successCallback(successText); // callback with success text
//             // confirm(); // callback
//         }
//     });
// };

export const SwallverfiyPass = (resolve) => {
    $('.modal').removeAttr('tabindex');
    Swal.fire({
        title: 'Password Required.',
        text: 'Please provide your login password to verify.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Verify',
        input: 'password',
        inputPlaceholder: 'Enter your password',
        returnFocus: true,
    }).then((result) => {
        if (result.isConfirmed) {
            SwalLoading('Verifying...');
            resolve(result.value);
        }
    });
};

export const SwallnewStock = (item_name, stock, resolve) => {
    $('.modal').removeAttr('tabindex');
    Swal.fire({
        title: `${stock} is the current stock`,
        text: 'Please provide your new stock for ' + item_name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Update',
        input: 'number',
        inputValue: stock,
        inputAttributes: {
            min: 0,
        },
        returnFocus: true,
    }).then((result) => {
        if (result.isConfirmed) {
            const newStock = parseInt(result.value, 10);
            if (newStock >= 0) {
                SwalLoading('Updating...');
                resolve(newStock);
            } else {
                Swal.fire({
                    title: 'Invalid input',
                    text: 'Stock must be a non-negative number',
                    icon: 'error',
                });
            }
        }
    });
};

export const SwalLoading = (title, text, confirm) => {
    Swal.fire({
        title: title,
        text: text,
        icon: 'info',
        showCancelButton: false,
        showConfirmbutton: false,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Submit',
        allowOutsideClick: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        },
    });
};

export const SwalSuccessAutoRemove = (message) => {
    Swal.fire({
        title: message,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
    });
};

/* use in BusinessProfile */
export const SwalWarningAPI = (title, message, callback) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.isConfirmed) {
            // if clicked yes
            SwalLoading('Loading...');
            const resolve = () => {
                callback().then((res) => {
                    if (res.status == 1) {
                        console.log(res.message);
                        Swal.fire('Success', res.message, 'success');
                    } else {
                        Swal.close();
                    }
                });
            };
            resolve();
        }
    });
};

export const SwalUpdatePayment = (title, message, callback) => {
    Swal.fire({
        title: title,
        html: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.isConfirmed) {
            // if clicked yes
            SwalLoading('Loading...');
            const resolve = () => {
                callback().then((res) => {
                    if (res.status == 1) {
                        console.log(res.message);
                        Swal.fire('Success', res.message, 'success');
                    } else {
                        Swal.close();
                    }
                });
            };
            resolve();
        }
    });
};

export const SwalVerifyUpdatePayment = (title, message, callback) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        input: 'password',
        inputAttributes: {
            autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonColor: '#6967CE',
        cancelButtonColor: '#fa626b',
        confirmButtonText: 'Submit',
        preConfirm: (code) => {
            if (code == '123456') {
                return true;
            } else {
                Swal.showValidationMessage('Request Passcode failed.');
            }
        },
        allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
        if (result.isConfirmed) {
            SwalLoading('Loading...');
            const resolve = () => {
                callback().then((res) => {
                    if (res.status == 1) {
                        console.log(res.message);
                        Swal.fire('Success', res.message, 'success');
                    } else {
                        Swal.close();
                    }
                });
            };

            resolve();
        }
    });
};

// product edit form
export const SwalSuccessSupplierRemove = (message) => {
    Swal.fire({
        title: 'Success',
        text: message,
        icon: 'success',
        showConfirmButton: true,
        // timer: 1500,
    });
};

// product edit form
export const SwalSuccessSupplierAdded = (message) => {
    Swal.fire({
        title: 'Success',
        text: message,
        icon: 'success',
        showConfirmButton: true,
        // timer: 1500,
    });
};
