import * as TYPES from './../types';

// state
const initialState = {
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8},
    terms_conditions: [],
    single_term_condition: [],
    search: '',
    loading: false,
    main_page: false,
    edit_page: false,
    delete_modal: false,
    main_url: '/settings/sales',
    create_url: '/settings/terms-conditions/create',
    edit_url: '/settings/terms-conditions/edit',
    toggle_list: {
        position: 'right',
        open: false
    },
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    edit_modal: false,
}

const termConditionReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_TERMS_CONDITIONS:
            return {
                ...state,
                terms_conditions: action.payload.terms_conditions,
                pagination: action.payload.pagination
            }
        case TYPES.GET_SINGLE_TERM_CONDITION:
            return {
                ...state,
                single_term_condition: action.payload
            }
        case TYPES.TERM_CONDITION_INPUT_CHANGE:
            return {
                ...state,
                single_term_condition: { ...state.single_term_condition, [action.payload.key]: action.payload.value }
            }
        case TYPES.SEARCH_INPUT: 
            return {
                ...state,
                search: action.payload
            }
        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPES.SET_PAGE:
            if(action.payload.page == 'main_page')
            {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                }
            }
            else if(action.payload.page == 'edit_page')
            {
                return {
                    ...state,
                    main_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status
                }
            }
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPES.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload
            }
        case TYPES.SET_ROW_CHECKED: 
            return {
                ...state,
                set_row_checked: true
            }
        case TYPES.CLEAR_TERM_CONDITION:
            return {
                ...state,
                single_term_condition: [],
                search: '',
                loading: false,
                main_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/settings/sales',
                create_url: '/settings/terms-conditions/create',
                edit_url: '/settings/terms-conditions/edit',
            }
        case TYPES.TERMS_TOGGLE_LIST:
            return {
                ...state,
                toggle_list: action.payload
            }
        default:
            return state;
            break;
    }

}

export default termConditionReducer;

