import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import CategoryCreateGlobal from '../../category/Category.category.global';

function ProductCategoryModal({ show, onHide }) {

    return (
        <Modal centered dialogClassName="modal-container" show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton> <Modal.Title>Category</Modal.Title> </Modal.Header>
            <Modal.Body>
                <CategoryCreateGlobal pageOrigin="item"/>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
	// category: state.category
});

export default connect(mapStateToProps, {})(ProductCategoryModal);
