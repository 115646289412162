import React, { Fragment, useRef, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MiniSpinner } from '../../layouts/Spinner.js';
// import InvoicePrintContent from './InvoicePrintContent';
import { setPage, goPage, sendEmail, reSendEmail, getPdf } from '../../../redux/actions/invoice/invoice.actions.js';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { globalDropDown } from '../../../redux/actions/helper/helper.actions.js';
import DropDownAttachFileGlobal from '../../layouts/DropDownAttachFile.global.js';
import InvoiceCustomTemplate from '../custom_template/InvoiceCustomTemplate.js';

function InvoiceModalPrint({single_invoice , mini_loading, auth, print_modal_invoice, download_pdf, setPage, onHide, edit_url, goPage, globalDropDown, 
	files_to_remove, new_uploads, subs_setting, sending_email, sendEmail, reSendEmail, getPdf}) {

	const componentRef = useRef();
	const status_lbl = <span className={`lbl-status mr-1 lbl-${single_invoice.status}`}>{single_invoice.status}</span>;
	
	// previous button
	const previousBtn = (
		<> { single_invoice.latest_invoice_no != single_invoice.invoice_no && <div className="mdl-arrow-left arr-link"><Link onClick={() => goPage('prev')}><i className="la la-angle-left mdl-arrow"></i></Link></div>}</>
	)
	
	// next button
	const nextBtn = (
		<> { single_invoice.oldest_invoice_no != single_invoice.invoice_no && <div className="mdl-arrow-right arr-link"><Link onClick={() => goPage('next')}><i className="la la-angle-right mdl-arrow"></i></Link></div> } </>
	)
	

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,	
	});
		
	let file_counter = ((single_invoice.uploads ? single_invoice.uploads.length : 0) + (new_uploads.length)) - (files_to_remove.length);

	return (
		<Modal centered dialogClassName="modal-wdth" show={print_modal_invoice} size="lg" onHide={() => setPage('print_modal_invoice', null, false)} animation={true}>
		    <Modal.Header className="inv-bg-header">
	          {/* <Modal.Title> Sales Invoice</Modal.Title> */}
			  	<div className="col-4 pl-0">
					<div className="modal-status">
						{status_lbl}
					</div>
				</div>
			  	<div className="col-8 pr-0">
					<div className="float-right">
						<Link to={`${edit_url}/${single_invoice._id}`} className="btn btn-circle btn-inv btn-grey mr-1 icon-edit-c"><i className="ft ft-edit cursor-pointer"></i></Link>
						<button onClick={e => globalDropDown(e, 'show')} className="btn btn-circle btn-inv btn-grey mr-1 icon-paper-c relative"><i className="ft ft-paperclip cursor-pointer"></i>{file_counter > 0 ? <span className="badge badge-pill badge-sm badge-danger badge-up badge-file">{file_counter > 9 ? '9+' : file_counter}</span> : <></>}</button>
						<DropDownAttachFileGlobal origin="invoice" origin_data={single_invoice} new_uploads={new_uploads} files_to_remove={files_to_remove}/>
						{
							single_invoice.email_sent ? 
							<button className="btn btn-circle btn-inv btn-grey mr-1" onClick={(e) => reSendEmail(e, single_invoice._id)}><i className={sending_email ? "icon-loading-custom icon-20 cursor-pointer" : "ft-custom-mail-check cursor-pointer"}></i></button>
							:
							<button className="btn btn-circle btn-inv btn-grey mr-1" onClick={(e) => sendEmail(e, single_invoice._id)}><i className={sending_email ? "icon-loading-custom icon-20 cursor-pointer" : "ft-custom-mail-x cursor-pointer"}></i></button>
						}
						<button className="btn btn-circle btn-inv btn-grey mr-1 icon-pdf-c" onClick={(e) => getPdf(e, single_invoice._id, single_invoice.invoice_no)}><i className={download_pdf ? "icon-loading-custom icon-20 cursor-pointer" : "la la-file-pdf-o cursor-pointer"}></i></button>
						<button onClick={handlePrint} className="btn btn-circle btn-inv btn-grey mr-1"><i className="ft ft-printer cursor-pointer"></i></button>
						<button onClick={() => setPage('print_modal_invoice', null, false)} className="btn btn-circle btn-inv m-close-btn cursor-pointer"><i className="ft ft-x cursor-pointer"></i></button>
					</div>
                </div>
	        </Modal.Header>
		    <Modal.Body className="unset-scroll p-0 m-0">
				<div className="sd-border">
					{previousBtn}
						{ mini_loading ? <div className="my-5"><MiniSpinner/></div> : <InvoiceCustomTemplate componentRef={componentRef}/> }
					{nextBtn}
				</div>
			</Modal.Body>
		</Modal>
	  )
}

const mapStateToProps = state => ({
	auth: state.auth,
	single_invoice: state.invoice.single_invoice,
	print_modal_invoice: state.invoice.print_modal_invoice,
	mini_loading: state.invoice.mini_loading,
	new_uploads: state.invoice.new_uploads,
	files_to_remove: state.invoice.files_to_remove,
	subs_setting: state.invoice.subs_setting,
	sending_email : state.invoice.sending_email,
	download_pdf : state.invoice.download_pdf
	
})

export default connect(mapStateToProps, { setPage, goPage, globalDropDown, sendEmail, reSendEmail, getPdf})(InvoiceModalPrint)


	// <>
					// 	<InvoicePrintContent si={single_invoice} auth={auth} print="0" print_rows="5" />
					// 	<div style={{'display':'none'}}><InvoicePrintContent si={single_invoice} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
					// </>