import React, { useState } from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import POTemplateSetting from './PurchaseOrder/POTemplateSetting';
import ItemReceiptTS from './ItemReceipt/ItemReceiptTS';
import QuotationTS from './Quotation/QuotationTS';
import SalesInvoiceTS from './SalesInvoice/SalesInvoiceTS';
import DeliveryTS from './Delivery/DeliveryTS';
import CollectionReceiptTS from './CollectionReceipt/CollectionReceiptTS';

function TemplateSetting () {

    const [redirect, setRedirect] = useState({ status: false, url: '/settings/template/create-global' })
    const [selectedTab,SetSelectedTab] = useState('Purchase Order');
    const [tabs, setTabs] = useState([
        { key: 'Purchase Order', class : 'nav-link inv-top-nav inv-top-nav-active mr-30' },
        { key: 'Quotation', class : 'nav-link inv-top-nav mr-30' },
        { key: 'Sales Invoice', class : 'nav-link inv-top-nav mr-30' },
        { key: 'Item Receipt', class : 'nav-link inv-top-nav mr-30' },
        { key: 'Delivery Receipt', class : 'nav-link inv-top-nav mr-30' },
        { key: 'Collection Receipt', class : 'nav-link inv-top-nav mr-30' },
        // { key: 'Global Template', class : 'btn btn-sm btn-primary ml-auto' },
    ]);

    function handleChangeTab(tab)
    {
      SetSelectedTab(tab);
      tabs.map((list) => {
        list.class = list.key == tab ? "nav-link inv-top-nav inv-top-nav-active mr-30": list.key !== 'Global Template' ? "nav-link inv-top-nav mr-30" : "btn btn-sm btn-primary ml-auto";
        return list;
      });
      setTabs(tabs);
    }

    if(redirect.status){
        return <Redirect to={redirect.url}/>;
    }


    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1.5">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/settings/template" className="text-white f-regular">Template Setting</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                       <div className="">
                                            <nav class="nav f-regular">
                                                {
                                                    tabs.map(item => {
                                                        return <Link onClick={() => handleChangeTab(item.key) } className={item.class}>{item.key}</Link> 
                                                    }) 
                                                }

                                                { 
                                                    // tabs.map(item => {
                                                    //     if(item.key !== 'Global Template'){
                                                    //         return <Link onClick={() => handleChangeTab(item.key) } className={item.class}>{item.key}</Link> 
                                                    //     }else {
                                                    //         return <button onClick={() => setRedirect({ ...redirect, status: true })} className={item.class}>Create Template</button>
                                                    //     }
                                                    // }) 
                                                }
                                            </nav>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="row f-regular"> 
                        <div className="col-sm-12">
                            {(() => {
                                switch(selectedTab){
                                    case 'Purchase Order':
                                        return <POTemplateSetting/>; 
                                    case 'Quotation':
                                        return <QuotationTS/>;
                                    case 'Sales Invoice':
                                        return <SalesInvoiceTS/>;
                                    case 'Item Receipt':
                                        return <ItemReceiptTS/>;
                                    case 'Delivery Receipt':
                                        return <DeliveryTS/>;
                                    case 'Collection Receipt':
                                        return <CollectionReceiptTS/>;
                                    default: 
                                        return null;
                                }
                            })()}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    setting_app: state.setting_app
});

export default connect(mapStateToProps, { })(TemplateSetting);
