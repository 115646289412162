
import { SET_LOADING, SET_MODAL, SEARCH_INPUT, SET_MINI_LOADING, GET_USER_LOGS, GET_SINGLE_USER_LOG, CLEAR_USER_LOG, SORT_BY_COLUMN } from '../types';

const initialState = {
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8},
    user_logs: [],
    single_user_log: {
        log_event: '',
        log_table: '',
        log_old_values: [],
        log_new_values: [],
        log_ip_address: '',              
        created_at: '',
        new_items: [],
        old_items: [],
        user_details: {}
    },
    search: '',
    loading: false,
    mini_loading: false,
    user_log_modal: false,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
}

const userLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload
            }
        case GET_USER_LOGS:
            return {
                ...state,
                user_logs: action.payload.user_logs,
                pagination: action.payload.pagination
            }
        case GET_SINGLE_USER_LOG: 
            return {
                ...state,
                single_user_log: action.payload
            }
        case SET_MODAL:
            if(action.payload.status == false)
            {
                return {
                    ...state,
                    [action.payload.modal]: action.payload.status,
                    single_user_log: {
                        log_event: '',
                        log_table: '',
                        log_old_values: [],
                        log_new_values: [],
                        log_ip_address: '',              
                        created_at: '',
                        new_items: [],
                        old_items: [],
                        user_details: {}
                    },
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.modal]: action.payload.status
                }
            }
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload
            }
        case CLEAR_USER_LOG:
            return {
                ...state,
                single_user_log: {
                    log_event: '',
                    log_table: '',
                    log_old_values: [],
                    log_new_values: [],
                    log_ip_address: '',              
                    created_at: '',
                    new_items: [],
                    old_items: [],
                    user_details: {}
                },
                search: '',
                user_log_modal: false,
                loading: false,
                mini_loading: false
            }
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        default:
            return state;
    }
}

export default userLogReducer;











