import http from '../../../Services/api.laravel.http';

export const CategoryService = {
 	getSingleCategory: (id) => http.get('/admin/category/' + id),
  	ArchiveCategory: (formParams) => http.post('/admin/category/archives/list', formParams),
 	categoryPost: (formParams) => http.post('/admin/category', formParams),
  	paginateWithSearchCategories: (formParams) => http.post('/admin/category/search', formParams),
 	updateCategory: (formParams, id) => http.put('/admin/category/' + id, formParams),
 	getParentCategory: (formParams) => http.post('/admin/category/parent-category', formParams)
}
