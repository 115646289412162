import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Spinner, { MiniSpinner } from '../layouts/Spinner';
import Select from 'react-select';
import ProductAttributes from './Product.globals.attribute';
import PricingStocks from './Product.globals.stocks';
import ProductUpload from './Product.globals.upload';
import ProductVolume from './Product.globals.volume';
import ProductCost from './Product.globals.cost';
import { connect } from 'react-redux';
import {
    handleInputChange,
    handleSelect,
    handleSelectUpdateAttributeList,
    handleAttrCheckbox,
    handleSubmit,
    skuSkipValidation,
    getSingleProduct,
    getSelectAll,
    handleSelectSupplier,
    updateOnChangeItemTags,
    setModal,
    checkBarcodeValidation,
    editBarcode,
    isSalePrice,
    updateItemTags,
} from './../../redux/actions/product/product.actions';
import { Redirect } from 'react-router-dom';
import ProductTagModal from './modals/Product.tag.modal';
import CreatableSelect from 'react-select/creatable';
import ProductBrandModal from './modals/Product.brand.modal';
import ProductCategoryModal from './modals/Product.category.modal';
import ProductUnitModal from './modals/Product.unit.modal';
import ProductSupplierModal from './modals/Product.supplier.modal';
import ProductAttributeModal from './modals/Product.attribute.modal';
import ReactTooltip from 'react-tooltip';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';

function ProductEdit({
    product: {
        loading_tag,
        mini_loading,
        isSearchable,
        brands,
        categories,
        product_units,
        _item_tags_options,
        single_product,
        form_disable,
        main_page,
        main_url,
        item_tags_options,
        tag_modal,
        brand_modal,
        category_modal,
        unit_modal,
        supplier_modal,
        attribute_modal,
        barcode_disable,
    },
    handleSubmit,
    handleInputChange,
    handleSelect,
    skuSkipValidation,
    getSelectAll,
    getSingleProduct,
    handleSelectSupplier,
    updateOnChangeItemTags,
    setModal,
    checkBarcodeValidation,
    editBarcode,
    isSalePrice,
    match,
    updateItemTags,
    block_route,
    blockRoute,
}) {
    let history = useHistory();

    useEffect(() => {
        getSingleProduct(match.params.id);
        getSelectAll();
        blockRoute(false);
    }, []);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01');
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    if (main_page) return <Redirect to={main_url} />;

    return (
        <BeforeUnloadComponent
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Sales
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <a
                                                href={main_url}
                                                className="text-white"
                                            >
                                                Products
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-white">
                                                Edit
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header mb-0 pb-0">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                {mini_loading ? (
                                                    <Spinner />
                                                ) : (
                                                    <form
                                                        id="addProduct"
                                                        onSubmit={(e) =>
                                                            handleSubmit(
                                                                'update',
                                                                e
                                                            )
                                                        }
                                                        encType="multipart/form-data"
                                                    >
                                                        <h4 className="form-section">
                                                            <i className="ft-clipboard"></i>{' '}
                                                            Details{' '}
                                                        </h4>
                                                        <section className="row f-regular">
                                                            <div className="col-sm-6">
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        SKU{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="col-md-7 col-10">
                                                                        <input
                                                                            type="text"
                                                                            id="sku"
                                                                            name="sku"
                                                                            onBlur={
                                                                                skuSkipValidation
                                                                            }
                                                                            className="form-control"
                                                                            placeholder="SKU"
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_product.sku
                                                                            }
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-2 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Product
                                                                        Name{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="col-md-7 col-10">
                                                                        <input
                                                                            type="text"
                                                                            id="product_name"
                                                                            name="product_name"
                                                                            className="form-control"
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_product.product_name
                                                                            }
                                                                            placeholder="Product Name"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-2 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Description
                                                                    </label>
                                                                    <div className="col-md-7 col-10">
                                                                        <textarea
                                                                            id="description"
                                                                            name="description"
                                                                            rows="2"
                                                                            className="form-control"
                                                                            placeholder="Description"
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_product.description
                                                                            }
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-2 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Custom
                                                                        Tags:{' '}
                                                                    </label>
                                                                    <div className="col-md-6 col-9">
                                                                        {/* <CreatableSelect  isMulti onChange={updateOnChangeItemTags} onCreateOption={updateItemTags} isLoading={loading_tag} value={single_product.item_tags} defaultValue={single_product.item_tags} options={item_tags_options} placeholder="Select or Create New"/> */}
                                                                        <CreatableSelect
                                                                            isMulti
                                                                            onChange={
                                                                                updateOnChangeItemTags
                                                                            }
                                                                            isLoading={
                                                                                loading_tag
                                                                            }
                                                                            value={
                                                                                single_product.item_tags
                                                                            }
                                                                            defaultValue={
                                                                                single_product.item_tags
                                                                            }
                                                                            options={
                                                                                item_tags_options
                                                                            }
                                                                            placeholder="Select or Create New"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div
                                                                            className="a-tagicon icon-s"
                                                                            onClick={() =>
                                                                                setModal(
                                                                                    'tag_modal',
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="ft ft-edit text-primary"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Barcode
                                                                    </label>
                                                                    <div className="col-md-7 col-10">
                                                                        <div class="input-group mb-1">
                                                                            <input
                                                                                type="text"
                                                                                id="barcode"
                                                                                value={
                                                                                    single_product.barcode
                                                                                }
                                                                                name="barcode"
                                                                                className="form-control"
                                                                                disabled={
                                                                                    barcode_disable
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                onChange={
                                                                                    handleInputChange
                                                                                }
                                                                                placeholder="Barcode"
                                                                                required
                                                                            />
                                                                            <div class="input-group-append">
                                                                                {barcode_disable ? (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            editBarcode()
                                                                                        }
                                                                                        className="btn btn-sm btn-primary"
                                                                                        type="button"
                                                                                    >
                                                                                        <i className="ft ft-edit"></i>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            checkBarcodeValidation()
                                                                                        }
                                                                                        className="btn btn-sm btn-primary"
                                                                                        type="button"
                                                                                    >
                                                                                        <i className="ft ft-copy"></i>{' '}
                                                                                        COPY
                                                                                        SKU
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-2 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Brand{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="col-md-6 col-9">
                                                                        <Select
                                                                            placeholder="Select Brand..."
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            onChange={(
                                                                                select
                                                                            ) =>
                                                                                handleSelect(
                                                                                    select,
                                                                                    'brand_id'
                                                                                )
                                                                            }
                                                                            options={
                                                                                brands
                                                                            }
                                                                            value={
                                                                                single_product.brand_id_default
                                                                            }
                                                                            name="brand_id"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-left pl-0 col-1 col-md-1">
                                                                        <Show when="inv.products.brands.create">
                                                                            <div
                                                                                className="a-tagicon icon-s"
                                                                                onClick={() =>
                                                                                    setModal(
                                                                                        'brand_modal',
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                            </div>
                                                                        </Show>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Category{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="col-md-6 col-9">
                                                                        <Select
                                                                            placeholder="Select Category..."
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            onChange={(
                                                                                select
                                                                            ) =>
                                                                                handleSelect(
                                                                                    select,
                                                                                    'category_id'
                                                                                )
                                                                            }
                                                                            options={
                                                                                categories
                                                                            }
                                                                            value={
                                                                                single_product.category_id_default
                                                                            }
                                                                            name="category_id"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-left pl-0 col-1 col-md-1">
                                                                        <Show when="inv.products.categories.create">
                                                                            <div
                                                                                className="a-tagicon icon-s"
                                                                                onClick={() =>
                                                                                    setModal(
                                                                                        'category_modal',
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                            </div>
                                                                        </Show>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-md-4 col-12 label-control">
                                                                        Unit{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="col-md-6 col-9">
                                                                        <Select
                                                                            placeholder="Select Unit..."
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            onChange={(
                                                                                select
                                                                            ) =>
                                                                                handleSelect(
                                                                                    select,
                                                                                    'product_unit_id'
                                                                                )
                                                                            }
                                                                            options={
                                                                                product_units
                                                                            }
                                                                            value={
                                                                                single_product.product_unit_id_default
                                                                            }
                                                                            name="product_unit_id"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-left pl-0 col-1 col-md-1">
                                                                        <Show when="inv.setting.product-unit.create">
                                                                            <div
                                                                                className="a-tagicon icon-s"
                                                                                onClick={() =>
                                                                                    setModal(
                                                                                        'unit_modal',
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                            </div>
                                                                        </Show>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>

                                                        <ProductCost origin="edit" />
                                                        <ProductAttributes />
                                                        <PricingStocks />
                                                        <ProductVolume />
                                                        <ProductUpload />
                                                        <div className="row justify-content-end">
                                                            <div className="mr-2">
                                                                <div className="form-group">
                                                                    {form_disable ? (
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary btn-save disabled"
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary btn-save"
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <ProductTagModal
                    show={tag_modal}
                    onHide={() => setModal('tag_modal', false)}
                    _item_tags_options={_item_tags_options}
                />
                <ProductBrandModal
                    show={brand_modal}
                    onHide={() => setModal('brand_modal', false)}
                />
                <ProductCategoryModal
                    show={category_modal}
                    onHide={() => setModal('category_modal', false)}
                />
                <ProductUnitModal
                    show={unit_modal}
                    onHide={() => setModal('unit_modal', false)}
                />
                <ProductSupplierModal
                    show={supplier_modal}
                    onHide={() => setModal('supplier_modal', false)}
                />
                <ProductAttributeModal
                    show={attribute_modal}
                    onHide={() => setModal('attribute_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    single_product: state.product.single_product,
    product: state.product,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    handleSubmit,
    handleInputChange,
    handleSelect,
    handleSelectUpdateAttributeList,
    handleAttrCheckbox,
    skuSkipValidation,
    getSingleProduct,
    getSelectAll,
    handleSelectSupplier,
    updateOnChangeItemTags,
    setModal,
    checkBarcodeValidation,
    editBarcode,
    isSalePrice,
    updateItemTags,
    blockRoute,
})(ProductEdit);
