import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';

function ScanBatch({ show, search, handleInputChange, scanBatch }) {
    //** INPUT focus */
    const inputRef = useRef(null);

    useEffect(() => {
        if (show && inputRef.current) {
            inputRef.current.focus();
        }
    }, [show]);

    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>Scan Batch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={(e) => scanBatch(e, search)}
                    className="pick-form"
                >
                    <div className="input-group">
                        <input
                            type="text"
                            id="search"
                            value={search}
                            onChange={handleInputChange}
                            name="search"
                            className="form-control pick-input"
                            placeholder="Enter Batch #"
                            required
                            ref={inputRef}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary pick-btn"
                                type="submit"
                            >
                                <i className="ft ft-search"></i> Search
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default ScanBatch;
