import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import CustomerCreateGlobal from '../../customer/Customer.create.global';

function SalesOrderCustomerModal({ show, onHide }) {

    return (
        <Modal centered dialogClassName="modal-container-style" show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton dialogClassName="p-0">
	          <Modal.Title> Create Customer</Modal.Title>
	        </Modal.Header>
            <Modal.Body>
                <CustomerCreateGlobal pageOrigin="sales_order"/>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
    //
})

export default connect(mapStateToProps, { })(SalesOrderCustomerModal);
