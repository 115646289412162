import http from "../../../Services/api.laravel.http";

export const CustomerService = {
  // getBrands: () => http.get('/admin/brand'),
  getSingleCustomer: (id) => http.get("/admin/customer/" + id),
  ArchiveCustomer: (formParams) =>
    http.post("/admin/customer/archives", formParams),
  customerPost: (formParams) => http.post("/admin/customer", formParams),
  paginateWithSearchCustomers: (formParams) =>
    http.post("/admin/customer/search", formParams),
  updateSingleCustomer: (formParams, id) =>
    http.put("/admin/customer/" + id, formParams),
  // updateSingleCustomer: (formParams) => http.post('/admin/customer/update', formParams),
  // uploadFiles: (formParams) => http.post('/admin/customer/upload/files', formParams),
};
