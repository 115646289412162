import React, { useState } from 'react';
import { connect } from 'react-redux';
import {sendResetReqeust, handleInputChange, handleReset, setToken} from "../../redux/actions/auth/auth.actions";
import '../../assets/login.css';

function ResetPassword({auth : {inputs, status, loading}, handleInputChange, match, handleReset, setToken}) {

  document.body.classList.remove('vertical-layout');
  document.body.classList.add("bg-gradient-x-purple-blue");
  useState(() =>{
    setToken(match.params.token)
  }, [])

  return (
    <div className="app-content content">
        <div className="bg-auth bg-gradient-x-purple-blue"></div>
      <div className="content-wrapper">
          <div className="content-wrapper-before"></div>
          <div className="content-header row">
          </div>
          <div className="content-body">
              <form className="flexbox-container" onSubmit={(e) => handleReset(e)}>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-10 box-shadow-2 p-0 row b-ltb-10p b-rtb-10p">
                          <div className="col-md-6 b-ltb-10p bg-white">
                            <div className="p-2m">
                                <img src="/app-assets/images/logo/logo-mini.png" className="auth-logo" />
                                <h1>Enter your new Password</h1>
                                <div className="form-group position-relative has-icon-lef mt-2m mb-2m">
                                    <input type="password" className=" round login-form" name="password" onChange={(e) => handleInputChange(e)} placeholder="New Password" value={inputs.password} autoComplete="new-password" required></input>
                                    <div className="form-control-position r-unset">
                                        <i className="ft-lock"></i>
                                    </div>
                                </div>
                                
                                <div className="form-group position-relative has-icon-lef mt-2m mb-2m">
                                    <input type="password" className=" round login-form" name="confirm_password" onChange={(e) => handleInputChange(e)} placeholder="Confirm Password" value={inputs.confirm_password} autoComplete="new-password" required></input>
                                    <div className="form-control-position r-unset">
                                        <i className="ft-lock"></i>
                                    </div>
                                </div>
                                
                                <div className="form-group text-center">
                                    <button type="submit" className="btn-login btn-lg round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1 text-white" disabled={loading}  >{loading ? 'Loading...' : 'Reset Password'}</button>
                                </div>
                                {
                                  status.result ? 
                                  <div className="form-group">
                                      <div className="col-md-12 text-center">
                                          <span className={status.success ? "color-green" : "color-red"}><i>{status.message}</i></span>
                                      </div>
                                  </div>
                                  :
                                  <span></span>
                                }
                                <div className="form-group text-center">
                                    <a href="/login">Return to Login</a>
                                </div>
                                <div className="form-group text-center mt-2">
                                    <p> Powered by <a href='https://bluehive.com.ph/' target='_blank'>Bluehive Systems</a></p>
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6 b-rtb-10p bg-theme">
                              <div className="p-2m">
                                <div className=" border-0">
                                    <div className="text-center mt-3">  
                                      <img src="/app-assets/images/logo/blitz_237x237_logo_white.png" style={{'max-width': '100%', 'width': '100px'}} alt="branding logo"></img>
                                    </div>
                                    <div className="text-center mt-2">
                                      <img src="/app-assets/images/logo/blitz_logo_text_white.png" style={{'max-width': '100%', 'width': '350px'}} alt="branding logo"></img>
                                    </div>
                                    <div className="font-large-1 text-center font-weight-bold text-primary">
                                    </div>
                                </div>
                              </div>
                          </div>
                          
                      </div>
                  </div>
              </form>

          </div>
      </div>
    </div>  
  );
}
const mapStateToProps = state => ({
  auth: state.auth
});
 
 
export default connect(mapStateToProps, { sendResetReqeust, handleInputChange, handleReset, setToken})(ResetPassword);