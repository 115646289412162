import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../../layouts/Spinner";
import Pagination from "react-js-pagination";
import LogViewModal from './Log.modal.view';
import { connect } from 'react-redux';
import { handleSearchInputMUI, setModal, getLogs, sortTableByColumn } from '../../../../redux/actions/log/log.actions';
import { formattedDate } from "../../../../Utils/Common";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {  Show, useAccess } from "react-redux-permission";
import ReactTooltip from 'react-tooltip';
import { useDebounce } from 'use-debounce';
import { updateMUISetting } from "../../../../redux/actions/helper/helper.actions";

function Logs ({ log: { logs, loading, search, pagination, view_modal, sort_order_name, sort_order_direction }, setModal, handleSearchInputMUI, getLogs, sortTableByColumn, updateMUISetting }) {

    useEffect(() => {
        getLogs();
    }, [])

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
      if (debouncedSearch) {
        if(oldDebounced !== debouncedSearch[0]){
          if(debouncedSearch[0].length !== 1){
              setOldDebounced(debouncedSearch[0]);
              getLogs(1);
          }
        }
      } 
    },[debouncedSearch]);
    // END DEVOUNCE SEARCH


    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MUIDataTableBodyCell: {}
        }
    })

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'log_event', label: 'Log Event', options: { display: true,
                customBodyRender: (value) => {
                    return (
                        <span> {value.toUpperCase()} </span>
                    )
                }
            } 
        }, 
        {name: 'log_table', label: 'Table', options: { display: true } },
        {name: 'log_ip_address', label: 'IP Address', options: { display: true }},
        {name: 'user_details', label: 'Created By', options: { display: true,
                customBodyRender: (value) => {
                    return (
                        <span> {value ? value.admin_name : '' } </span>
                    )
                }
            }
        },
        {name: 'created_at', label: 'Created At', options: { display: true }},
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20,50,100],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        elevation: 2,
        selectableRows: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `logs_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getLogs(null, numberOfRows)
            updateMUISetting('rows', 'log', numberOfRows)
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getLogs(tableState.page + 1, pagination.itemsCountPerPage);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'log', null, tableState.columns);
                    break;    
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => { },
        onRowClick: (rowData, rowMeta) => {
            setModal('view_modal', rowData[0])
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search" onChange={handleSearchInputMUI} className="custom-search-input"/>
                </div>
            )
        }
    };

    const _columns = updateMUISetting('view-columns', 'log', null, columns);

    return (
        <section id="basic-examples">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <ReactTooltip  effect="solid" event="click"/>
                            <h4 className="card-title">Developer Logs <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                            <a className="heading-elements-toggle"> <i className="la la-ellipsis-v font-medium-3"></i></a>
                            <div className="heading-elements">
                                <ul className="list-inline mb-0"></ul>
                            </div>
                        </div>
                        <div className="card-content collapse show">
                            <div className="card-body">
                                <section className="row">
                                    <div className="col-sm-12">
                                        <Show when="inv.setting.system.developer-logs"> 
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <MUIDataTable data={logs} columns={_columns} options={options}/>
                                            </MuiThemeProvider> 
                                        </Show>
                                    </div>
                                </section>   
                                <LogViewModal show={view_modal} onHide={() => setModal('view_modal', null, false)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    log: state.log
})

export default connect(mapStateToProps, { handleSearchInputMUI, setModal, getLogs, sortTableByColumn, updateMUISetting })(Logs);
