import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../../../Utils/Common';
import Select from 'react-select';
import {
    CRCreatePaymentInput,
    collectionPayment,
} from '../../../redux/actions/collection_receipt/collection_receipt.action';
import Spinner from '../../layouts/Spinner';
import {handleHover} from '../../../redux/actions/helper/helper.actions';
import { fileExtensionIcon } from '../../../Utils/Common';

function PaymentModal({ show, onHide, loading, selectedPayment,handleHover }) {

    // console.log('selectedPayment', selectedPayment);
    
    return (
        <Modal
            centered
            dialogClassName="modal-container"
            show={show}
            onHide={onHide}
            size="md"
            animation={true}
            style={{ zIndex: '9999' }}
        >
            <Modal.Header className='toggle-list-header cr-side-link' closeButton>
                <Modal.Title className='mx-2' style={{color:"white"}}>Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Spinner />
                ) : (
                    <div className="mx-2 ">
                        <div className="d-flex mb-1">
                            <div className="mr-auto">
                                <div className='mb-2'>
                                <div className="mr-1">
                                        Payment Date
                                    </div>{' '}
                                    <div className="cr-h-label" style={{fontSize: '20px'}}>
                                        {moment(
                                            selectedPayment?.created_at
                                        ).format('MMMM DD, YYYY')}
                                    </div>
                                  
                                </div>
                                <div>
                                    <div className="mr-1">Payment Method</div>{' '}
                                    <div className="cr-h-label" style={{fontSize: '20px'}}>
                                        {selectedPayment?.payment_method.label}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="">
                                <div>
                                    <div className="mr-1">Amount</div>
                                    <div className="cr-h-label">
                                        ₱{' '}
                                        {numberFormat(
                                            selectedPayment?.amount || 0
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className="mr-1">Balance</div>{' '}
                                    <div className="cr-h-label cr-f-bal">
                                        ₱{' '}
                                        {numberFormat(
                                            selectedPayment?.balance || 0
                                        )}
                                    </div>
                                </div>
                            </div> */}
                        
                        <div className='mx-auto'>
                        {selectedPayment?.payment_method.value === 'cash' || selectedPayment?.payment_method.value === 'cod'? (
                                <div>
                                    <div className="mr-1">Remarks</div>{' '}
                                    <div className="cr-h-label">
                                        {selectedPayment?.remarks}
                                    </div>
                                </div>
                        ) : selectedPayment?.payment_method.value ===
                          'online' ? (
                            <>
                             <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">
                                            Reference No.
                                        </div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.ref_no}
                                        </div>
                                    </div>
                                </div>
                                <div  className='mb-1'>
                                    <div>
                                        <div className="mr-1">Method</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.method}
                                        </div>
                                    </div>
                                </div>
                                <div  className='mb-1'>
                                    <div>
                                        <div className="mr-1">Remarks</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.remarks}
                                        </div>
                                    </div>
                                </div>
                            </>
                               
                        ) : selectedPayment?.payment_method.value ===
                          'check' ? (
                            <>
                             <div  className='mb-1'>
                                    <div>
                                        <div className="mr-1">Check No.</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.check_no}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">Bank</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.bank}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">Check Date</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.check_date}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">Account</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.account}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : selectedPayment?.payment_method.value ===
                          'bank transfer' ? (
                            <>
                             <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">
                                            Reference No.
                                        </div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.ref_no}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">Bank</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.bank}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-1'>
                                    <div>
                                        <div className="mr-1">Account</div>{' '}
                                        <div className="cr-h-label">
                                            {selectedPayment?.account}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                            {selectedPayment?.uploads != null ? (
                                <>
                                <div className="mr-1 mt-2">File uploaded</div>{' '}
                                <div onMouseEnter={handleHover} title={selectedPayment?.uploads.origin_name?.slice(0, 20) + (selectedPayment?.uploads.origin_name?.length > 20 ? "..." : "")} onMouseLeave={() => handleHover('out')} className="d-flex justify-content-between align-items-center">
                                    <div className="min-width-300">
                                        <span className="file-icon-print"><img src={fileExtensionIcon(selectedPayment?.uploads.origin_name)} alt="file-types"/></span>
                                        <a href={selectedPayment?.uploads.file_path} download target="_blank"> {selectedPayment?.uploads.origin_name?.slice(0, 20) + (selectedPayment?.uploads.origin_name?.length > 20 ? "..." : "")}
                                        </a>
                                    </div>
                                </div>
                                </>
                               
                            ) : (
                                <>  </>
                            )}


                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className='toggle-list-header '>
                <div className='d-flex a' style={{marginLeft: 'auto'}}>
                    <div className="mr-1 cr-h-label align-self-center" >Amount:</div>
                    <div className="cr-h-label" style={{fontSize:'18px', color: '#3B3C36'}}>
                        ₱{' '}
                        {numberFormat(
                            selectedPayment?.amount || 0
                        )}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    cr: state.collection_receipt,
    selectedPayment: state.collection_receipt.selectedPayment,
});

export default connect(mapStateToProps, {
    CRCreatePaymentInput,
    collectionPayment,
    handleHover
})(PaymentModal);
