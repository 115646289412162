import React from 'react';
import { connect } from 'react-redux';
import BlitzDefaultContent from '../modals/QuotationPrintContent';
import MotoTruck from './MototruckTemplate';
import { getSubSetting } from '../../../Utils/Common';

function QuotationCustomTemplate ({ single_quotation, auth, componentRef }) {

    // console.log("Si", single_quotation)

    const subs_setting = getSubSetting()
    const template_label = single_quotation.template_setting ? single_quotation.template_setting.template_label : 'none';

    return (
        <>
            {(() => {
                 switch (template_label) {
                    case 'blitz_default':
                        return  <>
                                    <BlitzDefaultContent subs_setting={subs_setting} qu={single_quotation} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <BlitzDefaultContent subs_setting={subs_setting} qu={single_quotation} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'hayabusa':
                        return  <>
                                    <BlitzDefaultContent subs_setting={subs_setting} qu={single_quotation} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <BlitzDefaultContent subs_setting={subs_setting} qu={single_quotation} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'mototruck':
                        return  <>
                                <MotoTruck subs_setting={subs_setting} qu={single_quotation} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                <MotoTruck subs_setting={subs_setting} qu={single_quotation} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                            </>
                    default:
                        return <><h4 className="text-center my-5">-- There's No Data To Show--</h4></>;
                }
            })()}
        </>
    )
}


const mapStateToProps = state => ({
	auth: state.auth,
	single_quotation: state.quotation.single_quotation,
})

export default connect(mapStateToProps, {  })(QuotationCustomTemplate);
