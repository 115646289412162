import * as TYPE from '../types';

// state
const initialState = {
   block_route: false,
   isHover: false,
   hoverIndex: null,
   set_loading: false,
   is_attachment: false,
   progress: 0,
   muiPage: 1,
}

const helperReducer = (state = initialState, action) => {

    switch (action.type) {
        case TYPE.SET_DYNAMIC_LOADING: 
            return {
                ...state,
                [action.payload.key]: action.payload.status
            }
        case TYPE.BLOCK_ROUTE:
            return {
                ...state,
                block_route: action.payload
            }
        case TYPE.HANDLE_HOVER:
            return {
                ...state,
                isHover: action.payload.status,
                hoverIndex: action.payload.hoverIndex
            }   
        case TYPE.NEW_ATTACHMENT:
            return {
                ...state,
                is_attachment: action.payload
            }
        case TYPE.SET_PROGRESS:
            return {
                progress: action.payload
            }
        case TYPE.SET_MUI_PAGE:
            return {
                ...state,
                muiPage: action.payload
            }
        default:
           return state;
    }
}

export default helperReducer;

