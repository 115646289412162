import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';

export class BlitzDefaultContent extends React.PureComponent {
    // function ReceivingPrintContent({ po_items, receivingData, deliveryDate, single_receiving, }) {
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.si_data.items.length;

        this.state = {
            auth: this.props.auth,
            si_data: this.props.si_data,
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    renderPrint(page, totalPage) {
        let auth = this.state.auth;
        let si_data = this.props.si_data;

        this.setState({ totalItemsCount: this.props.si_data.items.length });

        // console.log(si_data);

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';

        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';

        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6 ">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );
        const tableRows = [];

        const totalItemsCount = si_data.items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        si_data.items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
            }
        });

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        const customerAddress = [
            si_data.customer.street1,
            si_data.customer.street2 && ', ',
            si_data.customer.street2,
            (si_data.customer.street1 || si_data.customer.street2) && ', ',
            si_data.customer.city,
            si_data.customer.city && si_data.customer.state && ', ',
            si_data.customer.state,
        ]
            .filter(Boolean)
            .join('');

        // const { company_name, company_address, company_landline, company_email } = this.props.si_data.subs_setting;

        // const customer_address = si_data.customer != null ? si_data.customer.street1 && si_data.customer.street1  + ', ' + si_data.customer.city && si_data.customer.city + ' ' + si_data.customer.country && si_data.customer.country.label : '';

        const template_setting = this.props.si_data.template_setting;

        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
        subs_setting &&
        subs_setting.currency &&
        subs_setting.currency.code &&
        subs_setting.currency.code.checked
            ? 'code'
            : 'symbol';

        return (
            <div className="margin-1p5em px-1 print-page mt-1">
                <div className="print-logo-container print-header-container">
                    {/* { template_setting && template_setting.company_logo && <img src={template_setting.company_logo} className="print-logo"/> }
                    { template_setting && template_setting.company_logo === '' ? template_setting ? <div className="c-name">{template_setting.company_name}</div> : <div className="print-logo-empty"></div> : null} */}
                    <img src={template_setting && "company_logo" in template_setting ? template_setting.company_logo : "/BlitzLogo.png"} alt="logo" className="print-logo" />
                    <div className="print-header text-right">
                        <span className="print-header-text">
                            DELIVERY INVOICE
                        </span>
                    </div>
                </div>

                <div className="print-body row print-content-block">
                    <div className="col-md-12 pr-0">
                        <span className="f-20b">Delivered to:</span>
                    </div>
                    <div className="col-md-4 col-print-4 ">
                        {/* <span className="f-16b">{si_data.customer}</span> */}
                        <span className="f-16b">
                            <Link
                                to={`/sales/customers/history/${si_data.customer._id}`}
                                className="txt-underline"
                                target="_blank"
                            >
                                {si_data.customer.name}
                            </Link>
                        </span>

                        <p className="f-12">{customerAddress}</p>
                    </div>
                    <div className="col-md-4 col-print-4 "></div>
                    <div className="col-md-4 col-print-4 pr-0">
                        <span className="f-16b">DR #</span>{' '}
                        <span className="f-right">
                            {si_data.delivery_receipt_no}
                        </span>
                        <br />
                        <span className="f-16b">Date</span>{' '}
                        <span className="f-right">
                            {si_data.delivery_date_string}
                        </span>
                    </div>
                    <div className="col-md-12 pr-0">
                        {si_data.status == 'draft' ? (
                            <div class="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table table-striped table-print">
                            <thead>
                                <tr className="print-table-header">
                                    <th>SKU</th>
                                    <th>Product</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Unit</th>
                                    {/* <th className="text-center">Unit Price</th>
                                    <th className="text-center">Total</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {si_data.items.map((data, index) =>
                                    showItemsFrom <= index ? (
                                        showItemsTo >= index ? (
                                            <tr key={index}>
                                                {/* <td>{data.sku}</td> */}
                                                <td
                                                    className="text-left"
                                                    style={DynamicStringStyle}
                                                >
                                                    <Link
                                                        to={`/inventory/view/${data._id}`}
                                                        className="txt-underline"
                                                        target="_blank"
                                                    >
                                                        <DynamicFont
                                                            content={data.sku}
                                                        />
                                                    </Link>
                                                    {data.barcode != '' && (
                                                        <>
                                                            <br />
                                                            <span className="f-10">
                                                                {
                                                                    data.barcode
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                     {data.description != '' && (
                                                        <>
                                                            <br />
                                                            <span className="f-10">
                                                                {
                                                                    data.description
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                     {data.brand != '' && (
                                                        <>
                                                            <br />
                                                            <span className="f-10">
                                                                {
                                                                    data.brand
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                                <td
                                                    className="text-left"
                                                    style={DynamicStringStyle}
                                                >
                                                    <DynamicFont
                                                        content={
                                                            data.product_name
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {data.qty}
                                                </td>
                                                <td className="text-center">
                                                    {data.product_unit_name}
                                                </td>
                                                {/* <td className="text-right">{numberFormat(data.srp)}</td>
                                                    <td className="text-right">{numberFormat(data.amount)}</td> */}
                                            </tr>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div>
                    {page == totalPage ? (
                        <Fragment>
                            <div className="col-md-12 mb-2m">
                                <span className="f-14b">&nbsp;</span>
                                <br />
                                <br />
                                <span className="f-14b">Remarks</span>
                                <p className="f-12">
                                    {si_data.remarks != null ? si_data.remarks : ''}{' '}
                                    &nbsp;
                                </p>
                            </div>
                            <div className="col-12 pr-0">
                                <div className="row">
                                    <div className="col-3 text-left">
                                        <span className="f-14b">
                                            Prepared by
                                        </span>
                                        <p className="f-12">
                                            {si_data.user_prepared_by}&nbsp;
                                        </p>
                                    </div>
                                    <div className="col-3 text-left">
                                        <span className="f-14b">
                                            Checked by
                                        </span>
                                        <p className="f-12">
                                            {si_data.user_checked_by} &nbsp;
                                        </p>
                                    </div>
                                    <div className="col-3 text-left">
                                        {/* <span className="f-14b">Approved by</span> */}
                                        <span className="f-14b">&nbsp;</span>
                                        <p className="f-12">&nbsp;</p>
                                    </div>
                                    <div className="col-3 text-center">
                                        <span className="f-14b">
                                            Received Goods in <br />
                                            good condition by
                                        </span>
                                        <p className="f-12">&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div className="row">
                                    <div className="col-5 text-center">
                                        <span className="f-14b">Received Goods in good condition by</span>
                                        <p className="f-12">&nbsp;</p>
                                    </div>
                                </div>
                            </div> */}
                        </Fragment>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="print-footer h-p25em">
                    <div className="sig-container"></div>
                    <div className="row">
                        <div className="col-md-10 col-print-10 pb-0 mb-0">
                            <ul className="f-12b ul-print-paw">
                                {template_setting && (
                                    <li>{template_setting.company_name}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_landline}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_email}</li>
                                )}
                                {template_setting && (
                                    <li className="mb-2">
                                        {template_setting.company_address}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-md-2 col-print-2 pb-0 mb-0">
                            <span className="f-right mt-25-f">
                                Page {page}/{totalPage}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        // console.log(this.state);
        this.setState({ totalItemsCount: this.props.si_data.items.length });
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{si_data.status == 'draft' ? si_data.status : ''}</h1>
                </div>
            */}

                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default BlitzDefaultContent;
