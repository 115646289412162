import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../../../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { formattedDate } from "../../../../Utils/Common";
import { Redirect, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getTermsConditions, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox, sortTableByColumn, toggleDrawer, getSingleTermCondition } from '../../../../redux/actions/term_condition/term_condition.actions';
import { handleHover, updateMUISetting } from "../../../../redux/actions/helper/helper.actions";

function TermConditionTable({ term_condition: { terms_conditions, pagination, search, loading, edit_url, edit_page, sort_order_name, sort_order_direction, set_row_checked }, 
    getTermsConditions, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox, hoverIndex, handleHover, updateMUISetting, toggleDrawer, getSingleTermCondition}) {

    // const { hasPermission } = useAccess();
    // const canCreate = hasPermission("inv.products.brands.create");
    // const canDelete = hasPermission("inv.products.brands.delete");
    // const canEdit = hasPermission("inv.products.brands.edit");
    // const canview = hasPermission("inv.products.brands.view");


    const history = useHistory();
    useEffect(() => {
        getTermsConditions();
    },[]);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        
      if (debouncedSearch) {
        if(oldDebounced !== debouncedSearch[0])
        {
            if(debouncedSearch[0].length !== 1){
                setOldDebounced(debouncedSearch[0]);
                getTermsConditions(1);
            }
        }
      } 
    },[debouncedSearch]);
    // END DEVOUNCE SEARCH

    if(edit_page) return <Redirect to={edit_url}/>

    const changePage = (link) => {
        history.push(link);
    }

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            MUIDataTableBodyCell: {
                    root: {
                    '&:nth-child(2)': {
                        height: '61px'
                    }
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(2)': {
                        width: '200px'
                    },
                    '&:nth-child(3)': {
                        width: '150px'
                    },
                    '&:nth-child(4)': {
                        width: '90px'
                    },
                }
            }
        }
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'title', label: 'Title', options: { display: true, } },
        {name: 'created_at', label: 'Created at', options: { display: true, print: false , download: false ,
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            } 
        },
        { name: '', label: '', options: { display: true, filter: false, sort: false, print: false, download: false, 
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = terms_conditions[tableMeta['rowIndex']]._id;
                    
                    // if(!canEdit)
                    // {
                    //     return false;
                    // }

                    return (    
                        <div className="btn-group">
                           {  hoverIndex == tableMeta.rowIndex ?  <Link onClick={() => getSingleTermCondition(id)} className="btn btn-sm btn-grey"><i className="ft ft-edit"></i></Link> : '' }
                        </div>
                    )
                },
                setCellProps: (value) => {
                    return {className : "text-center"}
                }
            }
        }
    ];


    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20,50,100,200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        elevation: 2,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `terms_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getTermsConditions(null, numberOfRows)
            updateMUISetting('rows', 'term_condition', numberOfRows);
        },
        onTableChange: (action, tableState) => {

            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getTermsConditions(tableState.page + 1, pagination.itemsCountPerPage);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'term_condition', null, tableState.columns);
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            confirmDeleteDialogBox();
            // if(canDelete)
            // {
            //     confirmDeleteDialogBox();
            // }
            // return false;
        },
        onRowClick: (rowData, rowMeta) => {},
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search" value={search} onChange={handleSearchInputMUI} className="custom-search-input"/>
                </div>
            )
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return { onMouseEnter: () => handleHover('in', rowIndex), onMouseLeave: () => handleHover('out', rowIndex) }
        },
    };

    // if(!canDelete)
    // {
    //   options.selectableRows = false;
    // }

    const _columns = updateMUISetting('view-columns', 'term_condition', null, columns);

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={terms_conditions} columns={_columns} options={options} />
            </MuiThemeProvider>         
        </>
    )
}

const mapStateToProps = state => ({
    term_condition: state.term_condition,
    hoverIndex: state.helper.hoverIndex
});

export default connect(mapStateToProps, { getTermsConditions, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox, handleHover, updateMUISetting, toggleDrawer, getSingleTermCondition })(TermConditionTable);