import { getNumRows } from '../../../Utils/Common.js';
import * as TYPES from '../../types.js';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
} from '../../../Services/_toast.service';
import { pickAndPackService } from './_service.pickAndPack.js';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { SwalWarning, SwalWarningHtml } from '../../../Services/_swal.service';

// Set Loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });

// Set Modal
export const setModal =
    (modal, products = null) =>
    async (dispatch) => {
        dispatch({
            type: TYPES.SET_MODAL,
            payload: { modal, status: products !== null },
        });
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
    // dispatch(getReceiving(1));
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
    };

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction) => async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getPickAndPack());
    };

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
    dispatch({
        type: TYPES.DATE_FILTER_INPUT,
        payload: { key: [e.target.name], value: e.target.value },
    });
};

// DATE INPUT CLEAR
export const dateFilterClear =
    (type, pageOrigin = null) =>
    async (dispatch) => {
        dispatch({ type: TYPES.PICK_AND_PACK_DATE_INPUT_CLEAR, payload: type });

        switch (type) {
            case 'pick_and_pack':
                dispatch(getPickAndPack(1, null, pageOrigin));
                break;
            default:
                break;
        }
    };

// HANDLE SUBMIT DATE FILTER FOR [ QUOTATIONS, SALES_INVOICES .... ]
export const handleDateFilterSubmit =
    (e, filterBy, pageOrigin = null) =>
    async (dispatch, getState) => {
        e.preventDefault();

        let { date_to, date_from } = getState().pick_and_pack;
        const today = new Date();
        try {
            if (
                new Date(date_from).getTime() >= today.getTime() ||
                new Date(date_to).getTime() >= today.getTime()
            ) {
                ToastDanger('Your Date value can not be greater than today.');
            } else {
                let date_filter = { date_from: date_from, date_to: date_to };
                // CHECK TYPE OF PAGE TO FILTER
                switch (filterBy) {
                    case 'pick_and_pack':
                        dispatch(
                            getPickAndPack(1, null, pageOrigin, date_filter)
                        );
                        break;
                    default:
                        break;
                }

                // console.log(pageOrigin);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            ToastDanger('Something went wrong please contact your admin...');
        }
    };

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: TYPES.SET_ROW_CHECKED });

export const clearBatch = () => async (dispatch) =>
    dispatch({ type: TYPES.CLEAR_BATCH });

export const moveToArchive = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().pick_and_pack;
    let id_arr = [];
    rows_to_delete.map((row) => {
        id_arr.push(row.id);
    });

    let params = {
        ids: JSON.stringify(id_arr),
    };

    pickAndPackService
        .ArchiveBatch(params)
        .then((res) => {
            dispatch(setRowChecked());
            ToastSuccess(res.data.message);
            dispatch(getPickAndPack());
            dispatch(clearBatch());
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            console.log(error);
        });
};

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    let { rows_to_delete } = getState().pick_and_pack;
    let names = [];
    rows_to_delete.map((rows, i) => {
        names.push('<b>' + rows.name + '</b>');
    });

    SwalWarningHtml(
        'Warning!',
        `Do you want to remove the Brand: ${names.join()}?`,
        () => dispatch(moveToArchive())
    );
};

// set page
export const setPage =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().purchase_order;

        switch (page) {
            case 'main_page':
                dispatch({ type: TYPES.SET_PAGE, payload: { page, status } });
                dispatch(clearBatch());
                break;
            default:
            // nothing
        }
    };

export const getPickAndPack =
    (pageNumber, rows_per_page = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));
        let { search, pagination, sort_order_name, sort_order_direction } =
            getState().pick_and_pack;

        let rows_per_page_val =
            rows_per_page !== null ? rows_per_page : getNumRows('brand');

        // let searchPostParams = {
        //   search: search,
        //   activePage: pagination.activePage,
        //   page: pageNumber,
        //   rows_per_page: rows_per_page_val,
        //   sort_order_name: sort_order_name,
        //   sort_order_direction: sort_order_direction,
        // };

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let searchPostParams = {
            search: search,
            options: {
                page: pageNumber,
                limit: rows_per_page_val || 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        pickAndPackService
            .getBatch(searchPostParams)
            .then((res) => {
                // let pagination = {
                //   totalCount: res.data.brands.total,
                //   activePage: res.data.brands.current_page,
                //   itemsCountPerPage: res.data.brands.per_page,
                //   totalItemsCount: res.data.brands.total,
                // };

                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit,
                    totalItemsCount: res.data.total_docs,
                };

                dispatch({
                    type: TYPES.GET_PICK_AND_PACK_DATA,
                    payload: {
                        batch_list: res.data.docs,
                        pagination: pagination,
                    },
                });
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                // ToastDanger("Network error, please reload the page.");
                console.log(err);
            });
    };

export const scanBatch = (e, search) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        e.preventDefault();
        const formdata = {
            batch_no: search,
        };

        // Validations
        if (search == '') {
            return ToastDanger('Search field is required');
        }

        dispatch(setLoading(true));
        pickAndPackService
            .scanBatch(formdata)
            .then((res) => {
                let batch_data = res.data.docs;
                dispatch({ type: TYPES.SCAN_BATCH, payload: batch_data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(setLoading(false));
                console.log(error);
                ToastDanger(error.data.errors[0]);
                reject();
            });
    });

export const scanSO = (e, search) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        e.preventDefault();
        const formdata = {
            so_no: search,
        };

        // Validations
        if (search == '') {
            return ToastDanger('Search field is required');
        }

        dispatch(setLoading(true));
        pickAndPackService
            .scanSO(formdata)
            .then((res) => {
                console.log('res', res);
                let so_data = res.data.docs;
                dispatch({ type: TYPES.SCAN_SO, payload: so_data });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(setLoading(false));
                console.log(error);
                ToastDanger(error.data.errors[0]);
                reject();
            });
    });

export const scanProduct = (search) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        let { batch_data } = getState().pick_and_pack;

        const formdata = {
            sku: search,
            batch_id: batch_data._id,
        };

        // Validations
        if (search == '') {
            return ToastDanger('Search field is required');
        }

        // dispatch(setLoading(true));
        pickAndPackService
            .scanProduct(formdata)
            .then((res) => {
                let batch_data = res.data.docs;
                dispatch({ type: TYPES.SCAN_PRODUCT, payload: batch_data });
                dispatch(batchChecker());
            })
            .catch((error) => {
                console.log(error);
                ToastDanger(error.data.errors[0]);
                reject();
            });
    });

export const handleSubmit = (id, list) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const formdata = { id: id };

        // console.log('list', list);

        const pickAndPackAction =
            list === 'so'
                ? pickAndPackService.packSO
                : pickAndPackService.pickBatch;

        pickAndPackAction(formdata)
            .then((res) => {
                let batch_data = res.data;

                if (list === 'so') {
                    dispatch(soChecker());
                } else {
                    dispatch(batchChecker());
                }
                resolve(batch_data);
            })
            .catch((error) => {
                console.log(error);
                ToastDanger(error.data.errors[0]);
                reject();
            });
    });

export const handleInputChange = (e) => async (dispatch) => {
    let { name, value } = e.target;
    // value = name == 'qty' ? parseInt(e.target.value) : name == 'item_discount' || name == 'pos_discount' ? parseFloat(e.target.value) : e.target.value;

    dispatch({ type: TYPES.SCAN_HANDLE_INPUT, payload: { name, value } });
};

// Batch Checker
export const batchChecker = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await pickAndPackService.batchChecker();
        // console.log('res', res);
        let batch_data = res.data.docs;

        dispatch(setLoading(false));
        dispatch({ type: TYPES.BATCH_CHECKER, payload: batch_data });
    } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
        ToastDanger(`Server Error`);
    }
};

// Sales Order Checker
export const soChecker = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await pickAndPackService.soChecker();
        // console.log('res', res);
        let so_data = res.data.docs;

        dispatch(setLoading(false));
        dispatch({ type: TYPES.SO_CHECKER, payload: so_data });
    } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
        ToastDanger(`Server Error`);
    }
};
