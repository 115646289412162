import http from '../../Services/api.laravel.http';
var qs = require('qs');

export const AgentService = {
 	// getBrands: () => http.get('/admin/brand'),
 	getSingleAgent: (id) => http.get('/admin/agent/' + id),
  	ArchiveAgent: (id) => http.put('/admin/agent/archives/' + id),
 	agentPost: (formParams) => http.post('/admin/agent', formParams),
  	paginateWithSearchAgents: (formParams) => http.post('/admin/agent/search', formParams),
 	updateSingleAgent: (formParams, id) => http.put('/admin/agent/' + id, formParams),
 	getQuotationsPaginate: (formParams) => http.post('/admin/agent/get/quotations', formParams),
 	getSalesInvoicesPaginate: (formParams) => http.post('/admin/agent/get/sales-invoices', formParams),
}
