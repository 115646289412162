import React, {
    Fragment,
    useEffect,
    useMemo,
    useCallback,
    useState,
} from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect } from 'react-redux';
import {
    getPickAndPack,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    // sendEmailDyanmic,
    // dynamiPdf,
    // emailConfig,
    setPage,
} from '../../redux/actions/pick_and_pack/pick_and_pack.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { formattedDate } from '../../Utils/Common';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import { defaultFormat } from '../../Utils/DateFormat';

function PickAndPackTable({
    pick_and_pack: {
        batch_list,
        pagination,
        search,
        loading,
        edit_url,
        // edit_page,
        // create_url,
        sort_order_name,
        sort_order_direction,
        set_row_checked,
    },
    getPickAndPack,
    setPage,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    pageOrigin,
    handleHover,
    hoverIndex,
    updateMUISetting,
    // sendEmailDyanmic,
    // dynamiPdf,
    // emailConfig,
    setMUIPage,
    muiPage,
}) {
    const history = useHistory();
    const { hasPermission } = useAccess();
    const canUpload = hasPermission('inv.fulfillment.pick-and-pack.upload');
    const canDelete = hasPermission('inv.fulfillment.pick-and-pack.delete');
    const canview = hasPermission('inv.fulfillment.pick-and-pack.view');

    useEffect(() => {
        if (canview) {
            getPickAndPack(muiPage, null, pageOrigin);
        }
        // emailConfig();
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    if (canview) {
                        setOldDebounced(debouncedSearch[0]);
                        getPickAndPack(1, null, pageOrigin);
                    }
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles((theme) => ({
        centeredHeader: {
            '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
        },
    }));
    const classes = useStyles();

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MUIDataTableBodyCell: {},
                MUIDataTableHeadCell: {
                    root: {
                        '&:nth-child(3)': {
                            width: '250px',
                        },
                        '&:nth-child(6)': {
                            width: '150px',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'batch_no',
            label: 'Batch No.',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value}</div>;
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
        {
            name: 'location',
            label: 'Location',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value}</div>;
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
        {
            name: 'print_date',
            label: 'Print Date',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value}</div>;
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value}</div>;
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard', //
        search: false,
        filter: false,
        searchOpen: true,
        searchProps: {
            onBlur: (e) => {
                console.log('onBlur!');
            },
            onKeyUp: (e) => {
                console.log('onKeyUp!');
            },
        },
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `pap_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getPickAndPack(null, numberOfRows, pageOrigin);
            updateMUISetting('rows', 'pick_and_pack', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState)
            switch (action) {
                case 'changePage':
                    getPickAndPack(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                    break;
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'pick_and_pack',
                        null,
                        tableState.columns
                    );
                    break;
                case 'changeRowsPerPage':
                    // updateMUISetting('rows', 'pick_and_pack', tableState.rowsPerPage);
                    break;
                case 'onFilterDialogOpen':
                    break;
                case 'onSearchClose':
                    break;
                default:
                    // console.log(tableState);
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            confirmDeleteDialogBox(pageOrigin);
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page
            // changePage(`${edit_url}/${rowData[0]}`)
            // showmodal
        },
        onCellClick: (colData, cellMeta) => {
            // console.log(cellMeta)
            // console.log(cellMeta.dataIndex);
            if (cellMeta.colIndex !== 7 && cellMeta.colIndex !== 8) {
                // console.log('PO_PRINT_ID', pick_and_pack[cellMeta.dataIndex]._id, pick_and_pack);
                // if (canPrint) {
                //     // setPage('print_modal', pick_and_pack[cellMeta.dataIndex]._id);
                // }
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'purchase_order',
        null,
        columns
    );

    return (
        <>
            <Show when="inv.fulfillment.pick-and-pack.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={batch_list}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>
        </>
    );
}

const mapStateToProps = (state) => ({
    pick_and_pack: state.pick_and_pack,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getPickAndPack,
    setPage,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    // sendEmailDyanmic,
    // dynamiPdf,
    // emailConfig,
    setMUIPage,
})(PickAndPackTable);
