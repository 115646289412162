import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { settingAppLogoUpload, uploadLogo, setModal, showCropping} from '../../../redux/actions/settings_app/setting_app.actions';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { dataURLtoFile } from '../../../Utils/Common';
import { ToastDanger } from '../../../Services/_toast.service';


function SettingAppLogoCrop ({ setting_app: { single_setting_app, upload_modal }, settingAppLogoUpload, uploadLogo, setModal, showCropping }) {

    const imgRef = useRef(null);
    const [trigger, setTrigger] = useState(false);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25
    });

    const [completedCrop, setCompletedCrop] = useState(null);

    const onLoad = useCallback((img) => {
        // imgRef.current = img;

        imgRef.current = img;
        const aspect = 4 / 3 ; //  aspect ratio: 4 / 3 or 16 / 9
        const width = img.width / aspect < img.height * aspect ? 50 : ((img.height * aspect) / img.width) * 50;
        const height = img.width / aspect > img.height * aspect ? 50 : (img.width / aspect / img.height) * 50;
        const y = (100 - height) / 2;
        const x = (100 - width) / 2;
    
        setCrop({
            unit: '%',
            width,
            height,
            x,
            y,
            // aspect,  // uncomment this if you want to
        });

        return false; // Return false if you set crop state in here.        
    }, []);


    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);


    const onChange = (c) => {
        setTrigger(true);
        setCrop(c)
    }

    // handle submit
    const submitLogo = () => {

        if(trigger == false){
            return ToastDanger('Please set your crop image by pulling or dragging the dotted lines.');
        }

        let snap = previewCanvasRef.current;
        // snap.getContext('2d');
        let croppedlogo = dataURLtoFile(snap.toDataURL(), 'cropped2.jpg');
        // send to api
        settingAppLogoUpload(croppedlogo, 'upload');
        setTrigger(false);
    }

    const onDrop = useCallback( async (acceptedFiles) => {
        uploadLogo(acceptedFiles)
        // console.log(acceptedFiles)
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <>
            <div className="row">
                <div className="col-12">
                    { 
                        single_setting_app.logo != "" && 
                        <>
                            <div className="float-left d-flex">
                                <label> UPDATE LOGO</label>
                            </div> 
                            <div className="float-right d-flex">
                                <label onClick={() => showCropping(false)}> <i className="ft ft-x close-logo-c"></i></label>
                            </div>
                        </>
                    }
                </div>
            </div>

            <div className="f-regular">
                {
                    single_setting_app.logo_preview !== '' ? 
                    <div className="text-center p-2 w-logo">
                        <div className="crop-container">
                            <ReactCrop
                                // minHeight={150}
                                maxHeight={150}
                                src={single_setting_app.logo_preview} 
                                onImageLoaded={onLoad}
                                crop={crop} 
                                onChange={onChange} 
                                onComplete={(c) => setCompletedCrop(c)}
                                imageStyle={{maxHeight: '300px'}}
                            />
                        </div>
                        <div className="crop-img">
                            {/* {style={{ width: Math.round(completedCrop?.width ?? 0),height: Math.round(completedCrop?.height ?? 0)}} } */}
                            <canvas ref={previewCanvasRef} />
                        </div>
                    </div>
                    :
                    <div {...getRootProps()} className="dropzone-line-style-setting-logo">
                        <input {...getInputProps()}/>
                        <center>
                            <i className="ft ft-upload-cloud upload-icon"></i>
                            <div className="">Drag & Drop <br/>Image Here</div>
                            <div className="upload-message-or mb-2">or</div>
                            <div className="btn btn-info btn">Browse Files</div>
                        </center>
                    </div>
                }

                {
                    single_setting_app.logo_preview && 
                    <div className="crop-footer mt-2">
                        <div className="row">
                            <div className="col-12 pb-1">
                                <div className="float-right d-flex">
                                    <div className="mr-2" {...getRootProps()}> <input {...getInputProps()}/> <div className="btn btn-grey btn">Choose File</div> </div>
                                    <button onClick={submitLogo} type="submit" className="btn btn-primary btn-save"> Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}


const mapStateToProps = state => ({
    setting_app: state.setting_app,
});

export default connect(mapStateToProps, { setModal, settingAppLogoUpload, uploadLogo, showCropping })(SettingAppLogoCrop);
