import {
  SET_LOADING,
  GET_PRODUCT_UNITS,
  GET_SINGLE_PRODUCT_UNIT,
  PRODUCT_UNIT_INPUT_CHANGE,
  SET_PAGE,
  SEARCH_INPUT,
  CLEAR_PRODUCT_UNIT,
  SET_MINI_LOADING,
  SORT_BY_COLUMN,
  GET_ROWS_TO_DELETE,
  SET_ROW_CHECKED,
} from "./../../types";
import { ToastSuccess, ToastDanger } from "../../../Services/_toast.service";
import $ from "jquery";
import { ProductUnitService } from "./_service.product_unit";
import { SwalWarning, SwalWarningHtml } from "../../../Services/_swal.service";
import {
  getSelectAll,
  setModal,
  handleSelect,
} from "../product/product.actions";
import { blockRoute, setMUIPage } from "../helper/helper.actions";
import { getNumRows } from "../../../Utils/Common";

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
  if (e.target.value) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({
    type: PRODUCT_UNIT_INPUT_CHANGE,
    payload: { key: e.target.name, value: e.target.value },
  });
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) =>
  dispatch({ type: SEARCH_INPUT, payload: e.target.value });

// loading
export const setLoading = (status) => async (dispatch) =>
  dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
  dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearProductUnit = () => async (dispatch) =>
  dispatch({ type: CLEAR_PRODUCT_UNIT });

// set page
export const setPage =
  (page, id, status = true) =>
  async (dispatch, getState) => {
    let { edit_url } = getState().product_unit;

    switch (page) {
      case "main_page":
        dispatch({ type: SET_PAGE, payload: { page, status } });
        dispatch(clearProductUnit());
        break;
      case "edit_page":
        let edit_url_with_id = `${edit_url}/${id}`;
        dispatch({
          type: SET_PAGE,
          payload: { page, status, edit_url: edit_url_with_id },
        });
        break;
      case "delete_modal":
        dispatch(getSingleProductUnit(id, "delete"));
        break;
      default:
        break;
    }
  };

// fetch
export const getProductUnits =
  (pageNumber, rows_per_page = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    let { search, pagination, sort_order_name, sort_order_direction } =
      getState().product_unit;
    let rows_per_page_val =
      rows_per_page != null ? rows_per_page : getNumRows("product_unit");
    // console.log(getState().product_unit);

    // let searchPostParams = {
    //     search: search,
    //     activePage: pagination.activePage,
    //     page: pageNumber,
    //     rows_per_page: rows_per_page_val,
    //     sort_order_name: sort_order_name,
    //     sort_order_direction: sort_order_direction
    // }

    //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
    let searchPostParams = {
      search,
      options: {
        page: pageNumber,
        limit: rows_per_page_val || 10,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };

    ProductUnitService.paginateWithSearchProductUnit(searchPostParams)
      .then((res) => {
        // let pagination = {
        //     totalCount: res.data.product_units.total,
        //     activePage: res.data.product_units.current_page,
        //     itemsCountPerPage: res.data.product_units.per_page,
        //     totalItemsCount: res.data.product_units.total
        // }

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let pagination = {
          totalCount: res.data.total_docs,
          activePage: res.data.page,
          itemsCountPerPage: res.data.limit,
          totalItemsCount: res.data.total_docs,
        };

        dispatch({
          type: GET_PRODUCT_UNITS,
          payload: { product_units: res.data.docs, pagination: pagination },
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        // if (error.data.errors.length > 0) {
        //   error.data.errors.map((err) => ToastDanger(err));
        // } else {
        //   ToastDanger("Network error, please reload the page.");
        // }
        console.log(error);
      });
  };

// store
export const productUnitPost =
  (e, pageOrigin) => async (dispatch, getState) => {
    e.preventDefault();
    let { single_product_unit } = getState().product_unit;

    $(".btn-save").attr("disabled", "disabled").html("Saving...");

    if(pageOrigin === 'item'){
      single_product_unit['pull'] = true;
    }
  
    dispatch(setMUIPage(1));
    ProductUnitService.productUnitPost(single_product_unit)
      .then((res) => {
        if (pageOrigin === "product_unit") {
          dispatch(setPage("main_page"));
        }

        if (pageOrigin === "item") {
          dispatch(getSelectAll());
          dispatch(setModal("unit_modal", false));
          let productUnitSelect = {
            value: res.data.docs._id,
            label: res.data.docs.name,
          };
          dispatch(handleSelect(productUnitSelect, "product_unit_id"));
        }

        ToastSuccess(res.data.message);
        dispatch(clearProductUnit());
        $(".btn-save").removeAttr("disabled").html("Save");
      })
      .catch((error) => {
        if (error.data.errors.length > 0) {
          ToastDanger(error.data.errors);
        } else {
          ToastDanger("Network error, please reload the page.");
        }
        $(".btn-save").removeAttr("disabled").html("Save");
        console.log(error);
      });
  };

// fetch single
export const getSingleProductUnit =
  (id, status = null) =>
  async (dispatch) => {
    dispatch(setMiniLoading(true));
    ProductUnitService.getSingleProductUnit(id)
      .then((res) => {
        dispatch({
          type: GET_SINGLE_PRODUCT_UNIT,
          payload: res.data.product_unit,
        });
        dispatch(setMiniLoading(false));
      })
      .catch((error) => {
        dispatch(setMiniLoading(false));
        ToastDanger("Network error, please reload the page.");
        console.log(error);
      });
  };

// update
export const updateProductUnit = (e) => async (dispatch, getState) => {
  e.preventDefault();
  $(".btn-save").attr("disabled", "disabled").html("Saving...");

  let { single_product_unit } = getState().product_unit;

  ProductUnitService.updateProductUnit(
    single_product_unit,
    single_product_unit._id
  )
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          dispatch(setPage("main_page"));
          ToastSuccess(res.data.message);
          break;
        default:
          break;
      }
      $(".btn-save").removeAttr("disabled").html("Save");
    })
    .catch((error) => {
      ToastDanger("Network error, please reload the page.");
      $(".btn-save").removeAttr("disabled").html("Save");
      console.log(error);
    });
};

// archieve
export const moveToArchive = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().product_unit;
  let id_arr = [];
  rows_to_delete.map((row) => {
    id_arr.push(row.id);
  });

  let params = {
    ids: JSON.stringify(id_arr),
  };

  ProductUnitService.ArchiveProductUnit(params)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.message);
          dispatch(getProductUnits());
          break;
        case 1:
          ToastSuccess(res.data.message);
          dispatch(getProductUnits());
          dispatch(clearProductUnit());
        default:
          break;
      }
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        ToastDanger(error.data.errors[0]);
      } else {
        ToastDanger("Network error, please reload the page.");
      }
      console.log(error);
    });
};

export const handleSearchInputMUI = (e) => async (dispatch) => {
  dispatch({ type: SEARCH_INPUT, payload: e.target.value });
};

// Sort by column name and direction
export const sortTableByColumn =
  (sort_order_name, sort_order_direction) => async (dispatch) => {
    let sortingParams = {
      sort_order_name: sort_order_name,
      sort_order_direction: sort_order_direction,
    };

    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getProductUnits());
  };

// returns selected rows only
export const getSelectedRows =
  (selectedRows, currentRows) => async (dispatch) => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current, i) => {
      selectedRows.map((selected, _i) => {
        if (selected.index == current.index) {
          let selected = { id: current.data[0], name: current.data[1] };
          newArray.push(selected);
        }
      });
    });
    // console.log(newArray);
    dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
  };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().product_unit;
  let names = [];
  rows_to_delete.map((rows, i) => {
    names.push("<b>" + rows.name + "</b>");
  });

  SwalWarningHtml(
    "Warning!",
    `Do you want to remove the Product Unit: ${names.join()}?`,
    () => dispatch(moveToArchive())
  );
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
  dispatch({ type: SET_ROW_CHECKED });
