import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setModal, searchProduct } from '../../../redux/actions/pos/pos.actions';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import { MiniSpinner } from '../../layouts/Spinner';

function POSItemModal({ pos, setModal, searchProduct}) {

    // const { pos_item } = pos;
    // const _item_total_price = pos.item_total_price < 0 ? <span className="lbl-status lbl-danger-pos">NEGATIVE</span> : numberFormat(pos.item_total_price);
    // const modalSize = pos.multi_products ? 'md' : 'lg';
    
    return (
        <Modal centered dialogClassName="modal-container" show={pos.pos_item_modal} onHide={() => setModal('pos_item_modal', false)} size='md' animation={true}>
            <Modal.Header closeButton> <Modal.Title></Modal.Title> </Modal.Header>
            <Modal.Body className="mt-0 pt-0">
                <div className="row f-regular">
                    <div className="col-12">
                        {
                            pos.pos_loading == 'item-modal' ?  <div className="col-12"><div className="my-5"><MiniSpinner/></div></div> :
                            pos.products.map(item => (
                                <div onClick={item.inStock ? () => searchProduct({ search: {_id: item._id, barcode: item.barcode} }, 'item-modal') : () => {}} className={`card card-body pos-card-list shadow mb-1 bg-white rounded ${item.stockWarning && 'bg-warning bg-accent-2'} ${!item.inStock && 'bg-danger bg-accent-2'}`}>
                                    <div className="row">
                                        <div className="col-2">
                                            {
                                                 <img src={item.product_image} className="img img-thumbnail"/> 
                                            }
                                        </div>
                                        <div className="col-6">
                                            {item.product_name}
                                            <div><small>SKU: {item.sku}</small></div>
                                        </div>
                                        <div className="col-4"> 
                                        <div className="float-right">
                                                <small>SRP: <span className="pos-strong-text">{numberFormat(item.srp)}</span></small>
                                                <div>{item.stockWarning && <span className={`lbl-status lbl-pos-warning`}>LOW THRESHOLD</span>}</div>
                                                <div>{item.inStock != true && <span className={`lbl-status lbl-danger`}>OUT OF STOCK</span>}</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
	pos: state.pos
});

export default connect(mapStateToProps, { setModal, searchProduct })(POSItemModal);
