import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';
import '../../layouts/template_styles/stealth-si-01.css';

export class StealthSI01 extends React.PureComponent {
    // function SalesInvoicePrintContent({ si, auth }) {
    // let subId = auth.user.subscriber_id;

    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.si.sales_invoice_items.length;
        this.state = {
            si: this.props.si,
            auth: this.props.auth,
            activePage: 1,
            itemsCountPerPage: 15,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td colspan={2}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }
        return tableRows;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        // console.log(page, totalPage);
        const { si } = this.state;
        const totalItemsCount = si.sales_invoice_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        si.sales_invoice_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
                // console.log(extraRowsCount);
            }
        });

        // console.log(showItemsFrom, showItemsTo);
        // console.log(extraRowsCount);

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        let auth = this.state.auth;

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';
        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';
        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b temp-div pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const template_setting = this.state.si.template_setting;
        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';

        return (
            <div className="margin-1p5em px-1 print-page">
                <div className="si01-ht">
                    <div className="si01-company">
                        {template_setting && template_setting.company_name}
                    </div>
                    <div className="si01-address">
                        {template_setting && template_setting.company_address}
                    </div>
                    <div className="si01-tin">
                        VAT Reg. TIN:{' '}
                        {template_setting && template_setting.company_tin
                            ? template_setting.company_tin
                            : ''}
                    </div>
                </div>
                <div className="si01-h mt-3">
                    <div className="si01-hl">
                        <div className="pull-left">
                            <div className="f-20b">SALES INVOICE</div>
                        </div>
                    </div>
                    <div>
                        <div className="pull-right">
                            <div className="f-18b text-center">
                                <span>
                                    N<sup>o</sup>
                                </span>{' '}
                                <span className="si01-no">{si.serial_no}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="si01-hl col-8">
                        <div className="pull-left">
                            <div className="si01-16b si-lbl">
                                Sold To: <span className="ln-soldto"></span>{' '}
                                <span className="vl-soldto si01-16">
                                    {' '}
                                    <Link
                                        to={`/sales/customers/history/${
                                            si.customer ? si.customer._id : null
                                        }`}
                                        className="txt-underline print-text"
                                        target="_blank"
                                    >
                                        {si.customer ? si.customer.name : null}
                                    </Link>{' '}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                TIN: <span className="ln-tin"></span>{' '}
                                <span className="vl-tin si01-16">
                                    {' '}
                                    {si.customer ? si.customer.tin : null}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                Address: <span className="ln-address"></span>{' '}
                                <span className="vl-address si01-16">
                                    {si.customer ? si.customer.street1 : null}{' '}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                Bus Style/Name: <span className="ln-bus"></span>{' '}
                                <span className="vl-bus si01-16"> </span>{' '}
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="">
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">Date:</span>{' '}
                                <span className="ln-date"></span>{' '}
                                <span className="vl-date">
                                    {' '}
                                    {defaultFormat(si.sales_invoice_date)}{' '}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">Terms: </span>{' '}
                                <span className="ln-terms"></span>{' '}
                                <span className="vl-terms">
                                    {si.payment_terms
                                        ? si.payment_terms.label
                                        : ''}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">
                                    OSC/PWD ID No.:
                                </span>{' '}
                                <span className="ln-osc"></span>{' '}
                                <span className="vl-osc"> </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">
                                    SC/PWD Signature:
                                </span>{' '}
                                <span className="ln-sc"></span>{' '}
                                <span className="vl-sc"> </span>{' '}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="si01-print-body row">
                    <div className="col-md-12">
                        {si.status == 'draft' ? (
                            <div class="si01-watermark ">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="si01-table">
                            <thead>
                                <tr>
                                    <th>QTY</th>
                                    <th>UNIT</th>
                                    <th colspan="2" width="50%">
                                        ARTICLES
                                    </th>
                                    <th width="20%">UNIT PRICE</th>
                                    <th>AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {si.sales_invoice_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <>
                                                <tr>
                                                    <td className="text-center">
                                                        {numberFormat(
                                                            item.qty,
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.product_unit_name
                                                            ? item.product_unit_name
                                                            : ''}
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        colspan="2"
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${item._id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    item.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {', '}
                                                        {item.products !=
                                                            null && (
                                                            <>
                                                                {' '}
                                                                {
                                                                    item
                                                                        .products
                                                                        .description
                                                                }
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.unit_price
                                                            ? formatPrice(
                                                                  item.unit_price,
                                                                  currencyDisplay
                                                              )
                                                            : formatPrice(
                                                                  item.srp,
                                                                  currencyDisplay
                                                              )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            item.amount,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                </tr>

                                                {item.discount_type &&
                                                    item.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td
                                                                colspan="2"
                                                                className=" p-1"
                                                            >
                                                                <span>
                                                                    <span>
                                                                        Discount:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {item
                                                                            .discount_type
                                                                            .label !==
                                                                        'Percent'
                                                                            ? formatPrice(
                                                                                  item.discount,
                                                                                  currencyDisplay
                                                                              )
                                                                            : item.discount}
                                                                        {item
                                                                            .discount_type
                                                                            .label ===
                                                                        'Percent'
                                                                            ? ' %'
                                                                            : ''}
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    style={{
                                                                        margin: '0 0 0 10px',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        Discount
                                                                        type:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item
                                                                                .discount_type
                                                                                .label
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {formatPrice(
                                                                    Number(
                                                                        item.discount_amount
                                                                    ).toFixed(
                                                                        2
                                                                    ),
                                                                    currencyDisplay
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}

                                {page == totalPage ? (
                                    <>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2}></td>
                                            <td>
                                                {' '}
                                                Total Sales{' '}
                                                <span>
                                                    (VAT Inclusive)
                                                </span>{' '}
                                            </td>
                                            <td align="center"> </td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2}></td>
                                            <td>Less VAT</td>
                                            <td></td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center" width="25%">
                                                VATable Sales
                                            </td>
                                            <td></td>
                                            <td>Amount: Net of VAT</td>
                                            <td></td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center">
                                                VAT-Exempt Sales
                                            </td>
                                            <td></td>
                                            <td>
                                                Less: <span>SC/PWD</span>{' '}
                                                Discount
                                            </td>
                                            <td align="center"> </td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center">
                                                Zero-Rated Sales
                                            </td>
                                            <td></td>
                                            <td>Amount Due</td>
                                            <td></td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center">VAT Amount</td>
                                            <td></td>
                                            <td>ADD VAT</td>
                                            <td align="center"></td>
                                        </tr>
                                        <tr>
                                            <td align="right" colspan="5">
                                                <strong>
                                                    TOTAL AMOUNT DUE
                                                </strong>
                                            </td>
                                            <td align="center">
                                                {formatPrice(
                                                    si.total,
                                                    currencyDisplay
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2}></td>
                                            <td>
                                                {' '}
                                                Total Sales{' '}
                                                <span>
                                                    (VAT Inclusive)
                                                </span>{' '}
                                            </td>
                                            <td align="center"> </td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2}></td>
                                            <td>Less VAT</td>
                                            <td></td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center" width="25%">
                                                VATable Sales
                                            </td>
                                            <td></td>
                                            <td>Amount: Net of VAT</td>
                                            <td></td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center">
                                                VAT-Exempt Sales
                                            </td>
                                            <td></td>
                                            <td>
                                                Less: <span>SC/PWD</span>{' '}
                                                Discount
                                            </td>
                                            <td align="center"> </td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center">
                                                Zero-Rated Sales
                                            </td>
                                            <td></td>
                                            <td>Amount Due</td>
                                            <td></td>
                                        </tr>
                                        <tr className="tbl-row">
                                            <td></td>
                                            <td></td>
                                            <td align="center">VAT Amount</td>
                                            <td></td>
                                            <td>ADD VAT</td>
                                            <td align="center"></td>
                                        </tr>
                                        <tr>
                                            <td align="right" colspan="5">
                                                <strong>
                                                    TOTAL AMOUNT DUE
                                                </strong>
                                            </td>
                                            <td align="center">
                                                {' '}
                                                {numberFormat(si.total)}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="si01-hl col-6">
                        <div className="pull-left"></div>
                    </div>
                    <div className="col-6">
                        <div className="">
                            <div className="f-12b">
                                <span>By:</span>{' '}
                                <span className="ln-sign"></span>{' '}
                            </div>
                            <div className="text-center lbl-sign">
                                Cashier/Authorized Representative
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="si01-f">
                            "THIS SALES INVOICE SHALL BE VALID FOR (5) YEARS
                            FROM THE DATE OF ATP"
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pb-0 mb-0">
                        <span className="pull-right f-12">
                            Page {page}/{totalPage}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let si = this.state.si;

        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{si.status == 'draft' ? si.status : ''}</h1>
                </div>
            */}
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
// 	single_sales_invoice: state.sales_invoice.single_sales_invoice,
// 	auth: state.auth
// })

export default StealthSI01;
