import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function StockLogFilter({ onFilterChange }) {
    const [activeFilter, setActiveFilter] = useState('out');

    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
        onFilterChange(filter); // Call the function to update the filter in getStockLogs
    };

    return (
        <>
            <label>Filter Movement:</label>
            <ButtonGroup
                aria-label="Stock log filter"
                className="stock-log-filter m-1"
            >
                <Button
                    variant={activeFilter === 'out' ? 'primary' : 'secondary'}
                    onClick={() => handleFilterChange('out')}
                    size="sm"
                >
                    Out
                </Button>
                <Button
                    variant={activeFilter === 'in' ? 'primary' : 'secondary'}
                    onClick={() => handleFilterChange('in')}
                    size="sm"
                >
                    In
                </Button>
            </ButtonGroup>
        </>
    );
}

export default StockLogFilter;
