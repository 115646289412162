import React from 'react';
import { connect } from 'react-redux';
import BlitzDefaultContent from './BlitzDefault.content';
import StealthSI01 from './Stealth-SI-01';
import PeoplesSI01 from './PeoplesSI01';
// import MotoTruck from './MotoTruckTemplate';
import MotoTruck from './MotoTruckNewTemplate';
import { getSubSetting } from '../../../Utils/Common';

function InvoiceCustomTemplate ({ single_invoice, auth, componentRef }) {

    const subs_setting = getSubSetting()
    const template_label = single_invoice.template_setting ? single_invoice.template_setting.template_label : 'none';

    return (
        <>
            {(() => {
                 switch (template_label) {
                    case 'blitz_default':
                        return  <>
                                    <BlitzDefaultContent subs_setting={subs_setting} invoice={single_invoice} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <BlitzDefaultContent subs_setting={subs_setting} invoice={single_invoice} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'stealth_si_01':
                        return  <>
                                    <StealthSI01 subs_setting={subs_setting} invoice={single_invoice} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <StealthSI01 subs_setting={subs_setting} invoice={single_invoice} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'peoples_si_01': // since this template is almost the same i just change some text using varaibles
                    case 'peoples_si_02':
                        return  <>
                                    <PeoplesSI01 subs_setting={subs_setting} invoice={single_invoice} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <PeoplesSI01 subs_setting={subs_setting} invoice={single_invoice} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'mototruck':
                        return  <>
                                <MotoTruck subs_setting={subs_setting} invoice={single_invoice} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                <MotoTruck subs_setting={subs_setting} invoice={single_invoice} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                            </>
                    default:
                        return <><h4 className="text-center my-5">-- There's No Data To Show--</h4></>;
                }
            })()}
        </>
    )
}


const mapStateToProps = state => ({
	auth: state.auth,
	single_invoice: state.invoice.single_invoice,
})

export default connect(mapStateToProps, {  })(InvoiceCustomTemplate);
