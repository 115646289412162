import * as TYPE from '../types';

const initialState = {
    si_template_settings: [],
    single_si_template_setting: {
        sales_invoice_template_id: '',
        template_name: '',
        company_name: '',
        company_address: '',
        company_email: '',
        company_landline: '',
        company_tin: '',
        company_mobile: '',
        is_default: false,
        logo: '',
        logo_preview: '',
        file_logo: null,
        type: '',
        off_default: false
    },
    loading: false,
    show_crop: false,
    crop_trigger: false,
    crop_isset: false,
    show_component: 'main',
}

const salesInvoiceTemplateSettingReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case TYPE.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPE.GET_SI_TEMPLATE_SETTING:
            return {
                ...state,
                si_template_settings: action.payload
            }
        case TYPE.SET_SI_TS_COMPONENT:
            return {
                ...state,
                show_component: action.payload
            }
        case TYPE.HANDLE_SI_TEMPLATE_INPUT:
            return {
                ...state,
                single_si_template_setting: { ...state.single_si_template_setting, [action.payload.name]: action.payload.value }
            }
        case TYPE.HANDLE_SET_SI_DEFAULT_TEMPLATE:
            return {
                ...state,
                single_si_template_setting: { ...state.single_si_template_setting, ...action.payload }
            }
        case TYPE.CLEAR_SI_TEMPLATE_SETTING:
            return {
                ...state,
                single_si_template_setting: {
                    sales_invoice_template_id: '',
                    template_name: '',
                    company_name: '',
                    company_address: '',
                    company_email: '',
                    company_landline: '',
                    company_mobile: '',
                    is_default: false,
                    logo: '',
                    logo_preview: '',
                    type: '',
                    file_logo: null,
                    off_default: false
                },
                loading: false,
                show_crop: false,
                crop_trigger: false,
                crop_isset: false,
                show_component: 'main'
            }
        case TYPE.SI_TS_SHOW_CROP:
            return {
                ...state,
                show_crop: action.payload
            }
        case TYPE.SI_TS_LOGO_PREVIEW:
            return {
                ...state,
                single_si_template_setting: { ...state.single_si_template_setting, logo_preview: action.payload },
                show_crop: true
            }
        case TYPE.SI_TS_CROP_LOGO:
            return {
                ...state,
                single_si_template_setting: { ...state.single_si_template_setting, file_logo: action.payload.file_logo },
                crop_trigger: action.payload.crop_trigger
            }
        case TYPE.SI_TS_REMOVE_LOGO:
            return {
                ...state,
                single_si_template_setting: { ...state.single_si_template_setting, ...action.payload }
            }
        case  TYPE.SI_TS_CROP_TRIGGER:
            return {
                ...state,
                crop_trigger: action.payload
            }
        case TYPE.GET_SINGLE_SI_TS:
            return {
                ...state,
                single_si_template_setting: { ...state.single_si_template_setting, ...action.payload }
            }
        case TYPE.SI_TS_CROP_ISSET:
            return {
                ...state,
                crop_isset: action.payload
            }
        default:
            return state;
    }
}


export default salesInvoiceTemplateSettingReducer;