import * as TYPES from './../types';

// state
const initialState = {
    sm_boxes: {
        // out_of_stock: 0,
        // low_stock: 0,
        // purchase_orders: 0,
        // sales_this_week: 0,
        inStock: 0,
        toReceive: 0,
        outOfStock: 0,
        lowStock: 0,
        purchaseOrders: 0,
        salesThisWeek: 0,
        incoming_items: 0,
        total_sales: 0,
        gross_profit: 0,
        net_sales: 0,
        inventory_value: 0,
        collectible: 0,
    },
    graphs: {
        topCustomers: [{ _id: null, name: null, totalSales: null }],
        topProducts: [{ _id: null, name: null, totalSales: null }],
        topPurchasedProducts: [{ _id: null, name: null, totalSales: null }],
        sales: {
            sales: {
                labels: [],
                data: [],
            },
            cost: {
                labels: [],
                data: [],
            },
            totalRevenue: 0,
            totalSold: 0,
        },
        categories: {
            labels: [],
            data: [],
        },
        purchaseHistory: [],
        inventoryLevels: {
            labels: [],
            data: [],
            lifetimeStocks: 0,
            stockValue: 0,
            stockSold: 0,
        },
        collections: {
            labels: [],
            data: [],
            total: 0,
        },
    },
    incoming_purchase_orders: [],
    order_history: [],
    loading: false,
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case TYPES.DASHBOARD_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
            break;
    }
};

export default homeReducer;
