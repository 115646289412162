import React, { useEffect, Fragment } from "react";
import Spinner from "../../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import Permissions from './Permissions/Roles';
import Accounts from './Accounts/Accounts';

function Users (){
    
    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><a href="/settings/users" className="text-white">Users</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <div className="card-content collapse show">
                                        <div className="card-body no-padding-top no-padding-left-right">
		                                     <section className="">
		                                        <div className="">
		                                            <ul className="nav nav-tabs  f-regular">
		                                                <li className=""><a  className="nav-link active" id="base-tab1" data-toggle="tab" aria-controls="tab1" href="#accounts" aria-expanded="true">Accounts</a></li>
		                                                <li className=""><a  className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#roles" aria-expanded="false">Roles</a></li>
		                                            </ul>
		                                            <div className="tab-content px-1 pt-1 card-box-top card">
		                                                <div role="tabpanel" className="tab-pane active " id="accounts" aria-expanded="true" aria-labelledby="base-tab1"><Accounts /></div>
		                                                <div role="tabpanel" className="tab-pane" id="roles" aria-expanded="true" aria-labelledby="base-tab1"><Permissions /></div>
		                                            </div>
		                                        </div>
		                                    </section>
		                                </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    user_log: state.user_log
})

export default connect(mapStateToProps, {  })(Users);