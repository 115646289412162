import http from '../../../Services/api.laravel.http';

export const pickAndPackService = {
    getBatch: (formdata) => http.post('/admin/pick-and-pack/search', formdata),
    scanBatch: (formdata) =>
        http.post('/admin/pick-and-pack/pick/batch', formdata),
    scanSO: (formdata) => http.post('/admin/pick-and-pack/pack/so', formdata),
    scanProduct: (formdata) =>
        http.post('/admin/pick-and-pack/pick/item', formdata),
    batchChecker: (formdata) =>
        http.get('/admin/pick-and-pack/pick/batch-checker', formdata),
    soChecker: (formdata) =>
        http.get('/admin/pick-and-pack/pack/so-checker', formdata),
    ArchiveBatch: (formParams) =>
        http.post('/admin/pick-and-pack/archives/list', formParams),
    pickBatch: (formParams) =>
        http.post('/admin/pick-and-pack/pick/update', formParams),
    packSO: (formParams) =>
        http.post('/admin/pick-and-pack/pack/update', formParams),
};
