import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactImageMagnify from 'react-image-magnify';
import EmailLogsTable from './Email.logs.table'

function EmailLogs() {


    return (
        <div className="card card-p-200">
            <div className="card-header">
                <ReactTooltip  effect="solid" event="click"/>
                <h4 className="card-title">Logs  <a className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle text-primary"></i></a></h4>
            </div>
            
            <div className="card-body">
               <EmailLogsTable/>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    emailsetting: state.emailsetting
});


export default connect(mapStateToProps, { })(EmailLogs);