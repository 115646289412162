import React, { useEffect, Fragment } from "react";
import Spinner from "../../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import TermCondition from "./PaymentTerms/TermCondition";

function FormSettings (){
    
    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><a href="/settings/sales" className="text-white">Sales Invoice</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="what-is">
                                    <div className="card-content collapse show">
                                        <div className="card-body no-padding-top no-padding-left-right">
		                                     <section className="row">
		                                        <div className="col-sm-12">
		                                            <ul className="nav nav-tabs f-regular">
		                                                <li className=""><a  className="nav-link active" id="base-tab1" data-toggle="tab" aria-controls="tab1" href="#purcahse-orders" aria-expanded="true">Purchase Orders</a></li>
		                                                <li className=""><a  className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#quotations" aria-expanded="false">Quotations</a></li>
                                                        <li className=""><a  className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#sales-invoice" aria-expanded="false">Sales Invoice</a></li>
                                                        <li className=""><a  className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#item-receipts" aria-expanded="false">Item Receipts</a></li>
                                                        <li className=""><a  className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#deliverynvoice-receipts" aria-expanded="false">Delivery Receipts</a></li>
                                                        <li className=""><a  className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#adjustment-reports" aria-expanded="false">Adjustment Reports</a></li>
		                                            </ul>
		                                            <div className="tab-content px-1 pt-1 card-box-top card">
                                                        <div role="tabpanel" className="tab-pane active " id="purcahse-orders" aria-expanded="true" aria-labelledby="base-tab1"><center>--Under Maintenance--</center></div>
                                                        <div role="tabpanel" className="tab-pane" id="quotations" aria-expanded="true" aria-labelledby="base-tab1"><center>--Under Maintenance--</center></div>
                                                        <div role="tabpanel" className="tab-pane" id="sales-invoice" aria-expanded="true" aria-labelledby="base-tab1">
                                                            {/* <TermCondition /> */}
                                                            <center>--Under Maintenance--</center>
                                                        </div>
                                                        <div role="tabpanel" className="tab-pane" id="item-receipts" aria-expanded="true" aria-labelledby="base-tab1"><center>--Under Maintenance--</center></div>
                                                        <div role="tabpanel" className="tab-pane" id="deliverynvoice-receipts" aria-expanded="true" aria-labelledby="base-tab1"><center>--Under Maintenance--</center></div>
                                                        <div role="tabpanel" className="tab-pane" id="adjustment-reports" aria-expanded="true" aria-labelledby="base-tab1"><center>--Under Maintenance--</center></div>
		                                            </div>
		                                        </div>
		                                    </section>
		                                </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    // user_log: state.user_log
})

export default connect(mapStateToProps, {  })(FormSettings);