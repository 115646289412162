import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class AgentShowModal extends Component {
	
	render() {
		const { 
			singleAgent: {
				admin_name, 
				contact_number,
				admin_email,
				created_at, 
				updated_at 
			}, 
			show, 
			onHide 
		} = this.props;

		return (
	      <Modal 
	      	className="modal-container"
	      	show={show}
	      	size="sm"
	      	onHide={onHide}
	      	animation={true}
	      >
	        <Modal.Header closeButton>
	          <Modal.Title>Agent Information</Modal.Title>
	        </Modal.Header>
			
	        <Modal.Body>
	      		<div>
	      			<div className="mb-1"><strong>Name: </strong>{admin_name}</div>
	      			<div className="mb-1"><strong>Contact Number: </strong>{contact_number}</div>
	      			<div className="mb-1"><strong>Email: </strong>{admin_email}</div>
	      			<hr />
	      			<div><strong>Created at: </strong>{created_at}</div>
	      			<div><strong>Updated at: </strong>{updated_at}</div>
	      		</div>
	        </Modal.Body>
	        <Modal.Footer>
	             <Button 
	              	variant="danger btn-sm" 
	              	onClick={this.props.onHide}
	              >
		          	Close
		        </Button>
	        </Modal.Footer>
	      </Modal>
		)
	}
}


export default AgentShowModal