import * as TYPE from '../types';

const initialState = {
    single_setting_app: {
        company_name: '',
        company_address: '',
        company_email: '',
        company_landline: '',
        company_mobile: '',
        company_tin: '',
        additional_information: '',
        industry: "",
        payment_mode: { value: '', label: 'None' },
        payment_mode_options: [
            {value: 'cash', label: 'Cash'},
            {value: 'credit card', label: 'Credit Card'},
            {value: 'cod', label: 'COD'},
            {value: 'check', label: 'Check'},
            {value: 'bank transfer', label: 'Bank Transfer'},
        ],
        timezone: { value: 'Asia/Manila', label: 'Asia/Manila' },
        tax_type: { value: 'none', label: 'None' },
        tax_rate: 12,
        payment_terms: { value: 'none', label: 'None' },
        language: { value: 'English',  label: 'English (EN)'},
        currency: {
            currency_value: { value: '', label: 'Choose Currency' },
            currency_name: null,
            symbol: null,
            code: null,
        },
        logo: '',
        logo_preview: '',
    },
    taxes: [
        { value: 'none', label: 'None'},
        { value: 'exclusive', label: 'Exclusive'},
        { value: 'inclusive', label: 'Inclusive'},
    ],
    subsetting: null,
    currencies: [],
    languages: [],
    payment_terms: [],
    mini_loading: false,
    payment_terms_modal: false,
    upload_modal: false,
    show_crop: false
}

const settingAppReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status
            }
        case TYPE.SET_MINI_LOADING: {
            return {
                ...state,
                mini_loading: action.payload
            }
        }
        case TYPE.GET_SETTING_APP:
            return {
                ...state,
                single_setting_app: { ...state.single_setting_app, ...action.payload }
            }
        case TYPE.SETTING_APP_INPUT_CHANGE:
            return {
                ...state,
                single_setting_app: { ...state.single_setting_app, [action.payload.name]: action.payload.value }
            }
        case TYPE.SETTING_APP_UPLOAD_LOGO:
            return {
                ...state,
                single_setting_app: { ...state.single_setting_app, logo_preview: action.payload }
            }
        case TYPE.SETTING_APP_REMOVE_LOGO:
            return {
                ...state,
                single_setting_app: { ...state.single_setting_app, logo: '', logo_preview: '', }
            }
        case TYPE.GET_CURRENCIES:
            return {
                ...state,
                currencies: action.payload
            }
        case TYPE.SELECT_CURRENCY_RADIO:
            return {
                ...state,
                single_setting_app: { ...state.single_setting_app, currency: action.payload }
            }
        case TYPE.GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            }
        case TYPE.SA_PAYMENT_TERMS:
            return {
                ...state,
                payment_terms: action.payload
            }
        case TYPE.SETTING_APP_GLOBAL_SELECT:
            return {
                ...state,
                single_setting_app: { ...state.single_setting_app, [action.payload.name]: action.payload.value }
            }
        case TYPE.SET_SUB_SETTING:
            return {
                ...state,
                subsetting: action.payload
            }
        case TYPE.SHOW_CROP:
            return {
                ...state,
                show_crop: action.payload
            }
        default:
            return state;
    }
}

export default settingAppReducer;