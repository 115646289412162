import React from "react";
import { connect } from "react-redux";
import BlitzDefaultContent from "./BlitzDefault.content";
import StealthDR01 from "./Stealth-DR-01";
import MotoTruck from "./MotoTruckTemplate"
import { getSubSetting } from "../../../Utils/Common";

function DeliveryCustomTemplate({ delivery: { si_data }, auth, componentRef }) {
  const subs_setting = getSubSetting()
  const template_label = si_data.template_setting
    ? si_data.template_setting.template_label
    : "none";

  // console.log(si_data);

  return (
    <>
      {(() => {
        switch (template_label) {
          case "blitz_default":
            return (
              <>
                <BlitzDefaultContent
                  si_data={si_data}
                  auth={auth}
                  print='0'
                  print_rows='5'
                  subs_setting={subs_setting}
                />
                <div style={{ display: "none" }}>
                  <BlitzDefaultContent
                    si_data={si_data}
                    auth={auth}
                    ref={componentRef}
                    print='1'
                    print_rows='19'
                    subs_setting={subs_setting}
                  />
                </div>
              </>
            );
          case "stealth_dr_01":
            return (
              <>
                <StealthDR01
                  si_data={si_data}
                  auth={auth}
                  print='0'
                  print_rows='5'
                  subs_setting={subs_setting}
                />
                <div style={{ display: "none" }}>
                  <StealthDR01
                    si_data={si_data}
                    auth={auth}
                    ref={componentRef}
                    print='1'
                    print_rows='19'
                    subs_setting={subs_setting}
                  />
                </div>
              </>
            );
            case "mototruck":
              return (
                <>
                  <MotoTruck
                    si_data={si_data}
                    auth={auth}
                    print='0'
                    print_rows='5'
                    subs_setting={subs_setting}
                  />
                  <div style={{ display: "none" }}>
                    <MotoTruck
                      si_data={si_data}
                      auth={auth}
                      ref={componentRef}
                      print='1'
                      print_rows='19'
                      subs_setting={subs_setting}
                    />
                  </div>
                </>
              );
          default:
            return (
              <>
                <h4>-- There's No Data To Show--</h4>
              </>
            );
          // return (
          //   <>
          //     <StealthDR01
          //       si_data={si_data}
          //       auth={auth}
          //       print='0'
          //       print_rows='5'
          //     />
          //     <div style={{ display: "none" }}>
          //       <StealthDR01
          //         si_data={si_data}
          //         auth={auth}
          //         ref={componentRef}
          //         print='1'
          //         print_rows='19'
          //       />
          //     </div>
          //   </>
          // );
        }
      })()}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  delivery: state.delivery,
});

export default connect(mapStateToProps, {})(DeliveryCustomTemplate);
