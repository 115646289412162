import React, { useEffect } from 'react';
import { productUnitPost, handleInputChange } from '../../redux/actions/product_unit/product_unit.actions';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from "../../redux/actions/helper/helper.actions";


function ProductUnitCreateGlobal({ product_unit: { single_product_unit }, productUnitPost, handleInputChange, pageOrigin, block_route, blockRoute }) {

    let history = useHistory();

    useEffect(() => {
        blockRoute(false);
    },[])

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => window.history.pushState(null, null, window.location.pathname);

    return (
        <BeforeUnloadComponent 
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
                SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
            }}
        >
        <form onSubmit={e => productUnitPost(e, pageOrigin)}>
            <ReactTooltip  effect="solid" event="click"/>
            <section className="row f-regular">
                <div className="col-12">
                    <label className="label-control f-regular">Name: <span className='text-danger'>*</span></label>
                    <div className="form-group row">
                        <div className="col-md-11 col-10">
                            <input onChange={handleInputChange} type="text" id="name" name="name" className="form-control" placeholder="Name" required/>
                        </div>
                        <div className="float-right pl-0 col-1 col-md-1">
                            <div className="input-group-prepend">
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>  
                    </div>
                </div>
                {
                    pageOrigin == 'product_unit' && 
                    <div className="col-12">
                        <div className="form-group row">
                            <label className="col-md-4 col-12 label-control f-regular">Description: </label>
                            <div className="col-md-11 col-10">
                                <textarea onChange={handleInputChange} id="description" name="description" rows="3" className="form-control" placeholder="Description"></textarea>
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div>
                    </div>
                }
                <div className="col-12">
                    <div className="form-group row">
                        <div className="col-md-4 col-12 label-control f-regular"></div>
                        <div className="col-md-7 col-10 text-right">
                            <button type="submit" className="btn btn-primary btn-save f-regular">Save</button>
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1"></div>  
                    </div>
                </div>
            </section>
        </form>
        </BeforeUnloadComponent>
    )
}

const mapStateToProps = state => ({
	product_unit: state.product_unit,
    block_route: state.helper.block_route
})

export default connect(mapStateToProps, { productUnitPost, handleInputChange, blockRoute })(ProductUnitCreateGlobal);



