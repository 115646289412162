import React, { Fragment, useEffect, useCallback, useState } from 'react';
import Spinner, { MiniSpinner } from '../layouts/Spinner';
import { NumberFormat } from '../../Services/_numberformat.service';
import { setPage, getSingleCustomer, handleChangeTab, removeFile, updateFile, customerUpdate, uploadFiles } from '../../redux/actions/customer/customer.actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fileExtensionIcon } from '../../Utils/Common';
import QuotationTable from '../quotation/Quotation.table';
import SalesOrderTable from '../sales_order/SalesOrder.table';
import CustomerDateFilterGlobal from './Customer.datefilter.global';
import { Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import { handleHover } from '../../redux/actions/helper/helper.actions';
import { useParams } from 'react-router-dom';
import DropDownAttachFileGlobal from '../layouts/DropDownAttachFile.global';

function CustomerHistory({ customer:{ upload_loading, single_customer, main_url, mini_loading, edit_url, quotation_page, quotation_url, sales_invoice_page,
     sales_invoice_url, files_to_remove, new_uploads }, getSingleCustomer, handleChangeTab, removeFile, updateFile, uploadFiles, handleHover, isHover, hoverIndex }) {
    
    const { id } = useParams();

    const pageOrigin = {
        customer_id: id,
        origin: 'customer_history',
    }

    const [tab, setTab] = useState('quotations'); // set default tab 


    const onDrop = useCallback(acceptedFiles => {
        updateFile(acceptedFiles)
     }, [])
 
     const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
 

    useEffect(() => {
        getSingleCustomer(id, 'history');
    },[]);

    if(quotation_page) return <Redirect to={quotation_url}/>
    if(sales_invoice_page) return <Redirect to={sales_invoice_url}/>


    return (
        <div className="app-content content">
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Sales</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item"><a href={main_url} className="text-white">Customer</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">History</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <Link to={main_url}><i className="ft ft-arrow-left"></i> Back</Link>

                                        <div className="float-right">
                                            <Link to={`${edit_url}/${id}`} className="btn btn-sm btn-grey"><i className="ft ft-edit"></i></Link>
                                        </div>

                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body f-regular">
                                            {
                                                mini_loading ? <Spinner/> : 
                                                <Fragment>
                                                    <section className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-1"><span><strong>Name: </strong></span>{single_customer.name}</div>
                                                            <div className="mb-1"><span><strong>Business Name: </strong></span>{single_customer.business_name}</div>
                                                            <div className="mb-1"><span><strong>Street 1: </strong></span>{single_customer.street1 || single_customer.address}</div>
                                                            <div className="mb-1"><span><strong>Street 2: </strong></span>{single_customer.street2}</div>
                                                            <div className="mb-1"><span><strong>City: </strong></span>{single_customer.city}</div>
                                                            <div className="mb-1"><span><strong>State/Region: </strong></span>{single_customer.state}</div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="mb-1"><span><strong>Country: </strong></span>{single_customer.country ? single_customer.country.label : ''}</div>
                                                            <div className="mb-1"><span><strong>Fax: </strong></span>{single_customer.fax}</div>
                                                            <div className="mb-1"><span><strong>Email: </strong></span>{single_customer.email}</div>
                                                            <div className="mb-1"><span><strong>Landline: </strong></span>{single_customer.landline}</div>
                                                            <div className="mb-1"><span><strong>Mobile: </strong></span>{single_customer.mobile}</div>
                                                            <div className="mb-1"><span><strong>Contact Person: </strong></span>{single_customer.contact_person}</div>
                                                        </div>
                                                        
                                                        <div className="col-lg-4 col-sm-12 mb-2">
                                                            <DropDownAttachFileGlobal origin="customer" origin_data={single_customer} new_uploads={new_uploads} files_to_remove={files_to_remove}/>
                                                        </div>
                                                    </section>
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>   
                            <div className="col-12">
                                <div className="card-content collapse show">
                                    <div className="card-body no-padding-top no-padding-left-right">
                                    <ul className="nav nav-tabs">
                                        <li className="">
                                            <a onClick={() => setTab('quotations')} className="nav-link active" data-toggle="tab" href="#tab-quotations">Quotation</a>
                                        </li>
                                        <li className="">
                                            <a onClick={() => setTab('sales_orders')} className="nav-link" data-toggle="tab" href="#tabs-sales-invoices">Sales Orders</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content px-1 card card-box-top">
                                        <div role="tabpanel" className="tab-pane active" id="tab-quotations">
                                            {
                                                tab === 'quotations' && 
                                                <>
                                                    <CustomerDateFilterGlobal filterBy="quotations" pageOrigin={pageOrigin}/>
                                                    <QuotationTable pageOrigin={pageOrigin}/>
                                                </>  
                                            }                                        
                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="tabs-sales-invoices">
                                            {
                                                tab === 'sales_orders' && 
                                                    <>
                                                        <CustomerDateFilterGlobal filterBy="sales_orders"/>
                                                        <SalesOrderTable pageOrigin={pageOrigin}/> 
                                                    </> 
                                            }                  
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    customer: state.customer,
    isHover: state.helper.isHover,
    hoverIndex: state.helper.hoverIndex
});

export default connect(mapStateToProps,{ getSingleCustomer, setPage, handleChangeTab, removeFile, updateFile, uploadFiles, handleHover })(CustomerHistory);
