import http from "../../../Services/api.laravel.http";

export const SupplierService = {
  getSingleSupplier: (id) => http.get("/admin/supplier/" + id),
  ArchiveSupplier: (formParams) =>
    http.post("/admin/supplier/archives", formParams),
  supplierPost: (formParams) => http.post("/admin/supplier", formParams),
  paginateWithSearchSuppliers: (formParams) =>
    http.post("/admin/supplier/search", formParams),
  updateSingleSupplier: (formParams, id) =>
    http.put("/admin/supplier/" + id, formParams),
  supplieAcountDelete: (formParams) =>
    http.post("/admin/supplier/account/delete", formParams),
  supplieAcountUpdate: (formParams) =>
    http.post("/admin/supplier/account/update", formParams),
};

// Delete this

// getPurchaseOrdersPaginate: (formParams) => http.post('/admin/supplier/get/purchase-orders', formParams),
// getProductsPaginate: (formParams) => http.post('/admin/supplier/get/products', formParams),
// paginateWithSearchPurchaseOrderDateFilter: (formParams) => http.post('/admin/supplier/purchase-orders/date-filter', formParams),
