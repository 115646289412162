import React from 'react';
import { Card } from 'react-bootstrap';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    getPickAndPack,
    scanSO,
    soChecker,
    handleInputChange,
    scanProduct,
    handleSubmit,
} from '../../redux/actions/pick_and_pack/pick_and_pack.actions';
import SoPrint from './print/SoPrint'; // Import the new component

function Packing({
    so_data,
    search,
    handleInputChange,
    scanProduct,
    handleSubmit,
    loading,
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <section id="with-other-options" style={{ flex: '1' }}>
                <div className="d-flex justify-content-between align-items-center p-1">
                    <p className="text-uppercase mb-0">Scan Sales Order #</p>
                    <h5 className="text-uppercase border border-dark p-1 mb-0">
                        {so_data.so_no}
                    </h5>
                </div>
                <div className="row match-height">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <Card>
                            <Card.Header>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="d-flex flex-column">
                                        {/* Use SoPrint component here */}
                                        <SoPrint so_data={so_data} />
                                    </div>
                                    <div className="heading-elements">
                                        <p className="mb-0">
                                            Total Items
                                            <div className="text-center">
                                                {
                                                    so_data.sales_order_items
                                                        .length
                                                }{' '}
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </Card.Header>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <Card.Body
                                        style={{
                                            border: '1px solid #ddd',
                                            borderRadius: '5px',
                                            padding: '15px',
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {so_data.sales_order_items &&
                                        so_data.sales_order_items.length > 0 ? (
                                            <div className="item-list">
                                                <div className="row mb-2 text-center">
                                                    <div className="col-4">
                                                        <strong>Product</strong>
                                                    </div>
                                                    <div className="col-4">
                                                        <strong>Qty</strong>
                                                    </div>
                                                    <div className="col-4">
                                                        <strong>sku</strong>
                                                    </div>
                                                </div>
                                                {so_data.sales_order_items.map(
                                                    (item, index) => (
                                                        <div
                                                            key={index}
                                                            className="row mb-2 text-center"
                                                            style={{
                                                                color:
                                                                    item.pick_status ===
                                                                    'picked'
                                                                        ? 'green'
                                                                        : 'red',
                                                            }}
                                                        >
                                                            <div className="col-4 font-weight-bold">
                                                                {
                                                                    item.product_name
                                                                }
                                                            </div>
                                                            <div className="col-4">
                                                                {item.qty}
                                                            </div>
                                                            <div className="col-4">
                                                                {item.sku}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <p className="text-center">
                                                No items in this sales order.
                                            </p>
                                        )}
                                    </Card.Body>
                                </>
                            )}
                        </Card>
                    </div>
                </div>
            </section>

            <footer
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#f8f9fa',
                    width: '100%',
                }}
            >
                <button
                    className="btn btn-primary pick-btn w-100 mt-2"
                    onClick={() => handleSubmit(so_data._id, 'so')}
                >
                    Complete
                </button>
            </footer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    pick_and_pack: state.pick_and_pack,
});

export default connect(mapStateToProps, {
    getPickAndPack,
    scanSO,
    soChecker,
    handleSubmit,
})(Packing);
