import React, { Fragment, useEffect } from "react";
import Pagination from "react-js-pagination";
import Spinner from "../layouts/Spinner";
import { connect } from 'react-redux';
import { exportProduct, getProducts, handleSearchInput, setPage, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox } from '../../redux/actions/product/product.actions';
import ReactTooltip from 'react-tooltip';
import { Redirect, useHistory} from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { formattedDate } from "../../Utils/Common";
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';

function Product ({ product: { products, loading, pagination, search, create_url, edit_page, edit_url, delete_modal, export_url, sort_order_direction, sort_order_name }, getProducts, handleSearchInput, setPage, exportProduct, sortTableByColumn, handleSearchInputMUI,getSelectedRows, confirmDeleteDialogBox}) {

  

  const { hasPermission } = useAccess();
  const canCreate = hasPermission("inv.products.items.create");
  const canDelete = hasPermission("inv.products.items.delete");
  const canEdit = hasPermission("inv.products.items.edit");
  const canview = hasPermission("inv.products.items.view");

  const history = useHistory();
    useEffect(() => {
      if(canview)
      {
        getProducts();
      }
    },[search])

    if(edit_page) return <Redirect to={edit_url}/>
    const changePage = (link) => {
      history.push(link);
  }


  const getMuiTheme = () => createMuiTheme({
      overrides: {
          MUIDataTable: {
              root: {},
              paper: {
              boxShadow: "none",
              }
          },
          MUIDataTableBodyRow: {
              root: {
              '&:nth-child(odd)': { 
                  backgroundColor: "rgba(0,0,0,.05)"
              }
              }
          },
          MUIDataTableBodyCell: {}
      }
  });

  const columns = [
      {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
      {name: 'sku', label: 'SKU', options : {},}, 
      {name: 'product_name', label: 'Product Name', options : {}, },
      {name: 'product_unit', label: 'Unit' , options : {},},
      {name: 'srp', label: 'SRP' , options : {} },
      { name: '', label: 'Action', options: { filter: false, sort: false, print: false, download: false, 
              customBodyRender: (value, tableMeta, updateValue) => {
                  let id = products[tableMeta['rowIndex']]._id;

                  return (    
                      <div className="btn-group">
                          <Link to={`${edit_url}/${id}`} className="btn btn-sm btn-grey"><i className="ft ft-edit"></i></Link>	
                          {/* <button className="btn btn-sm btn-danger" onClick={() => setPage('delete_modal', quotation._id)}><i className="ft ft-trash"></i></button> */}
                      </div>
                  )
              },
              setCellProps: (value) => {
                  return {className : "text-center"}
              }
          }
      }
    ];

  const options = {
      serverSide: true,
      jumpToPage: true,
      filterType: 'dropdown',
      responsive: 'standard',
      search: false,
      searchOpen: true,
      rowsPerPageOptions: [10,20,50,100],
      rowsPerPage: pagination.itemsCountPerPage,
      count: pagination.totalItemsCount,
      page: pagination.activePage - 1,
      sortOrder: {
          name: sort_order_name,
          direction: sort_order_direction
      },
      downloadOptions: {
          filename: `products_${formattedDate()}.csv`,
      },
      textLabels: {
          body: {
              noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
          }
      },
      onChangeRowsPerPage: (numberOfRows) => {
        getProducts(null, numberOfRows)
      },
      onTableChange: (action, tableState) => {
          switch (action) {
              case 'changePage':
                getProducts(tableState.page + 1, pagination.itemsCountPerPage);
                  break;
              case 'sort':
                  // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                  let column_name = tableState.sortOrder.name;
                  let direction = tableState.sortOrder.direction;
                  sortTableByColumn(column_name, direction);
                  break;
              case 'search': 
                  // handleSearchInputMUI(tableState.searchText);
                  break;
              case 'rowSelectionChange':
                  let selectedRows = tableState.selectedRows.data;
                  let currentRows = tableState.data
                  getSelectedRows(selectedRows, currentRows);
                  break;
              default:
              console.log('action not handled.');
          }
      },
      onRowsDelete: () => {
          // Confirm Delete Modal
          if(canDelete)
          {
            confirmDeleteDialogBox();
          }
          return false;
      },
      onRowClick: (rowData, rowMeta) => {
          // redirect to edit page    
          if(canEdit)
          {
            changePage(`${edit_url}/${rowData[0]}`)
          }
      },
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (    
            <div className="custom-search">
               <i className="ft ft-search"></i> <input type="text" name="search" onChange={handleSearchInputMUI} className="custom-search-input"/>
            </div>
        )
      }
  };

  if(!canDelete)
  {
      options.selectableRows = false;
  }
 
    return (

      <Fragment>  
        <Redirect to="/inventory/products"/>
        <div className="app-content content">
        <ReactTooltip  effect="solid" event="click"/>
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Products</h3>
                        <div className="breadcrumbs-top d-inline-block f-regular">
                            <div className="breadcrumb-wrapper mr-1.5">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><a href="/brand" className="text-white">Items</a></li>
                                </ol>
                            </div>
                        </div>
                        <Show when="inv.products.items.create">
                            <Link to={create_url} className="btn btn-primary pull-right btn-fix-header"><i className="ft ft-plus"></i> Create New</Link>
                        </Show>
                    </div>
                </div>
              <div className="content-body margin-top-5">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card">
                              <div className="card-header">
                                  <h4 className="card-title">List Of Items <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                  <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                                  <div className="pull-right">
                                      <Show when="inv.products.items.export">
                                        <a onClick={exportProduct} className="btn btn-sm btn-success text-white f-regular"><i className="ft ft-download"></i> Export</a>
                                      </Show>
                                  </div>
                              </div>
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                    <Show when="inv.products.items.view">
                                      <MuiThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable data={products} columns={columns} options={options}/>
                                      </MuiThemeProvider> 
                                    </Show>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
        </div>
      </Fragment>    
    );
}

const mapStateToProps = state => ({
  product: state.product
})

export default connect(mapStateToProps, { getProducts, handleSearchInput, setPage, exportProduct, sortTableByColumn, handleSearchInputMUI,getSelectedRows, confirmDeleteDialogBox})(Product)