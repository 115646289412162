import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    getPickAndPack,
    scanBatch,
    batchChecker,
    handleInputChange,
} from '../../redux/actions/pick_and_pack/pick_and_pack.actions';
import Spinner from '../layouts/Spinner';
import ScanBatch from './modal/ScanBatch';
import Picking from './Picking';

function Pick({
    pick_and_pack: { batch_list, search, batch_data, loading },
    getPickAndPack,
    scanBatch,
    batchChecker,
    handleInputChange,
}) {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        document.body.style.paddingTop = '0';

        return () => {
            document.body.style.paddingTop = '5rem';
        };
    }, []);

    useEffect(() => {
        batchChecker();
    }, []);

    useEffect(() => {
        if (!batch_data) {
            setShowModal(true); // Show modal when batch_data is empty
        } else {
            setShowModal(false); // Close modal when batch_data is not empty
        }
    }, [batch_data]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <main className="pick-main">
            <section>
                <div className="row pick-row">
                    <div className="col-12 pick-col">
                        {/* Render the ScanBatch modal */}
                        <ScanBatch
                            show={showModal}
                            search={search}
                            handleInputChange={handleInputChange}
                            scanBatch={scanBatch}
                        />
                    </div>
                </div>
            </section>

            {/* Render the Picking component and pass batch_data */}
            {batch_data && (
                <Picking
                    batch_data={batch_data}
                    search={search}
                    loading={loading}
                />
            )}
        </main>
    );
}

const mapStateToProps = (state) => ({
    pick_and_pack: state.pick_and_pack,
});

export default connect(mapStateToProps, {
    getPickAndPack,
    scanBatch,
    batchChecker,
    handleInputChange,
})(Pick);
