import {
    SET_MINI_LOADING,
    SET_LOADING,
    GET_PURCHASE_ORDERS,
    PURCHASE_ORDER_INPUT_CHANGE,
    GET_SINGLE_PURCHASE_ORDER,
    SET_PAGE,
    CLEAR_PURCHASE_ORDER,
    SEARCH_INPUT,
    GET_PO_SUPPLIERS,
    PO_GET_SUPPLIER_DATA,
    PO_GET_SUPPLIER_ITEMS,
    GET_PO_PAYMENT_TERMS,
    HANDLE_PO_SELECT_SUPPLIER,
    HANDLE_PO_COST,
    HANDLE_PO_QTY,
    HANDLE_PO_SELECT_ITEM,
    HANDLE_PO_ADD_ITEM,
    HANDLE_PO_REMOVE_ITEM,
    HANDLE_PO_INPUT_ATTR,
    HANDLE_PO_CHECK_TAX,
    HANDLE_PO_SELECT_PAYMENT_TERMS,
    HANDLE_PO_TOGGLE_EDIT_BTN_ATTR,
    HANDLE_PO_TOGGLE_EDIT_ROW,
    HANDLE_PO_EDIT_INPUT_ROW,
    HANDLE_PO_EDIT_UPDATE_ROW,
    HANDLE_PO_SUBMIT_ATTR,
    HANDLE_PO_CHECK_VAT,
    SET_PO_ITEM_ID,
    GET_PO_SUPPLIERS_DEFAULT,
    SET_MODAL,
    SET_ROW_CHECKED,
    GET_ROWS_TO_DELETE,
    SORT_BY_COLUMN,
    HANDLE_PO_SELECT_PAYMENT_MODE,
    GET_PO_TEMPLATES,
    HANDLE_SELECT_PO_TEMPLATE,
    SET_DATE_PICKER,
    SET_PO_ITEMS,
    PO_TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    PO_ITEMS_TEMPLATE,
    PO_CLEAR_UPLOAD,
    PO_SELECT_TAX_TYPE,
    PO_COMPUTATION_HANDLER,
    SEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    RESEND_EMAIL,
    SET_CHECKED,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    PASSWORD_VERIFY_MODAL,
    FILE_COUNT,
    CLEAR_DATA,
    BTN_FORM,
    GET_SINGLE_PRODUCT,
    GET_STATUS,
    GET_PO_STATUS
} from '../../types';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
    ToastQuestion,
} from '../../../Services/_toast.service';
import $ from 'jquery';
import { PurchaseOrderService } from './_service.purchase_order';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2';
import {
    SwalWarning,
    SwalError,
    SwalLoading,
    SwalSuccessAutoRemove,
    SwallverfiyPass,
    SwalWarningHtml,
} from '../../../Services/_swal.service';
import { Switch } from '@material-ui/core';
import {
    blockRoute,
    muiDataSetting,
    setMUIPage,
} from '../helper/helper.actions';
import { getNumRows, getSubSetting, getUserData } from '../../../Utils/Common';
import { getSettingApp, subSetting } from '../settings_app/setting_app.actions';

// DISCOUNT
export const handleSelectDiscountType = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: PURCHASE_ORDER_INPUT_CHANGE,
        payload: { key: 'discount', value: 0 },
    });
    dispatch({
        type: PURCHASE_ORDER_INPUT_CHANGE,
        payload: { key: 'discount_type', value: selectOption },
    });
};
export const handleInputChangeDiscount = (e) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { single_purchase_order } = getState().purchase_order;

    if (single_purchase_order.discount_type.value == 'percent') {
        if (e.target.value > 100) {
            ToastDanger(
                'If your discount is percentage, discount should not be over 100'
            );
            dispatch({
                type: PURCHASE_ORDER_INPUT_CHANGE,
                payload: { key: e.target.name, value: 100 },
            });
        } else if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: PURCHASE_ORDER_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: PURCHASE_ORDER_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    } else {
        if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: PURCHASE_ORDER_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: PURCHASE_ORDER_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    }
};

export const handleDiscountItem =
    (index, remove = false) =>
    async (dispatch, getState) => {
        let { single_purchase_order } = getState().purchase_order;
        let items = { ...single_purchase_order.purchase_order_items[index] };

        if (!items.product_name) {
            ToastDanger('Please add Product item at least one.');
            return;
        }

        if (remove) {
            items['discount'] = 0;
            items['discount_type'] = { value: 'none', label: 'None' };
            items['discount_amount'] = 0;
            items['amount'] = items.cost * items.qty;
        }

        single_purchase_order.purchase_order_items[index] = items;

        dispatch({
            type: SET_PO_ITEMS,
            payload: single_purchase_order.purchase_order_items,
        });

        dispatch({
            type: PO_TOGGLE_DISCOUNT_ROW,
            payload: { index, remove },
        });
    };

export const handleInputDiscountProduct =
    (e, index) => async (dispatch, getState) => {
        let { name, value } = e.target;
        value = parseFloat(value);
        let { single_purchase_order } = getState().purchase_order;
        let items = { ...single_purchase_order.purchase_order_items[index] };

        // // Save original amount if not already saved
        // if (!items.hasOwnProperty('original_amount')) {
        //   items['original_amount'] = items.cost;
        // }

        let discountedAmount = 0;
        let discountAmount = 0;

        items.amount = items.cost * items.qty;

        if (value === '0') {
        } else if (items.discount_type.value === 'percent') {
            discountAmount = (value / 100) * items.amount;
            discountedAmount = items.amount - discountAmount;
            items.amount = discountedAmount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = value;
            discountedAmount = items.amount - value;
            items.amount = discountedAmount;
        }

        items[name] = parseFloat(value);
        items['discount_amount'] = discountAmount ? discountAmount : 0;

        single_purchase_order.purchase_order_items[index] = items;
        dispatch({
            type: SET_PO_ITEMS,
            payload: single_purchase_order.purchase_order_items,
        });
    };

export const handleSelectDiscountTypeProduct =
    (selectOption, index) => async (dispatch, getState) => {
        let { single_purchase_order } = getState().purchase_order;
        let items = { ...single_purchase_order.purchase_order_items[index] };

        // Reset discount amount when changing discount type to 'amount'
        if (selectOption.value === 'amount' || 'percent' || 'none') {
            items.discount_amount = 0;
            items.amount = items.cost * items.qty;
        }

        items.discount_type = selectOption;
        items.discount = 0;

        single_purchase_order.purchase_order_items[index] = items;
        dispatch({
            type: SET_PO_ITEMS,
            payload: single_purchase_order.purchase_order_items,
        });
    };

export const handleChangeStatusView =
    (status_view) => async (dispatch, getState) => {
      dispatch({ type: GET_STATUS, payload: status_view });
  
      let {  search, purchase_orders } = getState().purchase_order;
  
      if (
        typeof purchase_orders[status_view] === "undefined" ||
        purchase_orders[status_view] == null
      ) {
        dispatch({ type: SEARCH_INPUT, payload: "" });
        dispatch(getPurchaseOrders(1));
      } else {
        dispatch({
          type: SEARCH_INPUT,
          payload: purchase_orders[status_view].search,
        });
        dispatch({
          type: GET_PURCHASE_ORDERS,
          payload: {
            purchase_orders: purchase_orders,
            pagination: purchase_orders[status_view],
          },
        });
      }
    };

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: PURCHASE_ORDER_INPUT_CHANGE,
        payload: { key: e.target.name, value: e.target.value },
    });
};

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    if (e.key === 'Enter') {
        //console.log("enter");
        // dispatch(getPurchaseOrders(1));
    }
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
    // console.log(e.target.value);
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    dispatch(getPurchaseOrders(1));
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearPurchaseOrder = () => async (dispatch) =>
    dispatch({ type: CLEAR_PURCHASE_ORDER });

// set page
export const setPage =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().purchase_order;

        switch (page) {
            case 'main_page':
                dispatch({ type: SET_PAGE, payload: { page, status } });
                dispatch(clearPurchaseOrder());
                break;
            case 'edit_page':
                let edit_url_with_id = `${edit_url}/${id}`;
                dispatch({
                    type: SET_PAGE,
                    payload: { page, status, edit_url: edit_url_with_id },
                });
                break;
            case 'delete_modal':
                dispatch(getSinglePurchaseOrder(id, 'delete'));
                break;
            case 'print_modal':
                if (status) {
                    dispatch(getSinglePurchaseOrder(id));
                } else {
                    dispatch(poClearUpload());
                }
                dispatch({ type: SET_PAGE, payload: { page, status } });
                break;
            case 'password_verify_modal':
                dispatch({ type: PASSWORD_VERIFY_MODAL, payload: false });
                break;
            default:
            // nothing
        }
    };

// Clear Upload in reducer
export const poClearUpload = () => async (dispatch) =>
    dispatch({ type: PO_CLEAR_UPLOAD });

// create selectOptions use in suppliers
const createSelectOption = (lists) => {
    let selectOptions = [];

    lists.map((val, i) => {
        let select = { value: val._id, label: val.name, i: i };

        selectOptions[i] = select;
    });

    return selectOptions;
};

export const SearchSupplier = (search) => async (dispatch) =>
    new Promise((resolve, reject) => {
    const formdata = {
        search: search,
    };

    // MODIFIED TO CANCEL PREVIOuS REQUEST PER SEARCH - ASK LUKE FOR ASSISTANCE
    PurchaseOrderService.getSupplier(formdata)
        .then((res) => {
            let supplier_arr = [];
            let suppliers = res.data.suppliers;

            suppliers.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.name,
                    name: 'supplier_id',
                };
                supplier_arr[i] = select;
            });
            resolve(supplier_arr);
            // dispatch({ type: GET_SI_CUSTOMERS, payload: supplier_arr });
        })
        // .then((res) => {
        //     // console.log(res.data.suppliers.data);
        //     let createOption = createSelectOption(res.data.suppliers.data);
        //     dispatch({ type: GET_PO_SUPPLIERS, payload: createOption });
        //     dispatch(setLoading(false));
        //     callback(createOption);
        //     return createOption;
        // })
        .catch((err) => {
            console.log(err);
            reject(err);
        });
});

// Handle Select Supplier
export const handleSelectSupplier =
    (selectOption, supplier_item_only = null) =>
    async (dispatch) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        // console.log(supplier_id, supplier, items);
        dispatch({ type: HANDLE_PO_SELECT_SUPPLIER, payload: selectOption });

        if (supplier_item_only == false) {
            dispatch({ type: SET_CHECKED, payload: supplier_item_only });
        }

        dispatch(getDisplaySupplier(selectOption.value));
        dispatch(getItemSupplier(selectOption.value, true, supplier_item_only));
    };

// get the supplier
const getDisplaySupplier = (id) => async (dispatch) => {
    PurchaseOrderService.getDisplaySupplier(id)
        .then((res) => {
            dispatch({
                type: PO_GET_SUPPLIER_DATA,
                payload: res.data.supplier,
            });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                ToastDanger(error.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            console.log(error);
        });
};

// get the supplier's items
const getItemSupplier =
    (id, _new = false, supplier_item_only = null) =>
    async (dispatch) => {
        dispatch(setLoading(true));
        PurchaseOrderService.getItemSupplier(id)
            .then((res) => {
                dispatch(setLoading(false));
                let items = res.data.products;

                if (!items) {
                    ToastWarning(
                        'Supplier does not have item in the system. please add at least one item.'
                    );

                    let items_arr = []
                    dispatch({type : SET_PO_ITEMS, payload : items_arr});
                } else {
                    let items_arr = [];
                    items = []
                    // console.log("items", items);
                    items.map((item, i) => {
                        if (item.product != null) {
                            let attribute = '';
                            try {
                                if (
                                    item.product.attribute_string == '()' ||
                                    item.product.attribute_string == null ||
                                    item.product.attribute_string == ''
                                ) {
                                    attribute = '';
                                } else {
                                    let attr_replace_char =
                                        item.product.attribute_string
                                            .replace('(', '')
                                            .replace(')', '');
                                    attribute =
                                        attr_replace_char.slice(0, 40) +
                                        (attr_replace_char.length > 40
                                            ? '...'
                                            : '');
                                }
                            } catch (err) {}

                            let select = {
                                _id: item.product._id,
                                value: item.product._id,
                                label: item.product.product_name,
                                sub_label: attribute,
                                barcode: item.product.barcode,
                                brand: item.product.brand,
                                product_name: item.product.product_name,
                                product_id: item.product._id,
                                sku: item.product.sku,
                                qty: 1,
                                product_unit_id: item.product.product_unit_id
                                    ? item.product.product_unit_id
                                    : null,
                                product_unit_name: item.product.product_unit
                                    ? item.product.product_unit.name
                                    : '',
                                attribute_string:
                                    item.product.attribute_string == '()'
                                        ? null
                                        : item.product.attribute_string,
                                cost: item.cost,
                                amount: item.cost,
                                description: item.product.description,
                                attribute_template: item.product.attribute_template,
                                lot_no:
                                    item.single_lot != null &&
                                    item.single_lot != undefined
                                        ? item.single_lot.lot_no
                                        : '',
                                status_code:
                                    item.product.status_code != null
                                        ? item.product.status_code
                                        : 0,
                                i: i,
                            };

                            items_arr[i] = select;
                        }
                    });

                    // console.log(items_arr);
                    //console.log("here");
                    dispatch({
                        type: PO_GET_SUPPLIER_ITEMS,
                        payload: items_arr,
                    });
                    // dispatch({type : SET_PO_ITEMS, payload : items_arr});
                    dispatch(set_items(items_arr, _new));
                }
            })
            .catch((error) => {
                // if (error.data.errors.length > 0) {
                //     ToastDanger(error.data.errors);
                // } else {
                //     ToastDanger('Network error, please reload the page.');
                // }

                console.log(error);
            });
    };

export const searchProduct =
    (search, index, new_item_template) => (dispatch, getState) =>
        new Promise((resolve, reject) => {
            // do something was a success
            let { single_purchase_order, supplier_item_only } =
                getState().purchase_order;

            const formdata = {
                search: search,
                supplier_id: single_purchase_order.supplier_id,
                supplier_item_only: supplier_item_only,
            };
            PurchaseOrderService.getProducts(formdata)
                .then((res) => {
                    let items_arr = [];
                    let items = res.data.supplierItems;

                    items.map((item, i) => {
                        let attribute = '';
                        if (
                            item.attribute_string == '()' ||
                            item.attribute_string == null ||
                            item.attribute_string == ''
                        ) {
                            attribute = '';
                        } else {
                            let attr_replace_char = item.attribute_string
                                .replace('(', '')
                                .replace(')', '');
                            attribute =
                                attr_replace_char.slice(0, 40) +
                                (attr_replace_char.length > 40 ? '...' : '');
                        }

                        let select = {
                            _id: item._id,
                            value: item._id,
                            label: item.product_name,
                            sub_label: attribute,
                            barcode: item.barcode,
                            product_name: item.product_name,
                            sku: item.sku,
                            qty: 1,
                            product_unit_id: item.product_unit_id
                                ? item.product_unit_id
                                : null,
                            product_unit_name: item.product_unit
                                ? item.product_unit.name
                                : '',
                            attribute_string:
                                item.attribute_string == '()'
                                    ? null
                                    : item.attribute_string,
                            srp: item.srp,
                            cost: item.cost,
                            amount: item.cost,
                            description: item.description,
                            lot_no: '',
                            status_code: item.status_code,
                            i: i,
                            description: item.description,
                            brand: item.brand,
                            category: item.category,
                            attribute_template: item.attribute_template
                        };

                        items_arr.push(select);
                    });

                    resolve(items_arr);
                })
                .catch((error) => {
                    if (error.data.errors.length > 0) {
                        ToastDanger(error.data.errors);
                    } else {
                        ToastDanger('Network error, please reload the page.');
                    }
                    console.log(error);
                    reject();
                });

            // do something was a fail
        });

// handle Cost
export const handleInputCost = (e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { itemSelected } = getState().purchase_order;

    let cost = parseFloat(e.target.value);
    let amount = itemSelected.qty * cost;

    dispatch({ type: HANDLE_PO_COST, payload: { cost: cost, amount: amount } });
};

// handle Qty
export const handleInputQty = (e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { itemSelected } = getState().purchase_order;
    let multipliedPrice = e.target.value * itemSelected.cost;

    dispatch({
        type: HANDLE_PO_QTY,
        payload: { qty: e.target.value, amount: parseFloat(multipliedPrice) },
    });
};

// handle vat
export const handleCheckVat = (e) => async (dispatch) => {
    if (e.target.checked) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let checked;

    if (e.target.checked) {
        checked = 12;
    } else {
        checked = 0;
    }

    dispatch({ type: HANDLE_PO_CHECK_VAT, payload: checked });
};

// handle edit row
export const handleToggleEditRow = (index) => async (dispatch) => {
    dispatch({ type: HANDLE_PO_TOGGLE_EDIT_ROW, payload: index });
};

// handle input edit row
export const handleEditInputRow = (index, e) => async (dispatch, getState) => {
    e.preventDefault();

    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { edit_purchase_order_items } = getState().purchase_order;
    let name = e.target.name;
    edit_purchase_order_items[index][name] = parseFloat(e.target.value);

    dispatch({
        type: HANDLE_PO_EDIT_INPUT_ROW,
        payload: edit_purchase_order_items,
    });
};

// handle update row
export const handleUpdateRow = (index) => async (dispatch, getState) => {
    let { edit_purchase_order_items } = getState().purchase_order;

    let qty = edit_purchase_order_items[index].qty;
    let cost = edit_purchase_order_items[index].cost;

    if (qty == 0 || isNaN(qty)) {
        ToastDanger('Qty is required and cannot be a string value');
    } else if (cost == 0 || isNaN(cost)) {
        ToastDanger('Cost is required and cannot be a string value');
    } else {
        let updated_amount = cost * qty;
        edit_purchase_order_items[index].amount = updated_amount;

        dispatch({
            type: HANDLE_PO_EDIT_UPDATE_ROW,
            payload: edit_purchase_order_items,
        });
    }
};

// handle Select Item
export const handleSelectItem = (selectOption) => async (dispatch) => {
    let item_selected = {
        _id: selectOption._id,
        sku: selectOption.sku,
        product_name: selectOption.product_name,
        qty: parseInt(selectOption.qty),
        product_unit_id: selectOption.product_unit_id,
        product_unit_name: selectOption.product_unit_name,
        attribute_string: selectOption.attribute_string,
        cost: parseFloat(selectOption.cost),
        amount: parseFloat(selectOption.amount),
    };

    let default_product_value = {
        value: selectOption._id,
        label: selectOption.product_name,
    };

    dispatch({
        type: HANDLE_PO_SELECT_ITEM,
        payload: {
            item_selected: item_selected,
            default_product_value: default_product_value,
        },
    });
};

// handle Add Item
export const handleAddItem = (e) => async (dispatch, getState) => {
    e.preventDefault();

    let { itemSelected, single_purchase_order } = getState().purchase_order;

    const duplicate = single_purchase_order.purchase_order_items.filter(
        (item) => item._id == itemSelected._id
    );

    if (duplicate.length > 0) {
        ToastDanger(`${duplicate[0].product_name} is already on the list.`);
    } else if (itemSelected.product_name == '') {
        ToastDanger('Product Item is required, please select at least one');
    } else if (itemSelected.qty == 0 || isNaN(itemSelected.qty)) {
        ToastDanger('Quantity field is required and cannot be a string value');
    } else if (itemSelected.cost == 0 || isNaN(itemSelected.cost)) {
        ToastDanger('Cost is required and cannot be a string value');
    } else {
        let purchase_order_items =
            single_purchase_order.purchase_order_items.concat(itemSelected);

        dispatch({
            type: HANDLE_PO_ADD_ITEM,
            payload: { purchase_order_items: purchase_order_items },
        });
    }
};

// Handle Remove Item
// export const handleRemoveItem = (index) => async (dispatch, getState) => {
//     let { purchase_order_items } =
//         getState().purchase_order.single_purchase_order;

//     // Get the removed item
//     const removedItem = purchase_order_items[index];

//     // removing value in array base on index
//     let _purchase_order_items = purchase_order_items.filter(
//         (_, i) => i !== index
//     );

//     // Update the items array for all the other items
//     _purchase_order_items.forEach((item) => {
//         if (item.items) {
//             item.items.push({
//                 label: removedItem.product_name,
//                 value: removedItem._id,
//             });
//         }
//     });

//     dispatch({
//         type: HANDLE_PO_REMOVE_ITEM,
//         payload: { purchase_order_items: _purchase_order_items },
//     });
// };

export const handleRemoveItem = (index) => async (dispatch, getState) => {
    let { purchase_order_items } = getState().purchase_order.single_purchase_order;
    // removing value in array base on index
    let _purchase_order_items = purchase_order_items.filter((_, i) => i !== index);
  
    dispatch({
      type: HANDLE_PO_REMOVE_ITEM,
      payload: { purchase_order_items: _purchase_order_items },
    });
  };

// handle select payment terms
export const handleSelectPaymentTerms =
    (selectOption) => async (dispatch, getState) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        dispatch({
            type: HANDLE_PO_SELECT_PAYMENT_TERMS,
            payload: selectOption,
        });
    };

// handle discount
export const handleInputAttr = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: HANDLE_PO_INPUT_ATTR,
        payload: { key: e.target.name, value: parseInt(e.target.value) },
    });
};

// handle toggle edit attr true
export const toggleEditBtn = (status, attr) => async (dispatch) => {
    dispatch({
        type: HANDLE_PO_TOGGLE_EDIT_BTN_ATTR,
        payload: { attr: attr, status: status },
    });
};

// handle discount submit
export const handleAttrSubmit = (attr) => async (dispatch, getState) => {
    let { edit_attr } = getState().purchase_order;
    let attr_val = edit_attr[attr];

    if (isNaN(attr_val)) {
        ToastDanger('Field required at least 0 value');
    } else {
        dispatch({
            type: HANDLE_PO_SUBMIT_ATTR,
            payload: { key: attr, value: attr_val },
        });
    }
};

// get all the select options
export const getSupplier = (search = "") => async (dispatch) => {
    const formdata = {
        search: search,
    };
    PurchaseOrderService.getSupplier(formdata)
        .then((res) => {
            let createOption = createSelectOption(res.data.suppliers);

            dispatch({ type: GET_PO_SUPPLIERS, payload: createOption });
            dispatch({ type: GET_PO_SUPPLIERS_DEFAULT, payload: createOption });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                ToastDanger(error.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            console.log(error);
        });
};

// get all the payment terms
export const getPaymentTerms = () => async (dispatch) => {
    PurchaseOrderService.getPaymentTerms()
        .then((res) => {
            let payment_terms_arr = [];
            let payment_terms =
                res.data.payment_terms || res.data.terms_conditions;

            payment_terms.map((val, i) => {
                let select = { value: val.content, label: val.title };
                payment_terms_arr[i] = select;
            });

            payment_terms_arr.unshift({ value: '', label: 'None' });

            dispatch({
                type: GET_PO_PAYMENT_TERMS,
                payload: payment_terms_arr,
            });
        })
        .catch((error) => {
            // if (error.data.errors.length > 0) {
            //   ToastDanger(error.data.errors);
            // } else {
            //   ToastDanger("Network error, please reload the page.");
            // }
            console.log(error);
        });
};

//  fetch
export const getPurchaseOrders =
    (pageNumber, rows_per_page = null, pageOrigin = null, date_filter = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));

        let { search, pagination, pagination_list, status_view, sort_order_name, sort_order_direction } =
            getState().purchase_order;
        let rows_per_page_val =
            rows_per_page !== null
                ? rows_per_page
                : getNumRows('purchase_order');

        // let params = {
        //     search: search,
        //     activePage: pagination.activePage,
        //     page: pageNumber,
        //     rows_per_page: rows_per_page_val,
        //     sort_order_name: sort_order_name,
        //     sort_order_direction: sort_order_direction
        // }

        /// new search function
        let params = {
            search: search,
            options: {
                page: pageNumber ? pageNumber : pagination.activePage,
                limit: rows_per_page_val ? rows_per_page_val : 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        //console.log("page origin:");
        //console.log(pageOrigin);

        if (pageOrigin) {
            switch (pageOrigin['origin']) {
                case 'inventory_view':
                    params.product_id = pageOrigin['product_id'];
                    break;
                case 'supplier_history':
                case 'supplier_date_filter':
                    params.supplier_id = pageOrigin['supplier_id'];
                default:
                    break;
            }
        }

        //console.log(params);

        // Custom date filtering
        if (date_filter) {
            params.date_from = date_filter['date_from'];
            params.date_to = date_filter['date_to'];
        }

        // if(item_id)
        PurchaseOrderService.paginateWithSearchPurchaseOrder(params, status_view)
            .then((res) => {
                let data_list = [];
                res.data.docs.map((data) => {
                data_list.push(data);
                });

                pagination_list[status_view] = data_list;

                // let pagination = {
                //     totalCount: res.data.purchase_orders.total,
                //     activePage: res.data.purchase_orders.current_page,
                //     itemsCountPerPage: res.data.purchase_orders.per_page,
                //     totalItemsCount: res.data.purchase_orders.total,
                // };

                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit,
                    totalItemsCount: res.data.total_docs,
                };

                dispatch({
                    type: GET_PO_STATUS,
                    payload: { 
                        pagination_list: pagination_list,
                        pagination: pagination,
                        purchase_orders: res.data.docs,
                    },
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                // dispatch(setLoading(false));
                // if (error.data.errors.length > 0) {
                //   ToastDanger(error.data.errors);
                // } else {
                //   ToastDanger("Network error, please reload the page.");
                // }
                console.log(error);
            });
    };

// fetch single
export const getSinglePurchaseOrder = (id) => async (dispatch, getState) => {
    dispatch(setMiniLoading(true));
    PurchaseOrderService.getSinglePurchaseOrder(id)
        .then((res) => {
            let supplier_item_only = true;

            if (res.data.status == 1) {
                let single_purchase_order = res.data.purchase_order;
                let po_item = [];
                single_purchase_order.purchase_order_items.map(
                    (data, index) => {
                        data.status_code = data.product
                            ? data.product.status_code
                            : null;
                        data.productDetails = []
                        po_item.push(data);
                    }
                );
                let po_default_template = [];
                supplier_item_only =
                    res.data.purchase_order.supplier_item_only != undefined
                        ? res.data.purchase_order.supplier_item_only
                        : true;
                // dispatch(getSettingApp());
                // const subsetting = getSubSetting();
                const subsetting = single_purchase_order.subs_setting;
                // single_purchase_order["default_tax"] = subsetting
                //   ? subsetting.tax_rate
                //   : 0;

                if (single_purchase_order['terms'] == null) {
                    single_purchase_order['terms'] = subsetting
                        ? subsetting.payment_terms
                            ? subsetting.payment_terms
                            : { value: 'none', label: 'None' }
                        : { value: 'none', label: 'None' };
                }

                if (single_purchase_order['tax'] === null) {
                    single_purchase_order['tax'] = 0;
                }

                // if(single_purchase_order['vat'] == null)
                // {
                //     single_purchase_order['vat'] = 0;
                // }

                if (single_purchase_order.template_setting) {
                    po_default_template = {
                        value: single_purchase_order.template_setting._id,
                        label: single_purchase_order.template_setting
                            .template_name
                            ? single_purchase_order.template_setting
                                  .template_name
                            : single_purchase_order.template.template_name,
                    };
                }

                let edit_attr = {
                    discount: single_purchase_order.discount
                        ? single_purchase_order.discount
                        : 0,
                };
                let default_supplier_value = {
                    value: res.data.purchase_order.supplier_details._id,
                    label: res.data.purchase_order.supplier_details.name,
                };

                single_purchase_order.purchase_order_date =
                    single_purchase_order.purchase_order_date
                        ? new Date(single_purchase_order.purchase_order_date)
                        : new Date();

                let payload = {
                    single_purchase_order: single_purchase_order,
                    edit_attr: edit_attr,
                    default_supplier_value: default_supplier_value,
                    po_default_template: po_default_template,
                };
                let file_count = {
                    number: 0,
                    str: '',
                };

                file_count.number = single_purchase_order.uploads
                    ? single_purchase_order.uploads.length
                    : 0;
                file_count.str =
                    file_count.number > 9 ? '9+' : file_count.number;
                dispatch({ type: FILE_COUNT, payload: file_count });
                dispatch({ type: SET_CHECKED, payload: supplier_item_only });
                dispatch({ type: GET_SINGLE_PURCHASE_ORDER, payload });
                dispatch(getItemSupplier(res.data.purchase_order.supplier_id));
                dispatch({ type: SET_PO_ITEMS, payload: po_item });
            }

            dispatch(setMiniLoading(false));
        })
        .catch((error) => {
            dispatch(setMiniLoading(false));
            if (error.data.errors.length > 0) {
                ToastDanger(error.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            console.log(error);
        });
};

// handle submit
export const handleOnSubmit =
    (method, status) => async (dispatch, getState) => {
        let { single_purchase_order, supplier_item_only } =
            getState().purchase_order;

        let errors = [];
        let items_data = single_purchase_order.purchase_order_items;
        items_data.map((item) => {
            if (item.qty <= 0) {
                errors.push(
                    'Quantity cannot be zero value, please add at least 1'
                );
            }
            if (item.cost <= 0) {
                errors.push(
                    'Unit Price cannot be zero value, please include at least the amount'
                );
            }
        });

        if (single_purchase_order.purchase_order_items.length == 0) {
            ToastDanger('Please add at least one item');
        } else if (errors.length > 0) {
            ToastDanger(errors);
        } else {
            // // embed product instead of searching in backend
            // single_purchase_order.purchase_order_items = single_purchase_order.purchase_order_items.map((item) => {
            //   return {
            //     ...item,
            //     product: item.items.find((product) => product.label === item.product_name),
            //   };
            // });

            // console.log(single_purchase_order.purchase_order_items);

            let stat_arr = status.split('-');
            let stat = stat_arr[0];
            let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;
            const data = {
                _id: single_purchase_order._id,
                po_no: single_purchase_order.po_no,
                supplier_id: single_purchase_order.supplier_id,
                purchase_order_items: JSON.stringify(
                    single_purchase_order.purchase_order_items
                ),
                discount: single_purchase_order.discount,
                discount_type: single_purchase_order.discount_type,
                tax: single_purchase_order.tax,
                vat_amount: single_purchase_order.vat_amount,
                tax_type: single_purchase_order.tax_type,
                other: single_purchase_order.other,
                sub_total: single_purchase_order.sub_total,
                total: single_purchase_order.total,
                remarks: single_purchase_order.remarks,
                payment_terms: single_purchase_order.payment_terms,
                status: stat,
                purchase_order_date: single_purchase_order.purchase_order_date,
                purchase_order_template_id:
                    single_purchase_order.purchase_order_template_id,
                payment_mode: single_purchase_order.payment_mode,
                supplier_item_only: supplier_item_only,
            };
            
            dispatch(setLoading(true));
            // console.log("submit",data);
            if (method == 'create') {
                if (status == 'draft' || status == 'draft-print')
                    return dispatch(purchaseOrderPost(data, actions));
                if (status == 'draft-email') {
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to draft and email this Purchase Order. Continue?',
                        () => dispatch(purchaseOrderPost(data, actions))
                    );
                }

                if (status == 'saved' || status == 'saved-print')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Purchase Order, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(purchaseOrderPost(data, actions))
                    );
                if (status == 'saved-email')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save and email this Purchase Order, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(purchaseOrderPost(data, actions))
                    );
            } else {
                // update
                if (status == 'draft' || status == 'draft-print')
                    return dispatch(
                        purchaseOrderUpdate(data, data._id, actions)
                    );
                if (status == 'draft-email') {
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to draft and email this Purchase Order. Continue?',
                        () =>
                            dispatch(
                                purchaseOrderUpdate(data, data._id, actions)
                            )
                    );
                }
                if (status == 'saved' || status == 'saved-print')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Purchase Order, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                purchaseOrderUpdate(data, data._id, actions)
                            )
                    );
                if (status == 'saved-email')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save and email Purchase Order, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                purchaseOrderUpdate(data, data._id, actions)
                            )
                    );
            }
        }
    };

// store
export const purchaseOrderPost =
    (data, actions = null) =>
    async (dispatch) => {
        if (actions == 'email') {
            data['send_to_email'] = true;
        }

        dispatch(setMUIPage(1));
        PurchaseOrderService.purchaseOrderPost(data)
            .then((res) => {
                if (res.data.status == 0) {
                    ToastDanger(res.data.errors);
                } else if (res.data.status == 1) {
                    //console.log("res", res);
                    ToastSuccess(res.data.message);
                    dispatch(clearPurchaseOrder());
                    dispatch(setPage('main_page'));
                    if (actions == 'print') {
                        dispatch(setPage('print_modal', res.data.id));
                    }
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                dispatch(setLoading(false));
                console.log(error);
            });
    };

// update
export const purchaseOrderUpdate =
    (data, id, actions) => async (dispatch, getState) => {
        if (actions == 'email') {
            data['send_to_email'] = true;
        }

        PurchaseOrderService.updateSinglePurchaseOrder(data, id)
            .then((res) => {
                if (res.data.status == 0) {
                    ToastDanger(res.data.errors);
                } else if (res.data.status == 1) {
                    //console.log("res", res);
                    ToastSuccess(res.data.message);
                    dispatch(clearPurchaseOrder());
                    dispatch(setPage('main_page'));
                    if (actions == 'print') {
                        dispatch(setPage('print_modal', res.data.id));
                    }
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                console.log(error);
            });
    };

// archive
export const moveToArchive =
    (pageOrigin = null) =>
    async (dispatch, getState) => {
        let { rows_to_delete } = getState().purchase_order;
        let id_arr = [];

        rows_to_delete.map((row) => {
            id_arr.push(row.id);
        });
        let params = { ids: JSON.stringify(id_arr) };

        PurchaseOrderService.ArchivePurchaseOrder(params)
            .then((res) => {
                dispatch(setRowChecked());
                switch (res.data.status) {
                    case 0:
                        ToastWarning(res.data.message);
                        if (pageOrigin != null) {
                            if (pageOrigin['origin'] == 'supplier_history') {
                                dispatch(
                                    getPurchaseOrders(
                                        1,
                                        null,
                                        pageOrigin['supplier_id']
                                    )
                                );
                            }
                        } else {
                            dispatch(getPurchaseOrders());
                        }
                        break;
                    case 1:
                        ToastSuccess(res.data.message);
                        if (pageOrigin != null) {
                            if (pageOrigin['origin'] == 'supplier_history') {
                                dispatch(
                                    getPurchaseOrders(
                                        1,
                                        null,
                                        pageOrigin['supplier_id']
                                    )
                                );
                            }
                        } else {
                            dispatch(getPurchaseOrders());
                            dispatch(clearPurchaseOrder());
                        }
                    default:
                        break;
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                console.log(error);
            });
    };

export const setModal = (modal, status) => async (dispatch) => {
    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
};

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });

        if (pageOrigin) {
            // Sorting from Supplier History
            dispatch(getPurchaseOrders(1, null, pageOrigin));
        } else {
            dispatch(getPurchaseOrders());
        }
    };

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
    };

export const confirmDeleteDialogBox =
    (pageOrigin) => async (dispatch, getState) => {
        let { rows_to_delete } = getState().purchase_order;
        let po_no = '';
        let po = [];
        rows_to_delete.map((rows, i) => {
            po.push('<b>' + rows.name + '</b>'); //po_no +=  "<b>" + rows.name + "</b>, ";
        });
        SwalWarningHtml(
            'Warning!',
            `Do you want to remove the PO: ${po.join()}`,
            () => dispatch(moveToArchive(pageOrigin))
        );
    };

// set the rows unchecked
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: SET_ROW_CHECKED });

// handle select payment type
export const handleSelectPaymentType = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({ type: HANDLE_PO_SELECT_PAYMENT_MODE, payload: selectOption });
};

// fetch PO templates
export const getPOTemplates = () => async (dispatch) => {
    PurchaseOrderService.getPOTemplates()
        .then((res) => {
            let po_templates = res.data.purchase_order_templates;
            let po_default_template = {
                value: res.data.selected_template._id,
                label: res.data.selected_template.template_name,
            };
            let purchase_order_template_id =
                res.data.purchase_order_template_id;

            let po_template_arr = [];
            po_templates.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.template_name,
                    name: 'purchase_order_template_id',
                };

                po_template_arr[i] = select;
            });

            let dataPayload = {
                po_templates: po_template_arr,
                po_default_template: po_default_template,
                purchase_order_template_id: purchase_order_template_id,
            };

            dispatch({ type: GET_PO_TEMPLATES, payload: dataPayload });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                ToastDanger(error.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            $('.btn-remove').removeAttr('disabled').html('Remove');
            console.log(error);
        });
};

// handle select po templates
export const handleSelectPOTemplate = (selectedOption) => async (dispatch) => {
    if (selectedOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let purchase_order_template_id = selectedOption.value;
    let po_default_template = {
        value: selectedOption.value,
        label: selectedOption.label,
    };

    dispatch({
        type: HANDLE_SELECT_PO_TEMPLATE,
        payload: {
            po_default_template: po_default_template,
            purchase_order_template_id: purchase_order_template_id,
        },
    });
};

// handle Date Picker
export const PODatePicker = (date) => async (dispatch) => {
    if (date) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }
    dispatch({ type: SET_DATE_PICKER, payload: date });
};

// handle prev, next page of modals
export const goPage = (direction) => async (dispatch, getState) => {
    dispatch(setMiniLoading(true));
    let { single_purchase_order } = getState().purchase_order;

    let params = {
        direction: direction,
        _id: single_purchase_order._id,
    };

    try {
        const go_to = await PurchaseOrderService.goPage(params);
        dispatch(getSinglePurchaseOrder(go_to.data._id));
    } catch (e) {
        console.log(e);
        ToastDanger('Server Error');
        dispatch(setMiniLoading(false));
    }
};

export const dynamicInputChange = (e, index) => async (dispatch, getState) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let { name, value } = e.target;
    let { single_purchase_order } = getState().purchase_order;
    let items = { ...single_purchase_order.purchase_order_items[index] };
    items[name] = parseFloat(value);
    items.amount = parseFloat(items.qty) * parseFloat(items.cost);

    if (items.discount_type && items.discount_type.value !== 'none') {
        let discountAmount = 0;
        if (items.discount_type.value === 'percent') {
            discountAmount = (items.discount / 100) * items.amount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = items.discount;
        }
        items.amount = items.amount - discountAmount;
        items.discount_amount = discountAmount ? discountAmount : 0;
    }
    // dispatch({ type: MANUAL, payload: po_items });
    single_purchase_order.purchase_order_items[index] = items;
    dispatch({
        type: SET_PO_ITEMS,
        payload: single_purchase_order.purchase_order_items,
    });

    let bool = false;
    // po_items.map((data, key) =>{
    //     if(data.cost <= 0 || data.srp <= 0 || data.order_qty <= 0)
    //     {
    //         bool = true;
    //     }
    // })
    // dispatch({type : BTN_SUB, payload : bool});
};

// handle on click Dropdown menu
// export const handleDropDownAttach = () =>

export const set_items =
    (items, _new = true) =>
    async (dispatch, getState) => {
        let { single_purchase_order } = getState().purchase_order;
        let item_template = {
            _id: '',
            items: items,
            sku: '',
            product_name: '',
            // product_unit: { name: '' },
            attribute_string: '',
            qty: 0,
            cost: 0,
            amount: 0,
            _new: true,
        };

        if (_new) {
            let data = (single_purchase_order.purchase_order_items = [
                item_template,
            ]);
            dispatch({ type: SET_PO_ITEMS, payload: data });
        }

        dispatch({ type: PO_ITEMS_TEMPLATE, payload: item_template });
    };

//old
// export const change_item =
//   (selectOption, key) => async (dispatch, getState) => {
//     // console.log("selectOption", selectOption);
//     let { single_purchase_order } = getState().purchase_order;
//     let items_data = single_purchase_order.purchase_order_items;
//     items_data[key] = {
//       items: items_data[key].items,
//       _id: selectOption._id,
//       sku: selectOption.sku,
//       product_name: selectOption.product_name,
//       qty: parseInt(selectOption.qty),
//       product_unit_id: selectOption.product_unit_id,
//       product_unit_name: selectOption.product_unit_name,
//       attribute_string: selectOption.attribute_string,
//       cost: parseFloat(selectOption.cost),
//       amount: parseFloat(selectOption.amount),
//       status_code: selectOption.status_code,
//       _new: true,
//       description: selectOption.description,
//       barcode: selectOption.barcode,
//       selected: true,
//       brand: selectOption.brand,
//     };
//     dispatch({ type: SET_PO_ITEMS, payload: items_data });
//   };

//** MERN */
export const change_item =
    (selectOption, index) => async (dispatch, getState) => {
        // console.log("selectOption", selectOption);
        let { single_purchase_order } = getState().purchase_order;
        let items = { ...single_purchase_order.purchase_order_items[index] };

        // Check if the product is already selected in other rows
        const isProductAlreadySelected = single_purchase_order.purchase_order_items.some((item, i) => i !== index && item.product_id === selectOption._id);

        if (isProductAlreadySelected ) {
            // Display an error message (You can replace this with your preferred way of showing errors)
            ToastDanger("Product is already added on the list");
            // Remove the row
            dispatch(handleRemoveItem(index));
        } else {
        // console.log(selectOption);
        items['_id'] = selectOption._id;
        items["product_id"] = selectOption._id;
        items['sku'] = selectOption.sku;
        items['product_name'] = selectOption.product_name;
        items['qty'] = parseInt(selectOption.qty);
        items['product_unit_id'] = selectOption.product_unit_id;
        items['product_unit_name'] = selectOption.product_unit_name;
        items['attribute_string'] = selectOption.attribute_string;
        items['srp'] = selectOption.srp;
        items['cost'] = selectOption.cost;
        items['amount'] = parseFloat(selectOption.amount);
        items['status_code'] = selectOption.status_code;
        items['barcode'] = selectOption.barcode;
        items['_new'] = true;
        items['description'] = selectOption.description;
        items['selected'] = true;
        items['brand'] = selectOption.brand;
        items['category'] = selectOption.category;
        items['discount_type'] = { label: 'None', value: 'none' }; // default discount type
        items['discount'] = 0; // default discount
        items['discount_amount'] = 0;
        items['attribute_template'] = selectOption.attribute_template;
        items['productDetails'] = [];
        single_purchase_order.purchase_order_items[index] = items;

        dispatch({
            type: SET_PO_ITEMS,
            payload: single_purchase_order.purchase_order_items,
        });
        }
    };


    export const getSingleProduct =
    (id, status = null) =>
    async (dispatch) => {
        dispatch(setMiniLoading(false));

        PurchaseOrderService.getSingleProduct(id)
            .then((res) => {
                if (res.data.status == 1) {
                    // console.log(res.data.product);
                    let product = res.data.docs;
                  

                    let fetchData = {
                        _id: product._id,
                        total_stocks: product.total_stocks,
                        last_arrival: product.last_arrival,
                        incoming_orders: product.incoming_orders
                    };

                    dispatch({
                        type: GET_SINGLE_PRODUCT,
                        payload: {
                            productId: id,
                            productDetails: fetchData,
                        },
                    });
        
                    dispatch(setMiniLoading(false));

                    // if (status == 'delete') {
                    //     dispatch(archive());
                    // }
                }
            })
            .catch((error) => {
                dispatch(setMiniLoading(false));
                ToastDanger('Network error, please reload the page.');
                $('.btn-save').removeAttr('disabled').html('Save');
                console.log(error);
            });
    };

export const ShowComboBox = (index) => async (dispatch, getState) => {
    let { single_purchase_order } = getState().purchase_order;
    let items_data = single_purchase_order.purchase_order_items;
    items_data[index]['selected'] = false;
    dispatch({ type: SET_PO_ITEMS, payload: items_data });
};

export const add_items = () => async (dispatch, getState) => {
    let { single_purchase_order, item_template } = getState().purchase_order;
    let items_data = single_purchase_order.purchase_order_items;
    item_template.new = true;
  
    let errors = false;
    items_data.map((item) => {
      if (item.qty <= 0) {
        ToastDanger("Quantity cannot be zero value, please add at least 1");
        errors = true;
      }
      if (item.srp <= 0) {
        ToastDanger(
          "Unit Price cannot be zero value, please include at least the amount"
        );
        errors = true;
      }
    });
  
    if (!errors) {
      items_data.push(item_template);
      dispatch({ type: SET_PO_ITEMS, payload: items_data });
    }
  };
  
export const handleTaxType = (selectOption) => async (dispatch, getState) => {
    let { tax, default_tax } = getState().purchase_order.single_purchase_order;

    tax = selectOption.value === 'none' ? 0 : default_tax;

    dispatch({
        type: PO_SELECT_TAX_TYPE,
        payload: { tax, tax_type: selectOption },
    });
};

// COMPUTATION HANDLER
export const computationHandler = () => async (dispatch, getState) => {
    let {
        single_purchase_order: {
            purchase_order_items,
            discount,
            discount_type,
            tax,
            tax_type,
        },
    } = getState().purchase_order;

    // Subtotal and discount
    const sub_total = purchase_order_items.reduce(
        (sub, item) => sub + parseFloat(item.amount ? item.amount : item.cost),
        0
    );
    const discounted =
        discount_type.value == 'percent'
            ? (parseFloat(sub_total) * discount) / 100
            : discount;
    const sub_total_discounted = sub_total - discounted;

    // Exclusive Vat     // Formula: price * (vat / 100)
    const exclusive_vat = (parseFloat(sub_total_discounted) * tax) / 100;
    const total_w_exclusive =
        sub_total_discounted + (parseFloat(sub_total_discounted) * tax) / 100; // total amount

    // Inclusive Vat   // Formula: price / ( (vat / 100) + 1)
    const inclusive_amount = sub_total_discounted / (tax / 100 + 1);
    const inclusive_vat = sub_total_discounted - inclusive_amount;

    let payload = {};

    switch (tax_type.value) {
        case 'exclusive':
            payload = {
                vat_amount: exclusive_vat,
                sub_total: sub_total,
                total: total_w_exclusive,
            };
            break;
        case 'inclusive':
            payload = {
                vat_amount: inclusive_vat,
                sub_total: sub_total,
                total: sub_total_discounted,
            };
            break;
        default:
            payload = {
                vat_amount: 0.0,
                sub_total: sub_total,
                total: sub_total_discounted,
            };
            break;
    }

    // console.log(payload);
    dispatch({ type: PO_COMPUTATION_HANDLER, payload });
};

export const sendEmail = (e, id) => async (dispatch, getState) => {
    let { email_config } = getState().purchase_order;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendSingleEmail(id));
        });
    } else {
        dispatch(sendSingleEmail(id));
    }
};

const sendSingleEmail = (id) => async (dispatch, getState) => {
    let { purchase_orders } = getState().purchase_order;
    dispatch({ type: SEND_EMAIL, payload: true });
    PurchaseOrderService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: true });
        })
        .catch((err) => {
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({
                type: RESEND_EMAIL,
                payload: purchase_orders.email_sent,
            });
        });
};

export const reSendEmail = (e, id) => async (dispatch, getState) => {
    SwalWarning(
        'Are you sure?',
        'Do you really want to resend this email?',
        () => dispatch(sendEmail(e, id))
    );
};

export const sendEmailDyanmic =
    (e, id, index) => async (dispatch, getState) => {
        let { purchase_orders } = getState().purchase_order;
        let po = purchase_orders[index];
        if (po.email_sent) {
            SwalWarning(
                'Are you sure?',
                'Do you really want to resend this email?',
                () => dispatch(sendByIndex(id, index))
            );
        } else {
            dispatch(sendByIndex(id, index));
        }
    };

const sendByIndex = (id, index) => async (dispatch, getState) => {
    let { email_config } = getState().purchase_order;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendEmailIndex(id, index));
        });
    } else {
        dispatch(sendEmailIndex(id, index));
    }
};

const sendEmailIndex = (id, index) => async (dispatch, getState) => {
    let { purchase_orders } = getState().purchase_order;
    let po = purchase_orders[index];
    po.sending_email = true;
    purchase_orders[index] = po;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: purchase_orders });
    PurchaseOrderService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            po.sending_email = false;
            po.email_sent = true;
            purchase_orders[index] = po;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: purchase_orders });
        })
        .catch((err) => {
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            po.sending_email = false;
            purchase_orders[index] = po;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: purchase_orders });
        });
};

export const SupItemOnly = () => async (dispatch, getState) => {
    let { supplier_item_only } = getState().purchase_order;
    supplier_item_only = supplier_item_only ? false : true;
    dispatch({ type: SET_CHECKED, payload: supplier_item_only });
};

export const getPdf = (e, id, purchase_order_no) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_BTN, payload: true });
    PurchaseOrderService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = purchase_order_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch({ type: DOWNLOAD_BTN, payload: false });
        })

        .catch((err) => {
            dispatch({ type: DOWNLOAD_BTN, payload: false });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const dynamiPdf = (e, id, index) => async (dispatch, getState) => {
    let { purchase_orders } = getState().purchase_order;
    let po = purchase_orders[index];
    po.download_pdf = true;
    purchase_orders[index] = po;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: purchase_orders });
    PurchaseOrderService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = po.purchase_order_no + '.pdf';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            po.download_pdf = false;
            purchase_orders[index] = po;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: purchase_orders });
        })

        .catch((err) => {
            po.download_pdf = false;
            purchase_orders[index] = po;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: purchase_orders });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const emailConfig = () => async (dispatch) => {
    let x = 0;
    while (x < 3) {
        try {
            const result = await PurchaseOrderService.getEmailConfig();
            dispatch({ type: EMAIL_SETTING_DATA, payload: result.data });
            x = 4;
        } catch (err) {
            // Temporary comment coz of connection timeout
            // ToastDanger('Failed to load email configuration.');
            console.log('error', err);
            x++;
        }
    }
};

const verfiyPass = (callback) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
        };
        PurchaseOrderService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(result.data.message, callback('success'));
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const closeVerify = () => async (dispatch, getState) => {
    dispatch({ type: PASSWORD_VERIFY_MODAL, payload: false });
};

export const clear_data = () => async (dispatch) => {
    dispatch({ type: CLEAR_DATA });
};

export const cancelPo = () => async (dispatch, getState) => {
    let { single_purchase_order } = getState().purchase_order;
    SwalWarningHtml(
        'Are You Sure?',
        'You are about to cancel Purchase Order <b>' +
            single_purchase_order.po_no +
            '</b>. Continue?',
        () => dispatch(passwordCancel())
    );
};

const passwordCancel = () => async (dispatch) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
            role_id: getUserData().role_id,
            subscriber_id: getUserData().subscriber_id,
            id: getUserData()._id,
        };
        PurchaseOrderService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(
                    result.data.message,
                    dispatch(submitCancel())
                );
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const submitCancel = () => async (dispatch, getState) => {
    let { single_purchase_order, btn_form } = getState().purchase_order;
    btn_form.cancel = true;
    dispatch({ type: BTN_FORM, payload: btn_form });
    PurchaseOrderService.cancelPo(single_purchase_order._id)
        .then((result) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            ToastSuccess(
                'Purchase Order: ' + single_purchase_order.po_no + ' cancelled'
            );
            dispatch(setPage('main_page'));
        })
        .catch((err) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            try {
                ToastDanger(err.data.message);
            } catch (err) {
                ToastDanger('Failed to cancel Sales Invoice');
            }
        });
};
