import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner, { MiniSpinner } from "../../layouts/Spinner";
import { Show, useAccess } from "react-redux-permission";
import { numberFormat } from "../../../Utils/Common";
import { cancelPosTransactionView } from '../../../redux/actions/pos/pos.actions';
import moment from 'moment';

function POSReceipt({ pos: { pos_daily, pos_cutoff , pos_user, subs_setting, pos_serial_no, pos_created_at, 
    pos_item_list, pos_sub_total, pos_discount, pos_grand_total, pos_discount_type, pos_transaction_edit, pos_discount_amount}, 
    cancelPosTransactionView}) {
        
    return (
        <div className="d-flex justify-content-lg-start justify-content-center align-items-center mb-0 pb-0">
            {
                pos_transaction_edit === 'receipt' && 
                <div className="card pos-tran-bg-border pos-receipt">
                    <div className="card-body">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="">
                                <i className="ft ft-more-horizontal ft-tran-icon"></i>
                            </div>
                            <div className="">
                                <i className="ft ft-save mr-1 ft-tran-icon"></i>
                                <i className="ft ft-printer mr-1 ft-tran-icon"></i>
                                <i onClick={() => cancelPosTransactionView('receipt')} className="ft ft-x ft-tran-icon font-weight-bold"></i>
                            </div>
                        </div>

                        <div classNam="pos-receipt-header">
                            {   
                                subs_setting != null && 
                                <>
                                    <div className="text-center pos-r-f16 pb-2">
                                        <div className="pos-r-bold">{subs_setting.company_name}</div>
                                        <div>{subs_setting.company_address}</div>
                                        <div>{subs_setting.company_contact}</div>
                                    </div>
                                </>
                            }

                            <div className="pos-receipt-dots"></div>
                            <div className="pos-sub-title pb-3 pos-r-f14">
                                <div>Date: {pos_cutoff ? moment(pos_daily.date).format('MMMM D, YYYY') : ''} {pos_created_at && pos_created_at.substring(12, 21)}</div>
                                <div>Transaction No. {pos_serial_no && pos_serial_no}</div>
                                <div> Cashier: {pos_user && pos_user.admin_name}</div>
                            </div>

                            <div className="pos-receipt-dots"></div>
                            <div className="d-flex justify-content-between mb-1 pos-r-f14 pos-r-bold">
                                <div className="pos-w-150">Description</div>
                                <div className="pos-w-100 text-right">Price</div>
                                <div className="pos-w-100 text-right">Qty</div>
                                <div className="pos-w-100 text-right">Amount</div>
                            </div>
                            {
                                pos_item_list.length > 0 && pos_item_list.map((item, i) => (
                                    <div key={i} className="d-flex justify-content-between pos-r-f14">
                                        <div className="pos-w-150">{item.product_name.slice(0, 15) + (item.product_name.length > 15 ? "..." : "")}</div>
                                        <div className="pos-w-100 text-right">{item.srp && numberFormat(item.srp)}</div>
                                        <div className="pos-w-100 text-right">{item.order_qty}</div>
                                        <div className="pos-w-100 text-right">{item.amount && numberFormat(item.amount)}</div>
                                    </div>
                                ))
                            }
                            <div className="pos-receipt-dots my-1"></div>
                            <div className="pos-r-f14 pb-5 mw-50p">
                                <ul className="leaders">
                                    <li>
                                        <span>Sub Total</span>
                                        <span>{pos_sub_total && numberFormat(pos_sub_total)}</span>
                                    </li>
                                    {
                                        pos_discount > 0 ?
                                        <li>
                                            <span>Discount</span>
                                            <span>{ pos_discount_type === 'pwd' || pos_discount_type === 'senior' ?  '('+pos_discount_type.toUpperCase() + ') ' : ''}{pos_discount}{pos_discount_type === 'pwd' || pos_discount_type === 'senior' ? '%' : ''}</span>
                                        </li>
                                        :
                                        <></>
                                    }
                                    
                                    <li>
                                        <span>Discount Amount</span>
                                        <span>{pos_discount_amount && numberFormat(pos_discount_amount)}</span>
                                    </li>
                                    <li>
                                        <span>VAT</span>
                                        <span>0.00</span>
                                    </li>
                                    <li>
                                        <span>Others</span>
                                        <span>0.00</span>
                                    </li>
                                    <li>
                                        <span>Total</span>
                                        <span>{pos_grand_total && numberFormat(pos_grand_total)}</span>
                                    </li>
                                </ul>
                               
                            </div>
{/*                             
                            <div className="pos-receipt-dots"></div>
                                <div className="text-center pos-r-f12">
                                    <div>Thank you Please come again.</div>
                                    <div>This serves as your OFFICIAL RECEIPT.</div>
                                    <div>This Invoice/Receipt shall be valid.</div>
                                    <div>for five(5) years from the date</div>
                                    <div>of the permit to use</div>
                                    <div>Thank you Please come again.</div>
                                </div>
                            <div className="pos-receipt-dots"></div>
                                <div className="text-center pos-r-f12">
                                    <div>POS provider Bluehive Inc.</div>
                                    <div>E. Rodriguez Jr. Avenue, Quezon City.</div>
                                    <div>Accrdn No. 734213401238472193847123974</div>
                                    <div>Date of Accreditation 05/20/2021</div>
                                    <div>Valid Until 05/20/2022</div>
                                </div>  */}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
	pos: state.pos
})

export default connect(mapStateToProps, { cancelPosTransactionView })(POSReceipt);
