import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';
import '../../layouts/template_styles/peoples-si-01.css';

/*
 *   NAME: Peoples SI Template  01
 *   DESC: Cash Sales Order
 */
export class PeoplesSI01 extends React.PureComponent {
    // function SalesOrderPrintContent({ si, auth }) {
    // let subId = auth.user.subscriber_id;
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.si.sales_order_items.length;
        this.state = {
            si: this.props.si,
            auth: this.props.auth,
            activePage: 1,
            itemsCountPerPage: 15,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td colspan={2}>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }
        return tableRows;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        // console.log(page, totalPage);
        const { si } = this.state;
        const totalItemsCount = si.sales_order_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        si.sales_order_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
                // console.log(extraRowsCount);
            }
        });

        // console.log(showItemsFrom, showItemsTo);
        // console.log(extraRowsCount);

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        let auth = this.state.auth;

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';
        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';
        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b temp-div pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const template_setting = this.state.si.template_setting;
        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';

        const si_title =
            template_setting.template_label == 'peoples_si_01'
                ? 'CASH SALES ORDER'
                : 'CHARGE SALES ORDER';

        console.log(this.state.si);

        return (
            <div className="margin-1p5em px-1 print-page">
                <div className="si01-ht mt-1">
                    <div className="d-flex align-items-center justify-content-center si01-top-header">
                        <div className="si01-company-logo">
                            <img
                                src={
                                    template_setting &&
                                    template_setting.company_logo
                                }
                            />
                        </div>
                        <div className="si01-company">
                            {template_setting && template_setting.company_name}
                        </div>
                    </div>
                    <div className="psi01-address">
                        Owned & Operated by:{' '}
                        <b>(MACRO TRUCK PARTS TRADING OPC)</b>
                    </div>
                    <div className="psi01-address">
                        {template_setting && template_setting.company_address}
                    </div>
                    <div className="psi01-address">
                        Tel. Nos.{' '}
                        {template_setting && template_setting.company_landline},
                        859-1563,{' '}
                        {template_setting && template_setting.company_mobile},
                        Fax 852-1482
                    </div>
                    <div className="psi01-address">
                        VAT Reg. TIN:{' '}
                        {template_setting && template_setting.company_tin
                            ? template_setting.company_tin
                            : ''}
                    </div>
                    <div className="psi01-address">
                        Specialized in Truck Parts:{' '}
                        <b>
                            FUSO, HINO, ISUZU, HOWO, FOTON, SHACMAN, & CHENGLONG
                        </b>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <span className="psi01-no"> No: {si.serial_no}</span>
                </div>

                <div className="si01-h d-flex justify-content-between align-items-center">
                    <div className="psi01-cash">{si_title}</div>
                    <div className="si01-16b si-lbl psi01-date">
                        Date:{' '}
                        <span className="ps01-date si01-16">
                            {' '}
                            {defaultFormat(si.sales_order_date)}
                        </span>{' '}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="si01-hl col-8">
                        <div className="pull-left">
                            <div className="si01-16b si-lbl">
                                Customer:{' '}
                                <span className="ps01-soldto si01-16">
                                    {' '}
                                    <Link
                                        to={`/sales/customers/history/${
                                            si.customer ? si.customer._id : null
                                        }`}
                                        className="txt-underline print-text"
                                        target="_blank"
                                    >
                                        {si.customer ? si.customer.name : null}
                                    </Link>{' '}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                Address:{' '}
                                <span className="ps01-address si01-16">
                                    {' '}
                                    {si.customer ? si.customer.street1 : null}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                Bus Style:{' '}
                                <span className="ps01-bus si01-16"> </span>{' '}
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="">
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">TIN:</span>{' '}
                                <span className="ps01-tin"> </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">P.O. No. </span>{' '}
                                <span className="ps01-po">
                                    {' '}
                                    {si.reference_no}
                                </span>{' '}
                            </div>
                            <div className="si01-16b si-lbl">
                                <span className="lbl-r-ln">Terms:</span>{' '}
                                <span className="ps01-terms">
                                    {' '}
                                    {si.payment_terms
                                        ? si.payment_terms.label
                                        : ''}
                                </span>{' '}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="si01-print-body row">
                    <div className="col-md-12">
                        {si.status == 'draft' ? (
                            <div class="si01-watermark ">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="psi01-table">
                            <thead>
                                <tr>
                                    <th>QTY</th>
                                    <th>UNIT</th>
                                    <th colspan="2" width="50%">
                                        DESCRIPTION
                                    </th>
                                    <th width="20%">UNIT PRICE</th>
                                    <th>AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {si.sales_order_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <tr>
                                                <td className="text-center">
                                                    {numberFormat(item.qty, 0)}
                                                </td>
                                                <td className="text-center">
                                                    {item.product_unit
                                                        ? item.product_unit.name
                                                        : ''}
                                                </td>
                                                <td
                                                    className="text-center"
                                                    colspan="2"
                                                    style={DynamicStringStyle}
                                                >
                                                    <Link
                                                        to={`/inventory/view/${item._id}`}
                                                        className="txt-underline"
                                                        target="_blank"
                                                    >
                                                        <DynamicFont
                                                            content={
                                                                item.product_name
                                                            }
                                                        />
                                                    </Link>
                                                    {', '}
                                                    {item.products != null && (
                                                        <>
                                                            {' '}
                                                            {
                                                                item.products
                                                                    .description
                                                            }
                                                        </>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {item.unit_price
                                                        ? numberFormat(
                                                              item.unit_price
                                                          )
                                                        : numberFormat(
                                                              item.srp
                                                          )}
                                                </td>
                                                <td className="text-center">
                                                    {numberFormat(item.amount)}
                                                </td>
                                            </tr>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                        {page == totalPage ? (
                            <>
                                <table className="border1 mt-2 w-100 psi01-table-2">
                                    <tr>
                                        <td className="border1">
                                            <div className="terms">
                                                <b>TERMS & CONDITIONS:</b>{' '}
                                                Interest of 12% per annum will
                                                be charged on all overdue
                                                accounts. In case of litigation,
                                                the court of the City of Cagayan
                                                de Oro shall have jurisdiction,
                                                and the customer hereby agrees
                                                to pay for attorney’s fees and
                                                cost of suit resulting
                                                therefrom. Goods travel at
                                                buyer’s risk. Our responsibility
                                                ceases after goods delivered to
                                                the transportation are correct
                                            </div>
                                        </td>
                                        <td className="border1">
                                            <div className="return">
                                                No Return/Exchange will be
                                                entertained after 7 days from
                                                Date of Delivery
                                            </div>
                                        </td>
                                        <td className="border1">
                                            <div className="d-flex justify-content-between amounts">
                                                <div>
                                                    <span>VATable Sales</span>
                                                    <span>
                                                        VAT -Exempt Sales
                                                    </span>
                                                    <span>
                                                        Zero-Rated Sales
                                                    </span>
                                                    <span>VAT Amount</span>
                                                    <span>
                                                        <b>TOTAL AMOUNT DUE</b>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>
                                                        {numberFormat(
                                                            si.grand_total
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border1">
                                        <td>
                                            <div className="d-flex justify-content-between preparedby">
                                                <span>Prepared by:</span>
                                                <span>Checked by:</span>
                                            </div>
                                            <div className="d-flex justify-content-between preparedby-ln">
                                                <div>
                                                    <span>
                                                        {' '}
                                                        {si.user_prepared_by}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        {' '}
                                                        {si.user_checked_by}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2}>
                                            <div className="customer-ac">
                                                <span>
                                                    <b>
                                                        Customer’s
                                                        Acknowledgement
                                                    </b>
                                                </span>
                                                <span>
                                                    Received the merchandise as
                                                    indicated above in good
                                                    order as to
                                                </span>
                                                <span>
                                                    quantity and condition. All
                                                    terms and conditions of sale
                                                    are herein accepted
                                                </span>
                                            </div>

                                            <div className="d-flex justify-content-between customer-ac-ln">
                                                <div>
                                                    <span>&nbsp; </span>
                                                    <span></span>
                                                    <span>
                                                        Printed Name of
                                                        Authorized
                                                        Representative
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>&nbsp; </span>
                                                    <span></span>
                                                    <span>Signature</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex bottom-left">
                                                <img src="/template-setting/main-template/assets/peoples-01.png" />
                                                <div>
                                                    <span>
                                                        00 Bxs. (500x__ply)
                                                        0000-0000 BIR Authority
                                                        to Print No.
                                                        098AU2021______
                                                    </span>
                                                    <span>
                                                        Date Issued: _______
                                                        Valid Until: ________
                                                    </span>
                                                    <span>
                                                        Legacy Sales & Printing
                                                        Press, Inc. TIN
                                                        003-053-883-000 VAT
                                                    </span>
                                                    <span>
                                                        A. Luna Extn., 27
                                                        Cagayan de Oro City
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2}>
                                            <div className="d-flex justify-content-between bottom-right">
                                                <div className="p-left">
                                                    <span>
                                                        <b>
                                                            BIR Permit to Use
                                                            Loose-Leaf No.
                                                        </b>{' '}
                                                        __________
                                                    </span>
                                                    <span>
                                                        <b>
                                                            Date Issued:
                                                            _____________
                                                        </b>
                                                    </span>
                                                    <span>
                                                        PRINTER’S ACCREDITATION
                                                        NO. 098MP20190000000008
                                                    </span>
                                                    <span>
                                                        Date Issued: 03/18/19
                                                    </span>
                                                </div>
                                                <div className="p-right">
                                                    <span>
                                                        “THIS {si_title} SHALL
                                                        BE VALID FOR
                                                    </span>
                                                    <span>
                                                        FIVE (5) YEARS FROM THE
                                                        DATE OF ATP”
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </>
                        ) : (
                            <>
                                <table className="border1 mt-2 w-100 psi01-table-2">
                                    <tr>
                                        <td className="border1">
                                            <div className="terms">
                                                <b>TERMS & CONDITIONS:</b>{' '}
                                                Interest of 12% per annum will
                                                be charged on all overdue
                                                accounts. In case of litigation,
                                                the court of the City of Cagayan
                                                de Oro shall have jurisdiction,
                                                and the customer hereby agrees
                                                to pay for attorney’s fees and
                                                cost of suit resulting
                                                therefrom. Goods travel at
                                                buyer’s risk. Our responsibility
                                                ceases after goods delivered to
                                                the transportation are correct
                                            </div>
                                        </td>
                                        <td className="border1">
                                            <div className="return">
                                                No Return/Exchange will be
                                                entertained after 7 days from
                                                Date of Delivery
                                            </div>
                                        </td>
                                        <td className="border1">
                                            <div className="d-flex justify-content-between amounts">
                                                <div>
                                                    <span>VATable Sales</span>
                                                    <span>
                                                        VAT -Exempt Sales
                                                    </span>
                                                    <span>
                                                        Zero-Rated Sales
                                                    </span>
                                                    <span>VAT Amount</span>
                                                    <span>
                                                        <b>TOTAL AMOUNT DUE</b>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                    <span>&nbsp;</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border1">
                                        <td>
                                            <div className="d-flex justify-content-between preparedby">
                                                <span>Prepared by:</span>
                                                <span>Checked by:</span>
                                            </div>
                                            <div className="d-flex justify-content-between preparedby-ln">
                                                <div>
                                                    <span> </span>
                                                </div>
                                                <div>
                                                    <span> </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2}>
                                            <div className="customer-ac">
                                                <span>
                                                    <b>
                                                        Customer’s
                                                        Acknowledgement
                                                    </b>
                                                </span>
                                                <span>
                                                    Received the merchandise as
                                                    indicated above in good
                                                    order as to
                                                </span>
                                                <span>
                                                    quantity and condition. All
                                                    terms and conditions of sale
                                                    are herein accepted
                                                </span>
                                            </div>

                                            <div className="d-flex justify-content-between customer-ac-ln">
                                                <div>
                                                    <span>&nbsp; </span>
                                                    <span></span>
                                                    <span>
                                                        Printed Name of
                                                        Authorized
                                                        Representative
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>&nbsp; </span>
                                                    <span></span>
                                                    <span>Signature</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex bottom-left">
                                                <img src="/template-setting/main-template/assets/peoples-01.png" />
                                                <div>
                                                    <span>
                                                        00 Bxs. (500x__ply)
                                                        0000-0000 BIR Authority
                                                        to Print No.
                                                        098AU2021______
                                                    </span>
                                                    <span>
                                                        Date Issued: _______
                                                        Valid Until: ________
                                                    </span>
                                                    <span>
                                                        Legacy Sales & Printing
                                                        Press, Inc. TIN
                                                        003-053-883-000 VAT
                                                    </span>
                                                    <span>
                                                        A. Luna Extn., 27
                                                        Cagayan de Oro City
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={2}>
                                            <div className="d-flex justify-content-between bottom-right">
                                                <div className="p-left">
                                                    <span>
                                                        <b>
                                                            BIR Permit to Use
                                                            Loose-Leaf No.
                                                        </b>{' '}
                                                        __________
                                                    </span>
                                                    <span>
                                                        <b>
                                                            Date Issued:
                                                            _____________
                                                        </b>
                                                    </span>
                                                    <span>
                                                        PRINTER’S ACCREDITATION
                                                        NO. 098MP20190000000008
                                                    </span>
                                                    <span>
                                                        Date Issued: 03/18/19
                                                    </span>
                                                </div>
                                                <div className="p-right">
                                                    <span>
                                                        “THIS {si_title} SHALL
                                                        BE VALID FOR
                                                    </span>
                                                    <span>
                                                        FIVE (5) YEARS FROM THE
                                                        DATE OF ATP”
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 pb-0 mb-0">
                        <span className="pull-right f-12">
                            Page {page}/{totalPage}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let si = this.state.si;

        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{si.status == 'draft' ? si.status : ''}</h1>
                </div>
            */}
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default PeoplesSI01;
