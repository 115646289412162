import React, { useEffect, Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import Spinner from "../../../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { formattedDate } from "../../../../Utils/Common";
import { getAccounts, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox } from '../../../../redux/actions/settings/user.actions';
import {  Show, useAccess } from "react-redux-permission";
import { useDebounce } from 'use-debounce';

function Accounts ({userManagement: { pagination ,sort_order_name, sort_order_direction, rows_to_delete, loading, edit_url, accounts, search}, getAccounts, sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox}){
    
    const history = useHistory();
    const { hasPermission } = useAccess();
    const canCreate = hasPermission("inv.setting.users.accounts.view");
    const canDelete = hasPermission("inv.setting.users.accounts.create");
    const canEdit = hasPermission("inv.setting.users.accounts.edit");
    const canview = hasPermission("inv.setting.users.accounts.delete");

    useEffect(() => {
        getAccounts();
    },[]);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
      if (debouncedSearch) {
        if(debouncedSearch[0]){
            if(oldDebounced !== debouncedSearch[0]){
              if(debouncedSearch[0].length !== 1){
                if(canview){
                  setOldDebounced(debouncedSearch[0]);
                  getAccounts(1);
                }
              }
            }
        }
        else
        {
            if(oldDebounced !== ""){
                if(canview){
                  setOldDebounced("");
                  getAccounts(1);
                }
            }
        }
      } 
    },[debouncedSearch]);
    // END DEVOUNCE SEARCH

    const changePage = (link) => {
        history.push(link);
    }

    
    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles(
        theme => ({
            centeredHeader: {
            '& span': {
                display:'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
            },
        })
    );
    const classes = useStyles();    

    // THIS SET THE ROW BACKGROUND COLOR   
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            // MUIDataTableHeadCell: {
            //     toolButton: {
            //       justifyContent: 'center'
            //     },
            // },
            MUIDataTableBodyCell: {}
        }
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'admin_name', label: 'Name' }, 
        {name: 'admin_username', label: 'Username' }, 
        {name: 'contact_number', label: 'Contact #'},
        {name: 'admin_email', label: 'Email'},
        {name: 'subscriber_birthday', label: 'Birthday'},
        {name: 'role_name', label: 'Role', options: { filter: false, sort: false, setCellProps : value => {
            return {className : "text-center"}
        }} }, 
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20,50,100],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        elevation: 2,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `account_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getAccounts(null, numberOfRows)
        },
        onTableChange: (action, tableState) => {

            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getAccounts(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if(canDelete)
            {
              confirmDeleteDialogBox();  
            }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // Go to edit page when row is clicked
            if(canEdit)
            {
                history.push(`${edit_url}/${rowData[0]}`);
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search"  onKeyUp={handleSearchInputMUI} className="custom-search-input"/>
                </div>

            )
        }
    };

    if(!canDelete)
    {
        options.selectableRows = false;
    }

    return (
        <div className="m-1">
        
           <div className="d-flex justify-content-between mb-2">
                <h3 className="card-title">List of User Accounts <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a> </h3>
                <div>
                    <Show when="inv.setting.users.accounts.create"><Link to='/settings/users/account/create' className="btn btn-primary btn-sm btn-fix-header"><i className="ft ft-plus"></i> Create New</Link></Show>
                </div>
           </div>

            <Show when="inv.setting.users.accounts.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable data={accounts} columns={columns} options={options}/>
                </MuiThemeProvider>
            </Show> 
        </div>         
    )
}


const mapStateToProps = state => ({
    userManagement: state.userManagement
})

export default connect(mapStateToProps, { getAccounts, handleSearchInputMUI, sortTableByColumn, getSelectedRows, confirmDeleteDialogBox })(Accounts);