import React from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { Show, useAccess } from 'react-redux-permission';
import CollectionReceiptList from './CollectionReceipt.list';
import {
    CRClearFilterInputs,
    CRHandleChangeTabs,
    handleChangeStatusView
} from '../../redux/actions/collection_receipt/collection_receipt.action';

function CollectionReceipt({ CRClearFilterInputs, CRHandleChangeTabs, handleChangeStatusView }) {
    // const handleTabViews = (tabName) => {
    //     CRClearFilterInputs(); // clear the inputs
    //     CRHandleChangeTabs(tabName);
    // };

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Collections{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a
                                            href="/sales/invoices"
                                            className="text-white"
                                        >
                                            Collection Receipts
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="what-is" className="">
                                    <div className="card-content collapse show">
                                        <div className="card-body no-padding-top no-padding-left-right">
                                            <section className="row">
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs f-regular">
                                                        <li className=''>
                                                            <a
                                                            onClick={() => handleChangeStatusView("all")}
                                                            className='nav-link active'
                                                            id='base-tab1'
                                                            data-toggle='tab'
                                                            aria-controls='tab1'
                                                            href='#tabs'
                                                            aria-expanded='true'
                                                            >
                                                            All
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a
                                                            onClick={() => handleChangeStatusView("upcoming")}
                                                            className='nav-link'
                                                            id='base-tab2'
                                                            data-toggle='tab'
                                                            aria-controls='tab2'
                                                            href='#tabs'
                                                            aria-expanded='false'
                                                            >
                                                            Upcoming
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a
                                                            onClick={() =>
                                                                handleChangeStatusView("due")
                                                            }
                                                            className='nav-link'
                                                            id='base-tab3'
                                                            data-toggle='tab'
                                                            aria-controls='tab3'
                                                            href='#tabs'
                                                            aria-expanded='false'
                                                            >
                                                            Due
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a
                                                                onClick={() =>
                                                                    handleChangeStatusView("overdue")
                                                                }
                                                                className='nav-link'
                                                                id='base-tab3'
                                                                data-toggle='tab'
                                                                aria-controls='tab3'
                                                                href='#tabs'
                                                                aria-expanded='false'
                                                                >
                                                                    Overdue
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a
                                                                onClick={() =>
                                                                    handleChangeStatusView("paid")
                                                                }
                                                                className='nav-link'
                                                                id='base-tab3'
                                                                data-toggle='tab'
                                                                aria-controls='tab3'
                                                                href='#tabs'
                                                                aria-expanded='false'
                                                                >
                                                                    Paid
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content px-1 pt-1 card-box-top card">
                                                        <div
                                                            role="tabpanel"
                                                            className="tab-pane active table-responsive "
                                                            id="tabs"
                                                            aria-expanded="true"
                                                            aria-labelledby="base-tab1"
                                                        >
                                                            <Show when="inv.collections.receipts.view">
                                                                <CollectionReceiptList />
                                                            </Show>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    // upload_preview: state.collection_receipt.create_payment.upload_preview,
});

export default connect(mapStateToProps, {
    CRClearFilterInputs,
    CRHandleChangeTabs,
    handleChangeStatusView
})(CollectionReceipt);
