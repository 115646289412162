import React, { Fragment, useState, useEffect } from "react";
import Spinner, { MiniSpinner } from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {  Show, useAccess } from "react-redux-permission";
import { onlyNumber } from '../../../Utils/Common';
import Select from 'react-select';
import { getSettingApp, handleInputChange, handleSubmit, getCurrencies, setCurrencyRadio, getLanguages, getPaymentTerms, setModal, globalSettingAppSelect } from '../../../redux/actions/settings_app/setting_app.actions';
import SettingAppPaymentTermsModal from './modals/SettingApp.payment_terms.modal';
import SettingAppPaymentTermsEditModal from './modals/SettingApp.payment_terms.edit.modal';
import {timezones} from './import/timezone';
import { blockRoute } from "../../../redux/actions/helper/helper.actions";
import BeforeUnloadComponent from 'react-beforeunload-component';
import { SwalFormWarning } from "../../../Services/_swal.service";
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { toggleDrawer } from "../../../redux/actions/term_condition/term_condition.actions";
import TermConditionTable from '../SalesInvoice/PaymentTerms/TermCondition.table';

function SettingsAppApplication ({ setting_app: { single_setting_app, currencies, languages, payment_terms, taxes, mini_loading }, getSettingApp, handleSubmit, 
    getCurrencies, setCurrencyRadio, getLanguages, getPaymentTerms, setModal, blockRoute, block_route, 
    globalSettingAppSelect, toggleDrawer, toggle_list, handleInputChange}) {

    const history = useHistory();

    useEffect(() => {
        getSettingApp();
        getCurrencies();
        // getLanguages();
        getPaymentTerms();
        blockRoute(false);
    },[]);


    useEffect(() => {
        if(block_route){
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if(block_route){
            SwalFormWarning('Warning', 'Do you want to leave without saving', () => goLastLocation(), () => blockAgain())
        }
    }
    
    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    }

    const blockAgain = () => window.history.pushState(null, null, window.location.pathname);


    var tz = [];

    timezones.map((timezone) => tz.push({label: timezone, value: timezone}) );

    const tz_sorted = tz.sort((a, b) => (a.value > b.value) ? 1 : -1);


    const GlobalCss = withStyles({
        // @global is handled by jss-plugin-global.
        '@global': {
            // You should target [class*="MuiButton-root"] instead if you nest themes.
            '.MuiDrawer-paperAnchorRight': {
                right: 0,
                width: '600px',
                height: '750px',
                bottom: 0,
                marginTop: 'auto'
            }
        },
    })(() => null);

    const paymentTermsDrawerContent = () => (
        <div role="presentation" onKeyDown={() => toggleDrawer(false, 'setting_app')}>
            <div className="toggle-list-header">
                <i onClick={() => toggleDrawer(false, 'setting_app')} className="ft-arrow-right toggle-list-icon"></i>
                <span>Payment Terms</span>
            </div>
            <TermConditionTable/>
        </div>
    )

    return (
        <>
        <GlobalCss/>
        <Drawer anchor={toggle_list.position} open={toggle_list.open} onClose={() => toggleDrawer(false, 'setting_app')}>
            {paymentTermsDrawerContent()}
        </Drawer>
        <BeforeUnloadComponent 
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
                SwalFormWarning('Warning', 'Do you want to leave without saving', () => handleModalLeave(), () => handleModalCancel())
            }}
        >
        <div className="card">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header">
                <h4 className="card-title">Application  <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements">
                    <ul className="list-inline mb-0"></ul>
                </div>
            </div>
            <div className="card-content collapse show">
                <div className="card-body card-p-200">
                    <Show when="inv.setting.app.view"> 
                        {
                            mini_loading ? <MiniSpinner/> :
                            <form onSubmit={e => handleSubmit('app_settings', e)}>
                                <section className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-md-3 col-sm-12">Currency: </label>
                                            <div className="col-md-7 col-10 pr-0">
                                                <Select options={currencies} onChange={(select) => globalSettingAppSelect('currency', select)} value={single_setting_app.currency.currency_value} placeholder="Select Currency..." isSearchable={true} name="currency"/>
                                            </div>
                                            <div className="float-right pl-0 col-md-2 col-2">
                                                <div className="input-group-prepend">
                                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            single_setting_app.currency.currency_name != null && 
                                                <div className="form-group row">
                                                    <label className="col-md-3 col-sm-12">&nbsp; </label>
                                                    <div className="col-md-7 col-10 pr-0">
                                                        <div class="form-check form-check-inline mr-2">
                                                            <input onChange={setCurrencyRadio} value="symbol" checked={single_setting_app.currency.symbol.checked} class="form-check-input" type="radio" name="currency_radio" id="symbol"/>
                                                            <label class="form-check-label" for="symbol"><span className="text-secondary">Symbol:</span> &nbsp; {single_setting_app.currency.symbol.value} </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input onChange={setCurrencyRadio} value="code" checked={single_setting_app.currency.code.checked} class="form-check-input" type="radio" name="currency_radio" id="code"/>
                                                            <label class="form-check-label" for="code"><span className="text-secondary">Code: </span> &nbsp; {single_setting_app.currency.code.value} </label>
                                                        </div>
                                                    </div>  
                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                        <div className="input-group-prepend">
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                        <div className="form-group row">
                                            <label className="col-md-3 col-sm-12">Timezone: </label>
                                            <div className="col-md-7 col-10 pr-0">
                                                <Select value={single_setting_app.timezone} onChange={(select) => globalSettingAppSelect('timezone', select)} isSearchable={true} options={tz_sorted} name="timezone" required/>
                                            </div>
                                            <div className="float-right pl-0 col-md-2 col-2">
                                                <div className="input-group-prepend inv-tooltip-plus-icon">
                                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip">
                                                        <i className="la la-question-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-md-3 col-sm-12">Tax Type: </label>
                                            <div className="col-md-7 col-10 pr-0">
                                                <Select value={single_setting_app.tax_type} options={taxes} onChange={(select) => globalSettingAppSelect('tax_type', select)} isSearchable={true} name="tax_type" required/>
                                            </div>
                                            <div className="float-right pl-0 col-md-2 col-2">
                                                <div className="input-group-prepend inv-tooltip-plus-icon">
                                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip">
                                                        <i className="la la-question-circle"></i>
                                                    </span>
                                                    <i className="ft ft-plus-circle text-primary ft-icon-size p-cursor"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-md-3 col-sm-12">Tax Rate: </label>
                                            <div className="col-md-9 col-10 pr-0">
                                               <div className="tax-group">
                                                    <div className="input-group mn-width-150">
                                                        {
                                                            single_setting_app.tax_type.value === '' || single_setting_app.tax_type.value === 'none' ? <div className="form-control f-regular tax-wdth bg-disabled" disabled>0</div> : 
                                                            <input onKeyPress={e => onlyNumber(e)} value={single_setting_app.tax_rate} onChange={handleInputChange} name="tax_rate" type="text" maxLength="2" className="form-control f-regular tax-wdth" placeholder="Enter Tax Rate"/>
                                                        }
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </div>
                                                    </div>

                                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip">
                                                        <i className="la la-question-circle"></i>
                                                    </span>
                                               </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-md-3 col-sm-12">Payment Terms: </label>
                                            <div className="col-md-7 col-10 pr-0">
                                                <Select onChange={(select) => globalSettingAppSelect('payment_terms', select)} options={payment_terms} value={single_setting_app.payment_terms}  placeholder="Select Payment Terms..." isSearchable={true} name="payment_terms" required/>
                                            </div>
                                            <div className="float-right pl-0 col-md-2 col-2">
                                                <div className="input-group-prepend inv-tooltip-plus-icon">
                                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip">
                                                        <i className="la la-question-circle"></i>
                                                    </span>
                                                    <div className="a-tagicon icon-s" onClick={() => setModal('payment_terms_modal')} ><i className="ft ft-plus-circle text-primary"></i></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-md-3 col-sm-12">Language: </label>
                                            <div className="col-md-7 col-10 pr-0">
                                                <Select onChange={(select) => globalSettingAppSelect('language', select)} options={languages} value={single_setting_app.language} placeholder="Select Language..." isSearchable={true} name="language" isDisabled={true}/>
                                            </div>
                                            <div className="float-right pl-0 col-md-2 col-2">
                                                <div className="input-group-prepend">
                                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row mt-3">
                                            <label className="col-md-3 col-sm-12">&nbsp; </label>
                                            <div className="col-md-7 col-10 pr-0">
                                                <div className="float-right">
                                                    <button type="submit" className="btn btn-primary btn-save"> Save</button>
                                                </div>
                                            </div>
                                            <div className="float-right pl-0 col-md-2 col-2">
                                                <div className="input-group-prepend">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        }
                    </Show>
                </div>
            </div>
            <SettingAppPaymentTermsModal/>
            <SettingAppPaymentTermsEditModal/>
        </div>
        </BeforeUnloadComponent>
        </>
    )
}

const mapStateToProps = state => ({
    setting_app: state.setting_app,
    block_route: state.helper.block_route,
    toggle_list: state.term_condition.toggle_list
});

export default connect(mapStateToProps, { getSettingApp, handleInputChange, handleSubmit, getCurrencies, setCurrencyRadio, getLanguages, getPaymentTerms, setModal, blockRoute, 
    globalSettingAppSelect, toggleDrawer })(SettingsAppApplication);
