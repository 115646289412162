import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Spinner, { MiniSpinner } from "../../layouts/Spinner";
import {  Show, useAccess } from "react-redux-permission";
import { numberFormat, onlyNumber, formattedDate } from "../../../Utils/Common";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { setModal, handleSearchInputMUI, posDaily, posTransactionView, sortTableByColumn, searchTransaction} from '../../../redux/actions/pos/pos.actions';
import { Popover, PopoverState, ArrowContainer } from 'react-tiny-popover'
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from "moment";

function POSTransactionTable({ pos: { pos_daily, pos_cutoff , pos_cut_off_transactions, pagination, search, loading, sort_order_name, 
    sort_order_direction, set_row_checked, pos_loading, selected_row, transaction_search }, handleSearchInputMUI, posDaily, posTransactionView, sortTableByColumn, pos_daily_id, searchTransaction}) {

    const [index_loading, setIndexLoading] = useState(null);
   
    // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // const [indexOpen, setIndexOpen] = useState(null);
    // const [rowID, setRowID] = useState('');
    // const clickRef = useRef();

    // // action that takes place when click
    // const handleSetPopOver = (status, index, rowID) => {
    //     setIsPopoverOpen(status);
    //     setIndexOpen(index);
    //     setRowID(rowID)       
    // }

    // // accessing the div using ref to add function on it
    // const popOverRef = (index, rowID) => clickRef.current.onclick = handleSetPopOver(true, index, rowID);

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles(
        theme => ({
          centeredHeader: {
            '& span': {
              display:'flex',
              justifyContent: 'left',
              alignItems: 'center',
            },
          },
        })
    );
    const classes = useStyles();    

    // THIS SET THE ROW BACKGROUND COLOR   
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    '&:nth-child(odd)': { 
                        backgroundColor: "rgba(0,0,0,.05)"
                    }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    '&:nth-child(4)': {
                        width: '150px'
                    }
                }
            },
        }
    });


    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'serial_no', label: 'Transaction No.', options: {
            customBodyRender: (value, tableMeta) => {
                let rowIndex = tableMeta['rowIndex'];
                let transaction = pos_cut_off_transactions[rowIndex];
                
                const isCancelled = (transaction && transaction.status === "cancelled");
                
                return (
                    <span style={{ color: isCancelled ? 'red' : 'inherit' }}>
                        {(pos_loading === 'transaction-view' && index_loading === rowIndex) ?  
                        <LinearProgress height={60}/> : value }
                    </span>
                );
            }
        }},
        
        {name: 'created_at', label: 'Time', options: { print: false , download: false, sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let rowIndex = tableMeta['rowIndex'];
                    let transaction = pos_cut_off_transactions[rowIndex];
                    
                    const isCancelled = (transaction && transaction.status === "cancelled");
                    return ( 
                        <span style={{ color: isCancelled ? 'red' : 'inherit' }}>{ (pos_loading === 'transaction-view' && index_loading === rowIndex) ?  <LinearProgress height={60}/> : moment(value).format("hh:mmA")
                        // value.substring(12, 21) 
                        }</span>
                    )
                },
                setCellProps : value => {
                    return {className : "text-left"}
                },
            } 
        },
        {name: 'total_qty', label: 'Items', options: { print: false , download: false, sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                let rowIndex = tableMeta['rowIndex'];
                let transaction = pos_cut_off_transactions[rowIndex];
                
                const isCancelled = (transaction && transaction.status === "cancelled");
                return ( 
                    <span style={{ color: isCancelled ? 'red' : 'inherit' }}>{ (pos_loading === 'transaction-view' && index_loading === rowIndex) ?  <LinearProgress height={60}/> : value }</span> )
            },
            setCellProps : value => {
                    return {className : "text-left"}
                },
            } 
        },
        {name: 'grand_total', label: 'Total', options: { print: false , download: false, sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let rowIndex = tableMeta['rowIndex'];
                    let transaction = pos_cut_off_transactions[rowIndex];
                    
                    const isCancelled = (transaction && transaction.status === "cancelled");
                    return ( 
                        <span style={{ color: isCancelled ? 'red' : 'inherit' }}>{ (pos_loading === 'transaction-view' && index_loading === rowIndex) ?  <LinearProgress height={60}/> : value }</span> )
                },
                setCellProps : value => {
                    return {className : "text-left"}
                },
            } 
        }
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: (pagination.activePage > 0) ? pagination.activePage - 1 : 0,
        search: true,
        searchOpen: true,
        selectableRows: false,
        elevation: 2,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `pos_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            posDaily(pos_daily_id, null, numberOfRows, false);
            console.log('RUN HERE')
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    posDaily(pos_daily_id, tableState.page + 1,  pagination.itemsCountPerPage);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    // getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => { },
        onRowClick: (rowData, rowMeta) => {
            let rowID = rowData[0];
            let rowIndex = rowMeta.rowIndex;
            // popOverRef(rowIndex, rowID)
            // handleSetPopOver(true, rowIndex)
            posTransactionView(rowID, 'receipt', rowIndex);
            setIndexLoading(prevState => rowIndex);
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return { className: `cursor-pointer ${dataIndex === selected_row && 'bg-row-mui'}` }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text"  name="transaction_search" value={transaction_search} onChange={(e)=>searchTransaction(e, options, pos_daily_id)} className="custom-search-input"/>
                </div>
            )
        },
    };

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={pos_cut_off_transactions} columns={columns} options={options}/>
            </MuiThemeProvider>
        </>
    )
}

const mapStateToProps = state => ({
	pos: state.pos,
    pos_daily_id: state.pos.id
})

export default connect(mapStateToProps, { setModal, handleSearchInputMUI, posDaily, posTransactionView, sortTableByColumn, searchTransaction })(POSTransactionTable);

