import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactImageMagnify from 'react-image-magnify';
import Switch from "react-switch";
import {securityChange, loadConfig} from '../../../redux/actions/emailsettings/emailsetting.actions';

function EmailSecurity({emailsetting : {config},securityChange, loadConfig}) {
    // useState(()=>{
    //     loadConfig()
    // }, [])
    // console.log("config", config);
    return (
        <div className="card card-p-200">
            <div className="card-header">
                <ReactTooltip  effect="solid" event="click"/>
                <h4 className="card-title">Security  <a className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle text-primary"></i></a></h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group pb-0 mb-0">
                            <Switch 
                                onChange={(e) => securityChange(e)}
                                checked={config.require_password}
                                onColor="#6967CE"
                                width={62}
                                checkedIcon={ <div style={{ display:"flex", justifyContent: "center", alignItems: "center", height: "100%", paddingLeft: 4, fontSize: 16, color: "#fff" }}> Yes </div> }
                                uncheckedIcon={ <div style={{ display:"flex", justifyContent: "center", alignItems: "center", height: "100%", padding: 2, fontSize: 17, color: "#fff" }}> No </div> }
                            />
                            {/* <input type="checkbox" className="switchery" checked={config.require_password} id="manual-input" onChange={(e) => securityChange(e)} /> */}
                            <label for="manual-input" className="font-medium-2 text-bold-600 ml-1">Require password before sending email</label>&nbsp;<a className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle text-primary"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    emailsetting: state.emailsetting
});

export default connect(mapStateToProps, { securityChange, loadConfig})(EmailSecurity);
