import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    getPickAndPack,
    scanSO,
    soChecker,
    handleInputChange,
} from '../../redux/actions/pick_and_pack/pick_and_pack.actions';
import Spinner from '../layouts/Spinner';
import ScanSo from './modal/ScanSo';
import Packing from './Packing';

function Pack({
    pick_and_pack: { search, so_data, loading },
    getPickAndPack,
    scanSO,
    soChecker,
    handleInputChange,
}) {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        document.body.style.paddingTop = '0';

        return () => {
            document.body.style.paddingTop = '5rem';
        };
    }, []);

    useEffect(() => {
        soChecker();
    }, []);

    useEffect(() => {
        if (!so_data) {
            setShowModal(true); // Show modal when so_data is empty
        } else {
            setShowModal(false); // Close modal when so_data is not empty
        }
    }, [so_data]);

    if (loading) {
        return <Spinner />;
    }

    // console.log('so_data', so_data);

    return (
        <main className="pick-main">
            <section>
                <div className="row pick-row">
                    <div className="col-12 pick-col">
                        {/* Render the ScanSo modal */}
                        <ScanSo
                            show={showModal}
                            search={search}
                            handleInputChange={handleInputChange}
                            scanSO={scanSO}
                        />
                    </div>
                </div>
            </section>
            {/* Render the Packing component and pass so_data */}
            {so_data && <Packing so_data={so_data} loading={loading} />}
        </main>
    );
}

const mapStateToProps = (state) => ({
    pick_and_pack: state.pick_and_pack,
});

export default connect(mapStateToProps, {
    getPickAndPack,
    scanSO,
    soChecker,
    handleInputChange,
})(Pack);
