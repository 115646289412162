import React from 'react';
import { connect } from 'react-redux';
import BlitzDefaultContent from '../modals/PurchaseOrderPrintContent';
import MotoTruck from './MototruckTemplate';
import { getSubSetting } from '../../../Utils/Common';

function QuotationCustomTemplate ({ single_purchase_order, auth, componentRef }) {

    const subs_setting = getSubSetting()
    const template_label = single_purchase_order.template_setting ? single_purchase_order.template_setting.template_label : 'none';

    return (
        <>
            {(() => {
                 switch (template_label) {
                    case 'blitz_default':
                        return  <>
                                    <BlitzDefaultContent po={single_purchase_order} subs_setting={subs_setting} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <BlitzDefaultContent po={single_purchase_order} subs_setting={subs_setting} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'venum':
                        return  <>
                                    <BlitzDefaultContent po={single_purchase_order} subs_setting={subs_setting} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                    <BlitzDefaultContent po={single_purchase_order} subs_setting={subs_setting} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                                </>
                    case 'mototruck':
                        return  <>
                                <MotoTruck po={single_purchase_order} subs_setting={subs_setting} auth={auth} print="0" print_rows="5" /><div style={{'display':'none'}}>
                                <MotoTruck po={single_purchase_order} subs_setting={subs_setting} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
                            </>
                    default:
                        return <><h4 className="text-center my-5">-- There's No Data To Show--</h4></>;
                }
            })()}
        </>
    )
}


const mapStateToProps = state => ({
	auth: state.auth,
	single_purchase_order: state.purchase_order.single_purchase_order,
})

export default connect(mapStateToProps, {  })(QuotationCustomTemplate);
