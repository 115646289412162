import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { formattedDate } from "../../Utils/Common";
import {
  getCustomers,
  handleSearchInput,
  getSingleCustomer,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
} from "../../redux/actions/customer/customer.actions";
import { Redirect, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Show, useAccess } from "react-redux-permission";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  handleHover,
  setMUIPage,
  updateMUISetting,
} from "../../redux/actions/helper/helper.actions";

function Customer({
  customer: {
    customers,
    set_row_checked,
    pagination,
    search,
    loading,
    create_url,
    edit_url,
    history_url,
    sort_order_name,
    sort_order_direction,
  },
  getCustomers,
  handleSearchInput,
  getSingleCustomer,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  handleHover,
  hoverIndex,
  updateMUISetting,
  setMUIPage,
  muiPage,
}) {
  const history = useHistory();
  const { hasPermission } = useAccess();
  const canCreate = hasPermission("inv.sales.customers.create");
  const canDelete = hasPermission("inv.sales.customers.delete");
  const canEdit = hasPermission("inv.sales.customers.edit");
  const canview = hasPermission("inv.sales.customers.view");
  const canviewHistory = hasPermission("inv.sales.customers.history");

  useEffect(() => {
    getCustomers(muiPage);
  }, []);

  // START DEVOUNCED SEARCH
  const debouncedSearch = useDebounce(search, 800);
  const [oldDebounced, setOldDebounced] = useState("");
  useEffect(() => {
    if (debouncedSearch) {
      if (oldDebounced !== debouncedSearch[0]) {
        if (debouncedSearch[0].length !== 1) {
          if (canview) {
            setOldDebounced(debouncedSearch[0]);
            getCustomers(1);
          }
        }
      }
    }
  }, [debouncedSearch]);
  // END DEVOUNCE SEARCH

  const changePage = (link) => {
    history.push(link);
  };

  // THIS WILL SET THE HEADER ALIGN STYLE
  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      },
    },
  }));
  const classes = useStyles();

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "rgba(0,0,0,.05)",
            },
          },
        },
        MuiTableRow: {
          hover: {
            "&:hover": {
              backgroundColor: "#bfcfe4 !important",
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            "&:nth-child(2)": {
              height: "61px",
              width: "200px",
            },
            "&:nth-child(3)": {
              width: "200px",
            },
            "&:nth-child(4)": {
              width: "200px",
            },
            "&:nth-child(5)": {
              width: "200px",
            },
            "&:nth-child(6)": {
              width: "150px",
            },
          },
        },
        MUIDataTableHeadCell: {},
      },
    });

  const columns = [
    {
      name: "_id",
      label: "",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = customers[tableMeta["rowIndex"]]._id;
          return (
            <Link to={`${history_url}/${id}`} className='txt-underline'>
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: 'street1',
      label: 'Address',
      options: {
        setCellProps: (value) => {
          return { className: 'cursor-pointer' };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const customer = customers[tableMeta.rowIndex];
          const addressComponents = [
            customer.street1,
            customer.street2 && ', ',
            customer.street2,
             (customer.street1 ||customer.street2) && ', ',
            customer.city,
            customer.city &&customer.state && ', ',
            customer.state,
           ].filter(Boolean).join('');

          return <div>{addressComponents}</div>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        setCellProps: (value) => {
          return { className: "cursor-pointer text-center" };
        },
      },
    },
    {
      name: "mobile",
      label: "Contact",
      options: {
        filter: false,
        sort: false,
        setCellProps: (value) => {
          return { className: "cursor-pointer text-center" };
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        display: true,
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = customers[tableMeta["rowIndex"]]._id;

          if (!canEdit) {
            return false;
          }

          return (
            <div className='btn-group'>
              {hoverIndex == tableMeta.rowIndex && (
                <Link to={`${edit_url}/${id}`} className='btn btn-sm btn-grey'>
                  <i className='ft ft-edit'></i>
                </Link>
              )}
            </div>
          );
        },
        setCellProps: (value) => {
          return { className: "text-center" };
        },
      },
    },
  ];

  const options = {
    serverSide: true,
    jumpToPage: true,
    filterType: "dropdown",
    responsive: "standard",
    search: false,
    searchOpen: true,
    filter: false,
    rowsPerPageOptions: [10, 20],
    rowsPerPage: pagination.itemsCountPerPage,
    count: pagination.totalItemsCount,
    page: pagination.activePage - 1,
    sortOrder: {
      name: sort_order_name,
      direction: sort_order_direction,
    },
    downloadOptions: {
      filename: `customers_${formattedDate()}.csv`,
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <Spinner />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onChangeRowsPerPage: (numberOfRows) => {
      getCustomers(null, numberOfRows);
      updateMUISetting("rows", "customer", numberOfRows);
    },
    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      switch (action) {
        case "changePage":
          getCustomers(tableState.page + 1, pagination.itemsCountPerPage);
          setMUIPage(tableState.page + 1);
          break;
        case "sort":
          // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
          let column_name = tableState.sortOrder.name;
          let direction = tableState.sortOrder.direction;
          sortTableByColumn(column_name, direction);
          break;
        case "search":
          // handleSearchInputMUI(tableState.searchText);
          break;
        case "rowSelectionChange":
          let selectedRows = tableState.selectedRows.data;
          let currentRows = tableState.data;
          getSelectedRows(selectedRows, currentRows);
          break;
        case "propsUpdate":
          // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
          if (set_row_checked) {
            tableState.selectedRows["data"] = [];
            tableState.selectedRows["lookup"] = {};
            tableState.selectedRows["showResponsive"] = false;
          } else {
            return tableState;
          }
        case "viewColumnsChange":
          updateMUISetting("columns", "customer", null, tableState.columns);
          break;
        default:
          console.log("action not handled.");
      }
    },
    onRowsDelete: () => {
      // Confirm Delete Modal
      if (canDelete) {
        confirmDeleteDialogBox();
      }

      return false;
    },
    onCellClick: (colData, cellMeta) => {
      // console.log(purchase_orders[cellMeta.dataIndex]._id);
      if (cellMeta.colIndex != 6) {
        if (canviewHistory) {
          // changePage(`${history_url}/${customers[cellMeta.dataIndex]._id}`)
        }
      }
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div className='custom-search'>
          <i className='ft ft-search'></i>{" "}
          <input
            type='text'
            name='search'
            onChange={handleSearchInputMUI}
            className='custom-search-input'
          />
        </div>
      );
    },
    setRowProps: (data, dataIndex, rowIndex) => {
      return {
        onMouseEnter: () => handleHover("in", rowIndex),
        onMouseLeave: () => handleHover("out", rowIndex),
      };
    },
  };

  if (!canDelete) {
    options.selectableRows = false;
  }

  const _columns = updateMUISetting("view-columns", "customer", null, columns);

  return (
    <div className='app-content content'>
      <ReactTooltip effect='solid' event='click' />
      <div className='content-wrapper max-1920 mx-auto'>
        <div className='content-wrapper-before'></div>
        <div className='content-header row'>
          <div className='content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header'>
            <h3 className='content-header-title mb-0 d-inline-block '>
              <Link to='/customer' className='text-white'>
                Sales
              </Link>
            </h3>

            <div className='breadcrumbs-top d-inline-block'>
              <div className='breadcrumb-wrapper mr-1'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item f-regular'>
                    <a href='/sales/customers' className='text-white'>
                      {" "}
                      Customers
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Show when='inv.sales.customers.view'>
          <div className='content-body margin-top-5'>
            <section id='basic-examples'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <h4 className='card-title'>
                        List of Customers{" "}
                        <a
                          href='#'
                          className='tool-tip'
                          data-tip='this is a tool tip'
                        >
                          <i className='la la-question-circle'></i>
                        </a>
                        <Show when='inv.sales.customers.create'>
                          <Link
                            to={create_url}
                            className='btn btn-primary pull-right btn-sm'
                          >
                            <i className='ft ft-plus'></i> Create New
                          </Link>
                        </Show>
                      </h4>
                    </div>

                    <div className='card-content collapse show'>
                      <div className='card-body'>
                        <div className='col-sm-12'>
                          <MuiThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                              title={"List of Customers"}
                              data={customers}
                              columns={_columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Show>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  hoverIndex: state.helper.hoverIndex,
  muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
  getCustomers,
  handleSearchInput,
  getSingleCustomer,
  sortTableByColumn,
  handleSearchInputMUI,
  getSelectedRows,
  confirmDeleteDialogBox,
  handleHover,
  updateMUISetting,
  setMUIPage,
})(Customer);
