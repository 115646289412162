import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    fileExtensionIcon,
    numberFormat,
    onlyNumber,
} from '../../../Utils/Common';
import moment from 'moment';
import { handleHover } from '../../../redux/actions/helper/helper.actions';
import {
    handleInputChange,
    updateQty,
    submitReturn,
    resetModalInputs,
} from '../../../redux/actions/returns/return.actions';

function VieweReturnModal({
    return_stock_logs,
    show,
    onHide,
    modalData,
    handleHover,
}) {
    if (!show) return null;

    const data = modalData;

    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>Completed Return</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data ? (
                    <>
                        <div className="container">
                            {/* Stock log information */}
                            <div className="mb-2">
                                <div>
                                    <b>Movement:</b>{' '}
                                    <span>{data.movement}</span>
                                </div>
                                <div>
                                    <b> ID: </b>
                                    <span>
                                        {data._id.slice(0, 3)}...
                                        {data._id.slice(-3)}
                                    </span>
                                </div>
                                <div>
                                    <b>Date: </b>
                                    <span>
                                        {moment(data.date).format('MM/DD/YYYY')}
                                    </span>
                                </div>
                            </div>
                            <div className="row align-items-start mb-2">
                                <div className="col">
                                    <b>SKU</b>
                                    <div>{data.sku}</div>
                                </div>
                                <div className="col">
                                    <b>Product Name</b>
                                    <div>{data.product_name}</div>
                                </div>
                                <div className="col">
                                    <b>Description</b>
                                    <div>{data.description}</div>
                                </div>
                            </div>
                            <div className="row align-items-start mb-2">
                                <div className="col">
                                    <b>Reference Type</b>
                                    <div>{data.reference_type}</div>
                                </div>
                                <div className="col">
                                    <b>Reference No.</b>
                                    <div>{data.reference_no}</div>
                                </div>
                                <div className="col">
                                    <b>Supplier</b>
                                    <div>{data.supplier}</div>
                                </div>
                            </div>
                            <div className="row align-items-start mb-2">
                                <div className="col">
                                    <b>Returned Qunatity</b>
                                    <div>{data.quantity}</div>
                                </div>
                                <div className="col">
                                    <b>Cost</b>
                                    <div>{data.cost}</div>
                                </div>
                                <div className="col">
                                    <b>SRP</b>
                                    <div>{data.srp}</div>
                                </div>
                            </div>
                        </div>

                        {/* Quantity adjustment controls */}
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <b>Remarks: </b>
                                    {data.remarks}
                                </div>
                                {data.uploads &&
                                    data.uploads.length > 0 &&
                                    data.uploads.map((upload, index) => (
                                        <div className="mt-3">
                                            <div
                                                onMouseEnter={handleHover}
                                                title={
                                                    upload.name.slice(0, 20) +
                                                    (upload.name.length > 20
                                                        ? '...'
                                                        : '')
                                                }
                                                onMouseLeave={() =>
                                                    handleHover('out')
                                                }
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <div className="min-wdth-300">
                                                    <span className="file-icon-print">
                                                        <img
                                                            src={fileExtensionIcon(
                                                                upload.name
                                                            )}
                                                            alt="file-types"
                                                        />
                                                    </span>
                                                    <div className="text-secondary file-icon-text">
                                                        {upload.name.slice(
                                                            0,
                                                            20
                                                        ) +
                                                            (upload.name
                                                                .length > 20
                                                                ? '...'
                                                                : '')}
                                                    </div>
                                                </div>

                                                <div className="btn-group">
                                                    <a
                                                        href={upload.file_path}
                                                        download
                                                        target="_blank"
                                                        className="btn btn-blue btn-sm"
                                                    >
                                                        <i className="ft ft-download"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                ) : (
                    'No data available'
                )}
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    show: state.returns.return_stock_modal,
    modalData: state.returns.modalData,
    return_stock_logs: state.returns.return_stock_logs,
});

export default connect(mapStateToProps, {
    handleInputChange,
    updateQty,
    submitReturn,
    resetModalInputs,
    handleHover,
})(VieweReturnModal);
