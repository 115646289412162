import React, { useEffect, useState, Fragment } from "react";
import Spinner from "../../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import Logs from "./Developer/Logs";
import UserLog from "./Users/UserLog";
import EmailLogs from './EmailLogs/EmailLogs';
import { Link } from 'react-router-dom';

function SystemSettings (){

    const [selectedTab,SetSelectedTab] = useState('Logs');

    const [tabs, setTabs] = useState([
        { key: 'Logs', class : 'nav-link inv-top-nav inv-top-nav-active mr-30' },
        { key: 'Developer Logs', class : 'nav-link inv-top-nav mr-30' },
        { key: 'Email Logs', class : 'nav-link inv-top-nav mr-30' },
    ]);

    function handleChangeTab(tab)
    {
      SetSelectedTab(tab);
      tabs.map((list) => {
        list.class = list.key == tab ? "nav-link inv-top-nav inv-top-nav-active mr-30": "nav-link inv-top-nav mr-30";
        return list;
      });
      setTabs(tabs);
    }
    

    console.log(selectedTab, tabs)
    return (
        <div className="app-content content">
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><a href="/settings/system" className="text-white">Logs</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                       <div className="">
                                            <nav class="nav f-regular">
                                                {
                                                    tabs.map(item => ( <Link onClick={() => handleChangeTab(item.key) } class={item.class}>{item.key}</Link> ))
                                                }
                                            </nav>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show">
                                    <div className="card-body no-padding-top no-padding-left-right">
                                            <section className="row">
                                            <div className="col-sm-12">
                                                <div className="tab-content px-1 pt-1 card-box-top card">
                                                    {(() => {
                                                        switch(selectedTab){
                                                            case 'Logs':
                                                                return <UserLog/>; 
                                                            case 'Developer Logs':
                                                                return <Logs/>;
                                                            case 'Email Logs': 
                                                                return <EmailLogs/>
                                                            default: 
                                                                return null;
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </section>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    // user_log: state.user_log
})

export default connect(mapStateToProps, {  })(SystemSettings);