import {  SET_LOADING, CLEAR_PRODUCT_UNIT, GET_PRODUCT_UNITS, SEARCH_INPUT, SET_PAGE, PRODUCT_UNIT_INPUT_CHANGE, 
    GET_SINGLE_PRODUCT_UNIT, SET_MINI_LOADING, SORT_BY_COLUMN, GET_ROWS_TO_DELETE, SET_ROW_CHECKED  } from '../types';

const initialState = {
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    product_units: [],
    single_product_unit: [],
    search: '',
    loading: false,
    main_page: false,
    edit_page: false,
    delete_modal: false,
    main_url: '/settings/product-units',
    create_url: '/settings/product-units/create',
    edit_url: '/settings/product-units/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false
}

const productUnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_UNITS:
            return {
                ...state,
                product_units: action.payload.product_units,
                pagination: action.payload.pagination,
                set_row_checked: false
            }
        case GET_SINGLE_PRODUCT_UNIT:
            return {
                ...state,
                single_product_unit: action.payload
            }
        case PRODUCT_UNIT_INPUT_CHANGE:
            return {
                ...state,
                single_product_unit: { ...state.single_product_unit, [action.payload.key]: action.payload.value }
            }
        case SET_PAGE:
            if(action.payload.page == 'main_page')
            {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                }
            }
            else if(action.payload.page == 'edit_page')
            {
                return {
                    ...state,
                    main_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status
                }
            }
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload
            }
        case CLEAR_PRODUCT_UNIT:
            return {
                ...state,
                single_product_unit: [],
                search: '',
                loading: false,
                main_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/settings/product-units',
                create_url: '/settings/product-units/create',
                edit_url: '/settings/product-units/edit',
            }
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload
            }
        case SET_ROW_CHECKED: 
            return {
                ...state,
                set_row_checked: true
            }
        default:
            return state;
    }
}

export default productUnitReducer;