export const GET_COLLECTION_RECEIPT = 'GET_COLLECTION_RECEIPT';
export const GET_SINGLE_COLLECTION_RECEIPT = 'GET_SINGLE_COLLECTION_RECEIPT';
export const CLEAR_COLLECTION_RECEIPT = 'CLEAR_COLLECTION_RECEIPT';
export const CR_INPUT_CHANGE = 'CR_INPUT_CHANGE';
export const CR_SEARCH = 'CR_SEARCH';
export const SET_PAGE = 'SET_PAGE';
export const TAB_VIEW_CHANGE = 'TAB_VIEW_CHANGE';
export const CR_HANDLE_SELECT = 'CR_HANDLE_SELECT';
export const CR_GET_PAYMENT_TERMS = 'CR_GET_PAYMENT_TERMS';
export const CR_DATE_FILTER_INPUT = 'CR_DATE_FILTER_INPUT';
export const CR_CLEAR_FILTER_INPUTS = 'CR_CLEAR_FILTER_INPUTS';
export const CR_API_FILTER = 'CR_API_FILTER';
export const CR_DRAWER_TOGGLE = 'CR_DRAWER_TOGGLE';
export const CR_SET_MODAL = 'CR_SET_MODAL';
export const CR_HANDLE_CREATE_PAYMENT = 'CR_HANDLE_CREATE_PAYMENT';
export const CR_HANDLE_UPLOAD = 'CR_HANDLE_UPLOAD';
export const CR_HANDLE_REMOVE_UPLOAD = 'CR_HANDLE_REMOVE_UPLOAD';
export const GET_CR_STATUS = 'GET_CR_STATUS';
export const SET_SELECTED_PAYMENT = 'SET_SELECTED_PAYMENT';