import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { numberFormat, onlyNumber } from '../../Utils/Common';
import {
    SupplierCostOnChange,
    handleSelectSupplier,
    setModal,
    DoNotTrackSwitch,
    removeSupplier,
    editCost
} from './../../redux/actions/product/product.actions';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Show, useAccess } from 'react-redux-permission';
import Switch from 'react-switch';

function ProductCost({
    product: {
        single_product: {
            supplier_id_default,
            is_track,
            mute_stock,
            mute_threshold,
        },
        suppliers,
        editable_cost
    },
    SupplierCostOnChange,
    handleSelectSupplier,
    setModal,
    DoNotTrackSwitch,
    removeSupplier,
    origin,
    editCost
}) {
    const { hasPermission } = useAccess();
    const [editableSupplier, setEditableSupplier] = useState(null);

    const handleEditClick = (supplierLabel) => {
        setEditableSupplier(supplierLabel);
    };

    // console.log("supplier_id_default", supplier_id_default)

    return (
        <Fragment>
            <ReactTooltip effect="solid" event="click" />
            <h4 className="form-section">
                <i className="ft-clipboard"></i> Supplier:
            </h4>

            <div className="row mt-2 justify-content-center f-regular">
                <div className="col-md-6 col-12">
                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Track Inventory:
                        </label>
                        <div className="col-md-7 col-10">
                            <Switch
                                onChange={(event) =>
                                    DoNotTrackSwitch(event, 'is_track')
                                }
                                checked={is_track}
                                onColor="#6967CE"
                                width={62}
                                checkedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            paddingLeft: 4,
                                            fontSize: 16,
                                            color: '#fff',
                                        }}
                                    >
                                        {' '}
                                        Yes{' '}
                                    </div>
                                }
                                uncheckedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            padding: 2,
                                            fontSize: 17,
                                            color: '#fff',
                                        }}
                                    >
                                        {' '}
                                        No{' '}
                                    </div>
                                }
                            />
                        </div>
                        {/* <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>   */}
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Mute stock alarm:
                        </label>
                        <div className="col-md-7 col-10">
                            <Switch
                                onChange={(event) =>
                                    DoNotTrackSwitch(event, 'mute_stock')
                                }
                                checked={mute_stock}
                                onColor="#6967CE"
                                width={62}
                                checkedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            paddingLeft: 4,
                                            fontSize: 16,
                                            color: '#fff',
                                        }}
                                    >
                                        {' '}
                                        Yes{' '}
                                    </div>
                                }
                                uncheckedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            padding: 2,
                                            fontSize: 17,
                                            color: '#fff',
                                        }}
                                    >
                                        {' '}
                                        No{' '}
                                    </div>
                                }
                            />
                        </div>
                        {/* <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                            </div>
                        </div>   */}
                    </div>
                </div>
            </div>

            <div className="row justify-content-center f-regular">
                <div className="col-md-6 col-12">
                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Suppliers <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6 col-9">
                            <Select
                                placeholder="Select Supplier..."
                                isSearchable={true}
                                onChange={(select) =>
                                    handleSelectSupplier(select, origin)
                                }
                                value={supplier_id_default}
                                name="supplier_id"
                                options={suppliers}
                                isMulti
                                className="basic-multi-select product-select-supplier"
                                classNamePrefix="select"
                                required
                            />
                        </div>
                        <div className="float-left pl-0 col-1 col-md-1">
                            <Show when="inv.purchases.suppliers.create">
                                <div
                                    className="a-tagicon icon-s"
                                    onClick={() =>
                                        setModal('supplier_modal', true)
                                    }
                                >
                                    <i className="ft ft-plus-circle text-primary"></i>
                                </div>
                            </Show>
                        </div>
                        <div className="float-right pl-0 col-1 col-md-1">
                            <div className="input-group-prepend">
                                <span
                                    href="#"
                                    className="tool-tip text-primary"
                                    data-tip="this is a tool tip"
                                >
                                    <i className="la la-question-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    {supplier_id_default.length > 0 ? (
                        <div>
                            <div className="row mb-2 f-regular">
                                <label className="col-md-4 col-6">
                                    Supplier
                                </label>
                                <label className="col-md-4 col-6">
                                    Cost <span className="text-danger">*</span>
                                </label>
                                <label className="col-md-4 col-6">
                                    Initial Stock{' '}
                                </label>
                            </div>
                            {supplier_id_default &&
                                supplier_id_default.map((supplier, key) => (
                                    <Fragment>
                                        <div className="form-group row mb-1 f-regular">
                                            <label className="col-md-4 col-6 label-control">
                                                <small>{supplier.label}</small>
                                            </label>
                                            <div className="col-md-4 col-6">
                                                {/* Disabled temporary due to product lot feature */}
                                                {/* <input onKeyPress={e => onlyNumber(e)} type="number" onChange={e => SupplierCostOnChange(key, e)} value={supplier.cost} step="any" name="cost" className="form-control" min={0} required/> */}
                                                {supplier.old ||
                                                supplier.restored ? (
                                                    // <div className="form-control bg-disabled">
                                                    //     {supplier.cost}
                                                    // </div>
                                                    <>
                                                    {editableSupplier === supplier.label ? (
                                                        <input
                                                            onKeyPress={(e) => onlyNumber(e)}
                                                            type="number"
                                                            onChange={(e) => SupplierCostOnChange(key, e)}
                                                            value={supplier.cost}
                                                            step="any"
                                                            name="cost"
                                                            className="form-control"
                                                            min={0}
                                                            required
                                                        />
                                                    ) : (
                                                        <div className="form-control bg-disabled">
                                                            {supplier.cost}
                                                            <a
                                                                className='ml-10'
                                                                onClick={() => handleEditClick(supplier.label)}
                                                            >
                                                                <i className='icon-lock'></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </>
                                                ) : (
                                                    <input
                                                        onKeyPress={(e) =>
                                                            onlyNumber(e)
                                                        }
                                                        type="number"
                                                        onChange={(e) =>
                                                            SupplierCostOnChange(
                                                                key,
                                                                e
                                                            )
                                                        }
                                                        value={supplier.cost}
                                                        step="any"
                                                        name="cost"
                                                        className="form-control"
                                                        min={0}
                                                        required
                                                    />
                                                )}
                                            </div>
                                            {supplier.old ||
                                            supplier.restored ? (
                                                <div className="col-md-4 col-6">
                                                    <div className="d-flex">
                                                        <div className="form-control bg-disabled">
                                                            {
                                                                supplier.initial_stock
                                                            }
                                                        </div>
                                                        <button
                                                            onClick={() =>
                                                                removeSupplier(
                                                                    supplier,
                                                                    origin
                                                                )
                                                            }
                                                            type="button"
                                                            className="btn btn-mini btn-sm btn-danger product-supplier-btn"
                                                        >
                                                            <i className="ft ft-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="col-md-4 col-6">
                                                    <div className="d-flex">
                                                        <input
                                                            onKeyPress={(e) =>
                                                                onlyNumber(e)
                                                            }
                                                            type="number"
                                                            onChange={(e) =>
                                                                SupplierCostOnChange(
                                                                    key,
                                                                    e
                                                                )
                                                            }
                                                            value={
                                                                supplier.initial_stock
                                                            }
                                                            step="any"
                                                            name="initial_stock"
                                                            className="form-control"
                                                            min={0}
                                                            required
                                                        />
                                                        <button
                                                            onClick={() =>
                                                                removeSupplier(
                                                                    supplier,
                                                                    'new'
                                                                )
                                                            }
                                                            type="button"
                                                            className="btn btn-mini btn-sm btn-danger product-supplier-btn"
                                                        >
                                                            <i className="ft ft-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Fragment>
                                ))}
                        </div>
                    ) : (
                        <h4 className="text-center mt-2">
                            -- Please Select Supplier --
                        </h4>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    product: state.product,
});

export default connect(mapStateToProps, {
    SupplierCostOnChange,
    handleSelectSupplier,
    setModal,
    DoNotTrackSwitch,
    removeSupplier,
    editCost
})(ProductCost);
