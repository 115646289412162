import * as TYPE from '../../types';
import { CAT_SEARCH, SET_PAGE } from '../../reducers/ReducerTypes/category';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from 'jquery';
import { CategoryService } from './_service.category';
import { SwalWarning, SwalWarningHtml } from '../../../Services/_swal.service';
import {
  getSelectAll,
  setModal,
  handleSelect,
} from '../product/product.actions';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { getNumRows } from '../../../Utils/Common';

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
  if (e.target.value !== '') {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({
    type: TYPE.CATEGORY_INPUT_CHANGE,
    payload: { key: e.target.name, value: e.target.value },
  });
};

// handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
  dispatch({ type: CAT_SEARCH, payload: e.target.value });
};

export const handleSearchInputMUI = (e) => async (dispatch) => {
  dispatch({ type: CAT_SEARCH, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) =>
  dispatch({ type: TYPE.SET_LOADING, payload: status });

// clear data
export const clearCategory = () => async (dispatch) =>
  dispatch({ type: TYPE.CLEAR_CATEGORY });

// set modal
export const setPage =
  (page, id, status = true) =>
  async (dispatch, getState) => {
    let { edit_url } = getState().category;

    if (page == 'category_page') {
      dispatch({ type: SET_PAGE, payload: { page, status } });
      dispatch(clearCategory());
    } else if (page == 'edit_page') {
      // adding the id in url
      let edit_url_with_id = `${edit_url}/${id}`;

      dispatch({
        type: SET_PAGE,
        payload: { page, status, edit_url: edit_url_with_id },
      });
    } else {
      // delete modal
      dispatch(getSingleCategory(id, 'delete'));
    }
  };

// Parent Category checkbox
export const parentCategoryCheckbox = (e) => async (dispatch) => {
  if (e.target.checked) {
    dispatch(blockRoute(true));
  } else {
    dispatch(blockRoute(false));
  }

  dispatch({ type: TYPE.PARENT_CATEGORY_CHECKED, payload: e.target.checked });
};

// parent category select
export const handleSelectParentCategory =
  (selectOption) => async (dispatch) => {
    if (selectOption.value) {
      dispatch(blockRoute(true));
    } else {
      dispatch(blockRoute(false));
    }

    let selected_parent_category = {
      value: selectOption.value,
      label: selectOption.label,
    };
    //console.log(selected_parent_category);
    dispatch({
      type: TYPE.SET_PARENT_CATEGORY,
      payload: selected_parent_category,
    });
  };

//  fetch categories
export const getCategories =
  (pageNumber, rows_per_page = null, isInputSearch = false) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    let { cat_search, pagination, sort_order_name, sort_order_direction } =
      getState().category;
    let rows_per_page_val =
      rows_per_page !== null ? rows_per_page : getNumRows('category');

    // let searchPostParams = {
    //   search: cat_search,
    //   activePage: pagination.activePage,
    //   page: pageNumber,
    //   rows_per_page: rows_per_page_val,
    //   sort_order_name: sort_order_name,
    //   sort_order_direction: sort_order_direction,
    //   is_input_searching: isInputSearch, // true is search is triggerd through typing
    // };

    /// new search function
    let searchPostParams = {
      search: cat_search,
      options: {
        page: pageNumber ? pageNumber : pagination.activePage,
        limit: rows_per_page_val ? rows_per_page_val : 10,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };

    CategoryService.paginateWithSearchCategories(searchPostParams)
      .then((res) => {
      //  console.log(res.data);
        // let pagination = {
        //   totalCount: res.data.categories.total,
        //   activePage: res.data.categories.current_page,
        //   itemsCountPerPage: res.data.categories.per_page,
        //   totalItemsCount: res.data.categories.total,
        // };

        let pagination = {
          totalCount: res.data.total_docs,
          activePage: res.data.page,
          itemsCountPerPage: res.data.limit,
          totalItemsCount: res.data.total_docs,
        };

        dispatch({
          type: TYPE.GET_CATEGORIES,
          payload: {
            categories: res.data.docs,
            pagination: pagination,
          },
        });
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));

        // if (err.data.errors.length > 0) {
        //   err.data.errors.map((err) => ToastDanger(err));
        // } else {
        //   ToastDanger("Network error, please reload the page.");
        // }

        console.log(err);
      });
  };

// fetch Parent Category
export const getParentCategory =
  (category_id = null) =>
  async (dispatch) => {
    // should not load current id in the select
    let params = { category_id: category_id };

    let x = 0;

    while (x < 3) {
      try {
        const { data, success } = await CategoryService.getParentCategory(
          params
        );

        let parent_category = data.parent_category;
        let parent_catgory_arr = [];

        parent_category.map((parent, i) => {
          let select = { value: parent._id, label: parent.name };
          parent_catgory_arr[i] = select;
        });

        dispatch({
          type: TYPE.GET_PARENT_CATEGORY,
          payload: parent_catgory_arr,
        });

        x = 4;
      } catch (error) {
        // ToastDanger('Network error, please reload the page.');
        console.log(error);
        x++;
      }
    }
  };

// fetch single category
export const getSingleCategory =
  (id, status = null) =>
  async (dispatch) => {
    CategoryService.getSingleCategory(id)
      .then((res) => {
        let category = res.data.category;
        let parent_category = res.data.category.parent_category;
        let default_parent_category = '';

        category['parent_category_checked'] =
          category.parent_category_id === null ||
          category.parent_category_id === '' ||
          category.parent_category_id === 0
            ? true
            : false;

        if (parent_category) {
          default_parent_category = {
            value: category.parent_category._id,
            label: category.parent_category.name,
          };
        }

        // except its own id
        dispatch(getParentCategory(res.data.category._id));

        dispatch({
          type: TYPE.GET_SINGLE_CATEGORY,
          payload: category,
          default_parent_category: default_parent_category,
        });

        if (status == 'delete') {
          dispatch(archive());
        }
      })
      .catch((error) => {
        alert(`An Error Occured! ${error}`);
        console.log(error);
      });
    // console.log(res.data.supplier)
  };

// store
export const categoryPost = (e, pageOrigin) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_category } = getState().category;

  $('.btn-save').attr('disabled', 'disabled').html('Saving...');

  if(pageOrigin === 'item'){
    single_category['pull'] = true;
  }

  dispatch(setMUIPage(1));
  CategoryService.categoryPost(single_category)
    .then((res) => {
      if (pageOrigin == 'category') {
        dispatch(setPage('category_page'));
      }

      if (pageOrigin == 'item') {
        dispatch(getSelectAll());
        dispatch(setModal('category_modal', false));
        let categorySelect = {
          value: res.data.docs._id,
          label: res.data.docs.name,
        };
        dispatch(handleSelect(categorySelect, 'category_id'));
      }

      dispatch(clearCategory());
      ToastSuccess(res.data.message);

      $('.btn-save').removeAttr('disabled').html('Save');
    })
    .catch((error) => {
      if (error.data.errors.length > 0) {
        ToastDanger(error.data.errors);
      } else {
        ToastDanger('Network error, please reload the page.');
      }
      // ToastDanger("Network error, please reload the page.");
      $('.btn-save').removeAttr('disabled').html('Save');
      console.log(error);
    });
};

// update
export const updateCategory = (e) => async (dispatch, getState) => {
  e.preventDefault();

  let { single_category } = getState().category;

  $('.btn-update').attr('disabled', 'disabled').html('Updating...');

  CategoryService.updateCategory(single_category, single_category._id)
    .then((res) => {
      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.errors);
          break;
        case 1:
          dispatch(setPage('category_page'));
          ToastSuccess(res.data.message);
          break;
        default:
          break;
      }

      $('.btn-update').removeAttr('disabled').html('Update');
    })
    .catch((error) => {
      ToastDanger('Network error, please reload the page.');
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        ToastDanger('Network error, please reload the page.');
      }
      // ToastDanger("Network error, please reload the page.");
      $('.btn-update').removeAttr('disabled').html('Update');
      console.log(error);
    });
};

export const archive = () => async (dispatch, getState) => {
  let { single_category } = getState().category;

  SwalWarning(
    'Warning!',
    `Do you want to remove the Category: ${single_category.name}?`,
    () => dispatch(moveToArchives())
  );
};

// archive
export const moveToArchives = (data) => async (dispatch, getState) => {
  let { rows_to_delete } = getState().category;

  let id_arr = [];
  rows_to_delete.map((row) => {
    id_arr.push(row.id);
  });

  let params = {
    ids: JSON.stringify(id_arr),
  };

  CategoryService.ArchiveCategory(params)
    .then((res) => {
      dispatch(setRowChecked());

      switch (res.data.status) {
        case 0:
          ToastDanger(res.data.message);
          break;
        case 1:
          ToastSuccess(res.data.message);
          dispatch(getCategories());
          dispatch(clearCategory());
        default:
          break;
      }
    })
    .catch((error) => {
      ToastDanger('Network error, please reload the page.');
      if (error.data.errors.length > 0) {
        error.data.errors.map((err) => ToastDanger(err));
      } else {
        ToastDanger('Network error, please reload the page.');
      }
      // ToastDanger("Network error, please reload the page.");
      console.log(error);
    });
};

// Sort by column name and direction
export const sortTableByColumn =
  (sort_order_name, sort_order_direction) => async (dispatch) => {
    let sortingParams = {
      sort_order_name: sort_order_name,
      sort_order_direction: sort_order_direction,
    };

    dispatch({ type: TYPE.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getCategories());
  };

// returns selected rows only
export const getSelectedRows =
  (selectedRows, currentRows) => async (dispatch) => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current, i) => {
      selectedRows.map((selected, _i) => {
        if (selected.index == current.index) {
          let selected = { id: current.data[0], name: current.data[1] };
          newArray.push(selected);
        }
      });
    });
    // console.log(newArray);
    dispatch({ type: TYPE.GET_ROWS_TO_DELETE, payload: newArray });
  };

export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
  let { rows_to_delete } = getState().brand;
  let names = [];
  rows_to_delete.map((rows, i) => {
    names.push('<b>' + rows.name + '</b>');
  });

  SwalWarningHtml(
    'Warning!',
    `Do you want to remove the Category: ${names.join()}?`,
    () => dispatch(moveToArchives())
  );
};

// set the rows unchecked
export const setRowChecked = () => async (dispatch) =>
  dispatch({ type: TYPE.SET_ROW_CHECKED });
