import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { emailType, formattedDate } from "../../../Utils/Common";
import { updateMUISetting, handleHover } from "../../../redux/actions/helper/helper.actions";
import { getLogs, sortTableByColumn, setModal, emailConfig, reSendEmail, sendEmailDyanmic, setPage } from '../../../redux/actions/emailsettings/emailsetting.actions';
import EmailSingleLogModal from './Email.singlelog.modal';
import EmailSinglelogModal from "./Email.singlelog.modal";
import {setPage as PoModal} from '../../../redux/actions/purchase_order/purchase_order.actions';
import PurchaseOrderModalPrint from '../../purchase_order/modals/PurchaseOrder.modal.print';

function EmailLogsTable ({emailsetting,purchase_order, getLogs, 
    sortTableByColumn, updateMUISetting, setModal, handleHover, hoverIndex, emailConfig, reSendEmail, sendEmailDyanmic, setPage, PoModal}) {

    const history = useHistory();
    
    useEffect(() => {
        getLogs();
        emailConfig()
    },[]);

    const changePage = (link) => {
        history.push(link);
    }

    const emailType = (type) => {
        switch (type) {
          case 'po':
            return 'Purchase Order';
          case 'si':
            return 'Sales Invoice';
          case 'qn':
            return 'Quotation';
          case 'receiving':
            return 'Item Receipt';
          case 'dr':
            return 'Delivery Receipt'
          default:
            return ''
        }
    }


    // THIS SET THE ROW BACKGROUND COLOR   
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            // MUIDataTableHeadCell: {
            //     toolButton: {
            //       justifyContent: 'center'
            //     },
            // },
            MUIDataTableBodyCell: {}
        }
    });


    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false, setCellProps : value => {
            return {className : "cursor-pointer "}
        }} }, 
        {name: 'reference_no', label: 'Reference No.', options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                let id = emailsetting.logs[tableMeta['rowIndex']].reference_id;
                let type = emailsetting.logs[tableMeta['rowIndex']].email_type
                return (
                    <Link className="txt-underline" data-id={id} onClick={() => setPage(id, type)}>{value}</Link>
                )
            },
                setCellProps : value => {
                    return {className : "cursor-pointer "}
                }
            }
        },
        {name: 'email_type', label: 'Email Type', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (    
                        <><span>{emailType(value)}</span></>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer "}
                }
            }
        }, 
        {name: 'email_receiver', label: 'Email Receiver', options: {
                setCellProps : value => {
                    return {className : "cursor-pointer "}
                }
            }
        },
        {name: 'email_subject', label: 'Email Subject', options: {
                setCellProps : value => {
                    return {className : "cursor-pointer "}
                }
            }
        },
        {name: 'email_sent', label: 'Status', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (    
                       <>{value === 1 ? <span className="lbl-status lbl-success">SUCCESS</span> : <span className="lbl-status lbl-danger">FAILED</span> }</>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer "}
                }
            }
        },
        {name: 'created_at', label: 'Created at', options: { 
                print: false , download: false ,
                setCellProps : value => {
                    return {className : "cursor-pointer "}
                }
            } 
        },
        {
            name : '', label : 'Action', options : {
                print: false , download: false ,empty : true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = emailsetting.logs[tableMeta['rowIndex']]._id;
                    return (
                        <div className={(hoverIndex == tableMeta.rowIndex || emailsetting.logs[tableMeta['rowIndex']].single_email ? "" : "invisible ") + "btn-group "}>
                            <button className="btn btn-sm btn-grey btn-sm" onClick={() =>setModal('log_modal', true, id)}><i className="ft ft-search"></i></button>
                            {
                                emailsetting.logs[tableMeta['rowIndex']].email_sent == 0   &&
                                <button className={ " btn btn-warning btn-sm"} onClick={(e) =>sendEmailDyanmic(e, id, tableMeta.rowIndex)}>
                                    
                                    {
                                        emailsetting.logs[tableMeta['rowIndex']].single_email ?
                                        <i className="icon-loading-custom icon-15"></i>
                                        :
                                        <i className="custom-resend"></i>
                                    }
                                </button>
                            }
                        </div>
                    )
                },
                setCellProps: (value) => {
                    return {className : "cursor-pointer text-center"}
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20,50,100,200],
        rowsPerPage: emailsetting.pagination.itemsCountPerPage ? emailsetting.pagination.itemsCountPerPage : 1,
        count: emailsetting.pagination.totalItemsCount,
        page: emailsetting.pagination.activePage - 1,
        search: false,
        searchOpen: true,
        selectableRows: false,
        elevation: 2,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        sortOrder: {
            name: emailsetting.sort_order_name,
            direction: emailsetting.sort_order_direction
        },
        downloadOptions: {
            filename: `email_logs_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: emailsetting.loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getLogs(null, numberOfRows)
        },
        onTableChange: (action, tableState) => {

            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getLogs(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    // getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(emailsetting.set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            // if(canDelete)
            // {
            //     confirmDeleteDialogBox();
            // }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // console.log(rowData[0])
            // Go to edit page when row is clicked
            // if(canEdit)
            // {
            //     changePage(`${edit_url}/${rowData[0]}`);
            // }
            // setModal('log_modal', true, rowData[0])
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return { onMouseEnter: () => handleHover('in', rowIndex), onMouseLeave: () => handleHover('out', rowIndex) }
        },  
    };

    // if(!canDelete)
    // {
    //   options.selectableRows = false;
    // }

    const _columns = updateMUISetting('view-columns', 'email_logs', null, columns);

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={emailsetting.logs} columns={_columns} options={options}/>
            </MuiThemeProvider>
            <EmailSinglelogModal/>
            <PurchaseOrderModalPrint show={emailsetting.print_modal.po} onHide={PoModal('print_modal', null, false)} edit_url={emailsetting.edit_url.po} />
        </>
    )
}

const mapStateToProps = state => ({
    emailsetting: state.emailsetting,
    hoverIndex: state.helper.hoverIndex,
});


export default connect(mapStateToProps, { getLogs, sortTableByColumn, updateMUISetting, setModal, handleHover, emailConfig, reSendEmail, sendEmailDyanmic, setPage, PoModal })(EmailLogsTable);

