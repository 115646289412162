import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactImageMagnify from 'react-image-magnify';
import { loadConfig, dynamicChange, passwordtoggle, toggle_test, submitConfig, clickBtn, testEmail} from '../../../redux/actions/emailsettings/emailsetting.actions';

function EmailConfig({emailsetting : {config, password_toggle, show_test, btn_lbl, test_result}, loadConfig, dynamicChange, passwordtoggle, toggle_test, submitConfig,
    clickBtn, testEmail}) {
    
    useEffect(() => {
        loadConfig()
    }, [])
    return (
        <div className="card card-p-200">
            
            <div className="card-header">
                <ReactTooltip  effect="solid" event="click"/>
                <h4 className="card-title">Configurations  <a  className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle text-primary"></i></a></h4>
            </div>
            <div className="card-body">
                <form className="row" onSubmit={(e) => submitConfig(e)}>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-md-4 col-sm-12">Host: </label>
                            <div className="col-md-7 col-11">
                                <input type="text" className="form-control" name="host" value={config.host} onChange={(e) => dynamicChange(e)} required />
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-sm-12">Port: </label>
                            <div className="col-md-7 col-11">
                                <input type="text" className="form-control" name="port" value={config.port} onChange={(e) => dynamicChange(e)} required />
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-sm-12">Protocol: </label>
                            <div className="col-md-7 col-11">
                                <select className="form-control" name="protocol" value={config.protocol} onChange={(e) => dynamicChange(e)}>
                                    <option value="none">None</option>
                                    <option value="ssl">SSL</option>
                                    <option value="tls">TLS</option>
                                </select>
                                {/* <input type="text" className="form-control" required /> */}
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-md-4 col-sm-12">Authentication: </label>
                            <div className="col-md-7 col-11">
                                <input type="text" className="form-control" required />
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div> */}
                        <div className="form-group row">
                            <label className="col-md-4 col-sm-12">Usename: </label>
                            <div className="col-md-7 col-11">
                                <input type="email" className="form-control" name="username" value={config.username} onChange={(e) => dynamicChange(e)} required />
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-sm-12">Password: </label>
                            <div className="col-md-7 col-11">
                                <div className="input-group">
                                    <input type={password_toggle} name="password" className={ " form-control br-0" } onChange={(e) => dynamicChange(e)}  autoComplete="off" value={config.password} required/>
                                    <div className="input-group-append">
                                        <button className=" btn btn-eye" data-index="new_pass" type="button" onClick={(e) =>passwordtoggle(e)}><i className={password_toggle == 'password' ? 'la la-eye-slash' : 'la la-eye'}></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span>
                                </div>
                            </div>  
                        </div>
                        <div className="form-group row">
                            <label className="col-md-11">Send Test Email</label>
                            {/* <div className="float-right pl-0 col-1 col-md-1">
                                <div className="input-group-prepend">
                                    <a className="tool-tip text-primary" onClick={(e) => toggle_test(e)}><i className={"la la-chevron-circle-" + (show_test ? 'down' : 'left')}></i></a>
                                </div>
                            </div> */}
                        </div>
                        {/* {
                            show_test ?  */}
                            <section>
                                <div className="form-group row">
                                    <label className="col-md-4 col-sm-12">Send To: </label>
                                    <div className="col-md-7 col-11">
                                        <input type="email" name="test_email" className="form-control" value={config.test_email} onChange={(e) => dynamicChange(e)} />
                                    </div>
                                    <div className="float-right pl-0 col-1 col-md-1">
                                        <div className="input-group-prepend">
                                            <button className={"btn " + btn_lbl._class} type="button" onClick={(e => testEmail(e))}><i className={btn_lbl.icon}></i></button>
                                            {/* <span href="#" className="tool-tip text-primary" data-tip="this is a tool tip"><i className="la la-question-circle"></i></span> */}
                                        </div>
                                    </div>  
                                </div>
                               
                                {
                                    test_result.show ? 
                                    <div className="form-group row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-7">
                                            <div className="">
                                                {test_result.message}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <section></section>
                                }
                                
                            </section>
                            {/* : 
                            <section></section>
                        } */}
                        <div className="form-group row">
                            <div className="col-md-11 text-right">
                                <button className="btn btn-primary" type="submit">{btn_lbl.submit}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
         
        </div>
    )
}


const mapStateToProps = state => ({
    emailsetting: state.emailsetting
});

export default connect(mapStateToProps, { loadConfig, dynamicChange, passwordtoggle, toggle_test, submitConfig, clickBtn, testEmail})(EmailConfig);
