import http from '../../../Services/api.laravel.http';

export const EmailSettingService = {
 	getConfig: () => http.get('/settings/email'),
	setConfig: (formdata) => http.post('/settings/email/update', formdata),
	testConfig: (formdata) => http.post('/settings/email/test', formdata),
	getLogs: (formData) => http.post('/settings/email/logs', formData),
	getSingleLog: (id) => http.get('/settings/email/logs/' + id),
	securityConfig: (formdata) => http.post('/settings/email/security/update',formdata),
	getEmailConfig: () => http.get('/settings/email/security'),
	verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
	sendEmail: (id) => http.get('/settings/email/resend/'+ id),
}