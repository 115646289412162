import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import CustomerCreateGlobal from "./Customer.create.global";
import { Link } from "react-router-dom";

function CustomeCreate({ customer: { main_page, main_url } }) {
  if (main_page) return <Redirect to={main_url} />;

  return (
    <div className='app-content content'>
      <div className='content-wrapper'>
        <div className='content-wrapper-before'></div>
        <div className='content-header row'>
          <div className='content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header'>
            <h3 className='content-header-title mb-0 d-inline-block'>Sales</h3>
            <div className='breadcrumbs-top d-inline-block'>
              <div className='breadcrumb-wrapper mr-1'>
                <ol className='breadcrumb f-regular'>
                  <li className='breadcrumb-item'>
                    <Link to={main_url} className='text-white'>
                      Customers
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>
                    <Link to='#' className='text-white'>
                      Create
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className='content-body margin-top-5'>
          <section id='basic-examples'>
            <div className='row f-regular'>
              <div className='col-md-12 col-sm-12 col-lg-12 col-xl-9'>
                <div className='card'>
                  <div className='card-header mb-0 pb-0'>
                    <Link to={main_url}>
                      <i className='ft ft-arrow-left'></i> Back
                    </Link>
                  </div>
                  <div className='card-content collapse show'>
                    <div className='card-body f-regular'>
                      <CustomerCreateGlobal pageOrigin='customer' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps, {})(CustomeCreate);
