import React, { Fragment, useEffect } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { customAlert } from '../../Utils/Confirm';
import { map } from "jquery";
import {  Show, useAccess } from "react-redux-permission";

function Adjustment ({ }) {

    const list = [
        {
            id : '00001',
            date : '09/16/2020',
            ir : '0001',
            supplier : 'Big Pix',
            delivery_date : '10/10/2020',
            items : 1,
            qty : '10 pcs',
            reason : 'Damage',
            adjustment : '1pc',
            in_cost : '1,800.00',
            status : 'new'
        },
        {
            id : '00002',
            date : '09/17/2020',
            ir : '0003',
            supplier : 'Big Pix',
            delivery_date : '10/10/2020',
            items : 6,
            qty : '4 pcs',
            reason : 'Wrong Item',
            adjustment : '1pc',
            in_cost : '1,800.00',
            status : 'reported'
        },
        {
            id : '00003',
            date : '09/18/2020',
            ir : '0005',
            supplier : 'Cement Factory',
            delivery_date : '09/25/2020',
            items : 6,
            qty : '30 sacks',
            reason : 'Missing',
            adjustment : '4 sacks',
            in_cost : '1,800.00',
            status : 'settled'
        },
        {
            id : '00004',
            date : '09/18/2020',
            ir : '0006',
            supplier : 'Cement Factory',
            delivery_date : '09/20/2020',
            items : 10,
            qty : '6 sacks',
            reason : 'Damage',
            adjustment : '3 sacks',
            in_cost : '1,800.00',
            status : 'settled'
        }
    ]
    
    return (
        
        <div className="app-content content">
            <ReactTooltip  effect="solid"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Item Receipt Stock Adjustment </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item "><a href="#" className="text-white">Lists</a></li>
                                </ol>
                            </div>
                        </div>
                        <Show when="inv.receiving.adjustment.create"><a href="/receiving/adjustment/create"  className="btn btn-primary pull-right btn-fix-header"><i className="ft ft-plus"></i> Create New</a></Show>
                    </div>
                </div>
                <Show when="inv.receiving.adjustment.view">
                    <div className="content-body margin-top-5">
                      <section className="row">
                          <div className="col-sm-12">
                              <div id="what-is" className="card">
                                  <div className="card-header">
                                      <h4 className="card-title">List Of Stock Adjustment <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                      <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                                  </div>
                                  <div className="card-content collapse show">
                                      <div className="card-body">
                                          <div className="row">
                                                <div className="col-md-12 ">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-hover table-bordered mb-0 f-regular">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="13">
                                                                        <div className="pull-left"> <h4><b>Total: {list.length}</b></h4> </div>
                                                                    <div className="form-inline pull-right">
                                                                        <input  name="search" type="text" className="form-control input-sm" placeholder="Search here..." />
                                                                        <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                                                                    </div>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center">
                                                                        <input type="checkbox" />
                                                                    </th>
                                                                    <th>Report #</th>
                                                                    <th>Date Reported</th>
                                                                    <th>IR #</th>
                                                                    <th>Supplier</th>
                                                                    <th>Delivery Date</th>
                                                                    <th>Items</th>
                                                                    <th>Qty</th>
                                                                    <th>Reason</th>
                                                                    <th>Adjustment</th>
                                                                    <th>In Cost</th>
                                                                    <th>Status</th>
                                                                    <th>
                                                                        
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    list.map((data, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="text-center"> 
                                                                                    <input type="checkbox" />
                                                                                </td>
                                                                                <td>{data.id}</td>
                                                                                <td>{data.date}</td>
                                                                                <td>{data.ir}</td>
                                                                                <td>{data.supplier}</td>
                                                                                <td>{data.delivery_date}</td>
                                                                                <td>{data.items}</td>
                                                                                <td>{data.qty}</td>
                                                                                <td>{data.reason}</td>
                                                                                <td>{data.adjustment}</td>
                                                                                <td>{data.in_cost}</td>
                                                                                <td>{data.status}</td>
                                                                                <td>
                                                                                    <a href="#" className="btn btn-sm btn-grey btn-sm"><i class="ft ft-edit"></i></a>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                </div>
                                          </div>
                                       
                                      </div>

                                  </div>
                              </div>
                          </div>
                      </section>
                    </div>
                </Show>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    
});

export default connect(mapStateToProps, { })(Adjustment);
