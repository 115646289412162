import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import AttributeTemplateCreateGlobal from '../../attribute_template/AttributeTemplate.create.global';

function ProductAttributeModal({ attribute_template: { create_modal },show, onHide }) {

    return (
        <Modal centered dialogClassName="modal-container modal-style-lg" show={show} onHide={onHide} size="lg" animation={true}>
            {
                create_modal ? <Modal.Header > <Modal.Title>Attributes Template</Modal.Title> </Modal.Header>
                : <Modal.Header closeButton> <Modal.Title>Attributes Template</Modal.Title> </Modal.Header>
            }
            <Modal.Body>
                <AttributeTemplateCreateGlobal pageOrigin="item"/>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
	attribute_template: state.attribute_template,
});

export default connect(mapStateToProps, { })(ProductAttributeModal);
