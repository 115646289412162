import React from "react"

function Bundle() {
  return (
    <div className="text-center w-100">
        Coming soon....
    </div>
  )
}

export default Bundle