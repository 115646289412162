import React, { useState } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { setUserSession, setUserAccess, setUserPermissions , setUser} from '../../Utils/Common';
import {handleInputChange, submitRegister} from "../../redux/actions/auth/auth.actions";

function Register({auth : {inputs, status, loading, industry}, handleInputChange, submitRegister}) {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    function getPasswordStrengthText(password) {
        if (password.length >= 1 && password.length <= 6) {
          return 'Weak Password';
        } else if (password.length >= 7 && password.length <= 11) {
          return 'Good Password';
        } else if (password.length >= 12 && password.length <= 20) {
          return 'Strong Password';
        } else if (password.length > 20) {
          return 'Strong Password';
        } else {
          return '';
        }
      }
      

    function getPasswordStrengthClass(password) {
        if (password.length >= 1 && password.length <= 6) {
          return 'password-weak'; 
        } else if (password.length >= 7 && password.length <= 11) {
          return 'password-good'; 
        } else if (password.length >= 12) {
          return 'password-strong'; 
        } else {
          return ''; 
        }
      }
      
      function getPasswordStrengthWidth(password) {
        let width = 0;
      
        if (password.length >= 1 && password.length <= 6) {
          width = (password.length / 6) * 33.33; // Weak
        } else if (password.length >= 7 && password.length <= 11) {
          width = ((password.length - 6) / 5) * 33.33 + 33.33; // Good
        } else if (password.length >= 12 && password.length <= 20) {
          width = ((password.length - 12) / 8) * 33.33 + 66.66; // Strong
        } else if (password.length > 20) {
          width = 100; // Maximum width
        }
      
        return `${width}%`;
      }
      
      
           

  document.body.classList.remove('vertical-layout');
  document.body.classList.add("bg-gradient-x-purple-blue");
  console.log(status.message_arr.length);
  return (
    <div className="app-content content">
        <div className="bg-auth bg-gradient-x-purple-blue"></div>
      <div className="content-wrapper">
          <div className="content-wrapper-before"></div>
          <div className="content-header row">
          </div>
          <div className="content-body">
              <form className="flexbox-container" onSubmit={(e) => submitRegister(e)}>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-10 box-shadow-2 p-0 row b-ltb-10p b-rtb-10p">
                          <div className="col-md-6 b-ltb-10p bg-white">
                            <div className="p-2m">
                              <img src="/app-assets/images/logo/logo-mini.png" className="auth-logo" />
                              <h1>Sign up</h1>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <input type="text" className=" round login-form" placeholder="First name" name="fname" value={inputs.fname} onChange={(e) => handleInputChange(e)} autoComplete="new-name" required></input>
                                    <div className="form-control-position">
                                        <i className="ft-user"></i>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <input type="text" className=" round login-form" placeholder="Last name" name="lname" value={inputs.lname} onChange={(e) => handleInputChange(e)} autoComplete="new-name" required></input>
                                    <div className="form-control-position">
                                        <i className="ft-user"></i>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <input type="text" className=" round login-form" placeholder="Company Name" name="company_name" value={inputs.company_name} onChange={(e) => handleInputChange(e)} autoComplete="new-name" required></input>
                                    <div className="form-control-position">
                                        <i className="ft-home"></i>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <select className="round login-form select-round" name="industry" value={inputs.industry} onChange={(e) => handleInputChange(e)} required> 
                                        <option value="">Select Industry</option>
                                        {
                                            industry.map((data) => {
                                                return(
                                                    <option value={data}>{data}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <input type="text" className=" round login-form" placeholder="Industry" name="industry" value={inputs.industry} onChange={(e) => handleInputChange(e)} autoComplete="new-name" required></input> */}
                                    <div className="form-control-position">
                                        <i className="ft-home"></i>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <input type="text" className=" round login-form" placeholder="Your Username" name="username" value={inputs.username} onChange={(e) => handleInputChange(e)} autoComplete="new-email" required></input>
                                    <div className="form-control-position">
                                        <i className="ft-user"></i>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <input type="mail" className=" round login-form"  placeholder="Your Email" name="email" value={inputs.email} onChange={(e) => handleInputChange(e)} autoComplete="new-username" required></input>
                                    <div className="form-control-position">
                                        <i className="ft-mail"></i>
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <div style={{ position: 'relative' }}>
                                        <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="round login-form"
                                        placeholder="Your Password"
                                        name="password"
                                        value={inputs.password}
                                        onChange={(e) => handleInputChange(e)}
                                        autoComplete="new-password"
                                        required
                                        />
                                        <div className="form-control-position">
                                        <i className="ft-lock"></i>
                                        </div>
                                        <button
                                        type="button"
                                        className="border border-0 bg-transparent"
                                        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                        onClick={() => setShowPassword(!showPassword)}
                                        >
                                        {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                        </button>
                                    </div>
                                    <div className="password-strength-text mx-auto" style={{width: "90%"}}>
                                        {getPasswordStrengthText(inputs.password)}
                                    </div>
                                    {inputs.password && inputs.password.length > 0 && (
                                      <div className="password-meter mx-auto">
                                        <div
                                          className={`password-strength-bar ${getPasswordStrengthClass(inputs.password)}`}
                                          style={{ width: getPasswordStrengthWidth(inputs.password) }}
                                        ></div>
                                      </div>
                                    )}
                                </fieldset>

                                <fieldset className="form-group position-relative has-icon-left">
                                  <div style={{ position: 'relative' }}>
                                      <input
                                      type={showConfirmPassword ? 'text' : 'password'}
                                      className="round login-form"
                                      placeholder="Confirm Password"
                                      name="confirm_password"
                                      value={inputs.confirm_password}
                                      onChange={(e) => handleInputChange(e)}
                                      autoComplete="new-password"
                                      required
                                      />
                                      <div className="form-control-position">
                                      <i className="ft-lock"></i>
                                      </div>
                                      <button
                                      type="button"
                                      className="border border-0 bg-transparent"
                                      style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                      >
                                      {showConfirmPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                      </button>
                                  </div>
                                  {inputs.password.length > 0 && inputs.password === inputs.confirm_password &&
                                    <div className='text-success px-2'>
                                      Password matched
                                    </div>
                                  }
                                </fieldset>

                                <div className="form-group text-center">
                                    <button type="submit" className="btn-login btn-lg round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1 text-white" disabled={loading}  >{loading ? 'Loading...' : 'Sign up'}</button>
                                </div>
                                {
                                  status.result ? 
                                  <div className="form-group">
                                      <div className="col-md-12 text-center">
                                          {
                                              
                                              status.message_arr.length > 0 ?
                                                status.message_arr.map((message) => {
                                                    return(
                                                        <section>
                                                            <span className={status.success ? "color-green" : "color-red"}><i>{message}</i></span><br/>
                                                        </section>
                                                    )
                                                    
                                                })
                                               : 
                                               <span></span>
                                          }
                                          
                                      </div>
                                  </div>
                                  :
                                  <span></span>
                                }
                                <div className="form-group text-center">
                                    <a href="/login">Return to Login</a>
                                </div>
                                <div className="form-group text-center mt-2">
                                    <p> Powered by <a href='https://bluehive.com.ph/' target='_blank'>Bluehive Systems</a></p>
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6 b-rtb-10p bg-theme">
                              <div className="p-2m">
                                <div className=" border-0 reg-media">
                                    <div className="text-center mt-3">  
                                      <img src="/app-assets/images/logo/blitz_237x237_logo_white.png" style={{'max-width': '100%', 'width': '100px'}} alt="branding logo"></img>
                                    </div>
                                    <div className="text-center mt-2">
                                      <img src="/app-assets/images/logo/blitz_logo_text_white.png" style={{'max-width': '100%', 'width': '350px'}} alt="branding logo"></img>
                                    </div>
                                    <div className="font-large-1 text-center font-weight-bold text-primary">
                                    </div>
                                </div>
                              </div>
                          </div>
                          
                      </div>
                  </div>
              </form>

          </div>
      </div>
    </div>  
  );
}
 

const mapStateToProps = state => ({
  auth: state.auth
});
 

export default connect(mapStateToProps, { handleInputChange, submitRegister})(Register);