import http from '../../../Services/api.laravel.http';

export const ItemReceiptTemplateSettingService = {
	getIRTemplateSettings: () => http.get('/admin/receiving-template/settings/receiving-template'),
	storeIRTS: (formParams) => http.post('/admin/receiving-template/settings/receiving-template', formParams),
   	setAsDefault: (formParams) => http.post('/admin/receiving-template/settings/default', formParams),
  	IRTemplateSettingArchive: (formParams) => http.post('/admin/receiving-template/settings/archive', formParams),
   	getSingleIRTS: (id) => http.get(`/admin/receiving-template/settings/${id}`),
   	updateIRTS: (formParams) => http.post(`/admin/receiving-template/settings/update`, formParams),
}
