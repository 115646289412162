import moment from 'moment';
import React from 'react';
import { numberFormat } from '../../Utils/Common';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PaymentTable from './PaymentTable';

function CollectionReceiptDrawer({
    collection_receipt: { single_collection_receipt, transaction_types },
}) {
    const {
        status,
        sales_order_no,
        sales_order_id,
        transaction_type,
        due_date_status,
        date,
        due_date,
        total,
        balance,
        customer,
        payment_terms,
        payment_history
    } = single_collection_receipt;

    // console.log("single_collection_receipt", single_collection_receipt)
    // console.log("payment_history", payment_history.length)

    
    let makeSo = {
        value: sales_order_id,
        name: 'so',
        label: sales_order_no,
        pageOrigin: 'so-makeSo'
    }


    
    return (
        <>
            <div className="container">
                <div className="card mt-1">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="mr-auto">
                                <span
                                    className={'lbl-status mr-2 lbl-' + status}
                                >
                                    {status}
                                </span>
                                {status === 'paid' ? (<>N/A</>):(
                                    <span
                                      className={
                                          'lbl-status mr-2 lbl-' + due_date_status
                                      }
                                  >
                                      {due_date_status}
                                  </span>
                                )}
                              
                            </div>
                            <div className="">
                                <span>
                                    <i className="ft ft-download f-regular mr-2 cr-h-a"></i>
                                    <i className="ft ft-printer f-regular mr-2 cr-h-a"></i>
                                </span>
                            </div>
                        </div>

                        <div className="d-flex mt-4">
                            <div className="mr-auto">
                                <div className="d-flex">
                                    <div className="mr-1">Sales Order No.:</div>{' '}
                                    <div className="cr-h-a">
                                        {transaction_type === "sales_order" && (
                                            <Link
                                                className="txt-underline cr-h-label"
                                                to={`/sales/sales-orders/edit/${sales_order_id}`}
                                                target="_blank"
                                            >
                                                {sales_order_no}
                                            </Link>
                                        )}

                                        {/* {transaction_type ===
                                            transaction_types.delivery_receipt && (
                                            <Link
                                                className="txt-underline cr-h-label"
                                                to={`/deliveries/edit/${sales_order_id}`}
                                                target="_blank"
                                            >
                                                {sales_order_no}
                                            </Link>
                                        )} */}

                                        {transaction_type ===
                                            transaction_types.collection_receipt && (
                                            <div>{sales_order_no}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="mr-1">Amount:</div>{' '}
                                    <div className="cr-h-label">
                                        ₱ {numberFormat(total)}
                                    </div>
                                </div>
                                <div className="my-2"></div>
                                <div className="d-flex">
                                    <div className="mr-1">Customer:</div>{' '}
                                    <div>
                                        <Link
                                            className="txt-underline cr-h-label"
                                            to={`/sales/customers/history/${customer?._id}`}
                                        >
                                            {customer?.name}
                                        </Link>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="mr-1">Payment Terms:</div>{' '}
                                    <div className="cr-h-label">
                                        {payment_terms?.label}
                                    </div>
                                </div>
                                <div className="my-2"></div>
                                <div className="d-flex">
                                    <div className="mr-1"> Due Date:</div>
                                    <div className="cr-h-label">
                                        {due_date
                                            ? moment(date).format('MMM DD YYYY')
                                            : 'N/A'}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="mr-1">Due Date Status:</div>{' '}
                                    <div className="cr-h-label"></div>
                                </div>
                                <div className="d-flex">
                                    <div className="mr-1">Balance:</div>
                                    <div className="cr-h-label cr-f-bal">
                                        ₱ {numberFormat(balance)}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'grid' }}>
                                <div className="d-flex">
                                    <div className="mr-1">Date:</div>{' '}
                                    <div className="cr-h-label">
                                        {moment(date).format('MMMM DD YYYY')}
                                    </div>
                                </div>
                                {balance === 0 ? (
                                    <div className="d-flex align-items-end">
                                         <Link
                                            to={{ pathname: "/sales/invoices/create", makeSo }} 
                                            className='btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white'
                                            style={{width: "100%"}}
                                        >
                                            MAKE INVOICE
                                        </Link>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                <PaymentTable
                    paymentHistory={payment_history?.docs}
                    pagination={{
                        totalCount: payment_history?.total_docs,
                        activePage: payment_history?.page,
                        itemsCountPerPage: payment_history?.limit,
                        totalItemsCount: payment_history?.total_docs,
                    }}
                />

                </div>


               
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    collection_receipt: state.collection_receipt,
});

export default connect(mapStateToProps, {})(CollectionReceiptDrawer);

// export default CollectionReceiptDrawer;
