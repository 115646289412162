import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ProgressBar } from 'react-bootstrap';
import { fileExtensionIcon } from '../../Utils/Common';

function ReturnUpload({ onFilesSelected, onRemoveFile, uploadProgress = {} }) {
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
            }));
            const updatedFiles = [...files, ...newFiles];
            setFiles(updatedFiles);

            // Pass all files to the parent component
            onFilesSelected(updatedFiles.map((fileObj) => fileObj.file));
        },
        [files, onFilesSelected]
    );

    const removeFile = (fileToRemove) => {
        const updatedFiles = files.filter(
            (fileObj) => fileObj.file !== fileToRemove
        );
        setFiles(updatedFiles);
        onRemoveFile(fileToRemove);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
    });

    const renderFiles = () =>
        files.map((fileObj) => (
            <div
                key={fileObj.name}
                className="d-flex justify-content-between align-items-center mb-2"
            >
                <div className="d-flex align-items-center">
                    <img
                        src={fileExtensionIcon(fileObj.name)}
                        alt={fileObj.name}
                        style={{ width: '30px', marginRight: '10px' }}
                    />
                    <span className="text-secondary">{fileObj.name}</span>
                </div>
                <div className="btn-group">
                    <button
                        onClick={() => removeFile(fileObj.file)}
                        className="btn btn-danger btn-sm"
                    >
                        <i className="ft ft-x"></i>
                    </button>
                </div>
                {uploadProgress[fileObj.name] !== undefined && (
                    <ProgressBar
                        now={uploadProgress[fileObj.name]}
                        label={`${uploadProgress[fileObj.name]}%`}
                        style={{ width: '100%', marginLeft: '10px' }}
                    />
                )}
            </div>
        ));

    return (
        <div>
            <div
                {...getRootProps()}
                className="file-dropzone-line-print-modal mb-2"
            >
                <input {...getInputProps()} />
                <div className="file-upload-message-print-modal">
                    Attach your files here...
                </div>
            </div>
            <div>{renderFiles()}</div>
        </div>
    );
}

export default ReturnUpload;
