import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getReturns,
    sortTableByColumn,
    getSelectedRows,
    setModal,
    handleDateFilterSubmit,
} from '../../redux/actions/returns/return.actions';
import { useDebounce } from 'use-debounce';
import LogsDateFilter from './LogsDate.filter';
import ViewReturnModal from './modals/viewReturnModal';
import ReturnsFilter from './Returns.filter.global';

function ReturnsTable({
    returns: { returns, pagination, search },
    getReturns,
    getSelectedRows,
    sortTableByColumn,
    setModal,
    handleDateFilterSubmit,
}) {
    const [movementFilter, setMovementFilter] = useState('return'); // Track active movement filter
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');

    useEffect(() => {
        getReturns(1, null, 'return'); // Fetch initial data
    }, []);

    useEffect(() => {
        if (debouncedSearch[0] && oldDebounced !== debouncedSearch[0]) {
            if (debouncedSearch[0].length !== 1) {
                setOldDebounced(debouncedSearch[0]);
                getReturns(1, null);
            }
        }
    }, [debouncedSearch]);

    const handleFilterChange = (filter) => {
        setMovementFilter(filter);
        handleDateFilterSubmit(
            { preventDefault: () => {} },
            'returns',
            'return',
            filter
        );
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const sortedReturns = [...returns].sort((a, b) => {
        if (!sortColumn) return 0;
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA === undefined || valueB === undefined) return 0;
        if (sortOrder === 'asc') {
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        }
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    });

    return (
        <main className="w-100">
            <section className="filter-container">
                <div className="w-100">
                    <LogsDateFilter
                        filterBy="returns"
                        pageOrigin="return"
                        movementFilter={movementFilter}
                    />
                </div>

                <div className="w-100">
                    <ReturnsFilter onFilterChange={handleFilterChange} />
                </div>
            </section>

            <section className="ds-table-container">
                <table
                    id="ds-table-to-print"
                    className="ds-table ds-table-striped"
                >
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('created_at')}>
                                Date{' '}
                                {sortColumn === 'created_at' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('_id')}>
                                ID{' '}
                                {sortColumn === '_id' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('sku')}>
                                SKU{' '}
                                {sortColumn === 'sku' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('product_name')}>
                                Product Name{' '}
                                {sortColumn === 'product_name' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('Brand')}>
                                Brand{' '}
                                {sortColumn === 'Brand' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('description')}>
                                Description{' '}
                                {sortColumn === 'description' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('quantity')}>
                                Qty{' '}
                                {sortColumn === 'quantity' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('reference_no')}>
                                Reference No.{' '}
                                {sortColumn === 'reference_no' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('description')}>
                                Remarks{' '}
                                {sortColumn === 'remarks' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('supplier')}>
                                Supplier{' '}
                                {sortColumn === 'supplier' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleSort('movement')}>
                                Movement{' '}
                                {sortColumn === 'movement' &&
                                    (sortOrder === 'asc' ? '↑' : '↓')}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedReturns.length === 0 ? (
                            <tr>
                                <td
                                    colSpan="10"
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                    }}
                                >
                                    Sorry, there is no matching data to display.
                                </td>
                            </tr>
                        ) : (
                            sortedReturns.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.created_at?.substring(0, 10)}</td>
                                    <td>
                                        {item._id.slice(0, 3)}...
                                        {item._id.slice(-3)}
                                    </td>
                                    <td>{item.sku}</td>
                                    <td>
                                        <Link
                                            to={`/inventory/view/${item._id}`}
                                        >
                                            {item.product_name}
                                        </Link>
                                    </td>
                                    <td>{item.brand_name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.reference_no}</td>
                                    <td>{item.remarks}</td>{' '}
                                    <td>{item.supplier}</td>
                                    <td>{item.movement}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-grey"
                                            onClick={() =>
                                                setModal(
                                                    'return_stock_modal',
                                                    true,
                                                    item
                                                )
                                            }
                                        >
                                            <i className="ft ft-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </section>

            <ViewReturnModal
                className="modal-centered"
                onHide={() => setModal('return_stock_modal', null)}
            />
        </main>
    );
}

const mapStateToProps = (state) => ({
    returns: state.returns,
});

export default connect(mapStateToProps, {
    getReturns,
    getSelectedRows,
    sortTableByColumn,
    setModal,
    handleDateFilterSubmit,
})(ReturnsTable);
