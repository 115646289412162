import * as TYPES from "../../types";
import {
  ToastSuccess,
  ToastDanger,
  ToastWarning,
} from "../../../Services/_toast.service";
import $ from "jquery";
import { InventoryService } from "./_service.inventory.js";
import {
  SwalWarning,
  SwallverfiyPass,
  SwalError,
  SwallnewStock,
  SwalSuccessAutoRemove,
} from "../../../Services/_swal.service";
import {
  blockRoute,
  muiDataSetting,
  setMUIPage,
} from "../helper/helper.actions";
import { getNumRows, getSubSetting, getUserData } from "../../../Utils/Common";
import { getStockLogs } from "./stockLogs.action.js";

export const setLoading = (status) => async (dispatch) =>
  dispatch({ type: TYPES.SET_LOADING_INV, payload: status });

// LIST
export const handleSearchInventory = (e) => async (dispatch, getState) => {
  //console.log(e.target.value);
  let { stock_view, pagination_list } = getState().inventory;
  pagination_list[stock_view].search = e.target.value;
  dispatch({ type: TYPES.SET_SEARCH, payload: e.target.value });
  dispatch({
    type: TYPES.SET_PAGINATION,
    payload: {
      pagination_list: pagination_list,
      pagination: pagination_list[stock_view],
    },
  });
  // dispatch(getInventoryList(1, true));
};

export const handleChangeStockView =
  (stock_view) => async (dispatch, getState) => {
    dispatch({ type: TYPES.GET_STOCK_VIEW, payload: stock_view });

    let { inventory_list, search, pagination_list } = getState().inventory;
    let attr_list = inventory_list[stock_view];

    if (
      typeof pagination_list[stock_view] === "undefined" ||
      pagination_list[stock_view] == null
    ) {
      dispatch({ type: TYPES.SET_SEARCH, payload: "" });
      dispatch(getInventoryList(1));
    } else {
      dispatch({
        type: TYPES.SET_SEARCH,
        payload: pagination_list[stock_view].search,
      });
      dispatch({
        type: TYPES.SET_PAGINATION,
        payload: {
          pagination_list: pagination_list,
          pagination: pagination_list[stock_view],
        },
      });
    }
  };

// export const getInventoryList = (pageNumber, _search =false) => async (dispatch, getState) => {
//     console.log(pageNumber);
//     let { search, pagination, stock_view, inventory_list, pagination_list } = getState().inventory;

//     if(_search == false)
//     {
//         if(typeof(pagination_list[stock_view]) !== 'undefined' || pagination_list[stock_view] != null )
//         {
//             if(typeof(pagination_list[stock_view].search) !== 'undefined' || pagination_list[stock_view].search != null )
//             {
//                 if(pagination_list[stock_view].search == search)
//                 {
//                     if(pageNumber === pagination_list[stock_view].activePage)
//                     {
//                         console.log(pagination_list[stock_view].activePage);
//                         return false;
//                     }
//                 }
//             }
//         }
//     }

//     let searchPostParams = {
//         search: search,
//         activePage: pagination.activePage,
//         page: pageNumber
//     }
//     dispatch(setLoading(true));
//     InventoryService.getInvetoryList(searchPostParams, stock_view).then(res => {

//         inventory_list[stock_view] = res.data.data;
//         let pagination = { totalCount: res.data.total, activePage: res.data.current_page, itemsCountPerPage: res.data.per_page, totalItemsCount: res.data.total, search: search }
//         pagination_list[stock_view] = pagination;
//         dispatch({ type: TYPES.GET_INVENTORY_LIST, payload: { inventory_list: inventory_list, pagination:  pagination, pagination_list: pagination_list} })
//         dispatch(setLoading(false));
//     })
//     .catch(err => {
//         console.log(err);
//         dispatch(setLoading(false));
//         ToastDanger('Network error, please reload the page.');
//     });
// };

export const getInvetoryItem = (id) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  InventoryService.getInvetoryItem(id)
    .then((res) => {
      dispatch({ type: TYPES.GET_INVENTORY_ITEM, payload: res.data.data });
      dispatch(setLoading(false));
    })
    .catch((err) => {
      console.log(err);
      dispatch(setLoading(false));
      ToastDanger("Network error, please reload the page.");
    });
};

//END LIST

/* Updated by Michael Antoni */

export const getInventoryList =
  (pageNumber, _search = false, rows_per_page = null, brand_id = null) =>
  async (dispatch, getState) => {
    // console.log(pageNumber);
    let {
      search,
      pagination,
      stock_view,
      inventory_list,
      pagination_list,
      sort_order_name,
      sort_order_direction,
    } = getState().inventory;

    let rows_per_page_val =
      rows_per_page !== null ? rows_per_page : getNumRows("inventory") || 10;

    if (_search == false) {
      if (
        typeof pagination_list[stock_view] !== "undefined" ||
        pagination_list[stock_view] != null
      ) {
        if (
          typeof pagination_list[stock_view].search !== "undefined" ||
          pagination_list[stock_view].search != null
        ) {
          if (pagination_list[stock_view].search == search) {
            if (pageNumber === pagination_list[stock_view].activePage) {
             // console.log(pagination_list[stock_view].activePage);
              return false;
            }
          }
        }
      }
    }

    // let searchPostParams = {
    //   search: search,
    //   activePage: pagination.activePage,
    //   page: pageNumber,
    //   rows_per_page: rows_per_page_val, // rows_per_page,
    //   sort_order_name,
    //   sort_order_direction,
    //   brand_id,
    // };

    //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
    let searchPostParams = {
      search: search,
      options: {
        page: pageNumber,
        limit: rows_per_page_val,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
        brand_id,
      },
    };

    dispatch(setLoading(true));
    InventoryService.getInvetoryList(searchPostParams, stock_view)
      .then((res) => {
        // console.log("res.data.data", res.data.data);
        let data_list = [];
        res.data.docs.map((data, index) => {
          data.restock_click = false;
          data_list.push(data);
        });

        inventory_list[stock_view] = data_list;

       // console.log("list", stock_view);

        // let pagination = {
        //   totalCount: res.data.total,
        //   activePage: res.data.current_page,
        //   itemsCountPerPage: res.data.per_page,
        //   totalItemsCount: res.data.total,
        //   search: search,
        // };

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let pagination = {
          totalCount: res.data.total_docs,
          activePage: res.data.page,
          itemsCountPerPage: res.data.limit,
          totalItemsCount: res.data.total_docs,
          search: search,
        };

        pagination_list[stock_view] = pagination;
        dispatch({
          type: TYPES.GET_INVENTORY_LIST,
          payload: {
            inventory_list: inventory_list,
            pagination: pagination,
            pagination_list: pagination_list,
          },
        });

        let strcount =
          res.data.retstock_count > 99 ? "99+" : res.data.retstock_count;
        dispatch({
          type: TYPES.PO_REQUEST_COUNT,
          payload: { value: res.data.retstock_count, str: strcount },
        });
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
      });
  };

// Sort by column name and direction
export const sortTableByColumn =
  (sort_order_name, sort_order_direction) => async (dispatch) => {
    let sortingParams = {
      sort_order_name: sort_order_name,
      sort_order_direction: sort_order_direction,
    };

    dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getInventoryList());
  };

export const addStock =
  (e, index, rows_per_page = null) =>
  async (dispatch, getState) => {
    let { stock_view, inventory_list, show_modal } = getState().inventory;
    let rows_per_page_val =
      rows_per_page !== null ? rows_per_page : getNumRows("inventory");
    let inventory = inventory_list;

    //** change supplier_items to supplier */
    let items = inventory[stock_view][index];
    let supplier = items.supplier;

   // console.log("iterms", supplier);

    if (supplier.length <= 0) {
      ToastWarning("Item does not have a supplier.");
    } else {
      if (supplier.length > 1) {
        show_modal.supplier = true;
        dispatch({ type: TYPES.GET_SUPPLIERS, payload: supplier });
        dispatch({ type: TYPES.SET_MODAL, payload: show_modal });
        dispatch({ type: TYPES.INV_INDEX, payload: index });
        dispatch({ type: TYPES.GET_SINGLE_SUPPLIER, payload: null });
      } else {
        dispatch(addRequest(index, supplier[0].supplier_id, rows_per_page));
      }
    }
  };

export const addRequest =
  (index, supplier_id, rows_per_page) => async (dispatch, getState) => {
    let {
      stock_view,
      inventory_list,
      search,
      sort_order_name,
      sort_order_direction,
      pagination_list,
    } = getState().inventory;
    let rows_per_page_val =
      rows_per_page !== null ? rows_per_page : getNumRows("inventory");
    let inventory = inventory_list;

    inventory[stock_view][index].restock_click = true;
    dispatch({ type: TYPES.INVENTORY_LIST, payload: inventory });
    let formdata = {
      product_id: inventory[stock_view][index]._id,
      supplier_id: supplier_id,
      search: search,
      options: {
        limit: rows_per_page_val,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };
    InventoryService.setRestock(formdata)
      .then((res) => {
        ToastSuccess(res.data.message);

        inventory_list["all"] = res.data["all"].docs;
        inventory_list["re-stock"] = res.data["re-stock"].docs;
        let pagination = {
          totalCount: res.data["all"].total,
          activePage: res.data["all"].current_page,
          itemsCountPerPage: res.data["all"].per_page,
          totalItemsCount: res.data.total,
          search: search,
        };
        pagination_list["all"] = pagination;
        let pagination_stock = {
          totalCount: res.data["re-stock"].total,
          activePage: res.data["re-stock"].current_page,
          itemsCountPerPage: res.data["all"].per_page,
          totalItemsCount: res.data.total,
          search: search,
        };
        pagination_list["re-stock"] = pagination_stock;
        dispatch({
          type: TYPES.GET_INVENTORY_LIST,
          payload: {
            inventory_list: inventory_list,
            pagination: pagination,
            pagination_list: pagination_list,
          },
        });

        let strcount =
          res.data["all"].retstock_count > 99
            ? "99+"
            : res.data["all"].retstock_count;
        dispatch({
          type: TYPES.PO_REQUEST_COUNT,
          payload: { value: res.data["all"].retstock_count, str: strcount },
        });
      })
      .catch((err) => {
        inventory[stock_view][index].restock_click = false;
        dispatch({ type: TYPES.INVENTORY_LIST, payload: inventory });
        try {
          ToastDanger(err.data.message);
        } catch (error) {
          ToastDanger("Unknown Error occured.");
        }
      });
  };

export const getRestock = () => async (dispatch, getState) => {
  InventoryService.getRestock()
    .then((result) => {})
    .catch((err) => {
      // dispatch({type : TYPES.INVENTORY_LIST, payload :inventory});
      try {
        ToastDanger(err.data.message);
      } catch (error) {
        ToastDanger("Unknown Error occured.");
      }
    });
};

export const removeRestrock = (e, index) => async (dispatch, getState) => {
  SwalWarning(
    "Are you sure?",
    "Do you really want to remove this request item?",
    () => dispatch(removeRestockFn(e, index))
  );
};

export const removeRestockFn = (e, index) => async (dispatch, getState) => {
  let {
    stock_view,
    inventory_list,
    search,
    sort_order_name,
    sort_order_direction,
    pagination_list,
  } = getState().inventory;
  let rows_per_page_val = getNumRows("inventory");

  let inventory = inventory_list;
  let items = inventory[stock_view][index];
  inventory[stock_view][index].restock_click = true;
  dispatch({ type: TYPES.INVENTORY_LIST, payload: inventory });
  let formdata = {
    sort_order_name: sort_order_name,
    sort_order_direction: sort_order_direction,
    rows_per_page: rows_per_page_val,
    search: search,
  };
  InventoryService.removeRestock(items.request_id, formdata)
    .then((res) => {
      ToastSuccess(res.data.message);
      inventory_list["all"] = res.data["all"].data;
      inventory_list["re-stock"] = res.data["re-stock"].data;
      let pagination = {
        totalCount: res.data["all"].total,
        activePage: res.data["all"].current_page,
        itemsCountPerPage: res.data["all"].per_page,
        totalItemsCount: res.data.total,
        search: search,
      };
      pagination_list["all"] = pagination;
      let pagination_stock = {
        totalCount: res.data["re-stock"].total,
        activePage: res.data["re-stock"].current_page,
        itemsCountPerPage: res.data["all"].per_page,
        totalItemsCount: res.data.total,
        search: search,
      };
      pagination_list["re-stock"] = pagination_stock;
      dispatch({
        type: TYPES.GET_INVENTORY_LIST,
        payload: {
          inventory_list: inventory_list,
          pagination: pagination,
          pagination_list: pagination_list,
        },
      });

      let strcount =
        res.data["all"].retstock_count > 99
          ? "99+"
          : res.data["all"].retstock_count;
      dispatch({
        type: TYPES.PO_REQUEST_COUNT,
        payload: { value: res.data["all"].retstock_count, str: strcount },
      });
      if (res.data["all"].retstock_count <= 0) {
        dispatch({ type: TYPES.GET_STOCK_VIEW, payload: "all" });
      }
    })
    .catch((err) => {
      inventory[stock_view][index].restock_click = false;
      dispatch({ type: TYPES.INVENTORY_LIST, payload: inventory });
      try {
        ToastDanger(err.data.message);
      } catch (error) {
        ToastDanger("Unknown Error occured.");
        console.log("error", error);
      }
    });
};

export const processConfirm = () => async (dispatch, getState) => {
  let { selected_rows } = getState().inventory;
  if (selected_rows.length > 0) {
    SwalWarning(
      "Are you sure?",
      "All item(s) in the list will be process to PO, continue?",
      () => dispatch(processPO())
    );
  } else {
    ToastDanger("Please select at least one row.");
  }
};

export const processPO = () => async (dispatch, getState) => {
  let { show_modal, selected_rows } = getState().inventory;
  if (selected_rows.length > 0) {
    show_modal.po = true;
    dispatch({ type: TYPES.SET_MODAL, payload: show_modal });
  } else {
    ToastDanger("Please select at least one row.");
  }
};

export const setProcess = () => async (dispatch, getState) => {
  let { single_purchase_order } = getState().purchase_order;
  let { show_modal, po_per_supplier } = getState().inventory;
  let modal = show_modal;
  modal.po = false;
  if (po_per_supplier) {
    dispatch({ type: TYPES.SET_MODAL, payload: modal });
    dispatch(generatePO(single_purchase_order.supplier_id));
  } else {
    try {
      if (single_purchase_order.supplier_id != null) {
        dispatch({ type: TYPES.SET_MODAL, payload: modal });
        dispatch(generatePO(single_purchase_order.supplier_id));
      } else {
        ToastDanger("Please select a supplier first");
      }
    } catch (err) {
      ToastDanger("Please select a supplier first");
    }
  }
};

export const generatePO =
  (supplier_id = null) =>
  async (dispatch, getState) => {
    let {
      stock_view,
      inventory_list,
      search,
      sort_order_name,
      sort_order_direction,
      pagination_list,
      po_per_supplier,
      selected_rows,
    } = getState().inventory;

    let rows_per_page_val = getNumRows("inventory");
    let request_id = [];
    selected_rows.map((index) => {
      try {
        request_id.push(inventory_list["re-stock"][index]["request_id"]);
      } catch (err) {
        console.log("error", err);
       // console.log(inventory_list["re-stock"][index]);
      }
    });
    let formdata = {
      search: search,
      supplier_id: supplier_id,
      po_per_supplier: po_per_supplier,
      request_id: request_id,
      options: {
        limit: rows_per_page_val,
        sort_by: sort_order_name,
        sort_order: sort_order_direction,
      },
    };

    dispatch({ type: TYPES.BTN_SUB, payload: true });
    InventoryService.processRestock(formdata)
      .then((res) => {
        ToastSuccess(res.data.message);
        inventory_list["all"] = res.data["all"].docs;
        inventory_list["re-stock"] = res.data["re-stock"].docs;
        let pagination = {
          totalCount: res.data["all"].total,
          activePage: res.data["all"].current_page,
          itemsCountPerPage: res.data["all"].per_page,
          totalItemsCount: res.data.total,
          search: search,
        };
        pagination_list["all"] = pagination;
        let pagination_stock = {
          totalCount: res.data["re-stock"].total,
          activePage: res.data["re-stock"].current_page,
          itemsCountPerPage: res.data["all"].per_page,
          totalItemsCount: res.data.total,
          search: search,
        };
        pagination_list["re-stock"] = pagination_stock;
        dispatch({
          type: TYPES.GET_INVENTORY_LIST,
          payload: {
            inventory_list: inventory_list,
            pagination: pagination,
            pagination_list: pagination_list,
          },
        });

        let strcount =
          res.data["all"].retstock_count > 99
            ? "99+"
            : res.data["all"].retstock_count;
        dispatch({
          type: TYPES.PO_REQUEST_COUNT,
          payload: { value: res.data["all"].retstock_count, str: strcount },
        });
        dispatch({ type: TYPES.BTN_SUB, payload: false });
        if (res.data["all"].retstock_count <= 0) {
          dispatch({ type: TYPES.GET_STOCK_VIEW, payload: "all" });
        }
        dispatch({ type: TYPES.SELECTED_ROWS, payload: [] });
      })
      .catch((err) => {
        dispatch({ type: TYPES.BTN_SUB, payload: false });
        try {
          ToastDanger(err.data.message);
        } catch (error) {
          // ToastDanger('Unknown Error occured.');
          console.log("error", error);
        }
      });
  };

export const hideModal = (e, index) => async (dispatch, getState) => {
  let { show_modal } = getState().inventory;
  let modal = show_modal;
  modal[index] = false;
  dispatch({ type: TYPES.SET_MODAL, payload: modal });
};

export const supplierSelect = (id) => async (dispatch) => {
  dispatch({ type: TYPES.GET_SINGLE_SUPPLIER, payload: id });
};

export const setSupplierId = () => async (dispatch, getState) => {
  let { target_index, supplier_id } = getState().inventory;
  if (supplier_id != null) {
    dispatch(addRequest(target_index, supplier_id, null));
    dispatch({ type: TYPES.SET_MODAL, payload: { supplier: false } });
  } else {
    ToastDanger("Please select a supplier first.");
  }
};

export const perSupplierChange = () => async (dispatch, getState) => {
  let { po_per_supplier } = getState().inventory;

  let checked = po_per_supplier ? false : true;
  dispatch({ type: TYPES.INV_PER_SUPPLIER, payload: checked });
};

export const multipleDelete = (selectedRows) => async (dispatch, getState) => {
  let index = [];
  selectedRows.data.map((data) => {
    index.push(data.dataIndex);
  });
  dispatch({ type: TYPES.SELECTED_ROWS, payload: index });
  SwalWarning(
    "Are you sure?",
    "All the selected item(s) will be deleted from the request, continue?",
    () => dispatch(deleteMultiple())
  );
};

export const deleteMultiple = () => async (dispatch, getState) => {
  let {
    stock_view,
    inventory_list,
    search,
    sort_order_name,
    sort_order_direction,
    pagination_list,
    po_per_supplier,
    selected_rows,
  } = getState().inventory;
  let request = [];
  selected_rows.map((index) => {
    try {
      request.push(inventory_list[stock_view][index]["request_id"]);
    } catch (err) {
      console.log(err);
     // console.log(inventory_list[stock_view][index]);
    }
  });
  dispatch({ type: TYPES.DELETE_GENERAL, payload: true });
  let rows_per_page_val = getNumRows("inventory");
  let formdata = {
    search: search,
    po_per_supplier: po_per_supplier,
    request_id: request,
    options: {
      limit: rows_per_page_val,
      sort_by: sort_order_name,
      sort_order: sort_order_direction,
    },
  };
  InventoryService.removeMultipleRestock(formdata)
    .then((res) => {
      dispatch({ type: TYPES.DELETE_GENERAL, payload: false });
      ToastSuccess(res.data.message);
      inventory_list["all"] = res.data["all"].docs;
      inventory_list["re-stock"] = res.data["re-stock"].docs;
      let pagination = {
        totalCount: res.data["all"].total,
        activePage: res.data["all"].current_page,
        itemsCountPerPage: res.data["all"].per_page,
        totalItemsCount: res.data.total,
        search: search,
      };
      pagination_list["all"] = pagination;
      let pagination_stock = {
        totalCount: res.data["re-stock"].total,
        activePage: res.data["re-stock"].current_page,
        itemsCountPerPage: res.data["all"].per_page,
        totalItemsCount: res.data.total,
        search: search,
      };
      pagination_list["re-stock"] = pagination_stock;
      dispatch({
        type: TYPES.GET_INVENTORY_LIST,
        payload: {
          inventory_list: inventory_list,
          pagination: pagination,
          pagination_list: pagination_list,
        },
      });

      let strcount =
        res.data["all"].retstock_count > 99
          ? "99+"
          : res.data["all"].retstock_count;
      dispatch({
        type: TYPES.PO_REQUEST_COUNT,
        payload: { value: res.data["all"].retstock_count, str: strcount },
      });
      if (res.data["all"].retstock_count <= 0) {
        dispatch({ type: TYPES.GET_STOCK_VIEW, payload: "all" });
      }
      dispatch({ type: TYPES.SELECTED_ROWS, payload: [] });
    })
    .catch((err) => {
      dispatch({ type: TYPES.DELETE_GENERAL, payload: false });
      try {
        ToastDanger(err.data.message);
      } catch (error) {
        ToastDanger("Unknown Error occured.");
        console.log("error", error);
      }
    });
};

export const setSelectedRows = (rows) => async (dispatch) => {
  let indexes = [];
  rows.map((data) => {
    indexes.push(data.dataIndex);
  });
  dispatch({ type: TYPES.SELECTED_ROWS, payload: indexes });
};

// returns selected rows only
export const getSelectedRows =
  (selectedRows, currentRows) => async (dispatch) => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current, i) => {
      selectedRows.map((selected, _i) => {
        if (selected.index == current.index) {
          let selected = { id: current.data[0], name: current.data[1] };
          newArray.push(selected);
        }
      });
    });
    // console.log(newArray);
    dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
  };
export const handleSearchInputMUI = (e) => async (dispatch) => {
  dispatch({ type: TYPES.SET_SEARCH, payload: e.target.value });
  if (e.key === "Enter") {
    console.log("enter");
    // dispatch(getPurchaseOrders(1));
  }
};

export const filterItemByBrand =
  (brand_obj = null, bool) =>
  async (dispatch) => {
    if (bool) {
      dispatch(getInventoryList(1, null, null, brand_obj.brand_id));
      dispatch({ type: TYPES.INV_FILTER_BRAND, payload: brand_obj.brand_name });
    } else {
      dispatch({ type: TYPES.INV_FILTER_BRAND, payload: null });
      dispatch(getInventoryList(1, null, null, null));
    }
  };

export const stockPopUp = () => async (dispatch, getState) => {
  let { _id, user } = getState().auth;
  SwallverfiyPass(function (password) {
    let formdata = {
      password: password,
      id: user._id, 
      subscriber_id: user.subscriber_id, 
      role_id: user.role_id
    };

    InventoryService.verifyPassword(formdata)
      .then((result) => {
        // SwalSuccessAutoRemove(result.data.message, callback('success'))
        dispatch(updateStock());
      })
      .catch((err) => {
        console.log("err", err);
        try {
          SwalError(err.data.message);
        } catch (error) {
          console.log("error", error);
          SwalError("Unknown error occured");
        }
        // reject(err)
      });
  });
};

export const updateStock = () => async (dispatch, getState) => {
  let { inventory_item } = getState().inventory;
//  console.log("getState().inventory;", getState().inventory);
  SwallnewStock(inventory_item.product_name, inventory_item.stock, function (qty) {
    let formdata = {
      qty: qty,
      id: inventory_item._id,
    };
    InventoryService.updateStock(inventory_item._id, formdata)
      .then((result) => {
        SwalSuccessAutoRemove(result.data.message);
        dispatch(getStockLogs());
        dispatch(getInvetoryItem(inventory_item._id));
      })
      .catch((err) => {
        console.log("err", err);
        try {
          SwalError(err.data.message);
        } catch (error) {
          console.log("error", error);
          SwalError("Unknown error occured");
        }
        // reject(err)
      });
  });
};

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
  dispatch({
    type: TYPES.DATE_FILTER_INPUT,
    payload: { key: [e.target.name], value: e.target.value },
  });
};


// DATE INPUT CLEAR
export const dateFilterClear =
  (type, pageOrigin = null) =>
  async (dispatch) => {
    dispatch({ type: TYPES.INVENTORY_DATE_INPUT_CLEAR, payload: type });

    switch (type) {
      case "stock_logs":
        dispatch(getStockLogs(1, null, pageOrigin));
        break;
      default:
        break;
    }
  };

// HANDLE SUBMIT DATE FILTER FOR [ QUOTATIONS, SALES_INVOICES .... ]
export const handleDateFilterSubmit =
  (e, filterBy, pageOrigin = null) =>
  async (dispatch, getState) => {
    e.preventDefault();

    let { date_to, date_from } = getState().inventory;
    const today = new Date();


    try {
      if (
        new Date(date_from).getTime() >= today.getTime() ||
        new Date(date_to).getTime() >= today.getTime()
      ) {
        ToastDanger("Your Date value can not be greater than today.");
      } else {
        let date_filter = { date_from: date_from, date_to: date_to };

        // CHECK TYPE OF PAGE TO FILTER
        switch (filterBy) {
          case "stock_logs":
            dispatch(getStockLogs(1, null, pageOrigin, date_filter));
            break;
          default:
            break;
        }

       // console.log(pageOrigin);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      ToastDanger("Something went wrong please contact your admin...");
    }
  };
