import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryList from "react-select-country-list";
import {
  customerPost,
  handleInputChange,
  handleSelectCountry,
  handleMobile,
  addFile,
  removeFileEdit,
  clearCustomer,
} from "../../redux/actions/customer/customer.actions";
import { useDropzone } from "react-dropzone";
import ReactTooltip from "react-tooltip";
import { fileExtensionIcon } from "../../Utils/Common";
import { SwalFormWarning } from "../../Services/_swal.service";
import BeforeUnloadComponent from "react-beforeunload-component";
import { useHistory, useLocation } from "react-router-dom";
import { blockRoute } from "../../redux/actions/helper/helper.actions";

function CustomerCreateGlobal({
  customer: {
    single_customer: { isSearchable, uploads },
    main_page,
    main_url,
  },
  handleInputChange,
  customerPost,
  handleSelectCountry,
  handleMobile,
  addFile,
  removeFileEdit,
  pageOrigin,
  clearCustomer,
  block_route,
  blockRoute,
}) {
  const [countryOptions, setCountryOptions] = useState("");

  const [countryValue, setCountryValue] = useState({value: "PH", label: "Philippines"})
  const [mobileCountry, setMobileCountry] = useState("ph")

  /**
   * @param {"country" | "mobile"} origin - origin of change - "country" if from country dropdown or "mobile" if from mobile input
   * @param {string | null} - country code in lowercase e.g. "ph"
   * @param {{value: string, label: string} | null} countryObject - country dropdown option e.g. {value: "PH", label: "Philippines"}
   */
  const syncMobileAndCountry = (origin, country, countryObject) => {
    if (!origin || !(["country", "mobile"].find(item => item === origin.toLowerCase()))) return;
    

    if (origin === "country") {
      if (!countryObject) {
        setMobileCountry("ph")
      } else {
        setMobileCountry(countryObject.value.toLowerCase())
      }
    } else if (origin === "mobile") {
      if (!country) return;

      const countryValue = countryOptions.find(countryOption => countryOption.value === country.toUpperCase())
      setCountryValue(countryValue)
      handleSelectCountry(countryValue) // need to do change country in state as well
    }

    // reset mobile
    handleMobile("")
    
  }

  const onDrop = useCallback((acceptedFiles) => {
    addFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  let history = useHistory();

  useEffect(() => {
    if (countryOptions == "") {
      setCountryOptions(countryList().getData());
    }
  }, [countryOptions]);

  useEffect(() => {
    clearCustomer();
    blockRoute(false);
  }, []);

  useEffect(() => {
    // When onClick navbar prevent another dialog box
    window.addEventListener("beforeunload", function (e) {
      // the absence of a returnValue property on the event will guarantee the browser unload happens
      delete e["returnValue"];
      blockRoute(false);
    });

    if (block_route) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
    }

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block_route]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (block_route) {
      SwalFormWarning(
        "Warning",
        "Do you want to leave without saving",
        () => goLastLocation(),
        () => blockAgain()
      );
    }
  };

  const goLastLocation = () => {
    history.goBack();
    blockRoute(false);
  };

  const blockAgain = () =>
    window.history.pushState(null, null, window.location.pathname);

  if (pageOrigin != "customer") {
    return (
      <BeforeUnloadComponent
        ignoreBeforeunloadDocument={true}
        blockRoute={block_route}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          SwalFormWarning(
            "Warning",
            "Do you want to leave without saving",
            () => handleModalLeave(),
            () => handleModalCancel()
          );
        }}
      >
        <form onSubmit={(e) => customerPost(e, pageOrigin)}>
          <section className='row f-regular'>
            <ReactTooltip effect='solid' event='click' />
            <div className='col-12'>
              <label className='label-control f-regular'>
                Name: <span className='text-danger'>*</span>
              </label>
              <div className='form-group row'>
                <div className='col-md-11 col-10'>
                  <input
                    onChange={handleInputChange}
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='Name'
                    required
                  />
                </div>
                <div className='float-right pl-0 col-1 col-md-1'>
                  <div className='input-group-prepend'>
                    <span
                      href='#'
                      className='tool-tip text-primary'
                      data-tip='this is a tool tip'
                    >
                      <i className='la la-question-circle'></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group row'>
                <div className='col-md-4 col-12 label-control f-regular'></div>
                <div className='col-md-7 col-10 text-right'>
                  <button
                    type='submit'
                    className='btn btn-primary btn-save f-regular'
                  >
                    Save
                  </button>
                </div>
                <div className='float-right pl-0 col-2 col-md-1'></div>
              </div>
            </div>
          </section>
        </form>
      </BeforeUnloadComponent>
    );
  }

  return (
    <BeforeUnloadComponent
      blockRoute={block_route}
      ignoreChildrenLinks={true}
      modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
        SwalFormWarning(
          "Warning",
          "Do you want to leave without saving",
          () => handleModalLeave(),
          () => handleModalCancel()
        );
      }}
    >
      <form onSubmit={(e) => customerPost(e, pageOrigin)}>
        <ReactTooltip effect='solid' event='click' />
        <section className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <label className='label-control'>
                  Name: <span className='text-danger'>*</span>
                </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name='name'
                      className='form-control f-regular'
                      placeholder='Name'
                      required
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Business Name: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name='business_name'
                      className='form-control f-regular'
                      placeholder='Business Name'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Street 1: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <textarea
                      onChange={handleInputChange}
                      name='street1'
                      rows='3'
                      className='form-control f-regular'
                      placeholder='Street 1'
                      style={{ height: "80px", resize: "none" }}
                    ></textarea>
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Street 2: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <textarea
                      onChange={handleInputChange}
                      name='street2'
                      rows='3'
                      className='form-control f-regular'
                      placeholder='Street 2'
                      style={{ height: "80px", resize: "none" }}
                    ></textarea>
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>City: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name='city'
                      className='form-control f-regular'
                      placeholder='City'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>State/Region: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name='state'
                      className='form-control f-regular'
                      placeholder='State/Region'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Country: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <Select
                      value={countryValue}
                      options={countryOptions}
                      onChange={(option) => {setCountryValue(option); syncMobileAndCountry("country", null, option); handleSelectCountry(option)}}
                      isSearchable={isSearchable}
                      name='country'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Landline: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='number'
                      name='landline'
                      className='form-control f-regular'
                      placeholder='Landline'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Mobile: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <PhoneInput
                      countryCodeEditable={false}
                      enableSearch='true'
                      country={mobileCountry}
                      inputProps={{
                        name: "mobile",
                        required: false,
                        autoFormat: true,
                        enableSearch: true,
                      }}
                      inputClass='react-mobile-input w-100'
                      placeholder=''
                      masks={{ ph: "... ... ...." }}
                      onChange={(mobile, country) => {setMobileCountry(country.countryCode); syncMobileAndCountry("mobile", country.countryCode); handleMobile(mobile === country.dialCode ? "" : mobile)}}
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Fax: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='number'
                      name='fax'
                      className='form-control f-regular'
                      placeholder='Fax'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>TIN: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='number'
                      name='tin'
                      className='form-control f-regular'
                      placeholder='TIN'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Email: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='email'
                      name='email'
                      className='form-control f-regular'
                      placeholder='Email'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <label className='label-control'>Contact Person: </label>
                <div className='form-group row'>
                  <div className='col-md-11 col-10'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name='contact_person'
                      className='form-control f-regular'
                      placeholder='Contact Person'
                    />
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'>
                    <div className='input-group-prepend'>
                      <span
                        href='#'
                        className='tool-tip text-primary'
                        data-tip='this is a tool tip'
                      >
                        <i className='la la-question-circle'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-6 offset-6'>
                <div className='form-group row mt-3'>
                  <div className='col-md-12 col-8 text-right'>
                    <button type='submit' className='btn btn-primary btn-save'>
                      Save
                    </button>
                  </div>
                  <div className='float-right pl-0 col-1 col-md-1'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </BeforeUnloadComponent>
  );
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
  handleInputChange,
  customerPost,
  handleSelectCountry,
  handleMobile,
  addFile,
  removeFileEdit,
  clearCustomer,
  blockRoute,
})(CustomerCreateGlobal);
