import http from '../../../Services/api.laravel.http';

export const DeliveryTemplateSettingService = {
	getDRTemplateSettings: () => http.get('/admin/delivery-template/settings/delivery-template'),
	storeDRTS: (formParams) => http.post('/admin/delivery-template/settings/delivery-template', formParams),
   	setAsDefault: (formParams) => http.post('/admin/delivery-template/settings/default', formParams),
  	drTemplateSettingArchive: (formParams) => http.post('/admin/delivery-template/settings/archive', formParams),
   	getSingleDRTS: (id) => http.get(`/admin/delivery-template/settings/${id}`),
   	updateDRTS: (formParams) => http.post(`/admin/delivery-template/settings/update`, formParams),
}
