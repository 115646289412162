import { SET_LOADING, SORT_BY_COLUMN,
     SET_MODAL, GET_ROWS_TO_DELETE, SET_ROW_CHECKED } from './../types';

import { GET_BRANDS, BRAND_INPUT_CHANGE, GET_SINGLE_BRAND, CLEAR_BRAND, BRAND_SEARCH, SEARCH_INPUT, SET_PAGE } from './ReducerTypes/brand';

// state
const initialState = {
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    brands: [],
    single_brand: [],
    brand_search: '',
    loading: false,
    main_brand_page: false,
    edit_page: false,
    delete_modal: false,
    main_url: '/inventory/products?mode=Brands',
    create_url: '/inventory/products/brands/create',
    edit_url: '/inventory/products/brands/edit',
    // create_url: '/products/brands/create',
    // edit_url: '/products/brands/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    formChange: false,
}

const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANDS:
            return {
                ...state,
                brands: action.payload.brands,
                pagination: action.payload.pagination,
                set_row_checked: false
            }
        case GET_SINGLE_BRAND:
            return {
                ...state,
                single_brand: action.payload
            }
        case BRAND_INPUT_CHANGE:
            return {
                ...state,
                single_brand: { ...state.single_brand, [action.payload.key]: action.payload.value },
                formChange: true,
            }
        case BRAND_SEARCH: 
            return {
                ...state,
                brand_search: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_PAGE:
            if(action.payload.page == 'main_brand_page')
            {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                }
            }
            else if(action.payload.page == 'edit_page')
            {
                return {
                    ...state,
                    main_brand_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status
                }
            }
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload
            }
        case SET_ROW_CHECKED: 
            return {
                ...state,
                set_row_checked: true
            }
        case CLEAR_BRAND:
            return {
                ...state,
                single_brand: [],
                brand_search: '',
                loading: false,
                main_brand_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/inventory/products?mode=Brands',
                create_url: '/inventory/products/brands/create',
                edit_url: '/inventory/products/brands/edit',
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                set_row_checked: false,
                formChange: false,
            }
        default:
            return state;
            break;
    }

}

export default brandReducer;

