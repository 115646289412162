import React, { useEffect, Fragment } from "react";
import Spinner from "../layouts/Spinner";
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Redirect, useHistory, Link } from 'react-router-dom';
function ProfileInvoiceReceipts () {

 return (

<div className="app-content content">
    <ReactTooltip effect="solid" event="click" />
    <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
            <div className="content-header-left col-md-12 col-12 mb-2  fix-header">
                <h3 className="content-header-title mb-0 d-inline-block text-white">Profile</h3>
            </div>
        </div>
        <div className="content-body margin-top-5 mt-2">
            <section className="row f-regular">
                <div className="col-sm-12">
                    <div className="card">
                        {/*
                        <div className="card-header"></div>
                        */}
                        <div className="card-content collapse show">
                            <div className="card-body">
                                <div className="">
                                    <nav class="nav f-regular">
                                        <Link to={'/account/profile'} className="nav-link mr-50 inv-top-nav ">Information</Link>
                                        <Link to={'/account/profile/subcription'} className="nav-link mr-50 inv-top-nav">Billing</Link>
                                        <Link to={'/account/invoice_receipts'} className="nav-link mr-50 inv-top-nav inv-top-nav-active">Sales Invoice/Receipt</Link>                                                
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="basic-examples">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className="row mt-4 mb-5">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <a href="/account/profile/invoice">
                                                        <div className="card pull-up sales-box">
                                                            <div className="card-content">
                                                                <div className="card-body">
                                                                    <div className="media d-flex">
                                                                        <div className="align-self-top"><i className="ft-layers font-large-4 float-left"></i></div>
                                                                        <div className="media-body text-right align-self-bottom mt-3">
                                                                            <a className="d-block mb-1 font-medium-2" href="/account/profile/invoice">Sales Invoice</a>
                                                                            <h1 className="mb-0">2</h1>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>

                                                <div className="col-md-6">
                                                    <a href="/account/profile/receipts">
                                                        <div className="card pull-up sales-box ">
                                                            <div className="card-content">
                                                                <div className="card-body">
                                                                    <div className="media d-flex">
                                                                        <div className="align-self-top"><i className="ft-file-text   font-large-4 float-left"></i></div>
                                                                        <div className="media-body  text-right align-self-bottom mt-3">
                                                                            <a className="d-block mb-1 font-medium-2 " href="/account/profile/receipts">Receipts</a>
                                                                            <h1 className=" mb-0">5</h1>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

   

);

  }

 
const mapStateToProps = state => ({
    // profile: state.profile
});

export default connect(mapStateToProps, {  })(ProfileInvoiceReceipts);