import * as TYPE from './../types';
import { CAT_SEARCH, SET_PAGE } from './ReducerTypes/category';
// state
const initialState = {
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8},
    categories: [],
    parent_category: [],
    single_category: {
        name: '',
        parent_category_id: '',
        parent_category_checked: false,
        description: ''
    },
    default_parent_category: { value: '', label: 'Choose Parent Category' },
    cat_search: '',
    loading: false,
    category_page: false,
    edit_page: false,
    delete_modal: false,
    main_url: '/inventory/products?mode=Categories',
    // create_url: '/products/categories/create',
    // edit_url: '/products/categories/edit',
    create_url: '/inventory/products/categories/create',
    edit_url: '/inventory/products/categories/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    diselect_row: false,
    set_row_checked: false
}

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
                pagination: action.payload.pagination,
                set_row_checked: false
            }
        case TYPE.GET_PARENT_CATEGORY:
            return {
                ...state,
                parent_category: action.payload
            }
        case TYPE.SET_PARENT_CATEGORY:
            return {
                ...state,
                default_parent_category: action.payload,
                single_category: { ...state.single_category, parent_category_id: action.payload.value }
            }
        case TYPE.GET_SINGLE_CATEGORY:
            if(action.default_parent_category)
            {
                return {
                    ...state,
                    single_category: { ...state.single_category, ...action.payload },
                    default_parent_category: action.default_parent_category
                }
            }
            else
            {
                return {
                    ...state,
                    single_category: { ...state.single_category, ...action.payload }
                }
            }
        case TYPE.CATEGORY_INPUT_CHANGE:
            return {
                ...state,
                single_category: { ...state.single_category, [action.payload.key]: action.payload.value }
            }
        case CAT_SEARCH: 
            return {
                ...state,
                cat_search: action.payload
            }
        case TYPE.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_PAGE:
            if(action.payload.page == 'category_page')
            {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                }
            }
            else if(action.payload.page == 'edit_page')
            {
                return {
                    ...state,
                    category_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status
                }
            }
            else
            {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status
                }
            }
        case TYPE.PARENT_CATEGORY_CHECKED:
            return {
                ...state,
                single_category: { ...state.single_category, parent_category_checked: action.payload }
            }
        case TYPE.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPE.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload
            }
        case TYPE.SET_ROW_CHECKED: 
            return {
                ...state,
                set_row_checked: true
            }
        case TYPE.CLEAR_CATEGORY:
            return {
                ...state,
                single_category: [],
                default_parent_category: { value: '', label: 'Choose Parent Category' },
                category_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/products/categories',
                // create_url: '/products/categories/create',
                // edit_url: '/products/categories/edit',
                create_url: '/inventory/products/categories/create',
                edit_url: '/inventory/products/categories/edit',
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                diselect_row: false,
                cat_search: ""
            }
        default:
            return state;
            break;
    }

}

export default categoryReducer;

