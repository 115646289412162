import React, { Fragment, useEffect, useState, useRef } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { formattedDate, truncateObjectId } from '../../Utils/Common';
import {
    getBrands,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
} from '../../redux/actions/brand/brand.actions';
import { Redirect, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import moment from 'moment';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function BrandList({
    brand: {
        brands,
        pagination,
        brand_search,
        loading,
        edit_url,
        edit_page,
        create_url,
        sort_order_name,
        sort_order_direction,
        set_row_checked,
    },
    getBrands,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    hoverIndex,
    handleHover,
    updateMUISetting,
    setMUIPage,
    muiPage,
}) {
    const { hasPermission } = useAccess();
    const canCreate = true;
    const canDelete = true;
    const canEdit = true;
    const canview = true;

    const history = useHistory();

    useEffect(() => {
        getBrands(muiPage);
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(brand_search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getBrands(1);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const [copiedId, setCopiedId] = useState(null);

    if (edit_page) return <Redirect to={edit_url} />;

    const changePage = (link) => {
        history.push(link);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(6)': {
                            height: '61px',
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        '&:nth-child(3)': {
                            width: '250px',
                        },
                        '&:nth-child(6)': {
                            width: '150px',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: 'ID',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                customBodyRender: (_, tableMeta) => {
                    const brandId = brands[tableMeta['rowIndex']]._id;
                    return (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <span>{truncateObjectId(brandId)}</span>
                            {hoverIndex === tableMeta.rowIndex ? (
                                <span style={{ marginLeft: '15px' }}>
                                    <span
                                        style={{ position: 'relative' }}
                                        onMouseLeave={() =>
                                            setTimeout(
                                                () => setCopiedId(null),
                                                230
                                            )
                                        }
                                    >
                                        <CopyToClipboard
                                            text={brandId}
                                            onCopy={() => {
                                                setCopiedId(brandId);
                                            }}
                                        >
                                            {/* <FontAwesomeIcon
                                                icon={faCopy}
                                                // style={{ color: '#32cafe' }}
                                                // style={{ color: 'blue' }}
                                                size="lg"
                                            /> */}
                                            <i  className="ft-copy" style={{fontSize: "14px"}}></i>
                                        </CopyToClipboard>
                                        {copiedId === brandId ? (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    left: '-20px',
                                                    width: '60px',
                                                    height: '60px',
                                                }}
                                            >
                                                <a
                                                    data-tip="Copied!"
                                                    data-for={`copied-${brandId}`}
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '100%',
                                                    }}
                                                >
                                                    &nbsp;
                                                </a>
                                                <ReactTooltip
                                                    id={`copied-${brandId}`}
                                                    type="success"
                                                    effect="solid"
                                                    scrollHide={false}
                                                    resizeHide={false}
                                                    place="top"
                                                    delayHide={5000}
                                                />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                },
            },
        },
        { name: 'name', label: 'Name', options: { display: true } },
        {
            name: 'description',
            label: 'Description',
            options: { display: true },
        },
        // {
        //     name: 'created_at',
        //     label: 'Created at',
        //     options: {
        //         display: true,
        //         print: false,
        //         download: false,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             let created_at = brands[tableMeta['rowIndex']].created_at;
        //             return (
        //                 <>
        //                     {created_at &&
        //                         moment(created_at, [
        //                             'YYYY-MM-DDTHH:mm:ss.SSSZ',
        //                             'YYYY-MM-DD hh:mmA',
        //                         ]).format('YYYY-MM-DD - hh:mmA')}
        //                 </>
        //             );
        //         },
        //     },
        // },
        // {
        //     name: 'updated_at',
        //     label: 'Updated at',
        //     options: {
        //         display: true,
        //         print: false,
        //         download: false,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             let updated_at = brands[tableMeta['rowIndex']].updated_at;
        //             return (
        //                 <>
        //                     {updated_at &&
        //                         moment(updated_at, [
        //                             'YYYY-MM-DDTHH:mm:ss.SSSZ',
        //                             'YYYY-MM-DD hh:mmA',
        //                         ]).format('YYYY-MM-DD - hh:mmA')}
        //                 </>
        //             );
        //         },
        //     },
        // },
        {
            name: '',
            label: '',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = brands[tableMeta['rowIndex']]._id;

                    if (!canEdit) {
                        return false;
                    }

                    return (
                        <div className="btn-group" style={{ minWidth: '50px' }}>
                            {hoverIndex == tableMeta.rowIndex ? (
                                <Link
                                    to={`${edit_url}/${id}`}
                                    className="btn btn-sm btn-grey"
                                >
                                    <i className="ft ft-edit"></i>
                                </Link>
                            ) : (
                                ''
                            )}
                            {/* <Link to={`${edit_url}/${id}`} className="btn btn-sm btn-grey"><i className="ft ft-edit"></i></Link> */}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'text-center' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        elevation: 2,
        filter: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `brands_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getBrands(null, numberOfRows);
            updateMUISetting('rows', 'brand', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getBrands(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'brand',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }

            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // Go to edit page when row is clicked
            if (canEdit) {
                // changePage(`${edit_url}/${rowData[0]}`);
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        value={brand_search}
                        onChange={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting('view-columns', 'brand', null, columns);

    return (
        <div className="col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">
                        List of Brands{' '}
                        <a
                            href="#"
                            className="tool-tip"
                            data-tip="this is a tool tip"
                        >
                            <i className="la la-question-circle"></i>
                        </a>
                        <div className="pull-right">
                            <Show when="inv.products.brands.create">
                                <Link
                                    to={create_url}
                                    className="btn btn-sm btn-primary text-white "
                                >
                                    <i className="ft ft-plus"></i> Create New
                                </Link>
                            </Show>
                        </div>
                    </h4>
                    <a className="heading-elements-toggle">
                        <i className="la la-ellipsis-v font-medium-3"></i>
                    </a>
                    <div className="heading-elements">
                        <ul className="list-inline mb-0"></ul>
                    </div>
                </div>
                <div className="card-content collapse show">
                    <div className="card-body">
                        <Show when="inv.products.brands.view">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={brands}
                                    columns={_columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </Show>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    brand: state.brand,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getBrands,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
})(BrandList);
