import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';

function ScanSo({ show, search, handleInputChange, scanSO }) {
    // Create a reference for the input element
    const inputRef = useRef(null);

    // Focus the input field when the modal is shown
    useEffect(() => {
        if (show && inputRef.current) {
            inputRef.current.focus();
        }
    }, [show]);
    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>Scan Sales Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => scanSO(e, search)} className="pick-form">
                    <div className="input-group">
                        <input
                            type="text"
                            id="search"
                            value={search}
                            onChange={handleInputChange}
                            name="search"
                            className="form-control pick-input"
                            placeholder="Enter Sales Order #"
                            required
                            ref={inputRef}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary pick-btn"
                                type="submit"
                            >
                                <i className="ft ft-search"></i> Search
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default ScanSo;
