import React, {Fragment, useEffect, useMemo, useState } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { setPage, getInvoices,  sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox, sendEmailDyanmic, dynamiPdf, emailConfig, clickedPaymentWarning} from '../../redux/actions/invoice/invoice.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import InvoicePrintModal from './modals/Invoice.modal.print';
import {numberFormat} from '../../Utils/Common';
import {  Show, useAccess } from "react-redux-permission";
import { formattedDate } from '../../Utils/Common';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { handleHover, setMUIPage, updateMUISetting } from "../../redux/actions/helper/helper.actions";
import { defaultFormat } from '../../Utils/DateFormat';


function InvoiceTable ({ invoice: { search, loading, create_url, edit_url, edit_page, print_modal, pagination, invoices,  sort_order_name, sort_order_direction, set_row_checked  }, 
        setPage, sortTableByColumn, getInvoices, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox, pageOrigin, handleHover, hoverIndex, updateMUISetting, 
        sendEmailDyanmic, dynamiPdf, emailConfig, setMUIPage, muiPage, clickedPaymentWarning}) {

    const { hasPermission } = useAccess();
    const canCreate = hasPermission("inv.sales.invoices.create");
    const canDelete = hasPermission("inv.sales.invoices.delete");
    const canEdit = hasPermission("inv.sales.invoices.edit");
    const canPrint = hasPermission("inv.sales.invoices.print");
    const canViewCost = hasPermission("inv.sales.invoices.view-cost");
    const canCreateDR = hasPermission("inv.delivery.receipts.create");

    useEffect(() => {
        getInvoices(muiPage, null, pageOrigin);
        emailConfig()
    }, []);


    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
      if (debouncedSearch) {
        if(debouncedSearch[0]){
            if(oldDebounced !== debouncedSearch[0]){
              if(debouncedSearch[0].length !== 1){
                setOldDebounced(debouncedSearch[0]);
                getInvoices(1, null, pageOrigin);
              }
            }
        }
        else
        {
            if(oldDebounced !== ""){
              setOldDebounced("");
              getInvoices(1, null, pageOrigin);
            }
        }
      } 
    },[debouncedSearch]);
    // END DEVOUNCE SEARCH


    if(edit_page) return <Redirect to={edit_url}/>

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                },
            },
            MUIDataTableBodyRow: {
                root: {
                    '&:nth-child(odd)': { 
                        backgroundColor: "rgba(0,0,0,.05)"
                    },
                },
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    },
                },
            },
            MUIDataTableBodyCell: {
                root: {
                  "&:nth-child(4)": {
                    height: "61px",
                  },
                  "&:nth-child(8)": {
                    width: "200px",
                  },
                  "&:nth-child(9)": {
                    width: "200px",
                  },
                },
              },
            MUIDataTableHeadCell: {},
        },
    });

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } },
        {name: 'invoice_no', label: 'Sales Invoice No', options: { display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = invoices[tableMeta['rowIndex']]._id;
                    return (
                        <a className="txt-underline" onClick={() => setPage('print_modal_invoice', id)}>{value}</a>
                        // <Link className="txt-underline" onClick={() => setPage('print_modal_invoice', id)}>{value}</Link>
                    )
                }
            } 
        },
        {name: 'created_at', label: 'Date Created', options: { display: true, 
                customBodyRender: (value) => {
                    return (
                        <span> {value.substr(0, 10)} </span>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            }
        },
        {name: 'customer', label: 'Customer', options : { display: true, filter: false, sort: false,
                customBodyRender: (value, tableMeta) => {
                    let id = invoices[tableMeta['rowIndex']].customer_id;
                    return (
                        <Link className="txt-underline" to={`/sales/customers/history/${id}`}>{value}</Link>
                    )
                }
            }
        },
        // {name: 'payment_status', label: 'Payment', options: { display: true, 
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //         const si = {
        //             _id: invoices[tableMeta['rowIndex']]._id,
        //             serial_no: invoices[tableMeta['rowIndex']].serial_no,
        //             total_price: numberFormat(invoices[tableMeta['rowIndex']].grand_total),
        //             payment_status: value,
        //         }

        //         return (
        //                 <span onClick={() => clickedPaymentWarning(si)} className={ "lbl-status lbl-" + value}> {value} </span>
        //             )
        //         }
        //     }
        // },
        {name: 'status', label: 'Status', options: {  display: true,
            customBodyRender: (value) => {
                return (
                    <span className={ "lbl-status lbl-" + value}> {value} </span>
                    )
                }
            }
        },
        {name: canViewCost ? 'total_cost' : '', label: canViewCost ? 'Cost' : '', options : { display: true,
            customBodyRender: (value) => {
                    if(!canViewCost)
                    {
                        return false;  
                    }

                    return (
                        // <div className="money-cell">₱ {numberFormat(value)}</div>
                        <div className="">₱ {numberFormat(value)}</div>
                    )              
                }
            }
        },
        {name: 'total', label: 'Total', options: { display: true,
                customBodyRender: (value) => {
                    return (
                        // <div className="money-cell">₱ {value && numberFormat(value)}</div>
                        <div className="">₱ {value && numberFormat(value)}</div>
                    )
                }
            } 
        },
        {name: 'reference_no', label: 'Reference No.', options: { display: true,
                customBodyRender: (value, tableMeta) => {
                    let data = invoices[tableMeta['rowIndex']];
                    let linkTo = '';

                    if (value.startsWith('SO')) {
                        linkTo = `/sales/sales-orders/edit/${data.reference_id}`;
                    } else if (value.startsWith('DR')) {
                        linkTo = `/deliveries/edit/${data.reference_id}`;
                    }

                    return (
                        <Link to={linkTo} className="txt-underline">
                        {value}
                        </Link>
                    );
                    },
                setCellProps: (value) => {
                    return {className : "mw-20m"}
                } 
            } 
        },
    //     {name: '', label: '', options: { display: true, filter: false, sort: false, print: false , download: false, empty: true,
    //         customBodyRender: (value, tableMeta, updateValue) => {
          
    //             if(!canCreateDR)
    //             {
    //                 return false;
    //             }

    //             let makeDR = {
    //                 value: invoices[tableMeta['rowIndex']]._id,
    //                 name: 'si',
    //                 label: invoices[tableMeta['rowIndex']].invoice_no,
    //                 pageOrigin: 'si-makeDR'
    //             }

    //             let status = invoices[tableMeta['rowIndex']].status;
    //             let delivery_status = invoices[tableMeta['rowIndex']].delivery_status;
    //             let dr_id = invoices[tableMeta['rowIndex']].dr_id;
    //             // console.log("invoices[tableMeta['rowIndex']]",invoices[tableMeta['rowIndex']]);
    //             return (
    //                 <div>
    //                     {/* <div width="15%" align="center"> */}
    //                         {
    //                             status === 'saved' && dr_id == null?  
    //                             <Link to={{ pathname: "/deliveries/create", makeDR }} className='btn btn-sm btn-bg-gradient-x-purple-blue btn-glow text-white'>MAKE DR</Link> 
    //                             : 
    //                             dr_id != null ?
    //                             <span className={`lbl-status mr-1 delivery_status lbl-${delivery_status}`}>{delivery_status}</span>
    //                             :
    //                             <span>N/A</span>
    //                         }
    //                     {/* </div> */}
    //                 </div>
    //             )
    //         },
    //         setCellProps: (value) => {
    //             return {className : "cursor-pointer text-center"}
    //         }
    //     }
    // },
        { name: '', label: 'Actions', options: { display: true, filter: false, sort: false, print: false, download: false, 
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = invoices[tableMeta['rowIndex']]._id;
                    // console.log("invoices[tableMeta['rowIndex']]", invoices[tableMeta['rowIndex']]);
                    if(!canEdit)
                    {
                        return false;
                    }

                    return (    
                        <div className="btn-group">
                            {
                               (hoverIndex == tableMeta.rowIndex || invoices[tableMeta['rowIndex']].sending_email || invoices[tableMeta['rowIndex']].download_pdf) && 
                                <>
                                    <Link to={ '/sales/invoices/edit/' + id } className="btn btn-sm btn-grey btn-sm"><i className="ft ft-edit"></i></Link>	
                                    <button className="btn btn-sm btn-blue" onClick={(e) =>sendEmailDyanmic(e, id, tableMeta.rowIndex)}><i className={invoices[tableMeta['rowIndex']].sending_email ? "icon-loading-custom icon-15" : invoices[tableMeta['rowIndex']].email_sent ? "custom-resend" : "ft ft-navigation"}></i></button>
                                    <button className="btn btn-sm btn-danger" onClick={(e) => dynamiPdf(e, id, tableMeta.rowIndex)}><i className={invoices[tableMeta['rowIndex']].download_pdf ? "icon-loading-custom icon-15" : "ft-custom-pdf"}></i></button>
                                </>
                            }
                        </div>
                    )
                },
                setCellProps: (value) => {
                    return {className : "text-center"}
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        filter : false,
        rowsPerPageOptions: [10,20,50,100,200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `invoices_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
          getInvoices(null, numberOfRows, pageOrigin)
          updateMUISetting('rows', 'invoice', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState)
            switch (action) {
                case 'changePage':
                    getInvoices(tableState.page + 1, pagination.itemsCountPerPage, pageOrigin);
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search': 
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if(set_row_checked){
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    }
                    else
                    {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'invoice', null, tableState.columns);
                    break;
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if(canDelete)
            {
                confirmDeleteDialogBox(pageOrigin);
            }
            
            return false;
        },
        onRowClick: (rowData, rowMeta) => {},
        onCellClick: (colData, cellMeta) => {
            // console.log(cellMeta)
            if(cellMeta.colIndex !== 9)
            {   
                // console.log('PO_PRINT_ID', invoices[cellMeta.dataIndex]._id, invoices);
                if(canPrint)
                {
                    // setPage('print_modal_invoice', invoices[cellMeta.dataIndex]._id); 
                }
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search" onChange={handleSearchInputMUI} className="custom-search-input"/>
                </div>
            )
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return { onMouseEnter: () => handleHover('in', rowIndex), onMouseLeave: () => handleHover('out', rowIndex) }
        },            
    };


    if(!canDelete)
    {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting('view-columns', 'invoice', null, columns);

    return (
        <>
            <Show when="inv.sales.invoices.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable title={""} data={invoices} columns={_columns} options={options}/>
                </MuiThemeProvider> 
            </Show>
            <Show when="inv.sales.invoices.print"><InvoicePrintModal onHide={() => setPage('print_modal', null, false)} edit_url={edit_url} /></Show>
        </>
    );
}

const mapStateToProps = state => ({
    invoice: state.invoice,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage
});

export default connect(mapStateToProps, { setPage, getInvoices,  sortTableByColumn, handleSearchInputMUI, getSelectedRows, confirmDeleteDialogBox, handleHover, updateMUISetting, 
    sendEmailDyanmic, dynamiPdf, emailConfig, setMUIPage, clickedPaymentWarning})(InvoiceTable);