import React, { Fragment, useEffect, useState } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { defaultFormat } from '../../Utils/DateFormat';
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {  Show, useAccess } from "react-redux-permission";
import ReactTooltip from 'react-tooltip';
import AsyncSelect from 'react-select/async';
import ShippingPop from './ShippingPop';
import debounce from "debounce-promise";
import { searchPO, PoChange, popUpToggleClick, InputChange, ShippingChange, toggleDm, toggleItemDm, addOtherInput, otherInput, saveShipping, getShipping} from '../../redux/actions/shipping/shipping.action';
import calculator from '../../assets/calculator.svg'
import './shipping.css'
import BoxDm from './BoxDm';
import ItemDm from './ItemDm';

function ShippingCostsEdit ({ shipping : {shp_items, selected_po, inputs, other_fees, shp_no, po_no}, searchPO, PoChange, popUpToggleClick, InputChange, ShippingChange, toggleDm, 
    toggleItemDm, addOtherInput, otherInput, saveShipping, match, getShipping}) {
    let total_Qty = 0
    
    useEffect(() => {
        getShipping(match.params.id)
    }, [])

    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Shipping Costs</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item text-white">Shipping Cost - {shp_no}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <Show when="inv.shipping-costs.view">
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                           
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <section className="row">
                                                    <div className="col-md-4">
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Freight Cost</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input type="number" className="form-control text-right" name="freight_cost" onChange={e => InputChange(e)} value={inputs.freight_cost}/>
                                                            </div>
                                                            <div className='col-2 pt-5p'>
                                                                <a href='#' onClick={()=> popUpToggleClick()}><img className='calc-icon' src={calculator} /></a>
                                                                <ShippingPop/>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Broker's Fee</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input type="number" className="form-control text-right" name="broker_fee" onChange={e => InputChange(e)} value={inputs.broker_fee} />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Duties and Taxes</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input type="number" className="form-control text-right" name="duties_taxes" onChange={e => InputChange(e)} value={inputs.duties_taxes}/>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Port Charge</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input type="number" className="form-control text-right" name="port_charge" onChange={e => InputChange(e)} value={inputs.port_charge}/>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Delivery Fee</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input type="number" className="form-control text-right" name="delivery_fee" onChange={e => InputChange(e)} value={inputs.delivery_fee}/>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Others</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input type="number" className="form-control text-right" name="others" onChange={e => InputChange(e)} value={inputs.others}/>
                                                            </div>
                                                        </div>
                                                        {
                                                            other_fees.length > 0 ?
                                                            other_fees.map((data, index) => {
                                                                return (
                                                                    <div key={index} className="row mb-2">
                                                                        <div className='col-4'>
                                                                            <input type="text" className="form-control" placeholder='Label' name="name" value={data.name} onChange={(e) => otherInput(e, index)} />
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <input type="number" className="form-control text-right" placeholder='0' name="value" value={data.value} onChange={(e) => otherInput(e, index)}/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <></>
                                                        }
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <button className='btn btn-primary' onClick={() => addOtherInput()}>+</button>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <div className='col-4'>
                                                                <label>Total Shipping</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <h4 className='text-right'>₱ {NumberFormat(inputs.total_shipping)}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </section>   
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="card">
                                       
                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <div className="row mb-1">
                                                    <label className="col-1">PO</label>
                                                    <div className="col-3">
                                                        {po_no}
                                                    </div>
                                                </div>   
                                                <div className="row mb-1">
                                                    <label className="col-1">Supplier</label>
                                                    <label className="col-6">{selected_po != null ? selected_po.supplier.name : ''}</label>
                                                </div> 
                                                <div className="row mb-1">
                                                    <label className="col-1">PO Date</label>
                                                    <label className="col-6">{selected_po != null ? defaultFormat(selected_po.purchase_order_date, 'LL') : ''}</label>
                                                </div> 
                                                <div className="row mb-3 ">
                                                    <div className='col-md-12 table-responsive'>
                                                        {
                                                            selected_po != null ?
                                                            <table className="table table-hover table-striped table-bordered f-regular">
                                                                <thead>
                                                                    <tr>
                                                                        <th>SKU</th>
                                                                        <th>Product</th>
                                                                        <th>Qty</th>
                                                                        <th width="150">Boxes</th>
                                                                        <th width="150">Box Weight (kg)</th>
                                                                        <th width="150">Box Dimension m<sup>3</sup></th>
                                                                        <th width="150">Items/Box</th>
                                                                        <th width="150">Item Dimension</th>
                                                                        <th width="150">Cost/Item</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        selected_po.purchase_order_items.map((data, index) => {
                                                                            total_Qty += data.qty
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{data.sku}</td>
                                                                                    <td>{data.product_name}</td>
                                                                                    <td className='text-right'>{NumberFormat(data.qty, 0)}</td>
                                                                                    <td><input type="number" className='form-control text-right' onChange={(e) => ShippingChange(index, "box", e.target.value)} value={data.box}/></td>
                                                                                    <td ><input type="number" className='form-control text-right ' onChange={(e) => ShippingChange(index, "box_weight", e.target.value)} value={data.box_weight}/></td>
                                                                                    <td className='d-iflex'>
                                                                                        <input type="number" className='form-control text-right w-75' onChange={(e) => ShippingChange(index, "box_dimension", e.target.value)} value={data.box_dimension}/> 
                                                                                        <img className='calc-icon calc-input' src={calculator} onClick={() => toggleDm(index)}/>
                                                                                        {
                                                                                            data.box_dm.visible ?  <BoxDm index={index}/> : <></>
                                                                                        }
                                                                                    </td>
                                                                                    <td><input type="number" className='form-control text-right' onChange={(e) => ShippingChange(index, "item_box", e.target.value)} value={data.item_box}/></td>
                                                                                    <td className='d-iflex'>
                                                                                        <input type="number" className='form-control text-right w-75' onChange={(e) => ShippingChange(index, "item_dimension", e.target.value)} value={data.item_dimension}/> 
                                                                                        <img className='calc-icon calc-input' src={calculator} onClick={() => toggleItemDm(index)}/>
                                                                                        {
                                                                                            data.item_dm.visible ? <ItemDm index={index}/> : <></>
                                                                                        }
                                                                                    </td>
                                                                                    <td><input type="number" className='form-control text-right' value={data.item_cost}/></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan={2}>Total</td>
                                                                        <td className='text-right'>{NumberFormat(total_Qty, 0)}</td>
                                                                        <td className='text-right'>{NumberFormat(inputs.total_boxes, 0)}</td>
                                                                        <td colSpan={5}></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                            :
                                                            <></>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                <div className="row row justify-content-end mt-3">
                                                    <div className="mr-2">
                                                        <div className="btn-group btn-dpd">
                                                            <button className="btn btn-primary  btn-main" onClick={() => saveShipping('saved','update')} type="button">Save</button>
                                                            <button data-toggle="dropdown" className="btn btn-primary dropdown-toggle btn-carret" type="button"><span className="caret"></span>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dpd-menu"  onClick={() => saveShipping('draft','update')}>Draft</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Show>
            </div>
            
        </div>
    );
}

const mapStateToProps = state => ({
    shipping : state.shipping
})

export default connect(mapStateToProps, {searchPO, PoChange, popUpToggleClick, InputChange, ShippingChange, toggleDm, toggleItemDm, addOtherInput, otherInput, saveShipping, getShipping})(ShippingCostsEdit);