import http from '../../../Services/api.laravel.http';

export const QuotationTemplateSettingService = {
	getQUTemplateSettings: () => http.get('/admin/quotation-template/settings/quotation-template'),
	storeQUTS: (formParams) => http.post('/admin/quotation-template/settings/quotation-template', formParams),
   	setAsDefault: (formParams) => http.post('/admin/quotation-template/settings/default', formParams),
  	quTemplateSettingArchive: (formParams) => http.post('/admin/quotation-template/settings/archive', formParams),
   	getSingleQUTS: (id) => http.get(`/admin/quotation-template/settings/${id}`),
   	updateQUTS: (formParams) => http.post(`/admin/quotation-template/settings/update`, formParams),
}
