import * as TYPE from '../types';

// state
const initialState = {
    config : {
        host : '',
        port : '',
        protocol : '',
        username : '',
        password : '',
        test_email : '',
        require_password : false
    },
    password_toggle : 'password',
    show_test : false,
    btn_lbl : {
        submit : 'Save Settings',
        test : 'Send Test Email',
        loading : false,
        _class : 'btn-blue',
        icon : 'ft ft-navigation'
    },
    test_result : {
        status  : true,
        show    : false,
        message : ''
    },
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    logs: [],
    single_log: null,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    log_modal: false,
    mini_loading: false,
    loading: false,
    email_config : [],
    sending_email : false,
    edit_url : {
        po : ''
    },
    print_modal : {
        po : false
    }
}

const emailsettingrecuder = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.EMAIL_SETTING_DATA:
            return {
                ...state,
                config: action.payload
            }
        case TYPE.PASSWORD_TOGGLE:
            return{
                ...state,
                password_toggle : action.payload
            }
        case TYPE.EMAIL_SETTING_TOGGLE_TEST:
            return{
                ...state,
                show_test : action.payload
            }
        case TYPE.BTN_SUB:
            return{
                ...state,
                btn_lbl : action.payload
            }
        case TYPE.EMAIL_SETTING_TEST_RESULT:
            return{
                ...state,
                test_result : action.payload
            }
        case TYPE.EMAIL_SETTING_LOGS:
            return {
                ...state,
                logs: action.payload.logs,
                pagination: action.payload.pagination
            }
        case TYPE.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPE.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPE.SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload
            }
        case TYPE.SET_MODAL: 
            return {
                ...state,
                [action.payload.modal]: action.payload.status
            }
        case TYPE.EMAIL_SETTING_CLEAR_LOG:
            return {
                ...state,
                single_log: null
            }
        case TYPE.EMAIL_SETTING_SINGLE_LOG:
            return {
                ...state,
                single_log: action.payload
            }
        case TYPE.EMAIL_SETTING_DATA:
            return{
                ...state,
                email_config : action.payload
            }
        case TYPE.SEND_EMAIL:
            return{
                ...state,
                sending_email : action.payload
            }
        default:
            return state;
            break;
    }
}

export default emailsettingrecuder;

