import http from '../../../Services/api.laravel.http';

export const CollectionReceiptService = {
    getSingleCollectionReceipt: (id) =>
        http.get('/admin/collection-receipt/' + id),
    paginateWithSearchCollectionReceipt: (formParams, status) =>
        http.post('/admin/collection-receipt/search/' + status, formParams),
    collectionReceiptPost: (formParams) =>
        http.post('/admin/collection-receipt', formParams),
    updateSingleCollectionReceipt: (formParams, id) =>
        http.put('/admin/collection-receipt/' + id, formParams),
    paymentCollectionReceipt: (formParams) =>
        http.post('/admin/collection-receipt/payment', formParams),
    ArchiveCollectionReceipt: (formParams) =>
        http.post('/admin/collection-receipt/archives/list', formParams),
};
