import React, { Fragment, useEffect, useState } from 'react';
import './shipping.css'
import { connect } from 'react-redux';
import { freight, popUpToggleClick } from '../../redux/actions/shipping/shipping.action';

const ShippingPop = ({shipping : {popToggle, inputs}, freight, popUpToggleClick}) => {
    const currency = [
        'USD',
        'PHP'
    ]
    const [cost, setCost] = useState(inputs.cost)
    const [rate, setRate] = useState(inputs.rate)
    const [total, setTotal] = useState(inputs.freight_cost)
    const ClickPopOk = () => {
        freight(cost, rate)
        computeTotal()
        popUpToggleClick()
        
    }
    useEffect(() => {
        
    }, [popToggle])

    const computeTotal = () =>{
        setTimeout(function(){
            try{
                let val = cost * rate
                setTotal(val)
            }
            catch(err)
            {
                setTotal(0)
            }
        }, 100)
        
    }
    const costChange = (val) => {
        setCost(val)
        computeTotal()
    }

    const rateChange = (val) => {
        setRate(val)
        computeTotal()
    }
    
    return (
        popToggle ? 
        <div className='shipping-pop'>
            <div className='shipping-header'>
                <a onClick={()=> ClickPopOk ()}><i className='ft ft-x'></i></a>
            </div>
            <div className='row mb-1'>
                <label className='col-2'>Cost</label>
                <div className='col-3'>
                    <select className='form-control'>
                        {
                            currency.map(data => {
                                return (
                                    <option value={data}>{data}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='col-7'>
                    <input type="number" className='form-control text-right' placeholder='0' value={cost} onChange={e => costChange(e.target.value)}/>
                </div>
            </div>
            <div className='row mb-1'>
                <label className='col-2'>Rate</label>
                <div className='col-3'></div>
                <div className='col-7'>
                    <input type="number" className='form-control text-right' placeholder='0' value={rate} onChange={e => rateChange(e.target.value)}/>
                </div>
            </div>
            <div className='row mb-1'>
                <label className='col-2'>Total</label>
                <div className='col-3'></div>
                <div className='col-7'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">₱</span>
                        </div>
                        <input type="number" className='form-control text-right' placeholder='0' value={total} />
                    </div>
                     
                </div>
            </div>
            <div className='row mb-1'>
                <div className='col-12 text-right'>
                    <button className='btn btn-primary' onClick={()=> ClickPopOk ()}>Ok</button>
                </div>
            </div>
        </div>
        : 
        <></>
    )
}


const mapStateToProps = state => ({
    shipping : state.shipping
})

export default connect(mapStateToProps, {freight, popUpToggleClick})(ShippingPop);