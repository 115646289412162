import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../../layouts/Spinner";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import UserLogViewModal from './UserLog.modal.view';
import { getUserLogs, setModal, handleSearchInputMUI, sortTableByColumn } from '../../../../redux/actions/user_log/user_log.actions';
import { connect } from 'react-redux';
import { formattedDate } from "../../../../Utils/Common";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {  Show, useAccess } from "react-redux-permission";
import ReactTooltip from 'react-tooltip';
import {Link} from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { updateMUISetting } from "../../../../redux/actions/helper/helper.actions";
import moment from 'moment';

function UserLog ({ user_log: { user_logs, loading, search, pagination, user_log_modal, sort_order_direction, sort_order_name }, setModal, handleSearchInputMUI,
     sortTableByColumn, getUserLogs, updateMUISetting }){
    
    useEffect(() => {
        getUserLogs();
    }, [])

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
      if (debouncedSearch) {
        if(oldDebounced !== debouncedSearch[0]){
          if(debouncedSearch[0].length !== 1){
              setOldDebounced(debouncedSearch[0]);
              getUserLogs(1);
          }
        }
      } 
    },[debouncedSearch]);
    // END DEVOUNCE SEARCH


    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles(
        theme => ({
            centeredHeader: {
                '& span': {
                    display:'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    
                },
            },
        })
    );
    const classes = useStyles();


    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {},
                paper: {
                boxShadow: "none",
                }
            },
            MUIDataTableBodyRow: {
                root: {
                '&:nth-child(odd)': { 
                    backgroundColor: "rgba(0,0,0,.05)"
                }
                }
            },
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "#bfcfe4 !important"
                    }
                }
            },
            MUIDataTableBodyCell: {}
        }
    })
    

    const columns = [
        {name: '_id', label: '', options: { display: false, viewColumns: false, filter: false, print: false, download: false } }, 
        {name: 'created_at', label: 'Date', options: { display: true,  
            customBodyRender: (value) => {
               // Define the date formats
        const formats = [
            'YYYY-MM-DDTHH:mm:ss.SSSZ',
            'YYYY-MM-DD hh:mma',
          ];
          
          // Try to parse the date using the defined formats
          const parsedDate = moment(value, formats, true);
          
          // Check if the date is valid
          if (parsedDate.isValid()) {
            return parsedDate.format('YYYY-MM-DD HH:mm:ss');
          } else {
            return 'Invalid Date';
          }
        }
        }},
        {name: 'user_details', label: 'User', options: { display: true, filter: false, print: false, download: false,
                customBodyRender: (value) => {
                    return (
                        <span> <Link to={ "/settings/users/account/edit/" + value._id }>{value ? value.admin_name : '' }</Link> (<Link to={ "/settings/users/role/edit/" + value._id }>{value ? value.user_role.role_title : '' }</Link>)</span>
                    )
                },
                setCellProps : value => {
                    return {className : "cursor-pointer"}
                }
            }
        },
        {name: 'log_event', label: 'Action', options: { display: true,
                customBodyRender: (value) => {
                    return (
                        <span class={ 'lbl-status lbl-' + value}> {value.toUpperCase()} </span>
                    )
                }
            } 
        }, 
        {name: 'logData', label: 'Type', options: { display: true,
                customBodyRender: (value) => {
                    return (
                         <span>{value.type}</span>
                    )
                }
            } 
        }, 
        {name: 'logData', label: 'Logs', options: { display: true, filter: false, print: false, download: false,
                customBodyRender: (value) => {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: value.message }} />
                    )
                }
            }  
        }
    ];


    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10,20,50,100],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        elevation: 2,
        selectableRows: false,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction
        },
        downloadOptions: {
            filename: `user-logs_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? <Spinner/> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getUserLogs(null, numberOfRows)
            updateMUISetting('rows', 'user_log', numberOfRows)
        },
        onTableChange: (action, tableState) => {

            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getUserLogs(tableState.page + 1, pagination.itemsCountPerPage);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search': 
                //  handleSearchInputMUI(tableState.searchText);
                    break;
                case 'viewColumnsChange':
                    updateMUISetting('columns', 'user_log', null, tableState.columns);
                break;
                default:
                console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
        },
        onRowClick: (rowData, rowMeta) => {
            setModal('user_log_modal', rowData[0])
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (    
                <div className="custom-search">
                   <i className="ft ft-search"></i> <input type="text" name="search" onChange={handleSearchInputMUI} className="custom-search-input"/>
                </div>
            )
        }
    };

    // if(!canDelete)
    // {
    //   options.selectableRows = false;
    // }

    const _columns = updateMUISetting('view-columns', 'user_log', null, columns);

    return (
        <section id="basic-examples">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <ReactTooltip  effect="solid" event="click"/>
                            <h4 className="card-title">Logs <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                            <a className="heading-elements-toggle"> <i className="la la-ellipsis-v font-medium-3"></i></a>
                            <div className="heading-elements">
                                <ul className="list-inline mb-0"></ul>
                            </div>
                        </div>
                        <div className="card-content collapse show">
                            <div className="card-body">
                                <section className="row">
                                    <div className="col-sm-12">
                                        <Show when="inv.setting.system.logs">
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <MUIDataTable data={user_logs} columns={_columns} options={options}/>
                                            </MuiThemeProvider> 
                                        </Show>
                                    </div>
                                </section>   
                                <UserLogViewModal show={user_log_modal} onHide={() => setModal('user_log_modal', null, false)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    )
}


const mapStateToProps = state => ({
    user_log: state.user_log
})

export default connect(mapStateToProps, { getUserLogs, handleSearchInputMUI, sortTableByColumn, setModal, updateMUISetting })(UserLog);