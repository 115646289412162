import React, { Fragment } from "react";
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect } from 'react-redux';
import { defaultFormat } from '../../Utils/DateFormat';
import {stockClass } from '../../Utils/Common';


function SalesOrderView({
	sales_order: {
		single_sales_order, customers, terms_conditions, isSearchable, itemSelected, items,
		isDiscount, default_product_value , count_items, isOther, inv_templates, receipt_type_options,
		type_payment_options, default_customer_value, si_default_template
	}}) {

    // console.log("single_sales_order", single_sales_order);
    let percent_value = [{value: "", label: 'None', 'symbol' : ''},{value: "percent", label: 'Percent', 'symbol' : '%'}, {value: "amount", label: 'Fixed Amount', 'symbol' : ''}]

    const status_lbl = <span className={`lbl-status mr-1 lbl-${single_sales_order.status}`}>{single_sales_order.status}</span>;
    // const payment_mode = single_sales_order.payment_type && single_sales_order.payment_type.value != '' ? <span className={`lbl-status mr-1 lbl-payment-type lbl-${single_sales_order.payment_type.label}`}>{single_sales_order.payment_type.label}</span> : '';
	// const payment = single_sales_order.payment_status !== '' && single_sales_order.payment_status !== undefined ? <span className={`lbl-status mr-1 payment_status lbl-${single_sales_order.payment_status}`}>{single_sales_order.payment_status}</span> : '';
	// const delivery = single_sales_order.delivery_status !== '' && single_sales_order.delivery_status !== undefined ? <span className={`lbl-status mr-1 delivery_status lbl-${single_sales_order.delivery_status}`}>{single_sales_order.delivery_status}</span> : '';

    return (
        <form>
            <section>
                <div className="row f-regular">
                    <div className="col-12 mb-2">
                        {status_lbl}
                        {/* {payment}
                        {delivery} */}
                        {/* {payment_mode} */}
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            {
                                single_sales_order.is_manual ?
                                <Fragment>
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-4">
                                        <label className="mb-2"><i className="ft ft-check-square"></i> Manually Input Reference No.</label>
                                    </div>
                                </Fragment>
                                : null
                            }
                            <div className="col-sm-4">
                                <label>Template <span className='text-danger'>*</span></label>
                                <div className="form-group row">
                                    <div className="col-md-10 col-10 pr-0">
                                        <div className="form-control f-regular">{si_default_template.label || ""}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                            { single_sales_order.is_manual ? (
                           <div className="col-sm-4">
                                <label>Reference No. <span className='text-danger'>*</span></label>
                                <div className="form-group row">
                                <div className="col-md-10 col-10 pr-0">
                                   <div className="form-control f-regular">{single_sales_order.quotation_no}</div>
                                </div>
                                </div>
                                </div>
                                ) :  ( <div className="col-sm-4">
                                <label>Quotation No. <span className='text-danger'>*</span></label>
                                <div className="form-group row">
                                <div className="col-md-10 col-10 pr-0">
                                    <div className="form-control f-regular">{single_sales_order.quotation_no}</div>
                                </div>
                            </div>
                            </div>)}
                           
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <label>Customer<span className='text-danger'>*</span></label>
                                <div className="form-group row">
                                    <div className="col-md-10 col-10 pr-0">
                                        <div className="form-control f-regular">{default_customer_value.label}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-sm-4">
                                <label>Order Date</label>
                                <div className="form-group row">
                                    <div className="col-md-10 col-10 pr-0">
                                        <div className="date-picker-100">
                                            <div className="form-control f-regular">{defaultFormat(single_sales_order.sales_order_date)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            single_sales_order.customer &&
                            <Fragment>
                                <table className="table table-hover table-striped table-bordered f-regular">
                                    <thead>
                                        <tr>
                                            <th className="text-left">SKU</th>
                                            <th className="text-center">Product</th>
                                            <th className="text-center">Qty</th>
                                            <th className="text-center">Unit</th>
                                            <th className="text-center">SRP</th>
                                            <th className="text-center">Unit/Price</th>
                                            <th className="text-center">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            single_sales_order.sales_order_items.map((item, index) => (
                                                // <tr key={index} className={stockClass(item.status_code)}>
                                                // <tr key={index}>
                                                <Fragment key={index}> {/* This is the key part */}
                                                <tr className={stockClass(item.status_code)}>
                                                    <td className="table-data__sku">{item.sku}</td>
                                                    <td className='min-wdth-300 max-wdth-300'>
                                                        {item.product_name} <br />{" "}
                                                        <div className='text-secondary f-12 text-dark'>
                                                        {  item.barcode}
                                                        </div>
                                                        <div className='text-secondary f-12 text-dark'>
                                                        {  item.description}
                                                        </div>
                                                        <div className='text-secondary f-12 text-dark'>
                                                        {item.brand}
                                                        </div>
                                                    </td>
                                                    <td className="min-wdth-75 max-wdth-75 text-center">{NumberFormat(item.qty)}</td>
                                                    <td className="min-wdth-75 max-wdth-75 text-center">{item.product_unit_name}</td>
                                                    <td className="min-wdth-75 max-wdth-75" align="right">{NumberFormat(item.srp.toFixed(2))}</td>
                                                    <td className="min-wdth-75 max-wdth-75" align="right">{item.unit_price ? NumberFormat(item.unit_price.toFixed(2)) : NumberFormat(item.srp.toFixed(2))}</td>
                                                    <td className="min-wdth-75 max-wdth-75" align="right">{item.amount ? NumberFormat(item.amount.toFixed(2)) : NumberFormat(item.srp.toFixed(2))}</td>
                                                </tr>

                                                {item.discount_type && item.discount_type.value !== 'none' &&
                                                    <tr>
                                                        <td colSpan={1}></td>
                                                        <td className="d-flex justify-content-left ">
                                                        
                                                        
                                                        <div className="w-50">
                                                            <span>Discount: </span>
                                                            <span className="product-discount">
                                                                {item.discount}
                                                                {item.discount_type.label === 'Percent' ? ' %' : ''}
                                                            </span>
                                                        </div>
                                                                                    
                                                        <div className="w-50">
                                                            <span>Discount type: </span>
                                                            <span className="product-discount">
                                                                {item.discount_type.label}
                                                            </span>
                                                        </div>

                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='min-wdth-100 max-wdth-100 text-right text-danger'>
                                                        - {NumberFormat(Number(item.discount_amount).toFixed(2))}
                                                        </td>
                                                    </tr>
                                                    }
                                                </Fragment>
                                            ))
                                        }
                                        <tr>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                            <td className="p-2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td><div align="right">Sub Total</div></td>
                                            <td align="right">{NumberFormat(single_sales_order.sub_total.toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td><div align="right">Discount</div></td>
                                            <td align='right'>
                                            {single_sales_order.discount_type.value === 'percent' ? `${single_sales_order.discount}  %` : NumberFormat(single_sales_order.discount.toFixed(2))}  
                                            </td>
                                            {/* <td align="right">{NumberFormat(single_sales_order.discount.toFixed(2))} { percent_value[single_sales_order.discount_type].symbol}</td> */}
                                            {/* <td align="right">{single_sales_order.discount_type === 'percent' ? `${single_sales_order.discount}  %` : NumberFormat(single_sales_order.discount)}</td>*/}
                                        </tr> 
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td><div align="right">VAT</div></td>
                                            {/* <td><div align="right">VAT { single_sales_order.tax == 0  ?  null : single_sales_order.tax_type.value }</div></td> */}
                                            <td align="right">{single_sales_order.tax} %</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td><div align="right">Delivery Fee</div></td>
                                            <td width="12%">
                                                {<div align="right">{NumberFormat(single_sales_order.other.toFixed(2))} {" "}</div>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td><div align="right"><strong>Total</strong></div></td>
                                            {/* {/* <td align="right">{NumberFormat(single_sales_order.grand_total.toFixed(2))}</td> */}
                                            <td align="right">{NumberFormat(single_sales_order.total.toFixed(2))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Fragment>
                        }

                        <div className="col-12 mb-2"></div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Remarks:</label>
                                    <div className="form-control f-regular">{single_sales_order.remarks || 'None'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Payment Mode: </label>
                                    <div className="form-control f-regular">{single_sales_order.payment_mode.label}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Payment Terms: </label>
                                    <div className="form-control f-regular">{single_sales_order.payment_terms.label}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label>Receipt Type</label>
                                    <div className="form-control f-regular">{single_sales_order.receipt_type.label}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

const mapStateToProps = state => ({
	sales_order: state.sales_order
})

export default connect(mapStateToProps, {})(SalesOrderView);

