import React, { Fragment } from "react";
import { NumberFormat } from "../../Services/_numberformat.service";
import { connect } from "react-redux";
import { defaultFormat } from "./../../Utils/DateFormat";
import { numberFormat, stockClass } from "../../Utils/Common";

function QuotationView({
  quotation: {
    single_quotation,
    itemSelected,
    isDiscount,
    count_items,
    isOther,
    edit_attr,
    default_customer_value,
    qu_default_template,
  },
}) {
  let percent_value = [
    { value: "none", label: "None", symbol: "" },
    { value: "percent", label: "Percent", symbol: "%" },
    {  value: "amount", label: "Fixed Amount", symbol: "" },
  ];

  const status_lbl = (
    <span className={`lbl-status mr-1 lbl-${single_quotation.status}`}>
      {single_quotation.status}
    </span>
  );
  const payment_status =
    single_quotation.payment_mode &&
    single_quotation.payment_mode.value != "" ? (
      <span
        className={`lbl-status mr-1 lbl-payment-type lbl-${single_quotation.payment_mode.label}`}
      >
        {single_quotation.payment_mode.label}
      </span>
    ) : (
      ""
    );

  return (
    <form>
      <section>
        <div className='row'>
          <div className='col-md-12 mb-5'>
            <div className='row'>
              <div className='col-12 mb-2'>
                {status_lbl}
                {payment_status}
              </div>
              <div className='col-sm-4'>
                <label>
                  Template <span className='text-danger'>*</span>
                </label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    <div className='form-control f-regular'>
                      {qu_default_template.label}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-4'></div>
              <div className='col-sm-4'>
                <label>
                  Quotation No. <span className='text-danger'>*</span>
                </label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    <div className='form-control f-regular'>
                      {single_quotation.quotation_no}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <label>
                  Customer<span className='text-danger'>*</span>
                </label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    <div className='form-control f-regular'>
                      {default_customer_value.label}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'></div>
              <div className='col-sm-4'>
                <label>Invoice Date</label>
                <div className='form-group row'>
                  <div className='col-md-10 col-10 pr-0'>
                    <div className='date-picker-100'>
                      <div className='form-control f-regular'>
                        {defaultFormat(single_quotation.sales_invoice_date)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {single_quotation.customer && (
              <Fragment>
                <table className='table table-hover table-striped table-bordered f-regular'>
                  <thead>
                    <tr>
                      <th className='text-center'>
                        SKU
                      </th>
                      <th className='text-center'>
                        Product
                      </th>
                      <th className='text-center'>
                        Qty
                      </th>
                      <th className=' text-center'>
                        Unit
                      </th>
                      <th className=' text-center'>
                        Unit/Price
                      </th>
                      <th className=' text-center'>
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {single_quotation.quotation_items.map((item, index) => (
                      //  <tr key={index} className={stockClass(item.status_code)}>
                       <Fragment key={index}> {/* This is the key part */}
                       <tr className={stockClass(item.status_code)}> 
                              
                       <td className='min-wdth-100 max-wdth-100'>
                                {item.sku}
                              </td>
                        <td className='min-wdth-300 max-wdth-300'>
                                {item.product_name} <br />{" "}
                                <div className='text-secondary f-10'>
                                  {item.product != null &&
                                    item.description}
                                </div>
                                <div className='text-secondary f-10'>
                                  {item.brand && item.brand.name}
                                </div>
                              </td>
                        <td className='min-wdth-100 max-wdth-100 text-center'>
                          {item.qty}
                        </td>
                        <td className='min-wdth-75 max-wdth-75 text-center'>
                          {item.product_unit_name}
                        </td>
                        <td className='min-wdth-150 max-wdth-150' align='right'>
                          {NumberFormat(item.srp.toFixed(2))}
                        </td>
                        <td className='min-wdth-100 max-wdth-100' align='right'>
                          {NumberFormat(item.amount.toFixed(2))}
                        </td>
                      </tr>

                        {item.discount_type && item.discount_type.value !== 'none' &&
                        <tr>
                          <td colSpan={1}></td>
                          <td className="d-flex justify-content-left ">
                          
                            
                          <div className="w-50">
                              <span>Discount: </span>
                              <span className="product-discount">
                                  {item.discount}
                                  {item.discount_type.label === 'Percent' ? ' %' : ''}
                              </span>
                          </div>
                                                      
                          <div className="w-50">
                              <span>Discount type: </span>
                              <span className="product-discount">
                                  {item.discount_type.label}
                              </span>
                          </div>

                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className='min-wdth-100 max-wdth-100 text-right text-danger'>
                            - {NumberFormat(Number(item.discount_amount).toFixed(2))}
                          </td>
                        </tr>
                        }
                        </Fragment>
                    ))}
                    <tr>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                    </tr>
                    <tr>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                      <td className='p-2'>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan={4}></td>
                      <td>
                        <div align='right'>Sub Total</div>
                      </td>
                      <td align='right'>
                        {NumberFormat(single_quotation.sub_total.toFixed(2))}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}></td>
                      <td>
                        <div align='right'>Discount</div>
                      </td>
                      <td align='right'>
                        {single_quotation.discount_type.value === 'percent' 
                            ? `${parseInt(single_quotation.discount, 10)} %` 
                            : NumberFormat(Number(single_quotation.discount).toFixed(2))
                        }
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}></td>
                      <td>
                        <div align='right'>
                          VAT{" "}
                          {single_quotation.tax == 0
                            ? null
                            : single_quotation.tax_type.value}
                        </div>
                      </td>
                      <td align='right'>{single_quotation.tax} %</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td align='right'>Total</td>
                      <td align='center'>{count_items}</td>
                      <td></td>
                      <td>
                        <div align='right'>
                          <strong>Total</strong>
                        </div>
                      </td>
                      <td align='right'>
                        {NumberFormat(single_quotation.total.toFixed(2))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            )}

            <div className='col-12 mb-2'></div>

            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor=''>Remarks:</label>
                  <div className='form-control f-regular'>
                    {single_quotation.remarks || "None"}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-md-4'>
                <label>Payment Mode: </label>
                <div className='form-control f-regular'>
                  {single_quotation.payment_mode.label}
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-md-4'>
                <label>Payment Terms: </label>
                <div className='form-control f-regular'>
                  {single_quotation.payment_terms.label}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
}

const mapStateToProps = (state) => ({
  quotation: state.quotation,
});

export default connect(mapStateToProps, {})(QuotationView);
