import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setModal,reSendEmail} from '../../../redux/actions/emailsettings/emailsetting.actions';
import { MiniSpinner } from '../../layouts/Spinner';

function EmailSingleLogModal({emailsetting: { single_log, log_modal, mini_loading, sending_email }, setModal, reSendEmail }) {


    const emailType = (type) => {
        switch (type) {
          case 'po':
            return 'Purchase Order';
          case 'si':
            return 'Sales Invoice';
          case 'qn':
            return 'Quotation';
          case 'receiving':
            return 'Item Receipt';
          case 'dr':
            return 'Delivery Receipt'
          default:
            return ''
        }
    }
	return (
		<Modal centered show={log_modal} onHide={() => setModal('log_modal', false)} size="md" animation={true}>
			<Modal.Header closeButton> <Modal.Title>Email Log:</Modal.Title></Modal.Header>
		    <Modal.Body>
                {
                    mini_loading ? <MiniSpinner/> : single_log !== null && 
					<>
						<div className="mb-1 f-regular"><strong>Type: </strong>{emailType(single_log.email_type)}</div>
						<div className="mb-1 f-regular"><strong>Reference No.:</strong> {single_log.reference_no}</div>
						<div className="mb-1 f-regular"><strong>Sender:</strong> {single_log.email_sender}</div>
						<div className="mb-1 f-regular"><strong>Receiver: </strong>{single_log.email_receiver}</div>
						<div className="mb-1 f-regular"><strong>Receiver Name: </strong>{single_log.receiver_name}</div>
						<div className="mb-1 f-20"><strong>Status:</strong> { single_log.email_sent === 1 ? <span className="text-success">SUCCESS</span> : <span className="text-danger">FAILED</span>}</div>
						{
							single_log.email_sent === 0 && <div className="alert alert-danger">{single_log.email_errror[0]}</div>
						}
					</>
                }
		    </Modal.Body>
        {
          !mini_loading &&
            <Modal.Footer>
              <button className="btn btn-grey f-regular btn-md" onClick={() => setModal('log_modal', false)}>Cancel</button>
              {
                single_log != null &&
                single_log.email_sent == 0 &&
                <button className="btn-primary btn f-regular btn-save btn-md" onClick={(e) => reSendEmail(e, single_log._id)}>{sending_email ? "Sending..." :"Resend"}</button>
              }
              
            </Modal.Footer>
        }
        
		</Modal>
	  )
}

const mapStateToProps = state => ({
	emailsetting: state.emailsetting
})

export default connect(mapStateToProps, { setModal, reSendEmail })(EmailSingleLogModal);