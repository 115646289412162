import React, {Fragment, useEffect, useMemo } from 'react';
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { setPage, getInvoices, handleSearchInput, handleChangeStatusView } from '../../redux/actions/invoice/invoice.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import InvoicePrintModal from './modals/Invoice.modal.print';
import InvoiceTable from './Invoice.table';
import {  Show, useAccess } from "react-redux-permission";
import { Link } from 'react-router-dom';

function Invoice ({ invoice: { search, loading, create_url, edit_url, edit_page, pagination, invoices }, 
        setPage, handleSearchInput, getInvoices, handleChangeStatusView }) {

    // useMemo(() => {
    //     getInvoices();
    // },[])

    if(edit_page) return <Redirect to={edit_url}/>

    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Sales </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item "><a href="/sales/invoices" className="text-white">Invoices</a></li>
                                </ol>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div className='content-body margin-top-5'>
                <section id='basic-examples'>
                <div className='row'>
                    <div className='col-sm-12'>
                    <div id='what-is' className=''>
                        <div className='card-content collapse show'>
                        <div className='card-body no-padding-top no-padding-left-right'>
                            <section className='row'>
                            <div className='col-sm-12'>
                                <ul className='nav nav-tabs f-regular'>
                                {/* old params 'all', '1', '2'. 're-stock' */}
                                <li className=''>
                                    <a
                                    onClick={() => handleChangeStatusView("all")}
                                    className='nav-link active'
                                    id='base-tab1'
                                    data-toggle='tab'
                                    aria-controls='tab1'
                                    href='#tabs'
                                    aria-expanded='true'
                                    >
                                    Invoice list
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() => handleChangeStatusView("draft")}
                                    className='nav-link'
                                    id='base-tab2'
                                    data-toggle='tab'
                                    aria-controls='tab2'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Draft
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() =>
                                        handleChangeStatusView("saved")
                                    }
                                    className='nav-link'
                                    id='base-tab3'
                                    data-toggle='tab'
                                    aria-controls='tab3'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                    Saved
                                    </a>
                                </li>
                                <li className=''>
                                    <a
                                    onClick={() =>
                                        handleChangeStatusView("cancelled")
                                    }
                                    className='nav-link'
                                    id='base-tab3'
                                    data-toggle='tab'
                                    aria-controls='tab3'
                                    href='#tabs'
                                    aria-expanded='false'
                                    >
                                        Cancelled
                                    </a>
                                </li>
                                </ul>
                                <div className='tab-content px-1 pt-1 card-box-top card'>
                                <div
                                    role='tabpanel'
                                    className='tab-pane active table-responsive '
                                    id='tabs'
                                    aria-expanded='true'
                                    aria-labelledby='base-tab1'
                                >
                                    <Show when="inv.sales.invoices.view">
                                    <div className="content-body margin-top-5">
                                        <section id="basic-examples">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">List of Invoices <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a>
                                                                <Show when="inv.sales.invoices.create"><Link to={create_url} className="btn btn-primary pull-right btn-sm"><i className="ft ft-plus"></i> Create New</Link></Show>
                                                            </h4>
                                                        </div>
                                                        <div className="card-content collapse show">
                                                            <div className="card-body">
                                                                <Show when="inv.sales.invoices.view">
                                                                    <section className="row f-regular">
                                                                        <div className="col-sm-12">
                                                                            <InvoiceTable/>
                                                                        </div>
                                                                    </section>   
                                                                </Show>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </Show>
                                </div>
                                </div>
                            </div>
                            </section>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
            </div>
        </div>
                
    );
}

const mapStateToProps = state => ({
    invoice: state.invoice
});

export default connect(mapStateToProps, { setPage, getInvoices, handleSearchInput, handleChangeStatusView })(Invoice);