import React, { Fragment, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';
import { Show, useAccess } from "react-redux-permission";
import Navbar from "../components/layouts/Navbar";
import { connect } from 'react-redux';
import { setLoading, getUserDetails, validateToken } from '../redux/actions/auth/auth.actions';
import Spinner from "../components/layouts/Spinner";
// handle the private routes
function PrivateRoute({ component: Component, auth: { auth_loading,user,access,role,permissions }, getUserDetails,validateToken,  permission, ...rest }) {
	
	const { hasPermission, definePermission, isLoaded } = useAccess();

	

	useEffect(() => {
        getUserDetails();
    }, []);

	useEffect(() => {
		definePermission(permissions);
	}, [definePermission, permissions])


    if(auth_loading)
	{
		return (
			<Fragment>
			  <Spinner />
		    </Fragment> 
		)
	}

	// const x = hasPermission(permission)
	// console.log(x)

	// definePermission(permissions);
	// console.log(permission);

	const NoAccessDiv = () =>{
		return(
			
			<Fragment>
			{/* <Navbar /> */}
			{
				// console.log("permission", permission);
				// console.log("getToken",getToken())
				validateToken()
			}
		      <div className="app-content content">
		        <div className="content-wrapper">
		            <div className="content-wrapper-before"></div>
		            <div className="content-header row">
		            </div>
		            <div className="content-body">
		                <section className="flexbox-container">
		                    <div className="col-12 d-flex align-items-center justify-content-center">
		                        <div className="col-lg-6 col-md-6 col-10 box-shadow-2 p-0">
		                            <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
		                                <div className="card-header border-0">
		                                    <div className="font-large-1  text-center text-danger">
		                                        You have no access to this page. Please contact the administrator.
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                    </div>
		                </section>
		            </div>
		        </div>
		      </div>
			  
		     </Fragment> 
			 
		    )
	}

	const login = () => {
		if(!getToken())
		{
			window.location.href = "/login"
		}
		else
		{
			validateToken();
		}

	}
	
	// console.log(!hasPermission(permission))

	

	if (typeof(permission) !== 'undefined' && permission != null) {
		if (!permissions.includes(permission) && validateToken()) {
			window.location.href = "/settings/app"
		}
		return (
	  	<Show when={permission} fallback={login()}>
	  	{/* <Show when={permission} fallback={noAccessDiv(permission)}> */}
		    <Route {...rest} render={(props) => getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />
		</Show>    
	  )
	}
	else
	{
		return(
			<Route {...rest} render={(props) => getToken() ? <Fragment><Navbar /><Component {...props} /></Fragment> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />
		)
	}
  
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { getUserDetails, validateToken})(PrivateRoute);