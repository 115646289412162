import React, { Fragment, useEffect } from 'react';
import Spinner from '../layouts/Spinner';
import { getSingleSupplier, handleChangeTab } from '../../redux/actions/supplier/supplier.actions';
import { connect } from 'react-redux';
import PurchaseOrderTable from '../purchase_order/PurchaseOrder.table';
import SupplierDateFilterGlobal from './Supplier.datefilter.global';
import { useParams, Link } from 'react-router-dom'
import ProductList from '../product/Product.list';

function SupplierHistory({ supplier:{ single_supplier, edit_url, payment_details, main_url, mini_loading }, getSingleSupplier, handleChangeTab }) {
                    
    const { id } = useParams();

    const pageOrigin = {
        supplier_id: id,
        origin: 'supplier_history'
    }

    useEffect(() => {
        getSingleSupplier(id, 'history');
    },[]);

    return (
        <div className="app-content content">
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">Purchases</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item"><a href={main_url} className="text-white">Suppliers</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">History</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0 ">
                                        <Link to={main_url}><i className="ft ft-arrow-left"></i> Back</Link>

                                        <div className="float-right">
                                            <Link to={`${edit_url}/${id}`} className="btn btn-sm btn-grey"><i className="ft ft-edit"></i></Link>
                                        </div>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                            {
                                                mini_loading ? <Spinner/> : 
                                                <>
                                                    <section className="row">
                                                        <div className="col-sm-4">
                                                            <div className="mb-1"><span><strong>Name: </strong></span>{single_supplier.name}</div>
                                                            <div className="mb-1"><span><strong>Business Name: </strong></span>{single_supplier.business_name}</div>
                                                            <div className="mb-1"><span><strong>Street 1: </strong></span>{single_supplier.street1 || single_supplier.address}</div>
                                                            <div className="mb-1"><span><strong>Street 2: </strong></span>{single_supplier.street2}</div>
                                                            <div className="mb-1"><span><strong>City: </strong></span>{single_supplier.city}</div>
                                                            <div className="mb-1"><span><strong>State/Region: </strong></span>{single_supplier.state}</div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="mb-1"><span><strong>Country: </strong></span>{single_supplier.country ? single_supplier.country.label : ''}</div>
                                                            <div className="mb-1"><span><strong>Fax: </strong></span>{single_supplier.fax}</div>
                                                            <div className="mb-1"><span><strong>Email: </strong></span>{single_supplier.email}</div>
                                                            <div className="mb-1"><span><strong>Landline: </strong></span>{single_supplier.landline}</div>
                                                            <div className="mb-1"><span><strong>Mobile: </strong></span>{single_supplier.mobile}</div>
                                                            <div className="mb-1"><span><strong>Contact Person: </strong></span>{single_supplier.contact_person}</div>
                                                        </div>
                                                    </section>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <h4 className="card-title">Account Details <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                                        <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                                        <div className="heading-elements">
                                            <ul className="list-inline mb-0"></ul>
                                        </div>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                        <div className="row equal">
                                                {payment_details && payment_details.map((payment_detail, key) => (
                                                    <div class='col-md-6 col-lg-3 col-xl-3 padding-lr-5'>
                                                        <div class='col-md-12 card card-secondar y no-padding-left-right g-bg '>
                                                            <div class='card-content no-padding-left-right'>
                                                                <div class='card-body f-regular'>
                                                                    <div class='col-md-12 no-padding-left-right'><label><b>Bank Name:</b> </label>      {payment_detail.bank_name}</div>
                                                                    <div class='col-md-12 no-padding-left-right'><label><b>Bank Address: </b></label>   {payment_detail.bank_address}</div>
                                                                    <div class='col-md-12 no-padding-left-right'><label><b>Account Name: </b></label>   {payment_detail.account_name}</div>
                                                                    <div class='col-md-12 no-padding-left-right'><label><b>Account Number: </b></label> {payment_detail.account_number}</div>
                                                                    <div class='col-md-12 no-padding-left-right'><label><b>Swift Code: </b></label>     {payment_detail.swift_code}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                    
                            <div className="col-12">
                                <div className="card-content collapse show">
                                    <div className="card-body no-padding-top no-padding-left-right">
                                    <ul className="nav nav-tabs">
                                        <li className="">
                                            <a onClick={() => handleChangeTab('purchase_orders', pageOrigin['supplier_id'])} className="nav-link active" id="base-tab1" data-toggle="tab" aria-controls="tab1" href="#tab-po" aria-expanded="true">Purchase Orders</a>
                                        </li>
                                        <li className="">
                                            <a onClick={() => handleChangeTab('items', 1)} className="nav-link" id="base-tab2" data-toggle="tab" aria-controls="tab2" href="#tabs-items" aria-expanded="false">Items</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content px-1  card card-box-top">
                                        <div role="tabpanel" className="tab-pane active" id="tab-po" aria-expanded="true" aria-labelledby="base-tab1">
                                            <div>
                                                <SupplierDateFilterGlobal filterBy="purchase_orders"/>
                                                <PurchaseOrderTable pageOrigin={pageOrigin}/>
                                            </div>                                          
                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="tabs-items" aria-expanded="true" aria-labelledby="base-tab2">
                                            <ProductList pageOrigin={pageOrigin}/>                     
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>                                        
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
	supplier: state.supplier,
});

export default connect(mapStateToProps,{ getSingleSupplier, handleChangeTab })(SupplierHistory);
