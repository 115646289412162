import React, { useEffect, Fragment, useState } from "react";
import Spinner from "../../layouts/Spinner";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactImageMagnify from 'react-image-magnify';
import EmailConfig from './EmailConfig';
import EmailLogs from './EmailLogs';
import EmailSecurity from './EmailSecurity';

function EmailSetting() {


    const [selectedTab,SetSelectedTab] = useState('Configurations');

    const [tabs, setTabs] = useState([
        { key: 'Configurations', class : 'nav-link inv-top-nav inv-top-nav-active mr-50' },
        { key: 'Security', class : 'nav-link inv-top-nav mr-50' },
        { key: 'Logs', class : 'nav-link inv-top-nav mr-50' },
        
    ]);

    function handleChangeTab(tab)
    {
      SetSelectedTab(tab);
      tabs.map((list) => {
        list.class = list.key == tab ? "nav-link inv-top-nav inv-top-nav-active mr-50": "nav-link inv-top-nav mr-50";
        return list;
      });
      setTabs(tabs);
    }

    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Settings </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item "><Link to="#" className="text-white">Email</Link></li>
                                </ol>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header"></div> */}
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                        <nav className="nav f-regular">
                                            {
                                                tabs.map((item, index) => ( <a key={index} onClick={() => handleChangeTab(item.key) } className={item.class}>{item.key} {item.key == 'Theme' && <span className="mt-0 lbl-status lbl-coming-soon">Coming Soon</span>}</a> ))
                                            }
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            {(() => {
                                switch(selectedTab){
                                    case 'Configurations':
                                        return <EmailConfig/>; 
                                    case 'Logs':
                                        return <EmailLogs/>;
                                    case 'Security':
                                        return <EmailSecurity/>
                                    default: 
                                        return null;
                                }
                            })()}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    emailsetting: state.emailsetting
});

export default connect(mapStateToProps, { })(EmailSetting);
