import React, { Fragment, useEffect } from "react";
import Select, { components } from "react-select";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {  Show, useAccess } from "react-redux-permission";

function POSMenu() {
    return (
        <div className="card">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="card-header pb-0">
                <h4 className="card-title">Menu <a href="#" className="tool-tip" data-tip="this is a tool tip"><i className="la la-question-circle"></i></a></h4>
                <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                <div className="heading-elements">
                    <ul className="list-inline mb-0"></ul>
                </div>
            </div>
            <div className="card-content collapse show">
                <div className="card-body">

                </div>
            </div>

            <span className="mb-5 pb-5"></span>
        </div>
    )
}

const mapStateToProps = state => ({
	pos: state.pos
})

export default connect(mapStateToProps, { })(POSMenu);