import { SET_ATTR_LIST, SET_UNIT_MEASUREMENT, HANDLE_SELECT_TYPE, SET_LOADING, GET_ATTRIBUTE_TEMPLATES, ATTRIBUTE_TEMPLATE_INPUT_CHANGE,
     GET_SINGLE_ATTRIBUTE_TEMPLATE, SET_MODAL, CLEAR_ATTRIBUTE_TEMPLATE, SEARCH_INPUT, GET_ATTRIBUTES_FOR_TEMPLATE, 
     ATTRIBUTE_TEMPLATE_INPUT_CHECK, UPDATE_ATTRIBUTES_WITH_CHECK, GET_UNIT_MEASUREMENT, SET_MINI_LOADING, SORT_BY_COLUMN, GET_ROWS_TO_DELETE, SET_ROW_CHECKED } from '../../types';
import { ATTR_SEARCH } from '../../reducers/ReducerTypes/attribute';     
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from "jquery";
import { AttributeTemplateService } from './_service.attribute_template';
import { SwalWarning, SwalWarningHtml} from '../../../Services/_swal.service';
import { getSelectAll, setModal as setModalProd, handleSelectAttribute } from '../product/product.actions';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { getNumRows } from '../../../Utils/Common';


// handle inputs
export const handleInputChange = e => async dispatch => {

    if(e.target.value){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

    dispatch({ type: ATTRIBUTE_TEMPLATE_INPUT_CHANGE, payload: { key: e.target.name, value: e.target.value }})
}

// handle edit inputs
// export const handleSearchInput = e => async dispatch => {
//     dispatch({ type: SEARCH_INPUT, payload: e.target.value })
// }

export const handleSearchInputMUI = e => async dispatch => {
    dispatch({ type: ATTR_SEARCH, payload: e.target.value })
}

// loading
export const setLoading = status => async dispatch => dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = status => async dispatch => dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearAttributeTemplate = () => async dispatch => dispatch({ type: CLEAR_ATTRIBUTE_TEMPLATE  });

// set modal
export const setModal = (modal, id, status = true) => async dispatch => {
    dispatch({ type: SET_MODAL, payload: { modal, status } });
}

export const sortTableByColumn = (sort_order_name, sort_order_direction) => async dispatch => {
    
    let sortingParams = { 
        sort_order_name: sort_order_name, 
        sort_order_direction: sort_order_direction 
    } 
    
    dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
    dispatch(getAttributeTemplates());
}


// fetch attribute templates
export const getAttributeTemplates = (pageNumber, rows_per_page = null) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    let { search_attr, pagination, sort_order_name, sort_order_direction  } = getState().attribute_template;
    let rows_per_page_val = rows_per_page != null ? rows_per_page : getNumRows('attribute_template');

    // let searchPostParams = {
    //     search: search_attr,
    //     activePage: pagination.activePage,
    //     page: pageNumber,
    //     rows_per_page: rows_per_page_val, 
    //     sort_order_name: sort_order_name,
    //     sort_order_direction: sort_order_direction
    // }

    //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
    let searchPostParams = {
        search: search_attr,
        options: {
            page: pageNumber,
            limit: rows_per_page_val || 10,
            sort_by: sort_order_name,
            sort_order: sort_order_direction,
        },
    };

    AttributeTemplateService.paginateWithSearchAttributeTemplates(searchPostParams).then(res => {

        // let pagination = { 
        //     totalCount: res.data.attribute_templates.total, 
        //     activePage: res.data.attribute_templates.current_page, 
        //     itemsCountPerPage: res.data.attribute_templates.per_page, 
        //     totalItemsCount: res.data.attribute_templates.total
        // }
        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let pagination = {
            totalCount: res.data.total_docs,
            activePage: res.data.page,
            itemsCountPerPage: res.data.limit,
            totalItemsCount: res.data.total_docs,
        };

        // dispatch({ type: GET_ATTRIBUTE_TEMPLATES, payload: { attribute_templates: res.data.attribute_templates.data, pagination: pagination } })
        dispatch({ type: GET_ATTRIBUTE_TEMPLATES, payload: { attribute_templates: res.data.docs, pagination: pagination } })
        dispatch(setLoading(false));
    })
    .catch(err => {
        dispatch(setLoading(false));
        // ToastDanger('Network error, please reload the page.');
    });
};

// fetch units
export const getUnitMeasurement = () => async dispatch => {
    AttributeTemplateService.getUnits().then(res => {

        // const Units = res.data.Units.map(
        //   ({ _id, unit_m_property_name, units }) => ({
        //     value: _id,
        //     label: unit_m_property_name,
        //     units: units.map(
        //         ({_id, unit_m_name, unit_m_symbol}) => ({
        //             value: _id, 
        //             label: unit_m_name + " (" + unit_m_symbol + ")"
        //         })
        //     )
        //   })
        // );
 
        // ** New update
        const Units = res.data.unit_measurements.map(
            ({ _id, unit_m_property_name, units }) => ({
                    value: _id,
                    label: unit_m_property_name,
                    units: units.map(
                        ({id, unit_m_name, unit_m_symbol}) => ({
                            value: id, 
                            label: unit_m_name + " (" + unit_m_symbol + ")"
                        })
                    )
                  })
          );

        Units.unshift({ value: "", label: 'None' })

        console.log(Units)

        dispatch({ type: GET_UNIT_MEASUREMENT, payload: Units });
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
};


// fetch attributes
export const getAttributes = () => async dispatch => {
    AttributeTemplateService.getAttributes().then(res => {
        dispatch({ type: GET_ATTRIBUTES_FOR_TEMPLATE, payload: res.data.attributes });
    })
    .catch(err => {
        dispatch(setLoading(false));
        ToastDanger('Network error, please reload the page.');
    });
};


export const handleInputChangeAttr = (key, e, attr_list) => async (dispatch, getState) => {

    if(e.target.value){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

    attr_list[key].label = e.target.value;
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handleSelectOptionProperty = (key, selectOption, attr_list) => async (dispatch, getState) => {

    // console.log(selectOption);
    attr_list[key].unit_options = selectOption.units;
    attr_list[key].property_default = selectOption;
    attr_list[key].unit_options_value = null;
    if(selectOption.label === 'None')
    {
        let unit_options_value = {label: 'None'};
        attr_list[key].unit_options_value = unit_options_value;
    }

    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handleSelectUnit = (key, selectOption, attr_list) => async (dispatch, getState) => {

    attr_list[key].unit_options_value = selectOption;
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handleAddAttribute = (attr_list, default_attr) => async (dispatch, getState) => {

    const { attributesList } = getState().attribute_template;

    let errors = [];
    let labels = attributesList.map(function(item){ 
        if(item.label !== ''){
            return item.label.toLowerCase(); 
        }
    });
    // check duplicate labels
    labels.some(function(item, index){ 
        if(labels.indexOf(item) != index){
            errors.push(`"${item}" is already in Attribute Label Name list, please provide another name.`);
        }
    });

    if(errors.length > 0){
        return ToastDanger(errors);
    }

    console.log(default_attr);

    const new_attr_list = attr_list.concat(default_attr);

    if(new_attr_list.length > 0){
        dispatch(blockRoute(true));
    }else{
        dispatch(blockRoute(false));
    }

    dispatch({ type: SET_ATTR_LIST, payload: new_attr_list });
}

export const handleRemoveAttr = (key, attr_list) => async(dispatch, getState) => {
    // delete attr_list[key];
    attr_list  = attr_list.filter((item, item_key) => key !== item_key);
    // attr_list = 
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handSelectInputType = (key, selectOption, attr_list) => async(dispatch, getState) => {
    attr_list[key].inputy_type_value = selectOption;
    attr_list[key].input_options = [{label: null}];
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handSelectValidation = (key, selectOption, attr_list) => async(dispatch, getState) => {
    attr_list[key].validation = selectOption;
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handChangeInputOption = (key, e, attr_list, input_opt_index) => async(dispatch, getState) => {
    // attr_list[key].inputy_type_value = selectOption;
    attr_list[key].input_options[input_opt_index].label = e.target.value;
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const handSelectAddOption = (key, selectOption, attr_list) => async(dispatch, getState) => {

    let input_options = attr_list[key].input_options;

    console.log(key, attr_list)

    let errors = [];
    let labels = input_options.map((item) => { 
        if(item.label === null){
            errors.push('Option field is required to add another one.');
        }else{
            return item.label.toLowerCase();
        }
    });

    // check duplicate labels
    labels.some(function(item, index){ 
        if(labels.indexOf(item) != index){
            errors.push(`"${item}" is already in Options, please provide another option.`);
        }
    });

    if(errors.length > 0){
        return ToastDanger(errors);
    }

    let new_input_options = input_options.concat({label: null});
    attr_list[key].input_options = new_input_options;
    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}
export const handSelectRemoveOption = (key, selectOption, attr_list, input_options_key) => async(dispatch, getState) => {

    let input_options = attr_list[key].input_options;
    input_options = input_options.filter((item, item_key) => input_options_key !== item_key);
    attr_list[key].input_options = input_options;

    dispatch({ type: SET_ATTR_LIST, payload: attr_list });
}

export const validateAttribute = formParams => async (dispatch, getState) =>  {
    var error_count = 0;
    if(formParams.attributes_list){
        
        formParams.attributes_list.map((attr, index) => {

            let line = index + 1;

            if(!attr.measurement)
            {
                ToastDanger('Measurement is required in attributes list line ' + line); error_count++;
            }
            else
            {
                if(attr.measurement.label !== 'None')
                {
                    if(!attr.unit)
                    {
                        ToastDanger('Unit is required in attributes list line ' + line); error_count++;
                    }
                }
            }

            if(!attr.input_type)
            {
                ToastDanger('Input Type is required in attributes list line ' + line); error_count++;
            }
            else
            {
                if(attr.input_type.label !== 'Input Box')
                {
                    if(attr.input_options.length >= 1)
                    {
                        attr.input_options.map((option, index) => {
                            if(!option.label)
                            {
                                ToastDanger('Option is required in attributes list line ' + line); error_count++;
                            }
                        });
                    }
                    else
                    {
                        ToastDanger('Please add an input option in line ' + line); error_count++;
                    }
                }
                else
                {
                    if(!attr.validation)
                    {
                       ToastDanger('Please select validation in line ' + line); error_count++; 
                    }
                }
            }
        });
    }
    else
    {
        ToastDanger('Please click Add to add an attribute ');
    }

    return error_count;
}

export const attributeTemplatePost = (e, pageOrigin) => async (dispatch, getState) =>  {
    e.preventDefault();

    let { attributesList, single_attribute_template, create_modal } = getState().attribute_template;

    let formParams                      = {};
    formParams.template_name            = single_attribute_template.template_name;
    formParams.template_description     = single_attribute_template.template_description == null ? "" : single_attribute_template.template_description;

    let errors = [];
    let options_check = [];
    let labels = attributesList.map((item) => { 

        if(item.input_options.length > 1){
            item.input_options.map(option => {
                if(option.label != ''){
                    options_check.push(option.label);
                }
            })
        }

        if(item.label === null){
            errors.push('Attribute Name field is required.');
        }else{
            return item.label.toLowerCase();
        }
    });

    // check duplicate labels
    labels.some(function(item, index){ 
        if(labels.indexOf(item) != index){
            errors.push(`"${item}" is already in Attribute Name, please provide another name.`);
        }
    });

    // check duplicate input otions
    if(options_check.length > 0){
        let duplicates = [];
        options_check.some(function(opt, _index){
            if(options_check.indexOf(opt) != _index){
                duplicates.push(opt);
            }
        }); 
        let unique = [...new Set(duplicates)]; // removes duplicates
        if(duplicates.length > 0){
            errors.push(`"${unique.toString()}" is already in Options List, please provide another option(s).`);
        }
    }


    if(errors.length > 0){
        ToastDanger(errors);
        return false;
    }

    attributesList = attributesList.filter(function(val){return val});
    formParams.attributes_list = attributesList.map(
      (attr, index) => ({
        input_options: attr.input_options,
        input_type: attr.inputy_type_value,
        label: attr.label,
        measurement: attr.property_default,
        unit: attr.unit_options_value,
        validation: attr.validation,
      })
    );

    var error_count = validateAttribute(formParams);
    if(error_count >= 1 )
    {
        return false;
    }

    if(!formParams.template_name)
    {
        dispatch(setModal('create_modal', null, true));

        if(create_modal == true)
        {
            ToastDanger('Attribute Template Name is required');
        }
        else
        {
            dispatch(setModal('create_modal', null, true));
        }

        return false;
    }

    $('.btn-save').attr('disabled', 'disabled').html('Saving...');

    dispatch(setLoading(true));
    dispatch(setMUIPage(1));

    if(pageOrigin === 'item'){
        formParams['pull'] = true
    }

    AttributeTemplateService.attributeTemplatePost(formParams).then(res => {
        if(res.data.status === 1){
            if(pageOrigin === 'attribute_template')
            {
                dispatch({ type: ATTRIBUTE_TEMPLATE_INPUT_CHANGE, payload: { key: "success", value: true}});
                dispatch(setModal('create_modal', null, false));
            }
            
            if(pageOrigin === 'item')
            {
                dispatch(getSelectAll());
                dispatch(setModalProd('attribute_modal', false));
                let attribute_template = { value: res.data.docs._id, label: res.data.docs.name, attribute_lists: res.data.docs.attributes }   
                dispatch(handleSelectAttribute(attribute_template, 'attribute_template_id'))
            }
            
            dispatch(clearAttributeTemplate());
            ToastSuccess(res.data.message);
        }

        dispatch(setLoading(false));
        $('.btn-save').removeAttr('disabled').html('Save');
        
    })
    .catch(err => {
        if(err.data.errors.length > 0){
            ToastDanger(err.data.errors);
        }else{
            ToastDanger('Network error, please reload the page.');
        }
        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
    });

    return false;
}

//  fetch single attribute template 
export const getSingleAttributeTemplate = (id)=> async dispatch => {
    dispatch(setMiniLoading(true));
    AttributeTemplateService.getSingleAttributeTemplate(id).then(res => {
        // ** OLD IMPLEMENTATION
        // let attribute_list                      = res.data.attribute_list;
        // let single_attribute                    = res.data.attribute_template;
        // let Units                               = res.data.Units;
        // single_attribute.success                = false;
        // single_attribute.template_name          = single_attribute.name;
        // single_attribute.template_description   = single_attribute.description;
        // single_attribute.attributes_list         = [];

        // dispatch({ type: GET_SINGLE_ATTRIBUTE_TEMPLATE, payload: { single_attribute_template: single_attribute, attributes: res.data.attribute_template.attributes_witch_check } });
        // dispatch({ type: SET_ATTR_LIST, payload: attribute_list });
        // dispatch({ type: GET_UNIT_MEASUREMENT, payload: Units });

        // ** MERN IMPLEMENTATION
        let attribute_list                      = res.data.docs.attribute_list;
        let single_attribute                    = res.data.docs.attribute_template;
        let Units                               = res.data.docs.units;
        single_attribute.success                = false;
        single_attribute.template_name          = single_attribute.name;
        single_attribute.template_description   = single_attribute.description;
        single_attribute.attributes_list         = [];

        dispatch({ type: GET_SINGLE_ATTRIBUTE_TEMPLATE, payload: { single_attribute_template: single_attribute } });
        dispatch({ type: SET_ATTR_LIST, payload: attribute_list });
        dispatch({ type: GET_UNIT_MEASUREMENT, payload: Units });

        dispatch(setMiniLoading(false));

    })
    .catch(err => {
        ToastDanger('Network error, please reload the page.');
        dispatch(setMiniLoading(true));
    });
}

export const updateSingleAttributeTemplate = e => async (dispatch, getState) => {
    e.preventDefault();

    let { attributesList, single_attribute_template, edit_modal } = getState().attribute_template;
    let formParams                      = {};
    formParams.template_name            = single_attribute_template.template_name;
    formParams.template_description     = single_attribute_template.template_description == null ? "" : single_attribute_template.template_description;


    attributesList = attributesList.filter(function(val){return val});

    formParams.attributes_list = attributesList.map(
      (attr, index) => ({
        id: attr.id,
        input_options: attr.input_options,
        input_type: attr.inputy_type_value,
        label: attr.label,
        measurement: attr.property_default,
        unit: attr.unit_options_value,
        validation: attr.validation,
      })
    );

    var error_count = validateAttribute(formParams);

    if(error_count >= 1 )
    {
        return false;
    }

    if(!formParams.template_name)
    {
        if(edit_modal == true)
        {
            ToastDanger('Attribute Template Name is required');
        }
        else
        {
            dispatch(setModal('edit_modal', null, true));
        }

        return false;
    }
    else
    {
       if(edit_modal == false)
        {
            dispatch(setModal('edit_modal', null, true));
            return false;
        } 
    }

    $('.btn-save').attr('disabled', 'disabled').html('Saving...');

    console.log(formParams)
    dispatch(setLoading(true));
    AttributeTemplateService.updateSingleAttributeTemplate(formParams, single_attribute_template._id).then(res => {
        switch(res.data.status){
            case 0:
                ToastDanger(res.data.errors);
                dispatch(setLoading(false));
                return false;
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch({ type: ATTRIBUTE_TEMPLATE_INPUT_CHANGE, payload: { key: "success", value: true}});
                break;
            default:
                break;
        }
        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
    })
    .catch(err => {
        if (err.data.errors.length > 0) {
            ToastDanger(err.data.errors);
        } else {
            ToastDanger("Network error, please reload the page.");
        }
        $('.btn-save').removeAttr('disabled').html('Save');
        dispatch(setLoading(false));
    });

    return false;
}   


// archive
export const moveToArchives = () => async (dispatch, getState) => {

    let { rows_to_delete } = getState().attribute_template;

    let id_arr = [];
    rows_to_delete.map(row => { id_arr.push(row.id) })

    let params = {  ids: JSON.stringify(id_arr) };

    AttributeTemplateService.ArchiveAttributeTemplate(params).then(res => {

        dispatch(setRowChecked())
        switch (res.data.status) {
            case 0:
                ToastDanger(res.data.message);
                break;
            case 1:
                ToastSuccess(res.data.message);
                dispatch(getAttributeTemplates());
                dispatch(clearAttributeTemplate());
            default:
                break;
        }
    })
    .catch(error => {
        ToastDanger('Network error, please reload the page.');
        console.log(error);
    });
};


// returns selected rows only 
export const getSelectedRows = (selectedRows, currentRows) => async dispatch => {
    // console.log(selectedRows, currentRows)
    let newArray = [];

    currentRows.map((current,i) => {
        selectedRows.map((selected, _i) => {
            if(selected.index == current.index)
            {
                let selected = { id: current.data[0], name: current.data[1] };
                newArray.push(selected);
            }
        })
    });
    // console.log(newArray);
    dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
}

// confirm delete
export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
    
    let { rows_to_delete } = getState().attribute_template;
    let names = [];
    rows_to_delete.map((rows,i) => {  names.push( "<b>" + rows.name + "</b>" )  })
    // console.log(names, rows_to_delete);

    SwalWarningHtml('Warning!', `Do you want to remove the Attribute Template: ${names.join()}?`, () => dispatch(moveToArchives()));
}

// set the rows unchecked 
export const setRowChecked = () => async dispatch => dispatch({type: SET_ROW_CHECKED}); 
