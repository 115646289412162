import React, { useEffect } from "react";
import Spinner, { MiniSpinner } from "../../../layouts/Spinner";
import { connect } from 'react-redux';
import { getPOTemplateSettings, toggleComponent, confirmDialogBox } from "../../../../redux/actions/purchase_order_setting/po_template_setting.actions";


function POTSList({ loading, po_template_settings, getPOTemplateSettings, toggleComponent, confirmDialogBox }) {

    useEffect(() => {
        getPOTemplateSettings();
    },[])


    return (
        <div className="row">
            {
                loading ? <div className="col-6 justify-content-center py-5 px-5"><MiniSpinner/></div> :
                <>
                    {
                        po_template_settings && po_template_settings.map(po => (
                            <div className="col-lg-2 col-sm-6 col-12">
                                <div className="card temp-box">
                                    <div className="card-header temp-header row py-0 my-0 px-0 mx-0">
                                        <div className="mr-auto">
                                            <i className="ft ft-more-horizontal temp-box-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                { !po.main_template && <button onClick={() => toggleComponent('edit_copy', po)} class="dropdown-item" type="button">Edit</button> }
                                                { po.is_default === 1 ? null : !po.is_custom_template ? <button onClick={() => confirmDialogBox('set_default', po)} class="dropdown-item" type="button">Set as Default</button> : null }
                                                { po.main_template ? <button onClick={() => toggleComponent('duplicate-main', po)} class="dropdown-item" type="button">Duplicate</button> : <button onClick={() => toggleComponent('duplicate', po)} class="dropdown-item" type="button">Duplicate</button> }
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                            { !po.main_template && <div onClick={() => confirmDialogBox('archive', po)}><i className="ft ft-x temp-box-icon font-weight-bold"></i></div> }
                                        </div>
                                    </div>
                                    <div className="card-body my-0 p-0">
                                        <img src={po.template_image} alt="po" className="img-fluid cursor-pointer"/>
                                    </div>
                                </div>
                                <div className="text-center">{ po.is_default === 1 && <i className="ft ft-check-circle temp-default-check"></i> } {po.template_name}</div>
                                {
                                    po.is_default === 1 && <div className="text-center text-primary"><span className="lbl-status temp-coming-soon">DEFAULT</span></div>
                                }
                            </div>
                        ))
                    }

                    <div className="col-lg-2 col-sm-6 col-12">
                        <div className="card temp-box">
                            <div className="card-header temp-header row py-0 my-0 px-0 mx-0">
                                <div className="m-auto">
                                    <span className="mt-0 lbl-status temp-coming-soon">Coming Soon</span>
                                </div>
                            </div>
                            <div className="card-body my-0 p-0">
                                <span><i className="ft ft-plus-circle temp-plus-icon"></i></span>
                                <img src="/template-setting/blank.png" alt="po" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}


const mapStateToProps = state => ({
    setting_app: state.setting_app,
    loading: state.po_template_setting.loading,
    po_template_settings: state.po_template_setting.po_template_settings
});

export default connect(mapStateToProps, { getPOTemplateSettings, toggleComponent, confirmDialogBox })(POTSList);
