import React, { Component, Fragment, useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import { NavBarService } from './Navbar.service';
import { getUser, getToken, getUserAccess, setUserAccess, removeUserSession, setUserPermissions, getUserData} from '../../Utils/Common';
import { PermissionProvider, Show, useAccess } from "react-redux-permission";
import $ from 'jquery'; 
import {isTablet} from 'react-device-detect';

// interface WindowDimentions {
//     width: number;
//     height: number;
// }

function Navbar ({auth: { auth_loading,user,access,role,permissions }}) {
    let history = useHistory();
    const [token, setToken] = useState(getToken());
    const [current_url, setCurrent_url] = useState(window.location.pathname);
    const [first_url_path, setFirst_url_path] = useState('/');
    const { hasPermission, definePermission, isLoaded } = useAccess();
    const userdata = getUserData();

    // update navbar when completing business profile
    const [accessArray, setAccessArray] = useState(access || [])

    useEffect(() => {
        setAccessArray(accessArray)
    }, [access])

    const { innerWidth: width, innerHeight: height } = window;
    // console.log(width);

    useEffect(() => {
        var pathArray = window.location.pathname.split('/');

        if(typeof(pathArray[1]) !== 'undefined' || pathArray[1] != null)
        {
            setFirst_url_path(pathArray[1]);
        }
        
    }, []);


    // opens tab if active
    const openParent = (data_url, type = null) => {

        let arr = Object.values(data_url).filter(ob => ob.url === current_url); // check only child
        
        if(type === 'child'){
            let currentURL = current_url.split('/');
            let dataURL = data_url.split('/');

            // Child Url Only ex Suppliers
            if(current_url === data_url){
                return true;
            }

            // Sub Child URL ex (Create & Edit pages)
            if(currentURL.length >= 4){
                if(dataURL[2] === currentURL[2]){
                    return true;
                }
            }

        }else{

            let hasGroup = Object.values(data_url).filter(ob => {
                let baseURL = current_url.split('/')[1];
                let group = ob.url.split('/')[1];
                if(baseURL === group){
                    return true;
                }
            });
    
            if(arr.length > 0 || hasGroup.length > 0){
                return true;
            }else{
                return false;
            }
        }
    }

    if(!getToken())
    {
        let origin = window.location.origin;
        let url = window.location.href;
        if(url.indexOf('/forgot-password') < 0 && url.indexOf('/sign-up') < 0 && url.indexOf('/reset-password/') < 0)
        {
            return <Redirect to='/login'  /> 
        }

        // return <Redirect to='/login'  /> 
        
    }
    const logout =() => {
        removeUserSession();
        this.props.history.push('/login');
    }
    // console.log('URL ', new URL("/app-assets/images/svg/dashboard.svg"))
    const renderNav = (acc) => {

    if(acc.group !== 'account'){
        if(acc.hasChildren === false) 
        {
        //   return <li key={acc.value} className={ 'nav-item ' + (acc.url == current_url ? 'active' : '') } ><a href={acc.url}><i className={acc.icon} ></i><span className="menu-title" data-i18n="">{acc.label}</span></a></li>;
            return <li key={acc.value} 
            // className={ 'nav-item ' + (acc.url == current_url ? 'active' : '') } 
            ><a className={acc.label + "-sub"} href={acc.url}>{['Deliveries','Settings','Help','What\'s New'].includes(`${acc.label}`) ? <i className={`${acc.icon}`} ></i> : <i className={`navbar_svg_icon_${acc.label} icon_fix_size color_on_hover`} ></i>}<span className={acc.label + "-sub menu-title"} data-i18n="">{acc.label}</span></a></li>;
        } 
        else 
        {
          return (
            //   <li  key={acc.value} className={ 'nav-item has-sub ' + (first_url_path == acc.group ? 'open' : '')} ><a href="#"><i className={`navbar_svg_icon`}></i><span className="menu-title " data-i18n="">{acc.label}</span></a>
            <li key={acc.value} className={`nav-item has-sub ${openParent(acc.children) ? 'open active' : ''}`}><a>{['Outbound','Settings','Help','What\'s New'].includes(`${acc.label}`) ? <i className={`${acc.icon}`} ></i> : <i className={`navbar_svg_icon_${acc.label} icon_fix_size color_on_hover`} ></i>}<span className="menu-title" data-i18n="">{acc.label}</span></a>
                <ul className="menu-content">
                    {acc.children.map(children => (
                        <li key={children.value} className={ openParent(children.url, 'child') ? 'active' : '' } ><Link className={acc.label + "-" +children.label + "-sub menu-item"} to={children.url}>{children.label}</Link></li>
                    ))}
                </ul>
            </li>
          );
        }
    }
  }

  const renderAccountDrop = (acc) =>
  {
    if(acc.group === 'account'){
        if(acc.hasChildren === true) 
        {
          return (acc.children.map(children => (<a className="dropdown-item text-bold" href={children.url}>{children.label}</a>)));
        } 
    }
  }

    return (
        <React.Fragment> 
            {token ? ( 
                <React.Fragment> 
                    <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-dark">
                        <div className="navbar-wrapper">
                            <div className="navbar-container content height-11">
                                <div className="collapse navbar-collapse show" id="navbar-mobile">
                                    <ul className="nav navbar-nav mr-auto float-left">
                                        <li className="nav-item mobile-menu d-md-none mr-auto">
                                            <a className="nav-link nav-menu-main menu-toggle hidden-xs" >
                                                <i className="ft-menu font-large-1"> </i>
                                            </a>
                                        </li>        
                                    </ul>
                                    <ul className="nav navbar-nav float-right">
                                        {/* <li className="dropdown dropdown-notification nav-item"><a className="nav-link nav-link-label" data-toggle="dropdown"><i className="ficon ft-bell" id="notification-navbar-link"></i><span className="badge badge-pill badge-sm badge-danger badge-up badge-glow">5</span></a>
                                            <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                                <div className="arrow_box_right">
                                                    <li className="dropdown-menu-header">
                                                        <h6 className="dropdown-header m-0"><span className="grey darken-2">Notifications</span></h6>
                                                    </li>
                                                    <li className="scrollable-container media-list w-100"><a>
                                                            <div className="media">
                                                                <div className="media-left align-self-center"><i className="ft-plus-square icon-bg-circle bg-cyan"></i></div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading">You have new order!</h6>
                                                                    <p className="notification-text font-small-3 text-muted">Lorem ipsum dolor sit amet, consectetuer elit.</p><small>
                                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">30 minutes ago</time></small>
                                                                </div>
                                                            </div>
                                                        </a><a >
                                                            <div className="media">
                                                                <div className="media-left align-self-center"><i className="ft-download-cloud icon-bg-circle bg-red bg-darken-1"></i></div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading red darken-1">99% Server load</h6>
                                                                    <p className="notification-text font-small-3 text-muted">Aliquam tincidunt mauris eu risus.</p><small>
                                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Five hour ago</time></small>
                                                                </div>
                                                            </div>
                                                        </a><a >
                                                            <div className="media">
                                                                <div className="media-left align-self-center"><i className="ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3"></i></div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading yellow darken-3">Warning notifixation</h6>
                                                                    <p className="notification-text font-small-3 text-muted">Vestibulum auctor dapibus neque.</p><small>
                                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Today</time></small>
                                                                </div>
                                                            </div>
                                                        </a><a >
                                                            <div className="media">
                                                                <div className="media-left align-self-center"><i className="ft-check-circle icon-bg-circle bg-cyan"></i></div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading">Complete the task</h6><small>
                                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Last week</time></small>
                                                                </div>
                                                            </div>
                                                        </a><a >
                                                            <div className="media">
                                                                <div className="media-left align-self-center"><i className="ft-file icon-bg-circle bg-teal"></i></div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading">Generate monthly report</h6><small>
                                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Last month</time></small>
                                                                </div>
                                                            </div>
                                                        </a></li>
                                                    <li className="dropdown-menu-footer"><a className="dropdown-item text-muted text-center" >Read all notifications</a></li>
                                                </div>
                                            </ul>
                                        </li> */}


                                        <li className="dropdown dropdown-user nav-item">
                                            <span className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                                <span className="avatar avatar-online avatar-c">
                                                    <img src={userdata ? userdata.thumb ? userdata.thumb != '' ? userdata.thumb : '/profile.png' : '/profile.png' : '/profile.png'} alt="avatar"/>
                                                    <i></i>
                                                </span>
                                            </span>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <div className="arrow_box_right">
                                                    <a className="dropdown-item text-bold" href="/account/profile">Profile</a>
                                                    <a className="dropdown-item text-bold" href="/account/security">Security</a>
                                                    { user.subscriber_main === "1" ?<a className="dropdown-item text-bold" href="https://subs.bluehive.com.ph">Subscription</a> : void 0 }
                                                    <a className="dropdown-item" href="/login" onClick={logout} ><i className="ft-power"></i> Logout</a> 
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div id="main-menu" className="main-menu menu-fixed menu-dark menu-accordion menu-shadow " data-scroll-to-active="true" data-img="/app-assets/images/backgrounds/04.jpg">
                        <div className="navbar-header">
                            <ul className="nav navbar-nav flex-row position-relative">
                                <li className="nav-item mr-auto">
                                    <a className="navbar-brand">
                                        <img className="brand-logo" alt="Chameleon admin logo" src="/app-assets/images/logo/blitz_237x237_logo_white.png"/>
                                        <h3 className="brand-text brand-text-logo"><img alt="Chameleon admin logo" src="/app-assets/images/logo/blitz_logo_text_white.png"/></h3>                                        
                                    </a>
                                </li>
                                <li className="nav-item d-none d-md-block nav-toggle">
                                    <a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                        <i className="toggle-icon ft-disc font-medium-3" data-ticon="ft-disc"></i>
                                    </a>
                                </li>
                                <li className="nav-item d-md-none">
                                    <a className="nav-link close-navbar">
                                        <i className="ft-x"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="navigation-background"></div>
                        <div className="main-menu-content">
                            <ul className="navigation navigation-main mb-2m" id="main-menu-navigation" data-menu="menu-navigation">
                                {access.map((acc, i) => ( 
                                    renderNav(acc)
                                ))}
                            </ul>
                            <div className="version-nav">Version 1.0.2</div>
                        </div>    
                    </div>
                </React.Fragment>  
            ) : ( null)}

           
        </React.Fragment>  
    )

   
}





const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, {  })(Navbar);
