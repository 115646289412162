import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../layouts/Spinner";
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { handleSearchInputMUI } from '../../redux/actions/pos/pos.actions';
import {  Show, useAccess } from "react-redux-permission";
import POSSales from './POS.sales.js';
import POSTransaction from './postransaction/POS.transaction';
import POSCreate from './create/POSCreate';
import POSSetting from './POS.setting';


function POS ({ pos: { pos_tab } }) {

    const [selectedTab,SetSelectedTab] = useState('Sales');

    const [tabs, setTabs] = useState([
        { key: 'Sales', class : 'nav-link inv-top-nav inv-top-nav-active mr-50' },
        { key: 'Transactions', class : 'nav-link inv-top-nav mr-50' },
        { key: 'POS', class : 'nav-link inv-top-nav mr-50' },
        { key: 'Settings', class : 'nav-link inv-top-nav mr-50' },
    ]);

    const [trans_id, setTransID] = useState(null);

    function handleChangeTab(tab, id = null)
    {
        if(id != null) {
            setTransID(id);
        }
        SetSelectedTab(tab);
        tabs.map((list) => {
            list.class = list.key == tab ? "nav-link inv-top-nav inv-top-nav-active mr-50": "nav-link inv-top-nav mr-50";
            return list;
        });
        setTabs(tabs);
    }
    
    return (
        <div className="app-content content">
            <ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Sales </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1.5">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><a href="/brand" className="text-white f-regular">Simple POS</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">Daily Sales</a></li>
                                </ol>
                            </div>
                        </div>
                        {/* <Show when="inv.sales.pos.create">
                            <Link to={create_url} className="btn btn-primary pull-right btn-fix-header f-regular"><i className="ft ft-plus"></i> Create New</Link>
                        </Show> */}
                    </div>
                </div>
                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header"></div> */}
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                       <div className="">
                                            <nav className="nav f-regular">
                                                {
                                                    tabs.map(item => ( <Link onClick={() => handleChangeTab(item.key) } className={item.class}>{item.key}</Link> ))
                                                }
                                            </nav>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            {(() => {
                                switch(selectedTab){
                                    case 'Sales':
                                        return <POSSales handleChangeTab={handleChangeTab}/>; 
                                    case 'Transactions':
                                        return <POSTransaction trans_id={trans_id} setTransID={setTransID}/>;
                                    case 'POS':
                                        return <POSCreate/>
                                    case 'Settings':
                                        return <POSSetting/>
                                    default: 
                                        return null;
                                }
                            })()}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    pos: state.pos
});

export default connect(mapStateToProps, { handleSearchInputMUI })(POS);
