import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';

export const DeliveryService = {
    getDelivery: (formdata, status) => http.post('/admin/delivery/search/' + status, formdata),
    getDrData: (id) => http.get('/admin/delivery/' + id),
    getQuotations: (formdata) =>
        http.post('/admin/delivery/quotations/delivery', formdata),
    getItems: (id) => http.get('/admin/delivery/quotation/' + id),
    createDR: (formdata) => http.post('/admin/delivery', formdata),
    updateDR: (formdata) => http.post('/admin/delivery/update', formdata),
    getTermsConditions: () => http.get('admin/term-condition'),
    getCustomers: (search) =>
        http.get('/admin/customer/customer?search=' + search), // get all customer
    getProducts: (formdata) => http.post('/admin/delivery/product', formdata), // get all the products
    getSingleCustomer: (id) => http.get('/admin/customer/' + id),
    getPrint: (id) => httpWithFile.get('/admin/delivery/print/' + id),
    goPage: (formParams) => http.post('/admin/delivery/go-page', formParams),
    sendEmail: (id) => http.get('/admin/delivery/email/' + id),
    getPdf: (id) => httpWithFile.get('/admin/delivery/pdf/' + id),
    getEmailConfig: () => http.get('/settings/email/security'),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    getDeliveryTemplates: () => http.get('/admin/delivery-template'),
    archieveList: (formdata) =>
        http.post('/admin/delivery/archives/list', formdata),
    cancelDR: (id) => http.get('/admin/delivery/cancel/' + id),
};

// ** THIS ARE THE OLD API, WE KEEP THIS FOR REFERENCE ONLY
// createDR: (formdata) => http.post("/admin/delivery/save", formdata),
// updateDR: (formdata, id) => http.put("/admin/delivery/" + id, formdata),
// getCustomers: () => http.get("/admin/sales-invoice/customer"), // get all customer
// getCustomers: (search) =>
//   http.get("/admin/sales-invoice/customer?search=" + search), // get all customer (old)
// getProducts: (formdata) => http.post("/admin/product/search", formdata), // get all the products
// getDelivery: (formdata) => http.post("/admin/delivery/search", formdata),
// getQuotations: (formdata) => http.post("/admin/sales-invoice/delivery", formdata),
