import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import CategoryCreateGlobal from './Category.category.global';
import { Link } from 'react-router-dom';

function CategoryCreate({ category: { main_page, main_url} }) {
   
    if(main_page) return <Redirect to={main_url}/>

    return (
        <div className="app-content content">
			<ReactTooltip  effect="solid" event="click"/>
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">Sales</h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item"><a href={main_url} className="text-white">Categories</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-white">Create</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <Link to={main_url}><i className="ft ft-arrow-left"></i> Back</Link>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body pb-4">
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <CategoryCreateGlobal pageOrigin="category"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
	category: state.category
});

export default connect(mapStateToProps, { })(CategoryCreate);