import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryList from "react-select-country-list";
import {
  supplierPost,
  handleInputChange,
  handleSelectCountry,
  handleMobile,
  handleAddPayment,
  handleRemovePayment,
  handChangePaymentInput,
  savePayment,
  clearSupplier,
} from "../../redux/actions/supplier/supplier.actions";
import { Redirect } from "react-router-dom";
import { customAlert } from "../../Utils/Confirm";
import ReactTooltip from "react-tooltip";
import { SwalFormWarning } from "../../Services/_swal.service";
import BeforeUnloadComponent from "react-beforeunload-component";
import { useHistory, useLocation } from "react-router-dom";
import { blockRoute } from "../../redux/actions/helper/helper.actions";

function SupplierCreateGlobal({
  supplier: {
    default_payment_details_,
    payment_details,
    single_supplier,
    isSearchable,
  },
  handleInputChange,
  supplierPost,
  savePayment,
  handleSelectCountry,
  handleMobile,
  addFile,
  removeFile,
  handleAddPayment,
  handleRemovePayment,
  handChangePaymentInput,
  pageOrigin,
  block_route,
  blockRoute,
  clearSupplier,
}) {
  const [countryOptions, setCountryOptions] = useState([]);
  let history = useHistory();

  const [countryValue, setCountryValue] = useState({value: "PH", label: "Philippines"})
  const [mobileCountry, setMobileCountry] = useState("ph")

  useEffect(() => {
    if (countryOptions == "") {
      setCountryOptions(countryList().getData());
    }
  }, [countryOptions]);


   /**
   * @param {"country" | "mobile"} origin - origin of change - "country" if from country dropdown or "mobile" if from mobile input
   * @param {string | null} - country code in lowercase e.g. "ph"
   * @param {{value: string, label: string} | null} countryObject - country dropdown option e.g. {value: "PH", label: "Philippines"}
   */
   const syncMobileAndCountry = (origin, country, countryObject) => {
    if (!origin || !(["country", "mobile"].find(item => item === origin.toLowerCase()))) return;
    

    if (origin === "country") {
      if (!countryObject) {
        setMobileCountry("ph")
      } else {
        setMobileCountry(countryObject.value.toLowerCase())
      }
    } else if (origin === "mobile") {
      if (!country) return;

      const countryValue = countryOptions.find(countryOption => countryOption.value === country.toUpperCase())
      setCountryValue(countryValue)
      handleSelectCountry(countryValue) // need to do change country in state as well
    }

    // reset mobile
    handleMobile("")
    
  }

  useEffect(() => {
    blockRoute(false);
    clearSupplier();
  }, []);

  useEffect(() => {
    // When onClick navbar prevent another dialog box
    window.addEventListener("beforeunload", function (e) {
      // the absence of a returnValue property on the event will guarantee the browser unload happens
      delete e["returnValue"];
      blockRoute(false);
      console.log("01");
    });

    if (block_route) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
      console.log("02");
    }

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block_route]);

 

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (block_route) {
      SwalFormWarning(
        "Warning",
        "Do you want to leave without saving",
        () => goLastLocation(),
        () => blockAgain()
      );
    }
  };

  const goLastLocation = () => {
    history.goBack();
    blockRoute(false);
  };

  const blockAgain = () => {
    window.history.pushState(null, null, window.location.pathname);
    console.log("03");
  };

  const btnDelete = (payment_detail, key) => {
    if (payment_detail[key].has_saved == 1) {
      var message =
        "You are about to remove " +
        payment_detail[key].bank_name +
        ". Continue?";

      customAlert("Are you sure?", message, function () {
        handleRemovePayment(payment_detail, key);
      });
    } else {
      handleRemovePayment(payment_detail, key);
    }
  };

  if (pageOrigin != "supplier") {
    return (
      <BeforeUnloadComponent
        blockRoute={block_route}
        ignoreBeforeunloadDocument={true}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
          SwalFormWarning(
            "Warning",
            "Do you want to leave without saving",
            () => handleModalLeave(),
            () => handleModalCancel()
          );
        }}
      >
        <form onSubmit={(e) => supplierPost(e, pageOrigin)} id="form-supplier">
          <section className="row f-regular">
            <ReactTooltip effect="solid" event="click" />
            <div className="col-12">
              <label className="label-control f-regular">
                Name: <span className="text-danger">*</span>
              </label>
              <div className="form-group row">
                <div className="col-md-11 col-10">
                  <input
                    onChange={handleInputChange}
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="float-right pl-0 col-1 col-md-1">
                  <div className="input-group-prepend">
                    <span
                      href="#"
                      className="tool-tip text-primary"
                      data-tip="this is a tool tip"
                    >
                      <i className="la la-question-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group row">
                <div className="col-md-4 col-12 label-control f-regular"></div>
                <div className="col-md-7 col-10 text-right">
                  <button
                    type="submit"
                    className="btn btn-primary btn-save f-regular"
                  >
                    Save
                  </button>
                </div>
                <div className="float-right pl-0 col-2 col-md-1"></div>
              </div>
            </div>
          </section>
        </form>
      </BeforeUnloadComponent>
    );
  }

  return (
    <BeforeUnloadComponent
      blockRoute={block_route}
      ignoreBeforeunloadDocument={true}
      modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
        SwalFormWarning(
          "Warning",
          "Do you want to leave without saving",
          () => handleModalLeave(),
          () => handleModalCancel()
        );
      }}
    >
      <ReactTooltip effect="solid" event="click" />
      <form onSubmit={(e) => supplierPost(e, pageOrigin)} id="form-supplier">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6 col-sm-12">
                <label className="label-control">
                  Name: <span className="text-danger">*</span>
                </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="name"
                      className="form-control f-regular"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Business Name: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="business_name"
                      className="form-control f-regular"
                      placeholder="Business Name"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Street 1: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                  <textarea
                    onChange={handleInputChange}
                    name="street1"
                    className="form-control f-regular"
                    placeholder="Street 1"
                    style={{ height: "80px", resize: "none" }}
                  ></textarea>
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Street 2: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <textarea
                      onChange={handleInputChange}
                      name="street2"
                      rows="3"
                      className="form-control f-regular"
                      placeholder="Street 2"
                      style={{ height: "80px", resize: "none" }}
                    ></textarea>
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">City: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="city"
                      className="form-control f-regular"
                      placeholder="City"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">State/Region: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="state"
                      className="form-control f-regular"
                      placeholder="State/Region"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Country: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <Select
                      value={countryValue}
                      options={countryOptions}
                      onChange={(option) => {setCountryValue(option); syncMobileAndCountry("country", null, option); handleSelectCountry(option)}}
                      isSearchable={isSearchable}
                      name="country"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Landline: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="number"
                      name="landline"
                      className="form-control f-regular"
                      placeholder="Landline"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Mobile: </label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <PhoneInput
                      countryCodeEditable={false}
                      enableSearch="true"
                      country={mobileCountry}
                      inputProps={{
                        name: "mobile",
                        required: false,
                        autoFormat: true,
                        enableSearch: true,
                      }}
                      inputClass="react-mobile-input w-100"
                      placeholder=""
                      masks={{ ph: "... ... ...." }}
                      onChange={(mobile, country) => {setMobileCountry(country.countryCode); syncMobileAndCountry("mobile", country.countryCode); handleMobile(mobile === country.dialCode ? "" : mobile)}}
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Fax:</label>
                <div className="form-group row">
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="number"
                      name="fax"
                      className="form-control f-regular"
                      placeholder="Fax"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="label-control">Email: </label>
                <div className="form-group row">
                  <div className="col-md-11 co-10">
                    <input
                      onChange={handleInputChange}
                      type="email"
                      name="email"
                      className="form-control f-regular"
                      placeholder="Email"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group row">
                  <label className="label-control">Contact Person: </label>
                  <div className="col-md-11 col-10">
                    <input
                      onChange={handleInputChange}
                      type="text"
                      name="contact_person"
                      className="form-control f-regular"
                      placeholder="Contact Person"
                    />
                  </div>
                  <div className="float-right pl-0 col-1 col-md-1">
                    <div className="input-group-prepend">
                      <span
                        href="#"
                        className="tool-tip text-primary"
                        data-tip="this is a tool tip"
                      >
                        <i className="la la-question-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <h3>
        Account Details{" "}
        <span
          href="#"
          className="tool-tip text-primary"
          data-tip="this is a tool tip"
        >
          <i className="la la-question-circle"></i>
        </span>
      </h3>
      <div className="row equal">
        {payment_details.map((payment_detail, key) => (
          <div class="col-md-6 col-lg-4 col-xl-4 padding-lr-5">
            <div class="col-md-12 card card-secondar y no-padding-left-right g-bg ">
              <div class="card-content no-padding-left-right">
                <div class="card-body">
                  {payment_detail.mode == "edit" ? (
                    <form
                      onSubmit={(e) =>
                        savePayment(e, payment_details, key, "view")
                      }
                      id={"form-s-p-" + key}
                    >
                      <button
                        onClick={(e) => btnDelete(payment_details, key)}
                        className="btn btn-danger btn-sm pull-right padding-5"
                      >
                        <i className="ft ft-trash"></i>
                      </button>{" "}
                      <br />
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          Bank Name: <span className="text-danger">*</span>
                        </label>{" "}
                        <input
                          required
                          onChange={(e) =>
                            handChangePaymentInput(key, e, payment_details)
                          }
                          value={payment_detail.bank_name}
                          type="text"
                          class="form-control"
                          name="bank_name"
                        />
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          Bank Address: <span className="text-danger">*</span>
                        </label>{" "}
                        <textarea
                          required
                          onChange={(e) =>
                            handChangePaymentInput(key, e, payment_details)
                          }
                          value={payment_detail.bank_address}
                          type="text"
                          class="form-control"
                          name="bank_address"
                        />
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          Account Name: <span className="text-danger">*</span>
                        </label>{" "}
                        <input
                          required
                          onChange={(e) =>
                            handChangePaymentInput(key, e, payment_details)
                          }
                          value={payment_detail.account_name}
                          type="text"
                          class="form-control"
                          name="account_name"
                        />
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          Account Number: <span className="text-danger">*</span>
                        </label>{" "}
                        <input
                          required
                          onChange={(e) =>
                            handChangePaymentInput(key, e, payment_details)
                          }
                          value={payment_detail.account_number}
                          type="number"
                          class="form-control"
                          name="account_number"
                        />
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          Swift Code: <span className="text-danger">*</span>
                        </label>{" "}
                        <input
                          required
                          onChange={(e) =>
                            handChangePaymentInput(key, e, payment_details)
                          }
                          value={payment_detail.swift_code}
                          type="number"
                          class="form-control"
                          name="swift_code"
                        />
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <br />
                        <div className=" padding-bottom-5">
                          {payment_detail.has_saved == 1 ? (
                            <button
                              onClick={(e) =>
                                savePayment(e, payment_details, key, "view")
                              }
                              className="btn btn-secondary btn-sm"
                            >
                              <i className="ft ft-x"></i> Cancel
                            </button>
                          ) : (
                            <span></span>
                          )}
                          <button
                            type="submit"
                            form={"form-s-p-" + key}
                            className="btn btn-primary btn-sm float-right"
                          >
                            <i className="ft ft-save"></i> Save
                          </button>
                          <br />
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div>
                      <button
                        onClick={(e) => btnDelete(payment_details, key)}
                        className="btn btn-danger btn-sm pull-right padding-5"
                      >
                        <i className="ft ft-trash"></i>
                      </button>{" "}
                      <br />
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          <b>Bank Name:</b>{" "}
                        </label>{" "}
                        <p>{payment_detail.bank_name}</p>
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          <b>Bank Address: </b>
                        </label>{" "}
                        <p>{payment_detail.bank_address}</p>
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          <b>Account Name: </b>
                        </label>{" "}
                        <p>{payment_detail.account_name}</p>
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          <b>Account Number: </b>
                        </label>{" "}
                        <p>{payment_detail.account_number}</p>
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <label>
                          <b>Swift Code: </b>
                        </label>{" "}
                        <p>{payment_detail.swift_code}</p>
                      </div>
                      <div class="col-md-12 no-padding-left-right">
                        <div className=" padding-bottom-5">
                          <button
                            onClick={(e) =>
                              savePayment(e, payment_details, key, "edit")
                            }
                            className="btn btn-info btn-sm float-right"
                          >
                            <i className="ft ft-edit"></i> Edit
                          </button>
                        </div>
                        <br />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div class="col-md-6 col-lg-4 col-xl-4 padding-lr-5 ">
          <div class="col-md-12 card no-padding-left-right h-93 g-bg">
            <div class="card-content no-padding-left-right align-middle h-100p">
              <div class="card-body h-100p">
                <div className="btn-center-vertical w-75p">
                  <center>
                    <a
                      onClick={(e) =>
                        handleAddPayment(
                          payment_details,
                          default_payment_details_
                        )
                      }
                      className="btn btn-primary btn-sm btn-circle btn-md"
                    >
                      <i className="ft ft-plus f-20"></i>
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="mt-3">
          <div className="form-group float-right">
            <button
              type="submit"
              className="btn btn-primary btn-save"
              form="form-supplier"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </BeforeUnloadComponent>
  );
}

const mapStateToProps = (state) => ({
  supplier: state.supplier,
  block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
  handleInputChange,
  supplierPost,
  handleSelectCountry,
  handleMobile,
  handleAddPayment,
  handleRemovePayment,
  handChangePaymentInput,
  savePayment,
  blockRoute,
  clearSupplier,
})(SupplierCreateGlobal);
